<template>
  <b-field :label="label" :type="'is-'+color">
    <b-switch :size="'is-'+size" v-model="realValue"
              :disabled="!enabled" @input="onChangeValue"/>
  </b-field>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({})
export default class WSwitch extends Vue {
  @Prop({default: ''})
  private color!: string;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: ''})
  private label!: string;
  @Prop({default: ''})
  private value!: string;
  @Prop()
  private dataTest!: string;
  @Prop()
  private size!: string;


  private realValue: string = '';

  private mounted() {
    this.realValue = this.value;
  }

  public onChangeValue() {
    this.$emit('input', this.realValue);
  }

  @Watch('value')
  public onChangePropValue() {
    this.realValue = this.value;
  }

}
</script>

<style scoped lang="scss">
</style>
