<template>
  <div>
    <PopUpTitle :title="$t('channelSubscriber.title')"></PopUpTitle>

    <div class="channelContainer">
      <b-taginput v-model="channelsToAdd" :allow-new="false" ellipsis rounded class="channelFilterCreatorInput"
                  :placeholder="$t('channelSubscriber.autocompletePlaceHolder')"
                  @typing="onNewText"
                  @input="resetFilter" size="is-middle"
                  :autocomplete="true" keep-first type="is-light" :allow-duplicates="false"
                  :max-height="300" :open-on-focus="channelsToAdd.length===0"
                  :data="filterChannel" field="name">
        <template slot-scope="props">
          <div class="userAutocompleteBox">
            <div class="userAutocompleteBox-left">
              <w-icon :icon="isPrivate(props.option)?'lock-outline':'pound'" size="small" color="primary"/>
            </div>
            <div class="userAutocompleteBox-content">
              <div class="userNameChannel">
                {{ props.option.name }} ({{ props.option.numberOfUser }})
              </div>
            </div>
          </div>
        </template>
      </b-taginput>
      <div>
        <w-button icon="" :loading="loading" @click="subscribeSelection" :enabled="channelsToAdd.length>0">{{
            $t('generic.subscribe')
          }}
        </w-button>
      </div>

    </div>
    <!-- CLOSE BUTTON PANEL -->
    <div class="closePanelButton" @click="close">
      <w-icon icon="close" size="small"/>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WInput from '@/components/wrapper/w-input.vue';
import store from '@/store';
import {Channel, ChannelSubscription} from '@/store/channel/channelModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {SUBSCRIBE_TO_A_CHANNEL} from '@/store/channel/channelAction';
import {User} from '@/store/user/userModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WToolTip, WInput, WIcon, PopUpTitle, CenterCenterPanel, WButton, WError},
})
export default class ChannelSubscriberSubscribe extends Vue {


  private channelsToAdd: Channel[] = [];

  private loading: boolean = false;
  private filterText: string = '';

  private resetFilter() {
    this.filterText = '';
  }

  private isPrivate(channel: Channel) {
    return channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  private onNewText(e) {
    this.filterText = e;
  }


  private mounted() {
    setActionEvent(ActionEvent.channel_open_browse);
  }

  get filterChannel(): Channel[] {
    const listOfChannel = store.getters.getAllChannels;
    const listFilter = listOfChannel.filter((channel: Channel) => {
      const value = this.filterText;
      // if (channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE) {
      //   return false;
      // }
      if (value) {
        return channel.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return true;
      }
    });

    return listFilter;
  }

  get subscribeChannel(): ChannelSubscription[] {
    return store.getters.getSubscriptionChannels;
  }

  private isSelected(channel: Channel) {
    for (const channelSub of this.subscribeChannel) {
      if (channelSub.channelID === channel.channelID) {
        return true;
      }
    }
    return false;
  }


  get userConnected(): User {
    return store.getters.userConnected;
  }

  private async subscribeSelection() {
    this.loading = true;
    for (const channel of this.channelsToAdd) {
      await this.subscribe(channel);
    }
    this.loading = false;
    if (this.channelsToAdd.length > 0) {
      this.$emit('subscribe', this.channelsToAdd[0]);
    }
    this.close();

  }

  private async subscribe(channel: Channel) {
    await store.dispatch(SUBSCRIBE_TO_A_CHANNEL, {channel, emails: [this.userConnected.login]});
    setActionEvent(ActionEvent.channel_subscribed);
  }

  private close() {
    this.$emit('close');
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/shadows.scss";

.channelContainer {
  margin-top: 32px;
  display: flex;
  width: 100%;

  .channelFilterCreatorInput {
    flex: 1;
    margin-right: 8px;
  }

  .userAutocompleteBox {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    color: var(--black);

    .userAutocompleteBox-left {
      padding: 4px;
      display: flex;

    }

    .userAutocompleteBox-content {
      padding: 4px;
      width: 100%;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .userNameChannel {
        margin-right: 16px;
        text-transform: capitalize;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.closePanelButton {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

</style>
