export enum ScreenVirtualBackgroundEnum {
    BEACH = 'BEACH',
    BNEWS = 'BNEWS',
    CHRISTMAS = 'CHRISTMAS',
    CHRISTMAS2 = 'CHRISTMAS2',
    CHRISTMAS3 = 'CHRISTMAS3',
    CHRISTMAS4 = 'CHRISTMAS4',
    CHRISTMAS5 = 'CHRISTMAS5',
    HAPPY_NY1 = 'HAPPY_NY1',
    HAPPY_NY2 = 'HAPPY_NY2',
    HAPPY_NY3 = 'HAPPY_NY3',
    HAPPY_NY4 = 'HAPPY_NY4',
    HAPPY_NY5 = 'HAPPY_NY5',
    HAPPY_NY6 = 'HAPPY_NY6',
    HAPPY_NY7 = 'HAPPY_NY7',
    CITY_CALM = 'CITY_CALM',
    CITY_NIGHT = 'CITY_NIGHT',
    FIRE_PIT = 'FIRE_PIT',
    PARTICULE_COLOR = 'PARTICULE_COLOR',
    PARTICULE_BRIGHT = 'PARTICULE_BRIGHT',
    PARTICULE_ORANGE = 'PARTICULE_ORANGE',
    SHEEP = 'SHEEP',
    SPACE = 'SPACE',
    WATERFALL = 'WATERFALL',
    COFFEE = 'COFFEE',
    THANKS = 'THANKS',
    CONGRATULATIONS = 'CONGRATULATIONS',
    CONGRATULATIONS2 = 'CONGRATULATIONS2',
    HELLO = 'HELLO',
    WELCOME = 'WELCOME',
    AWESOME = 'AWESOME',
    VALENTINE = 'VALENTINE',
    VALENTINE2 = 'VALENTINE2',
    EASTER = 'EASTER',
    HAPPY_BIRTHDAY = 'HAPPY_BIRTHDAY',
    ATLANTIQUE = 'ATLANTIQUE',
    BLUE = 'BLUE',
    BLUE_2 = 'BLUE_2',
    BLUE_3 = 'BLUE_3',
    ORANGE_1 = 'ORANGE_1',
    BLUR_1 = 'BLUR_1',
    BLUR_2 = 'BLUR_2',
    BLUR_3 = 'BLUR_3',
    BLUR_4 = 'BLUR_4',
    BLUR_5 = 'BLUR_5',
    BLUR_6 = 'BLUR_6',
    BLUR_7 = 'BLUR_7',
    RED_1 = 'RED_1',
    CLOUD = 'CLOUD',
    DARK_LIQUID = 'DARK_LIQUID',
    DARK_LIQUID_2 = 'DARK_LIQUID_2',
    DARK_LIQUID_3 = 'DARK_LIQUID_3',
    DARK_LIQUID_4 = 'DARK_LIQUID_4',
    DARK_LIQUID_5 = 'DARK_LIQUID_5',
    LIQUID = 'LIQUID',
    LIQUID_2 = 'LIQUID_2',
    PALM = 'PALM',
    PALM_2 = 'PALM_2',
    PINK = 'PINK',
    PINK_2 = 'PINK_2',
    PINK_3 = 'PINK_3',
    PINK_4 = 'PINK_4',
    PURPLE = 'PURPLE',
    PURPLE_2 = 'PURPLE_2',
    SPACE_2 = 'SPACE_2',
    SPACE_3 = 'SPACE_3',
    SPACE_4 = 'SPACE_4',
    VORTEX = 'VORTEX',
    WATERFALL_2 = 'WATERFALL_2',
    WHITE_CURVE = 'WHITE_CURVE',
    WHITE_GEO = 'WHITE_GEO',
    WHITE_GEO_2 = 'WHITE_GEO_2',
    YELLOW_GEO = 'YELLOW_GEO',
    YELLOW_VORTEX = 'YELLOW_VORTEX',
    GREEN = 'GREEN',
    ABSTRACT1 = 'ABSTRACT1',
    ABSTRACT2 = 'ABSTRACT2',
    ABSTRACT3 = 'ABSTRACT3',
    COTON = 'COTON',
    STAR1 = 'STAR1',
    NEUTRAL_BEST_PRACTICE = 'NEUTRAL_BEST_PRACTICE',
    NEUTRAL_DOCUMENT_REVIEW = 'NEUTRAL_DOCUMENT_REVIEW',
    NEUTRAL_HOWTO = 'NEUTRAL_HOWTO',
    NEUTRAL_INTRODUCTION = 'NEUTRAL_INTRODUCTION',
    NEUTRAL_MEETING_FOLLOW_UP = 'NEUTRAL_MEETING_FOLLOW_UP',
    NEUTRAL_NEW_FEATURE = 'NEUTRAL_NEW_FEATURE',
    NEUTRAL_NEWS = 'NEUTRAL_NEWS',
    NEUTRAL_PROJECT_BRIEF = 'NEUTRAL_PROJECT_BRIEF',
    NEUTRAL_QUICK_NOTE = 'NEUTRAL_QUICK_NOTE',
    NEUTRAL_QUICK_UPDATE = 'NEUTRAL_QUICK_UPDATE',
    NEUTRAL_REPORT = 'NEUTRAL_REPORT',
    CONCLUSION = 'CONCLUSION',
    FEEDBACK='FEEDBACK',
    PROCESS_REVIEW='PROCESS_REVIEW',
    STEP_1='STEP_1',
    STEP_2='STEP_2',
    STEP_3='STEP_3',
    STEP_4='STEP_4',
    STEP_5='STEP_5',
}
