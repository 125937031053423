<template>
  <b-icon :data-test="dataTest" :icon="icon" :size="'is-'+size" :type="colorToShow"
          :style="colorStyle?'color:'+colorStyle:''"/>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterCenter from '@/components/layout/CenterCenter.vue';

@Component({})
export default class WModal extends Vue {
  @Prop({default: ''})
  private size!: string;
  @Prop({default: 'default'})
  private icon!: string;
  @Prop({default: ''})
  private color!: string;
  @Prop()
  private dataTest!: string;

  get colorToShow() {
    if (this.color.indexOf('#') === -1) {
      return 'is-' + this.color;
    } else {
      return '';
    }
  }

  get colorStyle() {
    if (this.color.indexOf('#') === 0) {
      return this.color;
    } else {
      return '';
    }
  }

}
</script>

<style scoped lang="scss">
.chooseVideo {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .blurBackground {
    width: 100%;
    height: 100%;
    filter: blur(10px);
    transform: scale(1.4);
  }
}


</style>
