import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {
    computeFaceTrackCrop, destroyOffscreenCanvasForFaceTrack, isFaceTrackInitialised,
    setUpOffscreenCanvasForFaceTrackFrame
} from '@/utils/video/videoEffect/FaceTrackWorker';


export class FaceTrackTransformer implements ICanvasTransform {
    public initialised: boolean = false;
    private id: string = uuidv4();

    private canvasPreRendering!: OffscreenCanvas;
    private width: number;
    private height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    public async init(stream: MediaStream) {
        this.canvasPreRendering = new OffscreenCanvas(this.width, this.height);
        // @ts-ignore
        await setUpOffscreenCanvasForFaceTrackFrame(this.canvasPreRendering, this.id);
        log.debug('Initialise FaceTrackTransformer');
    }

    public async start() {
        log.debug('start FaceTrackTransformer');
        return;
    }

    public async transform(sleepMode: boolean, input: ImageBitmap | null): Promise<ImageBitmap | null> {
        try {
            // const dateStart = new Date().getTime();
            let imageGenerated: ImageBitmap | null = null;
            if (!sleepMode && input && input.width > 0) {
                // console.log('Image Grabbed', new Date().getTime() - dateStart);
                imageGenerated = await computeFaceTrackCrop(this.id, input);
            }
            if (!this.initialised) {
                isFaceTrackInitialised(this.id).then((value) => {
                    this.initialised = value;
                });
            }
            return imageGenerated;

        } catch (e: any) {
            console.error(e);
            throw new Error('RENDER_ERROR: ' + e.toString());
        }
    }


    public async flush() {
        log.debug('Flush FaceTrackTransformer');
        destroyOffscreenCanvasForFaceTrack(this.id);
    }
}
