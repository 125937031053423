<template>
  <div class="textarea-emoji-picker">
    <div class="chatBox">
      <div class="inputBox">

        <b-input data-test="commentMessageInput" ref="inputText"
                 v-model="value"
                 type="textarea"
                 :placeholder="placeholder"
                 @input="renderText"
                 @focus="onFocus"
                 @blur="onBlur"
                 :maxlength="512" :has-counter="false"
                 :size="'is-small'" ma
        />

      </div>
      <w-button data-test="commentMessageEmojiPicker" size="small" color="text" class="buttonEmote"
                :icon="emotePanel?'emoticon-outline':undefined"
                @click="toggleEmojiPicker" :class="size"/>
      <div class="pickerBox" v-if="emotePanel" :class="size">
        <picker class="pickerLayout" @select="this.selectEmoji" :showCategories="false"
                :data="emoData"
                :showPreview="false" color="#9600FF"
                v-show="showEmojiPicker"/>
      </div>
    </div>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Picker} from 'emoji-mart-vue-fast';
import WButton from '@/components/wrapper/w-button.vue';
import WInput from '@/components/wrapper/w-input.vue';
import {
  EMO_DATA,
  replaceEmoJParenthesisByNative
} from '@/utils/emoJUtil';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WInput, WButton, Picker},
})
export default class TextAreaEmoJ extends Vue {
  @Prop({default: ''})
  private value!: string;
  @Prop({default: ''})
  private placeholder!: string;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default: true})
  private emotePanel!: boolean;

  @Prop({default: false})
  private autoFocus!: boolean;


  private showEmojiPicker: boolean = false;


  public mounted() {
    if (this.autoFocus) {
      this.inputText.focus();
    }
    this.renderText();
  }

  public onFocus(e) {
    this.$emit('focus', e);
  }

  public onBlur(e) {
    this.$emit('blur', e);
  }


  public toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  public validate(e) {
    this.$emit('submit', this.value);
  }

  get emoData() {
    return EMO_DATA;
  }

  get inputText() {
    // @ts-ignore
    return this.$refs.inputText.$refs.textarea;
  }

  public selectEmoji(emoji) {
    this.insertTextToCursor(emoji.native);
    this.$nextTick(this.toggleEmojiPicker);
    this.inputText.focus();
    setActionEvent(ActionEvent.text_insert_emote, {emoji: emoji.native});
  }

  public insertTextToCursor(text) {
    // @ts-ignore
    const inputText = this.inputText;
    // get current text of the input
    const currentText = this.value;
    // save selection start and end position
    const start = inputText.selectionStart;
    const end = inputText.selectionEnd;

    // update the value with our text inserted
    this.value = currentText.slice(0, start) + text + ' ' + currentText.slice(end);

    // update cursor to be at the end of insertion
    inputText.selectionEnd = start + text.length + 1;
  }


  public renderText(val = '', offset: number = 0) {
    const start = this.inputText.selectionStart;
    this.$nextTick(() => {
      const lastValue = this.value;
      this.value = replaceEmoJParenthesisByNative(this.value);
      if (offset === 0) {
        offset = this.value.length - lastValue.length;
      }
      this.$nextTick(() => {
        this.inputText.selectionEnd = start + offset;
      });
    });
  }

  @Watch('value')
  public onChangeValue() {
    this.$emit('input', this.value);
  }


}
</script>

<style lang="scss">

.textarea-emoji-picker {
  textarea.textarea {
    min-height: 50px;
    border-radius: 8px;
    //   max-height: 50px;
    font-size: 14px;
  }
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import '@/scss/gridBreakPoint.scss';

.textarea-emoji-picker {
  position: relative;
  width: 100%;

  .chatBox {
    display: flex;
    width: 100%;

    .inputBox {
      flex: 1;

      textarea.textarea {
        min-height: 100px;

      }
    }

    .sendBox {
      margin-left: 8px;
    }

    .buttonEmote {
      margin-top: 4px;
      @include GRID1X {
        display: none;
      }
    }


  }
}

.pickerBox {
  position: relative;
  margin: 8px;
  margin-left: auto;
  margin-top: -40px;

  .pickerLayout {
    text-align: left;
    max-height: 130px;
    background: #FFF;
    z-index: 1;
    position: absolute;
    left: -200px;
    top: -80px;
    max-width: 210px;
    bottom: 128px;
    @extend .shadow1;
  }

  &.small {
    margin-top: -80px;

    .pickerLayout {
      max-height: 150px;
    }
  }
}
</style>
