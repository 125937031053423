<template>
  <div>
    <MainTitle size="small" color="light" class="titleContact" v-if="this.totalOfGroup>0"
               :title="$t('menu.contacts.title')">
    </MainTitle>
    <div v-for="(group,index) in listOfGroup"
         :key="'groupList'+index">
      <w-tool-tip :label="groupTitleToolTip(group)" position="bottom">
        <div class="groupLine" @click="selectGroupOfUser(group)"
             :class="isSelected(group.groupID)+' '+classNotify(group.groupID)">
          <div class="nameGroup">
            {{ groupTitle(group) }}
          </div>
          <div class="avatarGroup">
            <div class="userAvatarGroup" v-for="(user,index) in group.members" v-if="index<3"
                 :key="'userAvatarGroup'+index">
              <UserAvatar size="tiny" :user-avatar="urlOfAvatar(user)" type="sticker"/>
            </div>
          </div>
        </div>
      </w-tool-tip>
    </div>

    <Transition name="fade">
      <w-button class="buttonMore" size="small" v-show="!wantMore && this.totalOfGroup>5" @click="wantMore=true"
                color="light" icon="plus">
        {{ this.$t('generic.more') }}
      </w-button>
    </Transition>
    <!-- DESACTIVATE the infinite loader -->
    <infinite-loading v-if="wantMore" :identifier="identifier" @infinite="debouceInfinite">
      <div slot="no-more"></div>
    </infinite-loading>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getImageURLForSize, getUserAvatar} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import {v4 as uuidv4} from 'uuid';
import router from '@/router';
import {User} from '@/store/user/userModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WButton from '@/components/wrapper/w-button.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import {GET_LIST_OF_GROUP_CONTACT} from '@/store/contact/contactAction';
import {GroupContact} from '@/store/contact/contactModel';
import {MY_LIST_WEET, MyWeetListParams, REINIT_MY_WEET} from '@/store/myWeet/myWeetAction';
import debounce from 'lodash/debounce';
import {isGrid1X} from '@/utils/gridBreakPoint';
import {OPEN_MENU} from '@/store/setup/setupAction';

@Component({
  components: {MainTitle, WButton, WToolTip, WIcon, UserAvatar},
})
export default class GroupContactPanel extends Vue {
  private routeSelected: string = '';

  private intervalTime: number = 120000;
  private intervalIndex: any = 0;
  private wantMore: boolean = false;
  private debouceInfinite = debounce((state) => {
    this.infiniteHandler(state);
  }, 250);

  public beforeDestroy() {
    clearInterval(this.intervalIndex);
  }

  public mounted() {
    this.loadGroup();
    this.intervalIndex = setInterval(this.loadGroup, this.intervalTime);
  }


  private loadGroup() {
    store.dispatch(GET_LIST_OF_GROUP_CONTACT, 0);
  }

  get identifier(): string {
    return store.getters.getGroupLoadinIdentifier;
  }

  get listOfGroup(): GroupContact[] {
    return store.getters.getGroupContacts;
  }

  private isNotify(groupID: string): boolean {
    for (const notification of store.getters.getWeetTypeUnreadNotification) {
      if (notification.groupID === groupID) {
        return true;
      }
    }
    return false;
  }

  private classNotify(groupID: string) {
    if (this.isNotify(groupID)) {
      return 'notify';
    } else {
      return '';
    }
  }


  private urlOfAvatar(user: User): string {
    return getUserAvatar(user, 96);
  }

  get totalOfGroup(): number {
    return store.getters.getTotalGroupContact;
  }

  get page(): number {
    return store.getters.getPageGroupContact;
  }

  @Watch('$route', {immediate: true, deep: true})
  private onRouteChange(route: any) {
    // manage the #TAG
    this.routeSelected = route.name;
  }

  private selectGroupOfUser(group: GroupContact) {

    const params = new MyWeetListParams();
    params.groupFilter = group.groupID;
    if (this.routeSelected !== 'MyWeets') {
      router.push({name: 'MyWeets'});
    }
    store.dispatch(REINIT_MY_WEET, params);
    store.dispatch(MY_LIST_WEET, params);

    // cloe the menu
    if (isGrid1X()) {
      store.dispatch(OPEN_MENU, false);
    }

  }

  private isSelected(groupID: string): string {
    if (store.getters.getGroupFilter === groupID) {
      return 'selected';
    } else {
      return '';
    }
  }

  private groupTitle(group: GroupContact): string {
    if (group.name && group.name !== '') {
      return group.name;
    } else {
      let title = '';

      const sizeOfMembers = group.members.length;
      if (sizeOfMembers > 0) {
        title += group.members[0].firstName;
      }
      if (sizeOfMembers > 1) {
        title += ' & ' + this.$tc('generic.other', sizeOfMembers - 1, {number: sizeOfMembers - 1}).toString();
      }
      return title;
    }
  }

  // nme of group
  private groupTitleToolTip(group: GroupContact): string {
    if (!group) {
      return '';
    }
    if (group.name && group.name !== '') {
      return group.name;
    } else {
      let title = '';
      let index = 0;
      for (const user of group.members) {
        title += user.firstName;
        if (index < group.members.length - 1) {
          title += ' & ';
        }
        index++;
      }
      return title;
    }
  }


  private infiniteHandler(state) {
    store.dispatch(GET_LIST_OF_GROUP_CONTACT, this.page + 1).then(() => {
      if (this.totalOfGroup <= this.listOfGroup.length) {
        state.complete();
      }
      state.loaded();
    }).catch(() => {
      state.complete();
    });
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';

.groupLine {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  min-height: 32px;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  text-transform: capitalize;
  @include GRID1X {
    min-width: 320px;
    max-width: 320px;
  }

  .nameGroup {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
    margin-right: 16px;
    font-size: 14px;
    color: var(--black);
    font-weight: 600;
  }

  &.notify {
    .nameGroup {
      font-weight: 900;
    }
  }

  .avatarGroup {
    display: flex;
    flex-grow: 1;
    text-align: right;
    justify-content: flex-end;

    .userAvatarGroup {
      margin-left: -8px;
    }
  }

  &.selected {
    background: var(--light);
  }


  &:hover {
    background: var(--light1);
  }

}

.titleContact {
  padding-left: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
  margin-top: 24px;
}

.buttonMore {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
