<template>
  <div>
    <!-- TAG -->
    <div v-if="contactOrGroupOrUser.channelID"
         class="userAutocompleteBox">
      <div class="userAutocompleteBox-left">
        <w-icon :icon="isChannelPrivate(contactOrGroupOrUser)?'lock-outline':'pound'" size="small" color="primary"/>
      </div>
      <div class="userAutocompleteBox-content">
        <div class="userNameWorkspace">
          {{ contactOrGroupOrUser.name }} ({{ contactOrGroupOrUser.numberOfUser }})
        </div>
      </div>
    </div>
    <!-- SHOW a Workspace Contact User -->
    <div v-else-if="contactOrGroupOrUser.userID"
         class="userAutocompleteBox">
      <div class="userAutocompleteBox-left">
        <user-avatar class="avatarClass" :user-avatar="imageForUser(contactOrGroupOrUser)" size="tiny"/>
      </div>
      <div class="userAutocompleteBox-content">
        <div class="userNameWorkspace">
          @{{ contactOrGroupOrUser.firstName }} {{ contactOrGroupOrUser.lastName }}
        </div>
      </div>
    </div>
    <!-- SHOW a contact USER -->
    <div v-else-if="contactOrGroupOrUser.contactUserID"
         class="userAutocompleteBox">
      <div class="userAutocompleteBox-left">
        <user-avatar class="avatarClass" :user-avatar="imageForUser(contactOrGroupOrUser.contactUser)" size="small"/>
      </div>
      <div class="userAutocompleteBox-content">
        <div class="userName">
          {{ contactOrGroupOrUser.contactUser.firstName }} {{ contactOrGroupOrUser.contactUser.lastName }}
        </div>
        <div class="userContact">
          {{ contactOrGroupOrUser.contactUser.contactEmail }}
        </div>
      </div>
      <div class="userAutocompleteBox-right">
        <div class="buttonCross" @click.stop="deleteContact(contactOrGroupOrUser)">
          <w-icon icon="close" class="iconClose" size="small"/>
        </div>
      </div>
    </div>
    <!-- Show a Group -->
    <div v-else-if="contactOrGroupOrUser.groupID" class="userAutocompleteBox">
      <div class="userAutocompleteBox-left">
        <user-avatar class="avatarClass" v-for="user in contactOrGroupOrUser.members"
                     :user-avatar="imageForUser(user)"
                     :key="'shareContactInput'+user.userID"
                     size="small" type="sticker"/>
      </div>
      <div class="userAutocompleteBox-content">
        <div class="userName">
          {{ groupName(contactOrGroupOrUser) }}
        </div>
        <div class="userContact">
            <span v-for="user in contactOrGroupOrUser.members" :key="'shareContactInputAutocompete'+user.userID">
              {{ user.contactEmail }}
            </span>
        </div>
      </div>
      <div class="userAutocompleteBox-right">
        <div class="buttonCross" @click.stop="deleteContact(contactOrGroupOrUser)">
          <w-icon icon="close" class="iconClose" size="small"/>
        </div>
      </div>
    </div>
    <div v-else-if="contactOrGroupOrUser.value" class="userAutocompleteBox">
      <div class="userContact">{{ $t('generic.email') }}: {{ contactOrGroupOrUser.value }}</div>
    </div>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WError from '@/components/wrapper/w-error.vue';
import {getUserAvatar} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {User} from '@/store/user/userModel';
import WInput from '@/components/wrapper/w-input.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {DELETE_CONTACT, DELETE_GROUP} from '@/store/contact/contactAction';
import {GroupContact} from '@/store/contact/contactModel';
import WIcon from '@/components/wrapper/w-icon.vue';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import ChannelWorkspace from '@/components/channel/ChannelWorkspace.vue';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';
import {Channel} from '@/store/channel/channelModel';

@Component({
  components: {
    ChannelWorkspace,
    WorkspaceAvatar, WToolTip, WInput, UserAvatar, WError, PopUpTitle, WModal, WButton, WIcon
  }
})
export default class ShareContactSuggestion extends Vue {

  @Prop()
  private contactOrGroupOrUser!: any;

  public deleteContact(contactOrGroup: any) {
    if (contactOrGroup.contactUserID) {
      store.dispatch(DELETE_CONTACT, contactOrGroup);
    } else {
      store.dispatch(DELETE_GROUP, contactOrGroup);
    }
  }

  private isChannelPrivate(channel: Channel): boolean {
    return channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  public imageForUser(user: User): string {
    return getUserAvatar(user, 48);
  }

  public groupName(group: GroupContact): string {
    if (group.name && group.name !== '') {
      return group.name;
    } else {
      let title = '';
      let index = 0;
      for (const user of group.members) {
        title += user.firstName;
        index++;
        if (index < group.members.length) {
          title += ' & ';
        }
      }
      return title;
    }
  }

}
</script>

<style scoped lang="scss">

.userAutocompleteBox {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: var(--black);

  &:hover .buttonCross {
    display: flex;
  }

  .buttonCross {
    display: none;
  }

  .userAutocompleteBox-left {
    padding: 4px;
    display: flex;

    .avatarClass {
      margin-left: -8px;
    }
  }

  .userAutocompleteBox-content {
    padding: 4px;
    width: 100%;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .userName {
      margin-right: 16px;
      text-transform: capitalize;
    }

    .userNameWorkspace {
      margin-right: 16px;
      text-transform: capitalize;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .userContact {
      color: var(--light2);
      font-size: 12px;
      font-weight: 600;
      font-style: italic;
    }
  }

  .userAutocompleteBox-right {
    display: flex;
    margin-left: 3%;

    .buttonCross {
      color: var(--dark);
      margin-right: -0px;
      justify-content: flex-end;
      align-self: center;

      .iconClose {
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.2);
          font-weight: bold;
        }
      }
    }
  }
}
</style>
