<template>
  <VueDraggableResizable class-name-active="dragableSticker-active"
                         class="dragableSticker"
                         :class="this.stickerManualMoved?'noAnimation ':''"
                         class-name-handle="dragableSticker-handle"
                         style="bottom: 96px;left:16px;" :lockAspectRatio="true"
                         :style="isStickerMinimize?'visibility:hidden':''"
                         :z="10"
                         :min-width="80"
                         :min-height="80"
                         :max-width="320"
                         :max-height="320"
                         :active.sync="selectSticker"
                         :handles="['tr']"
                         :x="xSticker" :y="ySticker" :w="widthSticker" :h="heightSticker"
                         @dragging="onDragSticker"
                         @resizing="onResize">
    <div>
      <transition name="fade">
        <div class="sticker" @mouseover="selectSticker=true" :class="stickerStateClass"
             v-show="mustShow && ((smoothAlternatePlayer && urlVideoPlayer1) || (urlVideoPlayer2 && !smoothAlternatePlayer))"
             @dblclick="toggleMinimizeStickers"
             :style="'background-image: url('+stickerBackground+')'">

          <transition name="fade">
            <div class="stickerLayout" v-show="startPlayer"
                 v-if="currentMedia && (currentMedia.media.status==='HD'  ||currentMedia.media.blobURL)">
              <Transition name="fade">
                <video preload="auto" v-show="smoothAlternatePlayer" ref="videoElement" playsinline
                       class="videoElement"
                       :class="filter1"
                       :src="urlVideoPlayer1"
                       @canplay="onPlaying"
                       @waiting="onWaiting"
                       @playing="onPlaying"
                       @timeupdate="sendMediaTime"
                >
                </video>
              </Transition>
              <Transition name="fade">
                <video preload="auto" v-show="!smoothAlternatePlayer" ref="videoElement2" playsinline
                       class="videoElement"
                       :class="filter2"
                       :src="urlVideoPlayer2"
                       @canplay="onPlaying"
                       @waiting="onWaiting"
                       @playing="onPlaying"
                       @timeupdate="sendMediaTime"
                >
                </video>
              </Transition>

              <div class="mask"></div>
              <!-- filter only if play -->
              <div class="layer1"></div>
              <div class="layer2"></div>
              <div class="layer3"></div>
              <div class="layer4"></div>
              <div class="layer5"></div>
            </div>
            <div v-else class="stickerLayout">
              <b-progress :value="currentMedia?currentMedia.media.progress:0" size="is-small" type="is-primary"
                          style="margin:32px;transform: scaleX(-1);"
                          show-value format="percent"/>
            </div>
          </transition>

          <!-- this is a preload video  -->
          <video preload="metadata" muted :src="preloadUrl" v-if="preloadUrl" class="preloadingVideo"/>
        </div>
      </transition>
    </div>

    <div slot="tr">
      <w-icon icon="arrow-expand" class="iconResize" size="small" color="white"/>
    </div>
  </VueDraggableResizable>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import store from '@/store';
import {TimeEvent, TimeEventMedia} from '@/store/timeLine/timeEvent';
import {PLAYER_SUBTITLE, SWITCH_MINIMISE_STIKERS} from '@/store/timeLine/timeLineAction';
import {Media, PlayerMedia} from '@/store/media/mediaModel';
import {getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {log} from '@/utils/log';
import VueDraggableResizable from 'vue-draggable-resizable';
import {Weet} from '@/store/weet/weetModel';
import {getMediasForWeet} from '@/utils/weetUtil';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import delay from 'delay';
import {getBufferingAdvance} from '@/utils/videoUtil';


@Component({
  components: {WIcon, WButton, VueDraggableResizable},
  computed: {},
})
export default class StickerPlayer extends Vue {
  private mustShow: boolean = false;

  @Prop({default: false})
  private miniSticker!: boolean;

  @Prop()
  private weet!: Weet;

  @Prop({default: 0})
  private timePlayer!: number;

  @Prop({default: false})
  private fullResponsive!: boolean;

  private smoothAlternatePlayer: boolean = false;
  private startPlayer: boolean = false;

  private oldMedia: PlayerMedia | null = null;
  private currentMedia: PlayerMedia | null = null;

  private preloadUrl: string = '';
  private isFullScreenSticker: boolean = false;
  private xSticker: number = 0;
  private ySticker: number = 0;
  private widthSticker: number = 153;
  private heightSticker: number = 153;
  private selectSticker: boolean = false;
  private timeOutSelectSticker: number = 0;
  private stickerManualMoved: boolean = false;

  private pauseEndTimeOutId: number = 0;

  // filter
  private filter1: string = '';
  private filter2: string = '';

  // url
  private urlVideoPlayer1: string = '';
  private urlVideoPlayer2: string = '';

  get isVoiceOverPreview(){
    return store.getters.isVoiceOverPreviewMode;
  }

  // Seek to time
  public seekInTimeLine(time) {
    // console.log('Sticker : this.seekInTimeLine', time);
    // get MediaEvent before timeLine
    const mediaEvent = getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY);
    if (mediaEvent) {
      this.seekMediaToTimeLineTime(mediaEvent);
      // this.videoElement.currentTime = time / 1000;
    }
  }

  public pauseMedia() {
    const videoElement = this.videoElement();
    if (videoElement) {
      videoElement.pause();
    }
  }

  public playMedia(time: number) {
    const videoElement = this.videoElement();
    if (videoElement && this.isMediaAivailable) {
      if (time) {
        this.seekInTimeLine(time);
      }
      videoElement.play().catch((e) => {
        log.debug(e.message);
      });
    }

  }

  private animateFilter() {
    // manage smooth switch filter
    if (this.smoothAlternatePlayer) {
      this.filter1 = this.filter(this.smoothAlternatePlayer);
      this.filter2 = '';
    } else {
      this.filter2 = this.filter(!this.smoothAlternatePlayer);
      this.filter1 = '';
    }
  }


  @Watch('weet')
  private onWeetChange() {
    this.currentMedia = this.playerMedia;
    this.urlVideoPlayer1 = this.urlVideoPlayer(this.smoothAlternatePlayer);
    this.urlVideoPlayer2 = this.urlVideoPlayer(!this.smoothAlternatePlayer);

    // manage smooth switch filter
    if (this.smoothAlternatePlayer) {
      this.filter1 = this.filter(this.smoothAlternatePlayer);
      this.filter2 = '';
    } else {
      this.filter2 = this.filter(!this.smoothAlternatePlayer);
      this.filter1 = '';
    }
  }

  get isMediaAivailable(): boolean {
    return !!this.currentMedia;
  }

  get isNonePosition(): boolean {
    if (store.getters.getCurrentPlayerTimeEvent) {
      return store.getters.getCurrentPlayerTimeEvent.positionLayout === StickerPosition.NONE;
    } else {
      return false;
    }

  }

  private onAlternatePlayer() {
    this.urlVideoPlayer1 = this.urlVideoPlayer(this.smoothAlternatePlayer);
    this.urlVideoPlayer2 = this.urlVideoPlayer(!this.smoothAlternatePlayer);
    if (this.smoothAlternatePlayer) {
      setTimeout(() => {
        this.filter1 = this.filter(this.smoothAlternatePlayer);
        this.filter2 = '';
      }, 250);
    } else {
      setTimeout(() => {
        this.filter2 = this.filter(!this.smoothAlternatePlayer);
        this.filter1 = '';
      }, 250);
    }
  }


  @Watch('selectSticker')
  private onSelectStickerChange() {
    if (this.selectSticker) {
      clearTimeout(this.timeOutSelectSticker);
      // @ts-ignore
      this.timeOutSelectSticker = setTimeout(() => {
        this.selectSticker = false;
      }, 2500);
    }
  }

  get isStickerMinimize(): boolean {
    return store.getters.isMinimizeSticker || this.isNonePosition || !this.isMediaAivailable;
  }


  @Watch('isStickerMinimize')
  private onStickerMinimiseChange() {
    // we reinit the postion when we show the
    if (!store.getters.isMinimizeSticker) {
      this.stickerManualMoved = false;
      this.reinitSizeOfSticker();
    }
  }

  private toggleMinimizeStickers() {
    store.dispatch(SWITCH_MINIMISE_STIKERS, !this.isStickerMinimize);
    setActionEvent(ActionEvent.player_control_hide_cam, {
      player_webcam_hide: this.isStickerMinimize,
      weetID: this.weet.weetID,
      action: 'dblclick'
    });
  }

  private onDragSticker(left, top) {
    // reinit hover
    this.onSelectStickerChange();
    this.stickerManualMoved = true;
    this.xSticker = left;
    this.ySticker = top;
  }

  private onResize(left, top, width, height) {
    // reinit hover
    this.onSelectStickerChange();
    this.stickerManualMoved = true;
    this.xSticker = left;
    this.ySticker = top;
    this.widthSticker = width;
    this.heightSticker = height;
  }

  private urlVideoPlayer(alternateSwitch: boolean): string {
    if (alternateSwitch) {
      if (this.currentMedia) {
        return this.currentMedia.url;
      } else {
        return '';
      }
    } else {
      if (this.oldMedia) {
        return this.oldMedia.url;
      } else {
        return '';
      }
    }
  }

  private filter(alternateSwitch: boolean): string {
    if (alternateSwitch) {
      if (this.currentMedia) {
        let filter = this.currentMedia.filter.toString();
        if (!this.play) {
          filter += ' noAnimation';
        }
        return filter;
      } else {
        return '';
      }
    } else {
      if (this.oldMedia) {
        let filter = this.oldMedia.filter.toString();
        if (!this.play) {
          filter += ' noAnimation';
        }
        return filter;
      } else {
        return '';
      }
    }
  }

  get isFullscreenMode(): boolean {
    return store.getters.isFullscreenMode;
  }

  @Watch('isFullscreenMode')
  private onFullScreenChange() {
    this.reinitSizeOfSticker();
  }

  /**
   * Return the HTML Video Element
   */
  private videoElement(): HTMLVideoElement | undefined {
    if (this.smoothAlternatePlayer) {
      return this.$refs.videoElement as HTMLVideoElement;
    } else {
      return this.$refs.videoElement2 as HTMLVideoElement;
    }
  }

  private oldVideoElement(): HTMLVideoElement | undefined {
    if (!this.smoothAlternatePlayer) {
      return this.$refs.videoElement as HTMLVideoElement;
    } else {
      return this.$refs.videoElement2 as HTMLVideoElement;
    }
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  private reinitSizeOfSticker() {

    if (this.isFullscreenMode) {
      this.xSticker = 48;
      this.ySticker = -32;
      this.widthSticker = screen.height * 0.25;
      this.heightSticker = screen.height * 0.25;
    } else if (this.isFullScreenSticker) {

      this.xSticker = 48;
      this.ySticker = -32;

      if (this.miniSticker) {
        this.xSticker = 0;
        this.ySticker = 0;
        this.widthSticker = 150;
        this.heightSticker = 150;
      } else {
        this.widthSticker = 230;
        this.heightSticker = 230;
      }
    } else {
      this.xSticker = 0;
      this.ySticker = 0;
      if (this.miniSticker) {
        this.widthSticker = 100;
        this.heightSticker = 100;
      } else {
        this.widthSticker = 153;
        this.heightSticker = 153;
      }
    }
  }


  get stickerStateClass(): string {
    const fullscreen = store.getters.isFullscreenMode;
    let classCSS = '';

    if (this.isFullScreenSticker) {
      classCSS += 'fullscreenSticker ';
    }

    if (fullscreen) {
      // if the player is in fullscreenMode we add another class
      classCSS += 'fullscreenPlayer ';
    }

    if (this.isStickerMinimize) {
      classCSS += 'minimize ';
    }

    return classCSS;
  }

  private getMediasForMediaId(mediaID: string): Media | undefined {
    return getMediasForWeet(this.weet, mediaID);
  }

  get stickerBackground(): string {
    if (this.mediaDescription !== undefined && this.mediaDescription && this.mediaDescription.mediaID !== undefined &&
        this.mediaDescription.mediaID !== '') {
      const media: Media | undefined = this.getMediasForMediaId(this.mediaDescription.mediaID);
      if (media !== undefined) {
        return getUserAvatar(media.creator, 512);
      }

    }
    return getUserAvatar(undefined, 512);
  }

  get play(): boolean {
    return store.getters.isPlaying;
  }

  get volume(): number {
    return store.getters.volume;
  }

  private mounted() {
    this.mustShow = true;
    this.onMediaChange();
    this.reinitSizeOfSticker();
  }

  get mediaDescription(): TimeEventMedia {
    return store.getters.getPlayerSecondMedia;
  }


  get subtitleUrl(): string {
    if (this.mediaDescription !== undefined && this.mediaDescription.mediaID !== undefined &&
        this.mediaDescription.mediaID !== '') {
      const media: Media | undefined = this.getMediasForMediaId(this.mediaDescription.mediaID);
      if (media) {
        return media?.subtitlePath;
      }
    }
    return '';
  }


  @Watch('mediaDescription')
  private onMediaChange() {
    this.smoothAlternatePlayer = !this.smoothAlternatePlayer;
    this.oldMedia = this.currentMedia;
    this.currentMedia = this.playerMedia;
    this.preloadUrl = this.getPreloadingUrl();
    if (this.subtitleUrl) {
      store.dispatch(PLAYER_SUBTITLE, this.subtitleUrl);
    }
    this.onAlternatePlayer();
    this.refreshPlayState();
    this.seekInTimeLine(this.timePlayer);
    // if no media we trig the event, that the media is OK (don't wait for me)
    if (!this.isMediaAivailable) {
      this.onPlaying();
    }

  }

  @Watch('volume')
  private onChangeVolume() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();

    if (videoElement) {
      videoElement.volume = this.volume / 100;
    }
    if (oldVideoElement) {
      oldVideoElement.volume = this.volume / 100;
    }
  }

  @Watch('isVoiceOverPreview')
  private onVoiceOverPreviewChange() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();

    if (videoElement) {
      videoElement.muted = this.isVoiceOverPreview
    }
    if (oldVideoElement) {
      oldVideoElement.muted = this.isVoiceOverPreview
    }
  }

  @Watch('speedMode')
  private onSpeedChange() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();
    if (videoElement) {
      videoElement.playbackRate = this.speedMode;
    }
    if (oldVideoElement) {
      oldVideoElement.playbackRate = this.speedMode;
    }
  }

  private getPreloadingUrl(): string {
    const timeEvent = store.getters.getNextPlayerTimeEvent;
    if (!timeEvent || !timeEvent.secondMedia) {
      return '';
    }
    const media = this.getMediasForMediaId(timeEvent.secondMedia.mediaID);
    if (!media) {
      return '';
    }
    return getVideoURLForStatus(media) + '#t=' + Math.floor(timeEvent.secondMedia.currentTime / 1000);
  }

  get playerMedia(): PlayerMedia | null {

    if (this.mediaDescription !== undefined && this.mediaDescription && this.mediaDescription.mediaID !== '') {
      const media: Media | undefined = this.getMediasForMediaId(this.mediaDescription.mediaID);
      if (media === undefined) {
        // mediaNot found
        return null;
      }
      const playerMedia = new PlayerMedia(media);
      if (media.type === SourceVideoType.WEBCAM) {
        playerMedia.mirroring = true;
      }
      playerMedia.type = media.type;
      playerMedia.filter = this.mediaDescription.filter;

      if (media.blobURL && media.status != 'HD') {
        playerMedia.url = media.blobURL;
      } else {
        playerMedia.url = getVideoURLForStatus(media);
      }
      return playerMedia;
    } else {
      return null;
    }
  }

  @Watch('play')
  private togglePlay() {
    if (this.play) {
      this.startPlayer = true;
      this.refreshPlayState();
    } else {
      this.videoElement()?.pause();
    }

    this.animateFilter();
  }

  private async seekMediaToTimeLineTime(currentTimeEvent: TimeEvent) {
    const videoElement = this.videoElement();
    // 20 secondes - 5 (eventStart)
    const mediaMustBeInTime = this.timePlayer - currentTimeEvent.time + currentTimeEvent.secondMedia.currentTime;
    const secondValue = (mediaMustBeInTime / 1000);
    // if no duration, we cannot seek on the timeline, so we wait :-(
    let waiting = 0;
    while (waiting <= 50 && videoElement && videoElement.seekable.length === 0 && this.isMediaAivailable && isNaN(videoElement.duration)) {
      await delay(100);
      // console.log('Wait sticker');
      waiting++;
    }
    if (videoElement && videoElement.duration > secondValue) {
      videoElement.currentTime = secondValue;
      // console.log('Sticker Seek to ', secondValue, currentTimeEvent, this.timePlayer);
    }
  }


  /**
   * Refresh the status of the player if need
   */
  private refreshPlayState() {
    if (this.mediaDescription === undefined) {
      return;
    }
    const videoElement = this.videoElement();
    // we have to pause the video before the end, if not a wrong frame is played
    if (videoElement && videoElement.currentTime + 0.5 >= videoElement.duration) {
      // pause un X milliseconde to avoid return at the first frame 0.5
      const currentUrl = this.currentMedia?.url;
      clearTimeout(this.pauseEndTimeOutId);
      // @ts-ignore
      this.pauseEndTimeOutId = setTimeout(() => {
        // we checked if the video don't change
        if (currentUrl === this.currentMedia?.url) {
          // we recheck if the videoElement don't change after the timeOut
          if (videoElement && videoElement.currentTime + 0.5 >= videoElement.duration) {
            videoElement.pause();
          }
        }
      }, (videoElement.duration - videoElement.currentTime - 0.05) * 100);
      // stop function, to avoir replayed
      return;
    }
    // if the media must be running and
    // we are in playing status
    if (this.mediaDescription.running
        && this.play) {
      // we launched the play only if the video is paused
      if (videoElement && videoElement.paused) {
        log.debug('[Sticker -> refreshPlayState] => launch Stickers Play');
        videoElement.play().catch((e) => {
          log.debug(e.message);
        });
      }
    } else {
      if (videoElement && !videoElement.paused) {
        log.debug('[Sticker ->  refreshPlayState] => launch Stickers Pause');
        videoElement.pause();
      }
    }

    // we stop the old video
    this.restoreSetting();
  }

  private sendMediaTime() {
    const videoElement = this.videoElement();
    if (videoElement) {
      this.$emit('mediaTime', videoElement.currentTime * 1000);
    }
  }


  private timeOutWaiting: any = 0;

  private onPlaying() {
    // console.log('Clear');
    clearTimeout(this.timeOutWaiting);
    this.$emit('playing');
    // console.log('[STICKER] => Playing');
    this.restoreSetting();
  }

  private restoreSetting() {
    // we stop the old video
    this.oldVideoElement()?.pause();
    this.onChangeVolume();
    this.onSpeedChange();
  }

  private onWaiting(event) {
    const videoElement = this.videoElement();
    // if the time don't change in 500ms, we inform we are un waiting
    this.timeOutWaiting = setTimeout(() => {
      if (videoElement) {
        // need 2 secondes advance
        if (getBufferingAdvance(videoElement) < 3) {
          // console.log('[STICKER] => Waiting');
          // console.log('[STICKER] videoElement.url ' + videoElement.src);
          // console.log('[STICKER] videoElement.currentTime:' + videoElement.currentTime);
          // console.log('[STICKER] videoElement.display ' + videoElement.style.display);
          // console.log('[STICKER] video in progreess ', (event.target === this.videoElement()) ? 'true' : 'false');
          // console.log('[STICKER] ', this.smoothAlternatePlayer ? '1' : '2');
          this.$emit('waiting');
        } else {
          // console.log('Waiting ignored');
        }
      }
    }, 500);

  }

  /**
   * Remove url on destroy
   */
  private beforeDestroy() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();
    if (videoElement) {
      videoElement.src = '';
    }
    this.preloadUrl = '';
    if (oldVideoElement) {
      oldVideoElement.src = '';
    }
  }
}
</script>

<style lang="scss">
//* THIS STYLE MUST BE GLOBAL **/

.dragableSticker-handle {
  position: absolute;
  height: 0px;
  width: 0px;
  transition: all 300ms linear;
}

.dragableSticker-handle-tr {
  position: absolute;
  top: -12px;
  right: -12px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  color: white;
  cursor: nesw-resize;
  z-index: 11;
  background: rgba(0, 0, 0, 0.2);

  .iconResize {
    margin-top: 8px;
  }
}
</style>


<style lang="scss" scoped>
@import '@/scss/shadows.scss';


// commun
$stickerSize: 100%;
$stickerFullScreenSize: 230px;
$stickerFullScreenMonitorSize: 20vh;
.sticker {
  position: absolute;
  // border: 2px solid #FFFFFF;
  min-width: $stickerSize;
  min-height: $stickerSize;
  max-width: $stickerSize;
  max-height: $stickerSize;
  overflow: hidden;
  bottom: 0px;
  border-radius: 100% 100% 100% 16px;
  left: 0px;
  transition: all 0.5s;

  &:hover {
    opacity: 0.3;
  }

  &.minimize {
    transition: all 0.2s;
    transform: scale(0);
  }


  //fix issue in safari border-radius and overflow
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background: black;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  .stickerLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(-1.01) scaleY(1.01);
    transition: all 1s;


    .videoElement {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      animation-duration: 2s;
      width: 100%;
      height: 100%;
    }
  }

  &.fullscreenSticker {
    //  border: 3px solid #FFFFFF;
  }

  &.fullscreenPlayer {
  }

  &.fullscreenSticker.fullscreenPlayer {
  }


  .preloadingVideo {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
  }

}

.dragableSticker {
  border-radius: 100% 100% 100% 16px;
  border: none;
  cursor: move;
  transition: all 0.5s;
  @extend .stickersShadow;
}

.dragableSticker-active {
  @extend .shadow1;
}

.noAnimation {
  transition: none;
}


</style>
