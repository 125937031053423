import {Contact, GroupContact} from '@/store/contact/contactModel';
import {User} from '@/store/user/userModel';
import _ from 'lodash';

/**
 * Return all groups that contains the contact
 * @param groups, the initial groups to search in
 * @param contact, the contact to search in groups
 */
export const getGroupWithContact = (groups: GroupContact[], contact: Contact): GroupContact[] => {
  return groups?.filter((groupContact: GroupContact) => {
    return groupContact.members.find((member: User) => member.userID === contact.contactUser.userID) !== undefined;
  });
};

/**
 * Remove the contact from the groupContact members and return the new member list
 * @param groupContact
 * @param contact groupGeraudJeremyDimitry, contactGerJer
 */
export const getGroupMembersExceptContact = (groupContact: GroupContact, contact: Contact): User[] => {
  return groupContact.members = groupContact.members
    .filter((member: User) => member.userID !== contact.contactUser.userID);
};

/**
 * This function remove the contact from all groupSearched.
 * The function return the list to be deleted, which is the groups that only contains the contact to be deleted or the duplicate members group
 * WARNING, This function mutate directly the state value "groupSearched"
 *
 * @param stateGroupSearched
 * @param contact
 */
export const removeUserFromGroups = (stateGroupSearched: { [id: string]: GroupContact; }, contact: Contact): GroupContact[] => {
  const groupsSearched = Object.values(stateGroupSearched) as GroupContact[];
  const listOfGroupToBeDelete: GroupContact[] = [];
  for (const groupContact of getGroupWithContact(groupsSearched, contact)) {
    if (groupContact.members.length === 1) {
      // Delete Group if it contains only this contact
      listOfGroupToBeDelete.push(groupContact);
    } else {
      // Delete User from Group
      groupContact.members = getGroupMembersExceptContact(groupContact, contact);
      // IF another group already exist with the same members, then remove this group
      const allOtherGroups = groupsSearched.filter((grp: GroupContact) => grp.groupID !== groupContact.groupID);
      for (const group of allOtherGroups) {
        if (_.isEqual(group.members, groupContact.members)) {
          listOfGroupToBeDelete.push(groupContact);
        }
      }
    }
  }
  return listOfGroupToBeDelete;
};

