import {AuthorisationEnum} from '@/enum/AuthorisationEnum';

export const LOAD_WEET_FOR_PLAYING = 'LOAD_WEET_FOR_PLAYING';
export const ADD_A_WEET = 'ADD_A_WEET';
export const GET_ALL_WORKSPACE_WEETS = 'GET_ALL_WORKSPACE_WEETS';
export const RESET_WORKSPACE_WEET_LIST = 'RESET_WORKSPACE_WEET_LIST';
export const SHARE_A_WEET = 'SHARE_A_WEET';
export const DELETE_SHARE_A_WEET = 'DELETE_SHARE_A_WEET';
export const GET_EXPORTED_URL_WEET = 'GET_EXPORTED_URL_WEET';
export const REGENERATE_EXPORTED_URL_WEET = 'REGENERATE_EXPORTED_URL_WEET';
export const MODIFY_SHARE_A_WEET = 'MODIFY_SHARE_A_WEET';
export const MODIFY_OWNER_WEET = 'MODIFY_OWNER_WEET';
export const GET_WEET_SILENCE = 'GET_WEET_SILENCE';
export const REQUEST_UPGRADE_WORKSPACE_WEET = 'REQUEST_UPGRADE_WORKSPACE_WEET';
export const GET_WEET_PEOPLES = 'GET_WEET_PEOPLES';
export const EDIT_WEET = 'EDIT_WEET';
export const CREATE_WEET_TO_WORKSPACE = 'CREATE_WEET_TO_WORKSPACE';
export const DELETE_WEET_FROM_WORKSPACE = 'DELETE_WEET_FROM_WORKSPACE';


export class ShareParams {
    public weetID: string;
    public emails: string[];
    public allow: AuthorisationEnum;


    constructor(weetID: string, emails: string[] = [], allow: AuthorisationEnum) {
        this.weetID = weetID;
        this.emails = emails;
        this.allow = allow;
    }
}

export class ActionUserShareParams {
    public weetID: string;
    public userID: string;

    constructor(weetID: string, userID: string) {
        this.weetID = weetID;
        this.userID = userID;
    }
}
