import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  GET_AUTHORIZATION_CODE,
  AUTHORIZATION_CODE_SUCCESS,
  CHECK_CONNECTED_TOKEN_EXTERNAL_USER
} from '@/store/identity/identityAction';
import {FROM_EXTERNAL_TOOL_URL} from '@/store/integration/integrationAction';
import {AuthorizationCodeParams, Token} from '@/store/identity/identityModel';
import {UPDATE_WORKSPACE} from '@/store/workspace/workspaceAction';
import store from '@/store';
import {selectWorkspace} from '@/utils/workspaceUtil';

const identityAPI = BACK_ENDPOINT + '/identity';

export default {
  state: {
    authorizationCode: '',
    status: '',
    // url of the external tool to send message to it
    externalToolURL: ''
  },
  mutations: {
    [AUTHORIZATION_CODE_SUCCESS]: (state: any, {
      authorizationCode
    }: { authorizationCode: string }) => {
      state.status = 'success';
      state.authorizationCode = authorizationCode;
    },
    [FROM_EXTERNAL_TOOL_URL]: (state: any, externalToolURL: string) => {
      state.externalToolURL = externalToolURL;
    }

  },
  getters: {
    getIdentityAuthorizationCode: (state: any) => state.authorizationCode,
    getIdentityStatus: (state: any): string => state.status,
    getExternalToolURL: (state: any): string => state.externalToolURL
  },
  actions: {
    async [GET_AUTHORIZATION_CODE]({
                                     commit,
                                     dispatch,
                                     getters
                                   }: { commit: any, dispatch: any, getters: any },
                                   authorizationCodeParams: AuthorizationCodeParams) {
      return await axios({
        url: identityAPI + '/authorization',
        data: authorizationCodeParams,
        method: 'POST',
      }).then((resp) => {
        return resp.data?.code;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [CHECK_CONNECTED_TOKEN_EXTERNAL_USER]({
                                                  commit,
                                                  dispatch,
                                                  getters
                                                }: { commit: any, dispatch: any, getters: any },
                                                token: Token) {
      return await axios({
        url: identityAPI + '/checkTokenAndUserConnected',
        data: token,
        method: 'POST',
      }).then((resp) => {
        const workspace = resp.data;

        commit(UPDATE_WORKSPACE, workspace);
        // we have to make sure that we are in the correct workspace
        if (store.getters.getSelectedWorkspaceID !== workspace.workspaceID) {
          selectWorkspace(workspace.workspaceID);
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    [FROM_EXTERNAL_TOOL_URL](
      {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
      externalToolURL: string
    ) {
      commit(FROM_EXTERNAL_TOOL_URL, externalToolURL);
    }

  },
};
