<template>
  <div class="channel" @click="selectAChannel(channel.channelID)" v-if="isExist"
       :class="{'selectable': isSelectable ,'notify': isNotify}">
    <span class="iconChannel"><w-icon :icon="isPrivate?'lock-outline':'pound'" size="small"/></span>
    {{ updatedLabel }}<span v-if="notifiable"> ({{ channel.numberOfUser }})</span>

    <span class="iconDeleteChannel" v-if="deleteAction" @click="sendDelete"><w-icon icon="close-circle"
                                                                                    size="small"/></span>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {Channel} from '@/store/channel/channelModel';
import {MY_LIST_WEET, MyWeetListParams, REINIT_MY_WEET} from '@/store/myWeet/myWeetAction';
import store from '@/store';
import {SELECT_WORKSPACE_CHANNEL} from '@/store/channel/channelAction';
import router from '@/router';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';

@Component({
  components: {
    WIcon
  },
})
export default class ChannelWorkspace extends Vue {
  @Prop()
  private channel!: Channel;

  @Prop({default: true})
  private selectable!: boolean;

  @Prop({default: true})
  private notifiable!: boolean;

  @Prop({default: false})
  private deleteAction!: boolean;

  private routeSelected: string = '';


  get isPrivate(): boolean {
    return this.channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  get isExist(): boolean {
    return !!store.getters.getChannel(this.channel.channelID);
  }

  get isNotify(): boolean {
    const notifications = store.getters.getWeetTypeUnreadNotification;
    for (const notif of notifications) {
      if (this.notifiable && notif.channels && notif.channels.includes(this.channel.channelID)) {
        return true;
      }
    }
    return false;
  }

  get updatedLabel(): string {
    const channel = store.getters.getChannel(this.channel.channelID);
    if (channel) {
      return channel.name;
    } else {
      return this.channel.name;
    }
  }

  get isSelectable(): boolean {
    return this.selectable && this.isSubscribe;
  }

  get isSubscribe(): boolean {
    for (const subChannel of store.getters.getSubscriptionChannels) {
      if (subChannel.channelID === this.channel.channelID) {
        return true;
      }
    }
    return false;
  }

  @Watch('$route', {immediate: true, deep: true})
  private onRouteChange(route: any) {
    // manage the #CHANNEL
    this.routeSelected = route.name;
  }

  private selectAChannel(channel) {
    if (this.isSelectable) {
      const params = new MyWeetListParams();
      params.tagFilter = '';
      // Unselect workspace channel if any
      params.channelFilter = channel;
      store.dispatch(SELECT_WORKSPACE_CHANNEL);
      store.dispatch(REINIT_MY_WEET, params);
      store.dispatch(MY_LIST_WEET, params);
      if (this.routeSelected !== 'MyWeets') {
        router.push({name: 'MyWeets'});
      }
    }
  }

  private sendDelete() {
    this.$emit('delete', this.channel);
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';

.channel {
  background: transparent;
  color: var(--black);
  margin-right: 4px;
  border-radius: 16px;
  font-size: 14px;
  padding-right: 8px;
  padding-left: 8px;
  background: var(--light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //background: var(--light);
  // padding-left: 12px;
  // padding-right: 16px;

  .iconChannel {
    margin-right: 0px;
    color: var(--black);
  }

  .iconDeleteChannel {
    border-radius: 20px;
    color: var(--light1);
    cursor: pointer;

    &:hover {
      color: var(--light2);
    }
  }

  &.notify {
    background: var(--light);
    font-weight: 900;
    color: var(--black);

    .iconChannel {
      color: var(--black);
    }
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background: var(--light1);
      color: var(--black);

      .iconChannel {
        color: var(--black);
      }
    }
  }


}

</style>
