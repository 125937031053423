import {Weet} from '@/store/weet/weetModel';
import {User} from '@/store/user/userModel';
import store from '@/store';
import {AuthorisationEnum} from '@/enum/AuthorisationEnum';
import {LIST_OF_QUEUE} from '@/store/media/mediaModule';
import Queue from 'queue';
import {Media} from '@/store/media/mediaModel';
import {SectionEventDescriptor, Segment, TimeEvent} from '@/store/timeLine/timeEvent';
import {isChromium, isEmbedInTeams, isTabletOrMobile} from '@/utils/extentionUtil';
import {
    PREPOPULATE_SHARED_EMAILS,
    PREPOPULATE_WEET_TITLE,
    RECORD_MAIN_VIDEO_TYPE, RECORD_STICKER_MUTE, RECORD_STICKER_POSITION, RECORD_STICKER_VIDEO_MUTE,
    SET_CHANNELS_FOR_EDITING,
    SET_CURRENT_EDITING_WEET,
    SHOW_POPUP_CREATION,
} from '@/store/recordingState/recordStateAction';
import copy from 'copy-to-clipboard';
import slugify from '@sindresorhus/slugify';
import {PUBLIC_PATH, SUBTITLES_MANAGEMENT_ENDPOINT} from '@/store/conf/api';
import {CLEAR_TIME_LINE, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {CLEAR_MEDIA} from '@/store/media/mediaAction';
import {TimeEventType} from '@/enum/TimeEventType';
import {clone, getUserAvatar, htmliFy} from '@/utils/util';
import prettyMilliseconds from 'pretty-ms';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import sortBy from 'lodash/sortBy';
import {CommentPolicy} from '@/store/weet/CommentPolicy';
import {
    FROM_EXTERNAL_TOOL_URL,
    FROM_EXTERNALTOOL,
    PREPOPULATE_EXTERNALTOOL_REFERENCE
} from '@/store/integration/integrationAction';
import {embededInExternalIframe, notifyWeetCreationInTeamsNotSupported} from '@/utils/integrationUtil';
import {i18n} from '@/utils/i18nUtils';
import {alertMessage, informError} from '@/utils/dialog';
import {canCreateWeet, canEditWorkspace} from '@/utils/workspaceUtil';
import router from '@/router';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {isTestMode} from "@/utils/CypressUtil";
import {SUBTITLES_MANAGEMENT_AUTHORISATION} from "@/store/subtitles/SubtitlesAction";
import {Debug} from "@sentry/integrations";
import {REGENERATE_EXPORTED_URL_WEET} from "@/store/weet/weetAction";
import {isSpeach} from "@/utils/speachUtils";
import {cloneDeep} from "lodash";
import stringify from 'json-stable-stringify';


export const goToSubtitleService = async (weet: Weet,callback:string="/play") => {
    if(isSpeach()){
        callback=PUBLIC_PATH+"editEmbed";
    }
    // ask for authorisation
    const token = await store.dispatch(SUBTITLES_MANAGEMENT_AUTHORISATION, {weetID:weet.weetID,callback:callback});
    window.location.href = SUBTITLES_MANAGEMENT_ENDPOINT + "?token=" + encodeURIComponent(token);
}
export const canEditThisWeet = (weet: Weet | null, user: User | null = null, allowAdmin: boolean = true,ignoreArchive:boolean=false): boolean => {
    if (user === null) {
        user = store.getters.userConnected;
    }
    // if no user connected
    if (user && weet) {
        if(weet.archive && !ignoreArchive){
            return false;
        }
        if (canEditWorkspace() && allowAdmin) {
            return true;
        }
        for (const owner of weet.owners) {
            if (owner.user.userID === user.userID && owner.allow === AuthorisationEnum.EDIT) {
                return true;
            }
        }
    }
    return false;
};

export const isOwner = (weet: Weet, user: User | null = null): boolean => {
    if (user === null) {
        user = store.getters.userConnected;
    }
    // if no user connected
    if (user && weet) {
        for (const owner of weet.owners) {
            if (owner.user.userID === user.userID && owner.owner) {
                return true;
            }
        }
    }
    return false;
};

export const isCoowner = (weet: Weet, user: User | null = null): boolean => {
    if (user === null) {
        user = store.getters.userConnected;
    }
    // if no user connected
    if (user && weet) {
        for (const owner of weet.owners) {
            if (owner.user.userID === user.userID) {
                return true;
            }
        }
    }
    return false;
};

export const getOwner = (weet: Weet): User | undefined => {
    if (weet) {
        for (const owner of weet.owners) {
            if (owner.owner) {
                return owner.user;
            }
        }
    }
    return undefined;
};


export const canCommentThisWeet = (weet: Weet, user: User | null = null): boolean => {

    if (user === null) {
        user = store.getters.userConnected;
    }
    if (weet.commentPolicy === CommentPolicy.NOBODY_CAN_COMMENT) {
        return false;
    }
    if (user && weet.commentPolicy === CommentPolicy.USER_CAN_COMMENT) {
        return true;
    }
    // if no user connected
    if (user) {
        if (user.settings) {
            for (const owner of weet.owners) {
                if (owner.user.userID === user.userID && (owner.allow === AuthorisationEnum.EDIT
                    || AuthorisationEnum.COMMENT)) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const canEditOrCommentThisWeet = (weet: Weet, user: User | null = null): boolean => {
    return canEditThisWeet(weet, user) || canCommentThisWeet(weet, user);
};


export const numberOfFileSync = (): number => {
    let i = 0;
    for (const obj of Object.values(LIST_OF_QUEUE)) {
        const queueMedia = obj as Queue;
        i += queueMedia.length;
    }
    return i;
};

export const getUploadMediaChunkToSync = (mediaID: string): number => {
    const i = 0;
    const queueMedia = LIST_OF_QUEUE[mediaID];
    if (queueMedia) {
        return queueMedia.length;
    } else {
        return 0;
    }
};

export const getMediasForWeet = (weet: Weet, mediaID: string): Media | undefined => {
    for (const media of weet.medias) {
        if (media.mediaID === mediaID) {
            return media;
        }
    }
};

export const isWeetIsReady = (weet: Weet): boolean => {
    if (!weet) {
        return false;
    }
    const valideMedia = (media: Media | undefined): boolean => {
        if (media) {
            if (media.status !== 'HD' && !media.blobURL) {
                return false;
            }
        }
        return true;
    };
    for (const timeEvent of weet.timeLine) {
        if (!valideMedia(getMediasForWeet(weet, timeEvent.mainMedia.mediaID))) {
            return false;
        }
        if (!valideMedia(getMediasForWeet(weet, timeEvent.secondMedia.mediaID))) {
            return false;
        }
    }
    // for (const media of weet.medias) {
    //   if (media.status !== 'HD' && !media.blobURL) {
    //     return false;
    //   }
    // }
    return true;
};


export const progressWeetProceed = (weet: Weet): number => {
    let valueMax = 0;
    for (const media of weet.medias) {
        if (media.status !== 'HD' && !media.blobURL) {
            valueMax += media.progress;
        } else {
            valueMax += 100;
        }
    }

    return Math.round(valueMax / weet.medias.length);
};


export const getWeetSegment = (weet: Weet): Segment[] => {
    const segments: Segment[] = [];

    let startEvent;
    for (const time of weet.timeLine) {
        if (time.type === TimeEventType.MEDIA_PLAY && !startEvent) {
            startEvent = time;
        } else if (time.type === TimeEventType.MEDIA_PAUSE ||
            time.type === TimeEventType.MEDIA_PLAY) {
            segments.push(new Segment(startEvent, time));
            startEvent = time;
        }
    }
    return segments;
};

export const getWeetSection = (weet: Weet, withComment: boolean = false, withStep: boolean = false): SectionEventDescriptor[] => {
    const sections: SectionEventDescriptor[] = [];
    // ADD STEP in TIMELINE
    for (const timeEvent of weet.timeLine) {
        if (timeEvent.type === TimeEventType.MEDIA_PLAY ||
            timeEvent.type === TimeEventType.STEP) {

            // Desactivated for the moment


            if (withStep) {
                const section: SectionEventDescriptor = new SectionEventDescriptor();
                section.time = timeEvent.time;
                section.type = TimeEventType.STEP;
                section.thumbType = 'sticker';
                let media: Media | undefined;
                if (timeEvent.secondMedia.mediaID) {
                    media = getMediasForWeet(weet, timeEvent.secondMedia.mediaID);
                } else {
                    media = getMediasForWeet(weet, timeEvent.mainMedia.mediaID);
                }

                if (media && media.creator && isCoowner(weet, media.creator)) {
                    section.image = getUserAvatar(media.creator, 128);
                    // const titleEvent = getTitleTimeEventEvent(weet.timeLine, timeEvent);
                    // if (titleEvent) {
                    //     section.tooltip = '@' + getHumanTime(section.time) + ': ' + titleEvent.value;
                    // } else {
                    //     section.tooltip = '@' + getHumanTime(section.time) + '  ' + media.creator.firstName;
                    // }
                    section.key = TimeEventType.STEP + timeEvent.time + media.creator.userID;
                    section.userID = media.creator.userID;
                    section.creator = media.creator;
                    section.media = media;
                    if (timeEvent.mainMedia && timeEvent.mainMedia.mediaID) {
                        section.mainMedia = getMediasForWeet(weet, timeEvent.mainMedia.mediaID);
                    }
                } else {
                    section.image = getUserAvatar(undefined, 128);
                    // const titleEvent = getTitleTimeEventEvent(weet.timeLine, timeEvent);
                    // if (titleEvent) {
                    //     section.tooltip = '@' + getHumanTime(section.time) + ': ' + titleEvent.value;
                    // } else {
                    //     section.tooltip = '@' + getHumanTime(section.time);
                }
                sections.push(section);
            }
        } else if (timeEvent.type === TimeEventType.CHAPTER_TITLE) {
            const section: SectionEventDescriptor = new SectionEventDescriptor();
            section.time = timeEvent.time;
            section.type = TimeEventType.CHAPTER_TITLE;
            section.thumbType = 'point';
            section.key = TimeEventType.CHAPTER_TITLE + timeEvent.time;
            section.tooltip = '@' + getHumanTime(section.time) + ': ' + timeEvent.value;
            sections.push(section);
        }
    }
    if (withComment) {
        for (const comment of store.getters.getAgregateComments) {
            const section: SectionEventDescriptor = new SectionEventDescriptor();
            section.time = comment.time;
            section.type = comment.type;
            section.thumbType = 'default';
            section.commentID = comment.commentID;
            section.userID = comment.user?.userID;
            section.key = comment.commentID;

            if (comment.type === CommentTypeEnum.EMOTE) {
                section.image = comment.imageUrl;
                section.thumbType = 'none';
            } else {
                section.image = getUserAvatar(comment.user, 128);
            }
            if (comment.type === CommentTypeEnum.TEXT) {
                section.tooltip += ': ' + htmliFy(comment.text);
            }
            if (comment.type === CommentTypeEnum.WEBCAM ||
                comment.type === CommentTypeEnum.SCREEN) {
                section.tooltip += ': 📺';
            }
            if (comment.user) {
                section.tooltip = '@' + getHumanTime(section.time) + '  ' + comment.user.firstName;
                section.name = '@' + getHumanTime(section.time) + '  ' + comment.user.firstName;
            } else {
                section.tooltip = '@' + getHumanTime(section.time);
                section.name = '@' + getHumanTime(section.time);
            }

            sections.push(section);
        }
    }

    return sortBy(sections, ['time', 'created']);
};

export const getHumanTime = (val: number): string => {
    if (val < 1000) {
        return '0:00';
    } else {
        return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
};


export const getWeetDuration = (weet: Weet): number => {
    if (weet.timeLine.length > 0) {
        return weet.timeLine[weet.timeLine.length - 1].time;
    } else {
        return 0;
    }
};

export const reinitRecorder = (weetID: string = '') => {
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, RecordingState.NONE);
    store.dispatch(SET_CURRENT_EDITING_WEET, weetID);
    store.dispatch(CLEAR_MEDIA);
    store.dispatch(SET_CHANNELS_FOR_EDITING, []);
    store.dispatch(CLEAR_TIME_LINE);
    muteMicro(false);
    muteWebcam(false);
    store.dispatch(RECORD_STICKER_POSITION, StickerPosition.BOTTOM_LEFT);
};

export const launchWeetCreation = (weetID: string = '', weetTitle = '', sharedEmails: string[] = [],
                                   externalToolReference: string = '', externalTool: string = '', audURL = '') => {
    if (isChromium() && notifyWeetCreationInTeamsNotSupported(weetTitle)) {
        return;
    } else if (!isChromium() || isTabletOrMobile()) {
        if (isEmbedInTeams()) {
            alertMessage('Sorry, Weet app does not support capturing of video and audio on Mobile, ' +
                'please use Weet screen recording on Desktop/Web Teams app for creation of a Weet. Feel free to <a href="https://help.weet.co">contact us for help</a>',
                'Weet app on Teams');
        } else {
            // IF browser is not chromium and the weet creation is not running from MsTeams Desktop
            alertMessage('Oops! We\'re sorry but this browser is not compatible. Weet works with Chrome or Edge on desktop.',
                '😥 Oops...');
        }
        return;
    }
    if (!canCreateWeet() && !weetID) {
        alertMessage('Sorry, you can\'t create a weet on this workspace.', '😥 Oops...');
        return;
    }
    if (weetID === '') {
        store.dispatch(TIMER_RECORD, 0);
    }
    if (weetTitle !== '') {
        store.dispatch(PREPOPULATE_WEET_TITLE, weetTitle);
    }
    if (sharedEmails.length > 0) {
        store.dispatch(PREPOPULATE_SHARED_EMAILS, sharedEmails);
    }
    if (weetID !== '' && canEditWorkspace() && !canEditThisWeet(store.getters.getWeet(weetID), null, false)) {
        if(!isSpeach()) {
            informError(i18n.t('workspace.manageWeets.doNotAbuse').toString(), 'top');
        }
    }
    store.dispatch(FROM_EXTERNALTOOL, externalTool);
    store.dispatch(PREPOPULATE_EXTERNALTOOL_REFERENCE, externalToolReference);
    store.dispatch(FROM_EXTERNAL_TOOL_URL, audURL);

    reinitRecorder(weetID);

    store.dispatch(SHOW_POPUP_CREATION, true);

    if (embededInExternalIframe()) {
        // Do something if you need to enable or disabled on an external Tool
    }
};


export const weetLink = (weet: Weet) => {
    return document.location.origin + '/play/' + weet.weetID + '/' + slugify(weet.title);
};
export const weetEditEmbedLink = (weet: Weet) => {
    return document.location.origin + '/editEmbed/' + weet.weetID;
};

export const weetEmbedLink = (weet: Weet) => {
    return document.location.origin + '/embed/' + weet.weetID + '/' + slugify(weet.title);
};

export const copyLink = (weet: Weet) => {
    if (!isTestMode()) {
        copy(weetLink(weet));
    }
};

export const getCommentPolicyList = (): any[] => {
    return [
        {
            value: CommentPolicy.USER_CAN_COMMENT,
            label: i18n.t('commentPolicy.userCanComment')
        },
        {
            value: CommentPolicy.COOWNER_CAN_COMMENT,
            label: i18n.t('commentPolicy.coownerComment')
        }];
};


/**
 * Return the firstName of the co-owner to dipslay in the tooltip, if the current (connected) user has edit right
 * @param user, the co-owner
 * @param weet, the weet on which [user] is a co-owner
 */
export const userFirstNameTooltip = (user: User, weet: Weet): string => {
    if (isCoowner(weet)) {
        return user.firstName;
    }
    return '';
};

/**
 * Return the lastname of the co-owner to dipslay in the tooltip, if the current (connected) user has edit right
 * @param user, the co-owner
 * @param weet, the weet on which [user] is a co-owner
 */
export const userLastNameTooltip = (user: User, weet: Weet): string => {
    if (isCoowner(weet)) {
        return user.lastName;
    }
    return '';
};

/**
 * Allow to create a fake weet with the recorderState
 * @param weetId
 */
export const createUnsavedEditingWeet = (weetId: string): Weet => {
    const weet = new Weet();
    weet.timeLine = store.getters.getTimeEvent;
    weet.medias = store.getters.getMedias;
    weet.weetID = weetId;
    const weetStore = store.getters.getMyWeet(store.getters.getEditingWeetID);
    if (weetStore) {
        weet.title = weetStore.title;
        weet.owners = weetStore.owners;
        weet.channels = weetStore.channels;
        weet.visibilityPolicy = weetStore.visibilityPolicy;
        weet.commentPolicy = weetStore.commentPolicy;
        weet.emotePolicy = weetStore.emotePolicy;
        weet.thumbnail = weetStore.thumbnail;
        weet.thumbnailManual = weetStore.thumbnailManual;
        weet.progressExport = weetStore.progressExport;
        weet.exportAivailable = weetStore.exportAivailable;
        weet.subtitles = weetStore.subtitles;
        weet.annotations = weetStore.annotations;
    }
    return weet;
};

export const getFirstLinesTranscriptFromWeet = (weet: Weet): string => {
    // we search the event with 0
    if (weet.timeLine.length > 0) {
        const timeEvent = weet.timeLine[0];
        let mediaId = '';
        if (timeEvent.secondMedia.mediaID !== undefined && timeEvent.secondMedia.mediaID !== '') {
            mediaId = timeEvent.secondMedia.mediaID;
        } else {
            mediaId = timeEvent.mainMedia.mediaID;
        }
        const words = store.getters.getWordsForMedia(mediaId);
        return words.map((w) => w.text).join(' ').substring(0, 250);
    }
    return '';
};

export function goToMyWeets(replace: boolean = false) {
    if (replace) {
        router.replace({name: 'MyWeets'});
    } else {
        router.push({name: 'MyWeets'});
    }
}

export const muteMicro = (mute: boolean = true) => {
    if (mute) {
        if (!store.getters.isStickerMute) {
            store.dispatch(RECORD_STICKER_MUTE);
        }
    } else {
        if (store.getters.isStickerMute) {
            store.dispatch(RECORD_STICKER_MUTE);
        }
    }
};

export const muteWebcam = (mute: boolean = true) => {
    if (mute) {
        if (!store.getters.isStickerVideoMute) {
            store.dispatch(RECORD_STICKER_VIDEO_MUTE);
        }
    } else {
        if (store.getters.isStickerVideoMute) {
            store.dispatch(RECORD_STICKER_VIDEO_MUTE);
        }
    }
};

export const checkExportedVersion =(weet:Weet)=>{
    if(weet.exportAivailable && weet.progressExport<=0){
        store.dispatch(REGENERATE_EXPORTED_URL_WEET, {weetID: weet.weetID, download: true})
    }
}

export const isWeetEquals=(weet1:Weet,weet2:Weet)=>{

    const cleanWeet=(weetToClean:Weet)=>{
        // remove workspace
        weetToClean.workspaces=[];

        // remove owner
        weetToClean.owners=[];
        weetToClean.thumbnailURL="";
        //creations date
        // @ts-ignore (force removing date)
        weetToClean.created=null;

        // download and stat
        weetToClean.downloads=0;
        weetToClean.viewers=0;
        weetToClean.viewers=0;

        // channels
        weetToClean.channels=[];

        // progress download
        weetToClean.exportAivailable=false;
        weetToClean.progressExport=0;
        return weetToClean;
    }

    const weet1Cloned=cleanWeet(clone(weet1));
    const weet2Cloned=cleanWeet(clone(weet2));
    return stringify(weet1Cloned)===stringify(weet2Cloned)

}
