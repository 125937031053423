import EmojData from 'emoji-mart-vue-fast/data/apple.json';
import {EmojiIndex} from 'emoji-mart-vue-fast';

export const SIMPLE_REGEX = new RegExp(
  '([\\s]|^)((<[\\/\\\\]?3)|([-^][_]?[-^])|(\\([ny]\\))|([,0Oo]?[:;=B8][,]?[\']?[\\-^"o]?[3DO0Ppob@$*\\\\)(\\/|>]))([\\s]|$)',
  'g',
);

export const EMO_DATA: EmojiIndex = new EmojiIndex(EmojData);

export const MAP_OF_EMOJ = {
  ':(': '🙁',
  ':)': '🙂',
  ':P': '😛',
  ':p': '😛',
  ':O': '😮',
  ':3': '😖',
  ':|': '😐',
  ':/': '😕',
  ':\\': '😕',
  ':$': '🤑',
  ':*': '😘',
  ':@': '😡',
  ':-(': '🙁',
  ':-)': '🙂',
  ':-P': '😛',
  ':-p': '😛',
  ':-O': '😮',
  ':-3': '😖',
  ':-|': '😐',
  ':-/': '😕',
  ':-\\': '😕',
  ':-*': '😘',
  ':-@': '😡',
  ':^(': '🙁',
  ':^)': '🙂',
  ':^P': '😛',
  ':^p': '😛',
  ':^O': '😮',
  ':^3': '😖',
  ':^|': '😐',
  ':^/': '😕',
  ':^\\': '😕',
  ':^$': '🤑',
  ':^*': '😘',
  ':^@': '😡',
  '<3': '❤️',
  '</3': '💔',
  '<\\3': '💔',
  ':D': '😀',
  ':-D': '😀',
  ':^D': '😀',
  ':o': '😮',
  ':-o': '😮',
  ':^o': '😮',
  ':0': '😮',
  ':-0': '😮',
  ':^0': '😮',
  ';)': '😉',
  ';-)': '😉',
  ';^)': '😉',
  ',:(': '😓',
  ',:-(': '😓',
  ',:^(': '😓',
  ',:)': '😅',
  ',:-)': '😅',
  ',:^)': '😅',
  '0:)': '😇',
  '0:-)': '😇',
  '0:^)': '😇',
  'o:)': '😇',
  'o:-)': '😇',
  'o:^)': '😇',
  'O:)': '😇',
  'O:-)': '😇',
  'O:^)': '😇',
  '8)': '😎',
  '8-)': '😎',
  '8^)': '😎',
  'B)': '😎',
  'B-)': '😎',
  'B^)': '😎',
  ':")': '😊',
  ':\')': '😂',
  ':\'-)': '😂',
  ':\'^)': '😂',
  ':\'D': '😂',
  ':\'-D': '😂',
  ':\'^D': '😂',
  ':,)': '😂',
  ':,-)': '😂',
  ':,^)': '😂',
  ':,D': '😂',
  ':,-D': '😂',
  ':,^D': '😂',
  ':\'(': '😢',
  ':\'-(': '😢',
  ':\'^(': '😢',
  ':,(': '😢',
  ':,-(': '😢',
  ':,^(': '😢',
  ':,\'(': '😭',
  ':,\'-(': '😭',
  ':,\'^(': '😭',
  ':b': '😛',
  ':-b': '😛',
  ':^b': '😛',
  ';b': '😜',
  ';-b': '😜',
  ';^b': '😜',
  ';p': '😜',
  ';-p': '😜',
  ';^p': '😜',
  ';P': '😜',
  ';-P': '😜',
  ';^P': '😜',
  ':>': '😆',
  ':->': '😆',
  ':^>': '😆',
  '=D': '😃',
  '=-D': '😃',
  '=^D': '😃',
  '=)': '😃',
  '=-)': '😃',
  '=^)': '😃',
  '^_^': '😄',
  '^^': '😄',
  '-_-': '😑',
  ':o)': '🐵',
  '(y)': '👍',
  '(n)': '👎'
};


export const replaceEmoJParenthesisByNative = (text: string): string => {
  return text.replace(SIMPLE_REGEX, (match) => {
    const emoji = MAP_OF_EMOJ[match.trim()];
    if (!emoji) {
      return match;
    }
    return (new RegExp('^\\s').test(match) ? match.substring(0, 1) : '') + emoji + ' ';
  });
};
