import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    DELETE_CONTACT,
    DELETE_GROUP,
    GET_LIST_OF_GROUP_CONTACT,
    RESET_CONTACT_AND_GROUP_SEARCHED,
    SEARCH_CONTACT,
    SEARCH_GROUP
} from '@/store/contact/contactAction';
import Vue from 'vue';
import {Contact, GroupContact} from '@/store/contact/contactModel';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import {v4 as uuidv4} from 'uuid';
import sortBy from 'lodash/sortBy';
import {removeUserFromGroups} from '@/utils/groupContactUtil';

const contactAPI = BACK_ENDPOINT + '/contacts';
const groupAPI = contactAPI + '/groups';

export default {
    state: {
        contactSearched: {},
        groupSearched: {},
        groupsContact: {},
        groupContactPage: 0,
        groupContactTotal: 0,
        groupLoadinIdentifier: uuidv4(),
    },
    mutations: {
        [SEARCH_CONTACT]: (state: any, contacts: Contact[]) => {
            state.contactSearched = {};
            contacts.forEach((contact) => {
                Vue.set(state.contactSearched, contact.contactUserID, contact);
            });
        },
        [SEARCH_GROUP]: (state: any, groups: GroupContact[]) => {
            state.groupSearched = {};
            groups.forEach((group) => {
                Vue.set(state.groupSearched, group.groupID, group);
            });
        },
        [GET_LIST_OF_GROUP_CONTACT]: (state: any, paginationWrapper: PaginationWrapper) => {
            // Don't need to clean the panel we don't car if a group disapear
            const groupsContact = paginationWrapper.data as GroupContact[];
            for (const i in groupsContact) {
                if (groupsContact[i] !== undefined) {
                    Vue.set(state.groupsContact, groupsContact[i].groupID, groupsContact[i]);
                }
            }
            state.groupContactPage = paginationWrapper.page;
            state.groupContactTotal = paginationWrapper.count;
        },
        [DELETE_GROUP]: (state: any, group: GroupContact) => {
            Vue.delete(state.groupSearched, group.groupID);
        },
        [RESET_CONTACT_AND_GROUP_SEARCHED]: (state: any) => {
            state.contactSearched = {};
            state.groupSearched = {};
            state.groupContactPage = 0;
            state.groupContactTotal = 0;
        },
        [DELETE_CONTACT]: (state: any, contact: Contact) => {
            // Delete the contact from contact list
            Vue.delete(state.contactSearched, contact.contactUserID);
            // Remove the contact from groups
            removeUserFromGroups(state.groupSearched, contact).forEach((groupContact: GroupContact) => {
                Vue.delete(state.groupSearched, groupContact.groupID);
            });
        },
    },
    getters: {
        getGroupSearched: (state: any): GroupContact[] => {
            return sortBy(Object.values(state.groupSearched), ['lastActivity']).reverse() as GroupContact[];
        },
        getContactSearched: (state: any): Contact[] => {
            return Object.values(state.contactSearched);
        },
        getGroupContacts: (state: any): GroupContact[] => {
            return Object.values(state.groupsContact);
        },
        getGroupContact: (state: any) => (groupID: string): GroupContact => {
            return state.groupsContact[groupID];
        },
        getTotalGroupContact: (state: any): number => {
            return state.groupContactTotal;
        },
        getPageGroupContact: (state: any): number => {
            return state.groupContactPage;
        },
        getGroupLoadinIdentifier: (state: any): string => {
            return state.groupLoadinIdentifier;
        },
    },
    actions: {
        async [SEARCH_GROUP]({commit, dispatch}: { commit: any, dispatch: any }, {
            emailRegex,
        }: { emailRegex: string, workspaceID?: string }) {
            if (emailRegex) {
                await axios({
                    url: `${groupAPI}/search/${emailRegex}`,
                    method: 'GET',
                }).then((resp) => {
                    commit(SEARCH_GROUP, resp.data);
                }).catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
            }
        },
        async [SEARCH_CONTACT]({commit, dispatch}: { commit: any, dispatch: any }, {
            emailRegex,
        }: { emailRegex: string, workspaceID?: string }) {
            if (emailRegex) {
                await axios({
                    url: contactAPI + '/search/' + emailRegex,
                    method: 'GET',
                }).then((resp) => {
                    commit(SEARCH_CONTACT, resp.data);
                }).catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
            }
        },
        async [GET_LIST_OF_GROUP_CONTACT]({commit, dispatch}: { commit: any, dispatch: any }, page: number) {
            await axios({
                url: groupAPI,
                params: {page: page},
                method: 'GET',
            }).then((resp) => {
                commit(GET_LIST_OF_GROUP_CONTACT, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_GROUP]({commit, dispatch}: { commit: any, dispatch: any }, group: GroupContact) {
            return await axios({
                url: `${groupAPI}/${group.groupID}`,
                method: 'DELETE',
            }).then((resp) => {
                commit(DELETE_GROUP, group);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [RESET_CONTACT_AND_GROUP_SEARCHED]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RESET_CONTACT_AND_GROUP_SEARCHED);
        },
        async [DELETE_CONTACT]({commit, dispatch}: { commit: any, dispatch: any }, contact: Contact) {
            return await axios({
                url: `${contactAPI}/${contact.contactUserID}`,
                data: contact,
                method: 'DELETE',
            }).then((resp) => {
                commit(DELETE_CONTACT, contact);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        }
    },
};
