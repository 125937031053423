<template>

</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Workspace} from '@/store/workspace/workspaceModel';
import {loadTheme, Theme} from '@/theme';
import {getImageURLForSize} from "@/utils/util";
import {manageThemeForWorkspace} from "@/utils/workspaceUtil";

@Component({
  components: {}
})
export default class ThemeSelector extends Vue {

  get workspaceSelected(): Workspace | null {
    return store.getters.getSelectedWorkspace;
  }

  @Watch('workspaceSelected')
  private selectTheme() {
    if (this.workspaceSelected) {
      // if theme valide, we load it
      if ((Object as any).values(Theme).includes(this.workspaceSelected.theme)) {
        manageThemeForWorkspace(this.workspaceSelected);
        return;
      }
    }
    manageThemeForWorkspace(undefined);
  }

}
</script>
