<template>
  <div>
    <transition name="fade">
      <w-button data-test="recorderPauseButton" color="light" style="animation-duration:  0.7s;" icon="stop"
                @click="relaunchRecord" v-show="isRecordingPause">{{ label }}
      </w-button>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import {PAUSE_RECORDING} from '@/store/recordingState/recordStateAction';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WButton},
})
export default class RecorderPauseButton extends Vue {

  @Prop({default: false})
  private mini!: boolean;

  // @Prop() private logo!: string;
  get isRecordingPause() {
    return store.getters.isPauseAivailable;
  }


  public mounted() {
    // receive message frome extention
    window.addEventListener('message', this.onMessageReceive);
  }

  public onMessageReceive(event) {
    if (event.data !== undefined && event.data.type !== undefined && event.data.type === PAUSE_RECORDING) {
      this.relaunchRecord();
    }
  }

  public relaunchRecord() {
    setActionEvent(ActionEvent.record_button_pause, {record_time: store.getters.getTimerRecord});
    store.dispatch(PAUSE_RECORDING);
    this.$emit('click');
  }

  get label(): string {
    if (this.mini) {
      return '';
    } else {
      return this.$t('recorderPauseButton.stop').toString();
    }

  }

  public beforeDestroy() {
    // destory the listener
    window.removeEventListener('message', this.onMessageReceive);
  }
}
</script>

<style scoped lang="scss">

</style>
