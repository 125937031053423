<template>
  <b-modal ref="currentWModal" class="wModal" :class="fullHeight?'fullHeight':'' "
           :active.sync="isActive"
           trap-focus
           aria-role="dialog"
           aria-modal
           style="height: 100%;"
           :width="maxWidth"
           :full-screen="fullscreen"
           :can-cancel="!modal"
           @close="onclose">
    <perfect-scrollbar v-if="!static" class="perfectBackgroundContainer">
      <slot></slot>
    </perfect-scrollbar>
    <div v-else>
      <slot></slot>
    </div>
  </b-modal>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {MODAL_ID} from '@/utils/dialog';

@Component({})
export default class WModal extends Vue {

  @Prop({default: false})
  private active!: boolean;

  @Prop({default: false})
  private modal!: boolean;

  @Prop({default: false})
  private fullscreen!: boolean;

  @Prop({default: '960px'})
  private maxWidth!: string;


  @Prop({default: true})
  private fullHeight!: boolean;

  @Prop({default: false})
  private static!: boolean;

  private isActive: boolean = false;

  private mounted() {
    this.isActive = this.active || false;
    this.$root.$refs[MODAL_ID] = this.$refs.currentWModal;
  }

  @Watch('active')
  public isActiveChange() {
    this.isActive = this.active;
    this.$root.$refs[MODAL_ID] = this.$refs.currentWModal;
  }

  public onclose() {
    this.$emit('close');
    this.$emit('update:active', false);
  }
}
</script>

<style lang="scss">
@import '@/scss/gridBreakPoint.scss';

/** Fix on fullscreen  **/
.wModal.modal.is-full-screen > .animation-content {
  background: none;
}

.wModal {
  .perfectBackgroundContainer {
    max-height: 100%;
    height: 100%;
  }

  .modal-background {
    z-index: -1;
    // backdrop-filter: blur(4px);
    background-color: rgba(27, 57, 84, 0.8);
  }


  &.is-fullscreen {
    background: none;
  }

  &.fullHeight {
    .modal-content {
      height: 100%;
    }
  }

  .modal.modal-content {
    @include GRID1X() {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      box-sizing: border-box;
      max-height: 100%;
      height: 100%;
      overflow: hidden;

    }
  }

  .modal-content, .modal-card {
    @include GRID1X() {
      margin: 0;
      box-sizing: border-box;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
