import {AvatarGender, AvatarVoiceAvatar} from "@/store/AIAvatar/aiavatarGenerationModel";


export const listOfVoice = [
    {label:'Jenny', value:AvatarVoiceAvatar.EN_US_JENNY,gender:AvatarGender.FEMALE},
    {label:'Vivienne', value: AvatarVoiceAvatar.FR_FR_VIVIENNE,gender:AvatarGender.FEMALE},
    {label:'Avia', value: AvatarVoiceAvatar.US_AVIA,gender:AvatarGender.FEMALE},
    {label:'Emma', value: AvatarVoiceAvatar.US_EMMA,gender:AvatarGender.FEMALE},
    {label:'Serphina', value: AvatarVoiceAvatar.DE_SERPHINA,gender:AvatarGender.FEMALE},
    {label:'Xiao', value: AvatarVoiceAvatar.ZN_XIAO,gender:AvatarGender.FEMALE},
    {label:'Isidora',value:AvatarVoiceAvatar.ES_ES_ISIDORA,gender:AvatarGender.FEMALE},
    {label:'Arabella',value:AvatarVoiceAvatar.ES_ES_ARABELLA,gender:AvatarGender.FEMALE},
    {label:'Isabella',value:AvatarVoiceAvatar.IT_IT_ISABELLA,gender:AvatarGender.FEMALE},
    {label:'Thalita',value:AvatarVoiceAvatar.PT_BR_THALITA,gender:AvatarGender.FEMALE},
    {label:'Xiao chen',value:AvatarVoiceAvatar.ZH_CN_XIAOCHEN,gender:AvatarGender.FEMALE},

    // male
    {label:'Andrew',value:AvatarVoiceAvatar.EN_US_ANDREW,gender:AvatarGender.MALE},
    {label:'Bryan',value:AvatarVoiceAvatar.EN_US_BRYAN,gender:AvatarGender.MALE},
    {label:'Florian',value:AvatarVoiceAvatar.DE_DE_FLORIAN,gender:AvatarGender.MALE},
    {label:'Remy',value:AvatarVoiceAvatar.FR_FR_REMY,gender:AvatarGender.MALE},
    {label:'Marcello',value:AvatarVoiceAvatar.IT_IT_MARCELLO,gender:AvatarGender.MALE},
    {label:'Alessio',value:AvatarVoiceAvatar.IT_IT_ALESSIO,gender:AvatarGender.MALE},
    {label:'Masaru',value:AvatarVoiceAvatar.JP_JP_MASARU,gender:AvatarGender.MALE},
    {label:'Yunki',value:AvatarVoiceAvatar.ZH_CN_YUNKI,gender:AvatarGender.MALE},
    {label:'Ryan',value:AvatarVoiceAvatar.EN_US_RYAN,gender:AvatarGender.MALE},
]
