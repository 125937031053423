<template>
  <b-dropdown aria-role="menu" scrollable trap-focus :expanded="expanded" :disabled="!enabled">
    <template #trigger>
      <div class="dropdownTriggerWrapper">
        <w-button :color="color" class="labelBtn" icon-right="chevron-down" :loading="isLoading"
                  size="small" :expanded="expanded" :justify-space-between="expanded">
          {{ valueLabel }}
        </w-button>
      </div>
    </template>
    <b-dropdown-item v-for="role of workspaceUserRoleListToShow" :key="role.value" aria-role="listitem"
                     :custom="isCustomRole(role.value)"
                     :disabled="isItemDisabled(role.value)"
                     @click="changeRole(role.value, role.label.toString())">
      {{ role.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WSelect from '@/components/wrapper/w-select.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {canDeleteWorkspace} from '@/utils/workspaceUtil';
import {WorkspaceUserRoleEnum} from '@/store/workspaceRight/workspaceRightModel';

@Component({
  components: {WSelect, WIcon, WButton},
})
export default class WorkspaceUserRole extends Vue {
  @Prop({default: true})
  private enabled!: boolean;

  @Prop({default: 'text'})
  private color!: string;

  @Prop({default: false})
  private isLoading!: boolean;
  @Prop()
  private value!: WorkspaceUserRoleEnum;

  @Prop({default: false})
  private invite!: boolean;
  @Prop({default: false})
  private expanded!: boolean;

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspaceID;
  }

  get workspaceUserRoleListToShow() {
    return this.workspaceUserRoleList.filter((role) => {
      return this.showItem(role.value)
    })
  }

  private isCustomRole(role: string): boolean {
    return role === WorkspaceUserRoleEnum.CUSTOM;
  }

  private isItemDisabled(role: string): boolean {
    return !this.isOwnerConnected && role === WorkspaceUserRoleEnum.OWNER;
  }

  get isOwnerConnected() {
    return canDeleteWorkspace();
  }

  private showItem(role: string): boolean {
    const isCustom = this.isCustomRole(role);
    return !isCustom;
  }


  private async changeRole(role: WorkspaceUserRoleEnum, roleLabel: string) {
    this.$emit('clickItem', role, roleLabel);
  }

  get workspaceUserRoleList() {
    return this.invite ? this.workspaceUserRoleListInvite : this.workspaceUserRoleListAll;
  }

  get workspaceUserRoleListAll() {
    return [
      {
        value: WorkspaceUserRoleEnum.OWNER,
        label: this.$t('workspace.manage.ownerRole')
      },
      {
        value: WorkspaceUserRoleEnum.ADMIN,
        label: this.$t('workspace.manage.adminRole')
      }, {
        value: WorkspaceUserRoleEnum.CREATOR,
        label: this.$t('workspace.manage.creatorRole')
      }, {
        value: WorkspaceUserRoleEnum.VIEWER,
        label: this.$t('workspace.manage.viewerRole')
      }, {
        value: WorkspaceUserRoleEnum.CUSTOM,
        label: this.$t('workspace.manage.customRole')
      }
    ];
  }

  get workspaceUserRoleListInvite() {
    return this.workspaceUserRoleListAll.filter((role) => role.value === WorkspaceUserRoleEnum.VIEWER || role.value === WorkspaceUserRoleEnum.CREATOR);
  }

  get valueLabel() {
    return this.workspaceUserRoleListAll.find((role) => role.value === this.value)?.label.toString();
  }

}
</script>
