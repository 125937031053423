<template>
  <div class="screenChooseLayout">

    <!-- Preview Screen -->
    <div>
      <div class="screenChoosedLayout" v-if="isScreenCastChoose">
        <video-source :type="sourceVideo.SCREENCAST" @error="closeScreen" @mediaLoaded="screenLoaded"
                      @killSource="onKillSource"/>
      </div>
      <div class="screenChoosedLayout" v-if="isVirtualScreenCastChoose">
        <VirtualScreenSource/>
      </div>
      <div class="screenChoosedLayout reverse" v-if="isWebcamChoose">
        <video-source :type="sourceVideo.WEBCAM" @error="closeScreen" @mediaLoaded="screenLoaded"
                      @killSource="onKillSource" :HD="true" :filter="true" @videoDenied="deniedWC"
                      @audioDenied="deniedWC"/>
      </div>
      <div class="screenChoosedLayout" v-if="isAIAvatarChoose">
        <AIAvatarSource @close="onAIGenerated"/>
      </div>
    </div>

    <!-- CHOOSE SCREENBACKGROUND -->
    <Transition name="fade">
      <div class="ambianceDropDownPanel" v-show="!isRecording && (isVirtualScreenCastChoose || isNoneChoose)">
        <b-dropdown animation="fadeRight" class="dropdownFilterVirtualScreen"
                    position="is-bottom-left"
                    :max-height="450">
          <w-button :tooltip="$t('svb.tooltip').toString()" tooltip-position="left" color="light" size="small"
                    icon="brush" :step-tour="'chooseBackground'"
                    slot="trigger"></w-button>
          <b-dropdown-item :focusable="false" class="noHover"
                           custom
                           paddingless>
            <VirtualScreenCastPanel/>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import store from '@/store';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_STICKER_FULLSCREEN,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import TopCenter from '@/components/layout/TopCenter.vue';
import VirtualScreenSource from '@/components/recorder/videoLayout/source/VirtualScreenSource.vue';
import FilterPanel from '@/components/recorder/sticker/buttons/FilterPanel.vue';
import VirtualScreenCastPanel from '@/components/recorder/videoLayout/chooseScreen/VirtualScreenCastPanel.vue';
import throttle from 'lodash/throttle';
import AIAvatarSource from "@/components/recorder/videoLayout/source/AIAvatarSource.vue";


@Component({
  components: {
    AIAvatarSource,
    VirtualScreenCastPanel,
    FilterPanel, VirtualScreenSource, TopCenter, VideoSource, WButton, WIcon, CenterCenter
  },
})
export default class ChooseMedia extends Vue {

  private mustShow: boolean = false;

  private debouceKill = throttle(() => {
    this.onKillSource();
  }, 1000);

  get sourceVideo(): any {
    return SourceVideoType;
  }


  private onKillSource() {
    this.$emit('killSource');
    // we have to wait the next tick to remove the source
    this.$nextTick(() => {
      // remove screen because is dead
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE);
    });
  }

  private mounted() {
    this.mustShow = true;
  }

  private deniedWC() {
    console.log('launch kill source', store.getters.isStickerMute);
    this.debouceKill();
  }


  get isRecording(): boolean {
    return !store.getters.isStartRecordingAivailable;
  }

  private screenLoaded() {
    this.$emit('screenLoaded');
  }


  private closeScreen() {
    this.$emit('killSource');
    // we wait the end of stop to remove the screencast,
    // if not the record crash.
    this.$nextTick(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE);
      if (store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }

  private onAIGenerated(){
    this.$emit('onAIGenerated')
  }

  get isScreenCastChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.SCREENCAST;
  }

  get isVirtualScreenCastChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.VIRTUAL_SCREENCAST;
  }

  get isNoneChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.NONE;
  }

  get isWebcamChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.WEBCAM;
  }
  get isAIAvatarChoose():boolean{
    return store.getters.getMainRecordSource === SourceVideoType.AI_AVATAR;
  }

  get mainMediaCurrentMediaID(): string {
    return store.getters.getMainMediaId;
  }
}
</script>

<style lang="scss">
@import '@/scss/shadows.scss';
@import '@/scss/gridBreakPoint.scss';

.dropdownFilterVirtualScreen {
  position: relative;


  .dropdown-menu {
    max-width: 248px;
    min-width: 240px;
    margin-top: -120px;
    margin-right: -270px;
    @include GRID2X {
      margin-top: -0px;
      margin-left: 0px;
    }
    @include GRID1X {
      margin-top: -0px;
      margin-left: 0px;
    }
  }
}

</style>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.screenChooseLayout {
  position: relative;
  // max-height: 218px;
  height: 100%;

  .ambianceDropDownPanel {
    text-align: right;
    position: absolute;
    top: 16px;
    right: 16px;
  }


  .screenChoosedLayout {
    // cursor: pointer;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;

    display: flex;
    position: absolute;
    align-items: center;
    top: 0px;
    justify-content: center;
    //background-color: rgba(27, 57, 84, 0.8);
    //border-radius: 8px;

    &.reverse {
      transform: scaleX(-1);
    }
  }

}

</style>
