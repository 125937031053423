import {CopyBitmapTransformer} from '@/utils/video/videoEffect/CopyBitmapTransform';
import {AccurateTimer} from '@/utils/accurateTimer';
import {log} from '@/utils/log';
import {FaceTrackTransformer} from '@/utils/video/videoEffect/FaceTrackTransform';
import {CopyCropAndScaleTransformer} from '@/utils/video/videoEffect/CopyCropAndScaleTransform';
import {BlurBackgroundTransformer} from '@/utils/video/videoEffect/BlurBackgroundTransform';
import {VirtualBackgroundTransformer} from '@/utils/video/videoEffect/VirtualBackgroundTransform';
import {CopyVideoTransformer} from '@/utils/video/videoEffect/CopyVideoTransform';
import delay from 'delay';
import {WebCamIntegrationTransform} from '@/utils/video/videoEffect/WebCamIntegrationTransform';

export enum StreamUtilStateChange {
    START_LOADING = 'START',
    STREAM_LOADED = 'MEDIA_LOADED',
    STREAM_READY = 'STREAM_READY',
    DESTROYED = 'DESTROYED'
}

export enum StreamUtilMediaType {
    NONE = 'NONE',
    WEBCAM = 'WEBCAM',
    SCREEN = 'SCREEN',
    SCREEN_CAM = 'SCREEN_CAM'
}

export enum WebcamIntegrationPosition {
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    BOTTOM_CENTER = 'BOTTOM_CENTER',
}

export class StreamUtils {

    public videoParams: any;
    // This a fps, it can be modify with the load
    public currentFPS: number = 20;
    public fpsAsked: number = 20;
    public streamGenerated!: MediaStream;
    public streamSource!: MediaStream;
    public secondStream!: MediaStream;
    public frameInProgress: number = 0;
    public maxFrameConcurrency: number = 1; // Math.floor(navigator.hardwareConcurrency / 2);
    public streamInitialise: boolean = false;
    public filterLoaded: boolean = false;
    public type: StreamUtilMediaType = StreamUtilMediaType.NONE;
    public canvas: HTMLCanvasElement | OffscreenCanvas | undefined;
    public video: HTMLVideoElement | undefined;
    public context!: ImageBitmapRenderingContext;
    public pauseRender: boolean = false;
    public nativeRenderer: boolean = false;
    public screenSurface: string | null = null;
    public hasAudioTrack: boolean = false;


    private onStateChangeCallback!: (type: StreamUtilStateChange, data: any) => {};
    private onStreamReadyCallBack!: () => {};
    private onRenderErrorCallBack!: () => {};
    private destroyed: boolean = false;

    private pipeline: ICanvasTransform[] = [];

    private timer: AccurateTimer = new AccurateTimer();

    constructor(highPerf: boolean = false) {
        if (highPerf) {
            this.maxFrameConcurrency = Math.floor(navigator.hardwareConcurrency / 2);
            log.info('ML and Render Thread concurrency ' + this.maxFrameConcurrency);
        } else {
            this.maxFrameConcurrency = 1;
        }
    }

    public onStateChange(callback) {
        this.onStateChangeCallback = callback;
    }

    public onStreamReady(callback) {
        this.onStreamReadyCallBack = callback;
    }

    public onRenderError(callback) {
        this.onRenderErrorCallBack = callback;
    }

    /**
     * Render a simple copy of the stream source in the canvas (transfert bitmap)
     */
    public inputBitmapRenderer(canvas: HTMLCanvasElement | OffscreenCanvas) {
        this.canvas = canvas;
        const ctx = canvas.getContext('bitmaprenderer', {alpha: false});
        if (!ctx) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context');
        }
        //@ts-ignore
        this.context = ctx;
        this.pipeline.push(new CopyBitmapTransformer());
    }

    /**
     * Render a simple copy of the stream source in the canvas (transfert bitmap)
     */
    public inputNativeRenderer(video: HTMLVideoElement) {
        this.video = video;
    }

    /**
     * Render a simple copy of the stream source in the canvas (transfert bitmap)
     */
    public inputVideoTagRenderer(canvas: HTMLCanvasElement | OffscreenCanvas, cropWidth: number | undefined, cropHeight: number | undefined) {
        this.canvas = canvas;
        const ctx = canvas.getContext('bitmaprenderer', {alpha: false});
        if (!ctx) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context');
        }
        // @ts-ignore
        this.context = ctx;
        this.pipeline.push(new CopyVideoTransformer(cropWidth, cropHeight));
        if (cropWidth && cropHeight) {
            this.canvas.width = cropWidth;
            this.canvas.height = cropHeight;
        }
    }

    /**
     * Render a simple copy of the stream source in the canvas (transfert bitmap)
     */
    public applyFaceTrackRenderer(canvas: HTMLCanvasElement | OffscreenCanvas, width: number, height: number) {
        this.canvas = canvas;
        if (!this.context) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context, please applyBitmapRenderer at first');
        }
        this.pipeline.push(new FaceTrackTransformer(width, height));
        this.canvas.width = width;
        this.canvas.height = height;
    }

    /**
     * Render a simple copy of the stream source in the canvas (redraw)
     */
    public applyCropAndScaleRenderer(canvas: HTMLCanvasElement | OffscreenCanvas, width: number, height: number) {
        // this transformer need a video to Render

        this.canvas = canvas;
        this.canvas.width = width;
        this.canvas.height = height;

        if (!this.context) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context, please applyBitmapRenderer at first');
        }
        this.pipeline.push(new CopyCropAndScaleTransformer(width, height));
    }

    /**
     * Render a simple copy of the stream source in the canvas (redraw)
     */
    public applyBlurBackgroundRenderer(canvas: HTMLCanvasElement | OffscreenCanvas, width: number, height: number = 2) {
        // this transformer need a video to Render

        this.canvas = canvas;
        this.canvas.width = width;
        this.canvas.height = height;

        if (!this.context) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context, please applyBitmapRenderer at first');
        }
        this.pipeline.push(new BlurBackgroundTransformer(width, height));
    }

    public async applyWebCamIntegration(canvas: HTMLCanvasElement | OffscreenCanvas, width: number, height: number,
                                        position: WebcamIntegrationPosition = WebcamIntegrationPosition.BOTTOM_LEFT,
                                        size: number) {
        this.canvas = canvas;

        if (!this.context) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context, please applyBitmapRenderer at first');
        }
        // create contrainte
        const screenConstraints = {
            audio: false,
            video: {
                // displaySurface: 'browser',
                cursor: 'always',
                height: {max: height},
                //  width: {max: width},
                // resizeMode: 'crop-and-scale'
                //  optional: [],
            },
        };
        // @ts-ignore
        this.secondStream = await navigator.mediaDevices.getDisplayMedia(screenConstraints);
        // we have to re set contraint for screen capture
        await this.applyFPSOnStream(this.secondStream); // same fps for sreen than webcam

        this.pipeline.push(new WebCamIntegrationTransform(width, height, this.secondStream, position, size));
    }

    /**
     * Render a simple copy of the stream source in the canvas (redraw)
     */
    public applyVirtualBackgroundRenderer(canvas: HTMLCanvasElement | OffscreenCanvas, width: number, height: number,
                                          urlVideo = '',
                                          backgroundColor = '',
                                          backgroundColor2 = '',
                                          urlImage = '') {

        // this transformer need a video to Render
        this.canvas = canvas;
        this.canvas.width = width;
        this.canvas.height = height;

        if (!this.context) {
            throw new Error('Impossible to create StreamUtils with Canvas with Context, please applyBitmapRenderer at first');
        }
        this.pipeline.push(new VirtualBackgroundTransformer(width, height, urlVideo,
            backgroundColor,
            backgroundColor2,
            urlImage));
    }


    /**
     * Load a webcam with the specific contraints
     * @param deviceId
     * @param width
     * @param height
     * @param fps
     */
    public async loadStream(type: StreamUtilMediaType, deviceId: string = '', width: number = 640, height: number = 480, fps = 20, fpsCapture = 20): Promise<MediaStream|null> {

        // Empty stream (for audio only)
        if (StreamUtilMediaType.NONE === type) {
            this.streamGenerated = new MediaStream();
            this.sendNewState(StreamUtilStateChange.STREAM_LOADED, this.streamGenerated);
            this.sendStreamReady();
            this.filterLoaded = true;
            return this.streamGenerated;
        }

        if (!this.pipeline) {
            throw new Error('Please apply a renderer before load a source');
        }
        // construct the video params
        this.videoParams = {
            width: width,
            height: height,
            frameRate: fps,
            deviceId: deviceId,
            resizeMode: 'none' // important to keep, if not there is some bug with some webcam n the recorder
        };
        if (height >= 720) {
            this.videoParams.aspectRatio = 16 / 9; // force 16/9 to avoid weird resolution (portrait mode)
        }
        this.type = type;
        this.currentFPS = fps;
        this.fpsAsked = fps;
        // send state
        this.sendNewState(StreamUtilStateChange.START_LOADING, this.videoParams);

        if (this.type === StreamUtilMediaType.WEBCAM || this.type === StreamUtilMediaType.SCREEN_CAM) {
            // load webcam
            this.streamSource = await navigator.mediaDevices.getUserMedia({video: this.videoParams, audio: false});
        }
        if (this.type === StreamUtilMediaType.SCREEN) {
            // create contrainte
            const screenConstraints = {
                audio: false,
                video: {
                    displaySurface: 'monitor',
                    cursor: 'always',
                    height: {max: height},
                    //  width: {max: width},
                    // resizeMode: 'crop-and-scale'
                    //  optional: [],
                },
            };
            // @ts-ignore
            this.streamSource = await navigator.mediaDevices.getDisplayMedia(screenConstraints);
            this.currentFPS = fps;
            // we have to re set contraint for screen capture
            await this.applyFPSOnStream(this.streamSource);

        }
        // send state with the stream
        this.sendNewState(StreamUtilStateChange.STREAM_LOADED, this.streamSource);


        // now init the pipeline
        for (const t of this.pipeline) {
            await t.init(this.streamSource, this.maxFrameConcurrency > 1);
        }

        this.setScreenSurface();
        const browserCapture = this.isBrowserCapture() || this.type === StreamUtilMediaType.SCREEN_CAM;
        // launch the timer
        if (this.video && this.pipeline.length <= 1 && !browserCapture) {
            this.nativeRenderer = true;
            this.streamGenerated = this.streamSource;
            this.filterLoaded = true;
            if (this.video) {
                this.video.srcObject = this.streamSource;
            }
            setTimeout(() => {
                this.sendStreamReady();
            }, 1000);
        } else {
            // canvas mode
            this.timer.setFps(this.currentFPS);
            this.timer.launch(() => {
                this.computeFrame();
            });

            // @ts-ignore
            this.streamGenerated = this.canvas.captureStream(fpsCapture) as MediaStream;
        }

        if (this.destroyed) {
            // we destroy
            this.destroy();
            return null;
        }
        return this.streamGenerated;

    }

    /**
     * Add a micro to the streamgenerated
     * @param micId
     */
    public async addMicrophone(micId: string = ''): Promise<MediaStream> {
        const mic = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
                deviceId: micId
            }
        });
        if (this.streamGenerated) {
            this.streamGenerated.addTrack(mic.getTracks()[0]);
        }
        this.hasAudioTrack = true;
        if (this.destroyed) {
            this.destroy();
        }
        return this.streamGenerated;
    }

    /**
     * Destroy the streamUtils and cloas all stream
     */
    public async destroy() {
        // await this.waitStreamInitialise();
        this.destroyed = true;
        if (this.pipeline) {
            for (const t of this.pipeline) {
                await t.flush();
            }
        }
        if (this.streamSource) {
            for (const track of this.streamSource.getTracks()) {
                track.stop();
            }
        }
        if (this.streamGenerated) {
            for (const track of this.streamGenerated.getTracks()) {
                track.stop();
            }
        }
        if (this.secondStream) {
            for (const track of this.secondStream.getTracks()) {
                track.stop();
            }
        }
        this.timer.stop();
        if (this.canvas) {
            // const image = new Image();
            // const emptyBitmap = await createImageBitmap(image, 0, 0, this.canvas.width, this.canvas.height);
            // this.context.transferFromImageBitmap(emptyBitmap);
        }
        this.screenSurface = null;
        this.sendNewState(StreamUtilStateChange.DESTROYED, undefined);
        await delay(100);
    }

    public async waitStreamInitialise(){
        const interval=500;
        const maxTimeToWait=5000;
        let timeWaited=0;
        while (timeWaited<maxTimeToWait){
            if(this.streamInitialise){
                return;
            }
            timeWaited+=interval;
            await delay(interval);
        }
    }

    public computeQuality(HQ: boolean = false) {
        let ratioVideo = 0.14;
        if (HQ) {
            ratioVideo = 0.16;
        }
        let quality = 2000000;
        if (this.streamGenerated && this.streamGenerated.getVideoTracks().length > 0) {
            const setting = this.streamGenerated.getVideoTracks()[0].getSettings();
            if (setting && setting.width && setting.height && setting.frameRate) {
                quality = (setting.width * setting.height * setting.frameRate * ratioVideo);
            }
            // log.info('Setup ' + quality);
        }
        if (quality < 500000) {
            quality = 500000;
        }
        return quality;
    }


    /**
     * Compute a frame with the pipeline
     */

    private async computeFrame() {
        if (this.canICommputeFrame()) {
            const dateStart = new Date().getTime();
            this.frameInProgress++;
            if (this.type === StreamUtilMediaType.SCREEN) {
                // allow resizing in live (tab, or window)
                this.resizeCanvas(this.streamSource);
            }
            if (this.type === StreamUtilMediaType.SCREEN_CAM) {
                // allow resizing in live (tab, or window)
                this.resizeCanvas(this.secondStream);
            }
            // we have to generate image because if note the track stop (inactivity)
            let isFrameGenerated = false;
            try {
                let imageGenerated: ImageBitmap | null = null;
                for (const t of this.pipeline) {
                    const tmpFrame = await t.transform(this.pauseRender, imageGenerated);
                    if (tmpFrame) {
                        imageGenerated = tmpFrame;
                    } else {
                        // if frame is null, we break the pipline
                        break;
                    }
                }
                if (imageGenerated && imageGenerated) {
                    isFrameGenerated = true;
                    this.context.transferFromImageBitmap(imageGenerated);
                    if (!this.filterLoaded) {
                        let filterLoaded = true;
                        for (const t of this.pipeline) {
                            if (!t.initialised) {
                                filterLoaded = false;
                                break;
                            }
                        }
                        this.filterLoaded = filterLoaded;
                    }
                }
            } catch (e) {
                if (this.streamInitialise) {
                    console.error(e);
                    if (this.onRenderErrorCallBack) {
                        this.onRenderErrorCallBack();
                    }
                    this.destroy();
                }
            }

            if (!isFrameGenerated) {
                this.frameInProgress--;
                return;
            } else {
                if (!this.streamInitialise) {

                    this.sendNewState(StreamUtilStateChange.STREAM_READY, undefined);
                    this.sendStreamReady();
                }
            }


            const timeOfFrameGeneration = new Date().getTime() - dateStart;
            // duration of a frame allowed
            const frameTimeMax = 1000 / this.currentFPS * (this.maxFrameConcurrency / 2);
            // console.log(timeOfFrameGeneration + 'ms of ' + frameTimeMax + 'ms');

            if (timeOfFrameGeneration > frameTimeMax) {
                this.decrementFPS();
            } else {
                this.incrementFPS();
            }

            this.frameInProgress--;
        } else {
            // we are in overload so we decreased the fps
            // console.log('doubleFrame');
            this.decrementFPS();
        }
        // set the right FPS
        this.timer.setFps(this.currentFPS);
    }

    private incrementFPS() {
        if (this.currentFPS < this.fpsAsked) {
            this.currentFPS++;
        }
    }

    private decrementFPS() {
        if (this.streamInitialise) {
            if (this.currentFPS > 1) {
                this.currentFPS--;
            }
        }
    }

    private applyFPSOnStream(stream: MediaStream) {
        const videoTrack = stream.getVideoTracks();
        if (videoTrack.length > 0) {
            videoTrack[0].applyConstraints({
                // width: {min: 1280, max: 1280},
                // height: {exact: 720},
                // resizeMode: 'none',
                frameRate: this.currentFPS,
            });
        }
    }

    /**
     * Resize the canvas to be at the same size of sources
     * @private
     */
    private resizeCanvas(stream: MediaStream) {
        const settings = stream.getVideoTracks()[0].getSettings();
        if (this.canvas) {
            if (settings.width != null) {
                this.canvas.width = settings.width;
            }
            if (settings.height != null) {
                this.canvas.height = settings.height;
            }
        }
        if (this.video) {
            if (settings.width != null) {
                this.video.width = settings.width;
            }
            if (settings.height != null) {
                this.video.height = settings.height;
            }
        }
    }

    private isBrowserCapture() {
        const settings = this.streamSource.getVideoTracks()[0].getSettings();
        // @ts-ignore
        if (settings.displaySurface === 'browser') {
            return true;
        }
    }

    private setScreenSurface() {
        const settings = this.streamSource.getVideoTracks()[0].getSettings();
        // @ts-ignore
        this.screenSurface = settings.displaySurface;
    }

    /**
     * The new state for the listener
     * @param type
     * @param data
     * @private
     */
    private sendNewState(type: StreamUtilStateChange, data: any) {
        // log.info('Stream ' + type);
        if (this.onStateChangeCallback) {
            this.onStateChangeCallback(type, data);
        }
    }

    /**
     * Send the StreamReady
     * @private
     */
    private sendStreamReady() {
        if (!this.streamInitialise) {
            if (this.onStreamReadyCallBack) {
                try {
                    this.onStreamReadyCallBack();
                } catch (e: any) {
                    log.error(e.toString());
                }
            }
            this.streamInitialise = true;
            //  log.info('Stream ready');
        }
    }


    private canICommputeFrame(): boolean {
        return this.frameInProgress < this.maxFrameConcurrency;
    }
}
