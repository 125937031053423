import axios from 'axios';

import {BACK_ENDPOINT, DOWNLOAD_ENDPOINT} from '@/store/conf/api';
import Vue from 'vue';
import {UploadMedia} from '@/store/upload/uploadMediaModel';
import {
  MEDIA_DOWNLOAD_END,
  MEDIA_DOWNLOAD_PROGRESS,
  MEDIA_DOWNLOAD_START,
  MediaDownloadParams,
} from '@/store/download/downloadAction';
import {DownloadMedia} from '@/store/download/downloadMediaModel';
import {getVideoURLForStatus} from '@/utils/util';

const mediaAPI = BACK_ENDPOINT + '/media';

// let blob = await fetch(url).then(r => r.blob());

export default {
  state: {
    mediaDownload: {},
  },
  mutations: {
    [MEDIA_DOWNLOAD_START]: (state: any, mediaDownload: DownloadMedia) => {
      Vue.set(state.mediaDownload, mediaDownload.mediaId, mediaDownload);
    },
    [MEDIA_DOWNLOAD_PROGRESS]: (state: any, mediaDownload: DownloadMedia) => {
      // Update the progress status
      Vue.set(state.mediaDownload, mediaDownload.mediaId, mediaDownload);
    },
    [MEDIA_DOWNLOAD_END]: (state: any, mediaDownload: DownloadMedia) => {
      Vue.delete(state.mediaDownload, mediaDownload.mediaId);
    },
  },
  getters: {
    isDownloadInProgress: (state: any): boolean => {
      for (const value of Object.values(state.mediaDownload)) {
        const mediaDownload = value as DownloadMedia;
        if (!mediaDownload.finished) {
          return true;
        }
      }
      return false;
    },
    getDownloadMedia: (state: any): DownloadMedia[] => {
      return Object.values(state.mediaDownload);
    },
  },
  actions: {
    async [MEDIA_DOWNLOAD_START]({commit, dispatch}: { commit: any, dispatch: any }, params: MediaDownloadParams) {

      // register the process
      const downloadMedia = new DownloadMedia();
      downloadMedia.mediaId = params.media.mediaID;
      downloadMedia.url = getVideoURLForStatus(params.media);
      commit(MEDIA_DOWNLOAD_START, downloadMedia);

      const urlToDownload = DOWNLOAD_ENDPOINT + '/' + params.media.jobID + '/' + params.media.status + '.mp4';
      const response = axios({
        url: urlToDownload,
        method: 'GET',
        responseType: 'blob', // important
        onDownloadProgress: (p) => {
          const downloadMediaProgress = new DownloadMedia();
          downloadMediaProgress.mediaId = downloadMedia.mediaId;
          downloadMediaProgress.url = downloadMedia.url;
          downloadMediaProgress.byte = p.loaded;
          downloadMediaProgress.totalByte = p.total;
          downloadMediaProgress.pourcent = Math.round(p.loaded / p.total * 100);
          // dispatch because we aren't in action anymore
          dispatch(MEDIA_DOWNLOAD_PROGRESS, downloadMediaProgress);
        },
      }).then((resp) => {
        // get mediaIn Data
        // var media=resp.data as Media;
        // now we commit the Media and remove the MediaUpload
        // we launch the update of media
        commit(MEDIA_DOWNLOAD_END, downloadMedia);
        return window.URL.createObjectURL(new Blob([resp.data]));
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        console.error(err);
        throw err;
      });
      return response;
    },
    async [MEDIA_DOWNLOAD_PROGRESS]({commit, dispatch}: { commit: any, dispatch: any }, params: UploadMedia) {
      commit(MEDIA_DOWNLOAD_PROGRESS, params);
    },
  },
};
