import {log} from '@/utils/log';
import {WebcamIntegrationPosition} from '@/utils/video/streamUtils';
import {PUBLIC_PATH} from "@/store/conf/api";

const offScreenCanvases = {};
const contexts = {};

const STANDARD_RESOLUTION = {w: 160, h: 96};
const HQ_RESOLUTION = {w: 256, h: 144};
const SELECTED_RES = STANDARD_RESOLUTION;
const MODEL_STANDARD_PATH = PUBLIC_PATH+'tflite/models/segm_lite_v681.tflite';
const MODEL_HQ_PATH =  PUBLIC_PATH+'tflite/models/segm_full_v679.tflite';

export class SelfieSegmentation {
    private HQ: boolean;
    private tflite!: TFLite;
    private segmentationPixelCount!: number;
    private inputMemoryOffset!: number;
    private outputMemoryOffset!: number;

    private segmentationMaskCanvas!: OffscreenCanvas;
    private segmentationMaskContext!: OffscreenCanvasRenderingContext2D;
    private outputCanvas!: OffscreenCanvas;
    private outputCanvasCtx!: OffscreenCanvasRenderingContext2D;

    private segmentationMask = new ImageData(SELECTED_RES.w, SELECTED_RES.h);

    constructor(HQ: boolean = false) {
        this.HQ = true;
    }

    public async init() {
        this.tflite = await createTFLiteSIMDModule();
        log.info('TFLITE LOADED', this.tflite);
        // load model
        const modelFileName = MODEL_STANDARD_PATH;
        log.debug('Loading tflite model:' + modelFileName);
        const modelResponse = await fetch(
            modelFileName
        );
        const model = await modelResponse.arrayBuffer();
        log.debug('Model buffer size:', model.byteLength);

        const modelBufferOffset = this.tflite._getModelBufferMemoryOffset();
        log.debug('Model buffer memory offset:', modelBufferOffset);
        log.debug('Loading model buffer...');
        this.tflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
        this.tflite._loadModel(model.byteLength);
        log.debug('End loading Model');
        this.segmentationPixelCount = SELECTED_RES.w * SELECTED_RES.h;
        this.inputMemoryOffset = this.tflite._getInputMemoryOffset() / 4;
        this.outputMemoryOffset = this.tflite._getOutputMemoryOffset() / 4;
    }

    public inputFrame(image: ImageBitmap) {
        if (!this.segmentationMaskCanvas) {
            this.segmentationMaskCanvas = new OffscreenCanvas(SELECTED_RES.w, SELECTED_RES.h);
            // @ts-ignore
            this.segmentationMaskContext = this.segmentationMaskCanvas.getContext('2d', {
                alpha: true,
                antialias: false,
                desynchronized: true,
                preserveDrawingBuffer: false,
                willReadFrequently: true
            }) as OffscreenCanvasRenderingContext2D;
        }
        // Resize image for tensor
        this.segmentationMaskContext.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            SELECTED_RES.w,
            SELECTED_RES.h
        );
        // get image data from segmentationMaskContext
        const imageData = this.segmentationMaskContext.getImageData(
            0,
            0,
            SELECTED_RES.w,
            SELECTED_RES.h
        );
        // insert frame in tensor
        for (let i = 0; i < this.segmentationPixelCount; i++) {
            this.tflite.HEAPF32[this.inputMemoryOffset + i * 3] = imageData.data[i * 4] / 255;
            this.tflite.HEAPF32[this.inputMemoryOffset + i * 3 + 1] =
                imageData.data[i * 4 + 1] / 255;
            this.tflite.HEAPF32[this.inputMemoryOffset + i * 3 + 2] =
                imageData.data[i * 4 + 2] / 255;
        }
        this.runInference();
    }

    private runInference() {
        this.tflite._runInference();

        for (let i = 0; i < this.segmentationPixelCount; i++) {
            const background = this.tflite.HEAPF32[this.outputMemoryOffset + i * 2];
            const person = this.tflite.HEAPF32[this.outputMemoryOffset + i * 2 + 1];
            const shift = Math.max(background, person);
            const backgroundExp = Math.exp(background - shift);
            const personExp = Math.exp(person - shift);

            // Sets only the alpha component of each pixel
            this.segmentationMask.data[i * 4 + 3] =
                (255 * personExp) / (backgroundExp + personExp); // softmax
        }

        this.segmentationMaskContext.putImageData(this.segmentationMask, 0, 0);
    }

    public async getBlurFrame(image: ImageBitmap, blurRatio: number = 80) {

        this.initOutPutCanvas(image);

        this.drawMask(image);

        this.drawUser(image);

        this.drawBlurBackground(image, blurRatio);
        // @ts-ignore
        return await createImageBitmap(this.outputCanvas);
    }

    public async getWebcamIntegrationFrame(webcam: ImageBitmap, screenCast: ImageBitmap, pourHeight: number, position: WebcamIntegrationPosition) {
        this.initOutPutCanvas(screenCast);
        this.drawMask(webcam, pourHeight, position);
        this.drawUser(webcam, pourHeight, position);
        this.drawBackground(screenCast);
        return await createImageBitmap(this.outputCanvas);
    }

    public async getVirtualBackgroundFrame(input: ImageBitmap, background: ImageBitmap) {
        this.initOutPutCanvas(input);
        this.drawMask(input);
        this.drawUser(input);
        this.drawBackground(background);
        return await createImageBitmap(this.outputCanvas);
    }

    public async getVirtualBackgroundColorFrame(input: ImageBitmap, color1: string, color2: string) {
        this.initOutPutCanvas(input);

        this.drawMask(input);

        this.drawUser(input);

        this.drawBackgroundColor(input, color1, color2);
        return await createImageBitmap(this.outputCanvas);
    }

    private initOutPutCanvas(image: ImageBitmap) {
        if (!this.outputCanvas) {
            this.outputCanvas = new OffscreenCanvas(image.width, image.height);
            // @ts-ignore
            this.outputCanvasCtx = this.outputCanvas.getContext('2d', {
                alpha: true,
                antialias: false,
                desynchronized: true,
                preserveDrawingBuffer: false,
            }) as OffscreenCanvasRenderingContext2D;
            // this.outputCanvasCtx = this.outputCanvas.getContext('2d') as OffscreenCanvasRenderingContext2D;
        }
    }

    private drawMask(image: ImageBitmap, size: number = 1, position: WebcamIntegrationPosition | null = null) {
        // Copy : Replace content
        this.outputCanvasCtx.globalCompositeOperation = 'copy';
        this.outputCanvasCtx.filter = 'none';
        // This is the edge border
        this.outputCanvasCtx.filter = 'blur(3px)';

        let dx = 0;
        let dy = 0;
        const width = this.outputCanvas.width * size;
        const height = this.outputCanvas.height * size;
        if (position) {
            if (position === WebcamIntegrationPosition.BOTTOM_LEFT) {
                dx = 0;
                dy = this.outputCanvas.height - height;
            } else if (position === WebcamIntegrationPosition.BOTTOM_CENTER) {
                dx = (this.outputCanvas.width / 2) - (width / 2);
                dy = this.outputCanvas.height - height;
            } else if (position === WebcamIntegrationPosition.BOTTOM_RIGHT) {
                dx = this.outputCanvas.width - width;
                dy = this.outputCanvas.height - height;
            }
        }
        // drow segementation mask
        this.outputCanvasCtx.drawImage(
            this.segmentationMaskCanvas,
            0,
            0,
            SELECTED_RES.w,
            SELECTED_RES.h,
            dx,
            dy,
            width,
            height
        );

    }

    private drawUser(image: ImageBitmap, size: number = 1, position: WebcamIntegrationPosition | null = null) {

        let dx = 0;
        let dy = 0;
        const width = this.outputCanvas.width * size;
        const height = this.outputCanvas.height * size;
        if (position) {
            if (position === WebcamIntegrationPosition.BOTTOM_LEFT) {
                dx = 0;
                dy = this.outputCanvas.height - height;
            } else if (position === WebcamIntegrationPosition.BOTTOM_CENTER) {
                dx = (this.outputCanvas.width / 2) - (width / 2);
                dy = this.outputCanvas.height - height;
            } else if (position === WebcamIntegrationPosition.BOTTOM_RIGHT) {
                dx = this.outputCanvas.width - width;
                dy = this.outputCanvas.height - height;
            }
        }
        // La nouvelle forme est dessinée uniquement là où il y a déjà du contenu sur le canvas. Tout le reste est rendu transparent.
        // so we put the face on the mask without filter
        this.outputCanvasCtx.globalCompositeOperation = 'source-in';
        this.outputCanvasCtx.filter = 'none';
        this.outputCanvasCtx.drawImage(image, dx, dy, width, height);
    }

    private drawBlurBackground(image: ImageBitmap, blurRatio: number) {
        // Les nouvelles formes sont dessinées derrière le contenu existant du canvas.
        // now we blur the background
        // Todo calculate in other canvas, and draw after
        this.outputCanvasCtx.globalCompositeOperation = 'destination-over';
        const blur = image.height / blurRatio;
        this.outputCanvasCtx.filter = 'blur(' + blur + 'px)'; // FIXME Does not work on Safari
        this.outputCanvasCtx.drawImage(image, 0, 0);

    }

    private drawBackground(background: ImageBitmap) {
        // Les nouvelles formes sont dessinées derrière le contenu existant du canvas.
        // now we blur the background
        // Todo calculate in other canvas, and draw after
        this.outputCanvasCtx.globalCompositeOperation = 'destination-over';
        this.outputCanvasCtx.drawImage(background, 0, 0);

    }


    private drawBackgroundColor(image: ImageBitmap, backgroundColor1: string = '#FFFFFF', backgroundColor2: string) {
        // Les nouvelles formes sont dessinées derrière le contenu existant du canvas.
        // we apply the color
        this.outputCanvasCtx.globalCompositeOperation = 'destination-over';
        if (backgroundColor1 && backgroundColor2) {
            const gradient = this.outputCanvasCtx.createLinearGradient(0, 0, Math.floor(image.width / 2), Math.floor(image.height / 2));
            gradient.addColorStop(0, backgroundColor1);
            gradient.addColorStop(1, backgroundColor2);
            this.outputCanvasCtx.fillStyle = gradient;
        } else {
            this.outputCanvasCtx.fillStyle = backgroundColor1;
        }
        this.outputCanvasCtx.fillRect(0, 0, image.width, image.height);


        this.outputCanvasCtx.drawImage(image, 0, 0);

    }
}


declare function createTFLiteSIMDModule(): Promise<TFLite>;

export interface TFLite extends EmscriptenModule {
    _getModelBufferMemoryOffset(): number;

    _getInputMemoryOffset(): number;

    _getInputHeight(): number;

    _getInputWidth(): number;

    _getInputChannelCount(): number;

    _getOutputMemoryOffset(): number;

    _getOutputHeight(): number;

    _getOutputWidth(): number;

    _getOutputChannelCount(): number;

    _loadModel(bufferSize: number): number;

    _runInference(): number;
}
