<template>
  <CenterCenter>
    <div class="main">
      <div>
        <PopUpTitle :title="$t('login.signIn')"/>
      </div>
      <div class="bottomLine">
        <div class="signUpLink">
          {{ $t('login.noAccount') }}
          <a data-test="signInSignUpLink" @click="goToSignUp">{{ $t('login.button.signup') }}</a>
        </div>
      </div>
      <ExternalSignIn class="externalSign" :popup="popIn||embededInExternalIframe" @close="close"/>
      <w-divider :label="$t('login.orManualSignIn')"/>
      <w-error data-test="signInErrorMessage" class="errorMessage" :message="errorMsg"/>
      <form class="login" @submit.prevent="loginAction">
        <w-input data-test="signInEmailInput" :label="$t('login.email')" v-model="username"
                 :placeholder="$t('login.email')" name="email"
                 @keyup.native.enter="loginAction"/>
        <w-input data-test="signInPasswordInput" :label="$t('login.password')" type="password" v-model="password"
                 :placeholder="$t('login.password')" name="password"
                 @keyup.native.enter="loginAction"/>
      </form>
      <div class="forgotPasswordLink">
        <a data-test="signInForgotPasswordLink" @click="goForgotPassword">{{ $t('login.forgotPassword') }}</a>
      </div>
      <div class="containerButton">
        <w-button data-test="signInValidateButton" color="primary" expanded class="buttonLogin" @click="loginAction">
          {{ $t('login.button.signin') }}
        </w-button>
      </div>


    </div>
  </CenterCenter>
</template>

<script lang="ts">
import CenterCenter from '@/components/layout/CenterCenter.vue';
import store from '@/store';
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import {AUTH_REQUEST, AuthParams} from '@/store/auth/authAction';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import ExternalSignIn from '@/components/authPanel/ExternalSignIn.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WDivider from '@/components/wrapper/w-divider.vue';
import router from '@/router';
import {closeWModal} from '@/utils/dialog';
import {embededInExternalIframe} from '@/utils/integrationUtil';


@Component({
  name: 'SignInPanel',
  components: {WDivider, ExternalSignIn, WError, WInput, PopUpTitle, WButton, CenterCenter},
})
export default class SignIn extends Vue {
  private username: string = '';
  private password: string = '';
  private errorMsg: string = '';
  private loading: boolean = false;

  @Prop({default: true})
  private canCancel!: boolean;

  @Prop({default: false})
  private popIn!: boolean;

  private close() {
    if (this.canCancel) {
      closeWModal(this);
      this.$emit('close');
    }
  }

  private loginAction() {
    this.errorMsg = '';
    this.loading = true;
    const params = new AuthParams(this.username, this.password);
    store.dispatch(AUTH_REQUEST, params).then(() => {
      setActionEvent(ActionEvent.auth_manual_success);
      this.close();
    }).catch((err) => {
      this.loading = false;
      this.errorMsg = err;
    });
  }

  private signByEmail() {
    this.$emit('signByEMail', true);
  }

  private goForgotPassword() {
    router.push({name: 'ForgotPassword'});
    this.$emit('forgotPassword');
  }

  private goToSignUp() {
    if (!this.popIn) {
      router.push({name: 'CreateAccount'});
    }
    this.$emit('signUp');
  }

  get embededInExternalIframe(): boolean {
    return embededInExternalIframe();
  }
}
</script>

<style scoped lang="scss">

.main {
  max-width: 512px;
  width: 100%;
  box-sizing: content-box;
  height: 100%;
  min-width: 320px;
  background: #FFF;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 48px;
  padding-bottom: 48px;
  border-radius: 8px;

  .externalSign {
    margin-top: 32px;
  }

  .errorMessage {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .forgotPasswordLink {
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
  }
}


form {
  text-align: left;
  width: 100%;
}

.orManual {
  text-align: center;
}

.bottomLine {
  width: 100%;
  font-size: 13px;
  font-weight: 900;
  margin-top: 24px;
  letter-spacing: 0.3px;
  color: var(--black);
  text-transform: uppercase;
}

.buttonLogin {
  margin-top: 32px;
}


</style>
