<template>
  <div class="visibilityPolicyLink" v-if="weet">
    <div class="visibilityLine">
      <div class="publicLinkLabel" :class="expanded?'expanded':''">{{ sharableLinkLabel }}</div>
      <w-switch color="primary" size="small" class="offsetAlignCenter"
                v-model="openVisibility" @input="changeVisibilityPolicy"></w-switch>

    </div>

    <Transition name="fade">
      <div class="visibilityLine" v-show="!isInternalVisibility">
        <div class="publicLinkLabel">{{ $t('visibilityPolicy.searchEngine') }}
          <w-help :label="$t('visibilityPolicy.tooltip')" class="helpOffset"/>
        </div>

        <w-switch color="primary" size="small" class="offsetAlignCenter"
                  v-model="searchEngineVisibility" @input="changeIndexationPolicy"></w-switch>
      </div>
    </Transition>

    <div class="commentPolicyLine">
      <ShareCommentPolicy :expanded="expanded" :weet="weet"/>
    </div>


  </div>


</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import {Weet} from '@/store/weet/weetModel';
import {SEND_VISIBILITY_POLICY_WEET} from '@/store/myWeet/myWeetAction';
import WSelect from '@/components/wrapper/w-select.vue';
import {inform} from '@/utils/dialog';
import WSwitch from '@/components/wrapper/w-switch.vue';
import {VisibilityPolicy} from '@/store/weet/VisibilityPolicy';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import CopyWeetButton from '@/components/actionButton/CopyWeetButton.vue';
import ShareCommentPolicy from '@/components/share/ShareCommentPolicy.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import {SettingWorkspace, SettingWorkspaceKey, WorkspaceVisibilityPolicyEnum} from '@/store/workspace/workspaceModel';

@Component({
  components: {
    WHelp,
    CopyWeetButton,
    ShareCommentPolicy,
    WToolTip,
    WSwitch,
    WSelect,
    WButton
  }
})
export default class ShareVisibilityPolicy extends Vue {
  @Prop()
  private weet!: Weet;


  @Prop({default: false})
  private expanded!: boolean;

  private openVisibility: boolean = false;


  private searchEngineVisibility: boolean = false;

  private mounted() {
    if (this.weet.visibilityPolicy === VisibilityPolicy.INTERNAL) {
      this.openVisibility = false;
    } else {
      this.openVisibility = true;
    }

    if (this.weet.visibilityPolicy === VisibilityPolicy.PUBLIC) {
      this.searchEngineVisibility = true;
    } else {
      this.searchEngineVisibility = false;
    }
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get sharableLinkLabel(): string {
    if (this.selectedWorkspaceID) {
      if (this.isWorkspaceIsPrivate) {
        this.$t('visibilityPolicy.publicLinkLabelWorkspace').toString();
      }
    }
    return this.$t('visibilityPolicy.publicLinkLabel').toString();
  }

  get isWorkspaceIsPrivate(): boolean {
    const setting = store.getters.getWorkspaceSetting(this.selectedWorkspaceID, SettingWorkspaceKey.WORKSPACE_VISIBILITY) as SettingWorkspace;
    return WorkspaceVisibilityPolicyEnum.PRIVATE === setting?.value;
  }

  private changeVisibilityPolicy(value: boolean) {
    let visibility = VisibilityPolicy.SEMI_PRIVATE;
    if (this.searchEngineVisibility) {
      visibility = VisibilityPolicy.PUBLIC;
    }
    if (!value) {
      visibility = VisibilityPolicy.INTERNAL;
    }

    store.dispatch(SEND_VISIBILITY_POLICY_WEET,
        {weetID: this.weet.weetID, policy: visibility}).then((data: Weet) => {
      inform(this.$t('visibilityPolicy.success').toString());
      this.$emit('updated', data);
    });

    setActionEvent(ActionEvent.share_visibility_policy, {
      visibilityPolicy: visibility,
      weetID: this.weet.weetID
    });
  }

  private changeIndexationPolicy(value: boolean) {
    let visibility = VisibilityPolicy.PUBLIC;
    if (!value) {
      visibility = VisibilityPolicy.SEMI_PRIVATE;
    }

    store.dispatch(SEND_VISIBILITY_POLICY_WEET,
        {weetID: this.weet.weetID, policy: visibility}).then(() => {
      inform(this.$t('visibilityPolicy.success').toString());
    });

    setActionEvent(ActionEvent.share_visibility_policy, {
      visibilityPolicy: visibility,
      weetID: this.weet.weetID
    });
  }

  get isInternalVisibility(): boolean {
    if (this.weet) {
      return this.weet.visibilityPolicy === VisibilityPolicy.INTERNAL;
    } else {
      return false;
    }
  }

  get isPublic(): boolean {
    if (this.weet) {
      return this.weet.visibilityPolicy === VisibilityPolicy.PUBLIC;
    } else {
      return false;
    }
  }

  get isSemiPrivate(): boolean {
    if (this.weet) {
      return this.weet.visibilityPolicy === VisibilityPolicy.SEMI_PRIVATE;
    } else {
      return false;
    }
  }
}
</script>

<style scoped lang="scss">

.visibilityPolicyLink {
  align-items: center;
  font-size: 16px;
  width: 100%;

  .visibilityLine {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 16px;
    animation-duration: 0.2s;

    .publicLinkLabel {
      flex: 1;
      display: flex;
    }

    .offsetAlignCenter {
      margin-top: 4px;
    }

    .helpOffset {
      margin-top: 2px;
      margin-left: 8px;
    }
  }

  .commentPolicyLine {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 16px;
    animation-duration: 0.2s;
  }

}

</style>
