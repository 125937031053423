<template>
  <div>
    <w-modal :active.sync="loading" :modal="true">
      <div class="loadingPanelContainer" >
        <div class="loadingPanel">
          <div>
            <div>{{ $t('player.audio.loading') }}</div>
            <w-button class="buttonCancelLoadingAudio" color="outlined" :size="'small'"
            @click="cancelPreview">{{$t('generic.cancel')}}</w-button>
          </div>
        </div>
      </div>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {checkUrlExists, getUrlContent} from '@/utils/util';
// @ts-ignore
import srtParser from 'subtitles-parser';
import store from "@/store";
import {Weet} from "@/store/weet/weetModel";
import {SRT_2_SPEECH_GENERATE, VOICE_OVER_MEDIA} from "@/store/media/mediaAction";
import {Text2SpeachParams, VoiceOverParams} from "@/store/AIAvatar/aiavatarGenerationModel";
import {base64ToBlob} from "@/utils/audioUtil";
import {SubtitleAudioParams} from "@/store/subtitles/SubtitleModel";
import {SUBTITLE_VOICE_OVER_MEDIA} from "@/store/subtitles/SubtitlesAction";
import WModal from "@/components/wrapper/w-modal.vue";
import WButton from "@/components/wrapper/w-button.vue";
import {PLAYER_PLAYING} from "@/store/timeLine/timeLineAction";

@Component(
    {
      components: {WButton, WModal, WIcon, WToolTip},
    })
export default class SubtitleVoiceOverLoaderPlayer extends Vue {
  // this is the result
  @Prop({required:true})
  private weet!:Weet;

  private loading=false;

  private canceled=false;

  private subtitleData: any[] = [];

  private currentLine = '';


  get subitlteVoiceOverLang():string{
    return store.getters.getSubtitleVoiceOverLang
  }

  get subtitleToVoiceOverURL(){
    var url = "";
    if (this.weet) {
      for (var sub of this.weet.subtitles) {
        if (sub.lang === this.subitlteVoiceOverLang) {
          url = sub.url;
        }
      }
    }
    return url;
  }
  get voiceOverURL():string{
    var url = "";
    if (this.weet) {
      for (var sub of this.weet.subtitles) {
        if (sub.lang === this.subitlteVoiceOverLang) {
          url = sub.url.replace('.srt',"_"+this.subitlteVoiceOverLang+".mp3");
        }
      }
    }
    return url;
  }

  public mounted() {
    this.downloadSubtitle();
  }


  @Watch('subitlteVoiceOverLang')
  public async downloadSubtitle() {
    if (this.subtitleToVoiceOverURL) {
      // check if audio already generated
      const fileAlreadyExist=await checkUrlExists(this.voiceOverURL);
      if(fileAlreadyExist){
        this.changeAudioToSync(this.voiceOverURL);
      }else{
        // waiting
        store.dispatch(PLAYER_PLAYING,false)
        const params=new Text2SpeachParams();
        this.loading=true;
        this.canceled=false;
        params.text= await getUrlContent(this.subtitleToVoiceOverURL);
        const mediaBast64=await store.dispatch(SRT_2_SPEECH_GENERATE,params)
        const blob=base64ToBlob(mediaBast64,'audio/mp3');
        const url = URL.createObjectURL(blob);
        if(!this.canceled) {
          this.changeAudioToSync(url);
          this.loading = false;
          store.dispatch(PLAYER_PLAYING, true)
        }
        this.canceled=false;
        const subtitleAudioParams=new SubtitleAudioParams(blob,this.weet.weetID,this.subitlteVoiceOverLang)
        store.dispatch(SUBTITLE_VOICE_OVER_MEDIA, subtitleAudioParams);
      }

    } else {
      this.changeAudioToSync(null);
      this.subtitleData = [];
    }
  }

  private cancelPreview(){
    this.canceled=true;
    this.loading=false;
  }


  @Emit('onAudioToSync')
  private async changeAudioToSync(url:String|null ){
    return;
  }




  public onReceiveSubtitle(srtBrut: string) {
    this.subtitleData = srtParser.fromSrt(srtBrut, true);
  }


}
</script>

<style scoped lang="scss">

.loadingPanelContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .loadingPanel{
    border-radius: 12px;
    background: white;
    min-height: 100px;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    .buttonCancelLoadingAudio{
      margin-top: 16px;
    }
  }
}
</style>
