<template>
  <audio ref="audioElement" :src="urlToPlay"/>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import store from '@/store';
import {TimeEvent, TimeEventMedia} from '@/store/timeLine/timeEvent';
import {PLAYER_SUBTITLE, SWITCH_MINIMISE_STIKERS} from '@/store/timeLine/timeLineAction';
import {Media, PlayerMedia} from '@/store/media/mediaModel';
import {getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {log} from '@/utils/log';
import VueDraggableResizable from 'vue-draggable-resizable';
import {Weet} from '@/store/weet/weetModel';
import {getMediasForWeet} from '@/utils/weetUtil';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import delay from 'delay';
import {getBufferingAdvance} from '@/utils/videoUtil';


@Component({
  components: {WIcon, WButton, VueDraggableResizable},
  computed: {},
})
export default class AudioPlayer extends Vue {

  @Prop()
  private weet!: Weet;

  @Prop({default:undefined})
  private urlToPlay:string|undefined=undefined;

  @Prop({default: 0})
  private timePlayer!: number;

  private audioElement():HTMLAudioElement{
   return this.$refs['audioElement'] as HTMLAudioElement;
  }
  // Seek to time
  public seekInTimeLine(time) {
    if(!this.urlToPlay){
      return;
    }
    const audioElement = this.audioElement();

    if(audioElement) {
      const mediaEvent = getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY);
      if(mediaEvent){
        const seekTime=(time-mediaEvent.time)/1000;
        if(seekTime<audioElement.duration && seekTime>=0) {
          audioElement.currentTime = (time - mediaEvent.time) / 1000;
          if (this.play && (audioElement.paused || audioElement.ended)) {
            this.playMedia()
          }
        }else{
          this.pauseMedia();
        }
      }
    }
  }

  public pauseMedia() {
    if(!this.urlToPlay){
      return;
    }
    const audioElement = this.audioElement();
    if (audioElement) {
      audioElement.pause();
    }
  }

  public playMedia(time: number=-1) {
    if(!this.urlToPlay){
      return;
    }
    const audioElement = this.audioElement();
    if (audioElement) {
      if (time>0) {
        this.seekInTimeLine(time);
      }
      audioElement.play().catch((e) => {
        log.debug(e.message);
      });
    }
  }


  get play(): boolean {
    return store.getters.isPlaying;
  }

  get volume(): number {
    return store.getters.volume;
  }
  get speedMode(): number {
    return store.getters.speedMode;
  }

  @Watch('volume')
  private onChangeVolume() {
    const audioElement = this.audioElement();

    if (audioElement) {
      audioElement.volume = this.volume / 100;
    }
  }

  @Watch('speedMode')
  private onSpeedChange() {

    const audioElement = this.audioElement();
    if (audioElement) {
      audioElement.playbackRate = this.speedMode;
    }
  }


  @Watch('play')
  private togglePlay() {
    if(!this.urlToPlay){
      return;
    }
    if (this.play) {
      this.audioElement().volume = this.volume / 100;
      this.audioElement().playbackRate = this.speedMode;
      this.audioElement()?.play().then(()=>{});
    } else {
      this.audioElement()?.pause();
    }
  }

  @Watch('urlToPlay')
  private async onUrlToPlayChange() {
    this.audioElement().volume = this.volume / 100;
    this.audioElement().playbackRate = this.speedMode;
    await delay(500); // waiting loading audio metadata
    this.seekInTimeLine(this.timePlayer);
  }






  /**
   * Remove url on destroy
   */
  private beforeDestroy() {
    const audioElement = this.audioElement();
    if (audioElement) {
      audioElement.src = '';
    }
  }
}
</script>

<style lang="scss">
//* THIS STYLE MUST BE GLOBAL **/

.dragableSticker-handle {
  position: absolute;
  height: 0px;
  width: 0px;
  transition: all 300ms linear;
}

.dragableSticker-handle-tr {
  position: absolute;
  top: -12px;
  right: -12px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  color: white;
  cursor: nesw-resize;
  z-index: 11;
  background: rgba(0, 0, 0, 0.2);

  .iconResize {
    margin-top: 8px;
  }
}
</style>


<style lang="scss" scoped>
@import '@/scss/shadows.scss';


// commun
$stickerSize: 100%;
$stickerFullScreenSize: 230px;
$stickerFullScreenMonitorSize: 20vh;
.sticker {
  position: absolute;
  // border: 2px solid #FFFFFF;
  min-width: $stickerSize;
  min-height: $stickerSize;
  max-width: $stickerSize;
  max-height: $stickerSize;
  overflow: hidden;
  bottom: 0px;
  border-radius: 100% 100% 100% 16px;
  left: 0px;
  transition: all 0.5s;

  &:hover {
    opacity: 0.3;
  }

  &.minimize {
    transition: all 0.2s;
    transform: scale(0);
  }


  //fix issue in safari border-radius and overflow
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background: black;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  .stickerLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(-1.01) scaleY(1.01);
    transition: all 1s;


    .videoElement {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      animation-duration: 2s;
      width: 100%;
      height: 100%;
    }
  }

  &.fullscreenSticker {
    //  border: 3px solid #FFFFFF;
  }

  &.fullscreenPlayer {
  }

  &.fullscreenSticker.fullscreenPlayer {
  }


  .preloadingVideo {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
  }

}

.dragableSticker {
  border-radius: 100% 100% 100% 16px;
  border: none;
  cursor: move;
  transition: all 0.5s;
  @extend .stickersShadow;
}

.dragableSticker-active {
  @extend .shadow1;
}

.noAnimation {
  transition: none;
}


</style>
