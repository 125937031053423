export enum PageEvent {
    page_login = 'page_login',
    page_account_creation = 'page_account_creation',
    page_recovery_password = 'page_recovery_password',
    page_regenerate_password = 'page_regenerate_password',
    page_my_weet = 'page_my_weet',
    page_profil = 'page_profil',
    page_player = 'page_player',
    page_embedded_player = 'page_embedded_player',
    page_tunnel_ext_allowing = 'page_tunnel_ext_allowing',
    page_record_inapp = 'page_record_inapp',
    page_record_step_record = 'page_record_step_record',
    page_record_step_share_preview = 'page_record_step_share_preview',
    page_record_step_advanced_cut = 'page_record_advanced_cut',
    page_record_step_advanced_chapter = 'page_record_advanced_chapter',
    page_record_step_advanced_thumbnail = 'page_record_step_advanced_thumbnail',
    page_notification = 'page_notification',
    page_404 = 'page_404',
    page_404_weet = 'page_404_weet',
    page_403_weet = 'page_403_weet',
    page_superadmin_play = 'page_superadmin_play',
    page_workspace_create = 'workspace_create',
    page_workspace_setting = 'page_workspace_setting',
    page_workspace_setting_apparence = 'page_workspace_setting_apparence',
    page_workspace_setting_visibility = 'page_workspace_setting_visibility',
    page_workspace_setting_members = 'page_workspace_setting_members',
    page_workspace_setting_channel = 'page_workspace_setting_channel',
    page_workspace_setting_weet = 'page_workspace_setting_weet',
    page_workspace_setting_billing = 'page_workspace_setting_billing',
    page_workspace_payment_success = 'page_workspace_payment_success',
    page_workspace_setting_delete = 'page_workspace_setting_delete',
    page_workspace_invite = 'page_workspace_invite',
    page_unsubscribe_tutorial = 'page_unsubscribe_tutorial',
    noise_cancelling_tools='noise_cancelling_tools',
    host_video_tools='host_video_tools',
    compress_video_tools='compress_video_tools',
    record_video_tools='record_video_tools',
    share_video_tools='share_video_tools',
    add_image_video_tools='add_image_video_tools',
    chapter_a_video_tools='chapter_a_video_tools',
    cut_video_tools='cut_video_tools',
    transcribe_video_tools='transcribe_video_tools',
    translate_video_tools='translate_video_tools'
}


export enum ActionEvent {
    // logout
    auth_logout = 'auth_logout',
    // google auth
    auth_start_google = 'auth_start_google',
    auth_success_google = 'auth_success_google',
    auth_error_google = 'auth_error_google',
    account_creation_google = 'account_creation_google',
    // microsoft auth
    auth_start_microsoft = 'auth_start_microsoft',
    auth_success_microsoft = 'auth_success_microsoft',
    auth_error_microsoft = 'auth_error_microsoft',
    account_creation_microsoft = 'account_creation_microsoft',
    // apple auth
    auth_start_apple = 'auth_start_apple',
    auth_success_apple = 'auth_success_apple',
    auth_error_apple = 'auth_error_apple',
    account_creation_apple = 'account_creation_apple',
    // auth manual
    auth_manual_success = 'auth_manual_success',
    // creation
    account_creation_manual = 'account_creation_manual',
    // password
    password_ask_recovery = 'password_ask_recovery',
    password_success_recovery = 'password_success_recovery',
    // my weet
    filter_my_weet = 'filter_my_weet',
    filter_my_weet_direct_access = 'filter_my_weet_direct_access',
    // thumbnail
    thumb_button_edit = 'thumb_button_edit',
    thumb_button_duplicate = 'thumb_button_duplicate',
    thumb_button_play = 'thumb_button_play',
    thumb_button_copy = 'thumb_button_copy',
    thumb_button_share = 'thumb_button_share',
    thumb_change_title = 'thumb_change_title',
    thumb_button_delete = 'thumb_button_delete',
    // profil
    profil_change_password = 'profil_change_password',
    profil_button_delete = 'profil_button_delete',
    profil_delete_success = 'profil_delete_success',
    profil_button_setting = 'profil_button_delete',
    profil_photo_upload = 'profil_photo_upload',
    profil_photo_success = 'profil_photo_success',
    profil_photo_rotate_L = 'profil_photo_rotate_L',
    profil_photo_rotate_R = 'profil_photo_rotate_R',
    profil_photo_zoom_minus = 'profil_photo_zoom_minus',
    profil_photo_zoom_plus = 'profil_photo_zoom_plus',
    profil_change_profil = 'profil_change_profil',
    profil_change_email_notif_weet_view = 'profil_change_email_notif_weet_view',
    profil_change_email_notif_weet_comment = 'profil_change_email_notif_weet_comment',
    profil_change_email_notif_weet_update = 'profil_change_email_notif_weet_update',
    profil_change_email_notif_weet_share = 'profil_change_email_notif_weet_share',
    profil_change_email_notif_new_weet = 'profil_change_email_notif_new_weet',
    profil_change_web_push_notif = 'profil_change_web_push_notif',
    profil_disconnect_all_sessions_popup = 'profil_disconnect_all_sessions_popup',
    profil_disconnect_all_sessions_success = 'profil_disconnect_all_sessions_success',
    // player
    player_button_play_central = 'player_button_play_central',
    replayer_button_play_central = 'replayer_button_play_central',
    player_control_play = 'player_control_play',
    player_control_seek = 'player_control_seek',
    player_control_volume = 'player_control_volume',
    player_control_speed = 'player_control_speed',
    player_control_fullscreen = 'player_control_fullscreen',
    player_control_hide_cam = 'player_control_hide_cam',
    player_control_quick_play = 'player_control_quick_play',
    // comment
    comment_react_thumbs = 'comment_react_thumbs',
    comment_react_heart = 'comment_react_heart',
    comment_react_applause = 'comment_react_applause',
    comment_react_sad = 'comment_react_sad',
    comment_react_laugth = 'comment_react_laugth',
    comment_react_love = 'comment_react_love',
    comment_react_metal = 'comment_react_metal',
    comment_react_smile = 'comment_react_smile',
    comment_text_sent = 'comment_text_sent',
    comment_mic_start = 'comment_mic_start',
    comment_mic_success = 'comment_mic_success',
    comment_mic_cancel = 'comment_mic_cancel',
    comment_video_start = 'comment_video_start',
    comment_video_success = 'comment_video_success',
    comment_video_cancel = 'comment_video_cancel',
    comment_screen_start = 'comment_screen_start',
    comment_screen_success = 'comment_screen_success',
    comment_screen_cancel = 'comment_screen_cancel',
    comment_reply_text_sent = 'comment_reply_text_sent',
    comment_reply_button = 'comment_reply_button',
    comment_reply_button_player = 'comment_reply_button_player',
    comment_player_text_click = 'comment_player_text_click',
    comment_player_video_click = 'comment_player_video_click',
    comment_player_video_close = 'comment_player_video_close',
    comment_player_screen_click = 'comment_player_screen_click',
    comment_player_screen_close = 'comment_player_screen_close',
    comment_delete = 'comment_delete',
    comment_view_seek = 'comment_view_seek',
    comment_view_previous_seek = 'comment_view_previous_seek',
    // emoJ
    text_insert_emote = 'text_insert_emote',
    // setting
    setting_webcam_change = 'setting_webcam_change',
    setting_microphone_change = 'setting_microphone_change',
    setting_time_section_sound = 'setting_time_section_sound',
    setting_noise_cancelation = 'setting_noise_cancelation',
    setting_time_section_notification = 'setting_time_section_notification',
    setting_capture_quality = 'setting_capture_quality',
    // tunnel
    tunnel_ext_open = 'tunnel_ext_cancel',
    tunnel_teams_open = 'tunnel_teams_open',
    tunnel_slack_open = 'tunnel_slack_open',
    tunnel_ext_wc_allow = 'tunnel_ext_cancel',
    // allow WC
    webcam_allowed = 'webcam_allowed',
    screen_allowed = 'screen_allowed',
    // edit
    record_button_setting = 'record_button_setting',
    record_button_filter = 'record_button_filter',
    record_button_facetrack = 'record_button_facetrack',
    record_button_mute_video = 'record_button_mute_video',
    record_button_unmute_video = 'record_button_unmute_video',
    record_button_mute_mic = 'record_button_mute_mic',
    record_button_unmute_mic = 'record_button_unmute_mic',
    record_button_record = 'record_button_record',
    record_button_cancel = 'record_button_cancel',
    record_button_validate = 'record_button_validate',
    record_button_pause = 'record_button_pause',
    record_section_limit_timeOut = 'record_section_limit_timeOut',
    record_type_screen = 'record_type_screen',
    record_virtual_screen = 'record_virtual_screen',
    record_remove_section = 'record_remove_section',
    record_hardware = 'record_hardware',
    record_advanced_setting = 'record_advanced_setting',
    // cut mode
    cut_mode_smart_cut = 'cut_mode_smart_cut',
    cut_mode_smart_trim = 'cut_mode_smart_trim',
    cut_mode_add_section = 'cut_mode_add_section',
    cut_mode_remove_section = 'cut_mode_remove_section',
    // chapter mode
    chapter_mode_add_chapter = 'chapter_mode_add_chapter',
    chapter_mode_remove_chapter = 'chapter_mode_remove_chapter',
    // timeline selector
    timeline_selector_increase_zoom = 'timeline_selector_increase_zoom',
    timeline_selector_decrease_zoom = 'timeline_selector_decrease_zoom',
    timeline_selector_move_zoom = 'timeline_selector_move_zoom',
    // page player
    player_button_mini_share_copy_link = 'player_button_mini_share_copy_link',
    player_button_delete = 'player_button_delete',
    player_button_edit = 'player_button_edit',
    player_button_end_edit = 'player_button_end_edit',
    player_button_download = 'player_button_download',
    player_control_edit_timeline = 'player_control_edit_timeline',
    player_change_title = 'player_change_title',
    player_button_mini_share_link_mobile = 'player_button_mini_share_link_mobile',
// share
    player_button_share = 'player_button_share',
    player_copy_link = 'player_copy_link',
    share_send_contact = 'share_send_contact',
    share_delete_contact = 'share_delete_contact',
    share_visibility_policy = 'share_visibility',
    share_comment_policy = 'share_comment_policy',
    share_emote_policy = 'share_emote_policy',
    player_button_share_embedded_weet = 'player_button_share_embedded_weet',
    modify_share_contact = 'modify_share_contact',
// notification
    notification_click = 'notification_click',

    // Workspace
    workspace_menu_button_create = 'workspace_menu_button_create',
    workspace_image_upload = 'workspace_image_upload',
    workspace_init_skip = 'workspace_init_skip',
    workspace_init_button_creation = 'workspace_init_button_creation',
    workspace_init_invite_people = 'workspace_init_invite_people',
    workspace_button_delete = 'workspace_button_delete',
    workspace_button_update = 'workspace_button_update',
    workspace_button_theme = 'workspace_button_theme',
    workspace_accept_invitation = 'workspace_accept_invitation',
    workspace_ignore_invitation = 'workspace_ignore_invitation',
    workspace_channel_invite_popup_open = 'workspace_channel_invite_popup_open',
    workspace_channel_delete = 'workspace_channel_delete',
    workspace_channel_add = 'workspace_channel_add',
    workspace_members_delete = 'workspace_members_delete',
    workspace_members_role_update = 'workspace_members_role_update',
    workspace_members_invite_poup_open = 'workspace_members_invite_poup_open',
    workspace_members_add = 'workspace_members_add',
    workspace_payment_cancel = 'workspace_payment_cancel',
    workspace_payment_upgrade = 'workspace_payment_upgrade',
    workspace_payment_manage = 'workspace_payment_manage',
    workspace_payment_contact = 'workspace_payment_contact',
    workspace_visibility_update = 'workspace_visibility_update',
    workspace_weets_delete = 'workspace_weets_delete',

    channel_open_browse = 'channel_open_browse',
    channel_subscribed = 'channel_subscribed',
    channel_open_add = 'channel_open_add',
    channel_added = 'channel_added',
    channel_visibility = 'channel_visibility',

    unsubscribe_tutorial = 'unsubscribe_tutorial'
}
