export enum AdvancedEditingMode {
    NONE = 'NONE',
    CUT = 'CUT',
    CUTTER = 'CUTTER',
    MERGE = 'MERGE',
    CHAPTER = 'CHAPTER',
    THUMBNAIL = 'THUMBNAIL',
    SUBTITLE = 'SUBTITLE',
    INTERACTION = 'INTERACTION',
    VOICE_OVER='VOICE_OVER',
    DOWNLOAD="DOWNLOAD"
}
