<template>
  <Transition name="fade">
    <div class="popUpPin" v-show="!mustClose">
      <div class="arrowUp"></div>
      <div class="closeButton" @click="onClickClose">
        <w-icon color="white" icon="close-circle-outline"/>
      </div>
      <div class="whiteTitle">
        {{ $t('extentionPin.title') }}
      </div>
      <div class="whiteLabel">
        {{ $t('extentionPin.installedSuccess') }}<br/>
        {{ $t('extentionPin.underline1') }}
        <w-icon icon="puzzle" size="small"/>
        {{ $t('extentionPin.underline2') }}
      </div>
      <img class="marginImage" src="@/assets/pinextension.gif" alt="pinExtention">
    </div>
  </Transition>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {isPluginInstalled, openExtentionStore, sendMessageToExtention} from '@/utils/extentionUtil';
import {IS_INSTALLED} from '@/store/recordingState/recordStateAction';
import WModal from '@/components/wrapper/w-modal.vue';
import {User} from '@/store/user/userModel';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import TopCenter from '@/components/layout/TopCenter.vue';
import {SETUP_EXT, SETUP_SCREENCAST} from '@/store/setup/setupAction';
import {EXTENTION_ID} from '@/store/conf/api';
import WIcon from '@/components/wrapper/w-icon.vue';

@Component({
  components: {WIcon, TopCenter, WButton, WModal},
})
export default class PinPlugin extends Vue {

  private mustClose: boolean = false;

  public onClickClose() {
    this.mustClose = true;
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.popUpPin {
  position: absolute;
  top: 16px;
  z-index: 200;
  right: 16px;
  border-radius: 12px;
  background: var(--dark);
  width: 320px;
  height: 416px;
  padding: 24px;
  text-align: left;
  @extend .shadow1;

  .closeButton {
    position: absolute;
    right: 12px;
    cursor: pointer;
    top: 12px;
  }

  .whiteTitle {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: white;
    margin-bottom: 24px;
  }

  .arrowUp {
    width: 0;
    height: 0;
    margin-top: -36px;
    right: 64px;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid var(--dark);
  }

  .whiteLabel {
    color: white;
  }

  .marginImage {
    margin-top: 24px;
    border-radius: 8px;
  }
}

</style>
