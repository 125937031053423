<template>
  <div class="timeLineContainer">
    <div v-for="(te,index) in getTimeEvents" :key="'timeEvent'+index">
      <TimeLineDebugElement :key="te.time+'timeEventKeyDebugTimeLine'" :timeEvent="te"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import TimeLineElement from '@/components/recorder/timeLine/TimeLineElement.vue';
import {PAUSE_RECORDING, START_RECORDING, STOP_RECORDING} from '@/store/recordingState/recordStateAction';
import {Media} from '@/store/media/mediaModel';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import TimeLineDebugElement from '@/components/recorder/timeLine/debug/TimeLineDebugElement.vue';

@Component({
  components: {TimeLineDebugElement, TimeLineElement},
})
export default class TimeLineDebugVizualizer extends Vue {


  get getTimeEvents(): TimeEvent[] {
    return store.getters.getTimeEvent;
  }


}
</script>

<style scoped lang="scss">
.timeLineContainer {
  display: flex;
  max-width: 100%;
  overflow: auto;
}
</style>
