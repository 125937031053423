<template>
  <EmptyWeetPlayer v-if="isWeetEmpty"
                   :weetID="weetID"/>
  <div v-else data-test="weetPlayer" ref="myWeetPlayer"
       :class="'layout '+ iosFullscreenClass +' '+nativePlayerClass+' '+isFullResponsiveClass "
       @mousemove="onMouseOver">
    <div class="mainVideoContainer ">
      <div class="mainLayout">
        <MainVideoPlayer :full-responsive="fullResponsive" :maxHeight="maxHeight"
                         v-if="weet && !isNativePlayer" :weet="weet"
                         ref="mainVideo"
                         @mediaTime="onMediaTime"
                         :timePlayer="timePlayer"
                         @waiting="onWaitingMainVideo"
                         @playing="onPlayingMainVideo"/>
        <NativeVideoPlayer v-if="weet && isNativePlayer && !isWeetHidden" :weet="weet"/>
      </div>

      <!-- No need stickers on Native Player -->
      <Transition name="fade">
        <StickerVideoPlayer ref="stickerVideo" :full-responsive="fullResponsive"
                            :weet="weet" :miniSticker="miniSticker"
                            @mediaTime="onMediaTime" v-if="weet && !isNativePlayer"
                            v-show="!isEndLayout && !firstScreen"
                            :timePlayer="timePlayer"
                            @waiting="onWaitingSticker"
                            @playing="onPlayingSticker"/>
      </Transition>
      <AudioPlayer :weet="weet" :url-to-play="audioUrl|| audioVoiceOverURL" ref="audioPlayer" :time-player="timePlayer"/>
      <!-- desactive before find a solution
      the first section must be the thumbnail in 16/9 -->
      <transition name="fade">
        <WeetThumbnailPlayer :weet="weet" v-show="firstScreen"/>
      </transition>
      <!-- full layout control -->
      <Transition :name="nameOfZoomAnime" v-if="!isWeetHidden">
        <div class="playContainer" v-if="!isNativePlayer" v-show="showControl && !isEndLayout"
             @click="onClickPlay">
          <w-icon icon="pause" class="logoPlay" v-show="play" color="dark"/>
          <w-icon icon="play" class="logoPlay" v-show="!play" color="dark"/>
        </div>
      </Transition>
      <Transition name="fade">
        <div class="loadingItem" v-if="!isNativePlayer" v-show="!mainVideoPlaying || !stickerPlaying">
          <img alt="loading" src="@/assets/icons/loading.gif" width="24px"/>
        </div>
      </Transition>
      <Transition :name="nameOfZoomAnime" v-if="!isWeetHidden">
        <div class="playContainer background" v-if="!isNativePlayer" v-show="isEndLayout "
             @click="onClickReplay">
          <w-icon icon="replay" class="logoPlay" v-show="!play" color="dark"/>
          <w-button icon="record" color="white" class="buttonEditWeet" v-if="canEditThisWeet && !fullResponsive"
                    @click="editAWeet">{{ $t('player.replySection') }}
          </w-button>
        </div>
      </Transition>


      <!-- Title of section -->
      <Transition name="fade">
        <div class="titleSection" v-show="enabledChapter && isMustShowSectionTitle && !firstScreen">
          <Transition name="fade">
            <div class="labelSectionTitleContainer" v-show="isMustShowSectionTitle">
              <div class="labelTitle">{{ titleOfSection }}</div>
            </div>
          </Transition>
        </div>
      </Transition>


      <Transition name="zoom">
        <div class="subtitleContainer" :class="!showControl?'bottom':''"
             v-show="subtitleLang && subtitleToShow && !firstScreen">
          <SubtitlePlayer class="subtitleLine" :subtitle="subtitleURL" @changeSubtitleLine="onChangeSubtitle"
                          :time="timePlayer"/>
        </div>
      </Transition>

    </div>

    <!-- PLAYER OF EMOTICON COMMENT -->
    <CommentEmotePlayer v-if="weet" :weet="weet" :timePlayer="timePlayer"/>

    <!-- IN APP POPIN COMMENT -->
    <!--    <WeetPlayerCommentPreview :timePlayer="timePlayer"/>-->

    <!-- CONTROL BAR -->
    <transition :name="nameOfFadeAnime">
      <!-- control bar not aivailable in native mode -->
      <div class="controlBar" v-show="showControl && !firstScreen" v-if="!isNativePlayer">
        <WeetControlPlayer ref="playerControls" v-if="weet && !isWeetHidden" :weet="weet"
                           :fullScreenElement="fullScreenElement"
                           :timeStart="timeStart" @seek="onSeek"
                           @replay="onClickReplay"
                           @timerPlayer="onTimePlayer" @playPlayer="onClickPlay"
                           :waiting="!stickerPlaying || !mainVideoPlaying"
                           :sectionSelected="sectionSelected"
        />

        <Transition name="fade" v-if="isEmoteEnabled && !isWeetHidden">
          <div class="reactLine" v-show="showControl && timePlayer>500" v-if="!isNativePlayer && emoteAivailable">
            <CommentEmoteCreator url="/emote/smile.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_smile"/>
            <CommentEmoteCreator url="/emote/laugth.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_laugth"/>
            <CommentEmoteCreator url="/emote/love.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_love"/>
            <CommentEmoteCreator url="/emote/applause.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_applause"/>
            <CommentEmoteCreator url="/emote/sad.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_sad"/>
            <CommentEmoteCreator url="/emote/thumbs.png" :weet="weet"
                                 :amplitude-action="actionEventEnum.comment_react_thumbs"/>
          </div>
        </Transition>
      </div>
    </transition>

    <!-- LOAD COMMENT DURING THE TIMELINE -->
    <CommentLoader v-if="weet!==undefined" :weetID="weetID" :timePlayer="timePlayer"/>
    <WeetTimeLinePlayer v-if="weet" :weet="weet" @seek="onSeek" :timePlayer="timePlayer"/>

    <WeetBlockerPlayer :weet="weet"/>
    <!-- fix the thumbnil if need -->
    <ThumbnailFixGenerator :weet="weet" v-if="isOwner"/>

    <!-- Annotation Player -->
    <WeetPlayerAnnotations v-if="weet" :weet="weet" :timePlayer="timePlayer"/>

    <!-- Voice over subtitle Loader -->
    <SubtitleVoiceOverLoaderPlayer v-if="weet && isSubtitleVoiceGeneration" :weet="weet" @onAudioToSync="onAudioToSync"/>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {LOAD_WEET_FOR_PLAYING} from '@/store/weet/weetAction';
import {
  CLEAR_SUBTITLE,
  PLAYER_PLAYING,
  SEEK_PLAYER,
  SWITCH_PLAYER_FULLSCREEN,
  TIMER_PLAYER
} from '@/store/timeLine/timeLineAction';
import screenfull from 'screenfull';
import WIcon from '@/components/wrapper/w-icon.vue';
import router from '@/router';
import CommentLoader from '@/components/play/comment/CommentLoader.vue';
import {Weet} from '@/store/weet/weetModel';
import mobileDetect from 'mobile-device-detect';
import MainVideoPlayer from '@/components/play/player/weetPlayerSubComponent/MainVideoPlayer.vue';
import StickerVideoPlayer from '@/components/play/player/weetPlayerSubComponent/StickerVideoPlayer.vue';
import WeetControlPlayer from '@/components/play/player/weetPlayerSubComponent/WeetControlPlayer.vue';
import WeetTimeLinePlayer from '@/components/play/player/weetPlayerSubComponent/WeetTimeLinePlayer.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {SWITCH_TO_NATIVE_PLAYER} from '@/store/setup/setupAction';
import NativeVideoPlayer from '@/components/play/player/weetPlayerSubComponent/NativeVideoPlayer.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';
import CommentEmoteCreator from '@/components/comment/CommentEmoteCreator.vue';
import {
  canEditThisWeet, checkExportedVersion,
  createUnsavedEditingWeet,
  getWeetDuration,
  isOwner,
  isWeetIsReady,
  launchWeetCreation
} from '@/utils/weetUtil';
import {RESET_COMMENTMODULE} from '@/store/comment/commentAction';
import CommentEmotePlayer from '@/components/play/comment/CommentEmotePlayer.vue';
import WeetPlayerCommentPreview from '@/components/play/player/weetPlayerSubComponent/WeetPlayerCommentPreview.vue';
import {CommentPolicy} from '@/store/weet/CommentPolicy';
import {EXTERNAL_TOOL_INIT} from '@/store/integration/integrationAction';
import {RETURN_TO_ASKED_URL} from '@/store/auth/authAction';
import {inform} from '@/utils/dialog';
import WButton from '@/components/wrapper/w-button.vue';
import {hideGlobalTour} from '@/utils/tour';
import delay from 'delay';
import prettyMilliseconds from 'pretty-ms';
import {getFirstMediaPlayEvent, isChapterEventOnTimeLine} from '@/utils/timeLineUtils';
import WModal from '@/components/wrapper/w-modal.vue';
import WeetBlockerPlayer from '@/components/play/player/weetPlayerSubComponent/WeetBlockerPlayer.vue';
import WeetSpeedSelectorPlayer from '@/components/play/player/weetPlayerSubComponent/WeetSpeedSelectorPlayer.vue';
import EmptyWeetPlayer from '@/components/play/player/EmptyWeetPlayer.vue';
import ThumbnailFixGenerator from '@/components/recorder/thumbnailGenerator/ThumbnailFixGenerator.vue';
import WeetThumbnailPlayer from '@/components/play/player/weetPlayerSubComponent/WeetThumbnailPlayer.vue';
import {manageThemeForWorkspace} from "@/utils/workspaceUtil";
import {EmotePolicy} from "@/store/weet/EmotePolicy";
import SubtitlePlayer from "@/components/play/player/weetPlayerSubComponent/SubtitlePlayer.vue";
import WeetPlayerAnnotations from "@/components/play/player/weetPlayerSubComponent/WeetPlayerAnnotations.vue";
import {SELECT_MODE, SET_VOICE_OVER_PREVIEW} from "@/store/advancedEditing/advancedEditingAction";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import {SpeachEvent, SpeachEventName} from "@/speach/speachEvent";
import {Segment} from "@/store/timeLine/timeEvent";
import AudioPlayer from "@/components/play/player/weetPlayerSubComponent/AudioPlayer.vue";
import SubtitleVoiceOverLoaderPlayer
  from "@/components/play/player/weetPlayerSubComponent/SubtitleVoiceOverLoaderPlayer.vue";


@Component({
  components: {
    SubtitleVoiceOverLoaderPlayer,
    AudioPlayer,
    WeetPlayerAnnotations,
    SubtitlePlayer,
    WeetThumbnailPlayer,
    ThumbnailFixGenerator,
    WeetSpeedSelectorPlayer,
    WeetBlockerPlayer,
    WModal,
    WButton,
    WeetPlayerCommentPreview,
    CommentEmotePlayer,
    CommentEmoteCreator,
    UserAvatar,
    NativeVideoPlayer,
    WeetTimeLinePlayer,
    WeetControlPlayer,
    StickerVideoPlayer,
    MainVideoPlayer,
    CommentLoader,
    WIcon,
    EmptyWeetPlayer,
  },
})
export default class WeetPlayer extends Vue {
  @Prop({default: ''})
  private weetID!: string;
  @Prop({default: 0})
  private timeStart!: number;

  @Prop({default: true})
  private emoteAivailable!: boolean;

  @Prop({default: false})
  private miniSticker!: boolean;

  @Prop({default: true})
  private enabledChapter!: boolean;

  // allow to play the weet in editmode
  @Prop({default: false})
  private editMode!: boolean;

  @Prop({default: 'myWeetPlayer'})
  private fullScreenElement!: string;

  @Prop({default: false})
  private fullResponsive!: boolean;

  @Prop({default: null})
  private sectionSelected!: Segment | null;

  @Prop({default: undefined})
  private audioUrl: string | undefined = undefined;

  @Prop({default:false})
  private autoplay!: boolean;

  private audioVoiceOverURL: string = "";

  get isOwner(): boolean {
    if (this.weet) {
      return isOwner(this.weet);
    } else {
      return false;
    }
  }

  @Prop({default: -1})
  private maxHeight!: number;

  private highlight: boolean = false;
  private timeOutHighlight: any = 0;
  private timeTimeOut: number = 2000;
  private mobile: boolean = false;
  private transcriptMediaTime = 0;
  private subtitleToShow: boolean = false;
  private weet: Weet | null = null;

  private timePlayer: number = 0;

  private stickerPlaying: boolean = false;
  private mainVideoPlaying: boolean = false;

  // title section animation
  private titleOfSectionChange: boolean = false;
  private timeOutAnimationTitleSection: any;

  // refresh
  private refreshAivailable: boolean = true;

  // show firstscreen or not
  private firstScreen: boolean = false;


  get actionEventEnum(): any {
    return ActionEvent;
  }

  get subtitleLang(): string {
    return store.getters.getSubtitleLang;
  }

  get subtitleURL(): string {
    var url = "";
    if (this.weet) {
      for (var sub of this.weet.subtitles) {
        if (sub.lang === this.subtitleLang) {
          url = sub.url;
        }
      }
    }
    return url;
  }

  get isSubtitleVoiceGeneration(){
    return store.getters.isSubtitleVoiceGeneration;
  }
  get isWeetReady(): boolean {
    if (this.weet !== null) {
      return isWeetIsReady(this.weet);
    } else {
      return false;
    }
  }

  get canEditThisWeet(): boolean {
    if (this.weet) {
      return canEditThisWeet(this.weet);
    } else {
      return false;
    }
  }

  get titleOfSection(): string {
    return store.getters.getPlayerTitle;
  }

  get isWeetHidden(): boolean {
    return this.weet?.hidden || false;
  }

  get isWeetEmpty(): boolean {
    return this.weet?.medias.length === 0;
  }


  @Watch('titleOfSection')
  private onTitleOfSectionChange() {
    this.titleOfSectionChange = true;
    clearTimeout(this.timeOutAnimationTitleSection);
    this.timeOutAnimationTitleSection = setTimeout(() => {
      this.titleOfSectionChange = false;
    }, 5000);
  }

  private getSimpleTime(time: number) {
    return prettyMilliseconds(time, {colonNotation: true, secondsDecimalDigits: 0});
  }

  @Watch('play')
  private onChangePlay(value: boolean) {
    if (value) {
      this.firstScreen = false;
    }
    if (value) {
      window.parent.postMessage(new SpeachEvent(SpeachEventName.WEET_PLAYING, this.timePlayer), "*")
    } else {
      window.parent.postMessage(new SpeachEvent(SpeachEventName.WEET_PAUSING, this.timePlayer), "*")
    }

  }


  get isMustShowSectionTitle(): boolean {
    // !! Pan Pan your a boolean
    return this.showControl && !this.isEndLayout && this.isChapterMode
        || (this.titleOfSectionChange && !!this.titleOfSection);
  }

  get showControl(): boolean {
    return this.highlight || !this.play;
  }

  get isChapterMode(): boolean {
    if (this.weet) {
      return isChapterEventOnTimeLine(this.weet?.timeLine);
    } else {
      return false;
    }
  }

  get isEditable(): boolean {
    if (this.weet) {
      return canEditThisWeet(this.weet);
    } else {
      return false;
    }
  }

  get isEndLayout() {
    if (this.weet) {
      return getWeetDuration(this.weet) < this.timePlayer + 200;
    } else {
      return false;
    }
  }


  get play(): boolean {
    return store.getters.isPlaying;
  }

  get isFullscreen(): boolean {
    return store.getters.isFullscreenMode;
  }

  get isBuffering(): boolean {
    return (store.getters.getMainVideoBuffering + store.getters.getSecondVideoBuffering) > 0;
  }

  get isNativePlayer(): boolean {
    // no native player if video not ready
    if ((this.weet && !this.weet.exportAivailable) || !this.mobile) {
      return false;
    }
    return store.getters.isNativePlayer === true;
  }

  get nameOfFadeAnime(): string {
    if (this.mobile) {
      return '';
    } else {
      return 'fade';
    }
  }

  get nameOfZoomAnime(): string {
    if (this.mobile) {
      return '';
    } else {
      return 'fade';
    }
  }

  get isCommentEnabled(): boolean {
    if (this.weet) {
      return this.weet.commentPolicy !== CommentPolicy.NOBODY_CAN_COMMENT;
    } else {
      return false;
    }
  }

  get isEmoteEnabled(): boolean {
    if (this.weet) {
      return this.weet.emotePolicy !== EmotePolicy.NOBODY_CAN_REACT;
    } else {
      return false;
    }
  }

  private onMediaTime(time) {
    this.transcriptMediaTime = time;
  }

  get seekPlayer(): number {
    return store.getters.getSeekTimerPlayer;
  }

  @Watch('seekPlayer')
  private externalSeek() {
    if (this.seekPlayer > -1) {
      this.onSeek(this.seekPlayer);
      store.dispatch(SEEK_PLAYER, -1);
    }
  }

  private onSeek(time) {
    // console.log('Weet Player .onSeek ', time, this.mainVideoPlaying, this.stickerPlaying);
    this.onTimePlayer(time);
    // we wait the timePlayer Propagation
    this.$nextTick(() => {
      if (this.$refs.mainVideo) {
        // @ts-ignore
        this.$refs.mainVideo.seekInTimeLine(time);
      }
      if (this.$refs.stickerVideo) {
        // @ts-ignore
        this.$refs.stickerVideo.seekInTimeLine(time);
      }
      if (this.$refs.playerControls) {
        // @ts-ignore
        this.$refs.playerControls.seek(time);
      }
      if (this.$refs.audioPlayer) {
        // @ts-ignore
        this.$refs.audioPlayer.seekInTimeLine(time)

      }
    });
  }

  private onTimePlayer(time) {
    this.timePlayer = time;
  }

  @Watch('showControl')
  private emitShowControl() {
    this.$emit('controlChange', this.showControl);
  }

  private async onClickReplay() {
    if (this.weet) {
      // get time of the first media
      const firstEvent = getFirstMediaPlayEvent(this.weet?.timeLine);
      if (firstEvent) {
        this.onSeek(firstEvent.time);
        store.dispatch(TIMER_PLAYER, firstEvent.time);
      }
    }

    await delay(100);
    this.onClickPlay();

  }

  private onClickPlay() {
    store.dispatch(PLAYER_PLAYING, !this.play);
    setActionEvent(ActionEvent.player_button_play_central, {
      player_play: this.play,
      weetID: this.weetID
    });
    if (this.play) {
      this.playPlayers();
    } else {
      this.pausePlayers();
    }
  }

  private onMouseOver() {
    this.highlight = true;
    clearTimeout(this.timeOutHighlight);
    this.timeOutHighlight = setTimeout(() => {
      this.highlight = false;
    }, this.timeTimeOut);
  }

  private onChangeSubtitle(txt) {
    if (txt.trim().length > 0) {
      this.subtitleToShow = true;
    } else {
      this.subtitleToShow = false;
    }
  }

  private async onAudioToSync(url: string | null) {
    const mustContinuePlaying = this.play;
    store.dispatch(PLAYER_PLAYING, false);
    this.pausePlayers();
    if (url) {
      store.dispatch(SET_VOICE_OVER_PREVIEW, true);
      this.audioVoiceOverURL = url
    } else {
      store.dispatch(SET_VOICE_OVER_PREVIEW, false);
      this.audioVoiceOverURL = ""
    }
    await delay(500);
    if (mustContinuePlaying) {
      // relaunch playing
      this.onClickPlay();
    }
  }

  @Watch('isFullscreen')
  private onChangeFullScreenMode() {

    const element = this?.$parent?.$refs[this.fullScreenElement] as HTMLElement;
    if (screenfull.isEnabled) {
      if (!this.isNativePlayer) {
        // @ts-ignore
        if (this.isFullscreen !== screenfull.isFullscreen) {
          screenfull.toggle(element);
        }
      }
    }
  }


  private async mounted() {
    if (this.weetID !== '') {

      // switch to native player if need
      this.needToSwitchOnNaticePlayer();

      store.dispatch(EXTERNAL_TOOL_INIT);
      // desactivated for the moment
      // this.firstScreen = true;
      while (this.refreshAivailable && (!this.weet || !isWeetIsReady(this.weet))) {
        await this.loadWeet();
        await delay(2000);
      }
      // check exported version
      if (this.canEditThisWeet && this.weet) {
        checkExportedVersion(this.weet);
      }
      if (screenfull.isEnabled) {
        screenfull.on('change', this.onScreenFullScreen);
      }
      if(this.autoplay){
        store.dispatch(PLAYER_PLAYING, true);
        this.playPlayers();
      }
    }
  }

  private needToSwitchOnNaticePlayer() {
    this.mobile = mobileDetect.isMobile;
    // if we are on mobile, but no type of player define, we switch to native player
    if (this.mobile) {
      if (store.getters.isNativePlayer === undefined) {
        store.dispatch(SWITCH_TO_NATIVE_PLAYER, true);
      }
    }
  }

  private async loadWeet() {
    if (!this.editMode) {
      try {
        await store.dispatch(LOAD_WEET_FOR_PLAYING, this.weetID);
        this.weet = store.getters.getWeet(this.weetID);
        if (this.weet?.workspaces && this.weet?.workspaces.length > 0) {
          const workspaceWeet = this.weet.workspaces[0];
          manageThemeForWorkspace(workspaceWeet);
        } else {
          manageThemeForWorkspace(undefined);
        }
      } catch (err: any) {
        if (err.status === 403) {
          // ask authentication
          if (store.getters.isAuthenticated) {
            router.push({name: 'AccessDenied'});
          } else {
            inform(this.$t('player.accessDenied').toString());
            store.dispatch(RETURN_TO_ASKED_URL, document.location.href);
            router.push({name: 'Authentication'});
          }
        } else {
          router.push({name: 'NotFoundWeet'});
        }
      }
    } else {
      this.weet = createUnsavedEditingWeet(this.weetID);
    }
  }

  get timeLineRecorder() {
    return store.getters.getTimeEvent;
  }

  get listOfMedia() {
    return store.getters.getMedias;
  }

  @Watch('timeLineRecorder')
  private onTimeLineChange() {
    if (this.editMode) {
      this.weet = createUnsavedEditingWeet(this.weetID);
    }
  }

  @Watch('listOfMedia', {
    immediate: false, // run immediately
    deep: true // detect when the blob arrive
  })
  private onMediaChange() {
    if (this.editMode) {
      this.weet = createUnsavedEditingWeet(this.weetID);
    }
  }

  get iosFullscreenClass(): string {
    if (!screenfull.isEnabled) {
      if (this.isFullscreen) {
        return 'iosFullscreen';
      }
    }
    return '';
  }

  get nativePlayerClass(): string {
    if (this.mobile && store.getters.isNativePlayer) {
      return 'nativePlayer';
    } else {
      return '';
    }
  }

  get isFullResponsiveClass(): string {
    if (this.fullResponsive) {
      return 'fullResponsiveClass';
    } else {
      return '';
    }
  }


  private onScreenFullScreen(event) {
    if (event.target === this?.$parent?.$refs[this.fullScreenElement]
        || document.fullscreenElement === null) {
      this.setFullScreen();
    }
  }

  private setFullScreen() {
    // @ts-ignore
    store.dispatch(SWITCH_PLAYER_FULLSCREEN, screenfull.isFullscreen);
  }

  private async editAWeet() {
    if (this.weet) {
      if (this.isFullscreen) {
        this.onChangeFullScreenMode();
        store.dispatch(SWITCH_PLAYER_FULLSCREEN, false);
        await delay(500);

      }
      hideGlobalTour();

      setActionEvent(ActionEvent.player_button_end_edit);
      store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
      launchWeetCreation(this.weet.weetID);
    }
  }


  // keep the last timeline where the video is stuck (waiting)
  private currentTimeLineStuck: number | undefined;

  private onWaitingSticker() {
    // console.log('Waiting Sticker');
    this.currentTimeLineStuck = this.timePlayer;
    this.stickerPlaying = false;
    this.pausePlayers();
  }

  private onPlayingSticker() {
    // console.log('playing Sticker');
    this.stickerPlaying = true;
    this.playPlayers();
  }

  /**
   * When the main video is stuck
   * @private
   */
  private onWaitingMainVideo() {
    // console.log('Waiting Main');
    this.currentTimeLineStuck = this.timePlayer;
    this.mainVideoPlaying = false;
    this.pausePlayers();
  }

  /**
   * When the main video is release
   * @private
   */
  private onPlayingMainVideo() {
    // console.log('playing Main');
    this.mainVideoPlaying = true;
    this.playPlayers();

  }

  private pausePlayers() {
    if (this.$refs.mainVideo) {
      // @ts-ignore
      this.$refs.mainVideo.pauseMedia();
    }
    if (this.$refs.stickerVideo) {
      // @ts-ignore
      this.$refs.stickerVideo.pauseMedia();
    }
  }

  private playPlayers() {
    if (this.mainVideoPlaying && this.stickerPlaying &&
        store.getters.isPlaying) {

      if (this.$refs.mainVideo) {
        // @ts-ignore
        this.$refs.mainVideo.playMedia(this.currentTimeLineStuck);
      }
      if (this.$refs.stickerVideo) {
        // @ts-ignore
        this.$refs.stickerVideo.playMedia(this.currentTimeLineStuck);
      }
      if (this.$refs.playerControls && this.currentTimeLineStuck) {
        // @ts-ignore
        this.$refs.playerControls.seek(this.currentTimeLineStuck);
      }
      this.currentTimeLineStuck = undefined;
    }
  }


  private beforeDestroy() {
    this.refreshAivailable = false;
    // clean the plauyer
    store.dispatch(RESET_COMMENTMODULE);
    store.dispatch(CLEAR_SUBTITLE);

    if (screenfull.isEnabled) {
      // @ts-ignore
      screenfull.off('change', this.onScreenFullScreen);
    }

    // now we reload the good theme
    const ws = store.getters.getSelectedWorkspace;
    manageThemeForWorkspace(ws);
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';
@import '@/scss/animation.scss';
@import '@/scss/gradiant.scss';
@import "@/scss/gridBreakPoint.scss";


.layout {
  @extend .shadow1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  flex-wrap: wrap;
  min-height: 385px;
  // background: var(--dark);
  background: var(--black);
  border-radius: 8px;
  // to fix an issue in safari
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  // overflow: hidden;
  // background: var(--black);
  @include GRID2X() {
    min-height: 385px;
  }
  @include GRID1X() {
    min-height: 385px;
  }

  .mainVideoContainer {
    width: 100%;
    min-height: 385px;
    height: 100%;
    //position: absolute;
    display: flex;
    align-items: center;
    //top: 0;

    .mainLayout {
      position: relative;
      flex: 1;
      height: 100%;
    }


    .subtitleContainer {
      position: absolute;
      animation-duration: 0.2s;
      width: calc(100% - 256px);
      min-width: 300px;
      height: 48px;
      left: 128px;
      line-height: 24px;
      bottom: 104px;
      z-index: 11; // to be overlay than the stickers
      text-align: center;
      transition: bottom 0.5s ease;

      &.bottom {
        bottom: 48px;
      }

      .subtitleLine {
        color: #FFF;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 900;
        text-shadow: 0px 0px 3px black;
        display: inline-block;
        padding: 4px;
        padding-left: 8px;
        padding-right: 8px;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.fullResponsiveClass {
    min-height: 285px;

    .mainVideoContainer {
      min-height: 285px;
    }
  }

  &.nativePlayer {
    height: auto;
    min-height: 0;
    border-radius: 0;

    .mainVideoContainer {
      height: auto;
      min-height: 0;

      .mainLayout {
        height: auto;
      }
    }
  }

  .panelChapter {
    position: absolute;
    top: 48px;
    left: 16px;
    z-index: 10;
    width: calc(40%);
    min-width: 280px;

    .itemChapter {
      max-width: 600px;
      width: 100%;

      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      .timeItem {
        color: var(--dark);
        font-weight: 900;
      }
    }
  }

}

.titleSection {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0px;
  left: 0px;
  height: 100px;
  color: white;
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.0));

  .labelSectionTitleContainer {
    display: flex;
    align-items: center;
    margin: 16px;
    animation-delay: 0.2s;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.6;
    }

    .containerOpenChapter {
      align-self: flex-start;
    }

    .labelTitle {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      // margin-top: 3px; // fix problem align icon
      margin-left: 8px;
      max-width: calc(100% - 128px);
      font-size: 20px;
      font-weight: 900;
      text-align: left;
    }
  }

}

.loadingItem {
  position: absolute;
  top: 16px;
  right: 16px;
}

.playContainer {
  position: absolute;
  width: 100%;
  top: 0;
  text-align: center;
  height: 100%;
  cursor: pointer;
  animation-duration: 0.2s;


  .logoPlay {
    border-radius: 60px;
    position: absolute;
    left: calc(50% - 30px);
    padding: 8px;
    top: calc(50% - 30px);
    background-color: rgba(255, 255, 255, 1);
    @extend .shadow3;
    width: 60px;
    height: 60px;
    transition: transform ease-in-out 0.2s;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .buttonEditWeet {
    position: absolute;
    top: 20%;
    left: calc(50% - 118px);
    transition: transform ease-in-out 0.2s;
    @extend .shadow3;
    border-radius: 16px;

    &:hover {
      transform: scale(1.1);
    }
  }


  .bufferingRing {
    border-radius: 60px;
    position: absolute;
    display: inline-block;
    margin-left: -35px;
    margin-top: -35px;
    top: 50%;
    border-left: solid 5px #34cc96;
    border-bottom: solid 5px #cf61da;
    border-right: solid 5px #4a90e2;
    border-top: solid 5px #7464d1;
    width: 70px;
    height: 70px;
    animation: fa-spin 1s ease-in-out infinite;
  }

}

.buttonBlurBackground {
  border-radius: 50px;
  height: 50px;
  width: 100px;
  text-align: center;
  //@extend .shadow1;
  padding: 8px;

  .containerButton {
    position: absolute;
  }

  .timer {
    margin-left: 0px;
    margin-top: 4px;
  }

  &.blur {
    // backdrop-filter: blur(100px);
  }
}

.finishBar {
  position: absolute;
  bottom: 124px;
  right: 0px;
}

.controlBar {
  animation-duration: 1s;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;

  //&:hover {
  //  z-index: 12;
  //}
}


.layout.iosFullscreen {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100%;
}

.reactLine {
  animation-duration: 0.5s;
  position: absolute;
  bottom: 4px;
  width: 320px;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  left: calc(50% - 320px / 2);

  .emoteComment {
    animation-duration: 0.5s;
  }
}


</style>
