import {getUrlContent} from "@/utils/util";
import SrtParser2, {Line} from "srt-parser-2";

/**
 * Extract the content of an SRT
 * @param url
 */
export const extractSrtContent=async (url:string)=>{
    return await getUrlContent(url);
}

/**
 * Extract a partial content of an SRT
 * @param url
 * @param startTime
 * @param endTime
 */
export const extractPartialSrtContent=async (url:string,startTime:number,endTime:number,offset=0)=>{
    const contentTxt=await extractSrtContent(url);
    // extract srt sentences for section
    const parser = new SrtParser2();
    const srtArray = parser.fromSrt(contentTxt) as Line[];
    const finalArray=[] as Line[];
    for(const line of srtArray){
        if(line.startSeconds*1000>=startTime
        && line.endSeconds*1000<=endTime){
            line.startSeconds-=offset/1000;
            line.endSeconds-=offset/1000;
            line.startTime=convertToTimeFormat(line.startSeconds);
            line.endTime=convertToTimeFormat(line.endSeconds);
            finalArray.push(line);
        }
    }
    return parser.toSrt(finalArray)+"\r\n";
}

const convertToTimeFormat=(seconds)=> {
    // compute values
    const hour = Math.floor(seconds / 3600); // 1 heure = 3600 secondes
    const minute = Math.floor((seconds % 3600) / 60); // Les minutes restantes après avoir enlevé les heures
    const second = Math.floor(seconds % 60); // Les secondes restantes
    const millisecond = Math.floor((seconds - Math.floor(seconds)) * 1000); // Extraire les millisecondes

    // Formatage values
    const formattedHour = String(hour).padStart(2, '0');
    const formattedMinute = String(minute).padStart(2, '0');
    const formattedSecond = String(second).padStart(2, '0');
    const formattedMillisecond = String(millisecond).padStart(3, '0'); // 3 chiffres pour millisecondes

    // return string `${hour}:${minute}:${second},${millisecond}`
    return `${formattedHour}:${formattedMinute}:${formattedSecond},${formattedMillisecond}`;
}
