// SETTINGS ACTION
export const SETUP_WC = 'SETUP_WC';
export const SETUP_EXT = 'SETUP_EXT';
export const OPEN_MENU = 'OPEN_MENU';
export const SETUP_SCREENCAST = 'SETUP_SCREENCAST';
export const SETUP_COMPLETE = 'SETUP_COMPLETE';
export const SETUP_FAVORITE_FILTER = 'SETUP_FAVORITE_FILTER';
export const SETUP_FAVORITE_FACE_TRACK = 'SETUP_FAVORITE_FACE_TRACK';
export const SETUP_FAVORITE_LAYOUT = 'SETUP_FAVORITE_LAYOUT';
export const SETUP_FAVORITE_WEBCAM = 'SETUP_FAVORITE_WEBCAM';
export const SETUP_FAVORITE_MICROPHONE = 'SETUP_FAVORITE_MICROPHONE';
export const SWITCH_TO_NATIVE_PLAYER = 'SWITCH_TO_NATIVE_PLAYER';
export const SETUP_SEGMENTATION_FRAMERATE = 'SETUP_SEGMENTATION_FRAMERATE';




