<template>
  <w-tool-tip :label="label" position="top">
    <w-icon :data-test="dataTest" :icon="icon" :size="size" :color="color"/>
  </w-tool-tip>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';

@Component({
  components: {WToolTip, WIcon}
})
export default class WHelp extends Vue {
  @Prop({default: 'small'})
  private size!: string;
  @Prop({default: 'information-outline'})
  private icon!: string;
  @Prop({default: 'light2'})
  private color!: string;
  @Prop()
  private dataTest!: string;
  @Prop({default: ''})
  private label!: string;

}
</script>

<style scoped lang="scss">

</style>
