import {SettingUser} from '@/store/settingUsers/settingUserModel';

export class User {
  public login: string;
  public contactEmail: string;
  public firstName: string;
  public lastName: string;
  public userID: string;
  public imageURL: string = '';
  public expiredPassword: string = '';
  public settings: SettingUser[] = [];
  public created: Date | null;

  constructor(login: string, firstName: string, lastName: string, contactEmail: string, userID: string, created: Date | null = null) {
    this.login = login;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userID = userID;
    this.contactEmail = contactEmail;
    this.created = created;
  }
}
