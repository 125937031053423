<template>


  <w-button class="typeform-share buttonTypeForm" :href="url" :data-mode="type"
            style="" :link="true" icon="comment-outline"
            target="_blank" :color="color" :size="size" :expanded="expanded">{{ $t('typeform.feedback') }}
  </w-button>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';

@Component({
  components: {WButton, WIcon}
})
export default class TypeForm extends Vue {
  @Prop()
  private url!: string;

  // can be drawer_left drawer_right popup
  @Prop({default: 'drawer_left'})
  private type!: string;

  @Prop({default: 'primary'})
  private color!: string;

  @Prop({default: 'default'})
  private size!: string;

  @Prop({default: false})
  private expanded!: boolean;


  public mounted() {
    this.initTypeForm();
  }

  private initTypeForm() {
    const d = document;
    const gi = d.getElementById;
    const ce = d.createElement;
    const gt = d.getElementsByTagName;
    const id = 'typef_orm_share';
    const b = 'https://embed.typeform.com/';
    let q;
    let js;
    if (!gi.call(d, id)) {
      js = ce.call(d, 'script');
      js.id = id;
      js.src = b + 'embed.js';
      q = gt.call(d, 'script')[0];
      q.parentNode.insertBefore(js, q);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.buttonTypeForm {
  z-index: 3;
}

</style>
