import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  GET_MY_NOTIFICATIONS_STAT,
  GET_NEXT_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
  UNREAD_NOTIFICATIONS
} from '@/store/myNotifications/myNotificationsAction';
import axios from 'axios';
import {Notification, NotificationStat, NotificationType} from '@/store/myNotifications/myNotificationModel';
import orderBy from 'lodash/orderBy';
import Vue from 'vue';
import {getNotificationIDs} from '@/utils/notification';

const myNotificationsAPI = BACK_ENDPOINT + '/my/notification';
const myNotificationsUnreadAPI = BACK_ENDPOINT + '/my/notification/unread';
const myNotificationStatAPI = BACK_ENDPOINT + '/my/notification/stat';

export default {
  state: {
    myNotifications: {},
    // value for find the red stickers
    unreadNotification: {},
    totalOfNotification: 0,
    totalOfUnread: 0,
  },
  mutations: {
    [GET_NEXT_NOTIFICATIONS]: (state: any, {data, page}: { data: Notification[], page: number }) => {
      for (const notif of data) {
        Vue.set(state.myNotifications, notif.id, notif);
      }
    },
    [RESET_NOTIFICATIONS]: (state: any) => {
      state.myNotifications = {};
    },
    [GET_MY_NOTIFICATIONS_STAT]: (state: any, stat: NotificationStat) => {
      state.totalOfNotification = stat.total;
      state.totalOfUnread = stat.unread;
    },
    [GET_UNREAD_NOTIFICATIONS]: (state: any, data: Notification[]) => {
      for (const notif of data) {
        Vue.set(state.unreadNotification, notif.id, notif);
      }
    },
    [READ_NOTIFICATIONS]: (state: any, notifications: Notification[]) => {
      notifications.forEach((notification: Notification) => {
        notification.viewed = true;
        Vue.set(state.myNotifications, notification.id, notification);
        Vue.delete(state.unreadNotification, notification.id);
        state.totalOfUnread--;
      });
    },
    [READ_ALL_NOTIFICATIONS]: (state: any) => {
      for (const notification of Object.values(state.myNotifications) as Notification[]) {
        notification.viewed = true;
        Vue.set(state.myNotifications, notification.id, notification);
      }
      state.unreadNotification = {};
      state.totalOfUnread = 0;
    },
    [UNREAD_NOTIFICATIONS]: (state: any, notifications: Notification[]) => {
      notifications.forEach((notification: Notification) => {
        notification.viewed = false;
        Vue.set(state.myNotifications, notification.id, notification);
        Vue.set(state.unreadNotification, notification.id, notification);
        state.totalOfUnread++;
      });
    }
  },
  getters: {
    getMyNotifications: (state: any): Notification[] => {
      return orderBy(Object.values(state.myNotifications), ['created'], ['desc']) as Notification[];
    },
    getUnreadNotification: (state: any): Notification[] => {
      return orderBy(Object.values(state.unreadNotification), ['created'], ['desc']) as Notification[];
    },
    getWeetTypeUnreadTimelineNotification: (state: any): Notification[] => {
      const notifications: Notification[] = [];
      for (const notif of Object.values(state.unreadNotification) as Notification[]) {
        if (notif.type === NotificationType.COMMENT_WEET ||
          notif.type === NotificationType.UPDATE_WEET ||
          notif.type === NotificationType.REPLY_COMMENT ||
          notif.type === NotificationType.SHARE_WEET ||
          notif.type === NotificationType.REACT_WEET
          || notif.type === NotificationType.INVITE_WEET
          || notif.type === NotificationType.NEW_WEET) {
          notifications.push(notif);
        }
      }
      return orderBy(notifications, ['created'], ['desc']) as Notification[];
    },
    getWeetTypeUnreadNotification: (state: any): Notification[] => {
      const notifications: Notification[] = [];
      for (const notif of Object.values(state.unreadNotification) as Notification[]) {
        if (notif.type === NotificationType.COMMENT_WEET ||
          notif.type === NotificationType.UPDATE_WEET ||
          notif.type === NotificationType.REPLY_COMMENT ||
          notif.type === NotificationType.REACT_WEET ||
          notif.type === NotificationType.SHARE_WEET ||
          notif.type === NotificationType.NEW_WEET ||
          notif.type === NotificationType.VIEW_WEET) {
          notifications.push(notif);
        }
      }
      return orderBy(notifications, ['created'], ['desc']) as Notification[];
    },
    getWorkspaceInviteUnreadNotification: (state: any): Notification[] => {
      const notifications: Notification[] = [];
      for (const notif of Object.values(state.myNotifications) as Notification[]) {
        if (notif.type === NotificationType.INVITE_WORKSPACE && !notif.viewed) {
          notifications.push(notif);
        }
      }
      return orderBy(notifications, ['created'], ['desc']) as Notification[];
    },
    getTotalOfNotification: (state: any): number => {
      return state.totalOfNotification;
    },
    getTotalOfUnreadNotification: (state: any): number => {
      return state.totalOfUnread;
    },
  },
  actions: {
    [RESET_NOTIFICATIONS]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
      commit(RESET_NOTIFICATIONS);
    },
    async [GET_NEXT_NOTIFICATIONS]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
      let afterDate;
      if (getters.getMyNotifications.length > 0) {
        afterDate = getters.getMyNotifications[getters.getMyNotifications.length - 1].created;
      }
      return await axios({
        url: myNotificationsAPI,
        params: {afterCreated: afterDate},
        method: 'GET',
      }).then((resp) => {
        commit(GET_NEXT_NOTIFICATIONS, {data: resp.data.data, afterCreated: afterDate});
        commit(GET_MY_NOTIFICATIONS_STAT, resp.data.stat);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_UNREAD_NOTIFICATIONS]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
      return await axios({
        url: myNotificationsUnreadAPI,
        method: 'GET',
      }).then((resp) => {
        commit(GET_UNREAD_NOTIFICATIONS, resp.data.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_MY_NOTIFICATIONS_STAT]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }
    ) {
      return await axios({
        url: myNotificationStatAPI,
        method: 'GET',
      }).then((resp) => {
        commit(GET_MY_NOTIFICATIONS_STAT, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [UNREAD_NOTIFICATIONS]({
                                   commit,
                                   dispatch,
                                   getters
                                 }: { commit: any, dispatch: any, getters: any },
                                 notifications: Notification[]) {
      if (notifications.length > 0) {
        commit(UNREAD_NOTIFICATIONS, notifications);
        return await axios({
          url: myNotificationsAPI,
          params: {read: false},
          data: {notificationIDs: getNotificationIDs(notifications)},
          method: 'PUT',
        }).catch((err) => {
          if (err.data) {
            err = err.data;
          }
          throw err;
        });
      }
    },
    async [READ_NOTIFICATIONS]({
                                 commit,
                                 dispatch,
                                 getters
                               }: { commit: any, dispatch: any, getters: any },
                               notifications: Notification[]) {
      if (notifications.length > 0) {
        commit(READ_NOTIFICATIONS, notifications);
        return await axios({
          url: myNotificationsAPI,
          params: {read: true},
          data: {notificationIDs: getNotificationIDs(notifications)},
          method: 'PUT',
        }).catch((err) => {
          if (err.data) {
            err = err.data;
          }
          throw err;
        });
      }
    },
    async [READ_ALL_NOTIFICATIONS]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
      return await axios({
        url: myNotificationsAPI,
        method: 'PATCH',
        params: {read: true},
      }).then((resp) => {
        commit(READ_ALL_NOTIFICATIONS);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },
};
