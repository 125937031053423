import html2canvas from "html2canvas";


export const exportNode2PngDataURL = async (node: HTMLElement, quality: number = 0.8): Promise<string> => {
    // create a canvas
    const canvas = await html2canvas(node, {
        allowTaint: false,
        backgroundColor: null,
        useCORS: true,
        logging: false,
        ignoreElements:(element)=>{
            return !!element.getAttribute('ignoreImageExport');
        },
        width: node.offsetWidth,
        height: node.offsetHeight,
        scale: 2,
    })

    // uncomment to show the image export from canvas
    const imageUrl = canvas.toDataURL('image/png');

    return imageUrl;

}
