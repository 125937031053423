<template>
  <div class="containerEmote">
    <w-tool-tip :label="tootlTipLabel" :position="'bottom'" :delay="1000">
      <img alt="emote" :src="url" class="imageEmote" data-test="commentEmojiButton" @click="sendComment"/>
    </w-tool-tip>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import prettyMilliseconds from 'pretty-ms';
import {CommentParams, SEND_COMMENT} from '@/store/comment/commentAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {Weet} from '@/store/weet/weetModel';
import {ActionEvent} from '@/enum/TrackerEnum';
import {setActionEvent} from '@/utils/tracker';
import {quickAuthentication} from '@/utils/loginUtil';
import {canEditOrCommentThisWeet} from '@/utils/weetUtil';
import {alertMessage} from '@/utils/dialog';

@Component({
  components: {WToolTip}
})
export default class CommentEmoteCreator extends Vue {
  @Prop()
  public url!: string;
  @Prop()
  public weet!: Weet;
  @Prop()
  public amplitudeAction!: ActionEvent;


  get time(): number {
    return store.getters.getTimerPlayer;
  }

  get tootlTipLabel(): string {
    let labelMilliSencond = prettyMilliseconds(this.time, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    if (this.time < 1000) {
      labelMilliSencond = '0:00';
    }
    return this.$t('comment.emote.tooltip', {timer: labelMilliSencond}).toString();
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  public async sendComment() {
    if (this.time < 0) {
      return;
    }
    await quickAuthentication();
    if (!canEditOrCommentThisWeet(this.weet)) {
      alertMessage(this.$t('commentPanelView.disabled.subtitle').toString(), this.$t('commentPanelView.disabled.title').toString());
      return;
    }
    const params = new CommentParams(this.weet.weetID, CommentTypeEnum.EMOTE, this.time);
    params.imageUrl = this.url;
    setActionEvent(this.amplitudeAction, {player_time: this.time});
    store.dispatch(SEND_COMMENT, params);
  }


}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.containerEmote {
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;

  .imageEmote {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.5);
    }
  }


}
</style>
