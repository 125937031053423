<template>
  <b-field :label="label" :type="'is-'+color">
    <slot></slot>
  </b-field>
</template>

<script lang="ts">
  import {Component, Prop, Vue} from 'vue-property-decorator';

  @Component({})
  export default class WField extends Vue {
    @Prop({default: 'light1'})
    private color!: string;
    @Prop({default: ''})
    private label!: string;
  }
</script>

<style scoped lang="scss">
</style>
