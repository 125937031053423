<template>
  <transition name="zoom">
    <div class="mainContainerTable" v-show="mustShow">
      <div class="videoContainer" style="animation-duration: 0.5s">
        <video oncontextmenu="return false;" disablePictureInPicture controlsList="nodownload noremoteplayback"
               playsinline ref="videoElement"
               class="videoElement"
               :src="urlOfNativePlayer" :poster="poster" controls
               @play="nativePlayerOnPlay" @pause="nativePlayerOnPause"
               @seeked="nativePlayerTimeUpdate" preload="metadata"
        />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {PLAYER_PLAYING, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import {getThumbnailURL, getVideoURLForStatus} from '@/utils/util';
import {log} from '@/utils/log';
import {Weet} from '@/store/weet/weetModel';
import {getMediasForWeet} from '@/utils/weetUtil';
import {GET_EXPORTED_URL_WEET} from '@/store/weet/weetAction';

@Component({})
export default class NativeVideoPlayer extends Vue {
  @Prop()
  private weet!: Weet;
  @Prop({default: false})
  private nativePlayer!: boolean;
  private mustShow: boolean = false;
  private urlOfNativePlayer: string = '';
  private OFFSET_GENERIQUE: number = 0;

  // manage timestamp manually
  private interval: any = 0;
  private timeStep: number = 100;

  public mounted() {
    this.mustShow = true;
    this.getUrlOfNativePlayer();
  }

  public nativePlayerOnPlay() {
    this.interval = setInterval(this.nativePlayerTimeUpdate, this.timeStep);
    store.dispatch(PLAYER_PLAYING, true);
  }

  public nativePlayerOnPause() {
    clearInterval(this.interval);
    store.dispatch(PLAYER_PLAYING, false);
  }

  public nativePlayerTimeUpdate(value) {
    store.dispatch(TIMER_PLAYER, (this.videoElement.currentTime * 1000) - this.OFFSET_GENERIQUE);
  }


  // get the video HTML element
  get videoElement(): HTMLVideoElement {
    return this.$refs.videoElement as HTMLVideoElement;
  }


  get timePlayer(): number {
    return store.getters.getTimerPlayer + this.OFFSET_GENERIQUE;
  }

  get play(): boolean {
    return store.getters.isPlaying;
  }


  get poster(): string {
    const timeEventMedia = this.getTimeEvent();
    if (timeEventMedia === undefined) {
      return '';
    }
    let mediaID = timeEventMedia.mainMedia.mediaID;

    if (mediaID === '') {
      mediaID = timeEventMedia.secondMedia.mediaID;
    }

    const mediaFound = getMediasForWeet(this.weet, mediaID);
    if (mediaFound !== undefined) {
      return getThumbnailURL(mediaFound?.jobID, true);
    } else {
      return '';
    }
  }

  public getTimeEvent(): any {
    // we search the event with 0
    if (this.weet.timeLine.length > 0) {
      return this.weet.timeLine[0];
    } else {
      return undefined;
    }
  }

  public getUrlOfNativePlayer() {
    return store.dispatch(GET_EXPORTED_URL_WEET, {weetID: this.weet.weetID, download: false}).then((url) => {
      this.urlOfNativePlayer = url;
    });
  }


  /**
   * change the status if play();
   */
  @Watch('play')
  public togglePlay() {
    if (this.play) {
      if (this.videoElement.paused) {
        this.videoElement.play().catch((e) => {
          log.debug(e);
        });
      }
    } else {
      this.videoElement.pause();
    }
  }

  @Watch('timePlayer')
  public onTimeChange() {
    // if there is an error of +/- 500 ms it's seek
    if (this.timePlayer > (this.videoElement.currentTime * 1000) + 500
        || this.timePlayer < (this.videoElement.currentTime * 1000) - 500) {
      this.videoElement.currentTime = this.timePlayer / 1000;
    }
  }

  /**
   * Remove url on destroy
   */
  public beforeDestroy() {
    this.videoElement.src = '';
    clearInterval(this.interval);
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.mainContainerTable {

  .videoContainer {
    font-size: 0; //remove incorect margin

    .videoElement {
      width: 100%;

    }
  }
}
</style>
