<template>

  <Transition name="fade">
    <div class="mainContainer" v-show="mustShow" ref="twitterContainer">
      <div class="labelContainer">
        <div class="newTitle">
          {{ $t('news.title') }}
        </div>
        <div class="newDescription">
          {{ $t('news.description') }}
        </div>
        <div class="releaseLink">
          <a target="_blank" href="https://help.weet.co/en/articles/4657492-release-notes">{{
            $t('news.releaseNoteLink')
            }}</a>
        </div>
      </div>
      <div class="tweeterContainer">
        <!-- Generate code with https://publish.twitter.com/ -->
        <a class="twitter-timeline" data-chrome="noheader nofooter"
           href="https://twitter.com/beweet/timelines/1352288077562183691"></a>
      </div>

    </div>
  </Transition>
</template>

<script lang="ts">
import CenterCenter from '@/components/layout/CenterCenter.vue';
import {Component, Vue} from 'vue-property-decorator';
import {SET_LAST_NEWS_DATE, SET_LAST_VERSION_NEWS} from '@/store/persistedInformation/persistAction';
import {FRONT_VERSION} from '@/store/conf/api';
import store from '@/store';

@Component({
  components: {CenterCenter},
})
export default class TwitterNewsProduct extends Vue {

  private mustShow: boolean = false;

  private mounted() {
    // @ts-ignore importation https://platform.twitter.com/widgets.js
    window.twttr.widgets.load(this.$refs.twitterContainer.$el);
    setTimeout(() => {
      this.mustShow = true;
    }, 500);


    store.dispatch(SET_LAST_NEWS_DATE, new Date());
    store.dispatch(SET_LAST_VERSION_NEWS, FRONT_VERSION);
  }


}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gridBreakPoint.scss";

.mainContainer {
  max-height: 564px;
  background: white;
  margin-top: 100px;
  border-radius: 8px;
  @extend .shadow1;
  display: flex;
  height: 100%;

  @include GRID1X() {
    max-width: 100%;
    margin-top: 0px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }


  .labelContainer {
    max-width: 500px;
    width: 500px;
    height: 100%;
    background: var(--light);
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0px 0px 8px;
    @include GRID1X() {
      display: none;
    }

    .newTitle {
      font-size: 24px;
      letter-spacing: 0.3px;
      font-weight: 900;
      color: var(--black);

    }

    .newDescription {
      padding: 32px;
      font-size: 16px;
      color: var(--black);
    }

    .releaseLink {
      text-align: center;
    }
  }

  .tweeterContainer {
    width: 100%;
    height: 100%;
    max-width: 500px;
    background: white;
    overflow: auto;
    position: relative;
    border-radius: 8px;
    max-height: 550px;
    @include GRID1X() {
      max-width: 100%;
      margin-top: 0px;
      height: 100%;
      max-height: 100vh;
    }
  }

}
</style>
