import { render, staticRenderFns } from "./WebcamAuthHelperArrow.vue?vue&type=template&id=0465c6a1&scoped=true"
import script from "./WebcamAuthHelperArrow.vue?vue&type=script&lang=ts"
export * from "./WebcamAuthHelperArrow.vue?vue&type=script&lang=ts"
import style0 from "./WebcamAuthHelperArrow.vue?vue&type=style&index=0&id=0465c6a1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0465c6a1",
  null
  
)

export default component.exports