<template>
  <Transition name="fadeRight">
    <div v-show="mustShow" style="width: 100%;" @click="$emit('click')">
      <w-tool-tip color="light" :label="this.getHumanTime"
                  size="small" position="top"
                  :expanded="true">
        <div class="segment">

        </div>
      </w-tool-tip>
    </div>
  </Transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Segment} from '@/store/timeLine/timeEvent';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import prettyMilliseconds from 'pretty-ms';

@Component({
  components: {WToolTip},
})
export default class TimeLineElement extends Vue {

  @Prop()
  private segment!: Segment;
  private mustShow: boolean = false;

  private mounted() {
    this.mustShow = true;
  }


  get getTime(): number {
    return this.segment.end.time - this.segment.start.time;
  }

  get getHumanTime(): string {
    return this.$t('generic.duration') + ': ' + prettyMilliseconds(this.getTime, {secondsDecimalDigits: 0});
  }
}
</script>

<style scoped lang="scss">

.segment {
  border-bottom: solid 4px var(--primary);
  width: 100%;
  // padding for the hover catch
  padding-top: 10px;
  transition: border 0.2s ease-in-out;
  margin-top: auto;
  cursor: pointer;

  &:hover {
    border-bottom: solid 8px var(--primary);
  }
}
</style>
