<template>
  <div>
    <w-button color="text" @click.stop="startVideoCommentRecording"
              :tooltip="toolTip"
              :tooltip-position="'bottom'"
              :icon="logo"
              :size="size">
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import {PLAYER_PLAYING, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import WButton from '@/components/wrapper/w-button.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import {RECORD_STICKER_VIDEO_MUTE, STOP_RECORDING} from '@/store/recordingState/recordStateAction';
import {getTabOfColor} from '@/utils/util';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {isChromium} from '@/utils/extentionUtil';
import {alertMessage} from '@/utils/dialog';
import {canEditOrCommentThisWeet} from '@/utils/weetUtil';
import {quickAuthentication} from '@/utils/loginUtil';

@Component({
  components: {StickerLayoutSource, WButton},
})
export default class CommentVideo extends Vue {
  @Prop()
  public weet!: Weet;

  @Prop()
  public audioOnly!: string;

  @Prop({default: 'default'})
  public size!: string;

  private loading: boolean = false;
  private error: string = '';

  get time(): number {
    return store.getters.getTimerPlayer;
  }


  get toolTip(): string {
    if (this.audioOnly) {
      return this.$t('commentVideoCreator.button.mic').toString();
    } else {
      return this.$t('commentVideoCreator.button.camera').toString();
    }
  }

  get logo() {
    if (this.audioOnly) {
      return 'microphone-outline';
    } else {
      return 'video-outline';
    }
  }


  public async startVideoCommentRecording() {
    if (!isChromium()) {
      alertMessage(this.$t('generic.capture.unaivailable').toString());
      return;
    }
    this.pauseVideo();
    await quickAuthentication();
    if (!canEditOrCommentThisWeet(this.weet)) {
      alertMessage(this.$t('commentPanelView.disabled.subtitle').toString(), this.$t('commentPanelView.disabled.title').toString());
      return;
    }

    if (this.audioOnly && !store.getters.isStickerVideoMute) {
      store.dispatch(RECORD_STICKER_VIDEO_MUTE);
    }
    if (!this.audioOnly && store.getters.isStickerVideoMute) {
      store.dispatch(RECORD_STICKER_VIDEO_MUTE);
    }
    if (this.audioOnly) {
      setActionEvent(ActionEvent.comment_mic_start, {player_time: this.time});
    } else {
      setActionEvent(ActionEvent.comment_video_start, {player_time: this.time});
    }
    store.dispatch(TIMER_RECORD, 0);
    this.$emit('click');
  }

  public pauseVideo() {
    store.dispatch(PLAYER_PLAYING, false);
  }

  public beforeDestroy() {
    store.dispatch(STOP_RECORDING);
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.containerCommentText {
  display: flex;
  width: 100%;

  .avatarBox {
    margin-right: 16px;
  }

  .inputBox {
    display: flex;
    flex: 1;

    .sendBox {
      margin-left: 8px;
    }
  }
}
</style>
