<template>
  <div class="containerCommentText">
    <div class="avatarBox" :class="size">
      <UserAvatar type="sticker" :size="size" :user-avatar="imageForUser" class="userAvatar"/>
    </div>
    <div class="inputBox" :class="size">
      <TextAreaEmoJ :auto-focus="autofocus" :size="size" :placeholder="placeHolderLabel"
                    class="inputTextAreaLight"
                    v-model="valueMessage" @focus="onFocus" @blur="onBlur"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import prettyMilliseconds from 'pretty-ms';
import {Weet} from '@/store/weet/weetModel';
import {getUserAvatar} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WInput from '@/components/wrapper/w-input.vue';
import TextAreaEmoJ from '@/components/comment/TextAreaEmoJ.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';

@Component({
  components: {WError, WButton, TextAreaEmoJ, WInput, UserAvatar}
})
export default class CommentTextCreator extends Vue {
  @Prop()
  public weet!: Weet;
  @Prop({default: 'default'})
  public size!: string;

  @Prop({default: ''})
  private placeholder!: string;

  @Prop({default: ''})
  private value!: string;

  @Prop({default: false})
  private autofocus!: boolean;

  private valueMessage: string = '';


  get time(): number {
    return store.getters.getTimerPlayer;
  }

  get placeHolderLabel(): string {
    if (this.placeholder) {
      return this.placeholder;
    } else {
      return this.$t('comment.text.placeHolderLabel').toString();
    }
  }

  get humanTime(): string {
    let labelMilliSencond = prettyMilliseconds(this.time, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    if (this.time < 1000) {
      labelMilliSencond = '0:00';
    }
    return labelMilliSencond;
  }

  get imageForUser(): string {
    return getUserAvatar(this.userConnected, 100);
  }

  get userConnected() {
    return store.getters.userConnected;
  }


  @Watch('value')
  public onChangePropValue() {
    this.valueMessage = this.value;
  }

  @Watch('valueMessage')
  private onValueMessageChange() {
    this.$emit('input', this.valueMessage);
  }

  private onFocus() {
    this.$emit('focus');
  }

  private onBlur() {
    this.$emit('blur');
  }
}
</script>

<style scoped lang="scss">

.containerCommentText {
  display: flex;
  width: 100%;

  .avatarBox {
    margin-right: 8px;

    &.small {
      margin-right: 0px;
    }
  }

  .inputBox {
    display: flex;
    flex: 1;
    border-radius: 8px;
    background: white;

    &.small {
      margin-top: -4px;
    }

    .timeBox {
      padding: 8px;
      color: var(--light2);
    }

    .inputTextAreaLight {
      margin-top: 4px;
      margin-left: 8px;
    }

  }

  .sendBox {
    margin-left: 8px;
    align-self: flex-end;
  }
}
</style>
