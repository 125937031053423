export const BACK_ENDPOINT = process.env.VUE_APP_BACK_ENDPOINT;
// export const PLAYER_ENDPOINT = process.env.VUE_APP_PLAYER_ENDPOINT;
// export const IMG_ENDPOINT = process.env.VUE_APP_IMG_ENDPOINT;
export const DOWNLOAD_ENDPOINT = process.env.VUE_APP_DOWNLOAD_ENDPOINT;
export const DEBUG_MODE = process.env.VUE_APP_DEBUG;
export const EXTENTION_ID = process.env.VUE_APP_EXT_ID;
export const GOOGLE_AUTH_ID = process.env.VUE_APP_GOOGLE_AUTH_ID;
export const APPLE_CLIENT_ID = process.env.VUE_APP_APPLE_CLIENT_ID;
export const MSAL_TENANT_ID = process.env.VUE_APP_MSAL_TENANT_ID || '';
export const FRONT_VERSION = process.env.VUE_APP_VERSION;
export const SD_ENABLED = process.env.VUE_APP_SD;
export const PUSH_NOTIFICATION_PROJECT_ID = process.env.VUE_APP_PUSH_NOTIFICATION_PROJECT_ID;
export const PUSH_NOTIFICATION_SENDER_ID = process.env.VUE_APP_PUSH_NOTIFICATION_SENDER_ID;
export const PUSH_NOTIFICATION_WEB_API_KEY = process.env.VUE_APP_PUSH_NOTIFICATION_WEB_API_KEY;
export const PUSH_NOTIFICATION_VAPID_KEY = process.env.VUE_APP_PUSH_NOTIFICATION_VAPID_KEY;
export const PUSH_NOTIFICATION_APP_ID = process.env.VUE_APP_PUSH_NOTIFICATION_APP_ID;
export const NULL_FILE = process.env.VUE_APP_NULL_AUDIO;
export const SUBTITLES_MANAGEMENT_ENDPOINT = process.env.VUE_APP_SUBTITLES_MANAGEMENT_ENDPOINT

export const TIME_CHUNK = 1500;
export const DEFAULT_WEET_TITLE = 'Create video tutorials with Weet';

export const APP_ENVIRONEMENT= process.env.VUE_APP_APP_ENVIRONEMENT
export const PUBLIC_PATH=process.env.VUE_APP_PUBLIC_PATH;
