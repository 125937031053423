import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {createImageBitmapForUrlBackground, cropAndScaleVideo} from '@/utils/video/canvasUtils';
import {SelfieSegmentation} from '@/worker/selfieSegementation';
import {WebcamIntegrationPosition} from '@/utils/video/streamUtils';


export class WebCamIntegrationTransform implements ICanvasTransform {
    public initialised: boolean = false;
    private id: string = uuidv4();
    private width: number;
    private height: number;
    private frame: ImageBitmap | null = null;
    private counterOfFrameGenerated: number = 0;
    private video: HTMLVideoElement | null = null;
    private selfie!: SelfieSegmentation;
    private screencast: MediaStream | null = null;
    private position: WebcamIntegrationPosition = WebcamIntegrationPosition.BOTTOM_LEFT;
    private size: number = 0;

    constructor(width: number, height: number, screencast: MediaStream, position: WebcamIntegrationPosition, size: number = 0.25) {
        this.width = width;
        this.height = height;
        this.screencast = screencast;
        this.position = position;
        this.size = size;
    }

    public async init(webcam: MediaStream, highPerf: boolean) {
        this.selfie = new SelfieSegmentation(highPerf);
        await this.selfie.init();
        this.initialised = true;

        if (this.screencast) {
            // we have to put the stream in a video to keep it alive
            this.video = document.createElement('video');
            this.video.id = this.id.toString();
            this.video.autoplay = true;
            this.video.style.zIndex = '-1';
            this.video.style.position = 'absolute';
            this.video.style.top = '0';
            this.video.style.opacity='0';
            this.video.style.left = '0';
            this.video.loop = true;
            document.body.append(this.video);
            this.video.srcObject = this.screencast;
            this.video.muted = true;

            await new Promise<void>((resolve, reject) => {
                if (this.video) {
                    this.video.onplay = () => {
                        resolve();
                    };
                }
            });
        }
        log.debug('Initialise WebcamIntegrationTransformer');
    }

    public async start() {
        log.debug('start WebcamIntegrationTransformer');
        return;
    }

    public async transform(sleepMode: boolean, inputWebcam: ImageBitmap | null): Promise<ImageBitmap | null> {
        try {
            const dateStart = new Date().getTime();
            // console.log('imageGrabbed', new Date().getTime() - dateStart);
            let imageGenerated: ImageBitmap | null = null;
            if (!sleepMode && inputWebcam && inputWebcam.width > 0) {
                this.selfie.inputFrame(inputWebcam);
                if (this.video) {
                    // if (!this.frame || this.counterOfFrameGenerated % 2 === 0) {
                    if (this.video.videoHeight > 0) {
                        if (this.video.width !== this.video.videoWidth
                            || this.video.height !== this.video.videoHeight) {
                            this.video.width = this.video.videoWidth;
                            this.video.height = this.video.videoHeight;
                        }
                        // the screencast
                        this.frame = await createImageBitmap(this.video);
                        // await cropAndScaleVideo(this.video, this.width, this.height);
                        // integrate webcam + screen
                        imageGenerated = await this.selfie.getWebcamIntegrationFrame(inputWebcam, this.frame, this.size, this.position);
                    }

                }
                this.counterOfFrameGenerated++;
            }

            return imageGenerated;
        } catch (e: any) {
            this.initialised = true;
            throw new Error('RENDER_ERROR: ' + e.toString());
        }
    }


    public async flush() {
        log.debug('Flush VirtualBackgroundTransformer');
        if (this.video) {
            this.video.remove();
            this.screencast = null;
        }
    }
}
