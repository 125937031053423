<template>
  <div>
    <transition name="fade"
                enter-active-class="fadeIn"
                leave-active-class="none">
      <w-button :icon-right="icon" tooltip-size="small"
                :color="color" :tooltip="$t('validateWeetButton.tooltip')" tooltip-position="top"
                v-show="isReady" v-on="$listeners">
        {{ $t('validateWeetButton.label') }}
      </w-button>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';


@Component({
  components: {WButton},
})
export default class ValidateWeetButton extends Vue {

  @Prop({default: 'arrow-right'})
  private icon!: string;
  @Prop({default: 'primary'})
  private color!: string;

  get isReady() {
    return store.getters.getTimerRecord > 0 && store.getters.isStartRecordingAivailable
        && !store.getters.isAvatarGenerateInProgress;
  }

  get isAivailable(): boolean {
    return !store.getters.isSychronisationInProgress && !store.getters.timelineNeedToBeSaved;
  }
}
</script>
<style scoped lang="scss">

</style>
