import {User} from '@/store/user/userModel';
import {WorkspaceUserRoleEnum} from '@/store/workspaceRight/workspaceRightModel';

export interface Workspace {
    personnal: boolean;
    workspaceID: string;
    name: string;
    createdUTC: number;
    imageURL: string;
    imageHeaderURL: string;
    expiredPassword: string;
    plan: PaymentPlanEnum;
    settings: SettingWorkspace[];
    theme: string;
}


export class WorkspaceGetUserParams {
    public page: number = 0;
    public workspaceID: string = '';
    public statusFilter: WorkspaceUserStatusEnum | null = null;
    public userFilter: string | null = null;

    constructor(page: number = 0) {
        this.page = page;
    }
}

export interface WorkspaceUsersStatus {
    user: User;
    status: WorkspaceUserStatusEnum;
    joined: number;
    role: WorkspaceUserRoleEnum;
    workspaceID: string;
}

export class WorkspaceUserManageInbound {
    public userID: string;
    public role: WorkspaceUserRoleEnum;

    constructor(userID: string, role: WorkspaceUserRoleEnum) {
        this.userID = userID;
        this.role = role;
    }
}

export enum PaymentPlanEnum {
    FREE = 'FREE',
    BUSSINESS = 'BUSSINESS',
    ENTERPRISE = 'ENTERPRISE'
}

export enum LeaveWorkspaceEnum {
    SHOULD_REMOVE_WORKSPACE = 'SHOULD_REMOVE_WORKSPACE',
    SHOULD_TRANSFER_OWNERSHIP = 'SHOULD_TRANSFER_OWNERSHIP',
    COULD_LEAVE_WORKSPACE = 'COULD_LEAVE_WORKSPACE'
}

export enum DeleteAccountStatusEnum {
    CAN_DELETE_ACCOUNT = 'CAN_DELETE_ACCOUNT',
    SHOULD_TRANSFER_OWNERSHIP = 'SHOULD_TRANSFER_OWNERSHIP',
    SHOULD_CANCEL_SUBSCRIPTION = 'SHOULD_CANCEL_SUBSCRIPTION'
}

export enum WorkspaceVisibilityPolicyEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
}

export enum SettingWorkspaceKey {
    WORKSPACE_VISIBILITY = 'WORKSPACE_VISIBILITY',
    WEET_FREEMIUM_QUOTA = 'WEET_FREEMIUM_QUOTA',
    WORKSPACE_USER_INVITE_TTL = 'WORKSPACE_USER_INVITE_TTL'
}

export class SettingWorkspaceParams {
    public key: SettingWorkspaceKey;
    public value: string;

    constructor(key: SettingWorkspaceKey, value: any, workspaceID: string | null) {
        this.key = key;
        this.value = value;
    }
}

export class InviteUsersParam {
    public emails: string[];
    public role: WorkspaceUserRoleEnum;

    constructor(emails: string[], role: WorkspaceUserRoleEnum) {
        this.emails = emails;
        this.role = role;
    }
}

export class SettingWorkspace {
    public key: SettingWorkspaceKey;
    public value: string;
    public workspaceID: string | null;

    constructor(key: SettingWorkspaceKey, value: any, workspaceID: string | null) {
        this.key = key;
        this.value = value;
        this.workspaceID = workspaceID;
    }
}

export class DeleteAccountStatus {
    public status: DeleteAccountStatusEnum;
    public workspaces: Workspace[];

    constructor(status: DeleteAccountStatusEnum, workspaces: Workspace[]) {
        this.status = status;
        this.workspaces = workspaces;
    }
}

export enum WorkspaceUserStatusEnum {
    PENDING = 'PENDING',
    ACTIF = 'ACTIF',
    EXPIRED = 'EXPIRED'
}

export interface WorkspaceCount {
    count: number;
}
