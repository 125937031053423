<template>
  <div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {isPluginInstalled, openExtentionStore, sendMessageToExtention} from '@/utils/extentionUtil';
import {IS_INSTALLED} from '@/store/recordingState/recordStateAction';
import WModal from '@/components/wrapper/w-modal.vue';
import {User} from '@/store/user/userModel';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import TopCenter from '@/components/layout/TopCenter.vue';
import {SETUP_EXT, SETUP_SCREENCAST} from '@/store/setup/setupAction';
import {EXTENTION_ID} from '@/store/conf/api';

@Component({
  components: {TopCenter, WButton, WModal},
})
export default class CheckPlugin extends Vue {


  public mounted() {
    this.detectIfInstalled();
  }


  public async detectIfInstalled() {
    store.dispatch(SETUP_EXT, await isPluginInstalled());
  }
}
</script>

<style scoped lang="scss">
.logoWeet {
  position: absolute;
  width: 200px;
  top: 16px;
  left: 16px;
  cursor: pointer;
  z-index: 1;
}

.containerFirstStep {
  display: block;
  padding: 32px;
  padding-top: 0px;
  border-radius: 16px;

  .imgBox {
    width: 100%;
    max-width: 500px;
    margin: 32px;
    margin-top: 32px;
  }

  .textBox1 {

    margin: auto;
    max-width: 500px;
  }

  .buttonBox {
    width: 100%;
    margin-top: 32px;
    text-align: center;
  }

}

.containerSecondStep {
  position: absolute;
  text-align: left;
  width: 100%;
  height: 100%;

  .buttonlayout {
    display: flex;
    margin-top: 48px;
    width: 400px;
    align-items: center;
    margin-left: calc(50% - 400px);

    .buttonRetry {
      margin-left: 16px;
    }
  }

  .arrowBox {
    position: absolute;
    width: 128px;
    height: 128px;
    left: calc(50% + 16px);
    top: 64px;
    background: url("@/assets/theme/arrow.png") no-repeat;
    background-size: contain;
    transform: rotate(80deg) scale(-1);
  }

  .textArrow {
    position: absolute;
    width: 300px;
    text-align: center;
    height: 128px;
    left: calc(50% + 16px);
    top: 168px;
  }
}
</style>
