import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    ADD_PRIVATE_USER_OPTION,
    APPLE_AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_LOGOUT,
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AuthParams, BLOCK_REDIRECTS_TO_LOGIN_PAGE,
    CREATE_ACCOUNT_USER,
    CREATE_ACCOUNT_USER_SILENT,
    EXTERNAL_SYSTEM_AUTH_REQUEST,
    GAUTH_REQUEST,
    MSAUTH_REQUEST,
    REFRESH_USER,
    REGENERATE_PASSWORD,
    REMOVE_PRIVATE_USER_OPTION,
    RESET_ALL_CONNEXTION,
    RESET_AUTH_STORE,
    RETURN_TO_ASKED_URL,
    SEND_LINK_PASSWORD,
    SHOW_POPUP_AUTH, SPEACH_AUTH_REQUEST, STORE_DEDICATED_TENANT, UNBLOCK_REDIRECTS_TO_LOGIN_PAGE,
    UPDATE_SETTING_USER_CONNECTED,
    UPDATE_USER_CONNECTED,
} from '@/store/auth/authAction';
import {User} from '@/store/user/userModel';
import {RESET_PERSIST_STORE, SELECT_WORKSPACE} from '@/store/persistedInformation/persistAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {getTenantID} from '@/utils/util';
import {logout, setIdentity} from '@/utils/tracker';
import {SettingUser} from '@/store/settingUsers/settingUserModel';
import Vue from 'vue';
import {PrivateUserOption, PushNotificationToken} from '@/store/integration/integrationModel';
import {
    DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE,
    REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET
} from '@/store/integration/integrationAction';
import store from '@/store';
import {redirectIfNeed} from '@/utils/loginUtil';
import {checkIfAvatarMustBeGenerated} from '@/utils/avatarGenerator';
import {Token} from '@/store/identity/identityModel';

const loginAPI = BACK_ENDPOINT + '/login';
const userAPI = BACK_ENDPOINT + '/users';
const forgotPasswordAPI = BACK_ENDPOINT + '/forgotPassword';
const regeneratePasswordAPI = BACK_ENDPOINT + '/regeneratePassword';

export default {
    state: {
        token: '',
        privateUserOptions: [],
        user: {},
        status: '',
        urlAsked: '/',
        showPopUpAuth: false,
        noLoginPageRedirection: false,
        dedicatedTenant:'',
    },
    mutations: {
        [SHOW_POPUP_AUTH]: (state: any, value: boolean) => {
            state.showPopUpAuth = value;
        },
        [AUTH_REQUEST]: (state: any) => {
            state.status = 'loading';
        },
        [AUTH_SUCCESS]: (state: any, {
            token,
            user,
            privateUserOptions
        }: { token: string, user: User, privateUserOptions: PrivateUserOption[] }) => {
            state.status = 'success';
            state.token = token;
            state.user = user;
            state.privateUserOptions = privateUserOptions;
            checkIfAvatarMustBeGenerated(user);
        },
        [STORE_DEDICATED_TENANT]:(state:any, value: string)=>{
            state.dedicatedTenant=value;
        },
        [AUTH_ERROR]: (state: any) => {
            state.status = 'error';
        },
        [RESET_AUTH_STORE]: (state: any) => {
            state.status = '';
            state.user = {};
            state.token = '';
            state.privateUserOptions = [];
        },
        [UPDATE_USER_CONNECTED]: (state: any, user: User) => {
            state.user = user;
        },
        [ADD_PRIVATE_USER_OPTION]: (state: any, privateUserOption: PrivateUserOption) => {
            if (state.privateUserOptions == null) {
                state.privateUserOptions = [];
            }
            state.privateUserOptions.push(privateUserOption);
        },
        [REMOVE_PRIVATE_USER_OPTION]: (state: any, privateUserOption: PrivateUserOption) => {
            state.privateUserOptions = state.privateUserOptions
                .filter((el: PrivateUserOption) => {
                    return el.key !== privateUserOption.key
                        || el.value !== privateUserOption.value
                        || el.externalId !== privateUserOption.externalId;
                });
        },
        [UPDATE_SETTING_USER_CONNECTED]: (state: any, settingUpdate: SettingUser) => {
            // now we update the user
            const user = state.user;
            let settingFound: SettingUser | undefined;
            for (const setting of user.settings) {
                if (setting.key === settingUpdate.key) {
                    settingFound = setting;
                }
            }

            if (settingFound) {
                settingFound.value = settingUpdate.value;
            } else {
                Vue.set(user.settings, user.settings.length, settingUpdate);
            }
            state.user = user;
        },
        [RETURN_TO_ASKED_URL]: (state: any, url: string) => {
            state.urlAsked = url;
        },
        [RESET_ALL_CONNEXTION]: (state: any) => {
            if (state.token === '') {
                window.location.reload();
            }
        },
        [BLOCK_REDIRECTS_TO_LOGIN_PAGE]: (state: any) => {
            state.noLoginPageRedirection = true;
        },
        [UNBLOCK_REDIRECTS_TO_LOGIN_PAGE]: (state: any) => {
            state.noLoginPageRedirection = false;
        }
    },
    getters: {
        token: (state: any) => state.token,
        userConnected: (state: any): User => state.user,
        isAuthenticated: (state: any): boolean => !!state.token,
        isShowPopUpAuth: (state: any): boolean => state.showPopUpAuth,
        authStatus: (state: any): string => state.status,
        getDedicatedTenant:(state:any):string => state.dedicatedTenant,
        urlAsked: (state: any): string => state.urlAsked,
        privateUserOptions: (state: any): PrivateUserOption[] => state.privateUserOptions as PrivateUserOption[],
        noLoginPageRedirection: (state: any): boolean => state.noLoginPageRedirection
    },
    actions: {
        async [AUTH_REQUEST]({
                                 commit,
                                 dispatch,
                                 getters
                             }: { commit: any, dispatch: any, getters: any },
                             authParams: AuthParams) {

            commit(AUTH_REQUEST);
            const response = await axios({
                url: loginAPI,
                data: authParams,
                method: 'POST',
            }).then((resp) => {
                    const token = resp.data.token;
                    sendMessageToExtention(AUTH_SUCCESS, {token: token, tenantID: getTenantID()});
                    // commit token
                    const privateUserOptions = resp.data.privateUserOptions as PrivateUserOption[];
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: privateUserOptions
                    });

                    setIdentity(resp.data.user);
                    redirectIfNeed();
                },
            ).catch((err) => {
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;

        },
        async [REFRESH_USER]({
                                 commit,
                                 dispatch,
                                 getters
                             }: { commit: any, dispatch: any, getters: any },
                             authParams: AuthParams) {

            const response = await axios({
                url: loginAPI,
                data: authParams,
                method: 'PUT',
            }).then((resp) => {
                    const token = resp.data.token;
                    sendMessageToExtention(AUTH_SUCCESS, {token: token, tenantID: getTenantID()});
                    // commit token
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                    });
                },
            ).catch((err) => {
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });

        },
        async [GAUTH_REQUEST]({
                                  commit,
                                  dispatch,
                                  getters
                              }: { commit: any, dispatch: any, getters: any },
                              gauthParams: any): Promise<boolean> {

            commit(AUTH_REQUEST);

            const response: Promise<boolean> = await axios({
                url: loginAPI + '/google',
                data: gauthParams,
                method: 'POST',
            }).then(async (resp) => {
                    const token = resp.data.token;
                    // commit token
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                    });
                    setIdentity(resp.data.user);
                    return resp.data.accountCreation;
                },
            ).catch((err) => {
                // Stop loading spinner
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;

        },
        async [MSAUTH_REQUEST]({
                                   commit,
                                   dispatch,
                                   getters
                               }: { commit: any, dispatch: any, getters: any },
                               msAuthParams: any): Promise<boolean> {

            commit(AUTH_REQUEST);

            const response: Promise<boolean> = await axios({
                url: loginAPI + '/microsoft',
                data: msAuthParams,
                method: 'POST',
            }).then(async (resp) => {
                    const token = resp.data.token;
                    // commit token
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                    });
                    setIdentity(resp.data.user);
                    return resp.data.accountCreation;
                },
            ).catch((err) => {
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;

        },
        async [APPLE_AUTH_REQUEST]({
                                       commit,
                                       dispatch,
                                       getters
                                   }:

                                       { commit: any, dispatch: any, getters: any },
                                   appleAuthParams: any): Promise<boolean> {

            commit(AUTH_REQUEST);

            const response: Promise<boolean> = await axios({
                url: loginAPI + '/apple',
                data: appleAuthParams,
                method: 'POST',
            }).then(async (resp) => {
                    const token = resp.data.token;
                    // commit token
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                    });
                    setIdentity(resp.data.user);
                    return resp.data.accountCreation;
                },
            ).catch((err) => {
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;

        },
        async [SPEACH_AUTH_REQUEST]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                    accessToken: Token) {
            commit(AUTH_REQUEST);
            const response = await axios({
                url: loginAPI + '/speach',
                data: accessToken,
                method: 'POST',
            }).then(async (resp) => {
                    const token = resp.data.token;
                    const privateUserOptions = resp.data.privateUserOptions as PrivateUserOption[];
                    // commit token
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: privateUserOptions
                    });
                    setIdentity(resp.data.user);
                },
            ).catch((err) => {
                // Stop loading spinner
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },
        [STORE_DEDICATED_TENANT]({
                                     commit,
                                     dispatch,
                                     getters
                                 }: { commit: any, dispatch: any, getters: any },
                                 dedicatedTenant: string){
            commit(STORE_DEDICATED_TENANT,dedicatedTenant)

        },
        async [EXTERNAL_SYSTEM_AUTH_REQUEST]({
                                                 commit,
                                                 dispatch,
                                                 getters
                                             }: { commit: any, dispatch: any, getters: any },
                                             accessToken: Token) {

            commit(AUTH_REQUEST);

            const response = await axios({
                url: loginAPI + '/external',
                data: accessToken,
                method: 'POST',
            }).then(async (resp) => {
                    const token = resp.data.token;
                    sendMessageToExtention(AUTH_SUCCESS, {token: token, tenantID: getTenantID()});
                    // commit token
                    const privateUserOptions = resp.data.privateUserOptions as PrivateUserOption[];
                    await store.dispatch(SELECT_WORKSPACE, resp.data.workspaceID);
                    commit(AUTH_SUCCESS, {
                        token,
                        user: resp.data.user,
                        privateUserOptions: privateUserOptions
                    });
                    setIdentity(resp.data.user);
                    redirectIfNeed();
                },
            ).catch((err) => {
                // Stop loading spinner
                commit(AUTH_ERROR);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [AUTH_LOGOUT]({commit, dispatch}: { commit: any, dispatch: any }) {
            const externalId = store.getters.getCurrentPushTokenExternalID;
            const pushNotificationTokens = store.getters.pushNotificationTokens as PushNotificationToken[];
            let currentToken: PushNotificationToken | undefined;
            if (pushNotificationTokens) {
                currentToken = pushNotificationTokens.find((x) => x.externalId === externalId);
            }
            if (currentToken) {
                await dispatch(REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET, currentToken);
            }
            dispatch(DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE);
            commit(RESET_AUTH_STORE);
            sendMessageToExtention(AUTH_LOGOUT);
            // logout from amplitude
            logout();
            dispatch(RESET_PERSIST_STORE);
        },
        async [CREATE_ACCOUNT_USER]({commit, dispatch}: { commit: any, dispatch: any },
                                    {user, password}: { user: User, password: string }) {
            const response = await axios({
                url: userAPI,
                data: {
                    login: user.login,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    contactEmail: user.contactEmail,
                    password
                },
                method: 'POST',
            }).then((resp) => {
                const token = resp.data.token;
                // commit token
                commit(AUTH_SUCCESS, {
                    token,
                    user: resp.data.user,
                    privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                });
                setIdentity(resp.data.user);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [CREATE_ACCOUNT_USER_SILENT]({commit, dispatch}: { commit: any, dispatch: any },
                                           {user}: { user: User }) {
            const response = await axios({
                url: userAPI + '/silent',
                data: {
                    login: user.login,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    contactEmail: user.contactEmail
                },
                method: 'POST',
            }).then((resp) => {
                const token = resp.data.token;
                // commit token
                commit(AUTH_SUCCESS, {
                    token,
                    user: resp.data.user,
                    privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
                });
                setIdentity(resp.data.user);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        [UPDATE_USER_CONNECTED]({commit, dispatch}: { commit: any, dispatch: any }, user: User) {
            commit(UPDATE_USER_CONNECTED, user);
        },
        [UPDATE_SETTING_USER_CONNECTED]({commit, dispatch}: { commit: any, dispatch: any }, setting: SettingUser) {
            commit(UPDATE_SETTING_USER_CONNECTED, setting);
        },
        [ADD_PRIVATE_USER_OPTION]({
                                      commit,
                                      dispatch
                                  }: { commit: any, dispatch: any },
                                  pushNotificationToken: PushNotificationToken) {
            commit(ADD_PRIVATE_USER_OPTION, pushNotificationToken);
        },

        async [SEND_LINK_PASSWORD]({commit, dispatch}: { commit: any, dispatch: any }, login: string) {
            const response = await axios({
                url: forgotPasswordAPI,
                data: {login},
                method: 'POST',
            }).then((resp) => {
                return 'ok';
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REGENERATE_PASSWORD]({commit, dispatch}: { commit: any, dispatch: any },
                                    {
                                        password,
                                        password2,
                                        token
                                    }: { password: string, password2: string, token: string }) {
            const response = await axios({
                url: regeneratePasswordAPI,
                data: {password, password2, token},
                method: 'POST',
            }).then((resp) => {
                commit(AUTH_SUCCESS, resp.data);
                return 'ok';
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        [RETURN_TO_ASKED_URL]({commit, dispatch}: { commit: any, dispatch: any }, url: string) {
            commit(RETURN_TO_ASKED_URL, url);
        },
        async [RESET_ALL_CONNEXTION]({commit, dispatch}: { commit: any, dispatch: any }, url: string) {
            // reset le workspaceID
            await dispatch(SELECT_WORKSPACE, null);
            commit(RESET_ALL_CONNEXTION);
        },
        [ADD_PRIVATE_USER_OPTION]({commit, dispatch}: { commit: any, dispatch: any },
                                  option: PrivateUserOption) {
            commit(ADD_PRIVATE_USER_OPTION, option);
        },
        [REMOVE_PRIVATE_USER_OPTION]({commit, dispatch}: { commit: any, dispatch: any },
                                     option: PrivateUserOption) {
            commit(REMOVE_PRIVATE_USER_OPTION, option);
        },
        [SHOW_POPUP_AUTH]({commit, dispatch}: { commit: any, dispatch: any },
                          value: boolean) {
            commit(SHOW_POPUP_AUTH, value);
        },
        [BLOCK_REDIRECTS_TO_LOGIN_PAGE](
            {commit, dispatch}: { commit: any, dispatch: any, getters: any }
        ) {
            commit(BLOCK_REDIRECTS_TO_LOGIN_PAGE);
        },
        [UNBLOCK_REDIRECTS_TO_LOGIN_PAGE](
            {commit, dispatch}: { commit: any, dispatch: any, getters: any }
        ) {
            commit(UNBLOCK_REDIRECTS_TO_LOGIN_PAGE);
        }
    },
};
