<template>

  <div class="mainContainerTable">
    <div class="videoContainer" v-if="currentMedia && (currentMedia.media.status==='HD' ||currentMedia.media.blobURL) ">
      <transition name="fade">
        <video preload="auto"
               playsinline ref="videoElement"
               :class="'videoElement '+fullscreenClass +' '+mirroring1+' '+filter1"
               :src="urlVideoPlayer1"
               v-show="smoothAlternatePlayer"
               :mainVideoPlayer="smoothAlternatePlayer"
               :style="maxHeight>0 && !isFullscreenMode?'max-height:'+maxHeight+'px':''"
               @canplay="onPlaying"
               @waiting="onWaiting"
               @playing="onPlaying"
               @timeupdate="sendMediaTime"
        >
        </video>
      </transition>
      <transition name="fade">
        <video v-show="!smoothAlternatePlayer" preload="auto" playsinline
               ref="videoElement2"
               :class="'videoElement '+fullscreenClass+' '+mirroring2+' '+filter2"
               :style="maxHeight && !isFullscreenMode?'max-height:'+maxHeight+'px':''"
               :src="urlVideoPlayer2"
               :mainVideoPlayer="!smoothAlternatePlayer"
               @canplay="onPlaying"
               @waiting="onWaiting"
               @playing="onPlaying"
               @timeupdate="sendMediaTime"
        >
        </video>
      </transition>
      <div class="mask"></div>
      <div class="layer1"></div>
      <div class="layer2"></div>
      <div class="layer3"></div>
      <div class="layer4"></div>
      <div class="layer5"></div>
    </div>
    <div v-else class="videoContainer">
      <b-progress size="is-small" data-test="waitingWeetReady" :value="currentMedia?currentMedia.media.progress:0"
                  type="is-primary"
                  style="margin:64px" show-value format="percent"/>
    </div>
    <!-- this is a preload video  -->
    <video preload="metadata" muted v-if="preloadUrl"
           :src="preloadUrl" class="preloadingVideo"/>
  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TimeEvent, TimeEventMedia} from '@/store/timeLine/timeEvent';
import store from '@/store';
import {Media, PlayerMedia} from '@/store/media/mediaModel';
import {getVideoURLForStatus} from '@/utils/util';
import {log} from '@/utils/log';
import {Weet} from '@/store/weet/weetModel';
import {getMediasForWeet} from '@/utils/weetUtil';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import delay from 'delay';
import {PLAYER_SUBTITLE} from '@/store/timeLine/timeLineAction';
import {getBufferingAdvance} from '@/utils/videoUtil';

@Component({})
export default class Player extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: 0})
  private timePlayer!: number;

  @Prop({default: false})
  private fullResponsive!: boolean;

  @Prop({default: -1})
  private maxHeight!: number;

  private mustShow: boolean = false;

  private smoothAlternatePlayer: boolean = false;

  private oldMedia: PlayerMedia | null = null;
  private currentMedia: PlayerMedia | null = null;

  // filter
  private filter1: string = '';
  private filter2: string = '';

  // url
  private urlVideoPlayer1: string = '';
  private urlVideoPlayer2: string = '';

  private mirroring1: string = '';
  private mirroring2: string = '';

  private pauseEndTimeOutId: number = 0;

  // preload the next video
  private preloadUrl: string = '';

  get isVoiceOverPreview(){
    return store.getters.isVoiceOverPreviewMode;
  }

  private sendMediaTime() {
    const videoElement = this.videoElement();
    if (videoElement) {
      this.$emit('mediaTime', videoElement.currentTime * 1000);
    }
  }

  public seekInTimeLine(time) {
    // get MediaEvent before timeLine
    const mediaEvent = getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY);
    if (mediaEvent) {
      this.seekMediaToTimeLineTime(mediaEvent);
    }
  }

  public pauseMedia() {
    this.videoElement()?.pause();
  }

  public playMedia(time: number) {
    const videoElement = this.videoElement();
    if (videoElement) {
      if (time) {
        this.seekInTimeLine(time);
      }
      videoElement.play().catch((e) => {
        log.debug(e.message);
      });
    }
  }

  private mounted() {
    this.mustShow = true;
    this.onMediaChange();
  }

  private onAlternatePlayer() {

    this.urlVideoPlayer1 = this.urlVideoPlayer(this.smoothAlternatePlayer);
    this.urlVideoPlayer2 = this.urlVideoPlayer(!this.smoothAlternatePlayer);

    this.mirroring1 = this.mirroringClass(this.smoothAlternatePlayer);
    this.mirroring2 = this.mirroringClass(!this.smoothAlternatePlayer);

    // if media not ready, no video element
    // manage smooth switch resolution Player
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();

    setTimeout(() => {
      if (oldVideoElement) {
        oldVideoElement.style.position = 'absolute';
      }
    }, 100);
    if (videoElement) {
      videoElement.style.position = 'relative';
    }

    // manage smooth switch filter
    if (this.smoothAlternatePlayer) {
      setTimeout(() => {
        this.filter1 = this.filter(this.smoothAlternatePlayer);
        this.filter2 = '';
        if (oldVideoElement) {
          oldVideoElement.style.position = 'absolute';
        }
        if (videoElement) {
          videoElement.style.position = 'relative';
        }
        window.dispatchEvent(new Event('resize'));
      }, 250);
    } else {
      setTimeout(() => {
        this.filter2 = this.filter(!this.smoothAlternatePlayer);
        this.filter1 = '';
        if (oldVideoElement) {
          oldVideoElement.style.position = 'absolute';
        }
        if (videoElement) {
          videoElement.style.position = 'relative';
        }
        window.dispatchEvent(new Event('resize'));
      }, 250);
    }
  }

  private urlVideoPlayer(alternateSwitch: boolean): string {
    if (alternateSwitch) {
      if (this.currentMedia) {
        return this.currentMedia.url;
      } else {
        return '';
      }
    } else {
      if (this.oldMedia) {
        return this.oldMedia.url;
      } else {
        return '';
      }
    }
  }

  private mirroringClass(alternateSwitch: boolean): string {
    if (alternateSwitch) {
      if (this.currentMedia) {
        return this.currentMedia.mirroring ? 'mirroring' : '';
      } else {
        return '';
      }
    } else {
      if (this.oldMedia) {
        return this.oldMedia.mirroring ? 'mirroring' : '';
      } else {
        return '';
      }
    }
  }

  private filter(alternateSwitch: boolean): string {
    if (alternateSwitch) {
      if (this.currentMedia) {
        let filter = this.currentMedia.filter.toString();
        if (!this.play) {
          filter += ' noAnimation';
        }
        return filter;
      } else {
        return '';
      }
    } else {
      if (this.oldMedia) {
        let filter = this.oldMedia.filter.toString();
        if (!this.play) {
          filter += ' noAnimation';
        }
        return filter;
      } else {
        return '';
      }
    }
  }


  get isFullscreenMode(): boolean {
    return store.getters.isFullscreenMode;
  }

  get volume(): number {
    return store.getters.volume;
  }

  get fullscreenClass(): string {
    if (this.isFullscreenMode) {
      return '';
    } else {
      if (!this.fullResponsive) {
        return 'maxHeight';
      }
    }
    return '';
  }

  /**
   * Get current media Description
   */
  get mediaDescription(): TimeEventMedia {
    return store.getters.getPlayerMainMedia;
  }

  // get the video HTML element
  private videoElement(): HTMLVideoElement | undefined {
    if (this.smoothAlternatePlayer) {
      return this.$refs.videoElement as HTMLVideoElement;
    } else {
      return this.$refs.videoElement2 as HTMLVideoElement;
    }
  }

  private oldVideoElement(): HTMLVideoElement | undefined {
    if (!this.smoothAlternatePlayer) {
      return this.$refs.videoElement as HTMLVideoElement;
    } else {
      return this.$refs.videoElement2 as HTMLVideoElement;
    }
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  get play(): boolean {
    return store.getters.isPlaying;
  }

  @Watch('weet')
  private onWeetChange() {
    this.currentMedia = this.playerMedia;
    this.urlVideoPlayer1 = this.urlVideoPlayer(this.smoothAlternatePlayer);
    this.urlVideoPlayer2 = this.urlVideoPlayer(!this.smoothAlternatePlayer);
    // this.onMediaChange();
    this.mirroring1 = this.mirroringClass(this.smoothAlternatePlayer);
    this.mirroring2 = this.mirroringClass(!this.smoothAlternatePlayer);

    // manage smooth switch filter
    if (this.smoothAlternatePlayer) {
      this.filter1 = this.filter(this.smoothAlternatePlayer);
      this.filter2 = '';
    } else {
      this.filter2 = this.filter(!this.smoothAlternatePlayer);
      this.filter1 = '';
    }
  }

  private getMediasForMediaId(mediaID: string): Media | undefined {
    return getMediasForWeet(this.weet, mediaID);
  }


  /**
   * Get the media of an url,
   * if (the media is not found, we return ''
   * if( this is a blob url, we return blob url
   * else if we are in SD mode, we return SD video
   * else we return the status video (SD or HD) depend of the ready status
   */
  get playerMedia(): PlayerMedia | null {

    if (this.mediaDescription !== undefined && this.mediaDescription && this.mediaDescription.mediaID !== '') {
      const media: Media | undefined = this.getMediasForMediaId(this.mediaDescription.mediaID);
      if (media === undefined) {
        // mediaNot found
        return null;
      }
      const playerMedia = new PlayerMedia(media);
      if (media.type === SourceVideoType.WEBCAM) {
        playerMedia.mirroring = true;
      }
      playerMedia.type = media.type;
      playerMedia.filter = this.mediaDescription.filter;

      if (media.blobURL !== '' && media.blobURL !== undefined && media.status !== 'HD') {
        playerMedia.url = media.blobURL;
      } else {
        playerMedia.url = getVideoURLForStatus(media);
      }
      return playerMedia;
    } else {
      return null;
    }
  }

  private getPreloadingUrl(): string {
    const timeEvent = store.getters.getNextPlayerTimeEvent;
    if (!timeEvent || !timeEvent.mainMedia) {
      return '';
    }
    const media = this.getMediasForMediaId(timeEvent.mainMedia.mediaID);
    if (!media) {
      return '';
    }
    return getVideoURLForStatus(media) + '#t=' + Math.floor(timeEvent.mainMedia.currentTime / 1000);
  }

  // seek without wait the timesycnhronisation
  get quickSeek(): number {
    return store.getters.getQuickSeek;
  }


  get subtitleUrl(): string {
    if (this.mediaDescription !== undefined && this.mediaDescription.mediaID !== undefined &&
        this.mediaDescription.mediaID !== '') {
      const media: Media | undefined = this.getMediasForMediaId(this.mediaDescription.mediaID);
      if (media) {
        return media?.subtitlePath;
      }
    }
    return '';
  }

  /**
   * Watch the mediaDescription
   * if the media description change, we have to refresh the state of the player
   * Media Change after playing of after seeking
   *
   */
  @Watch('mediaDescription')
  private onMediaChange() {
    this.smoothAlternatePlayer = !this.smoothAlternatePlayer;
    this.oldMedia = this.currentMedia;
    this.currentMedia = this.playerMedia;
    this.preloadUrl = this.getPreloadingUrl();
    if (this.subtitleUrl) {
      store.dispatch(PLAYER_SUBTITLE, this.subtitleUrl);
    }
    this.onAlternatePlayer();
    this.refreshPlayState();
    this.seekInTimeLine(this.timePlayer);
  }

  @Watch('volume')
  private onChangeVolume() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.videoElement();
    if (videoElement) {
      videoElement.volume = this.volume / 100;
    }
    if (oldVideoElement) {
      oldVideoElement.volume = this.volume / 100;
    }
  }

  @Watch('isVoiceOverPreview')
  private onVoiceOverPreviewChange() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.oldVideoElement();

    if (videoElement) {
      videoElement.muted = this.isVoiceOverPreview
    }
    if (oldVideoElement) {
      oldVideoElement.muted = this.isVoiceOverPreview
    }
  }


  /**
   * change the playrate of the video
   */
  @Watch('speedMode')
  private onSpeedChange() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.videoElement();
    if (videoElement) {
      videoElement.playbackRate = this.speedMode;
    }
    if (oldVideoElement) {
      oldVideoElement.playbackRate = this.speedMode;
    }
  }

  private animateFilter() {
    // manage smooth switch filter
    if (this.smoothAlternatePlayer) {
      this.filter1 = this.filter(this.smoothAlternatePlayer);
      this.filter2 = '';
    } else {
      this.filter2 = this.filter(!this.smoothAlternatePlayer);
      this.filter1 = '';
    }
  }

  /**
   * change the status if play();
   */
  @Watch('play')
  private togglePlay() {
    if (this.play) {
      this.refreshPlayState();
    } else {
      this.videoElement()?.pause();
    }
    this.animateFilter();
  }

  /**
   * Seek the player in the rightTime
   */
  private async seekMediaToTimeLineTime(currentTimeEvent: TimeEvent) {
    const videoElement = this.videoElement();

    // 20 secondes - 5 (eventStart)
    const mediaMustBeInTime = this.timePlayer - currentTimeEvent.time + currentTimeEvent.mainMedia.currentTime;
    const secondValue = (mediaMustBeInTime / 1000);
    // if no duration, we cannot seek on the timeline, so we wait :-(
    let waiting = 0;
    while (waiting <= 50 && videoElement && videoElement.seekable.length === 0 && isNaN(videoElement.duration)) {
      await delay(100);
      // console.log('Wait main');
      waiting++;
    }
    if (videoElement && videoElement.duration > secondValue) {
      videoElement.currentTime = secondValue;
    }
  }

  /**
   * Refresh the status of the player if need
   */
  private refreshPlayState() {
    const videoElement = this.videoElement();
    // we have to pause the video before the end, if not a wrong frame is played
    if (videoElement && videoElement.currentTime + 0.5 >= videoElement.duration) {
      // pause un X milliseconde to avoid return at the first frame 0.5
      const currentUrl = this.currentMedia?.url;
      clearTimeout(this.pauseEndTimeOutId);
      // @ts-ignore
      this.pauseEndTimeOutId = setTimeout(() => {
        // we checked if the video don't change
        if (currentUrl === this.currentMedia?.url) {
          videoElement?.pause();
        }
      }, (videoElement.duration - videoElement.currentTime - 0.05) * 100);
      // stop function, to avoir replayed
      return;
    }

    // if no media, do nothing
    if (this.mediaDescription === undefined) {
      return;
    }
    // if the media must be running and
    // we are in playing status
    if (this.mediaDescription.running
        && this.play) {
      if (videoElement && videoElement.paused) {
        log.debug('[MainPlayer -> refreshPlayState] => launch  Play');
        // launch the play
        videoElement.play().catch((e) => {
          log.debug(e.message);
        });
      }
    } else {
      // if not we pause the video
      if (videoElement && !videoElement.paused) {
        log.debug('[MainPlayer ->  refreshPlayState] => launch  Pause');
        videoElement.pause();
      }
    }
    // we stop the old video
    this.restoreSetting();
  }


  private timeOutWaiting: any = 0;

  private onPlaying(e) {
    clearTimeout(this.timeOutWaiting);
    this.$emit('playing');
    this.restoreSetting();
    // if the can play change the resolution we submit a reize event for interaction placement
    window.dispatchEvent(new Event('resize'));
  }


  private restoreSetting() {
    // we stop the old video
    this.oldVideoElement()?.pause();
    this.onChangeVolume();
    this.onSpeedChange();
  }

  private onWaiting() {
    const videoElement = this.videoElement();
    // if the time don't change in 500ms, we inform we are un waiting
    this.timeOutWaiting = setTimeout(() => {
      if (videoElement) {
        if (getBufferingAdvance(videoElement) < 3) {
          // console.log('[MAIN] => Waiting');
          // console.log('[MAIN] videoElement.url ' + videoElement.src);
          // console.log('[MAIN] videoElement.currentTime:' + videoElement.currentTime);
          // console.log('[MAIN] videoElement.display ' + videoElement.style.display);
          this.$emit('waiting');
        }
      } else {
        // / console.log('[MAIN] => Waiting, no videoElement');
        this.$emit('waiting');
      }
    }, 500);


  }

  /**
   * Remove url on destroy
   */
  private beforeDestroy() {
    const videoElement = this.videoElement();
    const oldVideoElement = this.videoElement();
    if (videoElement) {
      videoElement.src = '';
    }
    this.preloadUrl = '';
    if (oldVideoElement) {
      oldVideoElement.src = '';
    }
  }


  //////// ****** DEBUG SECTION ************** ////
  // private debugOnWeetProgress(event) {
  //   // compute buffering
  //   const video = event.target;
  //   let range = 0;
  //   const bf = video.buffered;
  //   const time = video.currentTime;
  //   // select the good range
  //   while (!(bf.length > range && bf.start(range) <= time && time <= bf.end(range))) {
  //     range = range + 1;
  //     console.log(range);
  //   }
  //   const loadStartPercentage = bf.start(range) / video.duration;
  //   const loadEndPercentage = bf.end(range) / video.duration;
  //   const loadPercentage = (loadEndPercentage - loadStartPercentage) * 100;
  //   if (video === this.$refs.videoElement) {
  //     this.progress1 = loadPercentage;
  //   }
  //   if (video === this.$refs.videoElement2) {
  //     this.progress2 = loadPercentage;
  //   }
  //   if (video === this.$refs.preloadingVideo) {
  //     this.pourcPreloading = loadPercentage;
  //   }
  // }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gridBreakPoint.scss";

.mainContainerTable {
  // Safari don't really like flex + and height 100%
  // we have to use table...
  //display: flex;
  //   display: table;
  height: 100%;
  width: 100%;
  font-size: 0;
  animation-duration: 0.2s;
  transition: all 1s ease;

  .videoContainer {
    position: relative;
    height: 100%;

    // Safari don't really like flex + and height 100%
    // we have to use table...
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    // transition: transform 1s ease;
    // transition-delay: 2s;

    .videoElement {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      max-width: 100%;
      margin: auto;
      animation-duration: 2s;
      grid-row-start: 1;
      grid-column-start: 1;
      border-radius: 8px;
      &.maxHeight {
        height: auto;
        min-height: $minPlayerHeight;
        max-height: $maxPlayerHeight;
      }

      &.mirroring {
        transform: scale3d(-1, 1, 1);
      }
    }
  }

  .preloadingVideo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    max-height: 50px;
  }
}
</style>
