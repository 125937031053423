<template>
  <w-field :label="label" :type="'is-'+color">
    <b-input :icon="icon"
             :size="'is-'+size"
             :type="type"
             :rounded="rounded"
             :disabled="!enabled"
             v-model="realValue"
             :placeholder="placeholder"
             :required="required"
             :expanded="expanded"
             @blur="onBlur"
             @focus="onFocus"
             @input="onChangeValue"
             :name="name"
             :data-test="dataTest"
             :minlength="minlength"
             :maxlength="maxlength"
             @keyup.native.enter="onEnter"
    ></b-input>
  </w-field>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WField from '@/components/wrapper/w-field.vue';

@Component({
  components: {WField}
})
export default class WInput extends Vue {
  @Prop({default: ''})
  private icon!: string;
  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default: 'black'})
  private color!: string;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: ''})
  private placeholder!: string;
  @Prop({default: ''})
  private label!: string;
  @Prop({default: ''})
  private value!: string;
  @Prop({default: false})
  private required!: boolean;
  @Prop({default: false})
  private expanded!: boolean;
  @Prop({default: 'text'})
  private type!: string;
  @Prop()
  private dataTest!: string;
  @Prop({default: ''})
  private name!: string;

  @Prop({default: 0})
  private minlength!: string;

  @Prop()
  private maxlength!: string;
  @Prop({default:false})
  private validateOnEnter!:boolean;

  private realValue: string = "";

  private mounted() {
    this.realValue = this.value;
  }

  public onBlur() {
    this.$emit('blur', this.realValue);
  }

  public onFocus() {
    this.$emit('focus',  this.realValue);
  }

  public onChangeValue() {
    this.$emit('input', this.realValue);
  }

  @Watch('value')
  public onChangePropValue() {
    this.realValue = this.value;
  }

  public onEnter(e){
    if(this.validateOnEnter){
      this.$emit('blur',this.realValue);
    }
  }

}
</script>

<style scoped lang="scss">
</style>
