<template>
  <div class="blueLayout" :class="size" @click="moveOrGoToPayment">
    <w-icon icon="shimmer" size="small" color="white" class="iconUpgradeplan"/>
    <div class="labelUpgrade">{{ $t('generic.upgrade') }}</div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {canMoveWeetFromCurrentWorkspace, hasPaidWorkspace} from '@/utils/workspaceUtil';
import {alertMessageWithConfirmation} from '@/utils/dialog';
import router from '@/router';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import {canEditThisWeet} from '@/utils/weetUtil';
import {SHOW_POPUP_CREATION} from '@/store/recordingState/recordStateAction';
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";

@Component({
  components: {WIcon, WToolTip, WButton},
})
export default class UpgradePlanTagButton extends Vue {

  @Prop({required: false})
  private weet!: Weet;

  @Prop({default: 'default'})
  private size!: string;


  get isEditable(): boolean {
    if (this.weet) {
      return canEditThisWeet(this.weet);
    } else {
      return false;
    }
  }

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspace;
  }

  get canMoveWeet(): boolean {
    if (this.weet) {
      return this.isEditable && (this.weet.workspaces.length === 0 || canMoveWeetFromCurrentWorkspace());
    } else {
      return false;
    }
  }

  private async moveOrGoToPayment() {
    // if i'm in PS
    // and i have a paid workspace
    // and i can move my weet
    if (!this.selectedWorkspaceID && hasPaidWorkspace() && this.canMoveWeet) {
      await alertMessageWithConfirmation(
          this.$t('menuWeet.downloadPs.move.description').toString(),
          this.$t('menuWeet.downloadPs.move.title').toString(),
      );
      this.$emit('move');
    } else {
      if (store.getters.showPopUpCreation) {
        store.dispatch(SHOW_POPUP_CREATION, false);
      }
      store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
      router.push({name: 'MyWorkspacePayment'});
    }
  }

}
</script>
<style scoped lang="scss">

.blueLayout {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  padding: calc(0.5em - 1px) 24px calc(0.5em - 1px) 24px;
  border-radius: 16px;
  height: 48px;
  transition: transform ease 0.2s;

  .iconUpgradeplan {
    margin-right: 4px;
  }

  .labelUpgrade {
    line-height: 24px;
    letter-spacing: 0.3px;
    font-size: 13px;
    font-weight: 900;
    color: white;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
  }

  &.small {
    padding: 15px;
    height: 32px;
    border-radius: 12px;

    .labelUpgrade {
      line-height: 16px;
    }
  }
}

</style>
