import { render, staticRenderFns } from "./w-error.vue?vue&type=template&id=fb813770&scoped=true"
import script from "./w-error.vue?vue&type=script&lang=ts"
export * from "./w-error.vue?vue&type=script&lang=ts"
import style0 from "./w-error.vue?vue&type=style&index=0&id=fb813770&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb813770",
  null
  
)

export default component.exports