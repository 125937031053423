<template>
  <Transition :name="nameOfZoomAnime" v-if="!isWeetHidden">
    <div class="playContainer" v-if="!isNativePlayer">
      <w-icon icon="package-variant" size="large" class="emptyIcon"/>
      <span class="emptyLabel">{{ $t('player.emptyWeet') }}</span>
      <w-button icon="record" color="primary" class="buttonEditWeet" v-if="canEditThisWeet && !fullResponsive"
                @click="editAWeet">
        {{ $t('player.firstSection') }}
      </w-button>
    </div>
  </Transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import store from '@/store';
import mobileDetect from 'mobile-device-detect';
import {canEditThisWeet, isWeetIsReady, launchWeetCreation} from '@/utils/weetUtil';
import {CLEAR_SUBTITLE, SWITCH_PLAYER_FULLSCREEN} from '@/store/timeLine/timeLineAction';
import delay from 'delay';
import {hideGlobalTour} from '@/utils/tour';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import screenfull from 'screenfull';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {LOAD_WEET_FOR_PLAYING} from '@/store/weet/weetAction';
import router from '@/router';
import {inform} from '@/utils/dialog';
import {RETURN_TO_ASKED_URL} from '@/store/auth/authAction';
import {EXTERNAL_TOOL_INIT} from '@/store/integration/integrationAction';
import {SWITCH_TO_NATIVE_PLAYER} from '@/store/setup/setupAction';
import {RESET_COMMENTMODULE} from '@/store/comment/commentAction';
import {isSpeach} from "@/utils/speachUtils";

@Component({
  components: {WIcon, WButton},
})
export default class EmptyWeetPlayer extends Vue {
  @Prop({default: ''})
  private weetID!: string;

  @Prop({default: false})
  private fullResponsive!: boolean;

  @Prop({default: 'myWeetPlayer'})
  private fullScreenElement!: string;

  private mobile: boolean = false;
  private weet: Weet | null = null;

  // refresh
  private refreshAivailable: boolean = true;

  get isNativePlayer(): boolean {
    // no native player if video not ready
    if ((this.weet && !this.weet.exportAivailable) || !this.mobile) {
      return false;
    }
    return store.getters.isNativePlayer === true;
  }

  get nameOfZoomAnime(): string {
    if (this.mobile) {
      return '';
    } else {
      return 'fade';
    }
  }

  get isWeetHidden(): boolean {
    return this.weet?.hidden || false;
  }

  get canEditThisWeet(): boolean {
    if (this.weet) {
      return canEditThisWeet(this.weet) && !isSpeach();
    } else {
      return false;
    }
  }


  get isFullscreen(): boolean {
    return store.getters.isFullscreenMode;
  }

  private async mounted() {
    if (this.weetID !== '') {

      // switch to native player if need
      this.needToSwitchOnNativePlayer();

      store.dispatch(EXTERNAL_TOOL_INIT);
      while (this.refreshAivailable && (!this.weet || !isWeetIsReady(this.weet))) {
        await this.loadWeet();
        await delay(2000);
      }
      if (screenfull.isEnabled) {
        screenfull.on('change', this.onScreenFullScreen);
      }

    }
  }

  private async editAWeet() {
    if (this.weet) {
      if (this.isFullscreen) {
        this.onChangeFullScreenMode();
        await store.dispatch(SWITCH_PLAYER_FULLSCREEN, false);
        await delay(500);
      }
      hideGlobalTour();
      setActionEvent(ActionEvent.player_button_end_edit);
      launchWeetCreation(this.weet.weetID);
    }
  }

  @Watch('isFullscreen')
  private onChangeFullScreenMode() {
    const element = this?.$parent?.$refs[this.fullScreenElement] as HTMLElement;
    if (screenfull.isEnabled) {
      if (!this.isNativePlayer) {
        // @ts-ignore
        if (this.isFullscreen !== screenfull.isFullscreen) {
          screenfull.toggle(element);
        }
      }
    }
  }

  private async loadWeet() {
    try {
      await store.dispatch(LOAD_WEET_FOR_PLAYING, this.weetID);
      this.weet = store.getters.getWeet(this.weetID);
    } catch (err: any) {
      if (err.status === 403) {
        // ask authentication
        if (store.getters.isAuthenticated) {
          router.push({name: 'AccessDenied'});
        } else {
          inform(this.$t('player.accessDenied').toString());
          store.dispatch(RETURN_TO_ASKED_URL, document.location.href);
          router.push({name: 'Authentication'});
        }
      } else {
        router.push({name: 'NotFoundWeet'});
      }
    }
  }

  private needToSwitchOnNativePlayer() {
    this.mobile = mobileDetect.isMobile;
    // if we are on mobile, but no type of player define, we switch to native player
    if (this.mobile) {
      if (store.getters.isNativePlayer === undefined) {
        store.dispatch(SWITCH_TO_NATIVE_PLAYER, true);
      }
    }
  }

  private onScreenFullScreen(event) {
    if (event.target === this?.$parent?.$refs[this.fullScreenElement]
        || document.fullscreenElement === null) {
      this.setFullScreen();
    }
  }

  private setFullScreen() {
    // @ts-ignore
    store.dispatch(SWITCH_PLAYER_FULLSCREEN, screenfull.isFullscreen);
  }

  @Watch('showControl')
  private emitShowControl() {
    this.$emit('controlChange', true);
  }

  private beforeDestroy() {
    this.refreshAivailable = false;
    // clean the plauyer
    store.dispatch(RESET_COMMENTMODULE);
    store.dispatch(CLEAR_SUBTITLE);

    if (screenfull.isEnabled) {
      // @ts-ignore
      screenfull.off('change', this.onScreenFullScreen);
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';
@import '@/scss/animation.scss';

.playContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  text-align: center;
  height: 100%;
  cursor: pointer;
  animation-duration: 0.2s;
  background-color: var(--light);
  color: var(--dark);

  .emptyIcon {
    position: absolute;
    top: 36px;
    zoom: 250%;
  }

  .emptyLabel {
    position: absolute;
    top: 236px;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
  }

  .buttonEditWeet {
    position: absolute;
    top: 301px;
    transition: transform ease-in-out 0.2s;
    @extend .shadow3;
    border-radius: 16px;

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
