import {TimeEventType} from '@/enum/TimeEventType';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {Media} from '@/store/media/mediaModel';
import {User} from '@/store/user/userModel';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {CommentTypeEnum} from "@/store/comment/commentTypeEnum";


export class TimeEvent {

  public type: TimeEventType;
  public time: number;
  public fullscreenMode: boolean = false;
  public positionLayout: StickerPosition = StickerPosition.BOTTOM_LEFT;
  // DESCRPTION MEDIA ID
  public mainMedia: TimeEventMedia = new TimeEventMedia();
  // this is the stickers
  public secondMedia: TimeEventMedia = new TimeEventMedia();

  public info: TimeEventInfo | undefined = undefined;

  public value: string = '';

  constructor(type: TimeEventType, time: number) {
    this.type = type;
    this.time = time;
  }
}


export class TimeEventMedia {
  public mediaID: string = '';
  public currentTime: number = 0;
  public filter: FilterVideoType = FilterVideoType.NONE;
  public running: boolean = false;
}

export class TimeEventInfo {
  public webcamMute: boolean | null = null;
  public microphoneMute: boolean | null = null;
  public virtualBackground: string | null = null;
  public noiseCancelation: boolean | null = null;
  public screenbackground: ScreenVirtualBackgroundEnum | null = null;
  public faceTracking: boolean | null = null;
  public captureScreenQuality: number | null = null;
  public screenSurface: string | null = null;
}

export class Segment {
  public start: TimeEvent;
  public end: TimeEvent;
  public id: string = '';

  constructor(start: TimeEvent, end: TimeEvent) {
    this.start = start;
    this.end = end;
  }
}

export class SectionEventDescriptor {
  public time: number = 0;
  public type: TimeEventType|CommentTypeEnum|'DISABLED_FROM_START'|'DISABLED_TO_END' = TimeEventType.STEP;
  public thumbType: string = '';
  public image: string = '';
  public tooltip: string = '';
  public key: string = '';
  public userID: string = '';
  public commentID: string = '';
  public name: string = '';
  public media: Media | undefined = undefined;
  public mainMedia: Media | undefined = undefined;
  public creator: User | undefined = undefined;
}


export class OffsetTimeEventUpdate {
  public start: number = 0;
  public to: number = 0;
  public duration: number = 0;
}
