const GRID1X = 720;
const GRID2X = 1080;
const GRID3X = 1440;
export const isGrid1X = () => {
  return (window.innerWidth <= GRID1X);
};

export const isGrid2X = () => {
  return (window.innerWidth > GRID1X && window.innerWidth < GRID2X);
};
export const isGrid3X = () => {
  return (window.innerWidth > GRID2X && window.innerWidth < GRID3X);
};

export const isGrid4X = () => {
  return (window.innerWidth > GRID3X);
};
