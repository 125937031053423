<template>
  <div class="divider">
    <div class="lineContainer"/>
    <div class="labelDivider">{{label}}</div>
    <div class="lineContainer"/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterCenter from '@/components/layout/CenterCenter.vue';

@Component({})
export default class WDivider extends Vue {
  @Prop({default: ''})
  private label!: string;

}
</script>

<style scoped lang="scss">

.divider {
  display: flex;
  height: 22px;
  width: 100%;
  color: var(--light2);
  font-size: 14px;
  align-items: center;

  .lineContainer {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid var(--light1);
    flex: 1;
  }

  .labelDivider {
    padding-left: 16px;
    white-space: nowrap;
    padding-right: 16px;
  }
}
</style>
