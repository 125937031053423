import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  CANCEL_WORKSPACE_SUBSCRIPTION,
  CONFIRM_CARD_PAYMENT,
  GET_CUSTOMER_PORTAL_URL,
  GET_MONTHLY_SUBSCRIPTION_PRICE,
  GET_YEARLY_SUBSCRIPTION_PRICE,
  GET_WORKSPACE_PAYMENT,
  SUBSCRIBE_TO_PRO_PLAN,
  CHANGE_PRICE_SUBSCRIPTION,
  REACTIVATE_WORKSPACE_SUBSCRIPTION,
  UPDATE_WORKSPACE_SEAT_COUNT,
  GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT
} from '@/store/payment/paymentAction';
import {
  ConfirmCardPayment,
  CheckoutSessionOrCustomerPortal,
  CreatePaymentSubscriptionParams,
  Payment,
  Price, AmountNewQuantity
} from '@/store/payment/paymentModel';
import store from '@/store';
import {
  GET_MY_WORKSPACES_LIST
} from '@/store/workspace/workspaceAction';

const paymentAPI = BACK_ENDPOINT + '/my/payment';

export default {
  state: {
    payment: null,
    monthlyPrice: null,
    yearlyPrice: null,

  },
  mutations: {
    [GET_WORKSPACE_PAYMENT]: (state: any, payment: Payment) => {
      state.payment = payment;
    },
    [GET_MONTHLY_SUBSCRIPTION_PRICE]: (state: any, price: Price) => {
      state.monthlyPrice = price;
    },
    [GET_YEARLY_SUBSCRIPTION_PRICE]: (state: any, price: Price) => {
      state.yearlyPrice = price;
    },
  },
  getters: {
    getMyWorkspacePayment: (state: any): Payment => {
      return state.payment as Payment;
    },
    getMonthlyPrice: (state: any): Price => {
      return state.monthlyPrice as Price;
    },
    getYearlyPrice: (state: any): Price => {
      return state.yearlyPrice as Price;
    },
  },
  actions: {
    async [SUBSCRIBE_TO_PRO_PLAN](
      {commit, dispatch}: { commit: any, dispatch: any },
      params: CreatePaymentSubscriptionParams
    ): Promise<CheckoutSessionOrCustomerPortal> {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/subscribe/${workspace}`,
        data: params,
        method: 'POST',
      }).then((resp) => {
        return resp.data as CheckoutSessionOrCustomerPortal;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_CUSTOMER_PORTAL_URL](
      {commit, dispatch}: { commit: any, dispatch: any }
    ): Promise<CheckoutSessionOrCustomerPortal> {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/customer-portal/${workspace}`,
        method: 'GET',
      }).then((resp) => {
        return resp.data as CheckoutSessionOrCustomerPortal;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [CANCEL_WORKSPACE_SUBSCRIPTION](
      {commit, dispatch}: { commit: any, dispatch: any }
    ) {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/subscribe/${workspace}`,
        method: 'DELETE',
      }).then((resp) => {
        commit(GET_WORKSPACE_PAYMENT, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [REACTIVATE_WORKSPACE_SUBSCRIPTION](
      {commit, dispatch}: { commit: any, dispatch: any }
    ) {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/subscribe/reactivate/${workspace}`,
        method: 'POST',
      }).then((resp) => {
        commit(GET_WORKSPACE_PAYMENT, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_MONTHLY_SUBSCRIPTION_PRICE](
      {commit, dispatch}: { commit: any, dispatch: any },
    ) {
      return await axios({
        url: `${paymentAPI}/prices/month`,
        method: 'GET',
      }).then((resp) => {
        commit(GET_MONTHLY_SUBSCRIPTION_PRICE, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_YEARLY_SUBSCRIPTION_PRICE](
      {commit, dispatch}: { commit: any, dispatch: any },
    ) {
      return await axios({
        url: `${paymentAPI}/prices/year`,
        method: 'GET',
      }).then((resp) => {
        commit(GET_YEARLY_SUBSCRIPTION_PRICE, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_WORKSPACE_PAYMENT](
      {commit, dispatch}: { commit: any, dispatch: any }
    ) {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/${workspace}`,
        method: 'GET',
      }).then((resp) => {
        commit(GET_WORKSPACE_PAYMENT, resp.data);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [CONFIRM_CARD_PAYMENT](
      {commit, dispatch}: { commit: any, dispatch: any }, confirmCardPayment: ConfirmCardPayment
    ) {
      return await axios({
        url: `${paymentAPI}/card/confirm`,
        method: 'POST',
        data: confirmCardPayment,
      }).then((resp) => {
        commit(GET_WORKSPACE_PAYMENT, resp.data);
        // update list of workspace
        store.dispatch(GET_MY_WORKSPACES_LIST);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [CHANGE_PRICE_SUBSCRIPTION](
      {commit, dispatch}: { commit: any, dispatch: any },  {
        priceID
      }: {
        priceID: string
      }
    ) {
      const workspaceID = store.getters.getSelectedWorkspaceID;
      if (!workspaceID) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/${workspaceID}/price`,
        method: 'PUT',
        data: {
          priceID
        },
      }).then((resp) => {
        // we update the payment
        store.dispatch(GET_WORKSPACE_PAYMENT);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [UPDATE_WORKSPACE_SEAT_COUNT](
      {commit, dispatch}: { commit: any, dispatch: any },
      {
        seatNumber,
        amount
      }: {
        seatNumber: number,
        amount: number
      }
    ) {
      const workspaceID = store.getters.getSelectedWorkspaceID;
      if (!workspaceID) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/${workspaceID}/seatNumber`,
        method: 'PUT',
        data: {
          seatNumber: seatNumber,
          amount: amount
        },
      }).then((resp) => {
        const payment = resp.data as Payment;
        commit(GET_WORKSPACE_PAYMENT, payment);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT](
      {commit, dispatch}: { commit: any, dispatch: any },
      {
        seatNumber
      }: { seatNumber: number }
    ): Promise<AmountNewQuantity> {
      const workspaceID = store.getters.getSelectedWorkspaceID;
      if (!workspaceID) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${paymentAPI}/${workspaceID}/seatNumber/amount?seatNumber=${seatNumber}`,
        method: 'GET'
      }).then((resp) => {
        return resp.data as AmountNewQuantity;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },

};
