<template>
  <div>
    <transition name="zoom" v-if="!buttonMode">
      <div v-show="mustShow" v-on="$listeners" class="recordButtonLayout" :class="classMode">
        <div class="recordButtonBorder">
          <div class="recordButtonBody">

          </div>
        </div>
      </div>
    </transition>

    <transition name="zoom" v-else>
      <w-button v-show="mustShow" @click="$emit('click');mustShow=false" data-test="recorderStartPauseButton"
                :icon="isRecordingStop?'record':'pause'" :enabled="isWebcamAvailabelForRecord">
        {{ labelRecord }}
      </w-button>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {PAUSE_RECORDING, PING_RECORD, START_RECORDING} from '@/store/recordingState/recordStateAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';

@Component({
  components: {WButton},
})
export default class RecorderStartPauseButton extends Vue {
  @Prop({default: true})
  private enabled!: boolean;
  private mustShow: boolean = false;

  @Prop({default: false})
  private buttonMode!: boolean;

  get isWebcamAvailabelForRecord() {
    return store.getters.isWebcamAivailableForRecord;
  }

  get labelRecord(): string {
    if (this.isRecordingStop) {
      if (store.getters.getTimerRecord > 100) {
        return this.$t('createWeetComponent.record.button.continueRecord').toString();
      } else {
        return this.$t('createWeetComponent.record.button.record').toString();
      }
    } else {
      return this.$t('createWeetComponent.record.button.pause').toString();
    }
  }

  get isRecordingStop() {
    return store.getters.isStartRecordingAivailable;
  }

  @Watch('isRecordingStop')
  private onRecordingStateChange() {
    this.mustShow = true;
  }

  private mounted() {
    this.mustShow = true;
    // receive message frome extention
    window.addEventListener('message', this.onMessageReceive);
  }

  private onMessageReceive(event) {
    if (event.data !== undefined && event.data.type !== undefined) {
      if (event.data.type === PAUSE_RECORDING) {
        if (!this.isRecordingStop) {
          this.$emit('click');
        }
      } else if (event.data.type === START_RECORDING) {
        if (this.isRecordingStop) {
          this.$emit('click');
        }
      } else if (event.data.type === PING_RECORD) {
        sendMessageToExtention(PING_RECORD);
      }
    }
  }


  get classMode(): string {
    if (this.isRecordingStop) {
      return 'record';
    } else {
      return 'stop';
    }
  }


  get isEnabled(): boolean {
    const isSomethingToRecord = store.getters.getMainRecordSource !== SourceVideoType.NONE
        || !store.getters.isStickerMute || !store.getters.isStickerVideoMute;
    return this.enabled && isSomethingToRecord;
  }

  private beforeDestroy() {
    // destory the listener
    window.removeEventListener('message', this.onMessageReceive);
  }

}
</script>
<style scoped lang="scss">

.recordButtonLayout {
  width: 48px;
  height: 48px;
  position: relative;
  cursor: pointer;
  animation-delay: 0.5s;
  animation-duration: 0.2s;

  .recordButtonBorder {
    border: 2px solid var(--red);
    border-radius: 64px;
    padding: 4px;
    width: 100%;
    height: 100%;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 4px solid rgba(255, 56, 96, 0.42);
    }

    .recordButtonBody {
      background: var(--red);
      border-radius: 64px;
      height: 100%;
      width: 100%;
    }
  }

  &.stop {
    .recordButtonBorder {
      border-radius: 4px;
      padding: 6px;
      border: 2px solid rgba(255, 56, 96, 0.42);
      transition: border 0.5s ease-in-out;

      .recordButtonBody {
        background: var(--red);
        border-radius: 4px;
        height: 100%;
        width: 100%;

      }

      &:hover {
        .recordButtonBody {
          transition: all 0.2s ease-in-out;
          background: rgba(255, 56, 96, 0.8);
        }
      }

    }
  }

  &:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.02);
  }
}
</style>
