<template>
  <transition name="zoomDown">
    <div class="stickerContainer">
      <div class="sticker">
        <div class="stickerLayout">
          <VideoSource :filter="true" v-if="initialize" :sticker="true"
                       :type="sourceVideo.WEBCAM"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
  import Vue from 'vue';
  import {Component, Prop} from 'vue-property-decorator';
  import {FilterVideoType} from '@/enum/FilterVideoEnum';
  import {SourceVideoType} from '@/enum/SourceVideoEnum';
  import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
  import WButton from '@/components/wrapper/w-button.vue';
  import {RECORD_STICKER_MUTE, RECORD_STICKER_VIDEO_MUTE} from '@/store/recordingState/recordStateAction';
  import store from '@/store';


  @Component({
    components: {WButton, VideoSource},
    computed: {},
  })
  export default class StickerLayoutSource extends Vue {

    private mustShow: boolean = false;
    private initialize: boolean = false;

    public mounted() {
      if (store.getters.isStickerVideoMute) {
        store.dispatch(RECORD_STICKER_VIDEO_MUTE);
      }
      if (store.getters.isStickerMute) {
        store.dispatch(RECORD_STICKER_MUTE);
      }


      // waiting init webcam
      setTimeout(() => {
        this.initialize = true;
      }, 2000);
    }

    get filter(): any {
      return FilterVideoType;
    }


    get sourceVideo(): any {
      return SourceVideoType;
    }

  }
</script>
<style lang="scss">
  // Patch buefy 0.8.12 because the button is not round anymore
  .button.buttonRecorder .icon:first-child:not(:last-child) {
    margin-right: calc(-0.375em - 1px);
  }

  .button.is-rounded.buttonRecorder {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
</style>
<style lang="scss" scoped>
  @import '@/scss/shadows.scss';

  //commun
  .stickerContainer {
    position: absolute;
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 100% 100% 100% 0%;
    transition: all 0.5s;

    .sticker {
      min-width: 120px;
      min-height: 120px;
      max-width: 120px;
      max-height: 120px;
      overflow: hidden;
      transition: all 0.5s;
      border-radius: 100% 100% 100% 0%;

      .stickerLayout {
        margin-top: 16px;
        transform: scaleY(1.5) scaleX(-1.5);
        transition: all 0.5s;
      }
    }

    left: calc(50% - 91px);
  }


</style>
