<template>
  <Transition name="fade">
    <div class="containerSelectorSpeeds" v-show="!hideSelector">
      <div class="speedSelectorContainer" v-for="speed in getSpeedList" :key="'speedSelection'+speed">
        <w-tool-tip :label="computeWeetDuration(speed)" position="top">
          <div class="speedSelector" :class="speedMode===speed?'selected':''" @click.stop="selectSpeed(speed)">
            {{ speed }}x
          </div>
        </w-tool-tip>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {SPEED_SELECTION} from '@/utils/playerUtil';
import store from '@/store';
import {getWeetDuration} from '@/utils/weetUtil';
import prettyMilliseconds from 'pretty-ms';
import {Weet} from '@/store/weet/weetModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {CHANGE_PLAYER_SPEED} from '@/store/persistedInformation/persistAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WToolTip},
})
export default class WeetSpeedSelectorPlayer extends Vue {

  @Prop({required: true})
  private weet!: Weet;

  private hideSelector: boolean = false;

  get getSpeedList(): number[] {
    return SPEED_SELECTION;
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  get play(): boolean {
    return store.getters.isPlaying;
  }

  get duration(): number {
    if (this.weet) {
      return getWeetDuration(this.weet);
    } else {
      return 0;
    }
  }

  @Watch('play')
  private desactivateSelector(value: boolean) {
    if (value) {
      this.hideSelector = true;
    }
  }

  private computeWeetDuration(speed: number): string {
    return prettyMilliseconds(this.duration / speed, {colonNotation: true, secondsDecimalDigits: 0});
  }

  private selectSpeed(speed: number) {
    store.dispatch(CHANGE_PLAYER_SPEED, speed);
    setActionEvent(ActionEvent.player_control_speed, {
      player_speed: speed,
      weetID: this.weet.weetID
    });
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.containerSelectorSpeeds {
  background: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .shadow3;

  .speedSelector {
    font-size: 13px;
    color: var(--dark);
    min-width: 44px;
    padding: 7px 8px; // 7px +1 px border
    font-weight: 900;
    margin: 2px;
    line-height: 14px;
    border-radius: 12px;
    border: solid 1px transparent;


    &:hover {
      background: var(--light);
    }

    &.selected {
      background: var(--light);
      border-color: var(--light2);
    }

  }
}
</style>
