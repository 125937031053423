<template>
  <div class="commentContainer">
    <div class="commentPolicyLink" v-if="weet">
      <div class="commentPolicyLabelLink">
        {{ $t('commentPolicy.commentSwitch') }}
        <w-help :label="$t('commentPolicy.tooltip')" class="helpOffset"/>
      </div>
      <div>
        <w-switch size="small" :value="isCommentEnabled" @input="changeCommentPolicy"/>
      </div>
    </div>
    <div class="commentPolicyLink" v-if="weet">
      <div class="commentPolicyLabelLink">
        {{ $t('emotePolicy.emoteSwitch') }}
        <w-help :label="$t('emotePolicy.tooltip')" class="helpOffset"/>
      </div>
      <div>
        <w-switch size="small" :value="isEmote" @input="changeEmotePolicy"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import {Weet} from '@/store/weet/weetModel';
import {SEND_COMMENT_POLICY_WEET, SEND_EMOTE_POLICY_WEET} from '@/store/myWeet/myWeetAction';
import WSelect from '@/components/wrapper/w-select.vue';
import {CommentPolicy} from '@/store/weet/CommentPolicy';
import {inform} from '@/utils/dialog';
import WSwitch from '@/components/wrapper/w-switch.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WHelp from "@/components/wrapper/w-help.vue";
import {EmotePolicy} from "@/store/weet/EmotePolicy";

@Component({
  components: {
    WHelp,
    WSwitch,
    WSelect,
    WButton
  }
})
export default class ShareCommentPolicy extends Vue {
  @Prop()
  private weet!: Weet;
  @Prop({default: false})
  private expanded!: boolean;


  get isCommentEnabled(): boolean {
    return this.weet.commentPolicy === CommentPolicy.USER_CAN_COMMENT;
  }

  get isEmote(): boolean {
    return this.weet.emotePolicy === EmotePolicy.USER_CAN_REACT;
  }


  private changeCommentPolicy() {
    var value = this.isCommentEnabled ? CommentPolicy.NOBODY_CAN_COMMENT : CommentPolicy.USER_CAN_COMMENT;
    store.dispatch(SEND_COMMENT_POLICY_WEET,
        {weetID: this.weet.weetID, policy: value}).then(() => {
      inform(this.$t('commentPolicy.success').toString());
    });
    setActionEvent(ActionEvent.share_comment_policy, {
      commentPolicy: value,
      weetID: this.weet.weetID
    });
  }

  private changeEmotePolicy() {
    var value = this.isEmote ? EmotePolicy.NOBODY_CAN_REACT : EmotePolicy.USER_CAN_REACT;
    store.dispatch(SEND_EMOTE_POLICY_WEET,
        {weetID: this.weet.weetID, policy: value}).then(() => {
      inform(this.$t('emotePolicy.success').toString());
    });
    setActionEvent(ActionEvent.share_emote_policy, {
      emotePolicy: value,
      weetID: this.weet.weetID
    });
  }
}
</script>

<style scoped lang="scss">

.commentContainer {

  width: 100%;

  .commentPolicyLink {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    width: 100%;
    margin-bottom: 16px;

    .commentPolicyLabelLink {
      text-align: left;
      display: flex;
      flex: 1;

      .helpOffset {
        margin-top: 2px;
        margin-left: 8px;
      }
    }

    .chooseInput {
      margin-top: 8px;
    }

    &.expanded {
      width: 100%;
      display: block;

      .chooseInput {
        margin-top: 8px;
        margin-left: 0px;
      }
    }
  }
}


</style>
