export class AiavatarGenerationModel {
    public text:string;
    public voice:AvatarVoiceAvatar;
    public avatar:Avatar;
    public style:AvatarStyle;
    public backgroundUrl:string;
    constructor(text:string,
                avatar:Avatar,
                voice:AvatarVoiceAvatar,
                style:AvatarStyle,
                backgroundUrl:string) {
        this.text=text;
        this.voice=voice;
        this.style=style;
        this.avatar=avatar;
        this.backgroundUrl=backgroundUrl;
    }
}

export class Text2SpeachParams{
    text:string="";
    voice:AvatarVoiceAvatar=AvatarVoiceAvatar.FR_FR_VIVIENNE;
}

export class VoiceOverParams{
    audioToUpload:Blob;
    mediaID:string;
    constructor(
        audioToUpload:Blob,
        mediaID:string,
    ) {
        this.audioToUpload=audioToUpload;
        this.mediaID=mediaID;
    }
}

export enum AvatarVoiceAvatar{
    FR_FR_VIVIENNE="fr-FR-VivienneMultilingualNeural",
    US_AVIA='en-US-AvaMultilingualNeural',
    US_EMMA='en-US-EmmaMultilingualNeural',
    DE_SERPHINA= 'de-DE-SeraphinaMultilingualNeural',
    ZN_XIAO='zh-CN-XiaoxiaoMultilingualNeural',
    EN_US_JENNY="en-US-JennyMultilingualNeural",
    ES_ES_ISIDORA="es-ES-IsidoraMultilingualNeural",
    ES_ES_ARABELLA="es-ES-ArabellaMultilingualNeural",
    IT_IT_ISABELLA="it-IT-IsabellaMultilingualNeural",
    PT_BR_THALITA="pt-BR-ThalitaMultilingualNeural",
    ZH_CN_XIAOCHEN="zh-CN-XiaochenMultilingualNeural",
    EN_US_RYAN="en-US-RyanMultilingualNeural",
    EN_US_ANDREW="en-US-AndrewMultilingualNeural",
    EN_US_BRYAN="en-US-BrianMultilingualNeural",
    DE_DE_FLORIAN="de-DE-FlorianMultilingualNeural",
    FR_FR_REMY="fr-FR-RemyMultilingualNeural",
    IT_IT_MARCELLO="it-IT-MarcelloMultilingualNeural",
    IT_IT_ALESSIO="it-IT-AlessioMultilingualNeural",
    JP_JP_MASARU="ja-JP-MasaruMultilingualNeural",
    ZH_CN_YUNKI="zh-CN-YunyiMultilingualNeural"
}
export enum Avatar{
    LISA="lisa",
    HARRY="harry",
    JEFF="jeff",
    LORI="lori",
    MAX="max",
    MEG="meg"
}
export enum AvatarStyle{
    GRACEFUL_SITTING="graceful-sitting",
    CASUAL_SITTING="casual-sitting",
    GRACEFUL_STANDING="graceful-standing",
    TECHNICAL_SITTING="technical-sitting",
    TECHNICAL_STANDING="technical-standing",
    BUSINESS="business",
    CASUAL="casual",
    YOUTHFULL="youthful",
    FORMAL="formal",
    GRACEFUL="graceful"
}

export enum AvatarGender{
    MALE="MALE",
    FEMALE="FEMALE",
    UNKNOWN="UNKNOWN",
}

export enum AvatarBackground{
    OFFICE_1="OFFICE_1",
    OFFICE_2="OFFICE_2",
    OFFICE_3="OFFICE_3",
    OFFICE_4="OFFICE_4",
    OFFICE_5="OFFICE_5",
    OFFICE_6="OFFICE_6",
    OFFICE_7="OFFICE_7",
    OFFICE_8="OFFICE_8",
    OFFICE_9="OFFICE_9",
    OFFICE_10="OFFICE_10",
    OFFICE_11="OFFICE_11",
    OFFICE_12="OFFICE_12",
}
export const LIST_OF_AVATAR=[
    // Meg
    {avatar:Avatar.MEG,label:'Meg Formal',style:AvatarStyle.FORMAL,gender:AvatarGender.FEMALE, img:require('@/assets/AIAvatar/MEG-FORMAL.png')},
    {avatar:Avatar.MEG,label:'Meg business',style:AvatarStyle.BUSINESS, gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/MEG-BUSSINESS.png')},
    {avatar:Avatar.MEG,label:'Meg Casual',style:AvatarStyle.CASUAL,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/MEG-CASUAL.png')},
    // LORI
    {avatar:Avatar.LORI,label:'Lori graceful',style:AvatarStyle.GRACEFUL, gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LORI-GRACEFUL.png')},
    {avatar:Avatar.LORI,label:'Lori Casual',style:AvatarStyle.CASUAL, gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LORI-CASUAL.png')},
    {avatar:Avatar.LORI,label:'Lori Formal',style:AvatarStyle.FORMAL,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LORI-FORMAL.png')},

    // LISA
    {avatar:Avatar.LISA,label:'Lisa Formal',style:AvatarStyle.GRACEFUL_SITTING, gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LISA_GRACEFUL_SITTING.png')},
    {avatar:Avatar.LISA,label:'Lisa Casual',style:AvatarStyle.CASUAL_SITTING,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LISA_CASUAL_SITTING.png')},
    {avatar:Avatar.LISA,label:'Lisa Formal Standing',style:AvatarStyle.GRACEFUL_STANDING,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LISA_GRACEFUL_STANDING.png')},
    {avatar:Avatar.LISA,label:'Lisa Modern',style:AvatarStyle.TECHNICAL_SITTING,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LISA_TECHNICAL_SITTING.png')},
    {avatar:Avatar.LISA,label:'Lisa Modern Standing',style:AvatarStyle.TECHNICAL_STANDING,gender:AvatarGender.FEMALE,
        img:require('@/assets/AIAvatar/LISA_TECHNICAL_STANDING.png')},
    // HARRY
    {avatar:Avatar.HARRY,label:'Harry business',style:AvatarStyle.BUSINESS,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/HARRY-BUSINESS.png')},
    {avatar:Avatar.HARRY,label:'Harry casual',style:AvatarStyle.CASUAL,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/HARRY-CASUAL.png')},
    {avatar:Avatar.HARRY,label:'Harry youthful',style:AvatarStyle.YOUTHFULL,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/HARRY-YOUTHFUL.png')},

    // JEFF
    {avatar:Avatar.JEFF,label:'Jeff business',style:AvatarStyle.BUSINESS,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/JEFF-BUSINESS.png')},
    {avatar:Avatar.JEFF,label:'Jeff formal',style:AvatarStyle.FORMAL,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/JEFF-FORMAL.png')},


    // MAX
    {avatar:Avatar.MAX,label:'Max business',style:AvatarStyle.BUSINESS, gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/MAX-BUSINESS.png')},
    {avatar:Avatar.MAX,label:'Max Casual',style:AvatarStyle.CASUAL,gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/MAX-CASUAL.png')},
    {avatar:Avatar.MAX,label:'Max Formal',style:AvatarStyle.FORMAL, gender:AvatarGender.MALE,
        img:require('@/assets/AIAvatar/MAX-FORMAL.png')},


]

export const LIST_OF_BACKGROUND=[
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office1.jpeg",
        label:"Office 1",
    background:AvatarBackground.OFFICE_1},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office2.jpeg",
        label:"Office 2",
        background:AvatarBackground.OFFICE_2},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office3.jpeg",
        label:"Office 3",
        background:AvatarBackground.OFFICE_3},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office4.jpeg",
        label:"Office 4",
        background:AvatarBackground.OFFICE_4},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office5.jpeg",
        label:"Office 5",
        background:AvatarBackground.OFFICE_5},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office6.jpeg",
        label:"Office 6",
        background:AvatarBackground.OFFICE_6},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office7.jpeg",
        label:"Office 7",
        background:AvatarBackground.OFFICE_7},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office8.jpeg",
        label:"Office 8",
        background:AvatarBackground.OFFICE_8},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office9.jpeg",
        label:"Office 9",
        background:AvatarBackground.OFFICE_9},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office10.jpeg",
        label:"Office 10",
        background:AvatarBackground.OFFICE_10},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office11.jpeg",
        label:"Office 11",
        background:AvatarBackground.OFFICE_11},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office12.jpeg",
        label:"Office 12",
        background:AvatarBackground.OFFICE_12},
]


export const getLabelForAvatar=(avatar:Avatar,style:AvatarStyle)=>{
    const avatarFound= LIST_OF_AVATAR.find((a)=>{
        return a.avatar===avatar && a.style===style
    })
    return avatarFound?.label;
}
