export class Subtitle {

    public id: string = '';

    public label: string = '';
    public lang: string = "en_US";
    public url: string = "";

    public subtitleID: string = "";

    public content: string = "";
    public updateDate: string = "";
    public added = Date;

}

export class SubtitleAudioParams{
    audioToUpload:Blob;
    weetID:string;
    lang:string;
    constructor(
        audioToUpload:Blob,
        weetID:string,
        lang:string
    ) {
        this.audioToUpload=audioToUpload;
        this.lang=lang;
        this.weetID=weetID;
    }
}
