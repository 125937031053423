<template>
  <b-autocomplete
      :data="myWeetLoaded"
      :placeholder="$t('autoComplete.placeholder')"
      size="is-default"
      class="weetSearch"
      field="title"
      v-model="currentValue"
      :loading="loading"
      @typing="debouceData"
      icon="magnify"
      @select="selectWeet">

    <template slot-scope="props">
      <div class="weetAutoComplete">
        <div class="weetAutoCompleteLeft">
          <img class="image" alt="thumbnail" :src="getImageForWeet(props.option)">
        </div>
        <div class="weetAutoCompleteRight">
          <div class="topLine">
            <div class="titleBox">
              {{ props.option.title }}
            </div>
            <div class="avatarBox">
              <UserAvatar class="avatarUserSticker" size="tiny" type="sticker"
                          v-for="(user,index) in props.option.owners"
                          :user-avatar="getImageUser(user.user)"
                          :key="'searchWeetAvatar'+user.userID+index"
                          :user-firstname="user.user.firstName"
                          :tooltip-position="'left'"/>
            </div>
          </div>
          <div class="statView">
            <div class="date">
              {{ $t('autoComplete.createdAt', { time: getTimeForWeet(props.option) }) }},
            </div>
            <div class="numberView"> {{ props.option.views }}</div>
            <w-icon icon="eye-outline" color="light1"/>
            <div class="numberView"> {{ props.option.viewers }}</div>
            <w-icon icon="account-circle-outline" color="light1"/>
          </div>
        </div>
      </div>
    </template>
  </b-autocomplete>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import debounce from 'lodash/debounce';
import {MyWeetListParams, SEARCH_MY_WEET} from '@/store/myWeet/myWeetAction';
import store from '@/store';
import router from '@/router';
import slugify from '@sindresorhus/slugify';
import {getDateAgo, getThumbnailURL, getUserAvatar} from '@/utils/util';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {getMediasForWeet} from '@/utils/weetUtil';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {User} from '@/store/user/userModel';

@Component({
  components: {UserAvatar, WIcon, WToolTip},
})
export default class SearchWeetAutoComplete extends Vue {
  private loading: boolean = false;
  private currentValue: string = '';
  private debouceData = debounce((name) => {
    this.loadData(name);
  }, 250);

  private myWeetLoaded: Weet[] = [];

  public getTimeForWeet(weet: Weet) {
    return getDateAgo(weet.created);
  }

  public getTimeEvent(weet: Weet): any {
    // we search the event with 0
    if (weet.timeLine.length > 0) {
      return weet.timeLine[0];
    } else {
      return undefined;
    }
  }

  public getImageUser(user: User) {
    return getUserAvatar(user, 48);
  }

  public getImageForWeet(weet: Weet) {
    const timeEventMedia = this.getTimeEvent(weet);
    if (timeEventMedia === undefined) {
      return '';
    }
    let mediaID = timeEventMedia.mainMedia.mediaID;

    if (mediaID === '') {
      mediaID = timeEventMedia.secondMedia.mediaID;
    }

    const mediaFound = getMediasForWeet(weet, mediaID);
    if (mediaFound !== undefined) {
      return getThumbnailURL(mediaFound?.jobID);
    } else {
      return '';
    }
  }


  public selectWeet(weet) {
    setActionEvent(ActionEvent.filter_my_weet_direct_access);
    if (weet) {
      router.push({
        name: 'PreviewWeet',
        params: {weetID: weet.weetID, title: slugify(weet.title)},
      });
      // after selection we erase the value
      setTimeout(() => {
        this.currentValue = '';
      }, 100);
    }
  }

  public loadData(name) {
    this.loading = true;
    const params = new MyWeetListParams();
    params.titleFilter = name;
    store.dispatch(SEARCH_MY_WEET, params).then((data) => {
      setActionEvent(ActionEvent.filter_my_weet);
      this.loading = false;
      this.myWeetLoaded = data;
    }).catch();
  }

}

</script>

<style lang="scss">
@import "@/scss/gridBreakPoint.scss";

.weetSearch {
  max-width: 600px;

  &.autocomplete {
    margin: auto;

    .dropdown-menu {
      min-width: 500px;
      border-radius: 16px;

      .dropdown-item {
        padding-right: 16px;
      }
    }

    @include GRID1X {
      min-width: 250px;
    }

    .control.has-icons-left {
      .icon {
        z-index: inherit;
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";

.weetAutoComplete {
  display: flex;
  color: var(--black);
  font-size: 14px;
  text-transform: none;
  width: 100%;

  .weetAutoCompleteLeft {
    margin-right: 16px;
    min-width: 88px;

    .image {
      max-height: 48px;
      border-radius: 4px;
      @extend .shadow1;
    }
  }

  .weetAutoCompleteRight {
    flex: 1;

    .topLine {

      display: flex;
      width: 100%;

      .titleBox {
        font-weight: 900;
        letter-spacing: 0.3px;
        flex: 1;
      }

      .avatarBox {
        display: flex;

        .avatarUserSticker {
          margin-left: -8px;
        }
      }
    }

    .statView {
      text-align: right;
      align-items: center;
      display: flex;
      font-size: 14px;
      color: var(--dark);
      font-weight: 600;

      .date {
        color: var(--light2);
      }

      .numberView {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}

</style>
