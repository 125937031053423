import axios from 'axios';
import {SUBTITLE_VOICE_OVER_MEDIA, SUBTITLES_MANAGEMENT_AUTHORISATION} from "@/store/subtitles/SubtitlesAction";
import {BACK_ENDPOINT} from "@/store/conf/api";
import jwt from "jsonwebtoken";
import {SubtitleAudioParams} from "@/store/subtitles/SubtitleModel";
import {debug} from "util";
import {log} from "@/utils/log";

const subtitlesAPI = BACK_ENDPOINT + '/subtitles';
export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        async [SUBTITLES_MANAGEMENT_AUTHORISATION]({commit, dispatch}: { commit: any, dispatch: any }, {weetID,callback}:{weetID:string,callback:string}) {
            return await axios({
                url: subtitlesAPI + '/auth/' + weetID,
                params:{callback:callback},
                method: 'GET',
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SUBTITLE_VOICE_OVER_MEDIA]({commit,dispatch}:{ commit: any, dispatch: any },subtitleAudioParams:SubtitleAudioParams){
            const formData = new FormData();
            formData.append('audio', subtitleAudioParams.audioToUpload);
            formData.append('lang',subtitleAudioParams.lang);

            return await axios({
                method: 'POST',
                url: subtitlesAPI + '/'+subtitleAudioParams.weetID+'/audio' ,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
            }).then((res) => {
                log.debug("Subtitle save for "+subtitleAudioParams.lang)
                return res.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        }
    },
};
