<template>
  <div class="containerCommentText" style="animation-duration: 0.2s"
  >
    <div class="backgroundSelectedContainer" :class="cssClass" @click="changeTimePlayer()"
         v-on="!isMobile?{
        mouseover:  onMouseOver,
         mouseleave:onMouseLeave
    }:null">

      <!-- TODO The feature emote will arrive next Time, remoe true to try -->
      <div v-if="!isEmoteComment || true ">
        <!--  TIME TIMELINE -->
        <div class="timeBox" v-if="reply">
          {{ timeLabel }}
        </div>
        <!-- USER AVATAR -->
        <div class="avatarBox">
          <UserAvatar :size="reply?'default':'small'" type="sticker" :user-avatar="imageForUser" class="userAvatar"/>
          <div class="avatarTopLine">
            <div class="avatarName">
              {{ nameOfUser }}
            </div>
            <div class="avatarTime">
              <div class="dotClass">{{ $t('generic.dot2') }}</div>
              <w-tool-tip :position="'top'" :label="dateTimeToolTip">
                <div class="date">
                  {{ dateTime }}
                </div>
              </w-tool-tip>
            </div>
          </div>
          <!-- PASTILLE NOTIFY -->
          <Transition name="zoom">
            <div class="pastille" v-show="isNotify"></div>
          </Transition>
        </div>


        <!-- MAIN COMMENT -->
        <div class="contentBox"
        >
          <!-- TEXT COMMENT -->
          <div class="textComment" v-html="textOfComment" data-hj-suppress>
          </div>
          <!-- EMOTE COMMENT-->
          <div class="emoteComment" v-if="isEmoteComment">
            <div class="emoteComment">
              <!-- url of comment -->
              <img alt="emote" class="emoteImage" :src="comment.imageUrl">
              <!-- image tampon -->
              <img alt="emote" v-for="(commentRepeat,index ) in comment.repeatsComment" class="emoteImage"
                   :src="commentRepeat.imageUrl" :key="'emote'+index">
            </div>
          </div>
          <!-- COMMENT SCREEN -->
          <div class="screenComment" v-if="isScreenComment">
            <div class="screenVideoLayout" v-if="comment.media.status!=='WAITING'">
              <CommentScreenPlayer :comment="comment"/>
            </div>
            <div v-else>{{ $t('commentPanelView.commentView.waiting') }}</div>
          </div>
          <!-- COMMENT WEBCAM -->
          <div class="webcamComment" v-if="isWebcamComment">
            <div class="webcamVideoLayout" v-if="comment.media.status!=='WAITING'">
              <CommentStickerPlayer :comment="comment"/>
            </div>
            <div v-else>{{ $t('commentPanelView.commentView.waiting') }}</div>
          </div>

          <!-- BUTTON LINE -->
          <div class="buttonsContainer" :style="isMobile?'display:flex':''">
            <w-button style="animation-duration: 0.2s" class="iconReply"
                      v-if="canComment && !isEmoteComment"
                      v-show="isMouseOver || isMobile "
                      size="small" color="lighttext" @click.stop="openReplyPanel">
              {{ $t('commentPanelView.commentView.reply') }}
            </w-button>
            <div class="spacer">

            </div>
            <w-button style="animation-duration: 0.2s" class="iconPlay" icon="rewind-10"
                      tooltip-position="left" :class="comment.numberOfReplies===0?'iconPlayReply':''"
                      :tooltip="$t('commentPanelView.commentView.tooltip.play')"
                      size="small" color="text" @click.stop="changeTimePlayer(TIME_PREVIOUS)"
                      @dblclick="changeTimePlayer(DOUBLE_PREVIOUS)">
            </w-button>

            <!--      Delete button-->
            <w-button @click.stop="deleteComment" v-if="canEdit && !isEmoteComment"
                      class="iconDelete"
                      icon="delete-outline"
                      size="small" color="text"
            />
          </div>
        </div>
      </div>
      <!-- COMMENT EMOTE (NOT USE FOR THE MOMENT)-->
      <div v-else class="emotePanel">
        <div class="timeBox">
          {{ timeLabel }}
        </div>
        <!-- USER AVATAR -->
        <div class="avatarBox">
          <UserAvatar size="small" type="sticker" :user-avatar="imageForUser" class="userAvatar"/>
          <!-- PASTILLE NOTIFY -->
          <Transition name="zoom">
            <div class="pastille" v-show="isNotify"></div>
          </Transition>
        </div>

        <!--        <div class="emoteComment" >-->
        <!--          <img alt="emote" v-for="index in comment.repeat+1" class="emoteImage" :src="comment.imageUrl">-->
        <!--        </div>-->
        <div class="emoteComment">
          <!-- of comment -->
          <img alt="emote" class="emoteImage" :src="comment.imageUrl">
          <!-- image tampon -->
          <img alt="emote" v-for="(url,index) in comment.repeats" class="emoteImage" :src="url" :key="'emoteUrl'+index">
        </div>
        <div class="fakeLine"></div>
      </div>
    </div>

    <!-- REPLY EDITOR -->
    <div class="replyEditor" v-if="!isEmoteComment && isCommentEnabled">
      <SlideUpDown :active="mustOpenReply" :duration="500">
        <CommentPanelCreator v-if="mustOpenReply" style="animation-duration: 0.1s" class="" :weet="weet"
                             :commentIDToReply="comment.replyToCommentID?comment.replyToCommentID:comment.commentID"
                             :placeholder="initReplyValue"/>
      </SlideUpDown>
    </div>


    <div class="repliesPanel" v-if="comment.numberOfReplies>0 && showReply && !isEmoteComment">

      <div v-for="(reply,index) in comment.replies" class="replyView" :key="reply.commentID">
        <CommentViewComponent :ref="reply.commentID" style="animation-duration: 0.5s" :reply="false"
                              :comment="reply" :weet="weet"
        />
      </div>

      <div class="plusComment" v-if="comment.numberOfReplies>comment.replies.length">
        <w-button color="light" size="small" icon="plus"
                  @click.stop="moreReplies">{{ $t('generic.more') }}
        </w-button>
      </div>
    </div>


  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import {CommentEvent} from '@/store/comment/commentModel';
import UserAvatar from '@/components/user/UserAvatar.vue';
import SlideUpDown from 'vue-slide-up-down';
import {getDateAgo, getLocalTime, getUserAvatar, getVideoURLForStatus, htmliFy} from '@/utils/util';
import prettyMilliseconds from 'pretty-ms';
import {PLAYER_PLAYING, SEEK_PLAYER} from '@/store/timeLine/timeLineAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {
  COMMENT_TO_REPLY,
  DELETE_COMMENT,
  GetRepliesParams,
  LIST_REPLIES,
  OPEN_PANEL_REPLY,
} from '@/store/comment/commentAction';
import WButton from '@/components/wrapper/w-button.vue';
import {Weet} from '@/store/weet/weetModel';
import {canCommentThisWeet, canEditThisWeet} from '@/utils/weetUtil';
import mobileDetect from 'mobile-device-detect';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {READ_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';
import CommentScreenPlayer from '@/components/play/comment/CommentScreenPlayer.vue';
import CommentStickerPlayer from '@/components/play/comment/CommentStickerPlayer.vue';
import CommentPanelCreator from '@/components/comment/CommentPanelCreator.vue';
import DelayAnim from '@/components/layout/DelayAnim.vue';
import {CommentPolicy} from '@/store/weet/CommentPolicy';


@Component({
  name: 'CommentViewComponent',
  components: {
    DelayAnim,
    CommentPanelCreator,
    SlideUpDown,
    CommentStickerPlayer,
    CommentScreenPlayer,
    WToolTip,
    WIcon,
    WButton,
    UserAvatar
  },
})
export default class CommentView extends Vue {
  @Prop()
  private comment!: CommentEvent;
  @Prop()
  private weet!: Weet;
  @Prop({default: true})
  private reply!: boolean;

  @Prop({default: true})
  private showReply!: boolean;
  @Prop({default: true})
  private showPlay!: boolean;

  private timeOutRef: any = 0;
  private TIME_PREVIOUS = 10000;
  private DOUBLE_PREVIOUS = 20000;

  private pageReplies: number = 1;


  private isMobile: boolean = mobileDetect.isMobile;

  private isMouseOver: boolean = false;

  get cssClass() {
    if (this.reply && this.commentToReply === this.comment.commentID) {
      return 'main selected';
    } else if (this.reply) {
      return 'main';
    } else {
      if (this.mustOpenReply) {
        return 'reply selected';
      } else {
        return 'reply';
      }
    }

  }

  public readNotification() {
    store.dispatch(READ_NOTIFICATIONS, this.notifications);
  }


  public onMouseOver() {
    // if the mouse is over
    if (this.isNotify) {
      this.readNotification();
    }
    clearTimeout(this.timeOutRef);
    this.isMouseOver = true;
  }

  public onMouseLeave() {
    setTimeout(() => {
      this.isMouseOver = false;
    }, 500);
  }


  get initReplyValue(): string {
    if (this.comment.user) {
      return '@' + this.comment.user.firstName + ': ';
    } else {
      return '';
    }
  }

  get isCommentEnabled(): boolean {
    return this.weet.commentPolicy !== CommentPolicy.NOBODY_CAN_COMMENT;
  }

  get needToReplyTo(): string {
    if (this.comment.replyToCommentID) {
      return this.comment.replyToCommentID;
    } else {
      return this.comment.commentID;
    }
  }

  get commentToReply(): string {
    return store.getters.getCommentIDToReply;
  }


  get mustOpenReply(): boolean {
    return store.getters.getReplyPanelOpenID === this.comment.commentID;
  }

  public openReplyPanel() {
    this.changeTimePlayer(0);
    store.dispatch(COMMENT_TO_REPLY, this.needToReplyTo);
    store.dispatch(OPEN_PANEL_REPLY, this.comment.commentID);
  }

  get isAutenticated(): boolean {
    return store.getters.isAuthenticated;
  }

  get isEmoteComment(): boolean {
    return this.comment.type === CommentTypeEnum.EMOTE;
  }

  get isScreenComment(): boolean {
    return this.comment.type === CommentTypeEnum.SCREEN;
  }

  get isWebcamComment(): boolean {
    return this.comment.type === CommentTypeEnum.WEBCAM;
  }

  get videoURL(): string {
    if (this.comment.media) {
      return getVideoURLForStatus(this.comment.media);
    } else {
      return '';
    }
  }

  get isNotify(): boolean {
    return this.notifications.length > 0;
  }

  get notifications(): Notification[] {
    const notifications: Notification[] = [];
    for (const notification of store.getters.getUnreadNotification) {
      if (notification.commentID === this.comment.commentID) {
        notifications.push(notification);
      }
      if (this.comment.repeatsComment && this.comment.repeatsComment.length > 0) {
        for (const commentRepeat of this.comment.repeatsComment) {
          if (notification.commentID === commentRepeat.commentID) {
            notifications.push(notification);
          }
        }
      }
    }
    return notifications;
  }

  get textOfComment(): string {
    if (this.comment.text === null) {
      return '';
    } else {

      return htmliFy(this.comment.text);
    }
  }

  get canEdit(): boolean {
    if (this.comment.user?.userID === store.getters.userConnected.userID) {
      return true;
    } else {
      return canEditThisWeet(this.weet, store.getters.userConnected);
    }
  }

  get canComment(): boolean {
    if (!store.getters.isAuthenticated) {
      return true;
    } else {
      return canCommentThisWeet(this.weet, store.getters.userConnected);
    }
  }


  get dateTimeToolTip(): string {
    return getLocalTime(this.comment.created);
  }

  get dateTime(): string {
    return getDateAgo(this.comment.created);
  }

  public changeTimePlayer(offset: number = 0) {

    let time = this.comment.time;
    time = time - offset;
    if (time < 0) {
      time = 0;
      setActionEvent(ActionEvent.comment_view_previous_seek);
    } else {
      setActionEvent(ActionEvent.comment_view_seek);
    }


    store.dispatch(SEEK_PLAYER, time).then(() => {
      if (offset > 0) {
        store.dispatch(PLAYER_PLAYING, true);
      } else {
        store.dispatch(PLAYER_PLAYING, false);
      }
      if (this.comment.replyToCommentID) {
        store.dispatch(COMMENT_TO_REPLY, this.comment.replyToCommentID);
      } else {
        store.dispatch(COMMENT_TO_REPLY, this.comment.commentID);
      }
    });

  }


  public moreReplies() {
    const params = new GetRepliesParams(
        this.weet.weetID,
        this.comment.commentID,
        this.pageReplies
    );
    store.dispatch(LIST_REPLIES, params).then(() => {
      this.pageReplies++;
    });
  }


  get imageForUser(): string {
    return getUserAvatar(this.comment.user, 100);
  }

  get nameOfUser(): string {
    if (this.comment.user === null) {
      return this.$t('generic.anonymous').toString();
    } else {
      return this.comment.user.firstName;
    }
  }

  get mediaUrl(): string {
    if (this.comment.media !== null) {
      if (this.comment.media.status !== 'WAITING') {
        return getVideoURLForStatus(this.comment.media);
      }
    }
    return '';
  }


  get timeLabel(): string {
    if (this.comment.time <= 999) {
      return '0:00';
    }
    return prettyMilliseconds(this.comment.time, {
      colonNotation: true,
      secondsDecimalDigits: 0,
    });
  }


  public deleteComment() {
    this.$buefy.dialog.confirm({
      title: 'Deleting comment',
      message: this.$t('comment.view.delete.description').toString(),
      confirmText: this.$t('comment.view.delete.title').toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        const params = {
          weetID: this.weet.weetID, commentID: this.comment.commentID,
          replyTo: this.comment.replyToCommentID
        };
        store.dispatch(DELETE_COMMENT, params);
        setActionEvent(ActionEvent.comment_delete);
      },
    });
  }


}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gridBreakPoint.scss";

.containerCommentText {
  position: relative;
  transition: transform 0.2s ease-in-out;

  .backgroundSelectedContainer {
    padding-top: 8px;
    padding-bottom: 0px;
    cursor: pointer;
    transition: all 0.5s ease;
    background: rgba(255, 255, 255, 0);

    &:hover {
      background: var(--light);

      .buttonsContainer {
        opacity: 1;
      }
    }

    &.main {

      padding-left: 12px;
      border-radius: 8px;
      border-left: solid 4px rgba(255, 255, 255, 0);
      padding-right: 8px;

      .contentBox {
        border-left: solid 2px var(--light1);
        margin-left: 22px;
        margin-top: 0px;
      }

      &:hover {
        border-left: solid 4px var(--light1);
        background: var(--light);
      }

      &.selected {
        background: var(--light);
        border-left: solid 4px var(--primary);
      }

      @include GRID1X {
        padding-left: 4px;
      }

    }

    &.reply {
      margin-left: 38px;
      padding-left: 8px;
      padding-right: 12px;
      border-left: solid 2px var(--light1);
      border-radius: 0px 8px 8px 0px;

      .contentBox {
        margin-left: 12px;
        margin-top: 0px;
      }

      @include GRID1X {
        margin-left: 30px;
      }

      &.selected {
        //background: var(--light);
        border-radius: 0px 8px 0px 0px;
        background: var(--light0);

        .avatarBox {
          .userAvatar {

          }

          .avatarName {
            //color: var(--black);
          }
        }
      }
    }

  }

  .pastille {
    top: 0px;
    left: 0px;
    animation-duration: 1s;
    animation-delay: 1s;
    position: absolute;
    width: 12px;
    height: 12px;
    border: solid 2px #FFF;
    border-radius: 12px;
    background: var(--primary);
    @extend .shadow1;
  }

  .replyEditor {
    margin-left: 38px;
    padding-left: 0px;
    padding-bottom: 2px;
    min-width: 340px;
    border-left: solid 2px var(--light1);
    animation-duration: 0.5s;
    @include GRID1X {
      min-width: 240px;
      margin-left: 30px;
    }
  }

  .timeBox {
    position: absolute;
    border: solid 1px var(--light2);
    background: white;
    color: var(--light2);
    border-radius: 50px;
    font-size: 12px;
    top: 60px;
    left: 16px;
    width: 48px;
    @include GRID1X {
      left: 8px;
    }
  }

  .avatarBox {
    display: flex;
    align-items: flex-start;
    width: 100%;
    position: relative;
    height: 8px;

    .avatarTopLine {
      display: flex;
      align-items: center;

      .avatarName {
        text-align: left;
        margin-left: 8px;
        color: var(--black);
        font-weight: 700;
        font-size: 14px;
      }


      .avatarTime {
        margin-left: 4px;
        text-align: left;
        font-size: 14px;
        color: var(--light2);
        display: flex;

        .dotClass {
          margin-right: 4px;
        }
      }
    }
  }

  .contentBox {
    padding-left: 32px;
    padding-bottom: 8px;

    .textComment {
      font-size: 14px;
      margin-top: 16px;
      color: var(--black);
      max-width: 600px;
      font-weight: 600;
      text-align: left;
      word-break: break-word;
    }

    .emoteComment {
      text-align: left;
      padding-left: 12px;
      margin-top: 24px;

      .emoteImage {
        text-align: left;
        display: inline-block;
        margin-left: -12px;
        width: 24px;
      }
    }

    .screenComment {
      text-align: left;
      margin-bottom: 8px;

      .screenVideoLayout {
        min-height: 160px;
        height: 160px;
        margin-top: 24px;
        max-height: 160px;
        max-width: 294px;

        @extend .shadow3;
        border-radius: 8px;
        overflow: hidden;
      }
    }

    .webcamComment {
      text-align: left;
      // margin-bottom: -16px;

      .webcamVideoLayout {
        margin-top: 24px;
        border-radius: 8px;
        min-height: 140px;
        max-width: 140px;
        height: 140px;
        max-height: 140px;
        position: relative;
      }
    }

    .bottomBar {
      font-size: 12px;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    height: 32px;
    opacity: 0;
    transition: all 0.3s ease-in;

    .spacer {
      flex: 1;
    }

    .iconPlay {
      //position: absolute;
      // bottom: 4px;
      //  right: 8px;
      &.iconPlayReply {
        margin-right: -4px;
      }
    }

    .iconDelete {
      position: absolute;
      top: 4px;
      right: 8px;
    }

  }


  .plusComment {
    margin: 8px;
  }


  .emotePanel {
    min-height: 60px;

    .timeBox {
      top: 42px;
      margin-left: 0px;
      font-size: 12px;
      border: none;
      background: none;
    }

    .avatarBox {
      z-index: 1;
      margin-left: 8px;
    }

    .fakeLine {
      position: absolute;
      bottom: -4px;
      height: 12px;
      left: 38px;
      border: solid 1px var(--light1);
    }

    .emoteComment {
      position: absolute;
      text-align: left;
      top: 20px;
      width: 100%;
      left: 58px;

      .emoteImage {
        position: absolute;
        text-align: left;
        display: inline-block;
        margin-left: -8px;
        margin-top: -16px;
        width: 24px;
        z-index: 0;

        &:nth-child(1) {
          left: -4px;
          top: -8px;
        }

        &:nth-child(2) {
          left: 4px;
          z-index: 2;
          top: 16px;
        }

        &:nth-child(3) {
          left: -32px;
          top: -8px;
        }

        &:nth-child(4) {
          margin-left: -48px;
          margin-top: -8px;
        }

        &:nth-child(5) {
          margin-left: -48px;
          margin-top: 4px;
          z-index: 2;
        }

      }
    }
  }
}
</style>
