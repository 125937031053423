<template>
  <div class="fullContainer">
    <div class="container" :style="backgroundStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import {Component, Prop, Vue} from 'vue-property-decorator';

  @Component(
    {
      name: 'CenterCenterPanel',
    })
  export default class CenterCenter extends Vue {
    @Prop() private logo!: string;

    get backgroundStyle(): string {
      if (this.logo !== undefined) {
        return `background-image:url(${this.logo})`;
      } else {
        return '';
      }
    }
  }
</script>

<style scoped lang="scss">
@import "@/scss/gridBreakPoint.scss";
  .fullContainer {
    height: 100%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    min-height: 100%;
    width: 100%;
    background: 0% 60vh;
    background-repeat: no-repeat;
    background-size: 50%;
    @include GRID1X{
      padding: 0;
    }
  }
</style>
