import { render, staticRenderFns } from "./ChannelWorkspaceAddDropDown.vue?vue&type=template&id=329048be&scoped=true"
import script from "./ChannelWorkspaceAddDropDown.vue?vue&type=script&lang=ts"
export * from "./ChannelWorkspaceAddDropDown.vue?vue&type=script&lang=ts"
import style0 from "./ChannelWorkspaceAddDropDown.vue?vue&type=style&index=0&id=329048be&prod&lang=scss"
import style1 from "./ChannelWorkspaceAddDropDown.vue?vue&type=style&index=1&id=329048be&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329048be",
  null
  
)

export default component.exports