<template>
  <div>
    <transition name="bounceUp">
      <div class="sticker" @click="onEndedVideo" :style="'background-image: url('+stickerBackground+')'">
        <div class="stickerLayout" v-show="mustShow">
          <!-- Mode creation -->
          <VideoSource style="min-height:120px;" v-if="!readonly" :filter="true" :sticker="true"
                       :mediaCreatorMode="true"
                       :type="sourceVideo.WEBCAM"
                       :weetID="weetIDToComment"/>

          <!-- mode player (readOnly) -->
          <div v-else>
            <video ref="videoElement" playsinline class="videoElement" :class="comment.filter"
                   :src="mediaUrl" autoplay @ended="onEndedVideo"
            >
            </video>
            <div class="mask"></div>
            <div class="layer1"></div>
            <div class="layer2"></div>
            <div class="layer3"></div>
            <div class="layer4"></div>
            <div class="layer5"></div>
          </div>
        </div>
      </div>
    </transition>
    <!--  DESACTIVATE FILTER  (WAITING FOR STICKER PLAYER -->
    <div class="buttonSticker" v-show="!readonly">
      <transition-group name="zoom">
        <StickerFilterButton :toolTipPosition="'left'" :key="'button2'" :modal="true"
                             v-show="mustShow && isStartRecordingAivailable && !isWebcamVideoMute"
                             class="button2"
                             style="animation-duration: 0.3s;animation-delay: 0.4s;"/>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import StickerMicMuteButton from '@/components/recorder/sticker/buttons/StickerMicMuteButton.vue';
import StickerVideoMuteButton from '@/components/recorder/sticker/buttons/StickerVideoMuteButton.vue';
import StickerFilterButton from '@/components/recorder/sticker/buttons/StickerFilterButton.vue';
import {getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {CommentEvent} from '@/store/comment/commentModel';


@Component({
  components: {
    StickerFilterButton,
    StickerVideoMuteButton,
    StickerMicMuteButton, WButton, VideoSource,
  },
  computed: {},
})
export default class StickerCommentSource extends Vue {
  @Prop({default: false})
  private readonly!: boolean;

  @Prop()
  private comment!: CommentEvent;

  @Prop({default: ''})
  private weetIDToComment!: string;

  private mustShow: boolean = false;


  get isWebcamVideoMute() {
    return store.getters.isStickerVideoMute;
  }


  get stickerBackground(): string {
    if (this.comment && this.comment.media && this.comment.media.creator) {
      return getUserAvatar(this.comment.media.creator, 512);
    }
    // default avatar
    return getUserAvatar(store.getters.userConnected, 512);
  }

  get stickerCurrentMediaID(): string {
    return store.getters.getStickersMediaId;
  }


  get sourceVideo(): any {
    return SourceVideoType;
  }

  get isStartRecordingAivailable() {
    return store.getters.isStartRecordingAivailable;
  }


  get webcamAivailable() {
    return store.getters.isWebcamAivalable;
  }

  get mediaUrl(): string {
    if (this.comment !== null && this.comment.media !== null) {
      return getVideoURLForStatus(this.comment.media);
    }
    return '';
  }

  public onEndedVideo() {
    this.$emit('ended');
  }

  public mounted() {
    if (this.readonly) {
      this.mustShow = true;
    } else {
      setTimeout(() => {
        this.mustShow = true;
      }, 1000);
    }
  }


}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';

//commun

.sticker {
  bottom: 48px;
  right: 48px;
  transition: all 0.5s;
  cursor: pointer;
  overflow: hidden;
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 100% 100% 100% 16%;
  //fix issue in safari border-radius and overflow
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background: black;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  .stickerLayout {
    transform: scaleX(-1) scaleY(1);
    transition: all 0.5s;
    min-height: 120px;

    .videoContainer {
      position: absolute;
      object-fit: cover;
      min-height: 120px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

  }
}

.buttonSticker {
  position: absolute;
  margin-left: 8px;
  width: 32px;
  height: 200px;
  left: -4px;

  .button2 {
    position: absolute;
    top: -108px;
  }

}


</style>
