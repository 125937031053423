import delay from 'delay';

const offScreenCanvases = {};
const transformsCanvas = {};
export const CANVAS_NAMES = {
    blurred: 'blurred',
    blurredMask: 'blurred-mask',
    mask: 'mask',
    lowresPartMask: 'lowres-part-mask',
    virtualBackground: 'virtualBackground',
    capture_tabs: 'capture_tabs',
    segmentation: 'segmentation',
    staticBackground: 'staticBackground'
};

/**
 * get Canvas and create canvas if not exist
 */
const ensureOffscreenCanvasCreated = (canvasName: string): HTMLCanvasElement | OffscreenCanvas => {
    if (!offScreenCanvases[canvasName]) {
        offScreenCanvases[canvasName] = createOffScreenCanvas();
    }

    return offScreenCanvases[canvasName];
};


/**
 * Create an Off ScreenCanvas
 */
const createOffScreenCanvas = (): OffscreenCanvas => {
    return new OffscreenCanvas(320, 240);
};


export const createImageBitmapForUrlBackground = (offscreenCanvasName: string, width: number, height: number, url: string): Promise<ImageBitmap> => {
    return new Promise((resolve, reject) => {
        const canvas = ensureOffscreenCanvasCreated(offscreenCanvasName) as OffscreenCanvas;
        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width;
            canvas.height = height;
        }
        // @ts-ignore
        const ctx = canvas.getContext('2d', {alpha: false, desynchonized: true}) as OffscreenCanvasRenderingContext2D;

        const background = new Image();
        background.src = url;
        background.crossOrigin = 'Anonymous';
        background.onload = () => {
            ctx.translate(width, 0);
            ctx.scale(-1, 1);
            drawCoverImage(ctx, background, 0, 0, width, height);

            resolve(canvas.transferToImageBitmap());
        };
    });

};


export const createImageBitmapForStaticBackground = (offscreenCanvasName: string, width: number, height: number, color1: string, color2: string): ImageBitmap => {
    const canvas = ensureOffscreenCanvasCreated(offscreenCanvasName) as OffscreenCanvas;
    if (canvas.width !== width || canvas.height !== height) {
        canvas.width = width;
        canvas.height = height;
    }
    // @ts-ignore
    const ctx = canvas.getContext('2d', {alpha: false, desynchonized: true}) as OffscreenCanvasRenderingContext2D;
    if (color1 && color2) {
        const gradient = ctx.createLinearGradient(0, 0, Math.floor(width / 2), Math.floor(height / 2));
        gradient.addColorStop(0, color1);
        gradient.addColorStop(1, color2);
        ctx.fillStyle = gradient;
    } else {
        ctx.fillStyle = color1;
    }
    ctx.fillRect(0, 0, width, height);
    return canvas.transferToImageBitmap();
};

export const drawAndBlurImageOnCanvas = (image, blurAmount, canvas) => {
    const height = image.height;
    const width = image.width;
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.clearRect(0, 0, width, height);
    ctx.save();
    ctx.filter = 'blur(' + blurAmount + 'px)';
    ctx.drawImage(image, 0, 0, width, height);
    ctx.restore();
};


export const drawAndBlurImageOnOffScreenCanvas = (image, blurAmount: number, offscreenCanvasName: string): HTMLCanvasElement | OffscreenCanvas => {
    const canvas = ensureOffscreenCanvasCreated(offscreenCanvasName);
    if (blurAmount === 0) {
        renderImageToCanvas(image, canvas);
    } else {
        drawAndBlurImageOnCanvas(image, blurAmount, canvas);
    }
    return canvas;
};

export const renderImageToCanvas = (image, canvas: HTMLCanvasElement | OffscreenCanvas) => {
    const width = image.width;
    const height = image.height;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx !== null) {
        ctx.drawImage(image, 0, 0, width, height);
    }
};

export const renderImageDataToCanvas = (image, canvas: HTMLCanvasElement | OffscreenCanvas) => {
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    if (ctx !== null) {
        ctx.putImageData(image, 0, 0);
    }
};


export const renderImageDataToOffScreenCanvas = (image, canvasName): HTMLCanvasElement | OffscreenCanvas => {
    const canvas = ensureOffscreenCanvasCreated(canvasName);
    renderImageDataToCanvas(image, canvas);
    return canvas;
};


export const drawWithCompositing = (ctx, image, compositOperation) => {
    ctx.globalCompositeOperation = compositOperation;
    ctx.drawImage(image, 0, 0);
};

export const putWithCompositing = (ctx, image, compositOperation) => {
    ctx.globalCompositeOperation = compositOperation;
    ctx.putImageData(image, 0, 0);
};


const listOfLastFrame = {};
export const mustBeGenerateFrame = (fps: number, id: string, overload: boolean = false): boolean => {
    // new video frame
    if (!listOfLastFrame[id]) {
        listOfLastFrame[id] = new Date().getTime();
        return true;
    } else {
        // time between last frame
        let beetweenFrame = 1000 / fps;
        if (beetweenFrame < 50 && !overload) {
            // we don't want to overload system so we limit to 20 by second
            beetweenFrame = 50;
        }
        const now = new Date().getTime();
        if (listOfLastFrame[id] + beetweenFrame < now) {
            listOfLastFrame[id] = now;
            return true;
        } else {
            return false;
        }
    }
};

/**
 * Currently work only on landscape
 * @param imageBitmap
 * @param width
 * @param height
 */
export const cropAndScaleBitmap = async (imageBitmap: ImageBitmap | HTMLCanvasElement,
                                         width: number,
                                         height: number): Promise<ImageBitmap> => {
    let x = 0;
    const y = 0;
    const scale = imageBitmap.height / height;
    const scaleWidth = width * scale;
    const scaleHeight = height * scale;
    x = Math.floor((imageBitmap.width - scaleWidth) / 2);

    return await createImageBitmap(imageBitmap,
        x, 0, scaleWidth, scaleHeight, {
            resizeHeight: height,
            resizeWidth: width,
            resizeQuality: 'low'
        });
};
export const cropAndScaleVideo = async (video: HTMLVideoElement,
                                        width: number,
                                        height: number): Promise<ImageBitmap> => {
        let x = 0;
        const y = 0;
        const scale = video.videoHeight / height;
        const scaleWidth = width * scale;
        const scaleHeight = height * scale;
        x = Math.floor((video.videoWidth - scaleWidth) / 2);

        return await createImageBitmap(video,
            x, 0, scaleWidth, scaleHeight, {
                resizeHeight: height,
                resizeWidth: width,
                resizeQuality: 'low'
            });
    }
;


export const destroyCanvas = (id: string) => {
    delete offScreenCanvases[id];
    delete transformsCanvas[id];
};

class CanvasTransform {
    public scaleX = 1;
    public scaleY = 1;
    public x = 0;
    public y = 0;

    public equals(tranform: CanvasTransform): boolean {

        if (!tranform || tranform.scaleX !== this.scaleX || tranform.scaleX !== this.scaleX ||
            tranform.x !== this.x ||
            tranform.y !== this.y) {
            return false;
        } else {
            return true;
        }
    }
}


export const videoToImage = async (url: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {

        // we have to put the stream in a video to keep it alive
        const video = document.createElement('video');
        video.style.zIndex = '-1';
        video.style.position = 'absolute';
        video.style.opacity='0';
        video.style.top = '0';
        video.style.left = '0';
        video.muted = true;
        video.controls = true;
        video.playsInline = true;
        video.crossOrigin = 'anonymous';
        document.body.append(video);
        video.src = url;

        let videoReady = false;
        // @ts-ignore
        video.oncanplay = async () => {
            await delay(200);
            videoReady = true;
            if (video.videoWidth === 0) {

                video.remove();
                resolve('');
                return;
            }
        };
        for (let i = 0; i < 50; i++) {
            if (videoReady) {
                break;
            }
            await delay(100);
        }
        if (videoReady) {
            try {
                const canvas = new OffscreenCanvas(video.videoWidth, video.videoHeight);
                const context = canvas.getContext('2d', {alpha: false});
                if (context) {
                    context.drawImage(video, 0, 0);
                    const blob = await canvas.convertToBlob({type: 'image/jpeg', quality: 0.9});
                    video.remove();
                    resolve(URL.createObjectURL(blob));
                }
            } catch (e) {
                resolve('');
                video.remove();
            }
        } else {
            video.remove();
        }
    });
};


export const drawCoverImage = (ctx: CanvasRenderingContext2D
                                   | OffscreenCanvasRenderingContext2D,
                               img: HTMLImageElement | HTMLVideoElement,
                               x: number = 0,
                               y: number = 0,
                               w: number = 0,
                               h: number = 0,
                               offsetX: number = 0.5,
                               offsetY: number = 0.5,
                               mirror: boolean = false) => {

    if (w === 0) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) {
        offsetX = 0;
    }
    if (offsetY < 0) {
        offsetY = 0;
    }
    if (offsetX > 1) {
        offsetX = 1;
    }
    if (offsetY > 1) {
        offsetY = 1;
    }

    const iw = img.width;
    const ih = img.height;
    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;   // new prop. width
    let nh = ih * r;   // new prop. height
    let cx;
    let cy;
    let cw;
    let ch;
    let ar = 1;

    // decide which gap to fill
    if (nw < w) {
        ar = w / nw;
    }
    if (Math.abs(ar - 1) < 1e-14 && nh < h) {
        ar = h / nh;
    }
    // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) {
        cx = 0;
    }
    if (cy < 0) {
        cy = 0;
    }
    if (cw > iw) {
        cw = iw;
    }
    if (ch > ih) {
        ch = ih;
    }
    // fill image in dest. rectangle
    if (mirror) {
        ctx.drawImage(img, cx, cy, cw, ch, -x, y, -w, h);
    } else {
        ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
    }
};
