<template>
  <transition name="zoom">
    <div class="errorClass" v-show="message">
      <w-icon class="icon" icon="alert-circle"/>
      {{ message }}
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';

@Component({
  components: {WIcon},
})
export default class WError extends Vue {
  @Prop({default: ''})
  private message!: string;
  @Prop({default: ''})
  private color!: string;

}
</script>

<style scoped lang="scss">
.errorClass {
  animation-duration: 0.2s;
  border-radius: 4px;
  background: #e54c5f;
  color: white;
  padding: 8px;
  text-align: center;
  font-size: 16px;

  .icon {
    vertical-align: middle;
    margin-right: 8px;
  }
}
</style>
