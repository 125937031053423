<template>
  <transition name="fade">
    <div class="videoContainer" :class="stickerClass">
      <!-- Canvas renderer -->
      <Transition name="fade">
        <canvas v-show="(!isVideoMute || !sticker) && streamLoaded && !isNativeRecorder"
                ref="videoCanvasRenderer"
                class="videoElement" :class="filterClass">
        </canvas>
      </Transition>
      <!-- Video Native renderer -->
      <Transition name="fade">
        <video autoplay muted ref="videoNativeRenderer"
               v-show="(!isVideoMute || !sticker) && isNativeRecorder"
               :class="isWebcamStream?'videoElement '+filterClass:'videoElementScreen '">
        </video>
      </Transition>
      <!-- Filter -->
      <div class="mask"></div>
      <div class="layer1"></div>
      <div class="layer2"></div>
      <div class="layer3"></div>
      <div class="layer4"></div>
      <div class="layer5"></div>
      <!-- LOADER -->
      <Transition name="fade">
        <div class="loadingElement" v-show="!streamLoaded || !streamUtils.filterLoaded">
          <div class="smiley" v-if="isWebcamStream">{{ getRandomSmile }}</div>

          <div class="loadingLabel" v-if="isWebcamStream && !streamUtils.filterLoaded && streamLoaded">{{
              $t('capture.loadingFilter')
            }}
          </div>
          <div class="loadingLabel" v-else-if="isWebcamStream">{{
              getRandomAdvice
            }}
          </div>
        </div>
      </Transition>
      <div class="vuMeter" v-if="!this.isMute" v-show="soundDetect">
        <w-icon size="default" icon="waveform"></w-icon>
      </div>
      <div class="vuMeter mirror" v-else-if="isWebcamStream">
        <w-icon size="default" icon="microphone-off"></w-icon>
      </div>
      <WebcamAuthHelper/>
    </div>

  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import recordRTC from 'recordrtc';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {ChunkMedia, Media} from '@/store/media/mediaModel';
import {SETUP_WC} from '@/store/setup/setupAction';
import {SoundMeter, SoundMeterEvent} from '@/utils/audioEffect/soundMeter';
import WebcamAuthHelper from '@/components/recorder/videoLayout/authLayout/WebcamAuthHelper.vue';
import {StreamUtilMediaType, StreamUtils, WebcamIntegrationPosition} from '@/utils/video/streamUtils';
import {getUserAvatar} from '@/utils/util';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {inform, informError} from '@/utils/dialog';
import throttle from 'lodash/throttle';
import {
  CLEAR_MAIN_MEDIAID,
  CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID,
  GENERATE_STICKERS_MEDIAID,
  NEW_SOUND_DETECT,
  PAUSE_RECORDING,
  RECORD_STICKER_FACE_TRACK,
  RECORD_STICKER_FILTER,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_INTEGRATION,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIRTUAL_BACKGROUND,
  RECORD_WEBCAM_AIVAILABLE,
  SET_CURRENT_EDITING_WEET,
  SET_MAIN_TIME_RECORDING,
  SET_SCREEN_SURFACE,
  SET_STICKERS_TIME_RECORDING,
  START_RECORDING,
  UPDATE_MAIN_RUNNING_STATE,
  UPDATE_STICKERS_RUNNING_STATE
} from '@/store/recordingState/recordStateAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import delay from 'delay';
import {log} from '@/utils/log';
import {CREATE_MY_WEET} from '@/store/myWeet/myWeetAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {ADD_MEDIA, CREATE_CHUNK_MEDIA, SET_BLOB_TO_MEDIA} from '@/store/media/mediaAction';
import {DEBUG_MODE, NULL_FILE, PUBLIC_PATH, TIME_CHUNK} from '@/store/conf/api';
import WIcon from '@/components/wrapper/w-icon.vue';
import randomInt from 'random-int';
import {getImageForVBCustom, listOfFilter, listOfVirtualBackground} from '@/utils/filterUtils';
import {videoToImage} from '@/utils/video/canvasUtils';
import {SoundSegmenter} from '@/utils/audioEffect/soundSegmenter';
import {getSeekableBlob} from '@/utils/videoUtil';
import {muteMicro, muteWebcam} from '@/utils/weetUtil';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';


@Component({
  components: {WIcon, WebcamAuthHelper},
  computed: {},
})
export default class VideoSource extends Vue {

  @Prop({default: false}) // put webcam in HD
  public HD!: boolean;
  // Object record RTC
  public recordRTC: recordRTC = null;
  // id of interval when recording recordRTC
  public recordRTCInterval: any = 0;
  public recordCanvasTimeOut: any = 0;
  // Step of recording
  public recordRTCIntervalStep: number = 250;
  // TODO change timer for accurate Timer;

  // this date of start Recording (Interval step is not really confidence)
  public dateOfStartRecording: number = new Date().getTime();


  private currentTime: number = 0;

  // Vu meter parameters
  private soundMeter!: SoundMeter;
  private soundDetect: boolean = false;
  private soundSegmenter!: SoundSegmenter | undefined;

  // value if the stream is setup
  private streamLoaded: boolean = false;
  // stream for record RTC
  private stream!: MediaStream;

  private streamUtils: StreamUtils = new StreamUtils();

  // Is filter change aivailable
  @Prop({default: false})
  private filter!: boolean;

  // is the videosource is a stickers
  @Prop({default: false})
  private sticker!: boolean;

  // put this option if you are in mediaCreator mode (no link with a weet)
  @Prop({default: false})
  private mediaCreatorMode!: boolean;

  // WeetID
  @Prop({default: ''})
  private weetID!: string;

  // if you want the record start after stream Selction
  @Prop({default: false})
  private autostartRecord!: boolean;

  // Type of source
  @Prop()
  private type!: SourceVideoType;

  // if you want to add microphone on screen
  @Prop({default: false})
  private screenMicMode!: boolean;

  // number of the chunk in progress
  private partChunk: number = 0;


  get canvasRenderer(): HTMLCanvasElement {
    return this.$refs.videoCanvasRenderer as HTMLCanvasElement;
  }

  get isNativeRecorder(): boolean {
    return this.streamUtils.nativeRenderer;
  }

  get videoRenderer(): HTMLVideoElement {
    return this.$refs.videoNativeRenderer as HTMLVideoElement;
  }

  get userBackground(): string {
    return getUserAvatar(store.getters.userConnected, 512);
  }

  get filterClass(): string {
    if (!this.filter || !this.streamLoaded) {
      return FilterVideoType.NONE;
    } else {
      if (!this.HD) {
        return store.getters.getRecorderFilter;
      } else {
        // we verifie is the filter is aivailable in HD mode
        for (const f of listOfFilter()) {
          if (f.value === store.getters.getRecorderFilter) {
            if (f.full) {
              return store.getters.getRecorderFilter;
            } else {
              return FilterVideoType.NONE;
            }
          }
        }
        return FilterVideoType.NONE;
      }
    }
  }

  get stickerBackground(): string {
    return getUserAvatar(store.getters.userConnected, 512);
  }

  get getRandomSmile(): string {
    const randomSmile = [this.$t('capture.emote.1').toString(),
      this.$t('capture.emote.2').toString(),
      this.$t('capture.emote.3').toString(),
      this.$t('capture.emote.4').toString(),
      this.$t('capture.emote.5').toString(),
      this.$t('capture.emote.6').toString(),
      this.$t('capture.emote.7').toString(),
      this.$t('capture.emote.8').toString(),
      this.$t('capture.emote.9').toString()];
    return randomSmile[randomInt(randomSmile.length - 1)];
  }

  get getRandomAdvice(): string {
    const randomAdvice = [this.$t('capture.advice.1').toString(),
      this.$t('capture.advice.2').toString(),
      this.$t('capture.advice.3').toString(),
      this.$t('capture.advice.4').toString(),
      this.$t('capture.advice.5').toString()];
    return randomAdvice[randomInt(randomAdvice.length - 1)];
  }

  get stickerClass(): string {
    if (this.sticker) {
      return 'stickerBackground';
    } else {
      return 'screenBackground';
    }
  }

  get isWebcamStream(): boolean {
    return this.type === SourceVideoType.WEBCAM;
  }

  get previewMode(): boolean {
    return store.getters.isPreviewRecording;
  }

  get isMute(): boolean {
    return store.getters.isStickerMute;
  }

  get recordingState(): RecordingState {
    return store.getters.getRecordingState;
  }


  get isScreenRecording(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.SCREENCAST;
  }


  get isVideoMute(): boolean {
    return store.getters.isStickerVideoMute;
  }

  get isFaceTrack(): boolean {
    return store.getters.getStickerFaceTrack;
  }

  get favoriteFilter(): FilterVideoType | VirtualBackgroundEnum | WebCamIntegrationTypeEnum {
    return store.getters.getFavoriteFilter;
  }

  get favoriteFacetrack(): boolean {
    return store.getters.getFavoriteFaceTrack;
  }

  get favoriteMic(): string {
    return store.getters.getFavoriteMicro;
  }

  get favoriteCamera(): string {
    return store.getters.getFavoriteCamera;
  }

  get virtualBackground(): VirtualBackgroundEnum {
    return store.getters.getVirtualBackground;
  }

  get resolutionCapture(): number {
    return store.getters.getResolutionCapture;
  }

  get fpsCapture(): number {
    return store.getters.getFpsCapture;
  }

  private isStaticVirtualBackground(value: VirtualBackgroundEnum): boolean {
    for (const bg of listOfVirtualBackground()) {
      if (bg.value === value) {
        if (bg.type === 'image' || bg.type === 'custom') {
          return true;
        }
      }
    }
    return false;
  }

  private getImageForStaticVirtualBackground(value: VirtualBackgroundEnum): string {
    for (const bg of listOfVirtualBackground()) {
      if (bg.value === value) {
        if (bg.type === 'image') {
          return '/virtualBackground/static/' + this.virtualBackground + '.jpg';
        } else {
          return getImageForVBCustom(this.virtualBackground);
        }
      }
    }
    return '';
  }

  get getWebcamIntegrationFilter(): WebCamIntegrationTypeEnum {
    return store.getters.getWebCamIntegration;
  }

  get urlVirtualBackground(): string {
    if (this.virtualBackground === VirtualBackgroundEnum.NONE
        || !this.isWebcamStream) {
      return '';
    } else {
      if (this.isStaticVirtualBackground(this.virtualBackground)) {
        return this.getImageForStaticVirtualBackground(this.virtualBackground);
      } else {
        return '/virtualBackground/' + this.virtualBackground + '.mp4';
      }
    }
  }

  @Watch('virtualBackground')
  public onVirtualBackgroundChange() {
    this.forceReloadWebcam();
  }


  @Watch('isFaceTrack')
  public onFaceTrackChange() {
    this.forceReloadWebcam();
  }

  @Watch('isVideoMute')
  public onVideoMute() {
    this.forceReloadWebcam();
  }

  @Watch('isMute')
  public onAudioMute() {
    if (!this.streamUtils.hasAudioTrack) {
      this.forceReloadWebcam();
    }
  }

  @Watch('resolutionCapture')
  public async onResolutionChange() {
    if (!this.sticker) {
      this.$emit('killSource');
      clearTimeout(this.recordCanvasTimeOut);
      await this.streamUtils.destroy();
      this.streamUtils = new StreamUtils();
    }
  }

  @Watch('resolutionFps')
  public async onResolutionFps() {
    if (!this.sticker) {
      this.$emit('killSource');
      clearTimeout(this.recordCanvasTimeOut);
      await this.streamUtils.destroy();
      this.streamUtils = new StreamUtils();
    }
  }

  @Watch('isMute')
  public onMute() {
    if (this.isWebcamStream) {
      if (this.stream && this.stream.getAudioTracks()[0]) {
        this.stream.getAudioTracks()[0].enabled = !this.isMute;
      }
    }
  }


  @Watch('favoriteMic')
  public async onFavoriteMicro() {

    if (this.type === SourceVideoType.WEBCAM) {
      await this.forceReloadWebcam();
      await delay(500);
      this.notifyStream();
    }

  }

  @Watch('favoriteCamera')
  public async onFavoriteCamera() {
    if (this.type === SourceVideoType.WEBCAM) {
      await this.forceReloadWebcam();
      await delay(500);
      this.notifyStream();
    }
  }

  @Watch('recordingState')
  public onrecordingStateChange() {
    if (this.recordingState === RecordingState.RECORDING) {
      this.onStartRecording();
    } else if (this.recordingState === RecordingState.PAUSE) {
      this.onPauseRecording();
    } else if (this.recordingState === RecordingState.FINISH) {
      this.onStopRecording();
    }
  }

  @Watch('previewMode')
  public muteStreamIfNeed() {
    this.streamUtils.pauseRender = this.previewMode;
  }


  public async forceReloadWebcam() {
    // if only on sticker, and webcam is already in work
    if (this.isWebcamStream) {
      this.streamLoaded = false;
      clearTimeout(this.recordCanvasTimeOut);

      await this.streamUtils.destroy();
      if (this.soundMeter) {
        this.soundMeter.close();
      }
      // if we are in recording
      if (store.getters.getRecordingState === RecordingState.RECORDING) {
        // so desactivate the webcam
        store.dispatch(PAUSE_RECORDING).then(() => {
          this.loadWebCamMedia().then(() => {
            store.dispatch(START_RECORDING);
          });
        });
      } else {
        this.loadWebCamMedia();
      }
    }
  }

  public mounted() {
    if (this.type === SourceVideoType.WEBCAM) {
      this.setupFilter();
      // we wait the fiter is setup
      this.loadWebCamMedia();

    } else if (this.type === SourceVideoType.SCREENCAST) {
      this.loadScreencastMedia();
    }
  }


  public setupFilter() {
    // check if the filter exist
    if (FilterVideoType[this.favoriteFilter]) {
      store.dispatch(RECORD_STICKER_FILTER, this.favoriteFilter);
    } else if (VirtualBackgroundEnum[this.favoriteFilter]) {
      store.dispatch(RECORD_STICKER_VIRTUAL_BACKGROUND, this.favoriteFilter);
    } else if (WebCamIntegrationTypeEnum[this.favoriteFilter]) {
      store.dispatch(RECORD_STICKER_INTEGRATION, this.favoriteFilter);
    } else {
      // by default
      store.dispatch(RECORD_STICKER_FILTER, FilterVideoType.NONE);
    }
    // now we use faceTrack
    store.dispatch(RECORD_STICKER_FACE_TRACK, this.favoriteFacetrack);
  }

  public async onStreamReady() {
    await delay(1000);
    this.streamLoaded = true;
    setActionEvent(ActionEvent.webcam_allowed);
    this.$emit('mediaLoaded');
  }

  /**
   * This method load a screen :-)
   */
  public async loadScreencastMedia() {
    try {
      this.streamLoaded = false;
      // wait stream is ready before destroy
      await this.streamUtils.destroy();
      this.streamUtils = new StreamUtils();
      this.streamUtils.onStreamReady(() => {
        this.onStreamReady();
        setActionEvent(ActionEvent.screen_allowed);
      });

      // the streamUtils will choose the right input if displaySurface
      this.streamUtils.inputBitmapRenderer(this.canvasRenderer);
      this.streamUtils.inputNativeRenderer(this.videoRenderer);
      let fps: number = this.fpsCapture;
      let type = StreamUtilMediaType.SCREEN;
      let height = this.resolutionCapture;

      let width = Math.floor(height * 16 / 9);
      // the width must be a pair number
      if (width % 2 === 1) {
        width++;
      }
      if (this.getWebcamIntegrationFilter !== WebCamIntegrationTypeEnum.NONE) {
        type = StreamUtilMediaType.SCREEN_CAM;
        fps = this.fpsCapture;
        // ask screen
        this.streamUtils.applyWebCamIntegration(this.canvasRenderer, width, height, WebcamIntegrationPosition.BOTTOM_LEFT, 0.45);

        // ask for a small camera (best perf)
        width = 640;
        height = 480;
        // remove sticker (we don't need)
        store.dispatch(RECORD_STICKER_POSITION, StickerPosition.NONE);
      }

      const streamLoaded= await this.streamUtils.loadStream(type, '', width, height, fps, fps);
      if(!streamLoaded){
        return null;
      }else{
        this.stream = streamLoaded;
      }
      if (this.getWebcamIntegrationFilter === WebCamIntegrationTypeEnum.NONE) {
        // to send event to amplitude
        // we send the type of screen share to amplitude
        setActionEvent(ActionEvent.record_type_screen, {typeScreen: this.streamUtils.screenSurface});
      }
      // add microphone
      if (this.screenMicMode || this.getWebcamIntegrationFilter !== WebCamIntegrationTypeEnum.NONE) {
        await this.streamUtils.addMicrophone(this.favoriteMic);
        this.connectSoundMeter(this.stream);
      }

      store.dispatch(SET_SCREEN_SURFACE, this.streamUtils.screenSurface);
      this.recordRTC = recordRTC(this.stream, this.videoOptions());

      this.addStopOnKillSource();
      if (this.autostartRecord) {
        this.autoStartRecord();
      }

    } catch (e: any) {
      this.$emit('error', e.message);
      // if (e.message.indexOf('Permission denied') >= -1) {
      //   return;
      // } else {
      log.error(e.message);
      this.showError(this.$t('videoSource.screen.error').toString());
      // }
    }
  }

  public async autoStartRecord() {
    if (!this.mediaCreatorMode) {
      // we create a weet if nor already created and launch record on tabs
      if (store.getters.getEditingWeetID === '') {
        const weet = await store.dispatch(CREATE_MY_WEET);
        sendMessageToExtention(SET_CURRENT_EDITING_WEET, weet.weetID);
        store.dispatch(SET_CURRENT_EDITING_WEET, weet.weetID);
      }
    }
    // start recording
    store.dispatch(START_RECORDING);
  }


  /**
   * This methode add listener to stop record when the source is killed (close tab)
   */
  public addStopOnKillSource() {
    if (this.stream) {
      if (this.streamUtils.streamSource.getVideoTracks().length > 0) {
        // add lisetenr on the stream if cut by other source
        this.streamUtils.streamSource.getVideoTracks()[0].onended = async () => {
          // inform listener
          // update the store
          this.$emit('killSource');
          clearTimeout(this.recordCanvasTimeOut);
          await this.streamUtils.destroy();
          this.streamUtils = new StreamUtils();
        };
      }
    }
  }


  /**
   * This setup the recordRTC for a webacam
   */
  public async loadWebCamMedia() {
    try {
      store.dispatch(RECORD_WEBCAM_AIVAILABLE, false);
      this.streamLoaded = false;
      await this.streamUtils.destroy();
      this.streamUtils = new StreamUtils(this.HD);
      this.streamUtils.onStreamReady(async () => {
        this.onStreamReady();
        store.dispatch(RECORD_WEBCAM_AIVAILABLE, true);
        store.dispatch(SETUP_WC, true);

        if(store.getters.getMainRecordSource==SourceVideoType.AI_AVATAR
        || store.getters.getMainRecordSource===SourceVideoType.UPLOAD){
          this.streamUtils.destroy();
        }
      });


      // The webcam can be stop by a changof media or something like that.
      // So if error, we reload the camera
      this.streamUtils.onRenderError(throttle(() => {
        this.forceReloadWebcam();
        log.warn('Reload stream for webcam');
      }, 3000));
      // configuration in "classic mode"
      let wcHeight = this.resolutionCapture;
      let wcWidth = Math.floor(wcHeight * 16 / 9);
      let fpsCapture = this.fpsCapture;
      let widthResolution = wcWidth;
      let heightResolution = wcHeight;
      // need High Quality
      let HQ = true;

      // reduce params if not HD
      if (!this.HD) {
        // if we are in sticker mode
        wcWidth = 480;
        wcHeight = 480;
        fpsCapture = this.fpsCapture;
        widthResolution = 480;
        heightResolution = 480;
        HQ = false;
      }
      // reduce params if VB
      if (this.virtualBackground !== VirtualBackgroundEnum.NONE) {
        HQ = true; // we need mor bitrate for VB and blur
        if (!this.HD) {
          // fix resolution for AI effett in sticker
          wcWidth = 480;
          wcHeight = 480;
          fpsCapture = 25;
          widthResolution = 480;
          heightResolution = 480;
        } else {
          // fix resolution for AI effect in fullscreen
          wcWidth = 1280;
          wcHeight = 720;
          fpsCapture = 25;
          heightResolution = 720;
          widthResolution = Math.floor(heightResolution * 16 / 9);
          // widthResolution = 640;
          // heightResolution = 360;
        }
      }
      this.streamUtils.inputNativeRenderer(this.videoRenderer);
      // change params if Facetrack
      // no facetrack in HD for the moment
      if (this.isFaceTrack && !this.HD) {
        wcWidth = 1280;
        wcHeight = 720;


        HQ = true; // we need mor bitrate for Facetracking
        // no crop
        this.streamUtils.inputVideoTagRenderer(this.canvasRenderer, undefined, undefined);
        this.streamUtils.applyFaceTrackRenderer(this.canvasRenderer, widthResolution, heightResolution);
      } else {
        if (!this.HD || this.virtualBackground !== VirtualBackgroundEnum.NONE) {
          // launch the input capture with crop
          this.streamUtils.inputVideoTagRenderer(this.canvasRenderer, widthResolution, heightResolution);
        }
      }


      if (!this.isVideoMute) {
        // now compute the renderer
        if (this.virtualBackground === VirtualBackgroundEnum.BLUR) {
          this.streamUtils.applyBlurBackgroundRenderer(this.canvasRenderer, widthResolution, heightResolution);
        } else if (this.virtualBackground !== VirtualBackgroundEnum.NONE) {
          if (this.isStaticVirtualBackground(this.virtualBackground)) {
            this.streamUtils.applyVirtualBackgroundRenderer(this.canvasRenderer, widthResolution, heightResolution, '', '', '', this.urlVirtualBackground);
          } else {
            this.streamUtils.applyVirtualBackgroundRenderer(this.canvasRenderer, widthResolution, heightResolution, this.urlVirtualBackground);
          }
        }
        try {
          const streamLoaded=await this.streamUtils.loadStream(StreamUtilMediaType.WEBCAM,
              this.favoriteCamera, wcWidth, wcHeight, fpsCapture, fpsCapture);
          if(!streamLoaded){
            return;
          }else{
            this.stream =streamLoaded;
          }
        } catch (e) {
          // Todo if e contain => Permission denied
          muteWebcam(true);
          await this.loadWebCamMedia();
          this.$emit('videoDenied');
          inform(this.$t('videoSource.webcam.notAllowed').toString(), 'top', 'light');
          return;
        }
      } else {
        var streamLoaded=await this.streamUtils.loadStream(StreamUtilMediaType.NONE);
        if(!streamLoaded){
          return null;
        }else {
          this.stream = streamLoaded;
        }
      }

      try {
        if (!this.isMute) {
          await this.streamUtils.addMicrophone(this.favoriteMic);
          // we wait the microphone is Mounted on the stream
          setTimeout(() => {
            this.connectSoundMeter(this.stream);

            // launch youtubeme record
            if (this.soundSegmenter) {
              this.soundSegmenter.close();
            }
            this.soundSegmenter = new SoundSegmenter(this.stream, this.onSoundDetected);
          }, 100);
        }
      } catch (e) {
        // Todo if e contain => Permission denied
        muteMicro(true);
        await this.loadWebCamMedia();
        this.$emit('audioDenied');
        inform(this.$t('videoSource.microphone.notAllowed').toString(), 'top', 'light');
        return;
      }
      // intialise the mute
      this.onMute();
      this.recordRTC = recordRTC(this.stream, this.videoOptions(HQ));

      if (this.autostartRecord) {
        this.autoStartRecord();
      }


    } catch (e: any) {
      // todo create popup like meet
      this.$emit('error', e.message);
      this.showError(this.$t('videoSource.webcam.error').toString());
      store.dispatch(SETUP_WC, false);
    }
  }

  private onSoundDetected(value) {
    store.dispatch(NEW_SOUND_DETECT, value);
  }

  public notifyStream() {
    const audioTrack = this.getAudioTrackLabel();
    const videoTrack = this.getVideoTrackLabel();

    if (audioTrack) {
      inform('😗 ' + audioTrack);
    }
    if (videoTrack) {
      inform('🤩 ' + videoTrack);
    }
  }

  public getAudioTrackLabel() {
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        if (track.kind === 'audio') {
          return track.label.split('(')[0];
        }
      }
    }
    return '';
  }

  public getVideoTrackLabel() {
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        if (track.kind === 'video') {
          return track.label.split('(')[0];
        }
      }
    }
    return '';
  }

  public connectSoundMeter(stream: MediaStream) {
    log.info('Connect SoundMeter');
    if (this.soundMeter) {
      this.soundMeter.close();
    }
    let timeout = 0;
    this.soundMeter = new SoundMeter(stream, (event: SoundMeterEvent) => {
      clearTimeout(timeout);
      this.soundDetect = true;

      // @ts-ignore
      timeout = setTimeout(() => {
        this.soundDetect = false;
      }, 500);
    });
  }

  /**
   * Clean source when destroy the component
   */
  public async beforeDestroy() {
    if (this.streamUtils) {
      await this.streamUtils.destroy();
      this.streamUtils = new StreamUtils();
      clearTimeout(this.recordCanvasTimeOut);

      if (this.soundMeter) {
        this.soundMeter.close();
      }

      // @ts-ignore
      this.soundMeter = null;
      // @ts-ignore
      this.stream = null;
      // @ts-ignore
      this.canvaStream = null;

    }
  }


  public onStartRecording() {

    // if we have no other source, we switch to fullscreen
    if (!this.mediaCreatorMode && store.getters.getMainRecordSource === SourceVideoType.NONE
        && !store.getters.isStickerFullscreen) {
      store.dispatch(RECORD_STICKER_FULLSCREEN);
    }
    if (this.recordRTC !== null) {
      this.generateMediaID();
      this.createMedia();

      setActionEvent(ActionEvent.record_hardware, {
        videoHW: this.getVideoTrackLabel(), audioHW: this.getAudioTrackLabel(),
      });

      if (this.recordRTC.state === 'inactive' || this.recordRTC.state === 'stopped'
          || this.recordRTC.state === 'paused') {
        this.clearRecordingTime();
        this.recordRTC.startRecording();
        // reset counter for soundsegmenter
        if (this.soundSegmenter) {
          this.soundSegmenter.start();
        }
        if (!this.mediaCreatorMode) { // no need to compute media creator mode
          this.recordRTCInterval = setInterval(this.incRecordingTime, this.recordRTCIntervalStep);
        }
        this.updateRunningStatus(true);
      }
    }
  }

  public onPauseRecording() {
    this.onStopRecording();
  }

  public onStopRecording() {

    log.info('on Stop recording');


    if (this.recordRTC !== null) {
      log.info('on Stop RTC');
      this.recordRTC.stopRecording(async () => {
            if (this.soundSegmenter) {
              this.soundSegmenter.stop();
            }
            this.updateRunningStatus(false);
            this.clearRecordingTime();
            let mediaID = '';
            if (this.sticker) {
              mediaID = store.getters.getStickersMediaId;
            } else {
              mediaID = store.getters.getMainMediaId;
            }

            // convert in a seekableBlob
            const blob = this.recordRTC.getBlob();
            let blobURL = URL.createObjectURL(blob);
            let imageURL = '';
            if (blob.size > 0) {
              try {
                const seekableBlob = await getSeekableBlob(blob) as Blob;
                blobURL = URL.createObjectURL(seekableBlob);
                imageURL = await videoToImage(blobURL + '#t=1.5');
              } catch (e: any) {
                console.error(e);
              }
            } else {
              this.sendData(blob, 1);
              blobURL = NULL_FILE + '';
            }
            store.dispatch(SET_BLOB_TO_MEDIA, {mediaId: mediaID, blobURL: blobURL, imageURL: imageURL});
          },
      );
    }
  }

  public updateRunningStatus(value: boolean) {
    if (this.mediaCreatorMode) {
      return;
    }
    if (this.sticker) {
      store.dispatch(UPDATE_STICKERS_RUNNING_STATE, value);
    } else {
      store.dispatch(UPDATE_MAIN_RUNNING_STATE, value);
    }
  }

  public clearRecordingTime() {
    clearInterval(this.recordRTCInterval);
    this.dateOfStartRecording = new Date().getTime();
    this.currentTime = 0;
    if (this.sticker) {
      store.dispatch(SET_STICKERS_TIME_RECORDING, 0);
    } else {
      store.dispatch(SET_MAIN_TIME_RECORDING, 0);
    }
    this.partChunk = 0;
  }

  public incRecordingTime() {
    // compute time
    // the tick is not confidence (chrome try to send the tick at a time)
    this.currentTime = new Date().getTime() - this.dateOfStartRecording;
    if (this.sticker) {
      store.dispatch(SET_STICKERS_TIME_RECORDING, this.currentTime);
    } else {
      store.dispatch(SET_MAIN_TIME_RECORDING, this.currentTime);
    }
  }

  public createMedia() {
    const media = new Media();
    if (this.sticker) {
      media.mediaID = store.getters.getStickersMediaId;
    } else {
      media.mediaID = store.getters.getMainMediaId;
    }
    media.type = this.type;
    store.dispatch(ADD_MEDIA, media);
  }

  public generateMediaID() {
    if (store.getters.getMainRecordSource === SourceVideoType.NONE) {
      store.dispatch(CLEAR_MAIN_MEDIAID);
    }
    if (store.getters.getStickerRecorderSource === SourceVideoType.NONE) {
      store.dispatch(CLEAR_STICKER_MEDIAID);
    }
    // generate an MediaID
    if (this.sticker) {
      store.dispatch(GENERATE_STICKERS_MEDIAID);
    } else {
      store.dispatch(GENERATE_MAIN_MEDIAID);
    }
  }

  public videoOptions(HQ: boolean = false): any {

    var type='video';
    var mimeType= 'video/webm;codecs=h264';
    if(this.stream && this.stream.getVideoTracks().length==0){
      type='audio';
      mimeType= 'audio/webm;codecs=opus';
    }
    return {
      type: type,
      mimeType: mimeType,
      video: {width: 1440, height: 720},
      disableLogs: !DEBUG_MODE,
      audioBitsPerSecond: 64000,
      timeSlice: TIME_CHUNK,
      videoBitsPerSecond: this.streamUtils.computeQuality(HQ),
      // bitrate: 128000,
      // requires timeSlice above
      // returns blob via callback function
      ondataavailable: (blob) => {
        this.partChunk++;
        this.sendData(blob, this.partChunk);
      },
    };
  }

  private sendData(blob: Blob, part: number) {
    const chunk = new ChunkMedia();
    chunk.blob = blob;
    if (this.sticker) {
      chunk.mediaID = store.getters.getStickersMediaId;
    } else {
      chunk.mediaID = store.getters.getMainMediaId;
    }
    chunk.partNumber = part;
    chunk.type = this.type;
    if (this.recordRTC.state === 'stopped') {
      chunk.lastPart = true;
    } else {
      chunk.lastPart = false;
    }
    chunk.mediaCreatorMode = this.mediaCreatorMode;
    if (this.weetID) {
      chunk.weetID = this.weetID;
    } else {
      chunk.weetID = store.getters.getEditingWeetID;
    }
    chunk.noiseCancelation = store.getters.getNoiseCancelationLvl;
    chunk.transcoding = false;
    store.dispatch(CREATE_CHUNK_MEDIA, chunk);
  }

  public showError(msg: string) {
    informError(msg);
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/shadows.scss";

.videoContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  animation-duration: 1s;

  &.stickerBackground {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

  }


  video.videoElement {
    top: 0px;
    height: 100%;
    object-fit: cover;
    width: 100%;
    left: 0;
    border-radius: 8px;
    @extend .shadow3;
  }

  video.videoElementScreen {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    border-radius: 8px;
    @extend .shadow3;
  }

  .videoElement {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    border-radius: 8px;
    @extend .shadow3;
  }

  .controlBar {
    position: absolute;
    top: 16px;
    left: 0px;
  }

  .vuMeter {
    animation-duration: 0.1s;
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    color: #FFF;

    &.mirror {
      transform: scaleX(-1);
    }
  }

  .loadingElement {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(27, 57, 84, 0.8);
    justify-content: center;
    align-items: center;
    transform: scaleX(-1) scaleY(1);

    .smiley {
      margin-top: -10px;
      margin-right: 8px;
      font-size: 42px;
    }

    .loadingLabel {
      margin-right: 8px;
      font-size: 16px;
      color: white;
      font-weight: 900;
    }
  }
}

</style>
