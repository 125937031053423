import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    ADD_PUSH_NOTIFICATION_TOKEN,
    BIND_EXTERNAL_ACCOUNT_REQUEST, CHECK_CONNECTED_EXTERNAL_USER,
    DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE,
    EXTERNAL_TOOL_AUTH_FAILURE,
    EXTERNAL_TOOL_AUTH_SUCCESS,
    EXTERNAL_TOOL_INIT,
    EXTERNAL_TOOL_SEND_MESSAGE, FROM_EXTERNAL_TOOL_URL,
    FROM_EXTERNALTOOL,
    NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL,
    PREPOPULATE_EXTERNALTOOL_REFERENCE,
    REMOVE_ALL_PUSH_NOTIFICATION_TOKEN,
    REMOVE_PUSH_NOTIFICATION_TOKEN,
    REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET,
    SAVE_PUSH_NOTIFICATION_EXTERNAL_ID,
    SET_TEAMS_CONVERSATION_REFERENCE
} from '@/store/integration/integrationAction';
import {app, dialog} from '@microsoft/teams-js';
import {
    NotifyWeetCreation,
    PrivateUserOption,
    PrivateUserOptionKeyEnum,
    PushNotificationToken
} from '@/store/integration/integrationModel';
import {log} from '@/utils/log';
import {ADD_PRIVATE_USER_OPTION, REMOVE_PRIVATE_USER_OPTION} from '@/store/auth/authAction';
import {SET_SETTINGUSER, SettingUserParams} from '@/store/settingUsers/settingUserAction';
import {SettingUsersKey} from '@/store/settingUsers/settingUserKey';
import store from '@/store';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {User} from '@/store/user/userModel';
import Vue from 'vue';
import {ExternalToolEnum} from '@/enum/ExternalToolEnum';
// @ts-ignore
import * as microsoftTeams from '@microsoft/teams-js';
import {PREPOPULATE_SHARED_EMAILS, PREPOPULATE_WEET_TITLE} from '@/store/recordingState/recordStateAction';
import {isSpeach} from "@/utils/speachUtils";

const integrationAPI = BACK_ENDPOINT + '/integration';
const pushNotificationAPI = `${integrationAPI}/push/notification/tokens`;

export default {
    state: {
        // Identifiant unique du navigateur courrant, si les notifications sont activés
        pushNotificationExternalID: null,
        // The reference of the user conversation in external tool. This give the ability to the bot to notify weet creation in external tool
        // Exemple: Conversation reference of the Teams user. Used to notify user in Teams that login is sucessfull
        // For slack it is the response_url of the original slack modal
        externalToolReference: null,
        // true if weet is being created from external tool
        externalTool: ExternalToolEnum.NONE,
    },
    mutations: {
        [SAVE_PUSH_NOTIFICATION_EXTERNAL_ID]: (state: any, pushNotificationExternalID: string) => {
            state.pushNotificationExternalID = pushNotificationExternalID;
        },
        [REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET]: (state: any) => {
            state.pushNotificationExternalID = null;
        },
        [SET_TEAMS_CONVERSATION_REFERENCE]: (state: any, teamsConversationReference: string) => {
            state.teamsConversationReference = teamsConversationReference;
        },
        [PREPOPULATE_EXTERNALTOOL_REFERENCE]: (state: any, externalToolReference: string) => {
            state.externalToolReference = externalToolReference;
        },
        [FROM_EXTERNALTOOL]: (state: any, externalTool: ExternalToolEnum) => {
            state.externalTool = externalTool;
        },
    },
    getters: {
        getCurrentPushTokenExternalID: (state: any): string | null => {
            return state.pushNotificationExternalID;
        },
        pushNotificationTokens: (state: any): PushNotificationToken[] => {
            return store.getters.privateUserOptions
                .filter((option: PrivateUserOption) => option.key === PrivateUserOptionKeyEnum.PUSH_NOTIFICATION_TOKEN)
                .map((option: PrivateUserOption) => new PushNotificationToken(option.value, option.externalId));
        },
        getPrepopulatedExternalToolReference: (state: any): string[] => {
            return state.externalToolReference;
        },
        getExternalTool: (state: any): ExternalToolEnum => {
            return state.externalTool;
        },
    },
    actions: {
        async [BIND_EXTERNAL_ACCOUNT_REQUEST]({commit, dispatch}: { commit: any, dispatch: any },
                                              {code, stateID, redirectUri, externalTool, state, externalToolReference}
                                                  : { code: string, stateID: string, redirectUri: string, state: string, externalTool: ExternalToolEnum, externalToolReference: string }) {
            return await axios.post(integrationAPI + '/bindAccount', {
                code: code,
                stateID: stateID,
                redirectUri: redirectUri,
                state: state,
                externalTool: externalTool,
                externalToolReference: externalToolReference
            })
                .then((res: any) => {
                    log.info(externalTool + ' account successfully bound!');
                    return res;
                })
                .catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
        },
        async [SAVE_PUSH_NOTIFICATION_EXTERNAL_ID](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            notificationToken: PushNotificationToken
        ) {
            return await axios.post(pushNotificationAPI, {
                pushNotificationToken: notificationToken.pushNotificationToken,
                externalId: notificationToken.externalId,
            })
                .then((res) => {
                    dispatch(ADD_PUSH_NOTIFICATION_TOKEN, res.data as PushNotificationToken);
                    commit(SAVE_PUSH_NOTIFICATION_EXTERNAL_ID, res.data.externalId);
                    return res;
                })
                .catch((err) => {
                    // If error, we force the switch button to false
                    const params = new SettingUserParams(SettingUsersKey.WEB_PUSH_NOTIFICATION, false);
                    dispatch(SET_SETTINGUSER, params).then(() => {
                        setActionEvent(ActionEvent.profil_change_web_push_notif,
                            {webPushNotification: false});
                    });
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
        },
        async [REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            pushNotificationToken: PushNotificationToken
        ) {
            log.info('REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET' + pushNotificationToken);
            if (pushNotificationToken) {
                log.info('Revoking expired push notification key ' + pushNotificationToken.pushNotificationToken);
                commit(REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET);
                return await axios.delete(pushNotificationAPI, {
                    data: {pushNotificationToken: pushNotificationToken.pushNotificationToken}
                })
                    .then(async (res: any) => {
                        await dispatch(REMOVE_PUSH_NOTIFICATION_TOKEN, pushNotificationToken);
                        commit(SAVE_PUSH_NOTIFICATION_EXTERNAL_ID, null);
                        return res;
                    })
                    .catch((err) => {
                        if (err.data) {
                            err = err.data;
                        }
                        throw err;
                    });
            }
        },
        [REMOVE_ALL_PUSH_NOTIFICATION_TOKEN](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }
        ) {
            log.info('REVOKE_ALL_PUSH_NOTIFICATION_TOKEN_FROM_WEET');
            const pushNotificationTokens: PushNotificationToken[] = getters.pushNotificationTokens;
            if (pushNotificationTokens && pushNotificationTokens.length > 0) {
                pushNotificationTokens.forEach((token) => {
                    dispatch(REMOVE_PUSH_NOTIFICATION_TOKEN, token);
                    dispatch(REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET, token);
                });
            }
        },
        async [DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        ) {
            if(isSpeach()){
                return ;
            }
            // @ts-ignore
            return await Vue.$messaging.deleteToken()
                .then((res: any) => {
                    log.debug('Push Token has been unregister in firebase');
                    commit(SAVE_PUSH_NOTIFICATION_EXTERNAL_ID, null);
                    return res;
                })
                .catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
        },
        [ADD_PUSH_NOTIFICATION_TOKEN](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            pushNotificationToken: PushNotificationToken
        ) {
            const option = new PrivateUserOption(
                PrivateUserOptionKeyEnum.PUSH_NOTIFICATION_TOKEN,
                pushNotificationToken.externalId,
                pushNotificationToken.pushNotificationToken,
                (store.getters.userConnected as User).userID
            );
            dispatch(ADD_PRIVATE_USER_OPTION, option);
        },
        [REMOVE_PUSH_NOTIFICATION_TOKEN](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            pushNotificationToken: PushNotificationToken
        ) {
            const option = new PrivateUserOption(
                PrivateUserOptionKeyEnum.PUSH_NOTIFICATION_TOKEN,
                pushNotificationToken.externalId,
                pushNotificationToken.pushNotificationToken,
                (store.getters.userConnected as User).userID
            );
            store.dispatch(REMOVE_PRIVATE_USER_OPTION, option);
        },
        /**
         * Initializing Microsoft Teams SDK, this action should be dispatched before all other calls to MsTeams SDK.
         * @param commit
         * @param dispatch
         * @param getters
         */
        [EXTERNAL_TOOL_INIT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        ) {
            try {
                app.initialize().catch((e) => {
                    log.debug("Teams:" + e.message)
                });
                app.notifySuccess();
            } catch (e) {
                log.warn('MSTeams SDK initialization failed ' + e);
            }
        },
        [EXTERNAL_TOOL_AUTH_FAILURE](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, error: any
        ) {
            log.error('EXTERNAL_TOOL_AUTH_FAILURE: ' + error);
            try {
                microsoftTeams.authentication.notifyFailure(error);
            } catch (e) {
                log.error('notifyFailure failed' + e);
            }
        },
        [EXTERNAL_TOOL_AUTH_SUCCESS](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        ) {
            try {
                microsoftTeams.authentication.notifySuccess();
                app.getContext().then((context) => {
                    // If we are in MsTeams via browser or desktop, we close the iframe
                    if (context !== null && context !== undefined) {
                        window.close();
                    }
                });
                dialog.url.submit(undefined); // close the windows if iframe in teams
            } catch (e) {
                log.error('EXTERNAL_TOOL_AUTH_SUCCESS failed' + e);
            }
        },
        [EXTERNAL_TOOL_SEND_MESSAGE](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, message: any
        ) {
            try {
                dialog.url.submit(message);
                app.getContext().then((context) => {
                    // If we are in MsTeams via browser (full integration), we close the iframe
                    if (context !== null && context !== undefined) {
                        window.close();
                    }
                });
            } catch (e: any) {
                if (store.getters.getExternalTool === ExternalToolEnum.TEAMS) {
                    app.notifyFailure({reason: e.code, message: e.message});
                    log.error(e);
                }
                log.warn(e);
            }
        },
        async [NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, input: NotifyWeetCreation
        ) {
            if (input.externalTool && input.externalToolReference && input.weetId) {
                return await axios.post(`${integrationAPI}/weet/${input.weetId}?externalTool=${input.externalTool}`, {
                    externalToolReference: input.externalToolReference,
                }).then(async (res: any) => {
                    log.info('Weet created Notification successfully sent');
                    // Reinit prepopulated values
                    dispatch(PREPOPULATE_EXTERNALTOOL_REFERENCE, null);
                    dispatch(PREPOPULATE_WEET_TITLE, null);
                    dispatch(PREPOPULATE_SHARED_EMAILS, null);
                    return res;
                }).catch((err) => {
                    dispatch(PREPOPULATE_EXTERNALTOOL_REFERENCE, null);
                    dispatch(PREPOPULATE_WEET_TITLE, null);
                    dispatch(PREPOPULATE_SHARED_EMAILS, null);
                    if (err.data) {
                        err = err.data;
                    }
                    log.error(`Error when notifying weet creation to external tool with params ${JSON.stringify(input)}`);
                    log.error(err);
                    return err;
                });
            }
        },
        async [CHECK_CONNECTED_EXTERNAL_USER](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, {
                userID,
                teamID,
                externalTool
            }: { userID: string, teamID: string, externalTool: ExternalToolEnum }
        ) {
            return await axios.get(`${integrationAPI}/checkUser?userID=${userID}&teamID=${teamID}&externalTool=${externalTool}`)
                .then(async (res: any) => {
                    return res;
                }).catch((err) => {
                    if (err.data) {
                        err = err.data;
                    }
                    throw err;
                });
        },
        [PREPOPULATE_EXTERNALTOOL_REFERENCE](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            externalToolReference: string
        ) {
            commit(PREPOPULATE_EXTERNALTOOL_REFERENCE, externalToolReference);
        },
        [FROM_EXTERNALTOOL](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            externalTool: ExternalToolEnum
        ) {
            commit(FROM_EXTERNALTOOL, externalTool);
        }
    }

};
