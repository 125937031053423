import axios from 'axios';

import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    CONNECTION_UPLOAD_PROBLEM,
    MEDIA_CHUNK_UPLOAD_START,
    MEDIA_UPLOAD_END,
    MEDIA_UPLOAD_PROGRESS,
    MEDIA_UPLOAD_START, MediaMetadata,
    MediaUploadChunkParams,
    MediaUploadParams, UPLOAD_FILE_DESCRIPTION,
} from '@/store/upload/uploadAction';
import Vue from 'vue';
import {UploadMedia} from '@/store/upload/uploadMediaModel';
import {MediaIDUpdateParams} from '@/store/timeLine/timeLineAction';
import {log} from '@/utils/log';
import delay from 'delay';

const mediaAPI = BACK_ENDPOINT + '/media';
const mediaAPIChunk = mediaAPI + '/chunk';

export default {
    state: {
        mediaUploads: {},
        connectionProblem: false,
        uploadFile: null,
    },
    mutations: {
        [MEDIA_UPLOAD_START]: (state: any, uploadMedia: UploadMedia) => {
            Vue.set(state.mediaUploads, uploadMedia.mediaId, uploadMedia);
        },
        [MEDIA_UPLOAD_PROGRESS]: (state: any, uploadMedia: UploadMedia) => {
            // Update the progress status
            Vue.set(state.mediaUploads, uploadMedia.mediaId, uploadMedia);
        },
        [MEDIA_UPLOAD_END]: (state: any, uploadMedia: UploadMedia) => {
            // remove table
            Vue.delete(state.mediaUploads, uploadMedia.mediaId);
        },
        [CONNECTION_UPLOAD_PROBLEM]: (state: any, params: boolean) => {
            // remove table
            state.connectionProblem = params;
        },
        [UPLOAD_FILE_DESCRIPTION]: (state: any, params: File) => {
            // remove table
            state.uploadFile = params;
        }
    },
    getters: {
        isSychronisationInProgress: (state: any): boolean => {
            return Object.values(state.mediaUploads).length > 0;
        },
        getUploadMedias: (state: any): UploadMedia[] => {
            return Object.values(state.mediaUploads);
        },
        getUploadMedia: (state: any) => (mediaID: string): UploadMedia[] => {
            const listOfMedia: UploadMedia[] = [];
            for (const mediaUpload of Object.values(state.mediaUploads) as UploadMedia[]) {
                if (mediaUpload.mediaId === mediaID) {
                    listOfMedia.push(mediaUpload);
                }
            }
            return listOfMedia;
        },
        isConnectionProblem: (state: any): boolean => {
            return state.connectionProblem;
        },
        getUploadFile: (state: any): File => {
            return state.uploadFile;
        },
    },
    actions: {
        async [MEDIA_UPLOAD_START]({commit, dispatch}: { commit: any, dispatch: any }, params: MediaUploadParams) {

            // download blob from url
            const uploadMedia = new UploadMedia();
            uploadMedia.blobUrl = params.media.blobURL;
            uploadMedia.mediaId = params.media.mediaID;
            // register the process
            commit(MEDIA_UPLOAD_START, uploadMedia);

            // we get the blob
            const blob = await fetch(params.media.blobURL).then((data) => data.blob());
            const formData = new FormData();
            formData.append('media', blob);
            formData.append('type', params.media.type);
            const response = await axios({
                url: mediaAPI,
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
                onUploadProgress: (p) => {
                    const progressUploadMedia = new UploadMedia();
                    progressUploadMedia.mediaId = uploadMedia.mediaId;
                    progressUploadMedia.blobUrl = uploadMedia.blobUrl;
                    progressUploadMedia.byte = p.loaded;
                    progressUploadMedia.totalByte = p.total;
                    progressUploadMedia.pourcent = Math.round(p.loaded / p.total * 100);
                    dispatch(MEDIA_UPLOAD_PROGRESS, progressUploadMedia);
                },
            }).then((resp) => {
                // get mediaIn Data
                // var media=resp.data as Media;
                // now we commit the Media and remove the MediaUpload
                // we launch the update of media
                commit(MEDIA_UPLOAD_END, uploadMedia);
                const result = new MediaIDUpdateParams(uploadMedia.mediaId, resp.data.mediaID);
                return result;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;

            });
            return response;
        },
        async [MEDIA_UPLOAD_PROGRESS]({commit, dispatch}: { commit: any, dispatch: any }, params: UploadMedia) {
            commit(MEDIA_UPLOAD_PROGRESS, params);
        },
        async [MEDIA_CHUNK_UPLOAD_START]({
                                             commit,
                                             dispatch
                                         }: { commit: any, dispatch: any },
                                         params: MediaUploadChunkParams) {

            // download blob from url
            const uploadMedia = new UploadMedia();
            uploadMedia.blob = params.chunkMedia.blob;
            uploadMedia.mediaId = params.chunkMedia.mediaID;
            // register the process
            commit(MEDIA_UPLOAD_START, uploadMedia);

            const formData = new FormData();
            formData.append('chunk', uploadMedia.blob);
            formData.append('type', params.chunkMedia.type);
            formData.append('mediaID', params.chunkMedia.mediaID);
            formData.append('chunkPart', params.chunkMedia.partNumber.toString());
            formData.append('lastPart', '' + params.chunkMedia.lastPart);
            formData.append('noiseCancelation', '' + params.chunkMedia.noiseCancelation)
            formData.append('transcoding', '' + params.chunkMedia.transcoding)
            formData.append('maxTime', '' + params.chunkMedia.maxTime)

            const response = await axios({
                url: mediaAPIChunk,
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
                onUploadProgress: (p) => {
                    const progressUploadMedia = new UploadMedia();
                    progressUploadMedia.mediaId = uploadMedia.mediaId;
                    progressUploadMedia.blob = uploadMedia.blob;
                    progressUploadMedia.byte = p.loaded;
                    progressUploadMedia.totalByte = p.total;
                    progressUploadMedia.pourcent = Math.round(p.loaded / p.total * 100);
                    log.debug('progress upload' + progressUploadMedia.pourcent + '%');
                    dispatch(MEDIA_UPLOAD_PROGRESS, progressUploadMedia);
                    commit(CONNECTION_UPLOAD_PROBLEM, false);
                },
            }).then((resp) => {
                commit(CONNECTION_UPLOAD_PROBLEM, false);
                // get mediaIn Data
                // var media=resp.data as Media;
                // now we commit the Media and remove the MediaUpload
                // we launch the update of media
                commit(MEDIA_UPLOAD_END, uploadMedia);
                return new MediaIDUpdateParams(uploadMedia.mediaId, resp.data.video);
            }).catch(async (err) => {
                commit(CONNECTION_UPLOAD_PROBLEM, true);
                // we throw the error, and cancel the media...
                // the video will certainly broken... but maybe it's is not the better part
                console.error('Something wrong during the upload , please report to support', err);
                console.error('relaunch chunk in 2s');
                await delay(2000);
                return await dispatch(MEDIA_CHUNK_UPLOAD_START, params);

                // commit(MEDIA_UPLOAD_END, uploadMedia);
                // throw err;
            });
            return response;
        },

        [CONNECTION_UPLOAD_PROBLEM]({commit, dispatch}: { commit: any, dispatch: any }, params: boolean) {
            commit(CONNECTION_UPLOAD_PROBLEM, params);
        },
        [UPLOAD_FILE_DESCRIPTION]({commit, dispatch}: { commit: any, dispatch: any }, params: File) {
            commit(UPLOAD_FILE_DESCRIPTION, params);
        },
    },
};
