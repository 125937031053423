export const GET_MY_WORKSPACE_CHANNELS = 'GET_MY_WORKSPACE_CHANNELS';
export const CREATE_CHANNEL_TO_WORKSPACE = 'CREATE_CHANNEL_TO_WORKSPACE';
export const DELETE_CHANNEL_FROM_WORKSPACE = 'DELETE_CHANNEL_FROM_WORKSPACE';
export const REMOVE_CHANNEL_FROM_STORE = 'REMOVE_CHANNEL_FROM_STORE';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const SELECT_WORKSPACE_CHANNEL = 'SELECT_WORKSPACE_CHANNEL';
export const REINIT_CHANNEL_FOR_WORKSPACE = 'REINIT_CHANNEL_FOR_WORKSPACE';
export const GET_MY_CHANNEL_SUBSCRIPTION = 'GET_MY_CHANNEL_SUBSCRIPTION';
export const UNSUBSCRIBE_TO_A_CHANNEL = 'UNSUBSCRIBE_TO_A_CHANNEL';
export const SUBSCRIBE_TO_A_CHANNEL = 'SUBSCRIBE_TO_A_CHANNEL';
export const GET_LIST_OF_SUBSCRIBER = 'GET_LIST_OF_SUBSCRIBER';
export const SILENCE_CHANNEL = 'SILENCE_CHANNEL';






