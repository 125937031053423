import {User} from '@/store/user/userModel';
import store from '@/store';
import {UPDATE_MY_IMAGE} from '@/store/user/myUserAction';

/**
 * Gernerate an blob image of 512x512 with letter in center
 * @param twoLetters
 */
export const generateAvatartForText = async (twoLetters: string) => {
    const size = 512;
    const canvas = new OffscreenCanvas(size, size);
    const context = canvas.getContext('2d');
    if (context) {
        const grd = context.createLinearGradient(0, 0, size, size);
        grd.addColorStop(0, '#D6D4F8');
        grd.addColorStop(1, '#D6D4F8');

        // Fill with gradient
        context.fillStyle = grd;
        context.fillRect(0, 0, size, size);

        // now we write the letter
        context.font = '500 280px Avenir, Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        context.fillStyle = '#56548d';
        // context.shadowColor = 'black';
        // context.shadowBlur = 27;
        context.lineWidth = 25;
        context.fillText(twoLetters.toUpperCase(), size / 2, 360);
    }
    return await canvas.convertToBlob({
        type: 'image/jpeg',
        quality: 0.95
    });
};

export const checkIfAvatarMustBeGenerated = async (user: User) => {
    if (!user.imageURL) {
        let letters = '';
        // get text
        if (user.firstName.length > 0) {
            letters += user.firstName.substr(0, 1);
        }
        const blob = await generateAvatartForText(letters);
        store.dispatch(UPDATE_MY_IMAGE, blob);
    }
};
