import * as EBML from 'ts-ebml';
import {log} from '@/utils/log';

export const getSeekableBlob = (inputBlob) => {
    return new Promise((resolve, reject) => {
        const reader = new EBML.Reader();
        const decoder = new EBML.Decoder();
        const tools = EBML.tools;
        const fileReader = new FileReader();
        // on blob loaded
        fileReader.onload = (e) => {
            if (fileReader.result === null) {
                log.error('Error on reading blob, seems empty');
                reject('Error on reading blob, seems empty');
                return;
            }
            try {
                const ebmlElms = decoder.decode(fileReader.result as ArrayBuffer);
                ebmlElms.forEach((element) => {
                    // @ts-ignore
                    if (element.type !== 'unknown') {
                        reader.read(element);
                    }

                });
                reader.stop();
                const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
                const body = fileReader.result.slice(reader.metadataSize);
                const newBlob = new Blob([refinedMetadataBuf, body], {
                    type: 'video/webm'
                });
                log.info('Seeking is now aivailable');
                resolve(newBlob);
            } catch (e: any) {
                reject('Error on parsing blob, ' + e.message + ' , ' + e.error);
            }
        };
        fileReader.readAsArrayBuffer(inputBlob);
    });
};


/**
 * Compute how many second the buffering is in advance
 * @param video
 */
export const getBufferingAdvance = (video: HTMLVideoElement) => {
    const time = video.currentTime;
    const buffer = video.buffered;
    let valueAdvance = 0;
    // select the good range
    for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= time && time <= buffer.end(i)) {
            valueAdvance = buffer.end(i) - time;
        }
    }
    return valueAdvance;
};
