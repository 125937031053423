export const CHANGE_MY_PASSWORD = 'CHANGE_PASSWORD';
export const DISCONNECT_ALL_SESSIONS = 'DISCONNECT_ALL_SESSIONS';
export const UPDATE_MY_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_MY_IMAGE = 'UPDATE_MY_IMAGE';
export const DELETE_MY_ACCOUNT = 'DELETE_MY_ACCOUNT';
export const CAN_DELETE_MY_ACCOUNT = 'CAN_DELETE_MY_ACCOUNT';


export class PasswordChangeParam {
  public oldPassword: string;
  public password: string;
  public repassword: string;

  constructor(oldPassword: string, password: string, repassword: string) {
    this.oldPassword = oldPassword;
    this.password = password;
    this.repassword = repassword;
  }
}


export class ProfilUpdateParams {
  public login: string;
  public firstName: string;
  public lastName: string;

  constructor(login: string, firstName: string, lastName: string) {
    this.login = login;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}


