<template>
  <div class="weetAdvancedEditingSubtitleContainer">
    <GoToSubtitleWeetButton  v-if="isSubtitleFeatureAvailable" class="iconButton" icon="subtitles-outline" ref="subtitleOpener"
                             :tooltip="$t('createWeetComponent.advancedEditing.tooltip.subtitles')"
                             color="primary"
                             :weet="weet">
      {{ $t('createWeetComponent.advancedEditing.button.subtitleClickMandatory') }}
    </GoToSubtitleWeetButton>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import {PREVIEW_MODE, TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {SEEK_PLAYER, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import {
  getDurationOfTimeLine,
} from '@/utils/timeLineUtils';
import prettyMilliseconds from 'pretty-ms';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import {clone, getImageURLForSize} from '@/utils/util';
import {ThumbnailGenerator} from '@/utils/video/videoEffect/ThumbnailGenerator';
import debounce from 'lodash/debounce';
import WSwitch from '@/components/wrapper/w-switch.vue';
import UpgradePlanButton from '@/components/upgradePlan/UpgradePlanButton.vue';
import UpgradePlanTagButton from '@/components/upgradePlan/UpgradePlanTagButton.vue';
import {PaymentPlanEnum, Workspace} from '@/store/workspace/workspaceModel';
import {UPDATE_MY_WEET_IMAGE} from '@/store/myWeet/myWeetAction';
import WModal from '@/components/wrapper/w-modal.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {inform} from '@/utils/dialog';
import ThumbnailCropEditor from '@/components/recorder/thumbnailGenerator/ThumbnailCropEditor.vue';
import delay from 'delay';
import GoToSubtitleWeetButton from "@/components/actionButton/GoToSubtitleWeetButton.vue";


@Component({
  components: {
    GoToSubtitleWeetButton
  },
})
export default class CreateWeetSubtitleMode extends Vue {

  @Prop()
  private weet!: Weet;

  get isSubtitleFeatureAvailable(): boolean{
    return store.getters.isTranscript
  }

  get isSubtitleMode(): boolean {
    return store.getters.isSubtitleMode;
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingSubtitleContainer {
  margin: auto;
  display: flex;
  min-height: 200px;
  justify-content: center;

  align-items: center;
}
</style>
