<template>
  <Transition name="fade">
    <CenterCenterPanel>
      <div class="workspaceContainer" v-if="!showAddSeatsNeededToWorkspace">
        <pop-up-title :title="$t('workspace.invite.title').toString()"/>
        <div class="emailInput">
          <div class="emailLabel">{{ $t('workspace.invite.input') }}</div>
          <ShareContactInput :internal-user="false" :channel="false" class="shareContactInput"
                             @userCommit="userAddToSHare" :initialListContactToShare="listContactToShare"/>
          <RoleDropdown :value="role" :invite="true" @clickItem="changeRole" class="dropDownInvite" :expanded="true"/>
          <div class="messageRequireSeats" v-if="!freemiumOrViewer">
            {{
              $t('workspace.invite.requireSeats.message', { role: roleLabel, nbOfSeatsLeft: numberOfSeatsAvailable })
            }}
          </div>
          <w-error :message="error"/>
        </div>
        <div class="buttonWrapper">
          <w-button class="manageBtn" color="light" :enabled="canManageMembers" @click="goToManageUserPage">
            {{ $t('workspace.invite.manageButton') }}
          </w-button>
          <w-button class="sendBtn" color="primary"
                    :enabled="canSendInvite && listContactToShare.length > 0"
                    :loading="isLoadingInvite"
                    @click="checkAndInviteUsers">
            {{ $t('workspace.invite.sendButton') }}
          </w-button>
        </div>
      </div>
      <div class="workspaceContainer" v-if="showAddSeatsNeededToWorkspace">
        <pop-up-title :title="$t('workspace.invite.notEnoughSeats.title').toString()"/>
        <div v-html="canManagePayments ?
            $t('workspace.invite.notEnoughSeats.message', {
          role:roleLabel, nbOfNewSeatsNeeded, amountNow, amountInterval, interval})
          : $t('workspace.invite.notEnoughSeats.contactAdmin', {
              role:roleLabel, nbOfNewSeatsNeeded})">
        </div>
        <w-error :message="error"/>
        <div class="buttonWrapper">
          <w-button class="manageBtn" color="light" @click="showInvites">
            {{ $t('workspace.invite.notEnoughSeats.back') }}
          </w-button>
          <w-button class="sendBtn" color="primary" v-if="canManagePayments"
                    :loading="isLoadingPurchase"
                    @click="purchaseAndInviteUsers">
            {{
              $t('workspace.invite.notEnoughSeats.confirm', {
                nbOfNewSeatsNeeded
              })
            }}
          </w-button>
          <w-button v-else-if="false" class="sendBtn" color="primary"
                    :loading="isLoadingPurchase"
                    @click="purchaseAndInviteUsers">
            {{
              $t('workspace.invite.notEnoughSeats.sendRequest')
            }}
          </w-button>
        </div>
      </div>
    </CenterCenterPanel>
  </Transition>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import ShareContactInput from '@/components/share/ShareContactInput.vue';
import {
  InviteUsersParam,
  WorkspaceGetUserParams
} from '@/store/workspace/workspaceModel';
import store from '@/store';
import {inform} from '@/utils/dialog';
import {
  canSendInvite,
  canManageMembers,
  isFreemium,
  numberOfSeatsAvailable,
  canManagePayments
} from '@/utils/workspaceUtil';
import {
  ADD_USERS_TO_WORKSPACE, GET_WORKSPACE_CREATORS_COUNT,
  GET_WORKSPACE_USER_LIST
} from '@/store/workspace/workspaceAction';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import RoleDropdown from '@/components/workspace/RoleDropdown.vue';
import {WorkspaceUserRoleEnum} from '@/store/workspaceRight/workspaceRightModel';
import {
  GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT,
  GET_WORKSPACE_PAYMENT,
  UPDATE_WORKSPACE_SEAT_COUNT
} from '@/store/payment/paymentAction';
import {AmountNewQuantity} from '@/store/payment/paymentModel';
import {intervalNextPeriod, seatPriceNextPeriod} from '@/utils/paymentUtil';

@Component({
  components: {RoleDropdown, PopUpTitle, CenterCenterPanel, WButton, WError, ShareContactInput, WToolTip},
})
export default class WorkspaceInvite extends Vue {

  private error: string = '';
  private listContactToShare: string[] = [];
  private isLoadingInvite = false;
  private isLoadingPurchase = false;
  private role = WorkspaceUserRoleEnum.CREATOR;
  private roleLabel = this.$t('workspace.manage.creatorRole').toString();
  private showAddSeatsNeededToWorkspace = false;
  private amountNow = 0;
  private nbOfNewSeatsNeeded = 0;

  private mounted() {
    store.dispatch(GET_WORKSPACE_CREATORS_COUNT);
    store.dispatch(GET_WORKSPACE_PAYMENT);
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get canManageMembers(): boolean {
    return canManageMembers();
  }

  get canSendInvite(): boolean {
    return canSendInvite();
  }

  get isFreemium(): boolean {
    return isFreemium();
  }

  get canManagePayments(): boolean {
    return canManagePayments();
  }

  private changeRole(role: WorkspaceUserRoleEnum, roleLabel: string) {
    this.role = role;
    this.roleLabel = roleLabel;
  }

  private userAddToSHare(values: string[]) {
    this.listContactToShare = values;
  }

  private inviteUsers() {
    store.dispatch(ADD_USERS_TO_WORKSPACE, new InviteUsersParam(this.listContactToShare, this.role)).then(() => {
      this.isLoadingInvite = false;
      this.error = '';
      inform(this.$t('workspace.invite.confirmSend').toString());
      this.listContactToShare = [];
      // Reload the users to manage after a new one has been invited
      const workspaceUsersParams = new WorkspaceGetUserParams(store.getters.getCurrentWorkspaceUserPage);
      store.dispatch(GET_WORKSPACE_USER_LIST, workspaceUsersParams);
      store.dispatch(GET_WORKSPACE_CREATORS_COUNT);
      this.onclose();
    }).catch((err) => {
      this.isLoadingInvite = false;
      this.error = err;
    });
    setActionEvent(ActionEvent.workspace_members_add);
  }

  private checkAndInviteUsers() {
    this.isLoadingInvite = true;
    this.nbOfNewSeatsNeeded = this.getNumberOfNewSeatsNeeded;
    if (this.nbOfNewSeatsNeeded === 0) {
      this.inviteUsers();
    } else {
      this.showAddSeatsNeededToWorkspace = true;
      this.isLoadingInvite = false;
      if (this.canManagePayments) {
        this.isLoadingPurchase = true;
        store.dispatch(GET_WORKSPACE_PAYMENT);
        store.dispatch(GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT, {
          seatNumber: this.nbOfNewSeatsNeeded + this.seatCount
        }).then((amountNewQuantity: AmountNewQuantity) => {
          this.amountNow = amountNewQuantity.value / 100;
          this.isLoadingPurchase = false;
        }).catch((err) => {
          this.error = err;
          this.isLoadingPurchase = false;
        });
      }
    }

  }

  private purchaseAndInviteUsers() {
    this.isLoadingPurchase = true;
    store.dispatch(UPDATE_WORKSPACE_SEAT_COUNT, {
      seatNumber: this.nbOfNewSeatsNeeded + this.seatCount,
      amount: this.amountNow * 100
    }).then(() => {
      const confirmationMessage = this.$t('workspace.payment.seats.add.done').toString();
      inform(confirmationMessage);
      this.isLoadingInvite = true;
      this.showAddSeatsNeededToWorkspace = false;
      this.isLoadingPurchase = false;
      this.inviteUsers();
    }).catch((err) => {
      this.error = err;
      this.isLoadingPurchase = false;
    });


  }

  private showInvites() {
    this.showAddSeatsNeededToWorkspace = false;
  }

  private goToManageUserPage() {
    this.onclose();
    this.$router.push({name: 'MyWorkspaceManageMembers'});
  }

  private onclose() {
    this.$emit('close', false);
  }


  get numberOfSeatsAvailable(): number {
    return numberOfSeatsAvailable();
  }

  get seatCount(): number {
    return store.getters.getMyWorkspacePayment.seatCount;
  }

  get amountInterval(): number {
    return seatPriceNextPeriod() * (this.seatCount + this.nbOfNewSeatsNeeded);
  }

  get interval(): string {
    return intervalNextPeriod();
  }

  get freemiumOrViewer(): boolean {
    return isFreemium() || this.role === WorkspaceUserRoleEnum.VIEWER;
  }

  get getNumberOfNewSeatsNeeded(): number {
    if (this.freemiumOrViewer) {
      return 0;
    }
    return Math.max(this.listContactToShare.length - this.numberOfSeatsAvailable, 0);
  }


}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.workspaceContainer {
  max-width: 600px;
  padding: 32px;
  width: 100%;
  height: 100%;

  background: var(--white);
  position: relative;
  border-radius: 8px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  margin: auto;

  .emailInput {
    width: 100%;
    margin-top: 16px;
    flex-direction: column;
    display: flex;

    .emailLabel {
      display: block;
      text-align: left;
      width: 100%;
      color: var(--dark);
    }
  }

  .messageRequireSeats {
    color: var(--light2);
    font-size: 14px;
    text-align: left;
  }

  .dropDownInvite {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
  }

  .dropdown-trigger {
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    .sendBtn {
      justify-content: flex-end;
    }

    .manageBtn {
      justify-content: flex-start;
    }
  }
}

</style>
