export const SUBSCRIBE_TO_PRO_PLAN = 'SUBSCRIBE_TO_PRO_PLAN';
export const GET_MONTHLY_SUBSCRIPTION_PRICE = 'GET_MONTHLY_SUBSCRIPTION_PRICE';
export const GET_YEARLY_SUBSCRIPTION_PRICE = 'GET_YEARLY_SUBSCRIPTION_PRICE';
export const CANCEL_WORKSPACE_SUBSCRIPTION = 'CANCEL_WORKSPACE_SUBSCRIPTION';
export const REACTIVATE_WORKSPACE_SUBSCRIPTION = 'REACTIVATE_WORKSPACE_SUBSCRIPTION';
export const GET_WORKSPACE_PAYMENT = 'GET_WORKSPACE_PAYMENT';
export const CONFIRM_CARD_PAYMENT = 'CONFIRM_CARD_PAYMENT';
export const GET_CUSTOMER_PORTAL_URL = 'GET_CUSTOMER_PORTAL_URL';
export const CHANGE_PRICE_SUBSCRIPTION = 'CHANGE_PRICE_SUBSCRIPTION';
export const GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT = 'GET_AMOUNT_UPDATE_WORKSPACE_SEAT_COUNT';
export const UPDATE_WORKSPACE_SEAT_COUNT = 'UPDATE_WORKSPACE_SEAT_COUNT';
