<template>
  <div>
    <div @click="removeStickerLayout">
      <w-button :icon="iconStickerRemoved" size="small"
                color="text"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {
  RECORD_STICKER_FILTER,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_MUTE,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIDEO_MUTE,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {ActionEvent} from '@/enum/TrackerEnum';


@Component({
  components: {WButton, VideoSource},
  computed: {},
})
export default class StickerRemoveStickerButton extends Vue {

  get isVideoMute(): boolean {
    return store.getters.isStickerVideoMute;
  }

  get isStickerRemoved(): boolean {
    return this.stickerPosition === StickerPosition.NONE;
  }

  get iconStickerRemoved(): string {
    if (this.isStickerRemoved) {
      return 'account-off-outline';
    } else {
      return 'account-outline';
    }
  }

  get stickerPosition(): StickerPosition {
    return store.getters.getStickerPosition;
  }

  public removeStickerLayout() {
    if (this.isStickerRemoved) {
      store.dispatch(RECORD_STICKER_POSITION, StickerPosition.BOTTOM_LEFT);
    } else {
      store.dispatch(RECORD_STICKER_POSITION, StickerPosition.NONE);
    }
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';


</style>
