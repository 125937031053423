<template>
  <div>
    <div class="timeLineZoomSelector">
      <div class="timeLineZoomCursorConteneur">
        <div class="timeLineZoomCursor" :style="cursorLeft"></div>
      </div>
      <div class="timelineZoom" ref="noUiSliderZoomContainer"></div>
      <div class="timeLineZoomImageContainer">
        <img alt="thumbnail timeline Preview" class="imageBackground" :style="'left:'+((index-1)*(100/NB_IMAGE_BG))+'%'"
             :src="getImageForPosition(index)"
             v-for="index in NB_IMAGE_BG"
             :key="'imageTimeLineZoom'+index"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import noUiSlider from 'nouislider';
import {
  getDurationOfTimeLine,
  getFirstMediaPlayEvent,
  getMediaForTimeEvent,
  getTimeEventbeforeTime,
} from '@/utils/timeLineUtils';
import store from '@/store';
import {TimeEventType} from '@/enum/TimeEventType';
import {clone, getThumbnailURL} from '@/utils/util';
import {Weet} from '@/store/weet/weetModel';
import {
  SEEK_PLAYER,
  TIMER_PLAYER,
} from '@/store/timeLine/timeLineAction';
import WButton from '@/components/wrapper/w-button.vue';
import delay from 'delay';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WButton},
})
export default class TimeLineZoomSelector extends Vue {

  @Prop({})
  private weet!: Weet;

  private NB_IMAGE_BG = 16;
  private range: { min: number, max: number } = {min: 0, max: 100};
  private initialised = false;
  private currentZoom = [0, 60000];

  /**
   * Get the slider component
   * @private
   */
  private slider(): HTMLDivElement {
    return this.$refs.noUiSliderZoomContainer as HTMLDivElement;
  }


  public decreaseZoom() {
    // get 10% of timeLine
    const fivePourc = 0.05 * this.durationOfTimeLine;
    let endZoom = this.currentZoom[1] + fivePourc;
    let startZoom = this.currentZoom[0];
    if (endZoom > this.durationOfTimeLine) {
      endZoom = this.durationOfTimeLine;
      startZoom = startZoom - fivePourc;
    }
    Vue.set(this.currentZoom, 1, Math.round(endZoom));
    Vue.set(this.currentZoom, 0, Math.round(startZoom));

    this.$emit('changeZoom', this.currentZoom);
    this.updateSlider();
  }

  public increaseZoom() {
    const fivePourc = 0.05 * this.durationOfTimeLine;
    const endZoom = this.currentZoom[1] - fivePourc;
    if (endZoom - this.currentZoom[0] < 1000) {
      return;
    }
    Vue.set(this.currentZoom, 1, Math.round(endZoom));

    this.$emit('changeZoom', this.currentZoom);
    this.updateSlider();
  }

  public autoZoom() {
    Vue.set(this.currentZoom, 0, 0);
    Vue.set(this.currentZoom, 1, 30000);
    this.$emit('changeZoom', this.currentZoom);
    this.updateSlider();
  }

  public resetZoom() {
    Vue.set(this.currentZoom, 0, 0);
    Vue.set(this.currentZoom, 1, this.durationOfTimeLine);
    this.$emit('changeZoom', this.currentZoom);
    this.updateSlider();
  }

  private changePlayerTime(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
  }

  get durationOfTimeLine() {
    return getDurationOfTimeLine(this.weet.timeLine);
  }


  /**
   * Compute the time when click on the component
   * @param x
   * @private
   */
  private timeForX(x: number): number {
    return Math.round(x / this.slider().offsetWidth * getDurationOfTimeLine(this.weet.timeLine));
  }

  /**
   * Get image illustration of the timeLine
   * @param index
   * @private
   */
  private getImageForPosition(index: number): string {
    const time = Math.round(getDurationOfTimeLine(this.weet.timeLine) / this.NB_IMAGE_BG * index);
    const timeEvent = getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY);
    if (timeEvent) {
      const media = getMediaForTimeEvent(this.weet, timeEvent, true);
      if (media) {
        if (media.imageBlobURL) {
          return media.imageBlobURL;
        } else {
          if (media.status === 'HD') {
            return getThumbnailURL(media.jobID, true);
          } else {
            return '/avatar_min.jpg';
          }
        }

      }
    }
    return '';
  }


  private updateTimeLine(values, handle, unencoded, tap, positions, noSlider) {
    this.currentZoom = values;
    this.$emit('changeZoom', values);
  }

  private async updatePlayer(values, handle, unencoded, tap, positions, noSlider) {
    this.updateTimeLine(values, handle, unencoded, tap, positions, noSlider);
    await delay(500);
    const offset = (this.currentZoom[1] - this.currentZoom[0]) / 2;
    const startTime = Math.round(this.currentZoom[0]);

    // must be in the moddle
    const time = Math.round(startTime + offset);
    this.changePlayerTime(time);

    // amplitude
    setActionEvent(ActionEvent.timeline_selector_move_zoom);
  }

  get timerPlayer() {
    return store.getters.getTimerPlayer;
  }

  get isCutMode() {
    return store.getters.isCutMode;
  }

  get isCutterMode() {
    return store.getters.isCutterMode;
  }

  get isMergeMode() {
    return store.getters.isMergeMode;
  }

  /**
   * Compute the position of the timer
   */
  get cursorLeft() {
    const time = this.timerPlayer;
    const duration = getDurationOfTimeLine(this.weet.timeLine);
    let pourc = time / duration * 100;
    if (pourc > 100) {
      pourc = 100;
    }
    return 'left:' + pourc + '%';
  }


  private mounted() {
    this.computeRange();
  }

  @Watch('timerPlayer')
  private changePositionZoom() {
    const time = this.timerPlayer;
    const duration = getDurationOfTimeLine(this.weet.timeLine);
    const delta = this.currentZoom[1] - this.currentZoom[0];
    // must be in the moddle
    const offset = delta / 2;
    this.currentZoom[0] = time - offset;
    this.currentZoom[1] = time + offset;
    if (this.currentZoom[0] < 0) {
      this.currentZoom[0] = 0;
      this.currentZoom[1] = delta;
    }
    if (this.currentZoom[1] > duration) {
      this.currentZoom[1] = duration;
      this.currentZoom[0] = duration - delta;
    }
    this.updateSlider();
  }

  /**
   * Compute the range of the timeline
   * @private
   */
  private computeRange() {
    if (this.weet.timeLine.length <= 1) {
      this.range.min = 0;
      this.range.max = 1;
    } else {
      const min = getFirstMediaPlayEvent(this.weet.timeLine)?.time || 0;
      // we remove the end to not delete the last event (mediaPAUSE)
      const max = getDurationOfTimeLine(this.weet.timeLine) - 1;
      this.range.min = min;
      this.range.max = max;
      this.currentZoom = [min, max];
    }
    this.updateSlider();
  }

  private initSlider() {
    const options: any = {};
    // compute the section
    const start: number[] = this.currentZoom;
    const connect: boolean[] = [false, true, false];
    options.start = start;
    options.connect = connect;
    options.range = this.range;
    options.margin = 1000; // cut minimum
    options.behaviour = 'drag';
    options.tooltips = false;
    options.pips = false;

    if (start.length > 0) {
      // @ts-ignore
      noUiSlider.create(this.slider(), options);
      // @ts-ignore
      this.slider().noUiSlider.on('slide', this.updateTimeLine);
      // @ts-ignore
      this.slider().noUiSlider.on('change', this.updatePlayer);

    }

    this.$nextTick(() => {
      this.addConnectorClassSelector();
    });
    this.initialised = true;
  }

  private addConnectorClassSelector() {
    const slider = this.slider();
    const connectors = slider.querySelectorAll('.noUi-origin');
    let left = true;
    for (const connect of connectors) {
      if (left) {
        connect.className += ' left';
      } else {
        connect.className += ' right';
      }
      left = !left;
    }
  }


  private destroySlider() {
    if (// @ts-ignore
        this.slider().noUiSlider) {
      // @ts-ignore
      this.slider().noUiSlider.destroy();
    }
  }

  private updateSlider() {
    this.destroySlider();
    this.initSlider();

  }

  private beforeDestroy() {
    this.destroySlider();
  }
}
</script>

<style lang="scss">
//Customisation du composant noUiSlider
// pour gerer une timeline
@import '@/scss/shadows.scss';


.timeLineZoomSelector {
  position: relative;
  height: 32px;

  .timeLineZoomImageContainer {
    position: absolute;
    top: 0px;
    left: 16px;
    height: calc(32px);
    width: calc(100% - 32px);
    overflow: hidden;
    background: var(--light);
    transition: opacity 0.2s ease;
    border-radius: 4px;
    @extend .shadow1;
    filter: grayscale(50%) contrast(0.5);
    opacity: 0.5;

    .imageBackground {
      height: 100%;
      position: absolute;
      top: 0;
    }

    &:hover {
    }
  }

  // GESTINO TIMELINE
  .timelineZoom {
    height: 32px;
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    top: 0px;
    // cursor: url(@/assets/icons/pointer.png), auto;
    cursor: col-resize;
    // Background of the slider
    &.noUi-target {
      background: none;
      border: none;
      box-shadow: none;
      height: 56px;
    }

    .noUi-base {
      top: 20px;
      height: 0px;

      .noUi-connects {
        overflow: visible;

        .noUi-connect {
          background: none;
          height: 32px;
          margin-top: -20px;
          // background: rgba(150, 0, 255, 0.5);
          // backdrop-filter: saturate(150%);
          backdrop-filter: contrast(2.0) saturate(150%);
          box-shadow: 0px 0px 5px 2px var(--primary);

          &:before {
            content: "";
            position: absolute;
            top: 0px;

          }

        }
      }
    }


    .noUi-origin {
      .noUi-handle {
        border: 1px solid var(--dark);
        border-radius: 50%;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 4.5px;
        top: -16px;
        left: 40px;
        background: var(--light1);
        transition: all 0.1s linear;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        opacity: 0;


        &:hover {
          border: 1px solid var(--primary);
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
          // z-index: 100 !important;
          .noUi-tooltip {
            opacity: 1;
          }
        }

        &:focus {
          outline: none;
        }
      }

      &.left {
        .noUi-handle {
          background-image: url(@/assets/icons/pointer_left.png);

          background-repeat: no-repeat;
          background-size: 12px 12px;
          background-position: center center;
        }
      }

      &.right {
        .noUi-handle {
          background-image: url(@/assets/icons/pointer_right.png);
          background-repeat: no-repeat;
          background-size: 12px 12px;
          background-position: center center;
        }
      }
    }

    &:hover {
      .noUi-origin {

        .noUi-handle {
          opacity: 1;
        }
      }
    }
  }

  // GESTION CURSOR
  .timeLineZoomCursorConteneur {
    height: 0px;
    cursor: pointer;
    background: var(--light);
    border-radius: 4px;
    left: 16px;
    width: calc(100% - 32px);
    position: relative;

    .timeLineZoomCursor {
      pointer-events: none;
      will-change: contents;
      position: absolute;
      height: 32px;
      border: 2px solid white;
      @extend .shadow1;
      width: 0px;
      top: 0px;
      z-index: 2;
      transition: left 0.2s linear;
    }
  }


}

</style>
