<template>
  <VueDraggableResizable :parent="true" :resizable="false" :draggable="isEditable"
                         :w="1" :h="1"
                         :x="xInteraction"
                         :y="yInteraction"
                         :class-name="isEditable?'defaultClassDragInteraction':'clickInteraction'"
                         :class-name-dragging="isEditable?'onDragInteraction':''"
                         @dragging="onDragInteraction" @onDrag="onDragInProgress"
                         :z="30" :disableUserSelect="false">
    <transition :name="'zoom'">
      <div class="interactionPosition" v-show="show" @click.stop="openInteraction" :id="'interactionPoint-'+interaction.annotationID">
            <div class="interactionHighLight" :ignoreImageExport="iconMD==='none'" :class="{animate:!isCardAlwaysShow && isContentInCard && !openChooseIcon,editable:isEditable, invisible:iconMD==='none'}">
              <div class="iconIllustration" v-if="iconMD && iconMD!=='none'">
                <w-icon :icon="iconMD" />
              </div>
              <div class="interactionHighLightEdition" @click="openSelectIcon()" v-if="isEditable">
                <w-icon icon="pencil-outline" size="small"/>
              </div>
              <transition name="zoom">
                <div class="chooseIcon" v-if="isEditable" v-show="openChooseIcon" >
                  <div class="headerElement">
                    <div class="labelHighlightBubble">{{$t('createWeetComponent.advancedEditing.interactionMode.highlightBubble')}}</div>
                    <w-help class="iconHelp" :label="$t('createWeetComponent.advancedEditing.interactionMode.highlightBubbleHelp')"></w-help>
                    <w-switch @input="invisibleIcon" :value="interaction.iconMD!='none'" size="small" @click.stop="invisibleIcon"/>
                  </div>
                  <IconsSelector v-if="interaction.iconMD!='none'"  class="containerIcon" @select="changeIcon" size="small"  :annotation="interaction">
                    <!-- Desactivate invisible hightlight -->
<!--                    <div class="noneIcon" @click="changeIcon('none')" v-if="true"></div>-->
                    <div class="defaultIcon" @click="changeIcon('')"></div>
                  </IconsSelector>
                  <div class="closeIcon" @click="openChooseIcon=false">
                    <w-icon icon="close" size="small"/>
                  </div>
                </div>
              </transition>
            </div>

      </div>
    </transition>

    <!-- CARD -->
    <transition-group name="fade" ref="card" class="interactionCard" >
      <div class="interactionLayout" @click="selectCard" v-show="needToShowCard && !openChooseIcon" :id="'interactionCard-'+interaction.annotationID" :key="'effectInteractionLayout'+interaction.annotationID"
           :style="stylePositionCard"
           :class="{drag:needToBeTransparent,loadingPosition:loadingPosition,placeholderCard:needToShowPlaceHolderCard}"
           @mousemove="onMouseEnter()">
        <!-- badge visibility -->
        <transition name="zoom">
          <div class="messageVisibilityCard" v-show="needShowMessageVisibilityCard">
            <w-tool-tip position="top" :label="$t('createWeetComponent.advancedEditing.interactionMode.edit.visibilityHelper')">
              <w-icon icon="message-outline" size="small" class="iconInteract"/>
            </w-tool-tip>
          </div>
        </transition>
        <!-- card PlaceHolder -->
        <div class="cardPlaceHolder" v-if="needToShowPlaceHolderCard" @click="openCard();">
          <w-tool-tip :top-offset="8" :insecureHTML="toolTipPlaceHolderCard"  :position="'top'">
            <w-icon icon="plus" size="default" color="dark"/>
          </w-tool-tip>
        </div>

        <!--ToolBar -->
        <div class="toolbarCard" v-if="(isEditable && cardHover && !dragInProgress)" :class="{left:isLeftDirection,right:!isLeftDirection}">
          <div class="toolBarButton closeButton" @click="onMouseLeave(true)">
            <w-tool-tip :label="$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.close')" :position="isLeftDirection?'left':'right'">
              <w-icon icon="close" />
            </w-tool-tip>
          </div>
          <!--           Erase button -->
          <div class="toolBarButton eraseButton" @click="eraseContent">
            <w-tool-tip :label="$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.erase')" :position="isLeftDirection?'left':'right'">
              <w-icon icon="refresh" />
            </w-tool-tip>
          </div>

        </div>
        <!-- Always visible swithch -->
        <div class="headerEditingCard" v-if="isEditable  && cardHover">
          <div class="labelHighlightBubble">{{$t('createWeetComponent.advancedEditing.interactionMode.alwaysVisible')}}</div>
          <w-help class="iconHelp" :label="$t('createWeetComponent.advancedEditing.interactionMode.alwaysVisibleHelp')"></w-help>
          <w-switch @input="updateInteractionAlwaysShow" :value="!isCardAlwaysShow" size="small"/>
        </div>

        <!-- Card content -->
        <div class="cardInteractionContent" v-show="(isEditable && cardHover)|| isContentInCard">

          <!-- TITLE -->
          <div class="interactionTitle" :contenteditable="isEditable && cardHover" v-show="(isEditable && cardHover) || titleContent"
               :placeholder="$t('createWeetComponent.advancedEditing.interactionMode.edit.placeholderTitle')"
               @focusin="startEdit"
               @focusout="validateInteractionTitleContent" @keydown.enter="focusOutInteractionContent"
               v-html="titleContent"/>
          <!-- IMAGE -->
          <div class="interactionImage" v-show="(isEditable && cardHover)|| urlSourceImage">
            <img rel="preload" alt="image interaction" :src="urlSourceImage"/>
            <b-upload v-if="isEditable" class="uploadElement" :class="{'empty':!urlSourceImage}" drag-drop
                      accept=".png,.jpeg,.jpg,.gif" @input="uploadNewImage">
              <div class="uploadPanel">
                <w-icon color="light2"
                        icon="upload"
                        size="default">
                </w-icon>
                <p class="textUpload">
                  {{ $t('createWeetComponent.advancedEditing.interactionMode.edit.placeholderUpload') }}</p>
              </div>
            </b-upload>
            <div class="iconDeleteImage" v-if="urlSourceImage && cardHover && isEditable" @click.stop="eraseImage">
              <w-icon icon="delete-outline" color="dark" size="small"/>
            </div>
          </div>
          <!-- DESCRIPTION -->
          <div class="interactionDescription" :contenteditable="isEditable && cardHover"
               v-show="(isEditable && cardHover)|| descriptionContent"
               :placeholder="$t('createWeetComponent.advancedEditing.interactionMode.edit.placeholder')"
               @focusin="startEdit"
               @focusout="validateInteractionDescriptionContent" @keydown.enter="focusOutInteractionContent"
               v-html="descriptionContent">
          </div>
          <!-- button -->
          <div class="interactionButton" v-show="(isEditable && cardHover)|| interactionButtonURL"
               :class="{defined:!interactionButtonURL}">
            <div class="fakeButton" v-if="(isEditable && cardHover) && !interactionButtonURL" @click="openModalInteractionURL">
              {{ $t('createWeetComponent.advancedEditing.interactionMode.edit.placeholderButton') }}
            </div>
            <w-button color="primary" size="small" :expanded="true" v-if="interactionButtonURL" @click="isEditable?openModalInteractionURL():openLink()">

              <span v-if="isEditable">
                <span class="labelButtonEdited" >{{ interactionButtonLabel?interactionButtonLabel:$t('createWeetComponent.advancedEditing.interactionMode.edit.interactionButton') }}</span>
                <span class="labelButtonEditedHovered" >{{ $t('createWeetComponent.advancedEditing.interactionMode.edit.interactionButtonEdit') }}</span>
              </span>
              <span v-else>
                {{ interactionButtonLabel?interactionButtonLabel:$t('createWeetComponent.advancedEditing.interactionMode.edit.interactionButton') }}
              </span>
            </w-button>

            <w-modal v-if="isEditable" :active.sync="promptLink" :modal="false">
              <div class="backgroundInteractionEditingModal">
                <div class="containerInterdationEditingForm">
                  <pop-up-title :title="$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.title')"></pop-up-title>
                  <w-input :label="$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.labelInputLabel')"
                  :placeholder="$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.labelInputPlaceholder')"
                           v-model="interactionButtonLabel"/>
                  <w-input :label="$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.linkInputLabel')"
                           :placeholder="$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.linkInputPlaceholder')"
                           v-model="interactionButtonURL"/>
                  <div class="buttonLine">
                    <w-button @click="saveModalInteractionURL">{{ $t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.save') }}</w-button>
                  </div>
                </div>
              </div>
            </w-modal>
          </div>
        </div>
      </div>
    </transition-group>
  </VueDraggableResizable>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Annotation} from "@/store/annotation/annotationModel";
import {Howl} from 'howler';
import VueDraggableResizable from 'vue-draggable-resizable';
import UserAvatar from "@/components/user/UserAvatar.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import store from "@/store";
import {clone, getImageURLForSize, htmliFy, isURL} from "@/utils/util";
import {alertError, alertMessage, confirmationAlert, inform, informError, promptModal} from "@/utils/dialog";
import {SELECT_ANNOTATION, UPDATE_ANNOTATION} from "@/store/annotation/annotationAction";
import {Weet} from "@/store/weet/weetModel";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import CenterCenterPanel from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import WModal from "@/components/wrapper/w-modal.vue";
import delay from "delay";
import debounce from "lodash/debounce";
import WButton from "@/components/wrapper/w-button.vue";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import {PLAYER_PLAYING} from "@/store/timeLine/timeLineAction";
import {ANNOTATION_INTERACTION_SAVE_IN_PROGRESS} from "@/store/recordingState/recordStateAction";
import WInput from "@/components/wrapper/w-input.vue";
import PopUpTitle from "@/components/title/PopUpTitle.vue";
import {PUBLIC_PATH} from "@/store/conf/api";
import WSwitch from "@/components/wrapper/w-switch.vue";
import WHelp from "@/components/wrapper/w-help.vue";

@Component({
  components: {
    WHelp,
    WSwitch,
    PopUpTitle,
    WInput,
    WToolTip, WButton, WModal, IconsSelector, CenterCenterPanel, WIcon, UserAvatar, VueDraggableResizable},
})
export default class InteractionElementPlayerComponent extends Vue {

  private pop = new Howl({
    src: [PUBLIC_PATH+'sound/pop.mp3'],
    volume: 0.80,
  });

  @Prop()
  private parentID!: string;
  @Prop()
  private interaction!: Annotation;
  @Prop()
  private weet!: Weet;
  @Prop({default: 0})

  private timePlayer!: number;
  private showCard: boolean = false;
  private cardHover: boolean = false;
  private promptLink:boolean=false;

  private ready: boolean = false;
  private iconMD: string = '';
  private urlSourceImage: string = '';
  private isCardAlwaysShow:boolean=false;
  private titleCard:string="";
  private descriptionCard:string="";
  private interactionButtonLabel="";
  private interactionButtonURL: string = '';
  private xInteraction = 0;
  private yInteraction = 0;
  private parent!: HTMLElement;
  private cardWidth = 270;
  private cardPlaceHolderWidth=42;
  private cardOffsetWidth = 40;
  private cardOffsetHeight = 16;
  private stylePositionCard: any = {};
  private timeoutCloseCard: any = 0;
  private dragInProgress = false;
  private loadingPosition = true;
  private isLeftDirection=false;
  private openChooseIcon=false;
  private needToSaveCard=false;

  private debounceResize = debounce(async (el) => {
    this.reinitPosition()
  },250);



  private debounceSaveAnnotation = debounce((interaction) => {
    this.updateInteraction(interaction, false);
  }, 1500);
  // allow to inform the darg is terminated
  private debounceOnDrag = debounce((el: any) => {
    el.dragInProgress = false;
  }, 1000);

  private onMouseEnter() {
    clearTimeout(this.timeoutCloseCard);
    if (!this.dragInProgress && !this.needToShowPlaceHolderCard && this.isEditable) {
     this.openCard();
    }
  }

  private startEdit(){
    if( this.cardHover) {
      this.$emit('onCardEdit', true, () => {
        this.onMouseLeave(true);
      });
    }
  }
  private openCard(){
    this.cardHover = true;
    store.dispatch(PLAYER_PLAYING, false);
    this.updateCardPosition();
  }
  private async onMouseLeave(force=false) {
    clearTimeout(this.timeoutCloseCard);
    if(force){
      this.cardHover = false;
      this.$emit('onCardEdit',false);
      if(this.needToSaveCard) {
        this.saveInteration();
      }
      await delay(100)
      this.updateCardPosition();

    }else {
      this.timeoutCloseCard = setTimeout(() => {
        this.onMouseLeave(true)
      }, 1000)
    }
  }

  get show() {
    return this.ready && this.timePlayer >= this.interaction.time &&
        this.timePlayer <= this.interaction.time + this.interaction.duration;
  }

  get isEditable() {

    if (store.getters.getAnnotationSelected) {
      return store.getters.getAnnotationSelected.annotationID === this.interaction.annotationID
          && store.getters.isInteractionMode;// && !this.isSaveInProgress;
    }
    return false;
  }

  get isContentInCard() {
    return !!(this.titleContent ||
        this.descriptionCard ||
        this.urlSourceImage ||
        this.interactionButtonURL)
  }

  get needShowMessageVisibilityCard(){
    return store.getters.isInteractionMode && this.isContentInCard && !this.isCardAlwaysShow;
  }

  get needToBeTransparent(){
    // return this.dragInProgress;
    return (this.isEditable && this.isContentInCard && !this.isCardAlwaysShow && !this.cardHover && !this.isSaveInProgress) || this.dragInProgress;
  }
  get needToShowCard() {
    return (this.isEditable) ||
        (this.isContentInCard && this.showCard)//&& !this.dragInProgress;
  }

  get needToShowPlaceHolderCard() {
    return !this.isContentInCard && !this.cardHover && this.isEditable
  }

  get titleContent(): string {
    return htmliFy(this.titleCard).trim();
  }

  get descriptionContent(): string {
    return htmliFy(this.descriptionCard).trim();
  }

  get toolTipPlaceHolderCard():string{
    return this.$t('createWeetComponent.advancedEditing.interactionMode.edit.cardToolTip').toString()
  }

  get isSaveInProgress(){
    return store.getters.isAnnotationInteractionSaveInProgress;
  }
  private async mounted() {
    this.ready = true;

    if (this.interaction.sound) {
      this.pop.play();
    }
    this.iconMD = this.interaction.iconMD;

    if (this.interaction.annotationCard.imageUrl) {
      this.urlSourceImage = getImageURLForSize(this.interaction.annotationCard.imageUrl, 600);
    }
    this.titleCard=this.interaction.annotationCard.title;
    this.descriptionCard=this.interaction.annotationCard.description;
    this.isCardAlwaysShow=this.interaction.annotationCard.alwaysShow;
    this.interactionButtonURL = this.interaction.annotationCard.interactionUrl;
    this.interactionButtonLabel = this.interaction.annotationCard.interactionLabel;
    this.showCard = this.isEditable || (this.isCardAlwaysShow);
    this.initExternalComponent();

    this.setupInteractionWithPourcentage(this.interaction.positionX, this.interaction.positionY)
    await delay(10);
    this.updateCardPosition();
    this.loadingPosition = false;
    window.addEventListener('resize',this.onResize)

    // if anntation has no image representation we save it
    if(this.isEditable && !this.interaction.exportStaticUrl){
      await delay(1000);
      var interactionUpdated = clone(this.interaction) as Annotation;
      this.updateInteraction(interactionUpdated, false,true);
    }
  }

  private async onResize(){
    this.debounceResize(this);
  }

  private openSelectIcon(){
    this.openChooseIcon=!this.openChooseIcon;
  }

  @Watch('interaction')
  private async reinitPosition(){
    this.setupInteractionWithPourcentage(this.interaction.positionX, this.interaction.positionY)
    await delay(10);
    this.updateCardPosition();
  }

  @Watch('timePlayer')
  private closeWhenPlay(){
    if(this.needToSaveCard){
      this.onMouseLeave(true);
    }
  }


  private initExternalComponent() {
    const el = document.getElementById(this.parentID);
    if (el) {
      this.parent = el;
      // move card to parent element to avoid draggable feature
      // @ts-ignore
      this.parent.appendChild(this.$refs.card.$el);
    } else {
      console.warn("Error no parent found for interaction")
    }
  }


  private setupInteractionWithPourcentage(x, y) {
    this.xInteraction = this.parent.offsetWidth * x / 100;
    this.yInteraction = this.parent.offsetHeight * y / 100;
  }

  private updateInteractionPosition() {
    const x = this.xInteraction / this.parent.offsetWidth * 100;
    const y = this.yInteraction / this.parent.offsetHeight * 100;

    const interactionUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    interactionUpdated.positionX = x;
    interactionUpdated.positionY = y;
    this.debounceSaveAnnotation(interactionUpdated);
    this.updateCardPosition();
  }

  private updateCardPosition() {
    // reinit style
    this.stylePositionCard={}
    // compute left position
    const widthFrame = this.parent.offsetWidth;
    const heightFrame = this.parent.offsetHeight;
    const pourcX = this.xInteraction / widthFrame * 100;
    const pourcY = this.yInteraction / heightFrame * 100;

    let xCard;
    if (pourcX > 50) {
      // card is left of the highlight
      this.isLeftDirection=true;
      if(this.needToShowPlaceHolderCard){
        xCard=this.xInteraction+this.cardPlaceHolderWidth;
      }else{
        xCard = this.xInteraction - this.cardWidth / 2 - this.cardOffsetWidth;
      }

    } else {
      this.isLeftDirection=false;
      // card is right of the highlight
      xCard = this.xInteraction + this.cardWidth / 2 + this.cardOffsetWidth;
    }

    this.stylePositionCard.left = xCard + "px";
    // @ts-ignore
    if (!this.$refs.card) {
      return;
    }

    // @ts-ignore
    const card = this.$refs.card.$el as HTMLElement;
    const cardEl = card.firstElementChild;
    if (!cardEl) {
      return;
    }

    const heightCard = cardEl.clientHeight;
    if (heightCard <= 0) {
      return;
    }

    let top = this.yInteraction - 50;
    const outboundHeight = this.yInteraction + heightCard;
    if (top <= this.cardOffsetHeight) {
      top = this.cardOffsetHeight;
    }
    if (outboundHeight > heightFrame - this.cardOffsetHeight) {
      this.stylePositionCard.top = (heightFrame - (this.cardOffsetHeight + heightCard)) + "px";
    } else {
      this.stylePositionCard.top = top + "px";
    }
  }

  private selectCard() {
    if (store.getters.isInteractionMode) {
      store.dispatch(SELECT_ANNOTATION, this.interaction);
    }
  }

  private async openInteraction() {
    store.dispatch(PLAYER_PLAYING,false)
    this.selectCard();
    if(!this.isEditable && !this.interaction.annotationCard.alwaysShow){
      this.showCard = !this.showCard;
    }
    await delay(100);
    this.updateCardPosition();
  }

  private onDragInteraction(left, top) {
    this.xInteraction = left;
    this.yInteraction = top;
    this.updateInteractionPosition()
    this.onDragInProgress();
  }

  private onDragInProgress() {
    store.dispatch(PLAYER_PLAYING, false);
    this.dragInProgress = true;
    //inform the drag is ended
    this.debounceOnDrag(this);
  }

  private openLink(){
    window.open(this.interactionButtonURL,"_blank");
  }
  private openModalInteractionURL() {
    this.promptLink=true;
  }

  private saveModalInteractionURL()
  {
    this.startEdit();
    if(!this.interactionButtonURL){
      this.promptLink=false;
      return;
    }
    if (isURL(this.interactionButtonURL)) {
      this.needToSaveCard=true;
      this.promptLink=false;
    } else {
      alertError(this.$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.errorLink').toString(),
          this.$t('createWeetComponent.advancedEditing.interactionMode.edit.linkPromptModal.errorLinkTitle').toString());
    }
  }


  private async eraseImage(){
    try {
      await confirmationAlert(this.$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.eraseImageConfirmation.description').toString(),
          this.$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.eraseImageConfirmation.title').toString())
      this.urlSourceImage = "";
      this.needToSaveCard = true;
    }catch (e){
      // cancel nothing todo
    }
  }
  private async eraseContent(){
    try {
      this.startEdit();
      await confirmationAlert(this.$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.eraseConfirmation.description').toString(),
          this.$t('createWeetComponent.advancedEditing.interactionMode.edit.toolbar.eraseConfirmation.title').toString())
      this.titleCard = "";
      this.descriptionCard = "";
      this.urlSourceImage = "";
      this.interactionButtonURL = "";
      this.interactionButtonLabel="";
      this.needToSaveCard=true;
    }catch (e){
      // cancel nothing todo
    }
  }
  private async updateInteractionAlwaysShow(){
    var interaction = clone(store.getters.getAnnotationSelected) as Annotation;
    interaction.annotationCard.alwaysShow = !interaction.annotationCard.alwaysShow;
    this.isCardAlwaysShow=!this.isCardAlwaysShow;
    this.needToSaveCard=true;
    this.startEdit();
  }
  private async uploadNewImage(file: File) {
    this.startEdit();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        var dataUrl = reader.result;
        this.urlSourceImage = dataUrl + "";
        this.needToSaveCard=true;
      })
      reader.readAsDataURL(file);
    })
  }

  private async saveInteration(){
    if(this.isSaveInProgress){
      return;
    }
    var interactionUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    interactionUpdated.annotationCard.title=this.titleCard;
    interactionUpdated.annotationCard.description=this.descriptionCard;
    interactionUpdated.annotationCard.imageUrl=this.urlSourceImage;
    interactionUpdated.annotationCard.interactionUrl=this.interactionButtonURL;
    interactionUpdated.annotationCard.interactionLabel=this.interactionButtonLabel;
    interactionUpdated.annotationCard.alwaysShow=this.isCardAlwaysShow;
    interactionUpdated.annotationCard.exportStaticUrl="";
    await this.updateInteraction(interactionUpdated,this.isContentInCard,!this.isContentInCard);
    this.needToSaveCard=false;
  }
  private async updateInteraction(interaction: Annotation, withCardPicture = false, withHighlightPicture=false) {
    // export img before update
    let dataUrlImage = '';
    store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,true);
    if (withCardPicture) {
      const interactionCardNode = document.getElementById('interactionCard-' + interaction.annotationID);
      if (!interactionCardNode) {
        return;
      }
      // wait for annotation update
      await delay(50);
      dataUrlImage = await exportNode2PngDataURL(interactionCardNode);
      interaction.annotationCard.exportStaticUrl = dataUrlImage;
    }
    if(withHighlightPicture || !interaction.exportStaticUrl){
      const interactionPointNode = document.getElementById( 'interactionPoint-'+interaction.annotationID);
      if (!interactionPointNode) {
        return;
      }
      await delay(50);
      // if(interaction.iconMD==="none"){
      //   dataUrlImage = await exportTransparentPng();
      //   interaction.exportStaticUrl = dataUrlImage;
      //   console.log(dataUrlImage)
      // }else {
        dataUrlImage = await exportNode2PngDataURL(interactionPointNode);
        interaction.exportStaticUrl = dataUrlImage;
      console.log(dataUrlImage)
      // }
    }

    await store.dispatch(UPDATE_ANNOTATION, {
      weetID: this.weet.weetID, annotation: interaction
    }).then((interactionUpdated) => {
      store.dispatch(SELECT_ANNOTATION, interactionUpdated)
      if(interactionUpdated.annotationCard.imageUrl) {
        this.urlSourceImage = getImageURLForSize(interactionUpdated.annotationCard.imageUrl, 600);
      }
      inform(this.$t('createWeetComponent.advancedEditing.interactionMode.edit.success').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    }).catch(() => {
      informError(this.$t('createWeetComponent.advancedEditing.interactionMode.edit.error').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    })

  }

  private validateInteractionTitleContent(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      if (htmliFy(element.innerText).trim() !== this.titleContent) {
        // update the text content of the annotation
        this.titleCard=htmliFy(element.innerText.trim());
        this.needToSaveCard=true;
      }
    }
  }

  private validateInteractionDescriptionContent(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      if (htmliFy(element.innerText).trim() !== this.descriptionContent) {
        // update the text content of the annotation
        this.descriptionCard=htmliFy(element.innerText.trim());
        this.needToSaveCard=true;
      }
    }
  }

  private invisibleIcon(value:boolean){
    if(!value){
      this.changeIcon('none')
    }else{
      this.changeIcon('');
    }
  }
  private changeIcon(name:string){
    // this.openChooseIcon=false;
    var interactionUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    interactionUpdated.iconMD=name;
    if(name==='none') {
      interactionUpdated.annotationCard.alwaysShow = true;
      this.isCardAlwaysShow = interactionUpdated.annotationCard.alwaysShow;
    }
    this.iconMD=name;
    this.updateInteraction(interactionUpdated, false,true);
  }

  private focusOutInteractionContent(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

  public beforeDestroy() {
    // @ts-ignore
    this.$refs.card.$el.remove();
    window.removeEventListener('resize',this.onResize);
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.interactionPosition {
  position: absolute;
  z-index: 30;
  // reajust coordonate
  margin-left: -20px;
  margin-top: -20px;

  .interactionHighLight {
    //cursor: pointer;
    pointer-events: auto;
    width: 40px;
    height: 40px;
    background: rgba(var(--primaryRGB), 0.3);
    border: solid 2px white;
    border-radius: 100%;
    &.invisible{
      background: none;
      border: none;
    }
    &.editable {
      &.invisible {
        background: rgba(255,255,255,0.3);
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
        border: dashed 2px white;
      }
    }
    &.animate{
      animation: leaves 0.5s ease-in-out infinite alternate;
      animation-delay: 0.5s;
      &:hover {
        animation: leaves 0.2s ease-in-out infinite alternate;
      }
      &.editable:hover{
        animation:none;
      }
    }

    .iconIllustration{
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-top: -1px;
    }
    &:hover{
      .interactionHighLightEdition {
        opacity: 1;
        transform: scale(1);
      }
    }
    .interactionHighLightEdition{
      position: absolute;
      bottom:48px;
      left: 8px;
      width: 24px;
      height: 24px;
      color: var(--dark);
      border-radius:8px;
      background: white;
      opacity: 0.2;
      transform: scale(0);
      transition: all 0.4s ease-in-out;
      transition-delay: 0.2s;
      cursor: pointer;
      @extend .cardShadow;
      &:hover{
        opacity: 1;
      }
    }
    .chooseIcon {
      position: absolute;
      bottom: 48px;
      width: 360px;
      left: -160px;
      background: white;
      animation-duration: 0.2s;
      //height: 96px;
      padding-bottom: 4px;
      border-radius: 8px;
      @extend .stickersShadow;

      .headerElement{
        display: flex;
        align-items: center;
        background: var(--light1);
        justify-content: center;
        height: 32px;
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        .labelHighlightBubble{
          font-weight: 700;
          font-size: 14px;
          color: var(--black);
          margin-left: 8px;
          margin-right: 8px;
        }
        .iconHelp{
          margin-right: 8px;
        }
      }
      .containerIcon{
        display: flex;
        flex-wrap: wrap;

        .defaultIcon{
          margin: 8px;
          width: 24px;
          height: 24px;
          background:rgba(var(--primaryRGB), 0.3);
          border: solid 1px white;
          border-radius: 100%;
          cursor:pointer;
          &:hover{
            background: rgba(var(--primaryRGB), 0.6);
          }
        }
        .noneIcon{
          margin: 8px;
          width: 24px;
          height: 24px;
          border: dashed 1px var(--dark);
          border-radius: 100%;
          cursor:pointer;
          &:hover{
            border: dashed 2px var(--dark);
          }
        }
      }
      .closeIcon{
        position: absolute;
        right:-12px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        top:-12px;
        width: 24px;
        height: 24px;
        background:var(--dark);
        color: white;
        border:solid 1px white;
        border-radius: 100%;
      }
    }
  }
}

@keyframes leaves {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}

// CARD
$cardSize:270px;
$contentSize:238px;
.interactionCard {


  .interactionLayout {
    position: absolute;
    width: $cardSize;
    margin-left: -135px; //( width / 2)
    background: white;
    @extend .stickersShadow;
    border: solid 1px var(--light1);
    border-radius: 12px;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 16px;
    transition: all 0.5s ease;
    transition-delay: 0.1s;
    pointer-events: auto;


    .headerEditingCard{
      display: flex;
      align-items: center;
      background: var(--light1);
      justify-content: center;
      height: 32px;
      width: calc(100% + 16px);
      margin-left: -8px;
      border-radius: 8px 8px 0px 0px;

      .labelHighlightBubble{
        font-weight: 700;
        font-size: 14px;
        color: var(--black);
        margin-left: 8px;
        margin-right: 8px;
      }
      .iconHelp{
        margin-right: 8px;
      }
    }
    .messageVisibilityCard{
      font-size: 12px;
      opacity: 0.6;
      position: absolute;
      top:8px;
      color: black;
      background: var(--light1);
      border-radius: 8px;
      right: 8px;
      animation-duration: 0.2s;
      cursor: pointer;
      &:hover{
        opacity: 1;
      }
    }
    &.drag {
      opacity: 0.8;
    }

    &.loadingPosition {
      visibility: hidden;
    }

    &.placeholderCard {
      width: 42px;
      height: 42px;
      max-height: 42px;
      padding: 8px;
      padding-top: 0px;
      cursor: pointer;
    }

    .cardPlaceHolder {
      font-weight: 900;
      font-size: 16px;
      margin-top: 8px;
      color: var(--light2);
      display: flex;
      align-items: center;
      &:hover{
        color: var(--dark);
      }
    }
    .toolbarCard{
      position: absolute;
      background: var(--dark);
      border: solid 1px var(--light1);
      top: 16px;
      width: 32px;
      display: flex;
      flex-direction: column;
      &.right {
        border-radius: 0px 8px 8px 0px;
        right: -32px;
      }
      &.left{
        border-radius: 8px 0px 0px 8px;
        left: -32px;
      }
      .toolBarButton{
        color: white;
        cursor: pointer;
        &:hover{
          color:var(--light2);
        }
      }
    }

    .cardInteractionContent {
      width: 100%;
      height: 100%;
      overflow: hidden;


      .interactionTitle {
        font-weight: 900;
        font-size: 16px;
        margin-top: 16px;
        color: var(--black);
        text-transform: uppercase;
        border-radius: 8px;
        min-width: $contentSize;
        margin-left: 8px;
        margin-right: 8px;

        &[placeholder]:empty::before {
          content: attr(placeholder);
          color: var(--light1);
        }

        &[placeholder]:empty:focus::before {
          content: "";
        }
        &[contenteditable=true] {
          &:hover {
            box-shadow: 0px 0px 2px var(--light2);
          }

          &:focus {
            box-shadow: 0px 0px 2px var(--primary);
          }
        }
      }

      .interactionImage {
        min-width: $contentSize;
        margin-top: 16px;
        min-height: 64px;
        position: relative;
        font-size: 0px;
        margin-left: 8px;
        margin-right: 8px;

        .iconDeleteImage{
          position: absolute;
          opacity: 0.6;
          right: 8px;
          top:8px;
          cursor: pointer;
          font-size: 16px;
          &:hover{
            opacity: 1;
          }
        }
        img {
          //object-fit: contain;
          max-height: 150px;
          border-radius: 12px;
        }

        .uploadElement {
          min-width: $contentSize;
          display: flex;
          position: absolute;
          opacity: 0;
          right: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease-in-out;
          border-radius: 12px;
          padding: 0px;
          color: var(--light1);
          //border: 1px solid var(--light1);
          cursor: pointer;
          background: rgba(255, 255, 255, 0.9);

          .uploadPanel {
            display: flex;
            text-align: center;
            border: 0px;
            height: 100%;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .textUpload {
              font-size: 16px;
              color: var(--light2);
            }
          }

          &.empty {
            opacity: 1;
            min-height: 64px;
          }
        }

        &:hover {
          .uploadElement {
            opacity: 1;
          }
        }
      }

      .interactionDescription {
        min-width: $contentSize;
        margin-top: 16px;
        color: var(--dark);
        font-size: 14px;
        text-align: left;
        border-radius: 8px;
        margin-left: 8px;
        margin-right: 8px;

        &[placeholder]:empty::before {
          content: attr(placeholder);
          color: var(--light2);
        }

        &[placeholder]:empty:focus::before {
          content: "";
        }

        &[contenteditable=true] {
          &:hover {
            box-shadow: 0px 0px 2px var(--light2);
          }

          &:focus {
            box-shadow: 0px 0px 2px var(--primary);
          }
        }
      }

      .interactionButton {
        margin-top: 16px;
        margin-left: 8px;
        margin-right: 8px;
        .fakeButton {
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          border: 1px dashed var(--light1);
          height: 40px;
          color: var(--light2);
          text-transform: uppercase;
          font-weight: 900;
          font-size: 13px;

          &:hover {
            background: var(--light0);
          }
        }

        //Manage Label button edition
        .labelButtonEdited{
          display: block;
        }
        .labelButtonEditedHovered{
          display: none;
        }
        &:hover {
          .labelButtonEditedHovered{
            display: block;
          }
          .labelButtonEdited{
            display: none;
          }
        }
      }
    }
  }
}

.backgroundInteractionEditingModal{
  background: white;
  margin-left: 128px;
  margin-top: 32px;
  height: calc(100% - 64px);
  width: calc(100% - 256px);
  border-radius: 12px;
  .containerInterdationEditingForm{
    margin: auto;
    padding: 32px;
    padding-top: 16px;
    text-align: left;
    .buttonLine{
      width: 100%;
      margin-top: 32px;
      text-align: right;
    }
  }
}

</style>

<style lang="scss">
.uploadElement {
  .upload-draggable {
    border: 0px;
    height: 100%;
    border-radius: 12px;

  }
}

.defaultClassDragInteraction {
  background-color: transparent;
  border: none;
  cursor: grab;
}

.onDragInteraction {
  cursor: move;
}

.clickInteraction {
  cursor: pointer;
}
</style>
