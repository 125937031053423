<template>
  <div>
    <w-button icon="check" tooltip-size="small" :loading="loading" data-test="finishWeetButton"
              :color="color" :tooltip="toolTipText" tooltip-position="left"
              @click="onClick" :enabled="isAivailable || this.loading" :expanded="expanded">
      <span v-if="!mini"><slot></slot></span>
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';

import prettyMilliseconds from 'pretty-ms';
import {TIME_CHUNK} from '@/store/conf/api';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {numberOfFileSync} from '@/utils/weetUtil';

@Component({
  components: {WButton},
})
export default class ValidateWeetButton extends Vue {
  private stepIntervalSync: number = 1000;
  private numberOfFileToSync: number = 0;
  private intervalIndex: number = 0;

  @Prop({default: false})
  private mini!: boolean;

  @Prop({default: false})
  private expanded!: boolean;

  @Prop({default: 'primary'})
  private color!: boolean;

  @Prop({default: false})
  private label!: boolean;

  @Prop({default: false})
  private loading!: boolean;

  private mounted() {
    // @ts-ignore
    this.intervalIndex = setInterval(this.updateNumberOfFileSync, this.stepIntervalSync);
  }

  private updateNumberOfFileSync() {
    this.numberOfFileToSync = numberOfFileSync();
  }

  get isAivailable(): boolean {
    return (!store.getters.isSychronisationInProgress && !store.getters.timelineNeedToBeSaved);
  }

  get toolTipText(): string {
    if (!this.isAivailable) {
      return this.$t('endWeetButton.button.tooltip', {uploading: this.labelSynchronize}).toString();
    } else {
      return '';
    }
  }

  get isRecord(): boolean {
    return store.getters.getRecordingState === RecordingState.RECORDING;
  }

  get labelSynchronize(): string {
    if (!this.isRecord) {
      if (this.numberOfFileToSync > 0) {
        return this.$t('endWeetButton.uploading.withFile', {part: prettyMilliseconds((this.numberOfFileToSync / 2) * TIME_CHUNK)}).toString();
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  private onClick() {
    const weetID = store.getters.getEditingWeetID;
    if (weetID !== ''
        && weetID !== undefined
        && this.isAivailable) {
      this.$emit('click');
    }
  }


  private beforeDestroy() {
    clearInterval(this.intervalIndex);
  }
}
</script>
<style scoped lang="scss">

</style>
