<template>
  <w-modal :active="isNewWeet || isWeetHidden" :modal="true" :fullscreen="true" :static="true" max-width="100vw">
    <div class="hiddenWeetPanel">
      <CenterCenter>
        <div class="contentModalBlock" data-test="modalBlockerFreemium">
          <PopUpTitle v-if="isNewWeet" :title="$t('blocker.titleNewWeet')"/>
          <PopUpTitle v-else :title="$t('blocker.title')"/>

          <div class="blockerContent">
            <div class="solutionDescription">

              <div v-if="isNewWeet">
                {{ $t('blocker.contentNewWeet', { limit: limit }) }}
              </div>
              <div v-else-if="iOwnThisWeetInAWorkspaceIOwn">
                {{ $t('blocker.reactivateAivailable') }}
              </div>
              <div v-else-if="iOwnThisWeetInAWorkspaceIDontOwn">
                {{ $t('blocker.reactivateUnaivailable') }}
              </div>
              <div v-else-if="iDontOwnThisWeet">
                {{ $t('blocker.reactivatePersonalSpace') }}
              </div>
              <div v-else>
                {{ $t('blocker.personnalSpace') }}
              </div>
            </div>

            <div class="solutionAction">
              <w-button v-if="isNewWeet || iOwnThisWeetInAWorkspaceIOwn" color="primary" icon="shimmer"
                        @click="goPayment">
                {{ $t('blocker.button.upgrade') }}
              </w-button>

              <w-button v-else-if="iOwnThisWeetInAWorkspaceIDontOwn || iDontOwnThisWeet" :loading="loading"
                        color="primary"
                        icon="send" @click="sendRequest">
                {{ $t('blocker.button.request') }}
              </w-button>
              <div v-else>

                <InputWorkspaceChoose class="chooseWorkspace" @select="onSelectWorkspace" :value="selectedWorkspaceID"/>
                <w-button color="primary" icon="folder-move-outline" :loading="loading" :enabled="enabledButton"
                          @click="handleFilterWorkspace">
                  {{ $t('blocker.button.move') }}
                </w-button>
              </div>

              <div class="goBackButton" @click="goBackMyWeet">
                <w-icon icon="arrow-left" color="black" class="iconRight"/>
                {{ $t('blocker.button.back') }}
              </div>
            </div>
          </div>
        </div>
      </CenterCenter>
    </div>
  </w-modal>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import store from '@/store';
import {canManagePayments} from '@/utils/workspaceUtil';
import WButton from '@/components/wrapper/w-button.vue';
import router from '@/router';
import InputWorkspaceChoose from '@/components/workspace/InputWorkspaceChoose.vue';
import {ADD_WEET_TO_WORKSPACE} from '@/store/myWeet/myWeetAction';
import {inform} from '@/utils/dialog';
import {REQUEST_UPGRADE_WORKSPACE_WEET} from '@/store/weet/weetAction';
import WIcon from '@/components/wrapper/w-icon.vue';
import delay from 'delay';
import {isOwner} from '@/utils/weetUtil';
import {SHOW_POPUP_CREATION} from '@/store/recordingState/recordStateAction';
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";

@Component({
  components: {WIcon, InputWorkspaceChoose, WButton, PopUpTitle, CenterCenter, WModal}
})
export default class WeetBlockerPlayer extends Vue {
  @Prop()
  private weet!: Weet;

  // put true if this is a new weet creation (not a weet hideen)
  @Prop({default: false})
  private isNewWeet!: boolean;

  private loading: boolean = false;
  private enabledButton: boolean = false;
  private selectedWorkspaceToChange: string | null = '';

  get iDontOwnThisWeet(): boolean {
    return !this.isNewWeet && !isOwner(this.weet);
  }

  // The following method is implicitly in the v-else conditionals in the template
  // get iOwnThisWeetInMyPersonalSpace(): boolean {
  //   return isOwner(this.weet) && this.weet.workspaces.length === 0;
  // }

  get iOwnThisWeetInAWorkspaceIDontOwn(): boolean {
    return !this.isNewWeet && isOwner(this.weet) && this.isWorkspace && !canManagePayments();
  }

  get iOwnThisWeetInAWorkspaceIOwn(): boolean {
    return !this.isNewWeet && isOwner(this.weet) && this.isWorkspace && canManagePayments();
  }

  get isWeetHidden(): boolean {
    return (!this.isNewWeet && this.weet?.hidden) || false;
  }

  get isWorkspace(): boolean {
    return !!store.getters.getSelectedWorkspaceID;
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get limit(): number {
    return store.getters.getFreemiumQuotas(this.selectedWorkspaceID);
  }

  private async sendRequest() {
    this.loading = true;
    await store.dispatch(REQUEST_UPGRADE_WORKSPACE_WEET, this.weet.weetID);
    inform(this.$t('blocker.requestSent.success').toString());
    await delay(1500);
    this.goBackMyWeet();
  }

  private onSelectWorkspace(workspaceID: string | null = null) {
    this.selectedWorkspaceToChange = workspaceID;
    this.enabledButton = true;
  }

  private goBackMyWeet() {
    router.push({name: 'MyWeets'}).catch(err => {
    });
    this.closePopupCreation();
  }

  private async handleFilterWorkspace() {
    this.loading = true;
    // Add the weet to workspace we selected workspace change
    store.dispatch(ADD_WEET_TO_WORKSPACE,
        {
          weetID: this.weet.weetID,
          workspaceID: this.selectedWorkspaceToChange,
        }).then(() => {
      inform(this.$t('movePanel.move.success').toString());
      location.reload();
    });
  }

  private goPayment() {
    store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
    router.push({name: 'MyWorkspacePayment'});
    this.closePopupCreation();
  }

  private closePopupCreation() {
    if (store.getters.showPopUpCreation) {
      store.dispatch(SHOW_POPUP_CREATION, false);
    }
    this.$emit('closeWeetBlocker');
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gridBreakPoint.scss";

.hiddenWeetPanel {
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  .contentModalBlock {
    padding: 32px;
    background: white;
    min-width: 250px;
    max-width: 600px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    @include GRID1X {
      padding: 32px 8px;
    }

    .subtitle {
      margin-top: 16px;
      color: var(--black);
      font-size: 16px;
    }

    .solutionDescription {
      margin-top: 16px;
      font-size: 16px;
      color: var(--black);
    }

    .solutionAction {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      align-items: center;
      justify-content: center;
    }

    .chooseWorkspace {
      margin-bottom: 32px;
    }

    .goBackButton {
      margin-top: 24px;
      cursor: pointer;
      color: var(--black);
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 13px;
      letter-spacing: 0.3px;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 900;

      .iconRight {
        margin-right: 8px;
      }
    }
  }
}

</style>
