import {isEmbedInTeams} from '@/utils/extentionUtil';
import {ExternalToolEnum} from '@/enum/ExternalToolEnum';
import store from '@/store';
import {log} from '@/utils/log';
import {
    EXTERNAL_TOOL_INIT,
    EXTERNAL_TOOL_SEND_MESSAGE,
    NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL
} from '@/store/integration/integrationAction';
import router from '@/router';
import {confettiBee} from '@/utils/dialog';
import {FIRST_WEET_CONGRAT} from '@/store/persistedInformation/persistAction';
import slugify from '@sindresorhus/slugify';
import {DEFAULT_WEET_TITLE} from '@/store/conf/api';
import {getFirstLinesTranscriptFromWeet, weetEditEmbedLink, weetLink} from '@/utils/weetUtil';
import {Weet} from '@/store/weet/weetModel';

/**
 * Open an external Desktop Application with the browser external protocol handle 'app://open'.
 * Currently supported apps are slack/teams{@link ExternalToolEnum}
 */
export const openExternalToolDesktopApp = () => {
    const isFromExternalTool = store.getters.getExternalTool;
    if ((isEmbedInTeams() || isFromExternalTool === ExternalToolEnum.TEAMS) && !embededInExternalIframe()) {
        location.href = 'msteams://open';
        window.close();
    } else if (isFromExternalTool === ExternalToolEnum.SLACK) {
        location.href = 'slack://open';
    }
};

export const goToPlayPageIfNeeded = (weet: Weet, weetThumbnailURL: string) => {

    const url = weetLink(weet);
    // IF not in external tool, then go to the 'play' page
    if (!store.getters.getExternalTool) {
        // if (router.currentRoute.name !== 'PreviewWeet') {
        router.push({
            name: 'PreviewWeet',
            params: {weetID: weet?.weetID || '', title: slugify(DEFAULT_WEET_TITLE)},
        }).catch((e) => {
            console.warn(e.message);
        });
        // }
        if (!store.getters.isFirstWeetCreated) {
            confettiBee(3000);
            store.dispatch(FIRST_WEET_CONGRAT, true);
        }

        // we don't use the router to reload page if we are in reedition
    } else if (store.getters.getExternalTool === ExternalToolEnum.TEAMS && embededInExternalIframe()) {
        store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, undefined); // Close the windows iframe in Teams
    } else if (store.getters.getExternalTool === ExternalToolEnum.SLACK) {
        window.location.href = url;
    } else {
        const audURL = store.getters.getExternalToolURL;
        // Redirect to the weet play page if needed
        const editURL = weetEditEmbedLink(weet);
        const transcript = getFirstLinesTranscriptFromWeet(weet);
        // https://local.speachme.com:9011/
        if (window.parent) {
            window.parent.postMessage({
                type: NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL,
                title: weet?.title || '',
                playURL: url,
                editURL: editURL,
                weetThumbnailURL: weetThumbnailURL,
                description: transcript
            }, audURL);
        }
    }
};


/**
 * Send an message to external tool to notify that user try to externallogin (login with google/Ms) while running app in iframe.
 */
export const notifyIframeExternalSigninNotSupported = (lastAskedUrl: string) => {
    if (embededInExternalIframe() && lastAskedUrl?.startsWith('/editEmbed')) {
        try {
            store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, {id: 'weetLoginError', error: 'Cannot login in iframe'});
        } catch (e) {
            log.error('Notification to externaltool failed while login in iframe');
        }
    }
};


/**
 * Notify MsTeams that weet creation in TeamsDesktop app is not possible.
 * @param weetTitle, the optionnal title of the weet being created.
 * @return true if creation is running in msteams desktop and false otherwise
 */
export function notifyWeetCreationInTeamsNotSupported(weetTitle: string = ''): boolean {
    // IF browser is chromium but the weet creation is running from MsTeams Desktop
    if (isEmbedInTeams()) {
        store.dispatch(EXTERNAL_TOOL_INIT);
        const externalToolMessage = {
            id: 'weetCreationError',
            weetTitle: weetTitle,
            message: 'Sorry, Weet app does not support capturing of video and audio on Mobile, ' +
                'please use Weet screen recording on Desktop/Web Teams app for creation of a Weet. Feel free to contact us for help'
        };
        store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, externalToolMessage);
        return true;
    }
    return false;
}

/**
 * Notify MsTeams that the oauth flow is OK
 */
export function notifyTeamsOAuthSuccess() {
    log.debug('In notifyTeamsOAuthSuccess');
    const externalToolMessage = {
        id: 'weetOAuthSuccess',
        message: 'OAuth OK'
    };
    store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, externalToolMessage);
}

/**
 * Notify MsTeams that the oauth flow is KO
 */
export function notifyTeamsOAuthKO(errorMessage: string) {
    log.debug('In notifyTeamsOAuthKO: ' + errorMessage);
    const externalToolMessage = {
        id: 'weetOAuthError',
        message: errorMessage
    };
    store.dispatch(EXTERNAL_TOOL_SEND_MESSAGE, externalToolMessage);
}


/**
 * Return true if the app is running in iframe outside from weet domains (for ex: Teams)
 */
export function embededInExternalIframe() {
    // console.log('ifral', window.self !== window.top);
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
