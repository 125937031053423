<template>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {
  PLAYER_TIMELINE_CURRENT_EVENT,
  PLAYER_TIMELINE_NEXT_EVENT,
  PLAYER_TITLE,
  TIMER_PLAYER,
} from '@/store/timeLine/timeLineAction';
import {Weet} from '@/store/weet/weetModel';
import {
  getFirstMediaPlayEvent,
  getJumpEventForTime,
  getTimeEventAfterTime,
  getTimeEventbeforeTime
} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';

@Component
export default class TimeLinePlayer extends Vue {

  @Prop()
  private weet!: Weet;

  @Prop({default: 0})
  private timePlayer!: number;

  @Watch('weet')
  private onUpdateWeet() {
    if (this.currentTimeEvent) {
      this.dispatchTimeEvent(this.currentTimeEvent);
      this.onTitleChange();
    }
  }

  get currentTimeEvent(): TimeEvent | undefined {
    return getTimeEventbeforeTime(this.weet.timeLine, this.timePlayer, TimeEventType.MEDIA_PLAY);
  }

  get currentTitleEvent(): TimeEvent | undefined {
    return getTimeEventbeforeTime(this.weet.timeLine, this.timePlayer + 100, TimeEventType.CHAPTER_TITLE);
  }

  get titleTimeEvent(): number {
    if (this.currentTitleEvent) {
      return this.currentTitleEvent.time;
    } else {
      return -1;
    }
  }

  // this is to keep a key to avoid
  // the call of dispatchTimeEvent each time
  get timeLastEvent(): number {
    if (this.currentTimeEvent) {
      return this.currentTimeEvent.time;
    } else {
      return -1;
    }
  }

  private mounted() {
    // console.log(this.weet.timeLine);
    this.initToBegining();

    this.onRecordingStateChange();
  }

  @Watch('timeLastEvent')
  private onRecordingStateChange() {
    if (this.currentTimeEvent) {
      this.dispatchTimeEvent(this.currentTimeEvent);
    }
  }

  @Watch('titleTimeEvent')
  private onTitleChange() {
    if (this.currentTitleEvent) {
      store.dispatch(PLAYER_TITLE, this.currentTitleEvent.value);
    } else {
      store.dispatch(PLAYER_TITLE, '');
    }
  }

  /**
   * Dispatch an event Media
   */
  private dispatchTimeEvent(timeEvent: TimeEvent) {
    store.dispatch(PLAYER_TIMELINE_CURRENT_EVENT, timeEvent);

    const te = getTimeEventAfterTime(this.weet.timeLine, timeEvent.time, TimeEventType.MEDIA_PLAY);
    if (te) {
      store.dispatch(PLAYER_TIMELINE_NEXT_EVENT, te);
    }
  }


  private jumpScheduled: any = 0;

  @Watch('timePlayer')
  private mustJump() {
    if (!store.getters.isPreviewMode) {
      return;
    }
    clearTimeout(this.jumpScheduled);
    // detect if we need to jump now
    const te = getJumpEventForTime(this.weet.timeLine, this.timePlayer);
    if (te) {
      // Seek
      // @ts-ignore (//chek that)
      const timeToSeek = te.time;
      // console.log('TimeLine seek', timeToSeek, te);
      this.$emit('seek', timeToSeek);
      store.dispatch(TIMER_PLAYER, timeToSeek);
    } else {
      // now we try to anticipate the next jump
      const nextTe = getJumpEventForTime(this.weet.timeLine, this.timePlayer + 200);
      if (nextTe) {
        // Seek
        // get the event to start the jump
        const startJump = getTimeEventAfterTime(this.weet.timeLine, this.timePlayer, TimeEventType.START_CUT);
        if (startJump) {
          // @ts-ignore (//chek that)
          const inTime = startJump.time - this.timePlayer;
          // @ts-ignore (//chek that)
          const timeToSeek = nextTe.time;
          // console.log('plan to seek', timeToSeek, 'in ', inTime);
          this.jumpScheduled = setTimeout(() => {
            // console.log('seek Plan', timeToSeek);
            this.$emit('seek', timeToSeek);
            store.dispatch(TIMER_PLAYER, timeToSeek);
          }, inTime);
        }

      }
    }
  }


  /**
   * Init the begining of the timelin
   * @private
   */
  private initToBegining() {
    // init the first time of the player
    let valueToStart: number = 1;
    if (this.timePlayer > 0) {
      // we convert in number because the property can come from the router...
      // the router don't convert the string in type number
      valueToStart = parseInt(this.timePlayer + '', 10);
    } else {
      // get time of the first element of the timeline
      if (this.weet.timeLine.length > 0) {
        // first mediaPlayEvent
        const te = getFirstMediaPlayEvent(this.weet.timeLine);
        if (te) {
          valueToStart = te.time;
        } else {
          valueToStart = 0;
        }
      }
    }


    // wait all component is initiate
    this.$nextTick(() => {
      this.$emit('seek', valueToStart);
      store.dispatch(TIMER_PLAYER, valueToStart);
    });

  }

  private beforeDestroy() {
    store.dispatch(PLAYER_TITLE, '');
  }


}
</script>

<style scoped lang="scss">
</style>
