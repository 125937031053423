import store from '@/store';


export const seatPriceNextPeriod = (): number => {
    return (store.getters.getMyWorkspacePayment?.priceScheduled?.unitAmount != null
      ? store.getters.getMyWorkspacePayment?.priceScheduled?.unitAmount
      : store.getters.getMyWorkspacePayment?.price?.unitAmount || 0 ) / 100;
};



export const  intervalNextPeriod = (): string => {
    return store.getters.getMyWorkspacePayment?.priceScheduled?.interval
      || store.getters.getMyWorkspacePayment?.price?.interval
      || '';
};
