<template>
  <div :style="expanded?'width:100%':''">
    <!--https://www.npmjs.com/package/vue-popperjs-->
    <Popper :trigger="'hover'" :disabled="label==='' && textLabel ==='' && insecureHTML===''"
            :options="{
              placement: position,
              modifiers: { offset: { offset: topOffset+'px,'+leftOffset+'px' }}} " transition="fade"
            :append-to-body="true"
            :delay-on-mouse-over="delay"
            :delay-on-mouse-out="1"
            :stop-propagation="true"
            :prevent-default="true">
      <div class="popper hackHideToolTip">
        <div v-if="label|| insecureHTML" class="tootTipTemplate" v-html="labelToShow"></div>
        <div v-else class="tootTipTemplate">{{ textLabelToShow }}</div>
      </div>
      <div slot="reference" :style="style">
        <slot></slot>
      </div>
    </Popper>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {htmliFy} from '@/utils/util';
import mobileDetect from 'mobile-device-detect';
// @ts-ignore
import Popper from 'vue-popperjs';

@Component({
  components: {Popper},
})
export default class WToolTip extends Vue {
  @Prop({default: true})
  private rounded!: boolean;
  @Prop({default: 'small'})
  private size!: string;
  @Prop({default: 'light'})
  private color!: string;
  @Prop({default: 'auto'})
  private position!: string;
  @Prop({default: 100})
  private delay!: number;
  @Prop({default: ''})
  private label!: string;
  @Prop({default: ''})
  private textLabel!: string;
  @Prop()
  private dataTest!: string;
  @Prop({default: false})
  private expanded!: boolean;
  @Prop({default: false})
  private enlarge!: boolean;
  @Prop({default:''})
  private insecureHTML!:string;
  @Prop({default:0})
  private leftOffset!:number;
  @Prop({default:0})
  private topOffset!:number;


  get style(): string {
    if (this.enlarge) {
      return 'width:100%;height:100%;';
    } else {
      if (this.expanded) {
        return 'width:100%;';
      }
    }
    return '';
  }

  get labelToShow() {
    if (mobileDetect.isMobile) {
      return '';
    } else {
      if(this.insecureHTML){
        return this.insecureHTML;
      }else{
        return htmliFy(this.label);
      }
    }
  }

  get textLabelToShow(): string {
    if (mobileDetect.isMobile) {
      return '';
    } else {
      return this.textLabel;
    }
  }
}
</script>

<style lang="scss">
.tootTipTemplate {
  word-break: break-word;
}
</style>
