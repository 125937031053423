import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  PUSH_NOTIFICATION_APP_ID,
  PUSH_NOTIFICATION_PROJECT_ID,
  PUSH_NOTIFICATION_SENDER_ID,
  PUSH_NOTIFICATION_WEB_API_KEY
} from '@/store/conf/api';

/**
 * This method will call firebase to get user registration token.
 * If permission is not yet granted, the method will automatically ask user for permission to display push notification.
 * @return a reference to firebase-messaging channel
 */
export const initFirebasePushNotification = (): firebase.messaging.Messaging => {
  const config = {
    apiKey: PUSH_NOTIFICATION_WEB_API_KEY,
    authDomain: `${PUSH_NOTIFICATION_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${PUSH_NOTIFICATION_PROJECT_ID}.firebaseio.com`,
    projectId: PUSH_NOTIFICATION_PROJECT_ID,
    storageBucket: `${PUSH_NOTIFICATION_PROJECT_ID}.appspot.com`,
    messagingSenderId: PUSH_NOTIFICATION_SENDER_ID,
    appId: PUSH_NOTIFICATION_APP_ID
  };
  firebase.initializeApp(config);
  return firebase.messaging();
};



