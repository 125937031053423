<template>
  <div class="stickersContainerSource">
    <div class="closeButton" v-show="!isRecording">
      <w-button icon="close" color="text" size="small" @click="closeRecorder"/>
    </div>
    <StickerCommentSource :weetIDToComment="weet.weetID"/>
    <div class="timeBox" v-if="commentIDToReply===''">{{ humanTime }}</div>
    <div class="containerRecordLabel">
      <Transition name="zoom">
        <div class="labelHelp" v-show="!isRecording"
             v-html="isWebcamVideoMute?$t('comment.video.recorder.start.audio'):$t('comment.video.recorder.start.video')">
        </div>
      </Transition>
      <Transition name="zoom">
        <div class="commandRecord" v-show="isWebcamAvailabelForRecord && mustShow">
          <RecorderStartPauseButton class="buttonRecord" data-test="recorderStartPauseButton" @click="toggleRecord"/>
          <div class="timer" v-show="isWebcamAvailabelForRecord && mustShow">
            <TimerRecorder/>
          </div>
        </div>
      </Transition>
    </div>

    <transition name="fade">
      <div class="waitingUpload" v-show="loading">
        {{ $t('comment.video.recorder.wait') }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import StickerCommentSource from '@/components/recorder/sticker/StickerCommentSource.vue';
import TimerRecorder from '@/components/recorder/timer/TimerRecorder.vue';
import {
  PAUSE_RECORDING, RECORD_STICKER_MUTE,
  RECORD_STICKER_VIDEO_MUTE,
  START_RECORDING,
  STOP_RECORDING
} from '@/store/recordingState/recordStateAction';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {
  COMMENT_TO_REPLY,
  CommentParams,
  REPLY_COMMENT,
  SEND_COMMENT,
} from '@/store/comment/commentAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import RecorderStartPauseButton from '@/components/recorder/button/RecorderStartPauseButton.vue';
import {inform} from '@/utils/dialog';
import prettyMilliseconds from 'pretty-ms';

@Component({
  components: {
    RecorderStartPauseButton,
    TimerRecorder,
    StickerCommentSource, StickerLayoutSource, WButton,
  },
})
export default class CommentVideoRecorder extends Vue {
  @Prop()
  public weet!: Weet;


  private mustShow: boolean = false;
  private loading: boolean = false;
  private timerComment: number = -1;

  public mounted() {
    this.timerComment = this.time;
    setTimeout(() => {
      this.mustShow = true;
    }, 1000);
  }

  get isWebcamAvailabelForRecord() {
    return store.getters.isWebcamAivailableForRecord;
  }

  get isWebcamVideoMute() {
    return store.getters.isStickerVideoMute;
  }

  get humanTime(): string {
    const timeToComment = this.timerComment === -1 ? this.time : this.timerComment;
    let labelMilliSencond = prettyMilliseconds(timeToComment, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    if (timeToComment < 1000) {
      labelMilliSencond = '0:00';
    }
    return labelMilliSencond;
  }


  get stateRecording(): RecordingState {
    return store.getters.getRecordingState;
  }

  get stickerMediaId(): string {
    return store.getters.getStickersMediaId;
  }

  get isRecording(): boolean {
    return this.stateRecording === RecordingState.RECORDING;
  }

  public toggleRecord() {
    if (this.isRecording) {
      store.dispatch(PAUSE_RECORDING);
    } else {
      store.dispatch(START_RECORDING);
    }
  }

  public closeRecorder() {
    store.dispatch(STOP_RECORDING);
    if (store.getters.isStickerVideoMute) {
      store.dispatch(RECORD_STICKER_VIDEO_MUTE);
    }
    if (store.getters.isStickerMute) {
      store.dispatch(RECORD_STICKER_MUTE);
    }
    this.timerComment = -1;
    this.$emit('close');
  }

  @Watch('stateRecording')
  public onStateRecordingChange() {
    if (this.stateRecording === RecordingState.PAUSE) {
      this.loading = true;
    }
  }

  @Watch('stickerMediaId')
  public onStickerMediaIdEndUpload() {
    if (this.stateRecording === RecordingState.PAUSE) {
      this.sendComment();
    }
  }

  get time(): number {
    return store.getters.getTimerPlayer;
  }

  get commentIDToReply(): string {
    return store.getters.getCommentIDToReply;
  }

  public sendComment() {
    if (!this.stickerMediaId) {
      return;
    }

    let dispatchEvent = SEND_COMMENT;
    this.loading = true;
    const params = new CommentParams(this.weet.weetID, CommentTypeEnum.WEBCAM, this.timerComment);
    params.mediaID = this.stickerMediaId;
    params.filter = store.getters.getRecorderFilter;
    if (this.commentIDToReply) {
      dispatchEvent = REPLY_COMMENT;
      params.commentID = this.commentIDToReply;
    }
    store.dispatch(dispatchEvent, params).then((comment) => {
      this.loading = false;
      if (store.getters.isStickerVideoMute) {
        setActionEvent(ActionEvent.comment_mic_success, {comment_duration: store.getters.getTimerRecord});
      } else {
        setActionEvent(ActionEvent.comment_video_success, {comment_duration: store.getters.getTimerRecord});
      }
      inform(this.$t('comment.video.recorder.success').toString());
      this.closeRecorder();
    }).catch((err) => {
      this.loading = false;
      this.closeRecorder();
    });
  }
}
</script>
<style lang="scss">

.red {
  color: var(--red);
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gradiant.scss";

.stickersContainerSource {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 24px;

  .closeButton {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  .timeBox {
    font-size: 12px;
    margin-left: 2px;
    border: solid 1px var(--light2);
    background: white;
    color: var(--light2);
    border-radius: 50px;
    display: inline-block;
    max-height: 18px;
    width: 44px;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    bottom: 8px;
    left: 36px;

  }

  .labelHelp {
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
    width: 100%;
    margin-right: 32px;
    max-width: 100%;
  }

  .containerRecordLabel {
    width: 100%;

    .commandRecord {
      width: calc(100% - 152px);
      position: absolute;
      bottom: 24px;
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .buttonRecord {
        margin-left: 32px;
      }

      .timer {
        font-size: 20px;
        padding: 4px 8px 4px 8px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        margin-left: 16px;
      }
    }
  }


  .waitingUpload {
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    display: flex;
    top: 0;
    left: 0px;
    color: #FFF;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 8px;
    @extend .backgroundBlur;
  }
}
</style>
