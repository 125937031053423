<template>

  <div class="virtualScreenItemContainer">
    <div class="virtualBackgroundSeperator">
      <div class="separatorLine"></div>
      <div class="labelVirtualBackground">{{ $t('svb.title') }}</div>
      <div class="separatorLine"></div>
    </div>
    <perfect-scrollbar>
      <div class="svbItemElement" :class="screen.value===currentSVB && isSVB ?'selected':'no'"
           v-for="screen in getListSVB"
           :stepTour="screen.value"
           :key="'SVB'+screen.value"
           @click="chooseVirtualScreenCast(screen.value)">
        <img :alt="screen.label" :title="screen.label" :src="urlScreen(screen.value)"/>
      </div>
    </perfect-scrollbar>
    <div class="whiteLayer"></div>
  </div>

</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_MAIN_VIRTUAL_BACKGROUND,
  RECORD_STICKER_FULLSCREEN,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WIcon from '@/components/wrapper/w-icon.vue';
import {listOfScreenVirtualBackground} from '@/utils/filterUtils';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {getVirtualScreenVideoThumbnail} from '@/utils/util';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';


@Component({
  components: {CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource},
  computed: {},
})
export default class VirtualScreenCastPanel extends Vue {


  get currentSVB(): string {
    return store.getters.getScreenVirtualBackground;
  }

  get isRecording(): boolean {
    return !store.getters.isStartRecordingAivailable;
  }

  private urlScreen(screen: string): string {
    return getVirtualScreenVideoThumbnail(screen, true);
  }

  private chooseVirtualScreenCast(value: ScreenVirtualBackgroundEnum) {
    if (this.isRecording) {
      return;
    }
    store.dispatch(RECORD_MAIN_VIRTUAL_BACKGROUND, value);
    setActionEvent(ActionEvent.record_virtual_screen, {virtual_screen_background: value});
    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.VIRTUAL_SCREENCAST);
      // put fullscreen if need
      if (!store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }


  get getListSVB() {
    return listOfScreenVirtualBackground();
  }


  get isSVB() {
    return store.getters.getMainRecordSource === SourceVideoType.VIRTUAL_SCREENCAST;
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';

.virtualScreenItemContainer {
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;

  .ps {
    max-height: 412px;
    display: flex;
    flex-wrap: wrap;

    min-width: calc(100% + 16px);
    //padding-right: 16px;
    //margin-left: -16px;

    .svbItemElement {
      min-width: 100px;
      max-width: 100px;
      min-height: 56px;
      max-height: 56px;
      display: flex;
      position: relative;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      text-align: center;
      justify-content: center;
      align-items: center;
      background: var(--light);
      border: solid 2px var(--light1);
      margin-right: 16px;
      margin-top: 16px;
      border-radius: 4px 4px 4px 4px;
      transition: all 0.2s ease;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        border-color: var(--light2);
        transform: translate3d(0px, -2px, 0);

      }

      &.selected {
        opacity: 1;
        border-color: var(--dark);
      }

    }

  }

  .virtualBackgroundSeperator {
    display: flex;
    width: 100%;
    align-items: center;

    .labelVirtualBackground {
      color: var(--light2);
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      margin-left: 16px;
      margin-right: 16px;
    }

    .separatorLine {
      flex: 1;
      display: inline-block;

      height: 1px;
      background: var(--light1);
    }
  }

  .whiteLayer {
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1));
    // background: red;
    width: calc(100% - 32px);
    height: 40px;
    pointer-events: none;
  }
}

</style>
