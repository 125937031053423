<template>
  <b-field label-position="on-border" :label="label" :type="'is-'+color">
    <b-select :icon="icon"
              :size="'is-'+size"
              :rounded="rounded"
              :disabled="!enabled"
              v-model="realValue"
              :placeholder="placeholder"
              :required="required"
              :loading="loading"
              :expanded="expanded"
              @blur="onBlur"
              @input="onChangeValue"
              :data-test="dataTest"
    >
      <slot></slot>

    </b-select>
  </b-field>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({})
export default class WSELECT extends Vue {

  @Prop({default: ''})
  private icon!: string;
  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: ''})
  private size!: string;
  @Prop({default: ''})
  private color!: string;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: ''})
  private placeholder!: string;
  @Prop({default: ''})
  private label!: string;
  @Prop({default: ''})
  private value!: string;
  @Prop({default: false})
  private required!: boolean;
  @Prop({default: false})
  private loading!: boolean;
  @Prop({default: false})
  private expanded!: boolean;

  @Prop()
  private dataTest!: string;


  private realValue: string = '';

  private mounted() {
    this.realValue = this.value;
  }

  public onBlur() {
    this.$emit('blur',  this.realValue);
  }

  public onChangeValue() {
    this.$emit('input', this.realValue);
  }

  @Watch('value')
  public onChangePropValue() {
    this.realValue = this.value;
  }

}
</script>

<style scoped lang="scss">
</style>
