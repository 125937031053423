<template>
  <div>
    <b-dropdown :max-height="240" scrollable>
      <template #trigger="{ active }">
        <!-- workspace line -->
        <div class="workspaceLine" :style="minWidth?'min-width:'+minWidth:''">
          <WorkspaceAvatar :workspaceAvatar="avatar(getSelectedWorkspace)"
                           class="avatarIcon" v-if="getSelectedWorkspace"
                           :workspaceID="getSelectedWorkspace.workspaceID"
                           aria-role="menu"
                           type="square"
                           size="small"/>
          <span class="labelNameWorkspace" v-if="getSelectedWorkspace">{{ getSelectedWorkspace.name }}</span>

          <w-icon class="iconMenu" :icon="active ? 'chevron-up' : 'chevron-down'" color="light2"/>
        </div>
      </template>

      <!-- WS LIST -->
      <b-dropdown-item aria-role="listitem" v-for="workspace in myWorkspaces"
                       :key="'workspaceMove_' + workspace.workspaceID" class="workspaceItem"
                       :style="minWidth?'min-width:'+minWidth:''"
                       @click="selectWorkspace(workspace.workspaceID)">
        <WorkspaceAvatar :workspaceAvatar="avatar(workspace)"
                         class="avatarIcon"
                         :workspaceID="workspace.workspaceID"
                         aria-role="menu"
                         type="square"
                         size="small"
        />
        <span class="labelNameWorkspace">{{ workspace.name }}</span>
        <w-icon icon="check" class="iconCheck" color="primary" size="default"
                v-if="isWorkspaceSelected(workspace)"></w-icon>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import WModal from '@/components/wrapper/w-modal.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import {ADD_WEET_TO_WORKSPACE} from '@/store/myWeet/myWeetAction';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WorkspaceSelection from '@/components/workspace/WorkspaceSelection.vue';
import {confirmationDanger, inform} from '@/utils/dialog';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import {Workspace} from '@/store/workspace/workspaceModel';
import {getUserAvatar, getWorkspaceAvatar} from '@/utils/util';
import {User} from '@/store/user/userModel';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WError from '@/components/wrapper/w-error.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {WorkspaceRightManagmentEnum} from '@/store/workspaceRight/workspaceRightModel';

@Component({
  components: {
    WIcon,
    UserAvatar,
    WorkspaceAvatar,
    WorkspaceSelection,
    CenterCenter,
    WModal,
    PopUpTitle,
    WError
  }
})
export default class InputWorkspaceChoose extends Vue {

  @Prop({default: ''})
  private value!: string | null;

  @Prop({default: ''})
  private minWidth!: string;

  @Prop({default: false})
  private limitWorkspacesCreate!: boolean;

  private workspaceSelected: string | null = null;

  private mounted() {
    this.workspaceSelected = this.value;
  }

  get myWorkspaces(): Workspace[] {
    if (this.limitWorkspacesCreate) {
      const workspacesToDisplay: Workspace[] = [];
      for (const workspace of store.getters.getMyWorkspaces) {
        if (store.getters.getWorkspaceRightByKey(workspace.workspaceID, WorkspaceRightManagmentEnum.CREATE_WEET)) {
          workspacesToDisplay.push(workspace);
        }
      }
      return workspacesToDisplay;
    } else {
      return store.getters.getMyWorkspaces;
    }
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }

  get userImage(): string {
    return getUserAvatar(this.userConnected, 150);
  }

  private avatar(workspace: Workspace) {
    return getWorkspaceAvatar(workspace, 256);
  }

  private isWorkspaceSelected(workspace: Workspace | null): boolean {
    if (workspace) {
      return this.workspaceSelected === workspace.workspaceID;
    } else {
      return !this.workspaceSelected;
    }
  }

  get getSelectedWorkspace(): Workspace {
    return store.getters.getWorkspace(this.workspaceSelected);
  }

  private selectWorkspace(workspaceID: string | null) {
    this.workspaceSelected = workspaceID;
    this.$emit('select', workspaceID);
  }

}
</script>

<style scoped lang="scss">

@import "@/scss/gridBreakPoint.scss";

.workspaceLine {
  display: flex;
  align-items: center;
  border: solid 1px var(--light2);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  min-width: 400px;

  @include GRID1X {
    min-width: 300px;
  }

  .avatarIcon {
    margin-right: 16px;
  }

  .labelNameWorkspace {
    text-align: left;
    flex: 1;
  }

  &:hover {
    background: var(--light);
  }

  .iconMenu {

  }
}

.workspaceItem {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  min-width: 400px;

  @include GRID1X {
    min-width: 300px;
  }

  .avatarIcon {
    margin-right: 16px;
  }

  .labelNameWorkspace {
    flex: 1;
    text-align: left;
  }

  &.selected {
    background: var(--light);
  }
}

</style>
