export const GET_NEXT_NOTIFICATIONS = 'GET_NEXT_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const GET_MY_NOTIFICATIONS_STAT = 'GET_MY_NOTIFICATIONS_STAT';
export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS';

export const UNREAD_NOTIFICATIONS = 'UNREAD_NOTIFICATIONS';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';




