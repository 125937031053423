import {User} from '@/store/user/userModel';


export enum WorkspaceRightManagmentEnum {
    CREATE_WEET = 'CREATE_WEET',
    ACCESS_USER = 'ACCESS_USER',
    DELETE_USER = 'DELETE_USER',
    CREATE_USER = 'CREATE_USER',
    CREATE_CHANNEL = 'CREATE_CHANNEL',
    DELETE_CHANNEL = 'DELETE_CHANNEL',
    ACCESS_CHANNEL = 'ACCESS_CHANNEL',
    EDIT_USER = 'EDIT_USER',
    EDIT_WORKSPACE = 'EDIT_WORKSPACE',
    DELETE_WORKSPACE = 'DELETE_WORKSPACE',
    ACCESS_WEET = 'ACCESS_WEET',
    VIEW_ALL_WORKSPACE_WEETS = 'VIEW_ALL_WORKSPACE_WEETS',
    DELETE_OTHER_WEET = 'DELETE_OTHER_WEET',
    MANAGE_PAYMENTS = 'MANAGE_PAYMENTS',
    MOVE_WEET = 'MOVE_WEET'
}

export interface WorkspaceRightManagment {
    key: WorkspaceRightManagmentEnum;
    value: boolean;
}

export enum WorkspaceUserRoleEnum {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    VIEWER = 'VIEWER',
    CREATOR = 'CREATOR',
    CUSTOM = 'CUSTOM',
}
