<template>
  <div>

    <transition name="fade">
      <div class="startPanel" v-show="!loading">
        <div class="closeButton" v-show="!isRecording">
          <w-button icon="close" color="text" size="small" @click="closeRecorder"/>
        </div>
        <div class="previewPanel">
          <VideoSource @error="onErrorAccessScreen" :mediaCreatorMode="true"
                       :screen-mic-mode="true"
                       @mediaLoaded="medialoaded" :weetID="weet.weetID"
                       :type="sourceVideo.SCREENCAST" @killSource="toggleRecord"/>

        </div>
        <div class="timeBox" v-if="commentIDToReply===''">{{ humanTime }}</div>
        <div class="containerRecordLabel">
          <Transition name="zoom">
            <div class="labelHelp" v-show="!isRecording && showButtonRecord"
                 v-html="$t('comment.video.recorder.start.screen')">
            </div>
          </Transition>
          <Transition name="zoom">
            <div class="commandRecord" v-show="showButtonRecord">
              <RecorderStartPauseButton class="buttonRecord" @click="toggleRecord"/>
              <div class="timer">
                <TimerRecorder/>
              </div>
            </div>
          </Transition>
          <transition name="fade">
            <div class="waitingUpload" v-show="loading ">
              {{ $t('comment.screen.record.wait') }}
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {
  CommentParams,
  REPLY_COMMENT,
  SEND_COMMENT
} from '@/store/comment/commentAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {Weet} from '@/store/weet/weetModel';
import {PLAYER_PLAYING, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import WButton from '@/components/wrapper/w-button.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import {PAUSE_RECORDING, START_RECORDING, STOP_RECORDING} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import TimerRecorder from '@/components/recorder/timer/TimerRecorder.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import prettyMilliseconds from 'pretty-ms';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {isChromium} from '@/utils/extentionUtil';
import {alertMessage, inform} from '@/utils/dialog';
import RecorderStartPauseButton from '@/components/recorder/button/RecorderStartPauseButton.vue';

@Component({
  components: {
    RecorderStartPauseButton,
    WModal, TimerRecorder, CenterCenterPanel, VideoSource, StickerLayoutSource, WButton
  }
})
export default class CommentScreenRecorder extends Vue {
  @Prop()
  public weet!: Weet;

  @Prop({default: 'default'})
  public size!: string;

  private loading: boolean = false;
  private showButtonRecord: boolean = false;

  private error: string = '';
  private timerComment: number = -1;

  get commentIDToReply(): string {
    return store.getters.getCommentIDToReply;
  }

  get mainCurrentMediaID(): string {
    return store.getters.getMainMediaId;
  }

  get humanTime(): string {
    const timeToComment = this.timerComment === -1 ? this.time : this.timerComment;
    let labelMilliSencond = prettyMilliseconds(timeToComment, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    if (timeToComment < 1000) {
      labelMilliSencond = '0:00';
    }
    return labelMilliSencond;
  }

  public medialoaded() {
    this.showButtonRecord = true;
  }

  public mounted() {
    this.timerComment = this.time;
  }

  public onErrorAccessScreen() {
    setActionEvent(ActionEvent.comment_screen_cancel);
    this.$emit('close');
  }

  get sourceVideo(): any {
    return SourceVideoType;
  }

  get time(): number {
    return store.getters.getTimerPlayer;
  }


  public closeRecorder() {
    store.dispatch(STOP_RECORDING);
    this.timerComment = -1;
    this.$emit('close');
  }


  public toggleRecord() {
    if (this.isRecording) {
      store.dispatch(PAUSE_RECORDING);
    } else {
      store.dispatch(START_RECORDING);
    }
  }


  public startScreenCommentRecording() {
    if (!isChromium()) {
      alertMessage(this.$t('generic.capture.unaivailable').toString());
      return;
    }
    this.pauseVideo();
    store.dispatch(TIMER_RECORD, 0);
    setActionEvent(ActionEvent.comment_screen_start, {player_time: this.time});
  }

  public pauseVideo() {
    store.dispatch(PLAYER_PLAYING, false);
  }

  public beforeDestroy() {
    store.dispatch(STOP_RECORDING);
  }

  get stateRecording(): RecordingState {
    return store.getters.getRecordingState;
  }

  get isRecording(): boolean {
    return this.stateRecording === RecordingState.RECORDING;
  }

  get mainMediaId(): string {
    return store.getters.getMainMediaId;
  }

  @Watch('stateRecording')
  public onStateRecordingChange() {
    if (this.stateRecording === RecordingState.PAUSE) {
      this.loading = true;
    }
  }

  @Watch('mainMediaId')
  public onStickerMediaIdEndUpload() {
    if (this.stateRecording === RecordingState.PAUSE) {
      this.sendComment();
    }
  }

  public async sendComment() {

    if (!this.mainMediaId) {
      return;
    }
    let dispatchEvent = SEND_COMMENT;
    const params = new CommentParams(this.weet.weetID, CommentTypeEnum.SCREEN, this.time);
    params.mediaID = this.mainMediaId;
    if (store.getters.getCommentIDToReply) {
      dispatchEvent = REPLY_COMMENT;
      params.commentID = store.getters.getCommentIDToReply;
    }


    store.dispatch(dispatchEvent, params).then((comment) => {
      this.loading = true;
      setActionEvent(ActionEvent.comment_screen_success, {comment_duration: store.getters.getTimerRecord});
      this.loading = false;
      inform(this.$t('comment.screen.record.success').toString());
      this.closeRecorder();
    }).catch((err) => {
      this.loading = false;
      this.closeRecorder();
    });
  }

}
</script>
<style lang="scss">

.red {
  color: var(--red);
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/gradiant.scss";

.labelStopRecord {
  color: #FFF;
  font-size: 65px;
}

.closeButton {
  position: absolute;
  right: -4px;
  top: -4px;
}

.timeBox {
  font-size: 12px;
  margin-left: 2px;
  border: solid 1px var(--light2);
  background: white;
  color: var(--light2);
  border-radius: 50px;
  display: inline-block;
  max-height: 18px;
  width: 44px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  bottom: 8px;
  left: 44px;

}

.startPanel {
  position: relative;
  padding: 8px;
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;

  .previewPanel {
    max-width: 120px;
    max-height: 120px;
    min-height: 120px;
    min-width: 120px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  .labelHelp {
    position: absolute;
    top: 24px;
    font-size: 14px;
    margin-left: 0px;
    text-align: center;
    width: calc(100% - 120px);
    margin-right: 16px;
    max-width: calc(100% - 120px);
  }

  .commandRecord {
    position: absolute;
    bottom: 16px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);;

    .buttonRecord {
      margin-left: 24px;
    }

    .timer {
      font-size: 20px;
      padding: 4px 8px 4px 8px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8px;
      margin-left: 16px;
    }
  }
}

.waitingUpload {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  display: flex;
  top: 0;
  left: 0px;
  color: #FFF;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  @extend .backgroundBlur;
}


</style>
