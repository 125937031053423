<template>
  <transition name="zoom">
    <div class="mainTitle" v-show="mustShow">
      <div class="blocktitle">
        {{title}}
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class PopUpTitle extends Vue {
  @Prop({default: 'Your title'}) private title!: string;

  private mustShow: boolean = false;

  public mounted() {
    this.mustShow = true;
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';

.mainTitle {
  display: flex;
  animation-duration: 0.2s;
  align-items: center;
  margin-bottom: 8px;
  color: var(--black);
  @include GRID1X {
    display: block;
  }
}

.blocktitle {
  text-align: center;
  text-transform: capitalize;
  padding-left: 0px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 900;
  flex-grow: 1;
  margin: 0;
}


</style>
