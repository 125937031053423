import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {CommentEvent} from '@/store/comment/commentModel';
import store from '@/store';

export const commentForSecond = (time: number): CommentEvent[] => {
  const commentsToFilter = store.getters.getComments;

  return commentsToFilter.filter((comment: CommentEvent) => {
    // fix issue on comment start @zero
    const commentTime = comment.time;
    return (comment.type === CommentTypeEnum.EMOTE
        || comment.type === CommentTypeEnum.WEBCAM
        || comment.type === CommentTypeEnum.SCREEN
        || comment.type === CommentTypeEnum.TEXT)
      && !comment.replyToCommentID
      && commentTime >= time && commentTime < time + 1000;
  });

};
