import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {createImageBitmapForUrlBackground, cropAndScaleVideo} from '@/utils/video/canvasUtils';
import {SelfieSegmentation} from '@/worker/selfieSegementation';


export class VirtualBackgroundTransformer implements ICanvasTransform {
    public initialised: boolean = false;
    private id: string = uuidv4();
    private width: number;
    private height: number;
    private urlVideo: string;
    private backgroundColor: string;
    private backgroundColor2: string;
    private urlImage: string;
    private frame: ImageBitmap | null = null;
    private counterOfFrameGenerated: number = 0;
    private video: HTMLVideoElement | null = null;
    private selfie!: SelfieSegmentation;

    constructor(width: number, height: number, urlVideo,
                backgroundColor: string,
                backgroundColor2: string,
                urlImage: string) {
        this.width = width;
        this.height = height;
        this.urlVideo = urlVideo;
        this.backgroundColor = backgroundColor;
        this.backgroundColor2 = backgroundColor2;
        this.urlImage = urlImage;
    }

    public async init(stream: MediaStream, highPerf: boolean) {
        this.selfie = new SelfieSegmentation(highPerf);
        await this.selfie.init();
        this.initialised = true;

        if (this.urlImage) {
            this.frame = await createImageBitmapForUrlBackground(this.id, this.width, this.height, this.urlImage);
        }
        if (this.urlVideo) {
            // we have to put the stream in a video to keep it alive
            this.video = document.createElement('video');
            this.video.id = this.id.toString();
            this.video.autoplay = true;
            this.video.style.zIndex = '-1';
            this.video.style.position = 'absolute';
            this.video.style.top = '0';
            this.video.style.opacity='0';
            this.video.style.left = '0';
            this.video.loop = true;
            document.body.append(this.video);
            this.video.src = this.urlVideo;
            this.video.muted = true;

            await new Promise<void>((resolve, reject) => {
                if (this.video) {
                    this.video.onplay = () => {
                        resolve();
                    };
                }
            });
        }
        log.debug('Initialise VirtualBackgroundTransformer');
    }

    public async start() {
        log.debug('start VirtualBackgroundTransformer');
        return;
    }

    public async transform(sleepMode: boolean, input: ImageBitmap | null): Promise<ImageBitmap | null> {
        try {
            const dateStart = new Date().getTime();
            // console.log('imageGrabbed', new Date().getTime() - dateStart);
            let imageGenerated: ImageBitmap | null = null;
            if (!sleepMode && input && input.width > 0) {
                this.selfie.inputFrame(input);
                if (this.video) {
                    // if (!this.frame || this.counterOfFrameGenerated % 2 === 0) {
                    if (this.video.videoHeight > 0) {
                        if (this.video.width !== this.video.videoWidth
                            || this.video.height !== this.video.videoHeight) {
                            this.video.width = this.video.videoWidth;
                            this.video.height = this.video.videoHeight;
                        }
                        this.frame = await cropAndScaleVideo(this.video, this.width, this.height);
                        imageGenerated = await this.selfie.getVirtualBackgroundFrame(input, this.frame);
                    }

                } else if (this.urlImage) {
                    if (this.frame) {
                        imageGenerated = await this.selfie.getVirtualBackgroundFrame(input, this.frame);
                    }
                } else {
                    imageGenerated = await this.selfie.getVirtualBackgroundColorFrame(input, this.backgroundColor, this.backgroundColor2);
                }
                this.counterOfFrameGenerated++;
            }

            return imageGenerated;
        } catch (e: any) {
            this.initialised = true;
            throw new Error('RENDER_ERROR: ' + e.toString());
        }
    }


    public async flush() {
        log.debug('Flush VirtualBackgroundTransformer');
        if (this.video) {
            this.video.remove();
        }
    }
}
