<template>
  <b-upload v-model="file" @input="onFileInput" accept="image/*" class="uploadContainer">
    +
  </b-upload>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import store from '@/store';
import {UPLOAD_VIRTUAL_BACKGROUND} from '@/store/settingUsers/settingUserAction';


@Component({
  components: {WHelp, WSwitch, CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource},
  computed: {},
})
export default class CustomVBUploader extends Vue {

  @Prop()
  private virtualBackground!: string;

  private file: any = null;

  private onFileInput() {
    const reader = new FileReader();
    reader.onload = (e) => {
      // @ts-ignore
      const blob = new Blob([new Uint8Array(e.target.result)], {type: this.file.type});

      store.dispatch(UPLOAD_VIRTUAL_BACKGROUND, {key: this.virtualBackground, blob}).then(() => {
        this.$emit('uploaded');
      });
    };
    reader.readAsArrayBuffer(this.file);
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';

.uploadContainer {
  color: var(--dark);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

</style>
