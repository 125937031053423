import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {SelfieSegmentation} from '@/worker/selfieSegementation';


export class BlurBackgroundTransformer implements ICanvasTransform {
    public initialised: boolean = false;
    private id: string = uuidv4();
    private counterFrame: number = 0;
    private width: number;
    private height: number;
    private selfie!: SelfieSegmentation;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    public async init(stream: MediaStream, highPerf: boolean) {
        this.selfie = new SelfieSegmentation(highPerf);
        await this.selfie.init();
        this.initialised = true;
        log.debug('Initialise BlurBackgroundTransformer highPerf:' + highPerf);
    }

    public async start() {
        log.debug('start BlurBackgroundTransformer');
        return;
    }

    public async transform(sleepMode: boolean, input: ImageBitmap | null): Promise<ImageBitmap | null> {
        try {

            const dateStart = new Date().getTime();
            let imageGenerated: ImageBitmap | null = null;

            if (!sleepMode && input && input.width > 0) {
                this.counterFrame++;
                // console.log('imageGrabbed', new Date().getTime() - dateStart);
                // if (this.counterFrame % 2 === 0) {
                this.selfie.inputFrame(input);

                // }
                imageGenerated = await this.selfie.getBlurFrame(input);
                // console.log('imageWrited', new Date().getTime() - dateStart);
            }
            return imageGenerated;
        } catch (e: any) {
            this.initialised = true;
            throw new Error('RENDER_ERROR: ' + e.toString());
        }
    }


    public async flush() {
        log.debug('Flush BlurBackgroundTransformer');
        // destroyBlurEngine(this.id);
    }
}
