import * as Blazeface from '@tensorflow-models/blazeface';
import * as tfjs from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-wasm';
import {setWasmPaths} from '@tensorflow/tfjs-backend-wasm';

let model;
const offScreenCanvases = {};
const contexts = {};


export const setUpOffscreenCanvasForFaceCompute = async (id: string, width: number, height: number) => {
    offScreenCanvases[id] = new OffscreenCanvas(width, height);
    contexts[id] = offScreenCanvases[id].getContext('2d', {
        alpha: false,
        antialias: false,
        desynchronized: true,
        preserveDrawingBuffer: false,
    });
};

export const ensureBlazeModelIsLoad = async () => {
    if (!model) {

        setWasmPaths('/wasm/');
        await tfjs.setBackend('wasm');
        model = await Blazeface.load();
    }
};


export const computeSegementForFaceTrack = async (params: ImageBitmap, id: string) => {
    const canvas = offScreenCanvases[id];
    canvas.width = params.width;
    canvas.height = params.height;
    const ctx = contexts[id];
    await ctx.drawImage(params, 0, 0);
    return await model.estimateFaces(canvas, false, false, false);
};


export const destroyOffscreenCanvasForFaceCompute = (id: string) => {
    delete offScreenCanvases[id];
    delete contexts[id];
};
