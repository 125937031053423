<template>
  <div class="workspaceSelector">
    <b-dropdown scrollable :max-height="300">
      <template #trigger="{ active }">
        <a href="#" class="linkOpenWorkspaceSelection">{{ workspaceNameSelected }}
          <w-icon size="small" icon="chevron-down"/>
        </a>
      </template>
      <b-dropdown-item @click="selectWorkspace()" paddingless
                       :key="'workspaceSelector'+'personnal'">
        <div class="workspaceLine">
          <UserAvatar class="workspaceAvatar"
                      :userAvatar="userImage"
                      :user-firstname="$t('generic.personnalWorkspace')"
                      type="square"
                      :selected="!selectedWorkspaceID"
          />
          {{ $t('generic.personnalWorkspace') }}
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="selectWorkspace(work.workspaceID)" v-for="work in myWorkspaces"
                       :key="'workspaceSelector'+work.workspaceID" paddingless>
        <div class="workspaceLine">
          <WorkspaceAvatar class="workspaceAvatar"
                           :selected="work.workspaceID===selectedWorkspaceID"
                           :workspaceAvatar="getWorkspaceAvatar(work)"
                           type="square"/>
          {{ work.name }}
        </div>
      </b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {Workspace} from '@/store/workspace/workspaceModel';
import store from '@/store';
import WSelect from '@/components/wrapper/w-select.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {getUserAvatar, getWorkspaceAvatar} from '@/utils/util';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {User} from '@/store/user/userModel';

@Component({
  components: {UserAvatar, WSelect, WIcon, WButton, WorkspaceAvatar},
})
export default class WorkspaceSelection extends Vue {

  get userImage(): string {
    return getUserAvatar(this.userConnected, 150);
  }

  get workspaceNameSelected() {
    if (this.selectedWorkspace) {
      return this.selectedWorkspace.name;
    } else {
      return this.$t('generic.personnalWorkspace').toString();
    }
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }

  get myWorkspaces(): Workspace[] {
    return store.getters.getMyWorkspaces as Workspace[];
  }

  get selectedWorkspaceID(): Workspace | undefined {
    return store.getters.getSelectedWorkspaceID;
  }

  get selectedWorkspace(): Workspace | undefined {
    return store.getters.getSelectedWorkspace;
  }

  private getWorkspaceAvatar(workspace: Workspace) {
    return getWorkspaceAvatar(workspace, 128);
  }

  private selectWorkspace(workspaceID: string | null) {
    this.$emit('filterWorkspace', workspaceID);
  }

}
</script>

<style scoped lang="scss">

.workspaceSelector {
  margin-bottom: 8px;
  width: 100%;
  text-align: right;

  .linkOpenWorkspaceSelection {
    font-size: 14px;
  }

  .workspaceLine {
    display: flex;
    font-size: 14px;
    align-items: center;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 8px;

    .workspaceAvatar {
      margin-right: 16px;
    }

    &.selected {
      background: var(--light);
      font-weight: 900;
    }
  }
}
</style>
