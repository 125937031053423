import axios from 'axios';
import Vue from 'vue';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {Transcript, Word} from '@/store/transcript/transcriptModel';
import {
  LOAD_TRANSCRIPT,
  TRANSCRIPT_SAVE,
  UPDATE_MEDIAID_TRANSCRIPT,
  UPDATE_TRANSCRIPT
} from '@/store/transcript/transcriptAction';
import {MediaIDUpdateParams} from '@/store/timeLine/timeLineAction';

export const TRANSCRIPT_ENDPOINT = BACK_ENDPOINT + '/transcript';

export default {
  state: {
    // Use for transcript Recorder
    transcriptWords: {},
  },
  mutations: {
    [UPDATE_TRANSCRIPT]: (state: any, {words, mediaID}: { words: Word[], mediaID }) => {
      Vue.set(state.transcriptWords, mediaID, words);
    },
    [UPDATE_MEDIAID_TRANSCRIPT]: (state: any, params: MediaIDUpdateParams) => {
      const words = state.transcriptWords[params.oldID];
      Vue.set(state.transcriptWords, params.media.mediaID, words);
      state.transcriptWords[params.media.mediaID] = words;
      Vue.delete(state.transcriptWords, params.oldID);
    },
  },
  getters: {
    getWordsForMedia: (state: any) => (mediaID: string): Word[] => {
      const data = state.transcriptWords[mediaID];
      if (data) {
        return data;
      } else {
        return [];
      }
    },
  },
  actions: {
    async [LOAD_TRANSCRIPT]({commit, dispatch}: { commit: any, dispatch: any }, {
      mediaID, weetID
    }: { mediaID: string, weetID: string }) {

      return await axios(
        {
          url: TRANSCRIPT_ENDPOINT + '/' + weetID + '/' + mediaID,
          method: 'GET',
        }).then(async (resp) => {
        return resp.data as Transcript;
      }).catch((err) => {
        console.error(err);
        throw err;
      });
    },
    [UPDATE_TRANSCRIPT]({commit, dispatch}: { commit: any, dispatch: any }, {
      words,
      mediaID
    }: { words: Word[], mediaID: string }) {
      commit(UPDATE_TRANSCRIPT, {words, mediaID});
    },
    [UPDATE_MEDIAID_TRANSCRIPT]({
                                  commit,
                                  dispatch
                                }: { commit: any, dispatch: any },
                                mediaChangeParames: MediaIDUpdateParams) {
      commit(UPDATE_MEDIAID_TRANSCRIPT, mediaChangeParames);
    },
    async [TRANSCRIPT_SAVE]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                            {mediaID, weetID}: { mediaID: string, weetID: string }) {
      const words = getters.getWordsForMedia(mediaID);
      if (words.length <= 0) {
        return;
      } else {

        const transcript = new Transcript();
        transcript.words = words;
        transcript.mediaID = mediaID;
        transcript.lang = getters.getTranscriptLang;
        return await axios(
          {
            url: TRANSCRIPT_ENDPOINT + '/' + weetID + '/' + mediaID,
            method: 'POST',
            data: {
              transcript: transcript
            }
          }).then(async (resp) => {
          return resp.data as Transcript;
        }).catch((err) => {
          console.error(err);
          throw err;
        });
      }
    },
  },
};
