<template>

  <div class="filterItemContainer">
    <!-- Filter -->
    <div class="titleBackgroundSeperator">
      <div class="separatorLine"></div>
      <div class="labelVirtualBackground">{{ $t('filterPanel.filter') }}</div>
      <w-help :label="$t('filterPanel.disclaimer')" class="helpContainer"></w-help>
      <div class="separatorLine"></div>
    </div>
    <div class="scrollBox">
      <perfect-scrollbar :options="{suppressScrollX:true}" class="scrollContainer">
        <div class="filterContainer">

          <w-tool-tip :label="$t('filterPanel.noFilter')" :position="'top'">
            <div class="filterItemElement" @click="noFilter()"
                 :class="isNoFilter?'selected':'no'">
              <w-icon icon="cancel"/>
            </div>
          </w-tool-tip>

          <w-tool-tip :label="'filterPanel.bottom_left'" :position="'top'"
                      v-if="!isWebCamFull && isExperimentalFeature&&false">
            <div class="filterItemElement" @click="webCamIntegration(1)"
                 :class="isNoFilter?'selected':'no'">
              <w-icon icon="table-account"/>
            </div>
          </w-tool-tip>
          <!-- Filter -->
          <div class="" v-for="filter in getListFilter" :key="'F_'+filter.value"
               @click="setFilter(filter.value)" v-if="!isWebCamFull?true:filter.full">
            <div class="boxFilter">
              <div class="asterix" v-if="!filter.exportAivailable">
                *
              </div>
              <w-tool-tip :label="getLabelForFilter(filter.value)" position="top">
                <div class="filterItemElement" :steptour="filter.value"
                     :class="filter.value===currentFilter?'selected':'no'">
                  <div class="avatarBackground" :style="'background-image:url('+getUserAvatar+')'"
                       :class="classFilter(filter.value)"></div>
                  <div class="mask"></div>
                  <div class="layer1"></div>
                  <div class="layer2"></div>
                  <div class="layer3"></div>
                  <div class="layer4"></div>
                  <div class="newFilter" v-if="filter.new">New</div>
                </div>
              </w-tool-tip>
            </div>
          </div>
        </div>

      </perfect-scrollbar>
      <div class="whiteLayer">

      </div>
    </div>
    <!-- SPLIT -->

    <div class="titleBackgroundSeperator">
      <div class="separatorLine"></div>
      <div class="labelVirtualBackground">{{ $t('filterPanel.highPerformance') }}</div>
      <w-help :label="$t('filterPanel.highPerformanceToolTip')" class="helpContainer"></w-help>
      <div class="separatorLine"></div>
    </div>


    <div class="scrollBox">
      <perfect-scrollbar :options="{suppressScrollX:true}" class="scrollContainer">
        <div class="filterContainer">
          <!-- VB -->
          <div class="" v-for="vb in getListVB" :key="'VB_'+vb.value"
               style="position: relative">

            <div class="filterItemElement" @click="setVirtualBackground(vb.value)"
                 :class="vb.value===currentVB?'selected':'no'"
            >
              <w-tool-tip :label="getLabelForFilter(vb.value)" position="top">
                <div v-if="vb.value!=='BLUR' && vb.type!=='custom'" class="avatarBackground"
                     :style="'background-image:url('+vb.img+');filter:contrast(1.2)'"/>
                <div v-else-if="vb.type==='custom'" class="avatarBackground"
                     :style="'background-image:url('+getCustomVB(vb)+');'"></div>
                <div v-else class="avatarBackground"
                     :style="'background-image:url('+getUserAvatar+');filter:blur(3px)'"/>
              </w-tool-tip>
            </div>
            <div class="uploader" v-if="vb.type==='custom'">
              <CustomVBUploader :virtual-background="vb.value" @uploaded="setVirtualBackground(vb.value)"/>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="whiteLayer">

      </div>
    </div>
    <!-- Facetrack -->
    <div class="faceTrack" v-if="!isWebCamFull">
      <div class="faceTrackLabel">{{ $t('filterPanel.faceTrack') }}
        <w-help :label="$t('filterPanel.faceTrackAdvise')" class="helpContainer"></w-help>
      </div>
      <w-switch :enabled="isWebcamAvailabelForRecord" class="faceTrackSwitch" size="small"
                @input="setFaceTrack"
                :value="isFaceTrack"/>
    </div>


  </div>

</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import store from '@/store';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_STICKER_FACE_TRACK,
  RECORD_STICKER_FILTER, RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_INTEGRATION, RECORD_STICKER_POSITION, RECORD_STICKER_VIDEO_MUTE, RECORD_STICKER_VIDEO_TYPE,
  RECORD_STICKER_VIRTUAL_BACKGROUND,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WIcon from '@/components/wrapper/w-icon.vue';
import {getImageForVBCustom, listOfFilter, listOfVirtualBackground} from '@/utils/filterUtils';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {SETUP_FAVORITE_FACE_TRACK, SETUP_FAVORITE_FILTER} from '@/store/setup/setupAction';
import {getUserAvatar} from '@/utils/util';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import CustomVBUploader from '@/components/recorder/sticker/buttons/CustomVBUploader.vue';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';


@Component({
  components: {CustomVBUploader, WHelp, WSwitch, CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource},
  computed: {},
})
export default class FilterPanel extends Vue {


  get isWebCamFull(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.WEBCAM;
  }

  get isFaceTrack(): boolean {
    return store.getters.getStickerFaceTrack;
  }

  get isWebcamAvailabelForRecord() {
    return store.getters.isWebcamAivailableForRecord;
  }

  get isExperimentalFeature() {
    return store.getters.isExperimentalFeature;
  }


  private getLabelForFilter(filter: string) {
    const nonExportableLabel = this.$t("filterPanel.nonExportable")
    for (const f of this.getListFilter) {
      if (f.value === filter) {
        let label = f.label;
        if (!f.exportAivailable) {
          label += " <br/>" + nonExportableLabel + "";
        }
        return label;
      }
    }

    for (const f of this.getListVB) {
      if (f.value === filter) {
        return f.label;
      }
    }
    return this.$t('filterPanel.noFilter').toString();
  }

  get currentFilter(): string {
    return store.getters.getRecorderFilter;
  }

  get getUserAvatar(): string {
    return getUserAvatar(store.getters.userConnected, 50);
  }

  private getCustomVB(vb): string {
    return getImageForVBCustom(vb.value);
  }

  private classFilter(filter: string): string {
    return filter + ' noAnimation';
  }

  get currentVB(): string {
    return store.getters.getVirtualBackground;
  }

  get isVideoMute(): boolean {
    return store.getters.isStickerVideoMute;
  }

  get isStickerHided(): boolean {
    return store.getters.getStickerPosition === StickerPosition.NONE;
  }

  private async setVirtualBackground(value: string) {
    if (!this.isWebcamAvailabelForRecord) {
      return;
    }
    store.dispatch(RECORD_STICKER_VIRTUAL_BACKGROUND, value);
    store.dispatch(SETUP_FAVORITE_FILTER, value);
    setActionEvent(ActionEvent.record_button_filter, {virtualBackground: value});
    this.$emit('choosed');
  }

  private setFilter(value: string) {
    if (!this.isWebcamAvailabelForRecord) {
      return;
    }
    // remove fullscreen if need
    if (store.getters.isStickerFullscreen) {
      // store.dispatch(RECORD_STICKER_FULLSCREEN);
      store.dispatch(RECORD_STICKER_POSITION, StickerPosition.BOTTOM_LEFT);
    }
    // activate webcam if need
    if (store.getters.isStickerVideoMute) {

      store.dispatch(RECORD_STICKER_VIDEO_MUTE);
    }


    store.dispatch(RECORD_STICKER_FILTER, value);
    store.dispatch(SETUP_FAVORITE_FILTER, value);
    setActionEvent(ActionEvent.record_button_filter, {filter: value});
    this.$emit('choosed');
  }

  private setFaceTrack(value: string) {
    if (!this.isWebcamAvailabelForRecord) {
      return;
    }
    store.dispatch(RECORD_STICKER_FACE_TRACK, value);
    store.dispatch(SETUP_FAVORITE_FACE_TRACK, value);
    setActionEvent(ActionEvent.record_button_facetrack, {faceTrack: value});
    this.$emit('choosed');
  }

  private noFilter() {
    if (!this.isWebcamAvailabelForRecord) {
      return;
    }
    store.dispatch(RECORD_STICKER_FILTER, FilterVideoType.NONE);
    store.dispatch(SETUP_FAVORITE_FILTER, FilterVideoType.NONE);
    setActionEvent(ActionEvent.record_button_filter, {filter: FilterVideoType.NONE});
    this.$emit('choosed');
  }

  private webCamIntegration(type: number) {
    let integration = WebCamIntegrationTypeEnum.NONE;
    if (type === 1) {
      integration = WebCamIntegrationTypeEnum.LEFT;
    }
    store.dispatch(RECORD_STICKER_INTEGRATION, integration);
    store.dispatch(SETUP_FAVORITE_FILTER, integration);
    setActionEvent(ActionEvent.record_button_filter, {filter: integration});


    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.WEBCAM);
    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.SCREENCAST);
      store.dispatch(RECORD_STICKER_POSITION, StickerPosition.NONE);
      // remove fullscreen if need
      if (store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });

    this.$emit('choosed');
  }


  get getListFilter() {
    return listOfFilter();
  }

  get getListVB() {
    return listOfVirtualBackground();
  }

  get isNoFilter() {
    return this.currentVB === VirtualBackgroundEnum.NONE && this.currentFilter === VirtualBackgroundEnum.NONE;
  }

}
</script>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';

.filterItemContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 8px;

  .asterix {
    position: absolute;
    margin-left: 58px;
    color: var(--dark);
    font-size: 14px;
  }

  .scrollBox {
    position: relative;

    .scrollContainer {
      max-height: 164px;
      min-width: calc(100% + 16px);

      .filterContainer {
        margin-left: -16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: -8px;
        margin-bottom: 16px;
        padding-bottom: 32px;
        position: relative;
      }
    }

    .whiteLayer {
      position: absolute;
      bottom: 0;
      background: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1));
      width: 100%;
      height: 40px;
      pointer-events: none;
    }
  }


  .filterItemElement {
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: var(--light);
    margin-left: 16px;
    margin-top: 16px;
    border: solid 2px var(--light1);
    border-radius: 44px 44px 44px 8px;
    transition: all 0.2s ease;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      border-color: var(--light2);
      transform: translate3d(0px, -2px, 0);

    }

    .newFilter {
      position: absolute;
      margin-left: 0px;
      background: var(--primary);
      width: 40px;
      text-align: left;
      height: 14px;
      color: white;
      font-size: 10px;
      padding-left: 4px;
      margin-top: 30px;
    }


    &.selected {
      opacity: 1;
      border-color: var(--primary);
    }

    .avatarBackground {
      width: 44px;
      height: 44px;
      background-size: cover;
      background-position: center;

    }

    .fakeAvatar {
      color: white;
      position: absolute;
    }
  }

  .uploader {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: var(--light);
    @extend .shadow1;
    top: 12px;
    right: -6px;
    cursor: pointer;
    opacity: 1;
    line-height: 24px;
    transition: transform 0.1s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .titleBackgroundSeperator {
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;

    .labelVirtualBackground {
      color: var(--light2);
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0.3px;
      margin-left: 8px;
      margin-right: 4px;
      text-transform: uppercase;
    }

    .helpContainer {
      margin-right: 8px;
    }

    .separatorLine {
      flex: 1;
      display: inline-block;
      height: 1px;
      background: var(--light1);
    }
  }


  .faceTrack {
    text-align: left;
    display: flex;
    color: var(--light2);
    border-top: solid 1px var(--light);
    width: 100%;
    margin-bottom: 0px;
    margin-top: 8px;
    padding-top: 16px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    // text-transform: uppercase;
    justify-content: flex-end;

    .faceTrackLabel {
      width: 100%;
      display: flex;
      margin-right: 8px;
      align-content: center;

      .helpContainer {
        margin-left: 4px;
      }
    }

    .faceTrackSwitch {
      margin-right: -16px;
    }
  }


}

</style>
