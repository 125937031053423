import {
    CLEAR_MAIN_MEDIAID,
    CLEAR_STICKER_MEDIAID,
    CREATE_KEY_FRAME,
    GENERATE_MAIN_MEDIAID,
    GENERATE_STICKERS_MEDIAID,
    NEW_SOUND_DETECT,
    PAUSE_RECORDING,
    PREPOPULATE_SHARED_EMAILS,
    PREPOPULATE_WEET_TITLE,
    RECORD_MAIN_VIDEO_TYPE,
    RECORD_MAIN_VIRTUAL_BACKGROUND,
    RECORD_STICKER_FACE_TRACK,
    RECORD_STICKER_FILTER,
    RECORD_STICKER_FULLSCREEN,
    RECORD_STICKER_MUTE,
    RECORD_STICKER_POSITION,
    RECORD_STICKER_VIDEO_MUTE,
    RECORD_STICKER_VIDEO_TYPE,
    RECORD_STICKER_VIRTUAL_BACKGROUND,
    RECORD_WEBCAM_AIVAILABLE,
    SET_CURRENT_EDITING_WEET,
    SET_MAIN_TIME_RECORDING,
    SET_SCREEN_SURFACE,
    SET_STICKERS_TIME_RECORDING,
    SET_CHANNELS_FOR_EDITING,
    SHOW_POPUP_CREATION,
    START_RECORDING,
    STOP_RECORDING,
    UPDATE_MAIN_RUNNING_STATE,
    UPDATE_MEDIA_CONTROL_CHANGE,
    UPDATE_MEDIA_ID_RECORDING_STATE,
    UPDATE_STICKERS_RUNNING_STATE,
    RECORD_STICKER_INTEGRATION,
    ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,
    CHANGE_STEP_AI_GENERATION,
    AVATAR_AI_TEXT,
    SAVE_FLAT_WEET, VOICE_OVER_TEXT,
} from '@/store/recordingState/recordStateAction';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {v4 as uuidv4} from 'uuid';
import {MediaIDUpdateParams, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import {TimeEventType} from '@/enum/TimeEventType';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import {SegmentOfSound} from '@/utils/audioEffect/soundSegmenter';
import {Segment} from '@/store/timeLine/timeEvent';
import {Channel} from '@/store/channel/channelModel';
import Vue from 'vue';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';
import {Weet} from "@/store/weet/weetModel";
import stringify from "json-stable-stringify";


export default {
    state: {
        webcamRecordAivailable: false,
        // Type of media for the main video
        mainRecordSource: SourceVideoType.NONE,
        // type of media for the sticker
        stickerRecordSource: SourceVideoType.WEBCAM,
        // Recording state
        recording: RecordingState.NONE,
        // Sticker parameters
        stickerFullscreen: false,
        stickerPostion: StickerPosition.BOTTOM_LEFT,
        videoFilter: FilterVideoType.NONE,
        faceTrack: false,
        virtualBackground: VirtualBackgroundEnum.NONE,
        webcamIntegration: WebCamIntegrationTypeEnum.NONE,
        screenVirtualBackground: ScreenVirtualBackgroundEnum.SPACE,
        screenSurface: null,
        stickerRecordingTime: 0,
        stickerRecordingMediaID: '',
        stickerMute: false,
        stickerVideoMute: false,
        mainRecordingTime: 0,
        mainRecordingMediaID: '',
        keyFrameNeed: false,
        segmentSound: {},
        mediaControlEvent: TimeEventType.NONE, // stock the last change in videoFile
        mainVideoRunning: false,
        stickerVideoRunning: false,
        editingWeetID: '',
        showPopUpCreation: false,
        // The weet title prepopulated from slack/teams
        weetTitle: null,
        // The shared emails prepopulated from slack
        sharedEmails: [],
        editingChannels: {},
        annotationSaveInProgress:false,
        aiAvatarStep:0,
        aiAvatarText:"",
        aiVoiceOverText:"",
        flattenWeet:"" // weet store as string to keep the weet without modification
    },
    mutations: {
        [SHOW_POPUP_CREATION]: (state: any, value: boolean) => {
            state.showPopUpCreation = value;
        },
        [RECORD_WEBCAM_AIVAILABLE]: (state: any, value: boolean) => {
            state.webcamRecordAivailable = value;
        },
        [SET_SCREEN_SURFACE]: (state: any, value: string) => {
            state.screenSurface = value;
        },
        // STATE RECORD
        [START_RECORDING]: (state: any) => {
            state.recording = RecordingState.RECORDING;
        },
        [PAUSE_RECORDING]: (state: any) => {
            state.recording = RecordingState.PAUSE;
        },
        [STOP_RECORDING]: (state: any) => {
            state.recording = RecordingState.FINISH;
        },
        [CREATE_KEY_FRAME]: (state: any, value: boolean) => {
            state.keyFrameNeed = value;
        },
        [NEW_SOUND_DETECT]: (state: any, segmentSound: Segment) => {
            state.segmentSound = segmentSound;
        },
        // STICKERS CONFIGURATION
        [RECORD_STICKER_FULLSCREEN]: (state: any) => {
            state.stickerFullscreen = !state.stickerFullscreen;
        },
        [RECORD_STICKER_MUTE]: (state: any) => {
            state.stickerMute = !state.stickerMute;
        },
        [RECORD_STICKER_VIDEO_MUTE]: (state: any) => {
            state.stickerVideoMute = !state.stickerVideoMute;
        },

        [RECORD_STICKER_POSITION]: (state: any, position: StickerPosition) => {
            state.stickerPostion = position;
        },
        [RECORD_STICKER_FILTER]: (state: any, filter: FilterVideoType) => {
            state.videoFilter = filter;
        },
        [RECORD_STICKER_FACE_TRACK]: (state: any, faceTrack: boolean) => {
            state.faceTrack = faceTrack;
        },
        [RECORD_STICKER_VIRTUAL_BACKGROUND]: (state: any, value: VirtualBackgroundEnum) => {
            state.virtualBackground = value;
        },
        [RECORD_STICKER_INTEGRATION]: (state: any, webcamIntegration: WebCamIntegrationTypeEnum) => {
            state.webcamIntegration = webcamIntegration;
        },
        [RECORD_MAIN_VIRTUAL_BACKGROUND]: (state: any, value: ScreenVirtualBackgroundEnum) => {
            state.screenVirtualBackground = value;
        },
        [RECORD_MAIN_VIDEO_TYPE]: (state: any, source: SourceVideoType) => {
            state.mainRecordSource = source;
        },
        [RECORD_STICKER_VIDEO_TYPE]: (state: any, source: SourceVideoType) => {
            state.stickerRecordSource = source;
        },
        [GENERATE_STICKERS_MEDIAID]: (state: any, mediaID: string) => {
            state.stickerRecordingMediaID = mediaID;
        },
        [GENERATE_MAIN_MEDIAID]: (state: any, mediaID: string) => {
            state.mainRecordingMediaID = mediaID;
        },
        [SET_MAIN_TIME_RECORDING]: (state: any, time: number) => {
            state.mainRecordingTime = time;
        },
        [SET_STICKERS_TIME_RECORDING]: (state: any, time: number) => {
            state.stickerRecordingTime = time;
        },
        [UPDATE_MEDIA_ID_RECORDING_STATE]: (state: any, params: MediaIDUpdateParams) => {
            if (state.mainRecordingMediaID === params.oldID) {
                state.mainRecordingMediaID = params.media.mediaID;
            }
            if (state.stickerRecordingMediaID === params.oldID) {
                state.stickerRecordingMediaID = params.media.mediaID;
            }
        },
        [UPDATE_MEDIA_CONTROL_CHANGE]: (state: any, eventType: TimeEventType) => {
            state.mediaControlEvent = eventType;
        },
        [UPDATE_MAIN_RUNNING_STATE]: (state: any, value: boolean) => {
            state.mainVideoRunning = value;
        },
        [UPDATE_STICKERS_RUNNING_STATE]: (state: any, value: boolean) => {
            state.stickerVideoRunning = value;
        },
        [SET_CURRENT_EDITING_WEET]: (state: any, weetID: string) => {
            if (weetID === undefined) {
                state.editingWeetID = '';
            } else {
                state.editingWeetID = weetID;
            }
        },
        [PREPOPULATE_WEET_TITLE]: (state: any, weetTitle: string) => {
            state.weetTitle = weetTitle;
        },
        [PREPOPULATE_SHARED_EMAILS]: (state: any, sharedEmails: string[]) => {
            state.sharedEmails = sharedEmails;
        },
        [SET_CHANNELS_FOR_EDITING]: (state: any, channels: Channel[]) => {
            state.editingChannels = {};
            for (const channel of channels) {
                Vue.set(state.editingChannels, channel.channelID, channel);
            }
        },
        [ANNOTATION_INTERACTION_SAVE_IN_PROGRESS]:(state:any,value:boolean)=>{
            state.annotationSaveInProgress=value;
        },
        [CHANGE_STEP_AI_GENERATION]:(state:any,step:number)=>{
            state.aiAvatarStep=step;
        },
        [AVATAR_AI_TEXT]:(state:any,text:string)=>{
            state.aiAvatarText=text;
        },
        [VOICE_OVER_TEXT]:(state:any,text:string)=>{
          state.aiVoiceOverText=text;
        },
        [SAVE_FLAT_WEET]:(state:any,weet:Weet)=>{
            state.flattenWeet=stringify(weet);
        }
    },
    getters: {
        showPopUpCreation: (state: any): boolean => {
            return state.showPopUpCreation;
        },
        getEditingWeetID: (state: any): string => {
            return state.editingWeetID;
        },
        getRecordingState: (state: any): RecordingState => {
            return state.recording;
        },
        isWithoutExtentionRecord: (state: any, getters: any): boolean => {
            return !getters.getEditingWeetID;
        },
        isStartRecordingAivailable: (state: any): boolean => {
            return state.recording === RecordingState.NONE
                || state.recording === RecordingState.PAUSE
                || state.recording === RecordingState.FINISH;
        },
        isPauseAivailable: (state: any): boolean => {
            return state.recording === RecordingState.RECORDING;
        },
        isStickerFullscreen: (state: any): boolean => {
            return state.stickerFullscreen;
        },
        isStickerMute: (state: any): boolean => {
            return state.stickerMute;
        },
        isStickerVideoMute: (state: any): boolean => {
            return state.stickerVideoMute;
        },
        getStickerPosition: (state: any): StickerPosition => {
            return state.stickerPostion;
        },
        getScreenSurface: (state: any): string | null => {
            return state.screenSurface;
        },
        getRecorderFilter: (state: any): FilterVideoType => {
            if (state.stickerVideoMute) {
                return FilterVideoType.NONE;
            }
            // check if the filter exist, if note we change to the default Filter
            if (FilterVideoType[state.videoFilter]) {
                return state.videoFilter;
            } else {
                return FilterVideoType.NONE;
            }
        },
        getStickerFaceTrack: (state: any): boolean => {
            return state.faceTrack;
        },
        getVirtualBackground: (state: any): VirtualBackgroundEnum => {
            if (state.stickerVideoMute) {
                return VirtualBackgroundEnum.NONE;
            }
            // check if the filter exist, if note we change to the default Filter
            if (VirtualBackgroundEnum[state.virtualBackground]) {
                return state.virtualBackground;
            } else {
                return VirtualBackgroundEnum.BLUR;
            }
        },
        getScreenVirtualBackground: (state: any): ScreenVirtualBackgroundEnum => {
            // check if the filter exist, if note we change to the default Filter
            if (ScreenVirtualBackgroundEnum[state.screenVirtualBackground]) {
                return state.screenVirtualBackground;
            } else {
                return ScreenVirtualBackgroundEnum.SPACE;
            }
        },
        getWebCamIntegration: (state: any): WebCamIntegrationTypeEnum => {
            // check if the filter exist, if note we change to the default Filter
            if (WebCamIntegrationTypeEnum[state.webcamIntegration]) {
                return state.webcamIntegration;
            } else {
                return WebCamIntegrationTypeEnum.NONE;
            }
        },
        getMainRecordSource: (state: any): SourceVideoType => {
            return state.mainRecordSource;
        },
        getStickerRecorderSource: (state: any): SourceVideoType => {
            return state.stickerRecordSource;
        },
        getStickerCurrentRecordingTime: (state: any): number => {
            return state.stickerRecordingTime;
        },
        getStickersMediaId: (state: any): string => {
            return state.stickerRecordingMediaID;
        },
        getMainVideoRecordingTime: (state: any): number => {
            return state.mainRecordingTime;
        },
        getMainMediaId: (state: any): string => {
            return state.mainRecordingMediaID;
        },
        isKeyFrameNeed: (state: any): boolean => {
            return state.keyFrameNeed;
        },
        getSegmentSound: (state: any): SegmentOfSound => {
            return state.segmentSound;
        },
        getMediaControlEvent: (state: any): TimeEventType => {
            return state.mediaControlEvent;
        },
        isMainVideoRunning: (state: any): boolean => {
            return state.mainVideoRunning;
        },
        isStickerVideoRunning: (state: any): boolean => {
            return state.stickerVideoRunning;
        },
        getPrepopulatedWeetTitle: (state: any): string | null => {
            return state.weetTitle;
        },
        getPrepopulatedEmails: (state: any): string[] => {
            return state.sharedEmails;
        },
        isWebcamAivailableForRecord: (state: any): boolean => {
            return state.webcamRecordAivailable;
        },
        getEditingChannels: (state: any): Channel[] => {
            return Object.values(state.editingChannels);
        },
        isAnnotationInteractionSaveInProgress: (state: any): boolean => {
            return state.annotationSaveInProgress;
        },
        getAiAvatarStep: (state: any): number => {
            return state.aiAvatarStep;
        },
        getAiAvatarText: (state: any): string => {
            return state.aiAvatarText;
        },
        getVoiceOverText: (state: any): string => {
            return state.aiVoiceOverText;
        },
        getFlattenWeet: (state: any): string => {
            return state.flattenWeet;
        },
    },
    actions: {
        [SHOW_POPUP_CREATION]({
                                  commit,
                                  dispatch,
                                  state,
                                  getters
                              }: { commit: any, dispatch: any, state: any, getters: any },
                              value: boolean) {
            commit(SHOW_POPUP_CREATION, value);
        },
        [SET_SCREEN_SURFACE]({
                                 commit,
                                 dispatch,
                                 state,
                                 getters
                             }: { commit: any, dispatch: any, state: any, getters: any },
                             value: string) {
            commit(SET_SCREEN_SURFACE, value);
        },
        [RECORD_WEBCAM_AIVAILABLE]({
                                       commit,
                                       dispatch,
                                       state,
                                       getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   value: boolean) {
            commit(RECORD_WEBCAM_AIVAILABLE, value);
        },
        // STATE RECORD
        [START_RECORDING]({
                              commit,
                              dispatch,
                              state,
                              getters
                          }: { commit: any, dispatch: any, state: any, getters: any }) {

            if (!getters.isWithoutExtentionRecord) {
                sendMessageToExtention(START_RECORDING);
            }
            commit(START_RECORDING);
        },
        [PAUSE_RECORDING]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            if (!getters.isWithoutExtentionRecord) {
                sendMessageToExtention(PAUSE_RECORDING);
                // we remove the counter
                sendMessageToExtention(TIMER_RECORD, 0);

            }
            commit(PAUSE_RECORDING);
        },
        [STOP_RECORDING]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            if (!getters.isWithoutExtentionRecord) {
                sendMessageToExtention(STOP_RECORDING);
            }
            commit(STOP_RECORDING);
        },
        // STICKER CONFIGURATION
        [RECORD_STICKER_FULLSCREEN]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RECORD_STICKER_FULLSCREEN);
        },
        [RECORD_STICKER_MUTE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RECORD_STICKER_MUTE);
        },
        [RECORD_STICKER_VIDEO_MUTE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RECORD_STICKER_VIDEO_MUTE);
        },
        [RECORD_STICKER_POSITION]({commit, dispatch}: { commit: any, dispatch: any }, position: StickerPosition) {
            commit(RECORD_STICKER_POSITION, position);
        },
        [RECORD_STICKER_FILTER]({commit, dispatch}: { commit: any, dispatch: any }, filter: FilterVideoType) {
            commit(RECORD_STICKER_VIRTUAL_BACKGROUND, VirtualBackgroundEnum.NONE);
            commit(RECORD_STICKER_FILTER, filter);
            commit(RECORD_STICKER_INTEGRATION, WebCamIntegrationTypeEnum.NONE);
        },
        [RECORD_STICKER_INTEGRATION]({
                                         commit,
                                         dispatch
                                     }: { commit: any, dispatch: any },
                                     filter: WebCamIntegrationTypeEnum) {
            commit(RECORD_STICKER_VIRTUAL_BACKGROUND, VirtualBackgroundEnum.NONE);
            commit(RECORD_STICKER_FILTER, FilterVideoType.NONE);
            commit(RECORD_STICKER_INTEGRATION, filter);
        },
        [RECORD_STICKER_FACE_TRACK]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(RECORD_STICKER_FACE_TRACK, value);
        },
        [RECORD_STICKER_VIRTUAL_BACKGROUND]({
                                                commit,
                                                dispatch
                                            }: { commit: any, dispatch: any },
                                            background: VirtualBackgroundEnum) {
            commit(RECORD_STICKER_FILTER, FilterVideoType.NONE);
            commit(RECORD_STICKER_INTEGRATION, WebCamIntegrationTypeEnum.NONE);
            commit(RECORD_STICKER_VIRTUAL_BACKGROUND, background);
        },
        [RECORD_MAIN_VIRTUAL_BACKGROUND]({
                                             commit,
                                             dispatch
                                         }: { commit: any, dispatch: any },
                                         background: ScreenVirtualBackgroundEnum) {
            commit(RECORD_MAIN_VIRTUAL_BACKGROUND, background);
        },
        [RECORD_MAIN_VIDEO_TYPE]({
                                     commit,
                                     dispatch,
                                     getters
                                 }: { commit: any, dispatch: any, getters: any },
                                 source: SourceVideoType) {
            if (!getters.isWithoutExtentionRecord) {
                sendMessageToExtention(RECORD_MAIN_VIDEO_TYPE, source);
            }
            commit(RECORD_MAIN_VIDEO_TYPE, source);
        },
        [RECORD_STICKER_VIDEO_TYPE]({
                                        commit,
                                        dispatch,
                                        getters
                                    }: { commit: any, dispatch: any, getters: any },
                                    source: SourceVideoType) {
            commit(RECORD_STICKER_VIDEO_TYPE, source);
        },
        [GENERATE_MAIN_MEDIAID]({commit, dispatch}: { commit: any, dispatch: any }) {
            const mediaID = uuidv4();
            commit(GENERATE_MAIN_MEDIAID, mediaID);
            return mediaID;
        },
        [CLEAR_MAIN_MEDIAID]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(GENERATE_MAIN_MEDIAID, '');
            return;
        },

        [GENERATE_STICKERS_MEDIAID]({commit, dispatch}: { commit: any, dispatch: any }): string {
            const mediaID = uuidv4();
            commit(GENERATE_STICKERS_MEDIAID, mediaID);
            return mediaID;
        },
        [CLEAR_STICKER_MEDIAID]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(GENERATE_STICKERS_MEDIAID, '');
            return;
        },
        [SET_STICKERS_TIME_RECORDING]({commit, dispatch}: { commit: any, dispatch: any }, time: number) {
            commit(SET_STICKERS_TIME_RECORDING, time);
        },
        [SET_MAIN_TIME_RECORDING]({commit, dispatch}: { commit: any, dispatch: any }, time: number) {
            commit(SET_MAIN_TIME_RECORDING, time);
        },
        [CREATE_KEY_FRAME]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(CREATE_KEY_FRAME, value);
        },
        [NEW_SOUND_DETECT]({commit, dispatch}: { commit: any, dispatch: any }, segmentSound: SegmentOfSound) {
            commit(NEW_SOUND_DETECT, segmentSound);
        },
        [UPDATE_MEDIA_ID_RECORDING_STATE]({
                                              commit,
                                              dispatch
                                          }: { commit: any, dispatch: any },
                                          mediaChangeParames: MediaIDUpdateParams) {
            commit(UPDATE_MEDIA_ID_RECORDING_STATE, mediaChangeParames);
        },
        [UPDATE_MEDIA_CONTROL_CHANGE]({commit, dispatch}: { commit: any, dispatch: any }, timeEvent: TimeEventType) {
            commit(UPDATE_MEDIA_CONTROL_CHANGE, timeEvent);
        },
        [UPDATE_STICKERS_RUNNING_STATE]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(UPDATE_STICKERS_RUNNING_STATE, value);
        },
        [UPDATE_MAIN_RUNNING_STATE]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(UPDATE_MAIN_RUNNING_STATE, value);
        },
        [SET_CURRENT_EDITING_WEET]({
                                       commit,
                                       dispatch,
                                       state,
                                       getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   weetID: string) {
            if (!getters.isWithoutExtentionRecord) {
                sendMessageToExtention(SET_CURRENT_EDITING_WEET, weetID);
            }
            commit(SET_CURRENT_EDITING_WEET, weetID);
        },
        [PREPOPULATE_WEET_TITLE](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            weetTitle: string
        ) {
            commit(PREPOPULATE_WEET_TITLE, weetTitle);
        },
        [PREPOPULATE_SHARED_EMAILS](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            sharedEmails: string[]
        ) {
            commit(PREPOPULATE_SHARED_EMAILS, sharedEmails);
        },
        [SET_CHANNELS_FOR_EDITING](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            channels: Channel[]
        ) {
            commit(SET_CHANNELS_FOR_EDITING, channels);
        },
        [ANNOTATION_INTERACTION_SAVE_IN_PROGRESS](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            value: boolean
        ) {
            commit(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS, value);
        },
        [CHANGE_STEP_AI_GENERATION](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            step: number
        ) {
            commit(CHANGE_STEP_AI_GENERATION, step);
        },
        [AVATAR_AI_TEXT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            step: string
        ) {
            commit(AVATAR_AI_TEXT, step);
        },
        [VOICE_OVER_TEXT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            step: string
        ) {
            commit(VOICE_OVER_TEXT, step);
        },
        [SAVE_FLAT_WEET](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            weet: Weet
        ) {
            commit(SAVE_FLAT_WEET, weet);
        },
    }
};
