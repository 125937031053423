export class Transcript {
  public words: Word[] = [];
  public mediaID: string = '';
  public transcript: string = '';
  public segmentedTranscript: string = '';
  public subtitlePath: string = '';
  public lang: string = '';
}

export class Word {
  public id: number = 0;
  public start: number = 0;
  public text: string = '';
  public end: number = 0;
}
