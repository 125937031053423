export enum SettingUsersKey {
    EMAIL_NOTIFICATION_WEET_VIEWED = 'EMAIL_NOTIFICATION_WEET_VIEWED',
    EMAIL_NOTIFICATION_WEET_COMMENT = 'EMAIL_NOTIFICATION_WEET_COMMENT',
    EMAIL_NOTIFICATION_WEET_SHARED = 'EMAIL_NOTIFICATION_WEET_SHARED',
    EMAIL_NOTIFICATION_NEW_WEET = 'EMAIL_NOTIFICATION_NEW_WEET',
    EMAIL_NOTIFICATION_WEET_UPDATED = 'EMAIL_NOTIFICATION_WEET_UPDATED',
    TIME_SECTION_SOUND = 'TIME_SECTION_SOUND',
    TIME_SECTION_NOTIFICATION = 'TIME_SECTION_NOTIFICATION',
    WEB_PUSH_NOTIFICATION = 'WEB_PUSH_NOTIFICATION',
    NOISE_CANCELATION = 'NOISE_CANCELATION',
    CAPTURE_RESOLUTION = 'CAPTURE_RESOLUTION',
    CAPTURE_FPS = 'CAPTURE_FPS',
    VB_CUSTOM_SLOT_1 = 'VB_CUSTOM_SLOT_1',
    UNSUBSCRIBE_TUTORIAL_LIST = 'UNSUBSCRIBE_TUTORIAL_LIST',
    PERSONAL_SPACE_QUOTA = 'PERSONAL_SPACE_QUOTA'
}
