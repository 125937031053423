<template>
  <div class="userElement">
    <w-tool-tip class="userDescriptionWrapper" position="left" :textLabel="isEditable?userShare.user.contactEmail:''">
      <div class="blockUserDescription">
        <UserAvatar tooltip-size="small" type="sticker" :size="size" class="userAvatar"
                    :user-avatar="imageForUser(userShare.user)"/>
        <div class="userDescription" :class="size">
          {{ userShare.user.firstName }} {{ userShare.user.lastName }}
        </div>
      </div>
    </w-tool-tip>
    <div class="action" v-if="!userShare.owner || canEditWorkspace">
      <b-dropdown aria-role="list" position="is-top-left" :disabled="!isEditable">
        <template #trigger="{ active }">
          <w-button icon="dots-horizontal" color="outlined" size="small"/>
        </template>
        <b-dropdown-item v-if="canSwitchOwnership()" aria-role="listitem"
                         :disabled="isOwner(userShare)"
                         @click="handleOwnerChange()">
          <div class="lineActionRole">
            <w-icon class="icon" icon="crown-outline" size="small"/>
            <div class="labelAuth" v-if="isOwner(userShare)">{{ $t('shareWeet.weetOwner') }}</div>
            <div class="labelAuth" v-else>{{ $t('shareWeet.switchOwner') }}</div>
            <w-icon v-if="isOwner(userShare)" class="iconCheck" icon="check" color="primary" size="small"/>
          </div>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="handleAuthorizationChange(authorisationEnum.EDIT)">
          <div class="lineActionRole">
            <w-icon class="icon" icon="pencil-outline" size="small"/>
            <div class="labelAuth">{{ $t('shareWeet.edit') }}</div>
            <w-icon v-if="isEditor(userShare)" class="iconCheck" icon="check" color="primary" size="small"/>
          </div>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="handleAuthorizationChange(authorisationEnum.COMMENT)">
          <div class="lineActionRole">
            <w-icon class="icon" icon="comment-outline" size="small"/>
            <div class="labelAuth">{{ $t('shareWeet.comment') }}</div>
            <w-icon v-if="!isEditor(userShare)" class="iconCheck" icon="check" color="primary" size="small"/>
          </div>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="deleteShare(userShare.user)">
          <div class="lineActionRole danger">
            <w-icon class="icon" icon="delete-outline" size="small"/>
            <div class="labelAuth">{{ $t('shareWeet.delete') }}</div>
          </div>
        </b-dropdown-item>

      </b-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {Weet, WeetOwer} from '@/store/weet/weetModel';
import {User} from '@/store/user/userModel';
import {getUserAvatar} from '@/utils/util';
import {canEditThisWeet, isOwner} from '@/utils/weetUtil';
import {canEditWorkspace} from '@/utils/workspaceUtil';
import {AuthorisationEnum} from '@/enum/AuthorisationEnum';
import {
  DELETE_SHARE_A_WEET,
  ActionUserShareParams,
  MODIFY_OWNER_WEET,
  MODIFY_SHARE_A_WEET,
  ShareParams
} from '@/store/weet/weetAction';
import store from '@/store';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WButton from '@/components/wrapper/w-button.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {confirmationDanger, inform} from '@/utils/dialog';
import {log} from '@/utils/log';

@Component({
  components: {
    WIcon,
    WToolTip,
    UserAvatar,
    WButton
  }
})
export default class UserAuthorization extends Vue {
  @Prop()
  private userShare!: WeetOwer;

  @Prop()
  private weet!: Weet;

  @Prop({default: 'default'})
  private size!: string;

  private loading = false;


  get authorisationEnum(): object {
    return AuthorisationEnum;
  }

  get isEditable(): boolean {
    if (this.weet === undefined) {
      return false;
    }
    return canEditThisWeet(this.weet);
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get canEditWorkspace(): boolean {
    return canEditWorkspace();
  }

  private imageForUser(user: User): string {
    return getUserAvatar(user, 100);
  }

  private isMe(userShare: WeetOwer): boolean {
    const me: User = store.getters.userConnected;
    return me?.userID === userShare.user.userID;
  }

  private isEditor(userShare: WeetOwer): boolean {
    return userShare.allow === AuthorisationEnum.EDIT;
  }

  private isOwner(userShare: WeetOwer): boolean {
    return isOwner(this.weet, userShare.user);
  }

  private canSwitchOwnership(): boolean {
    return isOwner(this.weet) || this.canEditWorkspace;
  }

  private deleteShare(user: User) {
    confirmationDanger(
        this.$t('shareWeet.deleteConfirm.message').toString(),
        this.$t('shareWeet.deleteConfirm.title').toString(),
    ).then(() => {
      const params = new ActionUserShareParams(this.weet.weetID, user.userID);
      this.$emit('error', '');
      store.dispatch(DELETE_SHARE_A_WEET, params).then((data: Weet) => {
        this.$emit('updated', data);
        inform(this.$t('shareWeet.roleSuccess').toString());
      }).catch((err) => {
        this.$emit('error', err);
        setActionEvent(ActionEvent.share_delete_contact);
      });
    }).catch(() => {
      log.debug('Remove canceled');
    });

  }


  private handleAuthorizationChange(newAuthorization: AuthorisationEnum) {
    if (newAuthorization !== this.userShare.allow) {
      // Share value has changes
      this.loading = true;

      const emailsToShare = [this.userShare.user.contactEmail];
      const params = new ShareParams(this.weet.weetID, emailsToShare, newAuthorization);
      store.dispatch(MODIFY_SHARE_A_WEET, params).then((data: Weet) => {
        this.loading = false;
        this.$emit('error', '');
        this.$emit('updated', data);
        setActionEvent(ActionEvent.modify_share_contact, {
          new_authorization: newAuthorization,
          shared_contact: this.userShare.user.contactEmail
        });
        inform(this.$t('shareWeet.roleSuccess').toString());
      }).catch((err) => {
        this.loading = false;
        this.$emit('error', err);
      });
    }
  }


  private handleOwnerChange() {
    if (!this.isOwner(this.userShare)) {
      // Share value has changes
      this.loading = true;

      const params = new ActionUserShareParams(this.weet.weetID, this.userShare.user.contactEmail);
      store.dispatch(MODIFY_OWNER_WEET, params).then((data: Weet) => {
        this.loading = false;
        this.$emit('error', '');
        this.$emit('updated', data);
        setActionEvent(ActionEvent.modify_share_contact, {
          shared_contact: this.userShare.user.contactEmail
        });
        inform(this.$t('shareWeet.roleSuccess').toString());
      }).catch((err) => {
        this.loading = false;
        this.$emit('error', err);
      });
    }
  }


}
</script>

<style scoped lang="scss">

.userElement {
  margin-top: 8px;
  align-items: center;
  display: flex;

  .userDescriptionWrapper {
    flex: 1;
    margin-top: 8px;
    align-items: center;
    cursor: pointer;

    .blockUserDescription {
      display: flex;
      align-items: center;

      .userAvatar {
        display: inline-block;
        vertical-align: middle;
      }

      .userDescription {
        font-size: 14px;
        color: var(--black);
        font-weight: 600;
        padding-left: 16px;
        padding-right: 16px;
        display: inline-block;
        text-transform: capitalize;
        vertical-align: middle;


        &.small {
          display: flex;
          flex: 1;

          .firstName {
            margin-right: 4px;
          }
        }
      }
    }
  }


  .action {
    margin-top: 8px;
    text-align: right;
    margin-left: 8px;

    .lineActionRole {
      display: flex;
      align-items: center;

      .labelAuth {
        flex: 1;
        margin-left: 4px;
        margin-right: 4px;
      }


      &.danger {
        color: var(--red);
      }
    }
  }
}


</style>
