<template>
  <div class="progress">
    <div class="titleProgress">
      <w-icon :icon="waitingIcon"/>
      {{ $t('createWeetComponent.advancedEditing.progress.proccess') }}
    </div>
    <b-progress class="progressBar" format="percent" type="is-primary" size="is-large" :value="updateAvancement"
                show-value/>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {Media} from '@/store/media/mediaModel';
import {getThumbnailURL, getUserAvatar} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Weet} from '@/store/weet/weetModel';
import {isWeetIsReady, progressWeetProceed} from '@/utils/weetUtil';

@Component({
  components: {UserAvatar, WIcon}
})
export default class ProgressProcessPlayer extends Vue {

  @Prop()
  private weet!: Weet;
  private index = 0;

  get updateAvancement(): number {
    this.index++;
    return progressWeetProceed(this.weet);
  }

  get waitingIcon() {
    const icons = ['clock-time-one', 'clock-time-two',
      'clock-time-three', 'clock-time-four', 'clock-time-five',
      'clock-time-six', 'clock-time-seven', 'clock-time-eight',
      'clock-time-nine', 'clock-time-ten', 'clock-time-eleven',
      'clock-time-twelve'];
    const value = this.index % icons.length;
    return icons[value];
  }


}
</script>


<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.progress {
  width: 100%;
  height: 100%;
  border-radius: 0px;

  .titleProgress {
    font-size: 20px;
    font-weight: 900;
    color: white;
    margin-bottom: 32px;

    .progressBar {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

</style>
