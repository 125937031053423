import {APPLE_CLIENT_ID, GOOGLE_AUTH_ID, MSAL_TENANT_ID} from '@/store/conf/api';
import {SilentRequest} from '@azure/msal-browser/dist/request/SilentRequest';
import {GoogleOAuthProvider} from 'google-oauth-gsi';

/******************************* Microsoft Auth Config started */
// Microsoft authentication configuration
export const msalConfig = {
    auth: {
        clientId: MSAL_TENANT_ID,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: document.location.origin + '/login'
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const msalScopeRequest: SilentRequest = {
    scopes: ['openid', 'profile', 'User.Read', 'User.ReadBasic.All'],
};

/******************************* End Microsoft Auth Config */

/******************************* Google Auth Config started */

export const googleProvider = new GoogleOAuthProvider({
    clientId: GOOGLE_AUTH_ID || "",
});

/******************************* End Google Auth Config */

/**
 * Return the config object used to initialize apple signin.
 * Only compatbile popUpMode
 */
export function appleSigninOptions(): any {
    const redirectUri = document.location.origin + '/login';
    return {
        clientId: APPLE_CLIENT_ID,
        scope: 'email name',
        redirectURI: redirectUri,
        usePopup: true
    };
}
