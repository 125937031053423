<template>
  <div>
    <w-button tooltip-size="small" color="text" @click.stop="startScreenCommentRecording"
              :tooltip="$t('commentScreenCreator.button.screen')" icon="monitor"
              :tooltip-position="'bottom'"
              :size="size">
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {
  COMMENT_TO_REPLY,
  CommentParams,
  REPLY_COMMENT,
  SEND_COMMENT
} from '@/store/comment/commentAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {Weet} from '@/store/weet/weetModel';
import {PLAYER_PLAYING, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import WButton from '@/components/wrapper/w-button.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import {PAUSE_RECORDING, START_RECORDING, STOP_RECORDING} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import TimerRecorder from '@/components/recorder/timer/TimerRecorder.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {isChromium} from '@/utils/extentionUtil';
import {alertMessage} from '@/utils/dialog';
import {quickAuthentication} from '@/utils/loginUtil';
import {canEditOrCommentThisWeet} from '@/utils/weetUtil';

@Component({
  components: {WModal, TimerRecorder, CenterCenterPanel, VideoSource, StickerLayoutSource, WButton}
})
export default class CommentScreenCreator extends Vue {
  @Prop()
  public weet!: Weet;

  @Prop({default: 'default'})
  public size!: string;

  private screenRecordStart: boolean = false;

  get time(): number {
    return store.getters.getTimerPlayer;
  }


  public async startScreenCommentRecording() {
    if (!isChromium()) {
      alertMessage(this.$t('generic.capture.unaivailable').toString());
      return;
    }
    this.pauseVideo();

    await quickAuthentication();
    if (!canEditOrCommentThisWeet(this.weet)) {
      alertMessage(this.$t('commentPanelView.disabled.subtitle').toString(), this.$t('commentPanelView.disabled.title').toString());
      return;
    }


    store.dispatch(TIMER_RECORD, 0);
    setActionEvent(ActionEvent.comment_screen_start, {player_time: this.time});
    this.$emit('click');
  }

  public pauseVideo() {
    store.dispatch(PLAYER_PLAYING, false);
  }

  public beforeDestroy() {
    this.screenRecordStart = false;
    store.dispatch(STOP_RECORDING);
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.labelStopRecord {
  color: #FFF;
  font-size: 65px;
}

.waitingUpload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  color: #FFF;
  font-size: 65px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.8;
}

.startPanel {
  position: relative;
  max-width: 400px;
  margin: auto;

  .previewPanel {
    max-width: 400px;
    max-height: 300px;
  }

  .timerPanel {
    font-size: 24px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    min-width: 110px;
    display: inline-block;
    border-radius: 12px;
    padding-left: 12px;
    margin: auto;
    margin-top: 16px;
  }

  .buttonLine {
    margin: auto;
    margin-top: 24px;
    text-align: center;
  }


}


</style>
