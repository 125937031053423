import VueI18n from 'vue-i18n';
import en from '@/locales/en.json';
import Vue from 'vue';

// i18n Configuration
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en
  }
});
