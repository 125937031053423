<template>
  <div>
    <w-button :tooltip="$t('myNotification.button.readAll.tooltip')" tooltip-position="left"
              color="outlined"
              icon="check"
              :rounded="true" size="small" @click="markAllAsRead()" :loading="loading">
    </w-button>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import store from '@/store';
import {READ_ALL_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';

@Component({
  components: {WButton}
})
export default class NotificationMarkAsRead extends Vue {
  private loading = false;

  private markAllAsRead() {
    this.loading = true;
    store.dispatch(READ_ALL_NOTIFICATIONS)
        .then(() => {
          // UX improve... We try to say... we working hard
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        })
        .catch(() => this.loading = false);
  }

}

</script>


<style scoped lang="scss"></style>
