<template>

  <div class="menu">
    <div class="columnsMenu">
      <!-- COLUMN Workpsace -->
      <div class="columnLeft">

        <div class="workspaceList">


          <transition name="fade">
            <InboxSelection type="square" class="personnalWorkspace" :selected="isWorkspaceSelected()"
                            @click="selectWorkspace()" v-show="isNeedToViewInbox" data-test="inboxItem"/>
          </transition>
          <transition name="fade">
            <div class="pastille" v-show="isNotificationOnAvatar"></div>
          </transition>
          <template>
            <perfect-scrollbar :options="{suppressScrollX:true}" infinite-wrapper>
              <WorkspaceAvatar v-for="workspace in myWorkspaces" :key="'menu_' + workspace.workspaceID"
                               class="workspaceItem"
                               data-test="workspaceItem"
                               :workspaceAvatar="avatar(workspace)"
                               :workspaceID="workspace.workspaceID"
                               :workspaceName="workspace.name"
                               :notifications="notifications"
                               aria-role="menu"
                               tooltip-position="right"
                               type="square"
                               :selected="isWorkspaceSelected(workspace)"
                               @click="selectWorkspace(workspace.workspaceID)"
              />
              <infinite-loading @infinite="debouceInfinite" v-if="firstLoading" :force-use-infinite-wrapper="true">
                <div slot="no-more"></div>
              </infinite-loading>
            </perfect-scrollbar>
            <w-button icon="plus" :class="myWorkspaces.length===0?'buttonAddWorkspace':''" color="outlined"
                      tooltip-position="right" :tooltip="$t('menu.workspace.create')"
                      @click="createWorkspace" data-test="createWorkspaceButton"/>
          </template>
        </div>
        <div class="information">
          <div class="separatorLine"></div>
          <div class="tipItem" v-if="isFreemium">
            <upgrade-plan-button size="small" color="secondary" :with-label="false" />
          </div>
          <div class="tipItem" @click="goToBlog" data-test="menuBlogLink">
            <w-tool-tip position="right" :label="$t('menu.blog')">
              <w-icon color="dark" icon="book-open-variant"/>
            </w-tool-tip>
          </div>
        </div>

      </div>
      <!-- COLUMN Tag Panel -->
      <div class="columnRight">
        <WorkspaceMenu/>
        <perfect-scrollbar class="groupPanel">
          <div class="channelsPanel">

            <!-- STATIC TAGS -->
            <MenuStaticTags @select="selectStaticTag"/>

            <!-- CHANNELS WORKSPACE -->
            <MainTitle size="small" color="light" class="titleChannel" :title="$t('menu.channels.title')"
                       v-if="this.selectedWorkspaceID">
              <ChannelSubscriberButton class="buttonMenuChannel" @subscribe="selectChannel"/>

            </MainTitle>
            <WorkspaceMenuChannelList @select="selectChannel" v-if="this.selectedWorkspaceID"/>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import router from '@/router';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WButton from '@/components/wrapper/w-button.vue';
import MainTitle from '@/components/title/MainTitle.vue';
import GroupContactPanel from '@/components/groupContact/GroupContactPanel.vue';
import {MY_LIST_WEET, MY_WEET_INBOX, MyWeetListParams, REINIT_MY_WEET, StaticTag} from '@/store/myWeet/myWeetAction';
import {isGrid1X} from '@/utils/gridBreakPoint';
import {OPEN_MENU} from '@/store/setup/setupAction';
import {Notification} from '@/store/myNotifications/myNotificationModel';
import TypeForm from '@/components/typeform/TypeForm.vue';
import {getUserAvatar, getWorkspaceAvatar} from '@/utils/util';
import {isFreemium, selectWorkspace} from '@/utils/workspaceUtil';
import {User} from '@/store/user/userModel';
import {GET_MY_WORKSPACE, GET_MY_WORKSPACES_LIST} from '@/store/workspace/workspaceAction';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import {Workspace} from '@/store/workspace/workspaceModel';
import debounce from 'lodash/debounce';
import WorkspaceMenu from '@/components/workspace/WorkspaceMenu.vue';
import SearchWeetAutoComplete from '@/components/weet/input/SearchWeetAutoComplete.vue';
import {SELECT_WORKSPACE_CHANNEL} from '@/store/channel/channelAction';
import MenuStaticTags from '@/components/menu/menuComponent/MenuStaticTags.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WorkspaceMenuChannelList from '@/components/workspace/WorkspaceMenuChannelList.vue';
import ChannelSubscriberAdd from '@/components/channel/ChannelSubscriberAdd.vue';
import ChannelSubscriberButton from '@/components/channel/ChannelSubscriberButton.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import InboxSelection from "@/components/workspace/InboxSelection.vue";
import {PaginationWrapper} from "@/store/generic/paginationWrapper";
import UpgradePlanTag from "@/components/upgradePlan/UpgradePlanTag.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";

@Component({
  components: {
    UpgradePlanButton,
    UpgradePlanTag,
    InboxSelection,
    ChannelSubscriberButton,
    ChannelSubscriberAdd,
    WorkspaceMenuChannelList,
    CenterCenter,
    WModal,
    MenuStaticTags,
    SearchWeetAutoComplete,
    WorkspaceMenu,
    WorkspaceAvatar,
    TypeForm, GroupContactPanel, MainTitle, WButton, WToolTip, WIcon, UserAvatar
  },
})
export default class Menu extends Vue {
  private firstLoading: boolean = false;
  private routeSelected: string = '';

  private isNeedToViewInbox: boolean = false;

  get page(): number {
    return store.getters.getCurrentPageWorkspace;
  }

  get totalOfWorkspace(): number {
    return store.getters.getTotalOfWorkspace;
  }

  get myWorkspaces(): Workspace[] {
    return store.getters.getMyWorkspaces || [];
  }

  get selectedWorkspaceName(): string {
    return store.getters.getSelectedWorkspace?.name || this.$t('workspace.personal');
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get numberOfTag(): number {
    return store.getters.getAllChannels.length;
  }


  private avatar(workspace: Workspace) {
    return getWorkspaceAvatar(workspace, 256);
  }

  private debouceInfinite = debounce((state) => {
    this.infiniteHandler(state);
  }, 250);

  private mounted() {
    this.launchIsNeedToViewInbox();
  }

  private infiniteHandler(state) {
    store.dispatch(GET_MY_WORKSPACES_LIST, this.page + 1).then(() => {
      if (this.totalOfWorkspace <= this.myWorkspaces.length) {
        state.complete();
      }
      state.loaded();
    }).catch(() => {
      state.complete();
    });
  }

  private createWorkspace() {
    setActionEvent(ActionEvent.workspace_menu_button_create);
    this.$router.push({name: 'CreateWorkspace'});
  }

  private launchIsNeedToViewInbox() {
    const params = new MyWeetListParams();
    store.dispatch(MY_WEET_INBOX, params).then(async (listOfWeet: PaginationWrapper) => {
      this.isNeedToViewInbox = listOfWeet.count > 0;
      if (!this.isNeedToViewInbox && !this.selectedWorkspaceID) {
        // load workspace
        await store.dispatch(GET_MY_WORKSPACES_LIST);
        if (store.getters.getMyWorkspaces.length > 0) {
          selectWorkspace(store.getters.getMyWorkspaces[0].workspaceID);
        }
      }
    });
  }

  /**
   *
   * Return true if the workspace is selected and false otherwise
   * @param workspace, the workspace selected or null if selected workspace is the default one (Personnal Workspace)
   * @private
   */
  private isWorkspaceSelected(workspace: Workspace | null=null): boolean {
    return this.selectedWorkspaceID === (workspace?.workspaceID || null);
  }

  /**
   * When the workspace is selected, the list of Weets and the list of recently shared are updated.
   * @param workspaceID the ID of workspace selected or null if selected workspace is the default one (Personnal Workspace)
   * @private
   */
  private selectWorkspace(workspaceID: string | null = null) {
    selectWorkspace(workspaceID);
    if (this.routeSelected !== 'MyWeets' && this.shouldRedirectToMyWeet) {
      // Do not route to 'MyWeet' page if it is automatic selection
      router.push({name: 'MyWeets'});
    }
    if (!workspaceID) {
      this.selectStaticTag(StaticTag.SHARED_WITH_ME);
    }
  }

  get shouldRedirectToMyWeet(): boolean {
    return !this.isMyWeetRoute && !this.isMyorkspacePaymentsSuccessRoute;
  }

  get isMyWeetRoute(): boolean {
    return window.location.pathname === '/my/weets';
  }

  get isMyorkspacePaymentsSuccessRoute(): boolean {
    return window.location.pathname === '/workspace/paymentSuccess';
  }

  get userImage(): string {
    return getUserAvatar(this.userConnected, 150);
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }

  get notifications(): Notification[] {
    return store.getters.getWeetTypeUnreadNotification;
  }

  get isFreemium():boolean{
    return isFreemium()
  }

  get isNotificationOnAvatar(): boolean {
    for (const notif of this.notifications) {
      if (!notif.workspaceID) {
        return true;
      }
    }
    return false;
  }

  @Watch('$route', {immediate: true, deep: true})
  private onRouteChange(route: any) {
    // manage the #TAG
    this.routeSelected = route.name;
  }


  private isTagSelected(tag: string): string {
    if (store.getters.getTagsFilter === tag) {
      return 'selected';
    } else {
      return '';
    }
  }


  get staticTag() {
    return StaticTag;
  }


  private selectStaticTag(staticTag: string) {
    const params = new MyWeetListParams();
    params.tagFilter = staticTag;
    // Unselect workspace tag if any
    params.channelFilter = '';
    if (this.routeSelected !== 'MyWeets') {
      router.push({name: 'MyWeets'});
    }
    // store.dispatch(SELECT_WORKSPACE_TAG);
    store.dispatch(REINIT_MY_WEET, params);
    store.dispatch(MY_LIST_WEET, params);
    // close the menu
    if (isGrid1X()) {
      store.dispatch(OPEN_MENU, false);
    }
  }

  private selectChannel(channel: string) {
    const params = new MyWeetListParams();
    params.tagFilter = '';
    params.page = 0;
    // Unselect workspace channel if any
    params.channelFilter = channel;
    // store.dispatch(SELECT_WORKSPACE_TAG, tag);
    if (this.routeSelected !== 'MyWeets') {
      router.push({name: 'MyWeets'});
    }
    store.dispatch(REINIT_MY_WEET, params);
    store.dispatch(MY_LIST_WEET, params);
    // close the menu
    if (isGrid1X()) {
      store.dispatch(OPEN_MENU, false);
    }
  }

  private openIntercom() {
    window.open('https://help.weet.co', '_blank');
    // this.$intercom.show();
  }

  private goToBlog() {
    window.open('https://weet.co/blog/', '_blank');
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.menu {

  position: absolute;
  z-index: 1;
  //box-shadow: 1px 5px 5px rgba(27, 57, 84, 0.1);
  height: 100%;
  width: 100%;
  border-right: solid 1px var(--light);
  @include GRID1X {
    width: 100%;
  }

  .titleChannel {
    padding-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    margin-top: 16px;
    width: 100%;
  }

  .buttonMenuChannel {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
  }


  .searchPanel {
    padding-left: 24px;
    padding-right: 24px;
  }

  .columnsMenu {
    display: flex;
    height: 100%;

    .columnLeft {
      width: 80px;
      height: 100%;
      padding-top: 16px;
      background: var(--light);
      display: flex;
      flex-direction: column;
      box-shadow: inset 0px -1px 0px 1px var(--light1);

      .personnalWorkspace {
        height: 48px;
        cursor: pointer;
        transition: transform 0.2s ease;
        margin-bottom: 8px;

        &:hover {
          transform: scale(1.1);
        }
      }

      .pastille {
        width: 12px;
        height: 12px;
        border: solid 2px #FFF;
        border-radius: 12px;
        background: var(--primary);
        animation-delay: 1s;
        position: absolute;
        top: -3px;
        right: 13px;
        @extend .shadow1;
      }

      .workspaceList {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        min-height: 104px;
        max-height: calc(100% - 104px);
        width: 100%;

        .workspaceItem {
          margin: 8px;
          margin-bottom: 16px;
          transition: transform 0.2s ease;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }


        .ps {
          width: 100%;
          padding: 8px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }

      .buttonAddWorkspace {
        margin-top: 8px;
      }

      .information {
        margin-top: auto;
        display: flex;
        flex-direction: column;

        .separatorLine {
          margin-top: 16px;
          margin-bottom: 16px;
          height: 2px;
          display: inline-block;
          margin-left: 16px;
          margin-right: 16px;
          background: var(--light1);
        }

        .tipItem {
          cursor: pointer;
          margin-bottom: 16px;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0.8
          }
        }
      }
    }

    .columnRight {
      flex: 1;

      .separatorLine {
        margin-bottom: 16px;
        height: 1px;
        display: inline-block;
        background: var(--light1);
        width: 100%;
      }

      .buttonWorkspaceSetting {
        overflow: hidden;
      }

      .groupPanel {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100% - 48px);
        padding-bottom: 80px;
      }

      .channelsPanel {
        margin-top: 16px;
      }
    }
  }

}
</style>
