<template>
  <Transition name="fadeIn">
    <div v-show="mustShow" data-test="sectionPlayer" ref="sectionPlayer" class="sectionPlayerContainer"
    >
      <Transition name="fade">
        <div class="errorMessage" v-show="errorLoading">
          {{ $t('sectionPlayer.error') }}
        </div>
      </Transition>
      <!-- MAIN VIDEO PLAYER -->
      <div class="mainVideoContainer" :class="mirroring?'mirroring':''">
        <video class="videoElement" ref="mainVideo" v-show="!isLoading && main" :src="main"
               @timeupdate="onTimeUpdate()"
               @canplay="onMainVideoReady()"
               @ended="onEndVideo()" :class="filterMainClasses">
        </video>
        <div class="mask"></div>
        <div class="layer1"></div>
        <div class="layer2"></div>
        <div class="layer3"></div>
        <div class="layer4"></div>
        <div class="layer5"></div>
        <b-progress class="progress" type="is-primary" size="is-small" v-if="isLoading"></b-progress>

      </div>

      <!-- STICKER VIDEO PLAYER -->
      <Transition name="fade">
        <div class="sticker" v-show="!isLoading && sticker && mustShowSticker"
             :style="'background-image:url('+this.urlUser+')'">
          <div class="stickerLayout">
            <video class="videoElement"
                   :class="filterClasses"
                   @timeupdate="onTimeUpdate()" @ended="onEndVideo()" @canplay="onStickerVideoReady()"
                   ref="stickerVideo"
                   :src="sticker"></video>
            <div class="mask"></div>
            <div class="layer1"></div>
            <div class="layer2"></div>
            <div class="layer3"></div>
            <div class="layer4"></div>
            <div class="layer5"></div>
          </div>
        </div>
      </Transition>

      <!-- CONTROL BAR -->
      <div class="controlBar">
        <!-- play / pause -->
        <div class="playContainer" @click="onClickPlay">
          <w-icon data-test="weetPauseButton" icon="pause" class="logoPlay" v-show="play" color="white"/>
          <w-icon data-test="weetPlayButton" icon="play" class="logoPlay" v-show="!play" color="white"/>
        </div>

        <!-- volume player -->
        <div class="volumeContainer" @mouseover="changeVolumeHover=true" @mouseout="changeVolumeHover=false">
          <div @click="muteUnmuteVolume">
            <w-icon :icon="volumeIcon" class="volumeIcon"/>
          </div>
          <b-slider class="volumeSlider" :tooltip="false" v-show="changeVolumeHover" :type="'is-primary'"
                    :max="100" @input="changeVolume" :value="volume" bigger-slider-focus
                    rounded size="is-small"/>
        </div>
        <!-- timer -->
        <div class="timeContainer" data-test="weetPlayerTimer" v-if="duration>0 && duration!==Infinity">
          {{ humanTimer }} / {{ humanDuration }}
        </div>

        <!-- slider -->
        <div class="sliderContainer" data-test="weetPlayerTimeline">
          <b-slider :custom-formatter="val => getHumanTime(val*1000)" v-if="duration>0 && duration!==Infinity"
                    :max="duration" type="is-primary"
                    rounded size="is-small" @change="changeCurrentTime" :value="timer">
          </b-slider>
        </div>
        <!-- speed container -->
        <div class="speedContainer" @click="switchSpeedMode">
          <div class="speed">{{ speedMode }}</div>
        </div>

        <!-- fullscreen -->
        <div class="fullscreenContainer" @click="toggleFullscreen">
          <w-icon v-if="!isFullScreen" icon="fullscreen"/>
          <w-icon v-else icon="fullscreen-exit"/>
        </div>
      </div>

      <Transition name="fade">
        <div class="localMessage" v-show="localMode">
          {{ $t('sectionPlayer.draft') }}
        </div>
      </Transition>
    </div>
  </Transition>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import prettyMilliseconds from 'pretty-ms';
import screenfull from 'screenfull';
import {Segment, TimeEvent} from '@/store/timeLine/timeEvent';
import {Media} from '@/store/media/mediaModel';
import {getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {log} from '@/utils/log';
import store from '@/store';
import {REFRESH_MEDIA} from '@/store/media/mediaAction';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME} from '@/store/persistedInformation/persistAction';

@Component({
  components: {WIcon}
})
export default class SectionPlayer extends Vue {
  @Prop({default: []})
  private medias!: Media[];

  @Prop({default: true})
  private autoplay!: boolean;

  @Prop()
  private segment!: Segment;

  private main: string = '';
  private sticker: string = '';

  private mustShow: boolean = false;
  private changeVolumeHover: boolean = false;
  private play: boolean = false;
  private timer: number = 0;
  private duration: number = 0;
  private isFullScreen: boolean = false;
  private filterClass: string = '';
  private filterMainClass: string = '';
  private urlUser: string = '';
  private MAX_ITERATION = 10;
  private indexTimeOut: any = 0;
  private errorLoading: boolean = false;

  private mainVideoReady: boolean = false;
  private stickerVideoReady: boolean = false;
  private multiMedia: boolean = false;
  private mirroring: boolean = false;
  private mustShowSticker: boolean = true;

  public mounted() {
    this.mustShow = true;
    this.setupTheEventForTime();
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  get volume(): number {
    return store.getters.volume;
  }

  get localMode(): boolean {
    return this.main.indexOf('blob:') > -1;
  }


  get filterMainClasses() {
    if (this.filterMainClass) {
      let filter = this.filterMainClass + ' ';
      if (!this.play) {
        filter += ' noAnimation';
      }
      return filter;
    } else {
      return FilterVideoType.NONE;
    }
  }

  get filterClasses() {
    if (this.filterClass) {
      let filter = this.filterClass + ' ';
      if (!this.play) {
        filter += ' noAnimation';
      }
      return filter;
    } else {
      return FilterVideoType.NONE;
    }
  }


  get isLoading(): boolean {
    if ((this.multiMedia && this.stickerVideoReady && this.mainVideoReady)
        || (!this.multiMedia && this.stickerVideoReady)
        || (!this.multiMedia && this.mainVideoReady)) {
      return false;
    } else {
      return true;
    }
  }

  private setupTheEventForTime() {

    this.multiMedia = this.isMultiMedia(this.segment.start);
    this.mustShowSticker = this.segment.start.positionLayout !== StickerPosition.NONE;
    this.setupThisEvent(this.segment.start);
  }

  private isMultiMedia(timeEvent: TimeEvent): boolean {
    return timeEvent.mainMedia.mediaID !== '' && timeEvent.secondMedia.mediaID !== '';
  }

  private onEndVideo() {
    this.play = false;
    if (this.stickerVideo()) {
      this.stickerVideo().pause();
    }
    if (this.mainVideo()) {
      this.mainVideo().pause();
    }
  }

  private onMainVideoReady() {
    this.mainVideoReady = true;
    this.onCanPlay();
  }

  private onStickerVideoReady() {
    this.stickerVideoReady = true;
    this.onCanPlay();
  }


  private setupThisEvent(timeEvent: TimeEvent) {
    this.main = this.findUrlByMedia(timeEvent.mainMedia.mediaID, true);
    this.sticker = this.findUrlByMedia(timeEvent.secondMedia.mediaID, false);
    if (timeEvent.secondMedia && timeEvent.secondMedia.mediaID) {
      this.filterClass = timeEvent.secondMedia.filter;
    }
    if (timeEvent.mainMedia && timeEvent.mainMedia.mediaID) {
      this.filterMainClass = timeEvent.mainMedia.filter;
    }
    this.urlUser = this.getUrlUser(timeEvent.secondMedia.mediaID);
  }

  private getUrlUser(mediaId: string): string {
    for (const media of this.medias) {
      if (media.mediaID === mediaId) {
        return getUserAvatar(media.creator, 250);
      }
    }
    return '';
  }


  private findUrlByMedia(mediaId: string, main: boolean): string {
    for (const media of this.medias) {
      if (media.mediaID === mediaId) {
        if (main && media.type === SourceVideoType.WEBCAM) {
          this.mirroring = true;
        }
        if (media.blobURL && media.status !== 'HD') {
          return media.blobURL;
        } else {
          if (media.jobID && media.status === 'HD') {
            return getVideoURLForStatus(media);
          } else {
            // so we wait have a blob ready or an status ready
            if (this.MAX_ITERATION > 0) {
              clearTimeout(this.indexTimeOut);
              this.indexTimeOut = setTimeout(this.setupTheEventForTime, 2000);
              this.MAX_ITERATION--;
              if (media.jobID && media.status === 'WAITING') {
                store.dispatch(REFRESH_MEDIA, media);
              }
            } else {
              this.errorLoading = true;
            }
            return '';
          }
        }
      }
    }
    return '';
  }


  private startVideo() {
    this.mainVideo().play().catch((e) => {
      log.debug(e);
    });
    this.stickerVideo().play().catch((e) => {
      log.debug(e);
    });
  }

  private mainVideo(): HTMLVideoElement {
    return this.$refs.mainVideo as HTMLVideoElement;
  }

  private stickerVideo(): HTMLVideoElement {
    return this.$refs.stickerVideo as HTMLVideoElement;
  }

  get humanTimer(): string {
    return this.getHumanTime(this.timer * 1000);
  }

  get humanDuration(): string {
    return this.getHumanTime(this.duration * 1000);
  }

  private onCanPlay() {
    if (this.stickerVideoReady) {

      this.stickerVideo().playbackRate = this.speedMode;
    } else if (this.mainVideoReady) {
      this.mainVideo().playbackRate = this.speedMode;
    }
    this.duration = (this.segment.end.time - this.segment.start.time) / 1000;

    if (this.autoplay) {

      if ((this.multiMedia && this.stickerVideoReady && this.mainVideoReady)
          || (!this.multiMedia && this.stickerVideoReady)
          || (!this.multiMedia && this.mainVideo())) {
        if (!this.play) {
          this.onClickPlay();
        }
      }
    }
  }

  private onClickPlay() {
    if (!this.play) {
      if (this.stickerVideoReady && this.stickerVideo()) {
        if (this.timer >= this.duration) {
          this.stickerVideo().currentTime = 0;
        }
        this.stickerVideo().play().catch((e) => {
          log.error(e.message);
        });
      }
      if (this.mainVideoReady && this.mainVideo()) {
        if (this.timer >= this.duration) {
          this.mainVideo().currentTime = 0;
        }
        this.mainVideo().play().catch((e) => {
          log.error(e.message);
        });
      }
      this.play = true;
    } else {
      this.stickerVideo().pause();
      this.mainVideo().pause();
      this.play = false;
    }
  }

  private onTimeUpdate() {
    if (this.stickerVideoReady && this.stickerVideo()) {
      this.timer = this.stickerVideo().currentTime;
      this.setupTheEventForTime();
    } else if (this.mainVideoReady && this.mainVideo()) {
      this.timer = this.mainVideo().currentTime;
      this.setupTheEventForTime();
    }

    // fix desynchro of speed
    if (this.stickerVideo()) {
      this.stickerVideo().playbackRate = this.speedMode;
    }
    if (this.mainVideo()) {
      this.mainVideo().playbackRate = this.speedMode;
    }
    if (this.timer >= this.duration) {
      if (this.play) {
        this.onClickPlay();
      }
    }
  }

  private changeCurrentTime(val) {
    if (this.stickerVideo()) {
      this.stickerVideo().currentTime = val;
    }
    if (this.mainVideo()) {
      this.mainVideo().currentTime = val;
    }
  }

  private toggleFullscreen() {
    const element = this.$refs.sectionPlayer as HTMLElement;
    if (screenfull.isEnabled) {
      screenfull.toggle(element);
    }
    // @ts-ignore
    this.isFullScreen = screenfull.isFullscreen;


  }

  private switchSpeedMode() {
    const speed = this.speedMode;
    const speedTab: number[] = [0.8, 1, 1.2, 1.5, 2];
    let newSpeed = speedTab[0];
    for (const speedAvailable of speedTab) {
      if (speedAvailable > speed) {
        newSpeed = speedAvailable;
        break;
      }
    }
    store.dispatch(CHANGE_PLAYER_SPEED, newSpeed);
    if (this.stickerVideo()) {
      this.stickerVideo().playbackRate = newSpeed;
    }
    if (this.mainVideo()) {
      this.mainVideo().playbackRate = newSpeed;
    }
  }


  get volumeIcon(): string {
    if (this.volume >= 75) {
      return 'volume-high';
    } else if (this.volume >= 40) {
      return 'volume-medium';
    } else if (this.volume < 40 && this.volume > 0) {
      return 'volume-low';
    }
    return 'volume-off';
  }

  private muteUnmuteVolume() {

    let myVolume = 0;
    if (this.stickerVideo()) {
      if (this.volume <= 0) {
        this.stickerVideo().volume = 1;
        myVolume = 100;
      } else {
        this.stickerVideo().volume = 0;
        myVolume = 0;
      }
    }
    if (this.mainVideo()) {
      if (this.volume <= 0) {
        myVolume = 100;
        this.mainVideo().volume = 1;
      } else {
        myVolume = 0;
        this.mainVideo().volume = 0;
      }
    }
    store.dispatch(CHANGE_PLAYER_VOLUME, myVolume);
  }

  private changeVolume(val) {
    if (this.stickerVideo()) {
      this.stickerVideo().volume = val / 100;

    }
    if (this.mainVideo()) {
      this.mainVideo().volume = val / 100;
    }
    store.dispatch(CHANGE_PLAYER_VOLUME, val);
  }


  private getHumanTime(val: number): string {
    if (isNaN(val) || val === Infinity) {
      return '0:00';
    }
    if (val < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }
}
</script>


<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.sectionPlayerContainer {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--dark);


  .errorMessage {
    position: absolute;
    height: 24px;
    width: 100%;
    font-size: 14px;
    background: var(--light);
    color: var(--black);
  }

  .localMessage {
    position: absolute;
    display: flex;
    //align-items: center;
    top: 0px;
    justify-content: center;
    height: 80px;
    width: 100%;
    font-size: 16px;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    border-radius: 8px 8px 0px 0px;
    color: var(--white);
    font-weight: 900;
    margin-left: 0px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 20px;
  }

  // MAIN VIDEO
  .mainVideoContainer {
    position: relative;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    &.mirroring {
      transform: scale3d(-1, 1, 1);
    }

    .progress {
      width: calc(100% - 256px);
      position: absolute;
      margin-left: 128px;
      margin-right: 128px;
    }

    .videoElement {
      width: 100%;
      height: 100%;
      position: absolute;
      animation-duration: 2s;
      grid-row-start: 1;
      grid-column-start: 1;
    }
  }


  // STICKER  VIDEO
  .sticker {
    position: absolute;
    // border: 2px solid #FFFFFF;
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    bottom: 48px;
    border-radius: 100% 100% 100% 16px;
    left: 16px;
    transition: all 0.2s;
    background: black;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @extend .stickersShadow;

    .stickerLayout {
      min-height: 120px;
      transform: scaleX(-1) scaleY(1);
      transition: all 0.5s;
      position: relative;

      .videoElement {
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }


  // CONTROL BAR
  .controlBar {
    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
    width: 100%;
    bottom: 0px;
    position: absolute;
    padding-top: 64px;
    min-height: 100px;
    display: flex;
    // ICON play
    .playContainer {
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      margin-left: 8px;
      margin-right: 16px;

      .logoPlay {
        transition: transform ease-in-out 0.1s;

        &:hover {
          transform: scale(1.2);;
        }
      }
    }

    // Volume
    .volumeContainer {
      display: flex;
      cursor: pointer;
      color: white;
      font-size: 12px;
      align-items: center;
      margin-right: 8px;

      .volumeIcon {
        margin-right: 8px;
      }

      .volumeSlider {
        width: 64px;
        margin-right: 8px;
      }
    }

    // time container
    .timeContainer {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #FFF;
      margin-right: 8px;
    }

    // slider
    .sliderContainer {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 8px;
      margin-right: 8px;
    }

    // speed
    .speedContainer {
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: var(--dark);
      font-size: 10px;
      margin-left: 8px;
      font-weight: 900;
      transition: transform ease-in-out 0.1s;
      line-height: 18px;
      margin-right: 8px;

      &:hover {
        transform: scale(1.2);;
      }

      .speed {
        border-radius: 4px;
        width: 20px;
        height: 18px;
        background: var(--white);
      }
    }

    // fullscreen
    .fullscreenContainer {
      display: flex;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      color: #FFFFFF;

      &:hover {
        transform: scale(1.2);;
      }
    }
  }
}


</style>
