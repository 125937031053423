// @ts-ignore

// export const log = createSimpleLogger({timestampFormat: 'YYYY-MM-DD HH:mm:ss.SSS'});
class Log {
    private logLevel = 1;

    constructor() {
    }

    public info = (...logArg: any[]) => {
        if (this.logLevel >= 1) {
            logArg.splice(0, 0, '✅ info - ');
            console.info.apply(logArg);
        }
    };
    public debug = (...logArg: any[]) => {
        if (this.logLevel >= 0) {
            logArg.splice(0, 0, '👀 debug - ');
            console.info.apply(['debug', '👀 - '], logArg);
        }
    };

    public warn = (...logArg: any[]) => {
        logArg.splice(0, 0, '😞 warn - ');
        console.info.apply(null, logArg);
    };

    public error = (...logArg: any[]) => {
        console.error('🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
        logArg.splice(0, 0, '🔥 error - ');
        console.info.apply(null, logArg);
        console.error('🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
    };

    public setLevel = (value) => {
        if (value === 'INFO') {
            this.logLevel = 1;
        } else if (value === 'DEBUG') {
            this.logLevel = 0;
        }
    }
}

export const log = new Log();
