<template>
  <w-tool-tip :label="toolTipMessage"
              :size="tooltipSize"
              :color="tooltipColor"
              :position="tooltipPosition"
              :delay="tooltipDelay">
    <div :class="classOfAvatar+' '+type+ ' ' + classOfSelected" :style="backgroundOfAvatar" @click="onClick">
    </div>
  </w-tool-tip>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WToolTip from '@/components/wrapper/w-toolTip.vue';

@Component({
  components: {WToolTip},
})
export default class UserAvatar extends Vue {
  @Prop({default: require('@/assets/avatar.jpg')})
  private userAvatar!: string;
  @Prop({default: ''})
  private userFirstname!: string;
  @Prop({default: ''})
  private userLastname!: string;
  @Prop({default: ''})
  private email!: string;
  @Prop({default: ''})
  private size!: string;

  @Prop({default: 'top'})
  private tooltipPosition!: string;
  @Prop({default: 'small'})
  private tooltipSize!: string;
  @Prop({default: 'light'})
  private tooltipColor!: string;
  @Prop({default: 0})
  private tooltipDelay!: number;
  // circle / square / sticker
  @Prop({default: 'circle'})
  private type!: string;

  @Prop({default: false})
  private selected!: boolean;

  get classOfSelected() {
    return this.selected ? 'selected' : '';
  }

  get classOfAvatar() {
    return 'avatar ' + this.size;
  }

  get backgroundOfAvatar() {
    return 'background-image: url(' + this.userAvatar + ')';
  }

  get toolTipMessage(): string {
    if (this.userFirstname !== '') {
      return this.userFirstname + ' ' + this.userLastname;
    } else {
      return '';
    }
  }

  private onClick() {
    this.$emit('click');
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.avatar {
  background-size: cover;
  border-radius: 200px;
  width: 48px;
  height: 48px;
  transition: all 0.2s ease;
  border: 1px solid var(--light1);

  &:hover {
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  }

  &.selected {
    border: 1px solid var(--light);
    box-shadow: 0 0 0 2px var(--primary);
    //box-shadow: inset 0px 0px 0px 2px var(--primary);
  }

  &.square {
    border-radius: 16px;

    &.small {
      border-radius: 12px;
    }
  }

  &.none {
    border-radius: 0px;
    border: none;
  }

  &.sticker {
    box-sizing: border-box;
    border-radius: 48px 48px 48px 16px;
    border: 2px solid #FFFFFF;
  }

  &.xlarge {
    width: 160px;
    height: 160px;
    border-radius: 160px 160px 160px 16px;
  }

  &.large {
    width: 128px;
    height: 128px;
    border-radius: 128px 128px 128px 16px;
  }

  &.medium-large {
    width: 96px;
    height: 96px;
    border-radius: 96px 96px 96px 16px;
  }

  &.medium {
    width: 64px;
    height: 64px;
    border-radius: 64px 64px 64px 16px;
  }

  &.small {
    width: 36px;
    height: 36px;
    border-radius: 36px 36px 36px 12px;
  }

  &.tiny {
    width: 24px;
    height: 24px;
    border-radius: 24px 24px 24px 8px;
  }

  &.mini {
    width: 16px;
    height: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 16px 16px 16px 4px;
  }

  &.none.mini {
    border: 0px solid #FFFFFF;
  }

  &.sticker.tiny {
    border: 1px solid #FFFFFF;
  }
}
</style>
