/**
 * If you need to manipulate tour with mutliple component,
 * use this singleton
 */
export let GLOBAL_TOUR: any;

export const setGlobalTour = (tour: any) => {
  GLOBAL_TOUR = tour;
};

export const getGlobalTour = () => {
  return GLOBAL_TOUR;
};
/**
 * Hide the global tour
 */
export const hideGlobalTour = () => {
  if (GLOBAL_TOUR !== undefined) {
    GLOBAL_TOUR.hide();
  }
};
/**
 * restore the global tour
 */
export const restoreGlobalTour = () => {
  if (GLOBAL_TOUR !== undefined) {
    GLOBAL_TOUR.show();
  }
};


// https://shepherdjs.dev/docs/tutorial-02-usage.html
/**
 * Add a step to tour
 * @param tour
 * @param key
 * @param htmlContent
 * @param direction
 * @param isLast
 */
export const addStepTour = (tour: any, key: string,
                            htmlContent: any,
                            direction: string = 'top',
                            isLast: boolean = false) => {
  let labelButton = 'Next';
  if (isLast) {
    labelButton = 'End 😎 ';
  }
  tour.addStep({
    attachTo: {element: `[stepTour=${key}]`, on: direction},
    text: htmlContent,
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 16,
    arrow: true,
    classes: 'actionStepTour actionStepTour' + direction,
    buttons: [
      {
        action() {
          // @ts-ignore
          return this.cancel();
        },
        text: 'Skip',
        classes: 'skip',
      },
      {
        action() {
          // @ts-ignore
          return this.next();
        },
        text: labelButton
      },
    ]
  });
};

/**
 * Add a step to tour
 * @param tour
 * @param key
 * @param htmlContent
 * @param direction
 * @param autoNext
 * @param highlightClass
 */
export const addActionStepTour = (tour: any, key: string,
                                  htmlContent: any,
                                  direction: string = 'top',
                                  autoNext: boolean = true,
                                  highlightClass: string = 'highlightTourStep') => {
  tour.addStep({
    attachTo: {element: `[stepTour=${key}]`, on: direction},
    text: htmlContent,
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 16,
    arrow: false,
    classes: 'actionStepTour actionStepTour' + direction,
    cancelIcon: {
      enabled: true,
      label: 'Skip'
    },
    advanceOn: autoNext ? {selector: `[stepTour=${key}]`, event: 'click'} : {},
    highlightClass: highlightClass
  });
};
