<template>
  <div>
    <w-button
        @click="copyThumbnail"
        :rounded="rounded || circle"
        :size="size"
        :icon="icon"
        :icon-right="iconRight"
        :color="color"
        :disabled="!enabled"
        :loading="loading"
        v-on="$listeners"
      :tooltip="$t('copyWeetButton.thumbnail.tooltip')">
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import copy from 'copy-to-clipboard';
import {Weet} from '@/store/weet/weetModel';
import {getWeetURL} from '@/utils/util';
import {inform} from '@/utils/dialog';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {ThumbnailCopy} from '@/utils/video/videoEffect/ThumbnailCopy';

@Component({
  components: {WIcon, WToolTip, WButton},
})
export default class CopyThumbnailWeetButton extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default:  'image-outline'})
  private icon!: string;
  @Prop({default: ''})
  private iconRight!: string;
  @Prop({default: 'primary'})
  private color!: string;
  @Prop({default: false})
  private circle!: boolean;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: false})
  private loading!: boolean;
  @Prop({default: false})
  private link!: boolean;

  public async copyThumbnail() {
    // await new ThumbnailCopy().copyThumnbailWeet(this.weet);
    await new ThumbnailCopy().copyThumnbailWithLinkWeet(this.weet, this.$t('copyWeetButton.thumbnail.textImage').toString());
    inform(this.$t('copyWeetButton.thumbnail.confirm').toString());
  }
}
</script>
<style scoped lang="scss">
</style>
