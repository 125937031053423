import axios from 'axios';

import {BACK_ENDPOINT, DOWNLOAD_ENDPOINT} from '@/store/conf/api';
import Vue from 'vue';
import {UploadMedia} from '@/store/upload/uploadMediaModel';
import {
    MEDIA_DOWNLOAD_END,
    MEDIA_DOWNLOAD_PROGRESS,
    MEDIA_DOWNLOAD_START,
    MediaDownloadParams,
} from '@/store/download/downloadAction';
import {DownloadMedia} from '@/store/download/downloadMediaModel';
import {getVideoURLForStatus} from '@/utils/util';
import {
    CANCEL_CUSTOM_DOWNLOAD, CUSTOM_DOWNLOAD_DOWNLOADED,
    CustomDownloadParams,
    CustomDownloadResponse, GET_CUSTOM_DOWNLOAD_URL, GET_PROGRESS_CUSTOM_DOWNLOAD,
    LAUNCH_CUSTOM_DOWNLOAD
} from "@/store/customDownload/customDownloadAction";
import {Weet} from "@/store/weet/weetModel";
import progress = Mocha.reporters.progress;

const customDownloadAPI = BACK_ENDPOINT + '/customDownloads';

export default {
    state: {
        customDownloadProgress: {}
    },
    mutations: {
        [LAUNCH_CUSTOM_DOWNLOAD]: (state: any, customDownload: CustomDownloadResponse) => {
            Vue.set(state.customDownloadProgress, customDownload.weetID, customDownload);
        },
        [GET_PROGRESS_CUSTOM_DOWNLOAD]: (state: any, customDownload: CustomDownloadResponse) => {
            Vue.set(state.customDownloadProgress, customDownload.weetID, customDownload);
        },
        [CANCEL_CUSTOM_DOWNLOAD]: (state: any, customDownload: CustomDownloadResponse) => {
            Vue.delete(state.customDownloadProgress, customDownload.weetID);
        },
    },
    getters: {
        getWeetCustomDownloadProcessing: (state: any) => (weetID: string): CustomDownloadResponse => {
            return state.customDownloadProgress[weetID];
        },
    },
    actions: {
        async [LAUNCH_CUSTOM_DOWNLOAD]({
                                           commit,
                                           dispatch
                                       }: { commit: any, dispatch: any }, params: CustomDownloadParams) {

            const response = await axios({
                url: customDownloadAPI + "/" + params.weetID,
                params: params,
                method: 'GET'
            }).then((resp) => {
                commit(LAUNCH_CUSTOM_DOWNLOAD, resp.data);
                return resp.data;
            });
            return response;
        },

        async [GET_PROGRESS_CUSTOM_DOWNLOAD]({
                                                 commit,
                                                 dispatch
                                             }: { commit: any, dispatch: any }, params: CustomDownloadResponse) {
            const response = await axios({
                url: customDownloadAPI + "/" + params.weetID + "/" + params.jobID,
                method: 'GET'
            }).then((resp) => {
                commit(GET_PROGRESS_CUSTOM_DOWNLOAD, resp.data);
                return resp.data;
            });
            return response;
        },
        async [GET_CUSTOM_DOWNLOAD_URL]({
                                            commit,
                                            dispatch
                                        }: { commit: any, dispatch: any }, params: CustomDownloadResponse) {
            const response = await axios({
                url: customDownloadAPI + "/" + params.weetID + "/" + params.jobID + "/download",
                method: 'GET'
            }).then((resp) => {
                return resp.data;
            });
            return response;
        },
        async [CANCEL_CUSTOM_DOWNLOAD]({
                                           commit,
                                           dispatch
                                       }: { commit: any, dispatch: any }, params: CustomDownloadResponse) {
            const response = await axios({
                url: customDownloadAPI + "/" + params.weetID + "/" + params.jobID,
                method: 'DELETE'
            }).then((resp) => {
                commit(CANCEL_CUSTOM_DOWNLOAD, params);
                return resp.data;
            });
            return response;
        },
        [CUSTOM_DOWNLOAD_DOWNLOADED]({
                                         commit,
                                         dispatch
                                     }: { commit: any, dispatch: any }, params: CustomDownloadResponse) {
            commit(CANCEL_CUSTOM_DOWNLOAD, params);
        }
    },
};
