import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import Vue from 'vue';
import {
  CREATE_CHANNEL_TO_WORKSPACE,
  DELETE_CHANNEL_FROM_WORKSPACE,
  EDIT_CHANNEL, GET_LIST_OF_SUBSCRIBER, GET_MY_CHANNEL_SUBSCRIPTION,
  GET_MY_WORKSPACE_CHANNELS, REINIT_CHANNEL_FOR_WORKSPACE, REMOVE_CHANNEL_FROM_STORE,
  SELECT_WORKSPACE_CHANNEL, SILENCE_CHANNEL, SUBSCRIBE_TO_A_CHANNEL, UNSUBSCRIBE_TO_A_CHANNEL
} from '@/store/channel/channelAction';
import store from '@/store';
import delay from 'delay';
import {Channel, ChannelParams, ChannelSubscription} from '@/store/channel/channelModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';

const channelAPI = BACK_ENDPOINT + '/channels';

export default {
  state: {
    channels: {},
    subscribeChannels: {},
    currentChannelPage: 0,
    totalOfChannels: 0,
    selectedChannelID: '',
  },
  mutations: {
    [SELECT_WORKSPACE_CHANNEL]: (state: any, channelID: string) => {
      state.selectedChannelID = channelID;
    },
    [REINIT_CHANNEL_FOR_WORKSPACE]: (state: any) => {
      state.channels = {};
      state.subscribeChannels = {};
    },
    [GET_MY_WORKSPACE_CHANNELS]: (state: any, data: PaginationWrapper) => {
      const channels = data.data as Channel[];
      for (const channel of channels) {
        Vue.set(state.channels, channel.channelID, channel);
      }
      state.currentChannelPage = data.page;
      state.totalOfChannels = data.count;
    },
    [GET_MY_CHANNEL_SUBSCRIPTION]: (state: any, data: PaginationWrapper) => {
      const channelSubs = data.data as ChannelSubscription[];
      for (const channelSub of channelSubs) {
        Vue.set(state.subscribeChannels, channelSub.channelSubID, channelSub);
      }
    },
    [SUBSCRIBE_TO_A_CHANNEL]: (state: any, channelSub: ChannelSubscription) => {
      Vue.set(state.subscribeChannels, channelSub.channelSubID, channelSub);
    },
    [UNSUBSCRIBE_TO_A_CHANNEL]: (state: any, {channel, userID}: { channel: Channel, userID: string }) => {
      let channelSubToDelete: ChannelSubscription | null = null;
      for (const channelSub of Object.values(state.subscribeChannels) as ChannelSubscription[]) {
        if (channel.channelID === channelSub.channelID && channelSub.user.userID === userID) {
          channelSubToDelete = channelSub;
          break;
        }
      }
      if (channelSubToDelete) {
        Vue.delete(state.subscribeChannels, channelSubToDelete.channelSubID);
      }
    },
    [SILENCE_CHANNEL]: (state: any, {channel, silence}: { channel: Channel, silence: boolean }) => {
      for (const channelSub of Object.values(state.subscribeChannels) as ChannelSubscription[]) {
        if (channelSub.channelID === channel.channelID) {
          channelSub.silence = silence;
          Vue.set(state.subscribeChannels, channelSub.channelSubID, channelSub);
          break;
        }
      }
    },
    [CREATE_CHANNEL_TO_WORKSPACE]: (state: any, channel: Channel) => {
      Vue.set(state.channels, channel.channelID, channel);
    },
    [DELETE_CHANNEL_FROM_WORKSPACE]: (state: any, channelIds: string[]) => {
      for (const channelID of channelIds) {
        Vue.delete(state.channels, channelID);
      }
    },
  },
  getters: {
    // Return Channels for the "Manage channels" table
    getAllChannels: (state: any): Channel[] => {
      const arrayOfChannels = Object.values(state.channels) as Channel[];
      return arrayOfChannels.sort((a, b) => {
        return a.name.replace(/\W/g, '').localeCompare(b.name.replace(/\W/g, ''));
      });
    },
    getSubscriptionChannels: (state: any, getters: any): ChannelSubscription[] => {

      const arrayOfChannels = Object.values(state.subscribeChannels) as ChannelSubscription[];
      return arrayOfChannels.sort((a, b) => {
        const channelA = getters.getChannel(a.channelID);
        const channelB = getters.getChannel(b.channelID);
        if (!channelA || !channelB) {
          return -1;
        } else {
          if (!a.silence && !b.silence) {
            return channelA.name.replace(/\W/g, '').localeCompare(channelB.name.replace(/\W/g, ''));
          } else {
            if (a.silence && !b.silence) {
              return 1;
            }
            if (a.silence && b.silence) {
              return 0;
            }
            if (!a.silence && b.silence) {
              return -1;
            }
          }
        }
      });
    },
    getCurrentChannelsPage: (state: any): number => {
      return state.currentChannelPage;
    },
    getTotalOfChannels: (state: any): number => {
      return state.totalOfChannels;
    },
    getSelectedChannelID: (state: any): string => {
      return state.selectedChannelID;
    },
    getChannel: (state: any) => (channelID: string): Channel | undefined => {
      return state.channels[channelID];
    },
    getChannelByName: (state: any) => (channelName: string): Channel | undefined => {
      for (const channel of Object.values(state.channels) as Channel[]) {
        if (channel.name === channelName) {
          return channel;
        }
      }
      return undefined;
    },
    getFilteredChannel: (state: any) => (text: string): Channel[] => {
      return (Object.values(state.channels) as Channel[]).filter((channel) => {
        return channel.name.indexOf(text) > -1;
      });
    },
  },
  actions: {
    async [REINIT_CHANNEL_FOR_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(REINIT_CHANNEL_FOR_WORKSPACE);
    },
    async [GET_MY_WORKSPACE_CHANNELS]({
                                        commit,
                                        dispatch,
                                        getters
                                      }: { commit: any, dispatch: any, getters: any },
                                      channelParams: ChannelParams) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/workspace/${ws}`,
        method: 'GET',
        params: channelParams,
      }).then(async (resp) => {
        const paginationObj = resp.data as PaginationWrapper;
        commit(GET_MY_WORKSPACE_CHANNELS, resp.data);

        // load other page
        if (paginationObj.count > getters.getAllChannels.length) {
          await delay(100);
          channelParams.page++;
          dispatch(GET_MY_WORKSPACE_CHANNELS, channelParams);
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [CREATE_CHANNEL_TO_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any },
                                        names: string[]) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/workspace/${ws}`,
        method: 'POST',
        data: {names},
      }).then((resp) => {
        for (const channel of resp.data) {
          commit(CREATE_CHANNEL_TO_WORKSPACE, channel);
        }
        store.dispatch(GET_MY_CHANNEL_SUBSCRIPTION, new ChannelParams());
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [EDIT_CHANNEL]({commit, dispatch}: { commit: any, dispatch: any }, {
      channelID,
      name,
      visibilityPolicy
    }: {
      channelID: string,
      name: string | null,
      visibilityPolicy: VisibilityChannelPolicy | null
    }) {
      return await axios({
        url: `${channelAPI}/${channelID}`,
        method: 'PUT',
        data: {name, visibilityPolicy},
      }).then((resp) => {
        commit(CREATE_CHANNEL_TO_WORKSPACE, resp.data);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    [REMOVE_CHANNEL_FROM_STORE]({commit, dispatch}: { commit: any, dispatch: any },
                                channel: Channel) {
      commit(DELETE_CHANNEL_FROM_WORKSPACE, [channel.channelID]);
    },
    async [DELETE_CHANNEL_FROM_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any },
                                          channels: string[]) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/workspace/${ws}`,
        method: 'DELETE',
        data: {channels: channels},
      }).then(() => {
        commit(DELETE_CHANNEL_FROM_WORKSPACE, channels);
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    [SELECT_WORKSPACE_CHANNEL]({commit, dispatch}: { commit: any, dispatch: any }, channelID: string) {
      commit(SELECT_WORKSPACE_CHANNEL, channelID);
    },

    async [GET_MY_CHANNEL_SUBSCRIPTION]({
                                          commit,
                                          dispatch,
                                          getters
                                        }: { commit: any, dispatch: any, getters: any },
                                        channelParams: ChannelParams) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/workspace/${ws}/subscription`,
        method: 'GET',
        params: channelParams,
      }).then(async (resp) => {
        const paginationObj = resp.data as PaginationWrapper;
        await commit(GET_MY_CHANNEL_SUBSCRIPTION, resp.data);

        // load other page
        if (paginationObj.count > getters.getSubscriptionChannels.length) {
          await delay(100);
          channelParams.page++;
          dispatch(GET_MY_CHANNEL_SUBSCRIPTION, channelParams);
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SUBSCRIBE_TO_A_CHANNEL]({
                                     commit,
                                     dispatch,
                                     getters
                                   }: { commit: any, dispatch: any, getters: any },
                                   {channel, emails = []}: { channel: Channel, emails: string[] }) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/subscribe/${channel.channelID}`,
        method: 'POST',
        data: {emails}
      }).then(async (resp) => {
        for (const channelSub of resp.data as ChannelSubscription[]) {
          if (channelSub.user.userID === store.getters.userConnected.userID) {
            commit(SUBSCRIBE_TO_A_CHANNEL, channelSub);
          }
        }

        return resp;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [UNSUBSCRIBE_TO_A_CHANNEL]({
                                       commit,
                                       dispatch,
                                       getters
                                     }: { commit: any, dispatch: any, getters: any },
                                     {channel, userID}: { channel: Channel, userID: string }) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/subscribe/${channel.channelID}`,
        method: 'DELETE',
        data: {userID}
      }).then(async (resp) => {
        commit(UNSUBSCRIBE_TO_A_CHANNEL, {channel, userID});
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [GET_LIST_OF_SUBSCRIBER]({
                                     commit,
                                     dispatch,
                                     getters
                                   }: { commit: any, dispatch: any, getters: any },
                                   channelParams: ChannelParams): Promise<PaginationWrapper> {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/subscribe/${channelParams.channelID}`,
        method: 'GET',
        params: {page: channelParams.page}
      }).then(async (resp) => {
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SILENCE_CHANNEL]({
                              commit,
                              dispatch,
                              getters
                            }: { commit: any, dispatch: any, getters: any },
                            {channel, silence}: { channel: Channel, silence: boolean }) {
      const ws = store.getters.getSelectedWorkspaceID;
      if (!ws) {
        throw new Error('impossible to find a workspace');
      }
      return await axios({
        url: `${channelAPI}/silence/${channel.channelID}`,
        data: {silence},
        method: 'PUT',
      }).then(async (resp) => {
        // now we update the subscribtion
        commit(SILENCE_CHANNEL, {channel: channel, silence});
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },
};

