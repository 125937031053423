<template>
  <Transition name="zoom">
    <div class="pastille" :class="small?'small':''" v-show="isNotify"></div>
  </Transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {SectionEventDescriptor} from '@/store/timeLine/timeEvent';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';

@Component({
  components: {WIcon}
})
export default class WeetControlPastille extends Vue {
  @Prop({})
  private section!: SectionEventDescriptor;

  @Prop({})
  private weet!: Weet;

  @Prop({default: false})
  private small!: boolean;

  get isNotify(): boolean {
    for (const notification of store.getters.getWeetTypeUnreadTimelineNotification) {
      if (notification.weetID === this.weet.weetID && notification.by?.userID === this.section.userID &&
          // No purple dot on reply
          !notification.replyToCommentID &&
          Math.round(notification.time / 1000) === Math.round(this.section.time / 1000)) {
        return true;
      }
    }
    return false;
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import "@/scss/shadows.scss";

.pastille {
  background: var(--primary);
  border: solid 2px white;
  animation-delay: 2s;
  position: absolute;
  width: 12px;
  top: -4px;
  right: 16px;
  height: 12px;
  border-radius: 8px;
  @extend .shadow1;

  &.small {
    background: var(--primary);
    border: 1px solid #fff;
    position: absolute;
    width: 8px;
    top: -2px;
    right: -2px;
    height: 8px;
    border-radius: 8px;
  }
}
</style>
