import {Media} from '@/store/media/mediaModel';

export const MEDIA_DOWNLOAD_START = 'MEDIA_DOWNLOAD_START';
export const MEDIA_DOWNLOAD_PROGRESS = 'MEDIA_DOWNLOAD_PROGRESS';
export const MEDIA_DOWNLOAD_END = 'MEDIA_DOWNLOAD_END';

export class MediaDownloadParams {
    public media: Media;
    constructor(media: Media) {
        this.media = media;
    }
}
