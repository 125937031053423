import axios from 'axios';

import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  ADD_EVENT_TO_WEET_VIEW_SESSION,
  CREATE_WEET_VIEW_SESSION,
  LOAD_LAST_WEET_VIEWERS,
  STOP_WEET_VIEW_SESSION,
} from '@/store/weetview/weetViewAction';
import {WeetParamModel, WeetView} from '@/store/weetview/weetViewModel';
import {User} from '@/store/user/userModel';

export const weetViewAPI = BACK_ENDPOINT + '/weetView';
export default {
  state: {
    currentSessionID: '',
    weetViews: []
  },
  mutations: {
    [CREATE_WEET_VIEW_SESSION]: (state: any, sessionID: string) => {
      state.currentSessionID = sessionID;
    },
    [STOP_WEET_VIEW_SESSION]: (state: any) => {
      state.currentSessionID = '';
    },
    [LOAD_LAST_WEET_VIEWERS]: (state: any, viewers: User[]) => {
      state.weetViews = viewers;
    }
  },
  getters: {
    getWeetViewCurrentSession: (state: any): string => {
      return state.currentSessionID;
    },
    getWeetViews: (state: any): User[] => {
      return state.weetViews;
    }
  },
  actions: {
    async [CREATE_WEET_VIEW_SESSION]({commit, dispatch}: { commit: any, dispatch: any },
                                     weetParamModel: WeetParamModel) {
      await axios({
        url: weetViewAPI,
        data: weetParamModel,
        method: 'POST',
      }).then((resp) => {
        const weetView = resp.data as WeetView;
        commit(CREATE_WEET_VIEW_SESSION, weetView.sessionID);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [LOAD_LAST_WEET_VIEWERS]({commit, dispatch}: { commit: any, dispatch: any },
                                   {
                                     weetID,
                                     page = 0,
                                   }: { weetID: string, page: number }) {
      return await axios({
        url: `${weetViewAPI}/viewers/${weetID}?page=${page}`,
        method: 'GET',
      }).then((resp) => {
        const weetViews = resp.data as User[];
        commit(LOAD_LAST_WEET_VIEWERS, weetViews);
        return resp.data;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    [STOP_WEET_VIEW_SESSION]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
    ) {
      commit(STOP_WEET_VIEW_SESSION);

    },
    async [ADD_EVENT_TO_WEET_VIEW_SESSION]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                           {type, data}: { type: string, data: string }) {
      const sessionID = getters.getWeetViewCurrentSession;
      let time = getters.getTimerPlayer;
      // ignore if not relevant
      if (time < 100) {
        time = -1;
      }
      if (sessionID === '') {
        return;
      } else {
        await axios({
          url: weetViewAPI + '/' + sessionID,
          data: {type, data, time},
          method: 'PUT',
        }).then((resp) => {
          return 'OK';
        }).catch((err) => {
          if (err.data) {
            err = err.data;
          }
          throw err;
        });
      }
    }
  },

};
