<template>
  <div>
    <!-- TAG ALL -->
    <div class="tagLine" :class="isTagSelected(staticTag.ALL)+' '+classNotifyAll"
         @click="selectTag(staticTag.ALL)"
         data-test="menuTagAll" v-if="selectedWorkspaceID">
      <w-icon :icon="getTagIcon(staticTag.ALL)" size="small" class="iconTag"/>
      <span class="tagLabel">{{ $t('tags.my') }}</span>

    </div>

    <!-- TAG LAST VIEWED BY ME -->
    <div class="tagLine" :class="isTagSelected(staticTag.LAST_VIEWED)"
         @click="selectTag(staticTag.LAST_VIEWED)" data-test="menuTagLastViewed">
      <w-icon :icon="getTagIcon(staticTag.LAST_VIEWED)" size="small" class="iconTag"/>
      <span class="tagLabel">{{ $t('tags.lastViewed') }}</span>
    </div>

    <!-- TAG SHARED WITH ME -->
    <div class="tagLine" :class="isTagSelected(staticTag.SHARED_WITH_ME) +' '+classNotifySharedWithMe"
         @click="selectTag(staticTag.SHARED_WITH_ME)" data-test="menuTagSharedWithMe">
      <w-icon :icon="getTagIcon(staticTag.SHARED_WITH_ME)" size="small" class="iconTag"/>
      <span class="tagLabel">{{ $t('tags.share') }}</span>
    </div>

    <!-- TAG CREATED BY ME -->
    <div class="tagLine" :class="isTagSelected(staticTag.CREATED_BY_ME)+' '+classNotifyCreatedByMe"
         v-if="selectedWorkspaceID"
         @click="selectTag(staticTag.CREATED_BY_ME)" data-test="menuTagCreatedByMe">
      <w-icon :icon="getTagIcon(staticTag.CREATED_BY_ME)" size="small" class="iconTag"/>
      <span class="tagLabel">{{ $t('tags.createdByMe') }}</span>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import {StaticTag} from '@/store/myWeet/myWeetAction';
import {Notification} from '@/store/myNotifications/myNotificationModel';
import {getUserAvatar, getWorkspaceAvatar} from '@/utils/util';
import {getIconForChannel, selectWorkspace} from '@/utils/workspaceUtil';
import {Workspace} from '@/store/workspace/workspaceModel';

@Component({
  components: {
    WIcon
  },
})
export default class Menu extends Vue {

  private avatar(workspace: Workspace) {
    return getWorkspaceAvatar(workspace, 256);
  }

  get staticTag() {
    return StaticTag;
  }

  get classNotifyAll(): string {
    if (this.isNotifyAll) {
      return 'notify';
    } else {
      return '';
    }
  }

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspaceID;
  }

  get isNotifyAll(): boolean {
    return this.isNotifySharedWithMe || this.isNotifyCreatedByMe;
  }

  get notifications(): Notification[] {
    return store.getters.getWeetTypeUnreadNotification;
  }


  get isNotifySharedWithMe(): boolean {
    for (const notif of this.notifications) {
      if (StaticTag.SHARED_WITH_ME === notif.staticTag
          && notif.workspaceID === this.selectedWorkspaceID) {
        return true;
      }
    }
    return false;
  }

  get isNotifyCreatedByMe(): boolean {
    for (const notif of this.notifications) {
      if (StaticTag.CREATED_BY_ME === notif.staticTag
          && notif.workspaceID === this.selectedWorkspaceID) {
        return true;
      }
    }
    return false;
  }

  get classNotifySharedWithMe(): string {
    if (this.isNotifySharedWithMe) {
      return 'notify';
    } else {
      return '';
    }
  }

  get classNotifyCreatedByMe(): string {
    if (this.isNotifyCreatedByMe) {
      return 'notify';
    } else {
      return '';
    }
  }

  private isTagSelected(tag: string): string {
    if (store.getters.getTagsFilter === tag) {
      return 'selected';
    } else {
      return '';
    }
  }

  private getTagIcon(tag: string | StaticTag) {
    return getIconForChannel(tag);
  }

  private selectTag(tag: string) {
    this.$emit('select', tag);
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';

.tagLine {
  padding-left: 16px;
  line-height: 32px;
  font-size: 14px;
  color: var(--black);
  cursor: pointer;
  position: relative;
  text-align: left;

  &.notify {
    font-weight: 900;
  }


  &.selected {
    background: var(--light);

    .iconTag {

      color: var(--primary);
    }
  }

  .iconTag {
    color: var(--light2);
  }

  &:hover {
    background: var(--light1);

    .iconTag {
      color: var(--primary);
    }
  }

  .tagLabel {
    margin-left: 8px;
  }
}

</style>
