<template>
  <div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import {createUnsavedEditingWeet, getOwner, isOwner} from '@/utils/weetUtil';
import store from '@/store';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {ThumbnailGenerator} from '@/utils/video/videoEffect/ThumbnailGenerator';
import {getMediaForTimeEvent, getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import {UPDATE_MY_WEET_IMAGE} from '@/store/myWeet/myWeetAction';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Media} from '@/store/media/mediaModel';
import debounce from 'lodash/debounce';
import {User} from '@/store/user/userModel';
import delay from 'delay';

@Component({
  components: {},
})
export default class ThumbnailFixGenerator extends Vue {

  private DEFAULT_TIME = 1000;
  private tumbnailGenerator!: ThumbnailGenerator;
  private thumbnailInProgress: boolean = false;

  @Prop({required: true})
  private weet!: Weet;


  get isThumbnailGenerated(): boolean {
    if (this.weet) {
      return !!this.weet.thumbnail;
    } else {
      return false;
    }
  }

  get userConnected(): User|undefined {
    return store.getters.userConnected;
  }
  get isCanGenerate(): boolean {
    return isOwner(this.weet);
  }



  public async generateThumbnail() {

      if (this.weet && !this.thumbnailInProgress) {
        try {
          this.thumbnailInProgress = true;
          const blob = await this.tumbnailGenerator.generateThumbnailForWeet(this.weet, this.DEFAULT_TIME,
              {
                blur: false,
                avatarInSticker: false,
                removeSticker: false,
                showTitle: false,
                showTime: false,
                playIcon: true,
              background: null});
          this.thumbnailInProgress = false;
          store.dispatch(UPDATE_MY_WEET_IMAGE, {weetID: this.weet.weetID, blob});
          // const url = URL.createObjectURL(blob);
          // open(url);
        } catch (e) {
          // do nothing, data is missed for generate a thumbnail
          this.thumbnailInProgress = false;
        }
      }
  }

  public async mounted() {
    this.tumbnailGenerator = new ThumbnailGenerator();
    if (this.isCanGenerate && !this.isThumbnailGenerated) {
      await delay(Math.floor(Math.random() * 3000));
      this.generateThumbnail();
    }
  }

  public beforeDestroy() {
    this.tumbnailGenerator.destroy();
  }
}
</script>

<style scoped lang="scss">
</style>
