import {User} from '@/store/user/userModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';


export interface Channel {
  name: string;
  workspaceID: string;
  channelID: string;
  createdUTC: number;
  visibilityPolicy: VisibilityChannelPolicy;
  numberOfUser: number;
}

export interface ChannelSubscription {
  channelID: string;
  channelSubID: string;
  user: User;
  subscribed_dateUTC: number;
  silence: boolean;

}


export class ChannelParams {
  public page: number = 0;
  public nameFilter: string = '';
  public channelID: string = '';

  constructor(page: number = 0, nameFilter: string = '', channelID: string = '') {
    this.page = page;
    this.nameFilter = nameFilter;
    this.channelID = channelID;
  }
}
