<template>
  <div steptour="setting" class="settingPosition">
    <w-button icon="cog-outline" size="small"
              @click="activeSetingSource" color="light"
    ></w-button>
    <w-modal :active.sync="showChooseWC">
      <RecordSettingPanel/>
    </w-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import RecordSettingPanel from '@/components/recorder/setup/RecordSettingPanel.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';


@Component({
  components: {RecordSettingPanel, WModal, WButton, VideoSource},
  computed: {},
})
export default class SettingsButton extends Vue {

  private showChooseWC: boolean = false;

  public activeSetingSource() {
    this.showChooseWC = true;
    setActionEvent(ActionEvent.record_button_setting);
  }


}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';


</style>
