import { render, staticRenderFns } from "./ChooseScreen.vue?vue&type=template&id=e2175814&scoped=true"
import script from "./ChooseScreen.vue?vue&type=script&lang=ts"
export * from "./ChooseScreen.vue?vue&type=script&lang=ts"
import style0 from "./ChooseScreen.vue?vue&type=style&index=0&id=e2175814&prod&lang=scss"
import style1 from "./ChooseScreen.vue?vue&type=style&index=1&id=e2175814&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2175814",
  null
  
)

export default component.exports