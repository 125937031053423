import Vue from 'vue';
import store from '@/store';
import {Notification, NotificationType} from '@/store/myNotifications/myNotificationModel';
import {READ_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';


export const requestPermission = async () => {
    // @ts-ignore
    return await Vue.notification.requestPermission();
};


export const showNotification = (title: string, description: string, icon: string = '/favicon.png') => {
    // @ts-ignore
    Vue.notification.show(title, {
        body: description,
        icon: icon,
        silent: true
    }, {});
};

export const showNotificationWithOptionsAndEvents = (title: string, options: any, events: any = {}) => {
    // @ts-ignore
    Vue.notification.show(title, options, events);
};

export const readWorkspaceInviteNotification = (workspaceID: string) => {
    const allWorkspacesUnreadNotifs = store.getters.getWorkspaceInviteUnreadNotification;
    const currentWorkspaceUnreadNotif = allWorkspacesUnreadNotifs?.find((notif: Notification) => {
        return notif.workspace?.workspaceID === workspaceID;
    });
    if (currentWorkspaceUnreadNotif) {
        return store.dispatch(READ_NOTIFICATIONS, [currentWorkspaceUnreadNotif]);
    }
};

export function getNotificationIDs(notifications: Notification[]): string[] {
    return notifications.map((notif: Notification) => notif.id);
}

export function isInviteWorkspace(notification: Notification): boolean {
    return notification.type === NotificationType.INVITE_WORKSPACE;
}
