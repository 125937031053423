<template>
  <div>
    <w-modal :active.sync="isActive"
             trap-focus
             aria-role="dialog"
             aria-modal @close="onclose"
             style="overflow: auto">
      <CenterCenter v-if="isActive">
        <div class="contentModal">
          <!-- TITLE BLOCK -->
          <PopUpTitle :title="$t('download.paneloption.title')"/>
          <w-error :message="errormsg"/>

          <div class="labelTitleSlider">
            {{ $t('download.paneloption.resolution') }}
          </div>
          <div class="sliderOption">
            <b-slider :min="0" :max="3" aria-label="resolution" :tooltip="false" v-model="resolutionIndex">
              <b-slider-tick :value="0" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">720p</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="1" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">1080p</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="2" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">1440p (2K)</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="3" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">2160p (4K)</span>
                </div>
              </b-slider-tick>
            </b-slider>
          </div>

          <div class="labelTitleSlider">{{ $t('download.paneloption.fps') }}</div>
          <div class="sliderOption">
            <b-slider :min="0" :max="3" aria-label="fps" :tooltip="false" v-model="fpsIndex">
              <b-slider-tick :value="0" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">30fps</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="1" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">40fps</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="2" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">50fps</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="3" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">60fps</span>
                </div>
              </b-slider-tick>
            </b-slider>
          </div>

          <div class="labelTitleSlider">{{ $t('download.paneloption.speed') }}</div>
          <div class="sliderOption">
            <b-slider :min="0" :max="6" aria-label="speed" :tooltip="false" v-model="speedIndex">
              <b-slider-tick :value="0" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">0.5x</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="1" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">0.75x</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="2" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">1.00x (default)</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="3" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">1.25x</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="4" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">1.5x</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="5" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">2.00x</span>
                </div>
              </b-slider-tick>
              <b-slider-tick :value="6" class="sliderTick">
                <div class="sliderTickItem">
                  <span class="labelSliderTick">4.00x</span>
                </div>
              </b-slider-tick>
            </b-slider>
          </div>

          <div class="lineOptionSubtitleInteraction">
            <div class="chosseLangContainer">
              <div class="labelTitleSlider">{{ $t('download.paneloption.subtitle') }}</div>
              <div class="chooseLang">
                <w-select :value="'none'" @input="changeLang">
                  <option :value="'none'">
                    {{ $t('download.paneloption.noSubtitle') }}
                  </option>
                  <option :value="sub.lang" v-for="sub of weet.subtitles"
                          :key="sub.lang+sub.subtitleID">{{ sub.label }}
                  </option>
                </w-select>
              </div>
            </div>
            <div class="interaction">
              <div class="labelTitleSlider">{{ $t('download.paneloption.interaction') }}</div>
              <div class="switchInteraction">
               <w-switch size="small" @input="activateOpenInteraction"/>
              </div>
            </div>
          </div>


          <div>
            <w-button class="buttonValidate" :loading="loading" @click="downloadWeet" icon="download">
              {{ $t('download.paneloption.validate') }}
            </w-button>
          </div>

          <transition name="fade">
            <div class="modalWaiting" v-show="loading">
              <PopUpTitle :title="$t('download.paneloption.progress.title')"/>
              <CenterCenter>
                <div>
                  <div class="progressSubtitle">{{ $t('download.paneloption.progress.subtitle') }}</div>
                  <div class="pourcentageValue">{{ progressValue.toFixed(2) }}%</div>
                  <b-progress size="is-small" :value="progressValue" type="is-primary" class="progressBar" show-value>
                    {{ ' ' }}
                  </b-progress>
                </div>
              </CenterCenter>
              <div class="cancelBlock">
                <w-button color="outlined" @click="cancelDownload">{{
                    $t('download.paneloption.cancel')
                  }}
                </w-button>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="modalFinal" v-show="customDownloadResponse?.exportID">
              <PopUpTitle :title="$t('download.paneloption.final.title')"/>
              <CenterCenter>
                <div>
                  <div class="finishSubtitle">{{ $t('download.paneloption.final.subtitle') }}</div>
                </div>
              </CenterCenter>
              <div class="linkDownload">
                <w-button size="default" color="primary" icon="download" @click="download"
                          :loading="downloadLoading">
                  <span>{{ $t('download.paneloption.final.downloadButton') }}</span>
                </w-button>
              </div>
            </div>
          </transition>
        </div>
      </CenterCenter>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import WModal from '@/components/wrapper/w-modal.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WorkspaceSelection from '@/components/workspace/WorkspaceSelection.vue';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WError from '@/components/wrapper/w-error.vue';
import InputWorkspaceChoose from '@/components/workspace/InputWorkspaceChoose.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from "@/components/wrapper/w-icon.vue";
import WHelp from "@/components/wrapper/w-help.vue";
import {
  CANCEL_CUSTOM_DOWNLOAD, CUSTOM_DOWNLOAD_DOWNLOADED,
  CustomDownloadParams, CustomDownloadResponse, GET_CUSTOM_DOWNLOAD_URL,
  GET_PROGRESS_CUSTOM_DOWNLOAD,
  LAUNCH_CUSTOM_DOWNLOAD
} from "@/store/customDownload/customDownloadAction";
import delay from "delay";
import {alertError, alertErrorWithConfirmation, confettiBee} from "@/utils/dialog";
import {downloadUrl} from "@/utils/util";
import slugify from "@sindresorhus/slugify";
import {isWeetIsReady} from "@/utils/weetUtil";
import WSelect from "@/components/wrapper/w-select.vue";
import WSwitch from "@/components/wrapper/w-switch.vue";

@Component({
  components: {
    WSwitch,
    WSelect,
    WHelp,
    WIcon,
    WButton,
    InputWorkspaceChoose,
    UserAvatar,
    WorkspaceAvatar,
    WorkspaceSelection,
    CenterCenter,
    WModal,
    PopUpTitle,
    WError
  }
})
export default class DownloadOptionPanel extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: false})
  private active!: boolean;
  private isActive: boolean = this.active || false;
  private errormsg = '';
  private resolutionIndex = 0;
  private fpsIndex = 0;
  private speedIndex = 2;
  private loading: boolean = false;
  private downloadLoading: boolean = false;

  private tabResolution = [720, 1080, 1440, 2160];
  private tabSpeed = [0.5, 0.75, 1, 1.25, 1.5, 2, 4, 8];
  private tabFps = [30, 40, 50, 60];

  private progressValue = 0.00;
  private urlToDownload: string = "";
  private lang: string = "";

  private openInteraction:boolean=false;

  get customDownloadResponse(): CustomDownloadResponse {
    return store.getters.getWeetCustomDownloadProcessing(this.weet.weetID);
  }

  private start() {
    if (store.getters.getWeetCustomDownloadProcessing(this.weet.weetID)) {
      this.startProgress();
      this.loading = true;
    }
  }

  private changeLang(value: string) {
    this.lang = value;
  }
  private activateOpenInteraction(value:boolean){
    this.openInteraction=value;
  }

  private downloadWeet() {
    if (!isWeetIsReady(this.weet)) {
      this.showNotExportableMessage();
      return;
    }
    var height = this.tabResolution[this.resolutionIndex];
    var fps = this.tabFps[this.fpsIndex];
    var speed = this.tabSpeed[this.speedIndex];
    var urlSubtitle = "";
    if (this.lang) {
      for (var sub of this.weet.subtitles) {
        if (sub.lang === this.lang) {
          urlSubtitle = sub.url;
        }
      }
    }
    var param = new CustomDownloadParams(this.weet.weetID, height, fps, speed, urlSubtitle,this.openInteraction);
    store.dispatch(LAUNCH_CUSTOM_DOWNLOAD, param);
    this.loading = true;
    this.startProgress();
  }


  private async startProgress() {
    if (!this.isActive) {
      return;
    }
    await delay(1000);
    if (this.customDownloadResponse) {
      await store.dispatch(GET_PROGRESS_CUSTOM_DOWNLOAD, this.customDownloadResponse);
      const progress = this.customDownloadResponse.progress;
      if (progress < 0) {
        await this.showErrorMessage();
        this.cancelDownload();
        return;
      }

      this.progressValue = progress;
      if (!this.customDownloadResponse.exportID) {
        this.startProgress();
      } else {
        this.urlToDownload = await store.dispatch(GET_CUSTOM_DOWNLOAD_URL, this.customDownloadResponse);
        confettiBee(1000);
        this.resetProgressScreen();
        this.download();
      }
    } else {
      this.resetProgressScreen();
    }
  }

  private async cancelDownload() {
    await store.dispatch(CANCEL_CUSTOM_DOWNLOAD, this.customDownloadResponse);
    this.resetProgressScreen()
  }

  private resetProgressScreen() {
    this.loading = false;
    this.progressValue = 0;
  }

  private async showErrorMessage() {
    await alertErrorWithConfirmation(this.$t('download.paneloption.error.message').toString(), this.$t('download.paneloption.error.title').toString());
  }

  private async showNotExportableMessage() {
    await alertErrorWithConfirmation(this.$t('download.paneloption.nonExportable.message').toString(), this.$t('download.paneloption.nonExportable.title').toString());
  }

  @Watch('active')
  private isActiveChange() {
    this.isActive = this.active;
    if (this.isActive) {
      this.start();
    }
  }

  private close() {
    this.isActive = false;
    this.onclose();
  }

  private onclose() {
    this.$emit('close');
    this.$emit('update:active', false);
  }


  private async download() {
    this.downloadLoading = true;
    await downloadUrl(this.urlToDownload, slugify(this.weet.title) + this.tabResolution[this.resolutionIndex] + "p_" + this.tabFps[this.fpsIndex] + "fps_" + this.tabSpeed[this.speedIndex] + "x", 'video/mp4');
    await delay(2000);
    this.downloadLoading = false;
    store.dispatch(CUSTOM_DOWNLOAD_DOWNLOADED, this.customDownloadResponse)
    this.close();
  }

}
</script>

<style scoped lang="scss">


.contentModal {
  background: #FFF;
  max-width: 600px;
  min-height: 350px;
  padding: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 80px;
  border-radius: 8px;
  margin: auto;
  width: 100%;
  position: relative;
  text-align: left;

  .subTitleMovePanel {
    color: var(--dark);
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  .labelTitleSlider {
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
  }

  .modalWaiting, .modalFinal {
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    padding: 16px;
    padding-left: 32px;
    padding-right: 32px;
    top: 0px;
    left: 0px;
    border-radius: 8px;

    .progressSubtitle {
      margin-bottom: 24px;
      font-size: 16px;
    }

    .finishSubtitle {
      margin-bottom: 180px;
      font-size: 18px;
    }

    .progressBar {
      width: 100%;
      margin-bottom: 112px;
    }

    .cancelBlock, .linkDownload {
      position: absolute;
      bottom: 32px;
      right: 32px;
    }

  }

  .lineOptionSubtitleInteraction{
    display: flex;
    .chosseLangContainer{
      flex: 1;
      .chooseLang {
        margin-bottom: 24px;
      }
    }
    .interaction{
      min-width: 200px;
      margin-left: 24px;
      .switchInteraction{
        height: 42px;
        display: flex;
        align-items: center;
      }
    }
  }


  .sliderOption {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 48px;

    .sliderTick {
      background: var(--light2);

      .sliderTickItem {
        margin-top: 12px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        .labelSliderTick {
          font-size: 12px;

        }
      }
    }
  }


  .buttonValidate {
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
}
</style>
