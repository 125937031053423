<template>
  <transition name="none">
    <div v-show="mustShow">
      <div :class="'mainTitle '+size+' '+color">
        <div class="logoTitle" v-show="icon!==''">
          <w-icon :icon="icon" color="black" :size="size"/>
        </div>
        <div class="blocktitle" :class="{'editable':editable}" :contenteditable="editable"
             @focusout="validateText" @keydown.enter="focusOutTextEdition">
          {{ title }}
        </div>
        <div class="blockright">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';

@Component({
  components: {WIcon}
})
export default class MainTitle extends Vue {
  @Prop({default: 'Your title'}) private title!: string;
  @Prop({default: ''})
  private icon!: string;

  @Prop({default: 'dark'})
  private color!: string;
  @Prop({default: 'default'})
  private size!: string;

  @Prop({default: false})
  private editable!: boolean;

  private mustShow: boolean = false;

  public mounted() {
    this.mustShow = true;
  }

  private focusOutTextEdition(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

  private validateText(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      this.$emit('input', element.innerText.trim());
    }
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';

.mainTitle {
  display: flex;
  animation-duration: 0.2s;
  align-items: center;

  @include GRID1X {
    flex-wrap: wrap;
  }

  &.default {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    @include GRID2X {
      font-size: 20px;
    }
    @include GRID1X {
      font-size: 24px;
      text-align: center;
    }
  }

  &.small {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  &.light {
    color: var(--light2);
  }
}

.blocktitle {
  text-align: left;
  text-transform: capitalize;
  margin: 0;

  &.editable {
    border-radius: 4px;

    &:hover {
      box-shadow: 0px 0px 2px var(--light2);
    }

    &:focus {
      box-shadow: 0px 0px 2px var(--primary);
    }
  }
}

.logoTitle {
  display: flex;
  padding-right: 8px;
  @include GRID1X {
    display: none;
  }

  img {
    margin: 0;
    max-height: 60px;
  }
}

.blockright {
  float: right;
  text-align: right;
  flex: 1;
  @include GRID1X {
    float: inherit;
    display: block;
    text-align: center;
  }
}

</style>
