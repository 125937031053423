<template>

  <div class="main">
    <transition-group name="list-complete">
      <div :title="channel.name" class="channelLine list-complete-item" v-for="channel of myWorkspaceMenuChannels"
           :class="{'selected': isChannelSelected(channel) ,'notify': isNotify(channel), 'silence':isSilence(channel)}"
           @click="selectChannel(channel)" :key="'listMenuChannel'+channel.channelID">
        <w-icon :icon="isPrivate(channel)?'lock-outline':'pound'" size="small" class="iconChannel"/>
        <span class="channelLabel">{{ channel.name }}</span>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {Notification} from '@/store/myNotifications/myNotificationModel';
import WorkspaceMenu from '@/components/workspace/WorkspaceMenu.vue';
import {Channel, ChannelSubscription} from '@/store/channel/channelModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';

@Component({
  components: {
    WorkspaceMenu,
    WButton, WToolTip, WIcon
  },
})
export default class WorkspaceMenuChannelList extends Vue {

  get selectedChannelID(): string {
    return store.getters.getChannelFilter;
  }

  private isPrivate(channel: Channel) {
    return channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  private isChannelSelected(channel: Channel): boolean {
    return this.selectedChannelID === channel.channelID;
  }

  private isNotify(channel: Channel): boolean {
    for (const notif of this.unreadNotifications) {
      if (notif.channels?.includes(channel.channelID)) {
        return true;
      }
    }
    return false;
  }

  private selectChannel(channel: Channel) {
    this.$emit('select', channel.channelID);
  }

  private isSilence(channel: Channel): boolean {
    for (const channelSub of this.mySubscriptionChannel) {
      if (channelSub.channelID === channel.channelID) {
        return channelSub.silence;
      }
    }
    return false;
  }

  get mySubscriptionChannel(): ChannelSubscription[] {
    return store.getters.getSubscriptionChannels;
  }

  get myWorkspaceMenuChannels(): Channel[] {
    const listOfChannel: Channel[] = [];
    for (const channelSub of this.mySubscriptionChannel) {
      const channel = store.getters.getChannel(channelSub.channelID);
      if (channel) {
        listOfChannel.push(channel);
      }
    }
    return listOfChannel;
  }

  get unreadNotifications(): Notification[] {
    return store.getters.getWeetTypeUnreadNotification;
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';

.channelLine {
  padding-left: 16px;
  line-height: 32px;
  font-size: 14px;
  color: var(--black);
  cursor: pointer;
  position: relative;
  text-align: left;
  transition: all 0.5s ease;
  max-width: 220px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include GRID1X {
    max-width: 100%;
  }

  &.notify {
    font-weight: 900;
  }

  &.silence {
    color: var(--dark);
    opacity: 0.7;
  }

  &.selected {
    background: var(--light);

    .iconChannel {

      color: var(--primary);
    }
  }

  .iconChannel {
    color: var(--light2);
  }

  &:hover {
    background: var(--light1);

    .iconChannel {
      color: var(--primary);
    }
  }

  .channelLabel {
    margin-left: 8px;
  }
}

</style>
