import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';

export class CopyBitmapTransformer implements ICanvasTransform {
  public initialised: boolean = false;

  private streamSource!: MediaStream;
  // @ts-ignore
  private imageCapture !: ImageCapture;
  private id: string = uuidv4();


  public async init(stream: MediaStream) {
    this.streamSource = stream;
    const [track] = this.streamSource.getVideoTracks();
    // @ts-ignore
    this.imageCapture = new ImageCapture(track);
    log.debug('Initialise CopyBitmapTransform');
  }

  public async start() {
    log.debug('start CopyBitmapTransform');
    return;
  }

  public async transform(sleepMode: boolean, input: ImageBitmap | null = null): Promise<ImageBitmap | null> {
    try {
      // we grab the frame in sleep mode if note the track will auto desactivate
      const dateStart = new Date().getTime();
      let imageGrabbed = input;
      if (!imageGrabbed && this.imageCapture) {
        imageGrabbed = await this.imageCapture.grabFrame();
      }

      if (imageGrabbed && imageGrabbed.width > 0) {
        this.initialised = true;
        return imageGrabbed;
      } else {
        return null;
      }

    } catch (e) {
      if (e) {
        return null;
        //   throw new Error('RENDER_ERROR: ' + e.toString());
      } else {
        return null;
      }
    }
  }

  public async flush() {
    log.debug('Flush CopyBitmapTransform');
    this.imageCapture = undefined;
    for (const track of this.streamSource.getTracks()) {
      track.stop();
    }
  }
}
