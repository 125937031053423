import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {
  OPEN_MENU,
  SETUP_COMPLETE, SETUP_EXT, SETUP_FAVORITE_FACE_TRACK, SETUP_FAVORITE_FILTER,
  SETUP_FAVORITE_LAYOUT, SETUP_FAVORITE_MICROPHONE, SETUP_FAVORITE_WEBCAM,
  SETUP_SCREENCAST, SETUP_SEGMENTATION_FRAMERATE,
  SETUP_WC, SWITCH_TO_NATIVE_PLAYER,
} from './setupAction';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';

export default {
  state: {
    webcamAivailable: false,
    screencastAivailable: false,
    setupComplete: false,
    favoriteLayout: StickerPosition.BOTTOM_LEFT,
    favoriteFilter: FilterVideoType.NONE,
    faceTrack: false,
    favoriteWebcamId: '',
    favoriteMicrophoneId: '',
    menuOpen: true,
    extAvailable: false,
    nativePlayer: undefined,
    fpsSegmentation: -1,
  },
  mutations: {
    // SETUP REGISTER
    [SETUP_WC]: (state: any, allow: boolean) => {
      state.webcamAivailable = allow;
    },
    [SETUP_SCREENCAST]: (state: any, allow: boolean) => {
      state.screencastAivailable = allow;
    },
    [SETUP_COMPLETE]: (state: any, value: boolean) => {
      state.setupComplete = value;
    },
    [SETUP_FAVORITE_FILTER]: (state: any, filter: FilterVideoType | VirtualBackgroundEnum) => {
      state.favoriteFilter = filter;
    },
    [SETUP_FAVORITE_FACE_TRACK]: (state: any, facetrack: boolean) => {
      state.faceTrack = facetrack;
    },
    [SETUP_FAVORITE_WEBCAM]: (state: any, cameraID: string) => {
      state.favoriteWebcamId = cameraID;
    },
    [SETUP_FAVORITE_MICROPHONE]: (state: any, microphoneID: string) => {
      state.favoriteMicrophoneId = microphoneID;
    },
    [SETUP_FAVORITE_LAYOUT]: (state: any, stickersPostion: StickerPosition) => {
      state.favoriteLayout = stickersPostion;
    },
    [OPEN_MENU]: (state: any, open: boolean) => {
      state.menuOpen = open;
    },
    [SETUP_EXT]: (state: any, value: boolean) => {
      state.extAvailable = value;
    },
    [SWITCH_TO_NATIVE_PLAYER]: (state: any, native: boolean | undefined) => {
      state.nativePlayer = native;
    },
    [SETUP_SEGMENTATION_FRAMERATE]: (state: any, value: number) => {
      state.fpsSegmentation = value;
    },
  },
  getters: {
    isSetupComplete: (state: any): boolean => {
      return state.setupComplete;
    },
    isWebcamAivalable: (state: any): boolean => {
      return state.webcamAivailable;
    },
    isScreencastAivalable: (state: any): boolean => {
      return state.screencastAivailable;
    },
    getFavoriteLayout: (state: any): StickerPosition => {
      return state.favoriteLayout;
    },
    getFavoriteFilter: (state: any): FilterVideoType | VirtualBackgroundEnum => {
      return state.favoriteFilter;
    },
    getFavoriteFaceTrack: (state: any): boolean => {
      return state.faceTrack;
    },
    getFavoriteCamera: (state: any): boolean => {
      return state.favoriteWebcamId;
    },
    getFavoriteMicro: (state: any): boolean => {
      return state.favoriteMicrophoneId;
    },
    isMenuOpen: (state: any): boolean => {
      return state.menuOpen;
    },
    isExtAvalaible: (state: any): boolean => {
      return state.extAvailable;
    },
    isNativePlayer: (state: any): boolean | undefined => {
      return state.nativePlayer;
    },
    getSegmentationFramerate: (state: any): number => {
      return state.fpsSegmentation;
    },
  },
  actions: {
    [OPEN_MENU]({commit, dispatch}: { commit: any, dispatch: any }, open: boolean) {
      commit(OPEN_MENU, open);
    },
    // STATE RECORD
    [SETUP_WC]({commit, dispatch}: { commit: any, dispatch: any }, allow: boolean) {
      commit(SETUP_WC, allow);
    },
    [SETUP_SCREENCAST]({commit, dispatch}: { commit: any, dispatch: any }, allow: boolean) {
      commit(SETUP_SCREENCAST, allow);
    },
    [SETUP_COMPLETE]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
      commit(SETUP_COMPLETE, value);
    },
    [SETUP_FAVORITE_LAYOUT]({commit, dispatch}: { commit: any, dispatch: any }, position: StickerPosition) {
      commit(SETUP_FAVORITE_LAYOUT, position);
    },
    [SETUP_FAVORITE_WEBCAM]({commit, dispatch}: { commit: any, dispatch: any }, webcamId: string) {
      commit(SETUP_FAVORITE_WEBCAM, webcamId);
    },
    [SETUP_FAVORITE_MICROPHONE]({commit, dispatch}: { commit: any, dispatch: any }, microphoneId: string) {
      commit(SETUP_FAVORITE_MICROPHONE, microphoneId);
    },
    [SETUP_FAVORITE_FILTER]({
                              commit,
                              dispatch
                            }: { commit: any, dispatch: any },
                            filter: FilterVideoType | VirtualBackgroundEnum) {
      commit(SETUP_FAVORITE_FILTER, filter);
    },
    [SETUP_FAVORITE_FACE_TRACK]({
                                  commit,
                                  dispatch
                                }: { commit: any, dispatch: any },
                                value: boolean) {
      commit(SETUP_FAVORITE_FACE_TRACK, value);
    },
    [SETUP_EXT]({commit, dispatch}: { commit: any, dispatch: any }, value: true) {
      commit(SETUP_EXT, value);
    },
    [SWITCH_TO_NATIVE_PLAYER]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
      commit(SWITCH_TO_NATIVE_PLAYER, value);
    },
    [SETUP_SEGMENTATION_FRAMERATE]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
      commit(SETUP_SEGMENTATION_FRAMERATE, value);
    },
  },
};
