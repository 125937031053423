<template>
  <div class="layoutBackground">
    <div @click="closeOnboarding" class="closeIcon">
      <w-icon icon="close"></w-icon>
    </div>
    <div class="titleOnboarding">
      {{ $t('onboardingSection.title') }}
    </div>
    <b-carousel :arrow="false" :interval="10000"
                :pause-hover="false" v-model="carrouselIndex">
      <b-carousel-item>
        <div class="contentOnboarding">{{ $t('onboardingSection.p1') }}</div>
        <div class="onboardingVideo">
          <video muted src="/onboarding/o4.mp4" autoplay v-if="carrouselIndex===0"/>
        </div>
      </b-carousel-item>
      <b-carousel-item>
        <div class="contentOnboarding">{{ $t('onboardingSection.p2') }}</div>
        <div class="onboardingVideo">
          <video muted src="/onboarding/o5.mp4" autoplay v-if="carrouselIndex===1"/>
        </div>
      </b-carousel-item>
      <b-carousel-item>
        <div class="contentOnboarding">{{ $t('onboardingSection.p3') }}</div>
        <div class="onboardingVideo">
          <video muted src="/onboarding/o6.mp4" autoplay v-if="carrouselIndex===2"/>
        </div>
      </b-carousel-item>
    </b-carousel>
    <a href="https://help.weet.co/en/collections/2550539-getting-started" target="_blank" class="helpIcon">
      <w-icon icon="help-circle-outline" size="small"></w-icon>
    </a>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ExternalWeetPlayer from '@/components/play/player/modal/ExternalWeetPlayer.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import {ONBOARDING_RECORDER_1, ONBOARDING_SECTION_1} from '@/store/persistedInformation/persistAction';

@Component(
    {
      components: {WIcon},
    })
export default class OnboardingSection extends Vue {
  private carrouselIndex: number = 0;

  private closeOnboarding() {
    store.dispatch(ONBOARDING_SECTION_1, true);
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.layoutBackground {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 280px;
  text-align: left;
  @extend .shadow1;


  .closeIcon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .titleOnboarding {
    color: var(--black);
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.3px;
    margin-bottom: 16px;
  }

  .contentOnboarding {
    font-size: 14px;
    text-align: left;
    min-height: 64px;
  }

  .onboardingVideo {
    margin-top: 16px;
    margin-bottom: 16px;
    min-height: 116px;
    height: 116px;
  }

  .helpIcon {
    color: var(--dark);
    position: absolute;
    bottom: 16px;
    left: 24px;
  }
}
</style>
