<template>
  <div>
    <div class="button1" :key="'button1'">
      <w-button data-test="recorderMicMuteButton" :enabled="!isWebcamFull && !isWebcamIntegration" @click="switchMute"
                class="buttonRecorder"
                :icon="muteIcon" size="small"
                color="text"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {
  RECORD_STICKER_FILTER,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_MUTE,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIDEO_MUTE,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';


@Component({
  components: {WButton, VideoSource},
  computed: {},
})
export default class StickerMicMuteButton extends Vue {
  @Prop({default: 'right'})
  public toolTipPosition!: string;


  get isWebcamFull() {
    return store.getters.getMainRecordSource === SourceVideoType.WEBCAM;
  }

  get isWebcamIntegration() {
    return store.getters.getMainRecordSource === SourceVideoType.SCREENCAST && store.getters.getWebCamIntegration !== WebCamIntegrationTypeEnum.NONE;
  }

  get muteIcon(): string {
    if (store.getters.isStickerMute) {
      return 'microphone-off';
    } else {
      return 'microphone-outline';
    }
  }

  public switchMute() {
    if (store.getters.isStickerMute) {
      setActionEvent(ActionEvent.record_button_unmute_mic);
      this.$buefy.toast.open({
        message: this.$t('stickers.button.micro.toast.on').toString(),
        type: 'is-light',
      });
    } else {
      setActionEvent(ActionEvent.record_button_mute_mic);
      this.$buefy.toast.open({
        message: this.$t('stickers.button.micro.toast.off').toString(),
        type: 'is-danger',
      });
    }
    store.dispatch(RECORD_STICKER_MUTE);
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';

</style>
