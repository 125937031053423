import axios from 'axios';
import {
    DELETE_ANNOTATION_ON_WEET,
    REFRESH_WEET_FOR_EDITING, UPDATE_ANNOTATION_ON_WEET,
} from '@/store/myWeet/myWeetAction';
import store from '@/store';
import {log} from '@/utils/log';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    CREATE_ANNOTATION,
    DELETE_ANNOTATION,
    SELECT_ANNOTATION, UNSELECT_ANNOTATION,
    UPDATE_ANNOTATION
} from "@/store/annotation/annotationAction";
import {Annotation} from "@/store/annotation/annotationModel";

export const ANNOTATION_API = BACK_ENDPOINT + '/annotations';

export default {
    state: {
        annotationSelected: {},
    },
    mutations: {
        [SELECT_ANNOTATION]: (state: any, annotation: Annotation) => {
            state.annotationSelected = annotation;
        }
    },
    getters: {
        getAnnotationSelected: (state: any): Annotation | undefined => {
            if (state.annotationSelected) {
                return state.annotationSelected as Annotation;
            } else {
                return undefined;
            }
        },
    },
    actions: {
        async [CREATE_ANNOTATION]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  {weetID, annotation}: { weetID: string, annotation: Annotation }) {
            return await axios(
                {
                    url: ANNOTATION_API + "/" + weetID,
                    method: "POST",
                    data: annotation
                }
            ).then((resp) => {
                store.dispatch(UPDATE_ANNOTATION_ON_WEET,{weetID:weetID,annotation:resp.data});
                return resp.data;
            }).catch((err) => {
                log.error(err.message)
                throw err;
            });
        },
        async [UPDATE_ANNOTATION]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  {
                                      weetID,
                                      annotation,
                                  }: { weetID: string, annotation: Annotation }) {
            store.dispatch(UPDATE_ANNOTATION_ON_WEET,{weetID:weetID,annotation:annotation});
            return await axios(
                {
                    url: ANNOTATION_API + "/" + weetID,
                    method: "PUT",
                    data: annotation
                }
            ).then((resp) => {
                store.dispatch(UPDATE_ANNOTATION_ON_WEET,{weetID:weetID,annotation:resp.data});
                return resp.data;
            }).catch((err) => {
                log.error(err.message)
                throw err;
            });
        },
        async [SELECT_ANNOTATION]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  annotation: Annotation) {
            commit(SELECT_ANNOTATION, annotation);
        },
        async [UNSELECT_ANNOTATION]({
                                        commit,
                                        dispatch,
                                        state,
                                        getters
                                    }: { commit: any, dispatch: any, state: any, getters: any }) {
            commit(SELECT_ANNOTATION, undefined);
        },
        async [DELETE_ANNOTATION]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  {weetID, annotation}: { weetID: string, annotation: Annotation }) {

            store.dispatch(DELETE_ANNOTATION_ON_WEET, {weetID,annotation});
            await axios(
                {
                    url: ANNOTATION_API + "/" + weetID + "/" + annotation.annotationID,
                    method: "DELETE"
                }
            ).then((resp) => {
                return resp.data;
            }).catch((err) => {
                log.error(err.message)
                throw err;
            });
        },
    },
};
