<template>
  <transition name="zoom">
    <div class="videoFilePlayerContainer" @mouseover="highlight=true" @mouseleave="highlight=false" v-show="mustShow">
      <video ref="myVideo" @seeked="createTimeEvent" v-on="$listeners" class="videoElement" :src="src"
             @loadedmetadata="setDuration"/>

      <Transition name="zoomUp">
        <div class="playContainer" v-show="highlight" @click="play=!play">
          <!--          <img src="@/assets/play.png" class="logoPlay" v-show="!play">-->
          <!--          <img src="@/assets/play.png" class="logoPlay" v-show="play">-->
        </div>
      </Transition>
      <Transition name="fade">
        <div class="controlBar" v-show="highlight">
          <div class="sliderContainer">
            <b-slider rounded :max="duration" :custom-formatter="val => getHumanTime(val)" :value="currentTime"
                      @change="setCurrentTime" size="is-large" :type="color"></b-slider>
          </div>
        </div>
      </Transition>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import prettyMilliseconds from 'pretty-ms';
import {
  SET_MAIN_TIME_RECORDING,
  UPDATE_MAIN_RUNNING_STATE,
  UPDATE_MEDIA_CONTROL_CHANGE,
} from '@/store/recordingState/recordStateAction';
import store from '@/store';
import {TimeEventType} from '@/enum/TimeEventType';
import {getRandomColor} from '@/utils/util';

@Component
export default class VideoUploadPlayer extends Vue {
  @Prop({default: ''})
  private src!: string;

  private mustShow: boolean = false;
  private highlight: boolean = false;

  private currentTime: number = 0;
  private play: boolean = false;
  private duration: number = 0;

  get videoElement(): HTMLVideoElement {
    return this.$refs.myVideo as HTMLVideoElement;
  }

  get color(): string {
    return getRandomColor();
  }

  public createTimeEvent(e) {
    store.dispatch(UPDATE_MEDIA_CONTROL_CHANGE, TimeEventType.MEDIA_SEEK);
  }

  public setCurrentTime(e) {
    this.videoElement.currentTime = e;
  }

  @Watch('play')
  public onPlay() {

    if (this.play) {
      store.dispatch(UPDATE_MAIN_RUNNING_STATE, true);
      this.videoElement.play();
    } else {
      store.dispatch(UPDATE_MAIN_RUNNING_STATE, false);
      this.videoElement.pause();
    }
  }

  public getHumanTime(val: number): string {
    return prettyMilliseconds(val * 1000);
  }

  public mounted() {
    this.mustShow = true;

    if (this.videoElement != null) {
      this.videoElement.addEventListener('timeupdate', this.onTimeUpadte);
    }
  }

  public onTimeUpadte(event) {
    this.currentTime = this.videoElement.currentTime;
    store.dispatch(SET_MAIN_TIME_RECORDING, this.currentTime * 1000);
  }

  public setDuration(event) {
    this.duration = this.videoElement.duration;
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.videoFilePlayerContainer {
  position: relative;
  display: inline-block;
  text-align: center;
  animation-duration: 0.2s;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  @extend .shadow1;

  .playContainer {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 30px);
    width: 100%;
    animation-duration: 1s;
    text-align: center;

    .logoPlay {
      border-radius: 60px;
      padding: 8px;
      background-color: rgba(255, 255, 255, 1);
      @extend .shadow1;
      width: 60px;
      height: 60px;
      transition: transform ease-in-out 0.2s;

      &:hover {
        transform: scale(1.2);;
      }
    }
  }


  .videoElement {
    height: 100%;
  }

  .controlBar {
    animation-duration: 0.2s;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(27, 57, 84, 0.8));
    height: 100px;

    .sliderContainer {
      position: absolute;
      bottom: 16px;
      width: calc(100% - 64px);
    }
  }
}

</style>
