import { render, staticRenderFns } from "./GroupContactPanel.vue?vue&type=template&id=bb164026&scoped=true"
import script from "./GroupContactPanel.vue?vue&type=script&lang=ts"
export * from "./GroupContactPanel.vue?vue&type=script&lang=ts"
import style0 from "./GroupContactPanel.vue?vue&type=style&index=0&id=bb164026&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb164026",
  null
  
)

export default component.exports