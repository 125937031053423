import {SettingUsersKey} from '@/store/settingUsers/settingUserKey';

export const SETTINGUSERS_LIST = 'SETTINGUSERS_LIST';
export const SET_SETTINGUSER = 'SET_SETTINGUSER';
export const SETTINGUSER_CHANGED = 'SETTINGUSER_CHANGED';
export const UPLOAD_VIRTUAL_BACKGROUND = 'UPLOAD_VIRTUAL_BACKGROUND';

export class SettingUserParams {
  public key: SettingUsersKey;
  public value: string;

  constructor(key: SettingUsersKey, value: any) {
    this.key = key;
    this.value = value;
  }
}
