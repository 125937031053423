export class UploadMedia {
  public mediaId: string = '';
  public pourcent: number = 0;
  public byte: number = 0;
  public blobUrl: string = '';
  public blob: Blob = new Blob();
  public totalByte: number = 0;
  public finished: boolean = false;
}


