<template>
  <div>
    <b-dropdown ref="dropDown" class="dropDownChannelSelectionMainContainer" v-model="selectedChannels" multiple
                aria-role="list" :can-close="false"
                position="is-top-right" :max-height="300" scrollable @change="resetChannelToAdd">
      <template #trigger>
        <div class="triggerContainer">
          <w-tool-tip :label="$t('channels.addPanel.tooltip')">
            <w-icon icon="plus" size="small"></w-icon>
          </w-tool-tip>
        </div>
      </template>

      <b-dropdown-item custom paddingless class="filterInput">
        <b-taginput v-model="channelsToAdd" allow-new ellipsis rounded class="channelFilterCreatorInput"
                    :placeholder="$t('channels.addPanel.autocompletePlaceHolder')" @typing="onNewText"
                    @input="resetFilter" :before-adding="beforeAddingChannel"
        />
      </b-dropdown-item>
      <transition-group name="list-complete">
        <b-dropdown-item v-for="channel in filterChannel" :value="channel.channelID" aria-role="listitem" paddingless
                         class="channelItem list-complete-item" :key="'worksapceChannel'+channel.channelID">
          <div class="channelContainer">
            <span class="iconChannel"><w-icon :icon="isPrivate(channel)?'lock-outline':'pound'" size="small"/></span>
            {{ channel.name }}
          </div>
        </b-dropdown-item>
      </transition-group>
      <b-dropdown-item class="spacer" custom>

      </b-dropdown-item>
      <b-dropdown-item custom class="buttonBar">
        <w-button size="small" color="text" class="cancelButton" @click="cancelChannelWeet">
          {{ $t('channels.addPanel.cancel') }}
        </w-button>
        <w-button icon="check" size="small" @click="attachChannelToWeet"/>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {Weet} from '@/store/weet/weetModel';
import WInput from '@/components/wrapper/w-input.vue';
import store from '@/store';
import {Channel} from '@/store/channel/channelModel';
import {SET_CHANNELS_TO_WEET} from '@/store/myWeet/myWeetAction';
import {inform} from '@/utils/dialog';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {CREATE_CHANNEL_TO_WORKSPACE} from '@/store/channel/channelAction';
import delay from 'delay';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';

@Component({
  components: {WToolTip, WInput, WIcon, PopUpTitle, CenterCenterPanel, WButton, WError},
})
export default class ChannelWorkspaceAddDropDown extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: true})
  private autoValidate!: boolean;

  private selectedChannels: string[] = [];

  private channelsToAdd: string[] = [];

  private filterText: string = '';

  private isPrivate(channel: Channel) {
    return channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  private resetFilter() {
    delay(500).then(() => {
      this.filterText = '';
    });
  }

  private resetChannelToAdd() {
    this.channelsToAdd = [];
  }

  private onNewText(e) {
    if (e) {
      this.filterText = e;
    }
  }

  get filterChannel(): Channel[] {
    const listOfChannel = store.getters.getAllChannels;
    const listFilter = listOfChannel.filter((channel: Channel) => {
      const value = this.filterText;
      if (value) {
        return channel.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return true;
      }
    });
    return listFilter;
  }

  private mounted() {
    for (const channel of this.weet.channels) {
      this.selectedChannels.push(channel.channelID);
    }
  }

  private beforeAddingChannel(element) {
    const value = element.trim();
    // try to find a channel with the same value
    const channel = store.getters.getChannelByName(value);
    if (channel) {
      this.resetFilter();
      inform(this.$t('channels.addPanel.alreadyExist').toString());
      return false;
    }
    return true;
  }


  private cancelChannelWeet() {
    this.resetFilter();
    this.resetChannelToAdd();
    // @ts-ignore
    this.$refs.dropDown.toggle();
  }

  private async attachChannelToWeet() {
    if (this.channelsToAdd.length > 0) {
      const newChannel = await store.dispatch(CREATE_CHANNEL_TO_WORKSPACE, this.channelsToAdd) as Channel[];
      for (const channel of newChannel) {
        this.selectedChannels.push(channel.channelID);
      }
      this.channelsToAdd = [];
    }
    if (!this.autoValidate) {
      this.$emit('select', this.selectedChannels);
      this.close();
    } else {
      store.dispatch(SET_CHANNELS_TO_WEET, {
        weetID: this.weet.weetID,
        channelIDs: this.selectedChannels
      }).then(() => {
        inform(this.$t('channels.addPanel.success').toString());
        this.$emit('update');
        this.close();
      });
    }
  }

  private close() {
    // @ts-ignore
    this.$refs.dropDown.toggle();
  }
}
</script>


<style lang="scss">

.dropDownChannelSelectionMainContainer {

  .dropdown-menu {
    min-width: 320px;
    max-width: 600px;
    width: 100%;

    .dropdown-content {
      min-height: 300px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
    }
  }

  .channelFilterCreatorInput {
    .channel {
      background: var(--light);
      position: relative;

      &::before {
        content: "New";
        font-size: 10px;
        position: absolute;
        color: white;
        width: 28px;
        text-align: center;
        height: 14px;
        border-radius: 4px;
        background: var(--success);
        left: -4px;
        top: -5px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";


.triggerContainer {
  color: var(--light1);
  cursor: pointer;

  &:hover {
    color: var(--light2);
  }
}

.filterInput {
  margin-top: 16px;
  margin-bottom: 16px;
}

.channelItem {
  width: inherit;
  display: inline-block;
  background: var(--light);
  border-radius: 16px;
  color: var(--light2);
  margin: 2px;
  transition: all 0.5s ease-out;

  .channelContainer {

    padding-left: 4px;
    padding-right: 8px;

    .iconChannel {

    }
  }
}

.spacer {
  min-height: 60px;
}

.buttonBar {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1));
  width: 100%;
  border-radius: 8px;
  height: 48px;
  top: calc(300px - 60px);
  padding-right: 16px;
  padding-bottom: 16px;
  left: 0px;

  .cancelButton {
    margin-right: 8px;
  }
}

</style>
