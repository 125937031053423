<template>
  <transition name="fade">
    <div v-show="mustShow" v-if="!isMobile" data-test="commentScreenPlayer" ref="commentScreenPlayer"
         class="commentScreenPlayerContainer"
    >
      <!-- MAIN VIDEO PLAYER -->
      <div class="mainVideoContainer">
        <video class="videoElement" ref="mainVideo" :src="mediaUrl" @ended="onEndedVideo"
               @canplay="onCanPlay()" @timeupdate="onTimeUpdate()" :poster="thumbnailUrl" preload="metadata">
        </video>
      </div>

      <!-- CONTROL BAR -->
      <div class="controlBar" v-if="!noControl">
        <!-- play / pause -->
        <div class="playContainer" @click="onClickPlay">
          <w-icon data-test="weetPauseButton" icon="pause" class="logoPlay" v-show="play" color="white"/>
          <w-icon data-test="weetPlayButton" icon="play" class="logoPlay" v-show="!play" color="white"/>
        </div>

        <!-- volume player -->
        <div class="volumeContainer" @mouseover="changeVolumeHover=true" @mouseout="changeVolumeHover=false">
          <div @click="muteUnmuteVolume">
            <w-icon :icon="volumeIcon" class="volumeIcon"/>
          </div>
          <b-slider class="volumeSlider" :tooltip="false" v-show="changeVolumeHover" :type="'is-primary'"
                    :max="100" @input="changeVolume" :value="volume" bigger-slider-focus
                    rounded size="is-small"/>
        </div>
        <!-- timer -->
        <div class="timeContainer" data-test="weetPlayerTimer" v-if="duration>0" v-show="!changeVolumeHover">
          {{ humanTimer }} / {{ humanDuration }}
        </div>

        <!-- slider -->
        <div class="sliderContainer" data-test="weetPlayerTimeline">
          <b-slider :custom-formatter="val => getHumanTime(val*1000)" v-if="duration>0"
                    :max="duration" type="is-primary"
                    rounded size="is-small" @change="changeCurrentTime" :value="timer">
          </b-slider>
        </div>
        <!-- speed container -->
        <div class="speedContainer" @click="switchSpeedMode">
          <div class="speed">{{ speedMode }}</div>
        </div>

        <!-- fullscreen -->
        <div class="fullscreenContainer" @click="toggleFullscreen">
          <w-icon v-if="!isFullScreen" icon="fullscreen"/>
          <w-icon v-else icon="fullscreen-exit"/>
        </div>

      </div>
    </div>
    <div v-else>
      <video controls :src="mediaUrl" :poster="thumbnailUrl"></video>
    </div>
  </transition>

</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {getThumbnailURL, getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {CommentEvent} from '@/store/comment/commentModel';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {log} from '@/utils/log';
import prettyMilliseconds from 'pretty-ms';
import mobileDetect from 'mobile-device-detect';
import store from '@/store';
import {CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME} from '@/store/persistedInformation/persistAction';


@Component({
  components: {WIcon, WButton, CenterCenterPanel},
  computed: {},
})
export default class CommentScreenPlayer extends Vue {

  @Prop()
  private comment!: CommentEvent;
  @Prop({default: false})
  private noControl!: boolean;

  private mustShow: boolean = false;
  private play: boolean = false;
  private timer: number = 0;
  private duration: number = 0;
  private isFullScreen: boolean = false;
  private changeVolumeHover: boolean = false;
  private showSubtitle: boolean = false;
  private isMobile: boolean = mobileDetect.isMobile;

  public mounted() {
    this.mustShow = true;
    document.addEventListener('fullscreenchange', this.onScreenFullScreenChange);
  }

  private getUrlUser(): string {
    return getUserAvatar(this.comment.media?.creator, 48);
  }

  get mediaUrl(): string {
    if (this.comment !== null && this.comment.media !== null) {
      return getVideoURLForStatus(this.comment.media);
    }
    return '';
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }


  get thumbnailUrl(): string {
    if (this.comment !== null && this.comment.media !== null) {
      return getThumbnailURL(this.comment.media.jobID);
    }
    return '';
  }

  private onTimeUpdate(val) {
    if (this.mainVideo()) {
      this.timer = this.mainVideo().currentTime;
    }
  }


  private onEndedVideo() {
    setActionEvent(ActionEvent.comment_player_screen_close);
    this.$emit('ended');
  }

  private mainVideo(): HTMLVideoElement {
    return this.$refs.mainVideo as HTMLVideoElement;
  }

  private onCanPlay() {
    if (!this.mainVideo()) {
      return;
    }
    this.duration = this.mainVideo().duration;
    if (this.mainVideo()) {
      this.mainVideo().playbackRate = this.speedMode;
    }
  }

  get humanTimer(): string {
    return this.getHumanTime(this.timer * 1000);
  }

  get humanDuration(): string {
    return this.getHumanTime(this.duration * 1000);
  }

  get volume(): number {
    return store.getters.volume;
  }

  private onClickPlay() {
    if (!this.play) {
      this.mainVideo().play().catch((e) => {
        log.error(e.message);
      });
      // TODO map amplitude
      this.play = true;
    } else {
      this.mainVideo().pause();
      this.play = false;
      // TODO map amplitude
    }
  }

  private getHumanTime(val: number): string {
    if (val < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }

  private changeCurrentTime(val) {
    if (this.mainVideo()) {
      this.mainVideo().currentTime = val;
    }
    // TODO amplitude
  }

  get volumeIcon(): string {
    if (this.volume >= 75) {
      return 'volume-high';
    } else if (this.volume >= 40) {
      return 'volume-medium';
    } else if (this.volume < 40 && this.volume > 0) {
      return 'volume-low';
    }
    return 'volume-off';
  }


  private muteUnmuteVolume() {
    if (this.volume <= 0) {
      this.mainVideo().volume = 1;
      store.dispatch(CHANGE_PLAYER_VOLUME, 100);
    } else {
      this.mainVideo().volume = 0;
      store.dispatch(CHANGE_PLAYER_VOLUME, 0);
    }
  }

  private changeVolume(val) {
    this.mainVideo().volume = val / 100;
    store.dispatch(CHANGE_PLAYER_VOLUME, val);
  }

  private switchSpeedMode() {
    if (this.mainVideo()) {
      const speed = this.speedMode;
      const speedTab: number[] = [0.8, 1, 1.2, 1.5, 2];
      let newSpeed = speedTab[0];
      for (const speedAvailable of speedTab) {
        if (speedAvailable > speed) {
          newSpeed = speedAvailable;
          break;
        }
      }
      if (this.mainVideo()) {
        this.mainVideo().playbackRate = newSpeed;
      }
      store.dispatch(CHANGE_PLAYER_SPEED, newSpeed);
      // TODO amplitude
    }
  }

  private toggleFullscreen() {
    // TODO amplitude
    const element = this.$refs.commentScreenPlayer as HTMLElement;
    if (!this.isFullScreen) {
      element.requestFullscreen();
    } else {
      document.exitFullscreen();
    }


  }

  private onScreenFullScreenChange(event) {
    if (event.target === this.$refs.commentScreenPlayer) {
      this.isFullScreen = !this.isFullScreen;
    }
  }

  private beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onScreenFullScreenChange);
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/gridBreakPoint.scss';


.commentScreenPlayerContainer {
  height: 100%;
  width: 100%;
  min-width: 280px;
  position: relative;
  background: var(--black);

  // MAIN VIDEO
  .mainVideoContainer {
    position: relative;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    .progress {
      width: calc(100% - 256px);
      position: absolute;
      margin-left: 128px;
      margin-right: 128px;
    }

    .videoElement {
      width: 100%;
      height: 100%;
      animation-duration: 2s;
      grid-row-start: 1;
      grid-column-start: 1;
      position: absolute;
    }
  }


  // CONTROL BAR
  .controlBar {
    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
    width: 100%;
    bottom: 0px;
    position: absolute;
    padding-top: 64px;
    min-height: 100px;
    display: flex;
    // ICON play
    .playContainer {
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      margin-left: 8px;
      margin-right: 8px;

      .logoPlay {
        transition: transform ease-in-out 0.1s;

        &:hover {
          transform: scale(1.2);;
        }
      }
    }

    // Volume
    .volumeContainer {
      display: flex;
      cursor: pointer;
      color: white;
      font-size: 12px;
      align-items: center;
      margin-right: 0px;
      margin-top: 4px;

      .volumeIcon {
        margin-right: 8px;
      }

      .volumeSlider {
        width: 64px;
        margin-right: 12px;
        padding-top: 16px;
        // margin-top: 0px;
      }
    }

    // time container
    .timeContainer {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #FFF;
      margin-right: 8px;
    }

    // slider
    .sliderContainer {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 8px;
      margin-right: 8px;
    }

    // speed
    .speedContainer {
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: var(--dark);
      font-size: 10px;
      margin-left: 8px;
      font-weight: 900;
      transition: transform ease-in-out 0.1s;
      line-height: 16px;
      margin-right: 4px;
      margin-top: 2px;

      &:hover {
        transform: scale(1.2);;
      }

      .speed {
        border-radius: 2px;
        width: 20px;
        height: 16px;
        background: var(--white);
      }
    }

    // fullscreen
    .fullscreenContainer {
      display: flex;
      align-items: center;
      margin-right: 12px;
      cursor: pointer;
      color: #FFFFFF;

      &:hover {
        transform: scale(1.2);;
      }
    }
  }


}

</style>
