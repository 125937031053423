<template>
  <CenterCenter>
    <div class="main">
      <b-tabs class="tabContent" v-model="activeTab">
        <!-- TAB CAMERA -->
        <b-tab-item class="tabItem" :label="'Input / output'">
          <div class="lineInput">
            <w-select icon="microphone" :label="$t('settings.panel.audio')" :value="microphoneSelected"
                      @input="selectMicrophone">
              <option :value="audio.deviceId" v-for="audio of listOfDeviceAudio"
                      :key="'audio'+audio.deviceId">{{ audio.label }}
              </option>
            </w-select>
          </div>
          <div class="lineInput">
            <w-select icon="video" :label="$t('settings.panel.video')" :value="cameraSelected" @input="selectCamera">
              <option :value="video.deviceId" v-for="video of listOfDeviceVideo"
                      :key="'videoDevice'+video.deviceId">{{ video.label }}
              </option>
            </w-select>
          </div>

          <div class="lineInput">
            <w-select icon="cogs" :label="$t('settings.panel.captureQuality')" :value="resolutionSelected"
                      @input="chooseResolution">
              <option :value="res.value" v-for="(res,index) in listOfResolution"
                      :key="'resolution'+index"> {{ res.label }}
              </option>
            </w-select>
          </div>
          <div class="lineInput">
            <w-select icon="cogs" :label="$t('settings.panel.captureFps')" :value="fpsSelected"
                      @input="chooseFps">
              <option :value="fps.value" v-for="(fps,index) in listOfFps"
                      :key="'fps'+index"> {{ fps.label }}
              </option>
            </w-select>
          </div>
          <div class="lineInputNoiseCancelling">
            <div class="labelNoiseCancelling">
              {{ $t('settings.panel.removeNoise') }}
            </div>
            <div class="switchNoiseCancelling">
              <w-switch :value="getNoiseCancelationLvl>0" @input="setNoiseCancelelation" size="small"/>
            </div>
          </div>
        </b-tab-item>
        <!-- TAB SECTION HELPER -->
        <b-tab-item class="tabItem" :label="$t('settings.panel.titleSectionTime')">
          <div class="switchLine">
            <div class="labelSwitch">{{ $t('settings.panel.sectionHelperSound') }}</div>
            <div>
              <w-switch size="small" @input="setSectionSound"
                        :value="isSectionSound"
              />
            </div>
          </div>
          <div class="switchLine">
            <div class="labelSwitch">{{ $t('settings.panel.sectionHelperNotification') }}</div>
            <div>
              <w-switch size="small" @input="setSectionNotification"
                        :value="isSectionNotification"
              />
            </div>
          </div>

        </b-tab-item>

        <div class="validateContainer">
          <w-button color="primary" size="small" @click="closePopUp()">{{
              $t('settings.panel.close')
            }}
          </w-button>
        </div>
      </b-tabs>
    </div>
  </CenterCenter>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import store from '@/store';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WSelect from '@/components/wrapper/w-select.vue';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import StickerSetupSource from '@/components/recorder/sticker/StickerSetupSource.vue';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SETUP_FAVORITE_FILTER, SETUP_FAVORITE_MICROPHONE, SETUP_FAVORITE_WEBCAM} from '@/store/setup/setupAction';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {SettingUsersKey} from '@/store/settingUsers/settingUserKey';
import {SET_SETTINGUSER, SettingUserParams} from '@/store/settingUsers/settingUserAction';
import WSwitch from '@/components/wrapper/w-switch.vue';
import {showNotification} from '@/utils/notification';
import prettyMilliseconds from 'pretty-ms';
import {alertMessageWithConfirmation, closeWModal} from '@/utils/dialog';
import WHelp from '@/components/wrapper/w-help.vue';

@Component({
  components: {WHelp, WSwitch, WButton, StickerSetupSource, VideoSource, WSelect, PopUpTitle, CenterCenter},
})
export default class RecordSettingPanel extends Vue {
  private listOfDeviceAudio: InputDeviceInfo[] = [];
  private listOfDeviceVideo: InputDeviceInfo[] = [];
  private activeTab: number = 0;

  public mounted() {
    this.getDevices();
  }


  public closePopUp() {
    closeWModal(this);
  }

  public async getDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    for (const device of devices) {
      if (device.kind === 'audioinput') {
        this.listOfDeviceAudio.push(device as InputDeviceInfo);
      }
      if (device.kind === 'videoinput') {
        this.listOfDeviceVideo.push(device as InputDeviceInfo);
      }
    }
  }


  public selectMicrophone(deviceID: string) {
    store.dispatch(SETUP_FAVORITE_MICROPHONE, deviceID);
    setActionEvent(ActionEvent.setting_microphone_change);
  }

  public selectCamera(deviceID: string) {
    store.dispatch(SETUP_FAVORITE_WEBCAM, deviceID);
    setActionEvent(ActionEvent.setting_webcam_change);
  }

  get listOfResolution() {
    return [{value: 720, label: this.$t('resolution.720')},
      {value: 1080, label: this.$t('resolution.1080')},
      {value: 1440, label: this.$t('resolution.1440')},
      {value: 2160, label: this.$t('resolution.2160')}];
  }

  get listOfFps() {
    return [{value: 25, label: this.$t('fps.25')},
      {value: 30, label: this.$t('fps.30')},
      {value: 60, label: this.$t('fps.60')}];
  }


  get resolutionSelected() {
    return store.getters.getResolutionCapture;
  }

  get fpsSelected() {
    return store.getters.getFpsCapture;
  }

  public chooseResolution(value) {
    const params = new SettingUserParams(SettingUsersKey.CAPTURE_RESOLUTION, value);
    store.dispatch(SET_SETTINGUSER, params).then(() => {
      setActionEvent(ActionEvent.setting_capture_quality,
          {capture_quality: value});
    });
  }

  public chooseFps(value) {
    const params = new SettingUserParams(SettingUsersKey.CAPTURE_FPS, value);
    store.dispatch(SET_SETTINGUSER, params).then(() => {
      setActionEvent(ActionEvent.setting_capture_quality,
          {capture_quality: value});
    });
  }

  get listOfTime() {
    const listOFTime: any = [];
    // so we find the defaultTime
    const sectionMax = store.getters.getWeetSectionMaxLimit;
    const sectinonDefault = store.getters.getWeetSectionDefaultLimit;
    // so we push the 1 minutes time
    listOFTime.push({
      value: sectinonDefault,
      label: prettyMilliseconds(sectinonDefault * 1000, {verbose: true})
    });
    listOFTime.push({
      value: 300,
      label: prettyMilliseconds(300 * 1000, {verbose: true})
    });
    listOFTime.push({
      value: sectionMax,
      label: prettyMilliseconds(sectionMax * 1000, {verbose: true})
    });
    return listOFTime;
  }


  get getNoiseCancelationLvl() {
    return store.getters.getNoiseCancelationLvl;
  }

  get isSectionSound(): boolean {
    return store.getters.isTimeSectionSoundActivated;
  }

  get isSectionNotification(): boolean {
    return store.getters.isTimeSectionNotificationActivated;
  }


  public setSectionSound(value: boolean) {
    const params = new SettingUserParams(SettingUsersKey.TIME_SECTION_SOUND, value);
    store.dispatch(SET_SETTINGUSER, params).then(() => {
      setActionEvent(ActionEvent.setting_time_section_sound,
          {value_section_sound: value});
    });
  }

  public setNoiseCancelelation(value: boolean) {

    let valueToSet = 0;
    if (value) {
      valueToSet = 1;
    }
    const params = new SettingUserParams(SettingUsersKey.NOISE_CANCELATION, valueToSet);
    store.dispatch(SET_SETTINGUSER, params).then(() => {
      setActionEvent(ActionEvent.setting_noise_cancelation,
          {value_noise_cancelation: valueToSet});
    });
  }

  public setSectionNotification(value: boolean) {
    const params = new SettingUserParams(SettingUsersKey.TIME_SECTION_NOTIFICATION, value);
    if (value) {
      showNotification(
          this.$t('sectionHelper.nativeNotification.title').toString(),
          this.$t('sectionHelper.nativeNotification.description').toString()
      );
    }
    store.dispatch(SET_SETTINGUSER, params).then(() => {
      setActionEvent(ActionEvent.setting_time_section_notification,
          {value_section_notification: value});
    });
  }

  get cameraSelected() {
    if (store.getters.getFavoriteCamera === '' && this.listOfDeviceVideo.length > 0) {
      return this.listOfDeviceVideo[0].deviceId;
    }
    return store.getters.getFavoriteCamera;
  }

  get microphoneSelected() {
    if (store.getters.getFavoriteMicro === '' && this.listOfDeviceVideo.length > 0) {

      return this.listOfDeviceAudio[0].deviceId;
    }
    return store.getters.getFavoriteMicro;
  }


}
</script>

<style scoped lang="scss">

.main {
  padding: 24px;
  padding-top: 16px;
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background: #FFF;
  color: #1B3954;
  border-radius: 8px;
  margin: auto;
  min-height: 400px;

  .tabContent {
    height: 100%;

    .tabItem {
      min-height: 380px;
    }
  }


  .validateContainer {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .lineInput {
    margin-top: 24px;
  }

  .lineInputNoiseCancelling{
    display: flex;
    margin-top: 24px;
    .labelNoiseCancelling{
      display: flex;
      flex: 1;
      font-size: 16px;
      color: var(--black);
    }
    .switchNoiseCancelling{
      display: flex;
      margin-right: -8px;
    }
  }

  .lineInputMiddle {
    margin-top: 24px;
    margin-left: 30%;
  }

  .switchLine {
    display: flex;
    margin-top: 24px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .labelSwitch {
      text-align: left;
      color: var(--black);
      font-size: 14px;
      font-weight: 600;
      margin-right: 16px;
      flex: 1;
    }

    .selectValueSwitch {
      min-width: 150px;
      padding-right: 16px;
    }

    margin-bottom: 8px;
  }

  .helpNoiseCancelation {
    margin-top: -1px;
    margin-left: -6px;
  }
}

</style>
