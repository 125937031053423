<template>
  <div>
    <b-dropdown aria-role="list" :position="position">
      <template #trigger="{ active }">
        <w-button size="small" color="outlined" icon="plus"  >
          {{ $t('createWeetComponent.advancedEditing.annotationMode.add') }}
        </w-button>
      </template>
      <b-dropdown-item aria-role="listitem" class="itemSelection" @click="createAnnotation('TEXT')">
        <w-icon icon="text" class="iconItem" /> {{ $t('createWeetComponent.advancedEditing.annotationMode.type.text') }}
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" class="itemSelection" @click="createAnnotation('IMAGE')">
        <w-icon icon="image-outline" class="iconItem"/> {{ $t('createWeetComponent.advancedEditing.annotationMode.type.image') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from "@/components/wrapper/w-icon.vue";
import {
  Annotation,
  AnnotationPosition,
  AnnotationTheme,
  AnnotationType
} from "@/store/annotation/annotationModel";
import {CREATE_ANNOTATION, SELECT_ANNOTATION} from "@/store/annotation/annotationAction";
import {Weet} from "@/store/weet/weetModel";

@Component({
  components: {WIcon, WButton},
})
export default class CreateAnnotationButton extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default:'is-top-right'})
  private position!:string;

  private annotationDefaultDurationTime = 5000;
  private annotationDefaultIcon = 'information-slab-circle-outline';
  get timePlayer(): number {
    return store.getters.getTimerPlayer
  }
  private async createAnnotation(type:AnnotationType) {
    const annotation = new Annotation();
    annotation.type = type;
    annotation.textContent = "";
    if(this.timePlayer<0){
      annotation.time = 0;
    }else{
      annotation.time=this.timePlayer;
    }
    annotation.duration = this.annotationDefaultDurationTime;
    annotation.positionType = AnnotationPosition.TOP_RIGHT;
    annotation.theme = AnnotationTheme.DEFAULT;
    annotation.iconMD = this.annotationDefaultIcon;
    annotation.profil = false;
    annotation.interactionUrl = "";
    annotation.user = null;
    annotation.sound = false;
    var annotationCreated=await store.dispatch(CREATE_ANNOTATION, {weetID: this.weet.weetID, annotation: annotation});
    store.dispatch(SELECT_ANNOTATION,annotationCreated);
  }
}
</script>

<style scoped lang="scss">
.itemSelection{
  display: flex;
  align-items: center;
  .iconItem{
    margin-right: 8px;
  }
}
</style>
