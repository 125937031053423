import {OffsetTimeEventUpdate, TimeEvent} from '@/store/timeLine/timeEvent';

export const REINIT_MY_WEET = 'REINIT_MY_WEET';
export const MY_LIST_WEET = 'MY_LIST_WEET';
export const MY_WEET_INBOX = 'MY_WEET_INBOX';
export const REINIT_GRID_MY_WEET = 'REINIT_GRID_MY_WEET';
export const SEARCH_MY_WEET = 'SEARCH_MY_WEET';
export const DELETE_MY_WEET = 'DELETE_MY_WEET';
export const CREATE_MY_WEET = 'CREATE_MY_WEET';
export const DUPLICATE_MY_WEET = 'DUPLICATE_MY_WEET';
export const REFRESH_MY_WEET = 'REFRESH_MY_WEET';
export const ADD_WEET_TO_WORKSPACE = 'ADD_WEET_TO_WORKSPACE';
export const SET_CHANNELS_TO_WEET = 'SET_CHANNELS_TO_WEET';
// GET THE WEET WITH UNBLURED EMAILS IF USER IS AN EDITOR
export const REFRESH_WEET_UNBLURED = 'REFRESH_WEET_UNBLURED';
export const ATTACHED_MEDIA_TO_WEET = 'ATTACHED_MEDIA_TO_WEET';
export const DETACHED_MEDIA_TO_WEET = 'DETACHED_MEDIA_TO_WEET';
export const ATTACHED_TIMELINE_TO_WEET = 'ATTACHED_TIMELINE_TO_WEET';
export const CUT_TIMELINE_WEET = 'CUT_TIMELINE_WEET';
export const NOTIFY_WEET_UPDATE = 'NOTIFY_WEET_UPDATE';
export const LOAD_WEET_FOR_EDITING = 'LOAD_WEET_FOR_EDITING';
export const REFRESH_WEET_FOR_EDITING = 'REFRESH_WEET_FOR_EDITING';
export const SEND_CHANGE_WEET_TITLE = 'SEND_CHANGE_WEET_TITLE';
export const SEND_COMMENT_POLICY_WEET = 'SEND_COMMENT_POLICY_WEET';
export const SEND_EMOTE_POLICY_WEET = 'SEND_EMOTE_POLICY_WEET';
export const SEND_VISIBILITY_POLICY_WEET = 'SEND_VISIBILITY_POLICY_WEET';
export const COUNT_MY_OWN_WEETS = 'COUNT_MY_OWN_WEETS';
export const UPDATE_MY_WEET_IMAGE = 'UPDATE_MY_WEET_IMAGE';
export const MIGRATE_MY_PS = 'MIGRATE_MY_PS';

// UPDATE ANNOTATION
export const UPDATE_ANNOTATION_ON_WEET = 'UPDATE_ANNOTATION_ON_WEET'
export const DELETE_ANNOTATION_ON_WEET ='DELETE_ANNOTATION_ON_WEET'

export class AttachedMediaParams {
    public weetID: string;
    public mediaID: string;

    constructor(weetID: string, mediaID: string) {
        this.weetID = weetID;
        this.mediaID = mediaID;
    }
}

export class DettachedMediaParams {
    public weetID: string;
    public mediaID: string;

    constructor(weetID: string, mediaID: string) {
        this.weetID = weetID;
        this.mediaID = mediaID;
    }
}

export class AttachedTimeLineParams {
    public weetID: string;
    public timeLineEvent: TimeEvent[];
    public offsetTime: OffsetTimeEventUpdate[];

    constructor(weetID: string, timeLineEvent: TimeEvent[], offsetTimeEventUpdate: OffsetTimeEventUpdate[]) {
        this.weetID = weetID;
        this.timeLineEvent = timeLineEvent;
        this.offsetTime = offsetTimeEventUpdate;
    }
}

export class CutTimeLineParams {
    public weetID: string;
    public timeLineEvent: TimeEvent[];

    constructor(weetID: string, timeLineEvent: TimeEvent[]) {
        this.weetID = weetID;
        this.timeLineEvent = timeLineEvent;
    }
}

export class NotifyUpdateParams {
    public weetID: string;
    public times: number[];

    constructor(weetID: string, times: number[]) {
        this.weetID = weetID;
        this.times = times;
    }
}

export class MyWeetListParams {
    public page: number = 0;
    public titleFilter: string = '';
    public membersFilter: string[] = [];
    public groupFilter: string = '';
    public tagFilter: string = '';
    public userFilter: string = '';
    public workspaceFilter: string | null = null;
    public channelFilter: string = '';

    constructor(page: number = 0) {
        this.page = page;
        this.tagFilter = '';
        this.channelFilter = '';
        this.membersFilter = [];
        this.groupFilter = '';
        this.titleFilter = '';
        this.userFilter = '';
        this.workspaceFilter = '';
    }
}


export enum StaticTag {
    ALL = 'ALL',
    LAST_VIEWED = 'LAST_VIEWED',
    SHARED_WITH_ME = 'SHARED_WITH_ME',
    DRAFT = 'DRAFT',
    CREATED_BY_ME = 'CREATED_BY_ME',
}
