// ADD TO TIMELINE
import {Media} from '@/store/media/mediaModel';

export const TIMER_RECORD = 'TIMER_RECORD';
export const TIMER_PLAYER = 'TIMER_PLAYER';
export const SEEK_PLAYER = 'SEEK_PLAYER';

export const ADD_TIME_EVENT = 'ADD_TIME_EVENT';
export const ADD_TIME_LINE = 'ADD_TIME_LINE';
export const UPDATE_TIME_TIME_EVENT = 'UPDATE_TIME_TIME_EVENT';
export const ADD_TIMEOFFSET_COMMENT = 'ADD_TIMEOFFSET_COMMENT';
export const DELETE_TIME_EVENT = 'DELETE_TIME_EVENT';

export const REMOVE_SECTION_EVENT = 'REMOVE_SECTION_EVENT';
export const REMOVE_LAST_SECTION_EVENT = 'REMOVE_LAST_SECTION_EVENT';
export const MOVE_SECTION_EVENT = 'MOVE_SECTION_EVENT';
export const SET_TIMER_RECORD_TO_END = 'SET_TIMER_RECORD_TO_END';
export const DETTACHED_OBSOLETE_MEDIA = 'DETTACHED_OBSOLETE_MEDIA';
export const SAVE_TIMELINE_IF_NEED = 'SAVE_TIMELINE_IF_NEED';
export const SET_NEW_TIMELINE = 'SET_NEW_TIMELINE';

export const PLAYER_TIMELINE_CURRENT_EVENT = 'PLAYER_TIMELINE_CURRENT_EVENT';
export const PLAYER_TIMELINE_NEXT_EVENT = 'PLAYER_TIMELINE_NEXT_EVENT';
export const PLAYER_TITLE = 'PLAYER_TITLE';
export const PLAYER_PLAYING = 'PLAYER_PLAYING';
export const TIMELINE_UPDATE_MEDIAID = 'TIMELINE_UPDATE_MEDIAID';
export const CLEAR_TIME_LINE = 'CLEAR_TIME_LINE';
export const MAIN_MEDIA_BUFFERING = 'MAIN_MEDIA_BUFFERING';
export const SECOND_MEDIA_BUFFERING = 'SECOND_MEDIA_BUFFERING';
export const SWITCH_PLAYER_FULLSCREEN = 'SWITCH_PLAYER_FULLSCREEN';
export const SWITCH_MINIMISE_STIKERS = 'SWITCH_MINIMISE_STIKERS';
export const TIMELINE_SAVED = 'TIMELINE_SAVED';
export const TIMELINE_UNSAVED = 'TIMELINE_UNSAVED';
export const PLAYER_SUBTITLE = 'PLAYER_SUBTITLE';
export const CLEAR_SUBTITLE = 'CLEAR_SUBTITLE';

export class MediaIDUpdateParams {
  public oldID: string;
  public media: Media;

  constructor(oldID: string, media: Media) {
    this.oldID = oldID;
    this.media = media;
  }
}

