import {Weet} from '@/store/weet/weetModel';
import {getImageURLForSize, getWeetURL} from '@/utils/util';


export class ThumbnailCopy {


    public async copyThumnbailWeet(weet: Weet) {
        if (!weet.thumbnail) {
            return;
        }

        const blobImage = await this.getBlobThumbnail(weet);
        // @ts-ignore
        const data = [new ClipboardItem({[blobImage.type]: blobImage})];
        // @ts-ignore
        await navigator.clipboard.write(data);
    }

    public async copyThumnbailWithLinkWeet(weet: Weet, textImage: string) {
        if (!weet.thumbnail) {
            return;
        }
        const html = `<a href="${getWeetURL(weet)}">
              <img src="${getImageURLForSize(weet.thumbnail, 720)}" width="576"/>
              <br/>${textImage}</a>`;
        const blobInput = new Blob([html], {type: 'text/html'});
        // @ts-ignore
        const clipboardItemInput = new ClipboardItem({'text/html' : blobInput});
        // @ts-ignore
        await navigator.clipboard.write([clipboardItemInput]);
    }

    private getBlobThumbnail(weet: Weet): Promise<Blob> {
        if (weet.thumbnail) {
            const image = document.createElement('img') as HTMLImageElement;
            image.crossOrigin = 'anonymous';
            image.src = getImageURLForSize(weet.thumbnail, 720);
            return new Promise((resolve, reject) => {
                image.onload = async () => {
                    const canvas = new OffscreenCanvas(image.width, image.height);
                    const ctx = canvas.getContext('2d');
                    if (!ctx) {
                        return;
                    }
                    ctx.drawImage(image, 0, 0, image.width, image.height);
                    // now we export the image to put on clipboard
                    const blobImage = await canvas.convertToBlob({type: 'image/png', quality: 0.9});
                    // @ts-ignore
                    resolve(blobImage);
                };
            });
        } else {
            throw new Error('Impossible to load thumbnail');
        }
    }

}
