import {mustBeGenerateFrame} from '@/utils/video/canvasUtils';
import {
    computeSegementForFaceTrack, destroyOffscreenCanvasForFaceCompute,
    ensureBlazeModelIsLoad, setUpOffscreenCanvasForFaceCompute,
} from '@/worker/BlazeCompute';
import {NormalizedFace} from '@tensorflow-models/blazeface/dist/face';

const settings = {};


export const setUpOffscreenCanvasForFaceTrackFrame = async (off: OffscreenCanvas,
                                                            id: string,
) => {
    settings[id] = new FaceTrackWorkerSetting(off, id);
    await ensureBlazeModelIsLoad();
    await setUpOffscreenCanvasForFaceCompute(id, off.width, off.height);
};

export const isFaceTrackInitialised = async (id: string): Promise<boolean> => {
    const setting = settings[id];
    return setting && setting.lastSegmentation != null;
};

export const computeFaceTrackCrop = async (id: string, imageBitmap: ImageBitmap): Promise<ImageBitmap> => {
    if (!settings[id]) {
        return imageBitmap;
    }
    const setting = settings[id];
    const canvas = settings[id].canvasSource;

    // compute position
    setting.computeCropFromSegmentation(imageBitmap);
    const bitmapCrop = await createImageBitmap(imageBitmap,
        setting.currentX, setting.currentY, setting.currentW, setting.currentH, {
            resizeHeight: canvas.height,
            resizeWidth: canvas.width,
            resizeQuality: 'low'
        });


    if (mustBeGenerateFrame(settings[id].getQuality(), id)) {
        const dateStart = new Date().getTime();
        computeSegementForFaceTrack(imageBitmap, id).then((segmentation) => {
            if (settings[id]) {
                if (segmentation.length > 0) {
                    settings[id].lastSegmentation = segmentation[0];
                }
                const timeGeneration = new Date().getTime() - dateStart;
                settings[id].computeNewQuality(timeGeneration);
                // console.log(timeGeneration);
            }
        });
    }


    return await bitmapCrop;
};


export const destroyOffscreenCanvasForFaceTrack = (id: string) => {
    delete settings[id];
    destroyOffscreenCanvasForFaceCompute(id);
};


export class FaceTrackWorkerSetting {
    public canvasSource: OffscreenCanvas;
    public contextSource: OffscreenCanvasRenderingContext2D;
    public quality: number = -1; // -1 =auto
    public maxQuality: number = 8;
    public minQuality: number = 0.5;
    public lastSegmentation: NormalizedFace | null = null;
    public id: string;
    public currentX: number = -1;
    public currentY: number = -1;
    public currentW: number = -1;
    public currentH: number = -1;
    // the quality change with autoRatio / divide by time in ms
    private autoRatio = 250;

    private xGoal: number = -1;
    private yGoal: number = -1;
    private wGoal: number = -1;
    private hGoal: number = -1;
    private MOVE_SPEED: number = 0.05;
    private ANCHOR_STEP: number = 30;
    private OFFSET_Y_HEAD: number = -50;
    private ZOOM_OUT: number = 130;

    constructor(canvasSource: OffscreenCanvas, id: string) {
        this.canvasSource = canvasSource;
        this.contextSource = this.createContext(canvasSource);
        this.id = id;
    }

    public computeNewQuality(frameTimeGeneration: number) {
        const fps = this.autoRatio / frameTimeGeneration;
        if (fps > this.maxQuality) {
            this.quality = this.maxQuality;
        } else if (fps < this.minQuality) {
            this.quality = this.minQuality;
        } else {
            this.quality = fps;
        }

    }

    public getQuality() {
        if (this.quality === -1) {
            return this.minQuality;
        } else {
            if (this.quality > this.maxQuality) {
                this.quality = this.maxQuality;
            }
            return this.quality;
        }
    }

    public computeCropFromSegmentation(input: ImageBitmap) {
        if (!this.lastSegmentation) {
            const scale = input.height / this.canvasSource.height;
            this.wGoal = Math.floor(this.canvasSource.width * scale);
            this.hGoal = Math.floor(this.canvasSource.width * scale);
            this.xGoal = Math.floor((input.width - this.currentW) / 2);
            this.yGoal = 0;
            this.currentH = this.hGoal;
            this.currentY = this.yGoal;
            this.currentX = this.xGoal;
            this.currentW = this.wGoal;
        } else {
            if (this.lastSegmentation.probability && this.lastSegmentation.probability < 0.7) {
                return;
            }
            const ratioOut = this.canvasSource.height / this.canvasSource.width;
            const zoom = this.ZOOM_OUT;
            this.xGoal = Math.floor(this.lastSegmentation.topLeft[0] - zoom);
            this.yGoal = Math.floor(this.lastSegmentation.topLeft[1] - zoom + this.OFFSET_Y_HEAD);
            this.wGoal = Math.floor(this.lastSegmentation.bottomRight[0] - this.lastSegmentation.topLeft[0] + (zoom * 2));
            this.hGoal = Math.floor(this.wGoal * ratioOut);
            // control overlayout for Y
            if ((this.yGoal + this.hGoal) > input.height) {
                this.yGoal = input.height - this.hGoal;
            }
            if (this.yGoal < 0) {
                this.yGoal = 0;
            }
            // control overlayout for X
            if ((this.xGoal + this.wGoal) > input.width) {
                this.xGoal = input.width - this.wGoal;
            }
            if (this.xGoal < 0) {
                this.xGoal = 0;
            }
            const offsetSpeed = (goal: number, current: number) => {
                const distance = goal - current;
                return Math.floor(current + (distance * this.MOVE_SPEED));
            };
            const mustMove = (goal: number, current: number) => {
                if (goal > current + this.ANCHOR_STEP || goal < current - this.ANCHOR_STEP) {
                    return true;
                } else {
                    return false;
                }
            };

            // verifie if must move on X
            if (mustMove(this.xGoal, this.currentX)) {
                this.currentX = offsetSpeed(this.xGoal, this.currentX);
            }
            // verifie if must move on Y
            if (mustMove(this.yGoal, this.currentY)) {
                // compute Y
                this.currentY = offsetSpeed(this.yGoal, this.currentY);
            }

            // verifie if must move on W
            if (mustMove(this.wGoal, this.currentW) || mustMove(this.hGoal, this.currentH)) {
                this.currentW = offsetSpeed(this.wGoal, this.currentW);
                this.currentH = this.currentW * ratioOut;
                if (this.currentH > input.height) {
                    this.currentH = input.height;
                    this.currentW = this.currentH / ratioOut;
                }
            }
        }
    }

    private createContext(canvas: OffscreenCanvas): OffscreenCanvasRenderingContext2D {
        // @ts-ignore
        const ctx = canvas.getContext('2d',
            {
                alpha: false,
                antialias: false,
                desynchronized: true,
                preserveDrawingBuffer: false,
            }) as OffscreenCanvasRenderingContext2D;

        if (ctx != null) {
            ctx.imageSmoothingEnabled = false;
            return ctx;
        } else {
            throw new Error('ERROR on OffscreenCanvasCreator');
        }
    }

}
