import {log} from '@/utils/log';

export class SoundMeterEvent {
    public instant: number = 0;
    public slow: number = 0;
    public clip: number = 0;
    public currentTime: number = 0;
}

/**
 * Class util to mesure
 *  must be change by an audioWorklet
 */
export class SoundMeter {

    private onValueDetect;

    private audioContext!: AudioContext;

    private instant = 0;
    private slow = 0;
    private clip = 0;
    private script!: ScriptProcessorNode;


    constructor(stream: MediaStream, onValueDetect, accurracy = 256) {

        this.audioContext = new AudioContext();
        this.script = this.audioContext.createScriptProcessor(accurracy, 1, 1);
        this.onValueDetect = onValueDetect;
        this.script.onaudioprocess = (event) => {
            // to keep the real this
            this.onAudioProcess(event);
        };
        try {
            const mic = this.audioContext.createMediaStreamSource(stream);
            mic.connect(this.script);
            this.script.connect(this.audioContext.destination);
        } catch (e: any) {
            log.warn(e.message);
        }
    }

    public close() {
        this.script.onaudioprocess = null;
    }

    private onAudioProcess(event) {
        const input = event.inputBuffer.getChannelData(0);
        let i;
        let sum = 0.0;
        let clipcount = 0;
        for (i = 0; i < input.length; ++i) {
            sum += input[i] * input[i];
            if (Math.abs(input[i]) > 0.99) {
                clipcount += 1;
            }
        }


        this.instant = Math.sqrt(sum / input.length);
        this.slow = 0.90 * this.slow + 0.05 * this.instant;
        this.clip = clipcount / input.length;
        if (this.onValueDetect && this.instant > 0.01) {
            const eventSend = new SoundMeterEvent();
            eventSend.instant = this.instant;
            eventSend.slow = this.slow;
            eventSend.clip = this.clip;
            eventSend.currentTime = this.audioContext.currentTime;
            this.onValueDetect(eventSend);
        }
    }
}
