import {ToastProgrammatic as Toast} from 'buefy';
import {DialogProgrammatic as Dialog} from 'buefy';

import confetti from 'canvas-confetti';

export const inform = (message: string,
                       position = 'bottom-left',
                       color = 'dark') => {
    Toast.open({
        message: message,
        type: 'is-' + color,
        // @ts-ignore
        position: 'is-' + position,
        duration: 6000,
        queue: false
    });
};

export const informError = (message: string, position = 'bottom-left') => {
    inform(message, position, 'danger');
};

export const alertSuccess = (message: string, title: string = '') => {
    Dialog.alert({
        title: title,
        message: message,
        type: 'is-dark',
        hasIcon: true,
        icon: 'check-circle-outline',
        ariaRole: 'alertdialog',
        ariaModal: true,
    });
};
export const alertMessageCircle = (message: string, title: string = '') => {
    return alertMessage(message, title, 'alert-circle-outline');
};
export const alertMessage = (message: string, title: string = '', icon: string = 'alert-octagon-outline') => {
    Dialog.alert({
        title: title,
        message: message,
        type: 'is-dark',
        hasIcon: true,
        icon: icon,
        ariaRole: 'alertdialog',
        ariaModal: true,
    });
};
export const alertMessageWithConfirmation = (message: string, title: string = '', confirmText: string | undefined = undefined): Promise<void> => {
    return new Promise((resolve) => {
        Dialog.alert({
            title: title,
            message: message,
            type: 'is-dark',
            hasIcon: true,
            icon: 'alert-octagon-outline',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: confirmText,
            onConfirm: () => {
                resolve();
            },
        });
    });
};


export const alertError = (message: string, title: string = 'Error') => {
    Dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'alert-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
    });
};

export const alertErrorWithConfirmation = (message: string, title: string = 'Error'): Promise<void> => {
    return new Promise((resolve) => {
        Dialog.alert({
                title: title,
                message: message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'alert-circle',
                ariaRole: 'alertdialog',
                ariaModal: true,
                onConfirm: () => {
                    resolve();
                },
            }
        );
    });
};


export const confirmationAlert = (message: string, title: string = '', confirmText: string = 'Ok', cancelText: string = 'Cancel'): Promise<void> => {
    return confirmation(message, title, confirmText, cancelText, 'alert-circle-outline');
};

export const confirmation = (message: string, title: string = '', confirmText: string = 'Ok', cancelText: string = 'Cancel', icon: string = 'help-circle-outline'): Promise<void> => {
    return new Promise((resolve, reject) => {
        Dialog.confirm({
                title: title,
                message: message,
                type: 'is-dark',
                hasIcon: true,
                icon: icon,
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: confirmText,
                cancelText: cancelText,
                onConfirm: () => {
                    resolve();
                },
                onCancel: () => {
                    reject();
                }
            }
        );
    });
};

export const confirmationDanger = (message: string, title: string = '', confirmText: string = 'Ok', cancelText: string = 'Cancel'): Promise<void> => {
    return new Promise((resolve, reject) => {
        Dialog.confirm({
                title: title,
                message: message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'help-circle-outline',
                ariaRole: 'alertdialog',
                ariaModal: true,
                confirmText: confirmText,
                cancelText: cancelText,
                onConfirm: () => {
                    resolve();
                },
                onCancel: () => {
                    reject();
                }
            }
        );
    });
};

export const promptModal = async (message: string, title: string, value: string = '',placeholder:string='', confirmText: string = "Ok", cancelText: string = "Cancel"): Promise<string> => {
    return new Promise((resolve, reject) => {
        Dialog.prompt({
            message: message,
            title: title,
            inputAttrs: {
                type: 'text',
                value: value,
                placeholder: placeholder,
            },
            confirmText: confirmText,
            trapFocus: true,
            onConfirm: (value) => {
                resolve(value);
            },
            onCancel: () => {
                reject();
            }
        });
    });
}


export const confettiBee = async (duration: number) => {
    return new Promise((resolve, reject) => {
        const end = Date.now() + (duration);

        // go Buckeyes!
        const colors = ['#13C1FD', '#9600FF', '#5B53FF', '#FFFFFF'];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: {x: 0},
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: {x: 1},
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            } else {
                resolve('');
            }
        }());
    });
};


export const MODAL_ID = 'currentWModal';
/**
 * Close the currentWmodal
 * @param component
 */

export const closeWModal = (vueComponent: any) => {
    // @ts-ignore
    vueComponent.$root.$refs[MODAL_ID].close();
};
