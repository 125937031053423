<template>
  <div class="userAvatar" v-if="isAutenthicated" data-test="headerAvatar">
    <b-dropdown data-test="headerMenuDropDown" class="menuUser" position="is-bottom-left">
      <UserAvatar class=""
                  :userAvatar="userImage"
                  aria-role="menu"
                  type="square" slot="trigger"
      />
      <b-dropdown-item aria-role="listmenu" custom class="noHover">
        <div class="userDescription">
          <UserAvatar class="" size="small"
                      :userAvatar="userImage"
                      type="sticker"
          />
          <div class="userNames">
            <div class="userName">{{ userConnected.firstName }} {{ userConnected.lastName }}</div>
            <div class="userLogin">{{ userConnected.login }}</div>

          </div>
        </div>
      </b-dropdown-item>
      <hr class="dropdown-divider">
      <router-link :to="{name:'MyProfile'}" tag="a">
        <b-dropdown-item aria-role="listmenu" data-test="headerMenuAccountItem">
          {{ $t('header.menu.account') }}
        </b-dropdown-item>
      </router-link>
      <hr class="dropdown-divider">
      <b-dropdown-item aria-role="listmenu" data-test="headerMenuAccountItemNews" @click="launchPopUpNewsProduct">
        {{ $t('header.menu.whatNew') }}
      </b-dropdown-item>
      <!-- INTEGRATION MENU -->
      <b-dropdown-item aria-role="listmenu" class="integrationSubMenu" custom data-test="headerMenuAccountItemSubMenu">

        <b-dropdown id="dropDownIntegrationMenu" :triggers="['hover']" aria-role="list" position="is-bottom-left">
          <template #trigger>
            <div class="menuIntegration">
              <div class="labelIntegration">{{ $t('header.menu.integrations.title') }}</div>
              <div class="iconMenuIntegration">
                <w-icon class="iconIntegration" icon="chevron-right"/>
              </div>
            </div>
          </template>
          <b-dropdown-item class="integrationItem" aria-role="listitem" @click="openExtention()">
            <!--            <w-icon class="iconIntegration" icon="google-chrome"/>-->
            <img alt="iconChrome" class="iconIntegration" width="20px" src="@/assets/icons/integrations/chrome_48.png"/>
            {{ $t('header.menu.integrations.chrome') }}
          </b-dropdown-item>
          <b-dropdown-item class="integrationItem" aria-role="listitem" @click="openTeams()">
            <img alt="iconTeams" class="iconIntegration" width="20px" src="@/assets/icons/integrations/teams_48.png"/>
            {{ $t('header.menu.integrations.teams') }}
          </b-dropdown-item>
          <b-dropdown-item class="integrationItem" aria-role="listitem" @click="openSlack()">
            <img alt="iconSlack" class="iconIntegration" width="20px" src="@/assets/icons/integrations/slack_48.png"/>
            {{ $t('header.menu.integrations.slack') }}
          </b-dropdown-item>
        </b-dropdown>

      </b-dropdown-item>
      <b-dropdown-item class="installExtention" @click="openExtention()" v-if="!isPluginInstalled">
        {{ $t('header.menu.extentionChrome') }}
      </b-dropdown-item>
      <hr class="dropdown-divider">
      <b-dropdown-item aria-role="listmenu" @click="logout" tag="div" data-test="headerMenuLogoutItem">
        {{ $t('header.menu.logout') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import SignUp from '@/components/authPanel/SignUp.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import UserAvatar from '@/components/user/UserAvatar.vue';
import store from '@/store';
import {User} from '@/store/user/userModel';
import {getUserAvatar} from '@/utils/util';
import WIcon from '@/components/wrapper/w-icon.vue';
import {launchWeetCreation} from '@/utils/weetUtil';
import {AUTH_LOGOUT, RESET_ALL_CONNEXTION} from '@/store/auth/authAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import router from '@/router';
import {isPluginInstalled, openExtentionSlack, openExtentionStore, openExtentionTeams} from '@/utils/extentionUtil';
import NotificationItem from '@/components/notifications/NotificationItem.vue';
import {toLoginPage} from '@/utils/loginUtil';
import {VIEW_PRODUCT_NEWS} from '@/store/appView/appViewAction';
import {canCreateWeet} from '@/utils/workspaceUtil';
import {SELECT_MODE} from "@/store/advancedEditing/advancedEditingAction";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";

@Component(
    {
      name: 'Header',
      components: {
        NotificationItem, WIcon, UserAvatar, WModal, SignUp, WButton
      },
    })
export default class HeaderDropDownMenu extends Vue {

  private isPluginInstalled: boolean = false;

  get isAutenthicated(): boolean {
    return store.getters.isAuthenticated;
  }

  private async mounted() {
    this.isPluginInstalled = await isPluginInstalled();
  }

  private launchPopUpNewsProduct() {
    open("https://twitter.com/beweet/timelines/1352288077562183691", "_twitter")
    // store.dispatch(VIEW_PRODUCT_NEWS, true);
  }

  private openExtention() {
    openExtentionStore();
    setActionEvent(ActionEvent.tunnel_ext_open);
  }

  private openTeams() {
    openExtentionTeams();
    setActionEvent(ActionEvent.tunnel_teams_open);
  }

  private openSlack() {
    openExtentionSlack();
    setActionEvent(ActionEvent.tunnel_slack_open);
  }


  private logout() {
    store.dispatch(AUTH_LOGOUT).then(() => {
      setActionEvent(ActionEvent.auth_logout);
      store.dispatch(RESET_ALL_CONNEXTION);
    });
  }


  get userConnected(): User {
    return store.getters.userConnected;
  }

  get userImage(): string {
    return getUserAvatar(this.userConnected, 150);
  }


  get isCanCreateAWeet(): boolean {
    return canCreateWeet();
  }


  private createWeet() {
    store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
    launchWeetCreation();
  }

  private signin() {
    toLoginPage();
  }

  private signup() {
    router.push({name: 'CreateAccount'});
  }
}
</script>
<style lang="scss">
#dropDownIntegrationMenu {
  .dropdown-menu {
    right: 212px;
    padding-right: 16px;
    top: -16px;

    .dropdown-content {
    }
  }
}

</style>
<style scoped lang="scss">
@import "@/scss/gridBreakPoint.scss";

.userAvatar {
  margin-right: 32px;
  cursor: pointer;
  height: 48px;
  width: 48px;

  @include GRID1X {
    margin-right: 16px;
  }
}

.menuUser {
  text-align: left;

  .userDescription {
    cursor: auto;
    display: flex;
    min-width: 232px;

    .userNames {
      margin-left: 8px;

      .userName {
        font-weight: 600;
        color: var(--black);
        line-height: 18px;
      }

      .userLogin {
        font-size: 12px;
        color: var(--light2);
      }

    }
  }

  .integrationSubMenu {
    &:hover {
      background: whitesmoke;
    }
  }

  .menuIntegration {
    display: flex;
    align-items: center;
    min-width: 224px;

    .labelIntegration {
      flex: 1;
    }

    .iconMenuIntegration {
      .iconIntegration {
        margin-right: -8px;
      }
    }
  }

  .integrationItem {
    display: flex;
    align-items: center;
    min-width: 200px;

    .iconIntegration {
      margin-right: 8px;
    }
  }

  .installExtention {
    color: var(--primary);
  }
}
</style>
