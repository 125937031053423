<template>
  <div>
    <transition name="fade">
      <div class="timerContainer" v-show="this.time>1">
        <w-icon class="icon" icon="record" size="small"/>
        {{ humanTime }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import prettyMilliseconds from 'pretty-ms';
import {TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import WIcon from '@/components/wrapper/w-icon.vue';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {AccurateTimer} from '@/utils/accurateTimer';

@Component({
  components: {WIcon}
})
export default class TimerRecorder extends Vue {

  private timeStep: number = 250;
  private dateStart: number = new Date().getTime();
  private initialTime: number = 0;

  private timer: AccurateTimer = new AccurateTimer();


  get recordingState(): RecordingState {
    return store.getters.getRecordingState;
  }

  @Watch('recordingState')
  public onrecordingStateChange() {
    if (this.recordingState === RecordingState.RECORDING) {
      this.onStartRecording();
    } else if (this.recordingState === RecordingState.PAUSE) {
      this.onPauseRecording();
    } else if (this.recordingState === RecordingState.FINISH) {
      this.onStopRecording();
    }
  }


  public onStartRecording() {
    this.dateStart = new Date().getTime();
    this.initialTime = store.getters.getTimerRecord;
    this.timer.launch(this.incrementTimer, this.timeStep);

  }

  public onPauseRecording() {
    this.timer.stop();
  }

  public onStopRecording() {
    this.timer.stop();
  }

  public incrementTimer() {
    const time = new Date().getTime() - this.dateStart + this.initialTime;
    store.dispatch(TIMER_RECORD, time);
  }

  get time() {
    return store.getters.getTimerRecord;
  }

  get humanTime() {
    const ms = this.time;
    if (ms < 1000) {
      return '0:00';
    }
    return prettyMilliseconds(ms, {colonNotation: true, secondsDecimalDigits: 0});
  }

}
</script>
<style scoped lang="scss">

.timerContainer {
  color: var(--black);
  font-weight: 900;
  letter-spacing: 0.3px;
  text-align: left;

  .icon {
    color: var(--red);
  }
}
</style>
