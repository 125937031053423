import store from '@/store';
import {FRONT_VERSION} from '@/store/conf/api';

// 14 jours
// 1s * minutes * hours * 24 * day
const DATE_IGNORE_NEW: number = 1000 * 60 * 60 * 24 * 14;

export const mustShowLastNews = () => {
  const dateShowNews = store.getters.getLastDateNewsCheck as number | undefined;
  const lastVersionView = store.getters.getLastVersionNewsCheck;

  let dateObsolete: boolean = true;
  // validate date
  if (dateShowNews && (dateShowNews + DATE_IGNORE_NEW) > new Date().getTime()) {
    dateObsolete = false;
  }

  if (dateObsolete) {
    // we check the version
    try {
      if (FRONT_VERSION) {
        const stringVersion = FRONT_VERSION.split('.')[0] + '.' + FRONT_VERSION.split('.')[1];

        if (isNaN(parseFloat(stringVersion)) || parseFloat(stringVersion) > lastVersionView) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      return dateObsolete;
    }
  }
};
