import {StaticTag} from '@/store/myWeet/myWeetAction';
import {Workspace} from '@/store/workspace/workspaceModel';

export class Notification {

  public id: string = '';

  // who create the notification
  public by: UserDescription | null = null;

  // weet Description
  public weet: WeetDescription | null = null;

  // comment Description
  public comment: CommentDescription | null = null;

  // weet time
  public time: number | undefined;
  public duration: number | undefined;
  public created: Date | undefined;
  public type: NotificationType | undefined;
  public viewed: boolean = false;
  public other: UserDescription | null = null;
  public staticTag: StaticTag | undefined;

  // sources
  public weetID: string | null = null;
  public commentID: string | null = null;
  public replyToCommentID: string | null = null;
  public groupID: string | null = null;

  // Workspace
  public workspaceID: string | null = null;
  public workspace: Workspace | null = null;
  public channels: string[] = [];

}


export class NotificationStat {
  public total: number | undefined;
  public unread: number | undefined;
}

export enum NotificationType {
  VIEW_WEET = 'VIEW_WEET',
  COMMENT_WEET = 'COMMENT_WEET',
  INVITE_WEET = 'INVITE_WEET',
  SHARE_WEET = 'SHARE_WEET',
  UPDATE_WEET = 'UPDATE_WEET',
  SHARED_WEET = 'SHARED_WEET',
  REACT_WEET = 'REACT_WEET',
  REPLY_COMMENT = 'REPLY_COMMENT',
  NEW_WEET = 'NEW_WEET',
  INVITE_WORKSPACE = 'INVITE_WORKSPACE'
}


export class WeetDescription {
  public weetTitle: string | undefined;
  public weetThumbnail: string | undefined;
  public weetLink: string | undefined;
  public weetID: string | undefined;
}

export class CommentDescription {
  public commentID: string | undefined;
  public content: string | undefined;
  public commentType: string | undefined;
}


export class UserDescription {
  public login: string;
  public contactEmail: string;
  public firstName: string;
  public lastName: string;
  public userID: string;
  public imageUrl: string = '';

  constructor(login: string, firstName: string, lastName: string, contactEmail: string, userID: string) {
    this.login = login;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userID = userID;
    this.contactEmail = contactEmail;
  }
}
