import store from "@/store";
import {selectWorkspace} from "@/utils/workspaceUtil";
import {GET_MY_WORKSPACES_LIST} from "@/store/workspace/workspaceAction";
import {CREATE_MY_WEET} from "@/store/myWeet/myWeetAction";
import {sendMessageToExtention} from "@/utils/extentionUtil";
import {PREPOPULATE_WEET_TITLE, SET_CURRENT_EDITING_WEET} from "@/store/recordingState/recordStateAction";
import {TimeEventType} from "@/enum/TimeEventType";
import {TimeEvent, TimeEventInfo, TimeEventMedia} from "@/store/timeLine/timeEvent";
import {SourceVideoType} from "@/enum/SourceVideoEnum";
import {sendSilentWeetId} from "@/utils/speachUtils";

/**
 * Check if a workspace is selected
 * return false if not possible to select a workspace
 */
export const checkWorkspaceSelection=async ():Promise<boolean>=>{
    if (!store.getters.getSelectedWorkspace) {
        // selection the first workspace if no workspace
        let listOfWorkspace = store.getters.getMyWorkspaces;
        if (listOfWorkspace.length > 0) {
            selectWorkspace(listOfWorkspace[0].workspaceID);
        } else {
            // load workspace
            await store.dispatch(GET_MY_WORKSPACES_LIST);
            listOfWorkspace = store.getters.getMyWorkspaces;
            if (listOfWorkspace.length > 0) {
                selectWorkspace(listOfWorkspace[0].workspaceID);
            } else {
                return false;

            }
        }
    }
    return true;
}


export const initWeetEditing=async ()=>{
    if (store.getters.getEditingWeetID === '') {
        const weet = await store.dispatch(CREATE_MY_WEET, prepopulateWeetTitle());
        sendMessageToExtention(SET_CURRENT_EDITING_WEET, weet.weetID);
        await store.dispatch(SET_CURRENT_EDITING_WEET, weet.weetID);
        // We send the weetID silently to save the weet in the chapter
        // in case of refresh in the browser in speach
        sendSilentWeetId(weet.weetID);
        return weet;
    }
    return null;
}

export const prepopulateWeetTitle=(): string | null =>{
    const weetTitle = store.getters.getPrepopulatedWeetTitle;
    if (weetTitle != null) {
        store.dispatch(PREPOPULATE_WEET_TITLE, null);
        return weetTitle;
    }
    return null;
}


export const initTimeEvent=(type: TimeEventType): TimeEvent =>{
    const timeEvent = new TimeEvent(type, store.getters.getTimerRecord);
    timeEvent.fullscreenMode = store.getters.isStickerFullscreen;
    timeEvent.positionLayout = store.getters.getStickerPosition;

    // create mediaDescription  for mainMedia
    const mainMediaDescription = new TimeEventMedia();
    if (store.getters.getMainRecordSource === SourceVideoType.WEBCAM) {
        mainMediaDescription.filter = store.getters.getRecorderFilter;
    }
    mainMediaDescription.currentTime = store.getters.getMainVideoRecordingTime;
    mainMediaDescription.mediaID =  store.getters.getMainMediaId;
    mainMediaDescription.running = store.getters.isMainVideoRunning;
    // set main media description
    timeEvent.mainMedia = mainMediaDescription;

    // create sticker mediaDescription
    const secondMediaDescription = new TimeEventMedia();
    if (store.getters.getStickerRecorderSource === SourceVideoType.WEBCAM) {
        secondMediaDescription.filter = store.getters.getRecorderFilter;
    }
    secondMediaDescription.currentTime = store.getters.getStickerCurrentRecordingTime;
    secondMediaDescription.mediaID = store.getters.getStickersMediaId;
    secondMediaDescription.running = store.getters.isStickerVideoRunning;
    // set second mediaDescription
    timeEvent.secondMedia = secondMediaDescription;

    // now we create the info content
    if (timeEvent.type === TimeEventType.MEDIA_PLAY) {
        timeEvent.info = initInfoEventContent();
    }
    return timeEvent;
}

export const initInfoEventContent=(): TimeEventInfo=> {
    const info = new TimeEventInfo();
    info.microphoneMute = store.getters.isStickerMute;
    info.webcamMute = store.getters.isStickerVideoMute;
    info.virtualBackground = store.getters.getVirtualBackground;
    info.noiseCancelation = store.getters.getNoiseCancelationLvl;
    if (store.getters.getMainRecordSource === SourceVideoType.VIRTUAL_SCREENCAST) {
        info.screenbackground = store.getters.getScreenVirtualBackground;
    }
    info.faceTracking = store.getters.getStickerFaceTrack;
    info.captureScreenQuality = store.getters.getResolutionCapture;
    if (store.getters.getMainRecordSource === SourceVideoType.SCREENCAST) {
        info.screenSurface = store.getters.getScreenSurface;
    }
    return info;
}
