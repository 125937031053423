<template>
  <div>
    <b-dropdown position="is-bottom-left">
      <template #trigger="{ active }">
        <w-button color="light" size="small" icon="plus" :tooltip="$t('channelSubscripterButton.tooltip')"
                  :tooltip-position="'top'"/>
      </template>
      <b-dropdown-item @click="openChannelSubscriberModal">
        {{ $t('channelSubscripterButton.browse') }}
      </b-dropdown-item>
      <b-dropdown-item @click="channelAddModal=true">
        {{ $t('channelSubscripterButton.create') }}
      </b-dropdown-item>
    </b-dropdown>

    <w-modal :max-width="1121" :active.sync="channelSubModal" :modal="true">
      <CenterCenter>
        <div class="modalLayout">
          <ChannelSubscriberSubscribe @close="channelSubModal=false" @subscribe="selectChannel"/>
        </div>
      </CenterCenter>
    </w-modal>

    <w-modal :max-width="1121" :active.sync="channelAddModal" :modal="true">
      <CenterCenter>
        <div class="modalLayout">
          <ChannelSubscriberAdd @close="channelAddModal=false" @subscribe="selectChannel"/>
        </div>
      </CenterCenter>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WInput from '@/components/wrapper/w-input.vue';
import {Channel} from '@/store/channel/channelModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import ChannelSubscriberAdd from '@/components/channel/ChannelSubscriberAdd.vue';
import ChannelSubscriberSubscribe from '@/components/channel/ChannelSubscriberSubscribe.vue';

@Component({
  components: {
    ChannelSubscriberAdd,
    ChannelSubscriberSubscribe,
    WModal, CenterCenter, WToolTip, WInput, WIcon, PopUpTitle, CenterCenterPanel, WButton, WError
  },
})
export default class ChannelSubscriberButton extends Vue {
  private channelSubModal: boolean = false;

  private channelAddModal: boolean = false;

  private openChannelSubscriberModal() {
    this.channelSubModal = true;
  }

  private closeChannelSubscriberModal() {
    this.channelSubModal = false;
  }

  private selectChannel(channel: Channel) {
    this.$emit('subscribe', channel.channelID);
  }

}
</script>


<style scoped lang="scss">
@import "@/scss/shadows.scss";

.modalLayout {
  background: #FFF;
  max-width: 600px;
  min-height: 320px;
  padding: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  position: relative;
}

</style>
