<template>
  <div>
    <w-modal :active.sync="isActive"
             trap-focus
             aria-role="dialog"
             aria-modal @close="onclose"
             style="overflow: auto">
      <CenterCenter>
        <div class="contentModal">
          <!-- TITLE BLOCK -->
          <PopUpTitle :title="$t('movePanel.title')"/>
          <w-error :message="errormsg"/>
          <div class="subTitleMovePanel">{{ $t('movePanel.subtitle') }}</div>
          <div class="workspaceSelectionContainer">
            <InputWorkspaceChoose @select="onSelectWorkspace" :value="selectedWorkspaceID"
                                  :limit-workspaces-create="true"/>
          </div>

          <div>
            <w-button class="buttonValidate" :loading="loading" :enabled="enabledButton" @click="handleFilterWorkspace">
              {{ $t('movePanel.validate') }}
            </w-button>
          </div>
        </div>
      </CenterCenter>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import WModal from '@/components/wrapper/w-modal.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import {ADD_WEET_TO_WORKSPACE} from '@/store/myWeet/myWeetAction';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import WorkspaceSelection from '@/components/workspace/WorkspaceSelection.vue';
import {confirmationDanger, inform} from '@/utils/dialog';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import {Workspace} from '@/store/workspace/workspaceModel';
import {getUserAvatar, getWorkspaceAvatar} from '@/utils/util';
import {User} from '@/store/user/userModel';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WError from '@/components/wrapper/w-error.vue';
import InputWorkspaceChoose from '@/components/workspace/InputWorkspaceChoose.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {manageThemeForWorkspace, selectWorkspace} from "@/utils/workspaceUtil";

@Component({
  components: {
    WButton,
    InputWorkspaceChoose,
    UserAvatar,
    WorkspaceAvatar,
    WorkspaceSelection,
    CenterCenter,
    WModal,
    PopUpTitle,
    WError
  }
})
export default class WorkspaceMovePanel extends Vue {
  private mustShow: boolean = false;
  @Prop()
  private weet!: Weet | null;

  @Prop({default: false})
  private changeWorkspaceOnSelect!: boolean;

  @Prop({default: false})
  private active!: boolean;
  private isActive: boolean = this.active || false;
  private errormsg = '';

  private loading: boolean = false;

  private enabledButton: boolean = false;

  private selectedWorkspaceToChange: string | null = '';


  private mounted() {
    this.mustShow = true;
  }


  @Watch('active')
  private isActiveChange() {
    this.isActive = this.active;
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }


  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  private avatar(workspace: Workspace) {
    return getWorkspaceAvatar(workspace, 256);
  }


  private onclose() {
    this.$emit('close');
    this.$emit('update:active', false);
  }

  private onSelectWorkspace(workspaceID: string | null = null) {

    this.selectedWorkspaceToChange = workspaceID;
    this.enabledButton = true;
    if (workspaceID === store.getters.getSelectedWorkspaceID) {
      this.enabledButton = false;
    }
  }

  private async handleFilterWorkspace() {
    this.errormsg = '';
    this.loading = true;
    if (this.weet) {
      // Add the weet to workspace we selected workspace change
      store.dispatch(ADD_WEET_TO_WORKSPACE,
          {
            weetID: this.weet.weetID,
            workspaceID: this.selectedWorkspaceToChange,
          }).then(() => {
        inform(this.$t('movePanel.move.success').toString());
        if (this.changeWorkspaceOnSelect) {
          selectWorkspace(this.selectedWorkspaceToChange);
          manageThemeForWorkspace(store.getters.getWorkspace(this.selectedWorkspaceToChange));
        }
        this.loading = false;
        this.onclose();
      }).catch((error: any) => {
        this.errormsg = error.message;

      });
    } else {
      selectWorkspace(this.selectedWorkspaceToChange);
      manageThemeForWorkspace(store.getters.getWorkspace(this.selectedWorkspaceToChange));
      inform(this.$t('movePanel.move.success').toString());
      this.onclose();
    }
  }

}
</script>

<style scoped lang="scss">


.contentModal {
  background: #FFF;
  max-width: 600px;
  min-height: 320px;
  padding: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  border-radius: 8px;
  margin: auto;
  width: 100%;
  position: relative;

  .subTitleMovePanel {
    color: var(--dark);
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  .workspaceSelectionContainer {
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: center;

  }

  .buttonValidate {
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
}
</style>
