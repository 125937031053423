<template>
  <div>
    <transition name="zoom" >
      <w-button v-show="mustShow" @click="onChooseStep()" data-test="recorderStartPauseButton"
                :icon="icon" :loading="step===2" :enabled="isButtonEnabled"
                :tooltip="tooltip">
        {{ labelRecord }}
      </w-button>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {
  CHANGE_STEP_AI_GENERATION,
  PAUSE_RECORDING,
  PING_RECORD,
  START_RECORDING
} from '@/store/recordingState/recordStateAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';

@Component({
  components: {WButton},
})
export default class GenerateAvatarButton extends Vue {
  private mustShow: boolean = false;

  get step(){
    return store.getters.getAiAvatarStep;
  }

  get avatartText():string{
    return store.getters.getAiAvatarText;
  }

  get timeRemaining(){
    return store.getters.getAvatarAIremaining;
  }
  get isAIAvatarAuthorized(){
    return store.getters.isAvatarAI
  }

  get isButtonEnabled():boolean{

    if(!this.isAIAvatarAuthorized){
      return false;
    }
    if(this.timeRemaining<=0){
      return false;
    }

    if(this.step===0){
      return  true;
    }
    if(this.step===1 && this.avatartText){
      return true;
    }
    return false;
  }

  get tooltip(){
    if(!this.isAIAvatarAuthorized){
      return this.$t('createWeetComponent.aiAvatar.disabled')
    }
    if(this.timeRemaining<=0){
      return this.$t('createWeetComponent.aiAvatar.timeRemaining')
    }
    return "";
  }
  private mounted() {
    this.mustShow = true;
  }

  private onChooseStep(){
    if(this.step===0){
      store.dispatch(CHANGE_STEP_AI_GENERATION,1);
    }else{
      store.dispatch(CHANGE_STEP_AI_GENERATION,2);
    }
  }

  get labelRecord():string{
    if(this.step===0){
      return this.$t('createWeetComponent.record.button.avatarNext').toString();
    }else if(this.step===1){
      return this.$t('createWeetComponent.record.button.avatarGenerate').toString();
    }else{
      return this.$t('createWeetComponent.record.button.avatarWaiting').toString();
    }
  }

  get icon():string{
    if(this.step===0){
      return 'check';
    }else if(this.step===1){
      return 'play';
    }else{
      return 'clock-time-eight-outline';
    }
  }

}
</script>
<style scoped lang="scss">

</style>
