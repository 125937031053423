<template>
  <div class="mainContainer">
    <b-field class="fieldPhoto">
      <b-upload v-model="selectedFile"
                drag-drop accept="image/*"
                class="draggablePanel"
                @input="setupCropper">
        <div class="buttonChangeImage">
          <div class="fakeButton" data-class="fakeButtonUpload">
            {{
              $t('createWeetComponent.advancedEditing.thumbnailMode.upload')
            }}
          </div>
        </div>
      </b-upload>

    </b-field>
    <div class="boxEditing" v-show="currentlyEditing">
      <w-modal :active="true" trap-focus
               aria-role="dialog" :modal="true"
               @onclose="handlePopupClose"
      >
        <CenterCenter>
          <div class="editImageBackground">
            <div class="relativeBox">
              <img :src="fileUploadUrl" class="imageOriginal" ref="source">
              <div class="boxButton">
                <w-button :circle="true" class="buttonEditingAction" size="small" @click="resetCropper"
                          icon="aspect-ratio"
                          color="color1"/>
                <w-button :circle="true" class="buttonEditingAction" size="small" @click="rotateLeft" icon="rotate-left"
                          color="color2"/>
                <w-button :circle="true" class="buttonEditingAction" size="small" @click="rotateRight"
                          icon="rotate-right"
                          color="color3"/>
                <w-button :circle="true" class="buttonEditingAction" size="small" @click="zoomPlus"
                          icon="magnify-plus-outline"
                          color="color4"/>
                <w-button :circle="true" class="buttonEditingAction" size="small" @click="zoomMinus"
                          icon="magnify-minus-outline" color="color5"/>
              </div>
              <!-- to keep, this is a worker document for cropper -->
              <img :src="previewUrl" class="imagePreview" ref="preview">
            </div>
            <w-button :circle="true" class="validateEdition" icon="check" :loading="loading" color="primary"
                      @click="submitFormData"/>
          </div>
        </CenterCenter>
      </w-modal>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WButton from '@/components/wrapper/w-button.vue';
import Cropper from 'cropperjs';
import debounce from 'lodash/debounce';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import store from '@/store';
import WError from '@/components/wrapper/w-error.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import WModal from '@/components/wrapper/w-modal.vue';


@Component({
  components: {WIcon, WError, WButton, WorkspaceAvatar, WToolTip, WModal, CenterCenter},
})
export default class ThumbnailCropEditor extends Vue {


  private cropper: any = null;
  private fileUploadUrl: string = '';
  private previewUrl: string = '';
  private currentlyEditing: boolean = false;
  private errorMsg: string = '';
  private loading: boolean = false;
  private openPopup: boolean = false;
  private selectedFile: any = null;

  get sourceImage(): HTMLImageElement {
    return this.$refs.source as HTMLImageElement;
  }

  public resetCropper() {
    this.cropper.reset();
  }

  public handlePopupClose() {
    this.openPopup = false;

  }

  public rotateLeft() {
    this.cropper.rotate(-90);
  }

  public rotateRight() {
    this.cropper.rotate(90);
  }

  public zoomPlus() {
    this.cropper.zoom(0.2);
  }

  public zoomMinus() {
    this.cropper.zoom(-0.2);
  }

  public setupCropper(selectedFile: File) {

    if (this.cropper) {
      this.cropper.destroy();
    }

    if (this.fileUploadUrl) {
      window.URL.revokeObjectURL(this.fileUploadUrl);
    }

    if (!selectedFile) {
      this.cropper = null;
      this.fileUploadUrl = '';
      this.previewUrl = '';
      return;
    }

    this.fileUploadUrl = window.URL.createObjectURL(selectedFile);
    this.currentlyEditing = true;
    // we have to wait the browser load the image
    this.$nextTick(() => {
      this.cropper = new Cropper(this.sourceImage, {
        aspectRatio: 16 / 9,
        viewMode: 0,
        dragMode: 'crop',
        crop: debounce(this.updatePreview, 200),
      });
    });
  }


  public updatePreview(event) {
    const canvas = this.cropper.getCroppedCanvas();
    this.previewUrl = canvas.toDataURL('image/png');
  }

  public submitFormData() {
    this.errorMsg = '';
    this.loading = true;
    const canvas = this.cropper.getCroppedCanvas();

    canvas.toBlob((blob) => {
      this.cropper.destroy();
      this.fileUploadUrl = '';
      this.selectedFile = null;
      this.previewUrl = '';
      this.currentlyEditing = false;
      this.loading = false;
      this.$emit('blob', blob);
    });
  }
}
</script>
<style lang="scss">
.cropper-container.cropper-bg {
  background: white;
}
</style>

<style scoped lang="scss">
@import "@/scss/shadows.scss";


.editImageBackground {
  background: white;
  max-width: 600px;
  width: 100%;
  min-height: 400px;
  position: relative;
  margin: auto;
  border-radius: 16px;
  @extend .shadow1;
  padding: 16px;

  .relativeBox {
    position: relative;
    width: calc(100%);
    height: calc(330px - 32px);
  }
}

.mainContainer {
  .buttonChangeImage {
    width: 100%;

    .fakeButton {
      color: var(--black);
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border: solid 1px var(--light1);
      border-radius: 12px;
      font-size: 13px;
      justify-content: center;
      font-weight: 900;
      line-height: 22px;
      height: 32px;
      max-height: 32px;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        //transform: scale(1.05);
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }


  .imageOriginal {
    max-height: 300px;
    min-height: 300px;
    max-width: 300px;
    min-width: 300px;
  }

  .imagePreview {
    display: none;
    max-height: 229px;
    max-width: 100%
  }

  .boxEditing {
    display: flex;
    position: relative;

    .boxButton {
      position: absolute;
      top: 0;
      right: 0;

      .buttonEditingAction {
        margin: 4px;
      }
    }

    .validateEdition {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }

  .draggablePanel {
    width: calc(100% + 8px);
    margin-left: -4px;

    .draggableWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

}
</style>
