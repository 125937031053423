<template>
  <div class="commentEmote" :style="generateAleatoireAnimation">


    <img alt="emote" :src="comment.imageUrl"/>
    <div class="userContainer">
      <user-avatar class="avatar" :user-avatar="imageForUser" size="tiny"/>
      <div class="labelComment">{{ labelUser }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {CommentEvent} from '@/store/comment/commentModel';
import randomInt from 'random-int';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {User} from '@/store/user/userModel';
import {getImageURLForSize, getUserAvatar} from '@/utils/util';

@Component({
  components: {UserAvatar},
})
export default class CommentVideo extends Vue {

  @Prop()
  private comment!: CommentEvent;
  @Prop({default: 3000})
  private animationTime!: number;

  get labelUser() {
    if (this.comment.user === null) {
      return '';
    } else {
      return '@' + this.comment.user.firstName;
    }
  }

  get imageForUser(): string {
    return getUserAvatar(this.comment.user, 100);

  }

  get generateAleatoireAnimation() {
    const delay = randomInt(0, 500);
    const duration = this.animationTime + randomInt(0, 2000);
    return 'animation-delay: ' + delay + 'ms;' + 'animation-duration: ' + duration + 'ms;';
  }

}
</script>

<style scoped lang="scss">
.commentEmote {
  position: absolute;
  right: 64px;
  margin-right: 16px;
  bottom: 16px;
  opacity: 0;
  width: 5%;
  max-width: 64px;
  animation: PIOUFF 0.5s ease-in-out;

  .userContainer {
    display: flex;
    justify-items: left;
    margin-left: -32px;

    .labelComment {
      font-size: 16px;
      color: #FFF;
    }

    .avatar {
      margin-right: 8px;
      max-height: 24px;
    }
  }
}


@keyframes PIOUFF {
  0% {
    transform: rotate(0);
  }
  10% {
    right: 32px;
    opacity: 0.8;
    transform: rotate(10deg);
  }
  20% {
    right: 60px;
    opacity: 0.8;
  }
  30% {
    right: 64px;
    opacity: 0.8;
    transform: rotate(-10deg);
  }
  40% {
    right: 68px;
    opacity: 0.8;
    transform: rotate(0deg);
  }
  50% {
    right: 64px;
    opacity: 0.8;
  }
  60% {
    right: 58px;
    opacity: 0.8;
  }
  70% {
    right: 60px;
    opacity: 0.5;
    transform: rotate(5deg);
  }
  80% {
    right: 64px;
    opacity: 0;
    transform: rotate(0deg);
  }
  90% {
    opacity: 0;
    right: 64px;
  }
  100% {
    bottom: 100%;
    opacity: 0;
    transform: rotate(0);
  }

}


</style>
