<template>
  <div class="weetPreviewContainer">

    <!--     Name input -->
    <div class="inputLayout">
      <w-button icon="arrow-left" size="small" color="light" data-test="recorderStep2BackButton"
                @click="sendBack"></w-button>
      <div v-if="!isSpeach" ref="titleDiv" class="titleEditable" steptour="titleLine" :contenteditable="true"
           v-text="title"
           @focusout="changeTitleWeet" @keydown.enter="focusOutTitleEdition"/>
    </div>
    <div class="playerContainer" ref="playerPreviewScreen">
      <WeetPlayer v-if="!isProcessing" :miniSticker="true" :editMode="true" :maxHeight="324" :fullResponsive="true"
                  :emote-aivailable="false"
                  :fullScreenElement="'playerPreviewScreen'"
                  :weetID="weetID"/>
      <div v-else class="progressWeet">
        <ProgressProcessPlayer :weet="myWeet"/>
      </div>
    </div>
    <div class="linkBar" :class="{'personnalWorkspace':!selectedWorkspaceID}">
      <w-button :enabled="isAdvancedEditingAivailable" icon="message-outline" size="large" color="outlined"
                class="buttonEdit"
                @click="goToInteractionMode"
                :tooltip="$t('createWeetComponent.advancedEditing.tooltip.interaction')">
      </w-button>
      <w-button :enabled="isAdvancedEditingAivailable" icon="format-list-bulleted" size="large" color="outlined"
                class="buttonEdit" v-if="!isSpeach"
                @click="gotToChapterMode"
                :tooltip="$t('createWeetComponent.advancedEditing.tooltip.chapter')">
      </w-button>
      <w-button :enabled="isAdvancedEditingAivailable" icon="content-cut" size="large" color="outlined"
                class="buttonEdit"
                @click="gotToCutMode" :tooltip="$t('createWeetComponent.advancedEditing.tooltip.cut')">
      </w-button>
      <GoToSubtitleWeetButton :enabled="isAdvancedEditingAivailable" icon="subtitles-outline" size="large"
                              color="outlined" :weet="myWeet" v-if="subtitleFeatureAvailable"
                              class="buttonEdit" :tooltip="$t('createWeetComponent.advancedEditing.tooltip.subtitles')">
      </GoToSubtitleWeetButton>
      <w-button :enabled="isAdvancedEditingAivailable" icon="microphone-outline" size="large" color="outlined"
                class="buttonEdit"
                @click="gotToVoiceOverMode" :tooltip="$t('createWeetComponent.advancedEditing.tooltip.voiceOver')">
      </w-button>
      <w-button :enabled="isAdvancedEditingAivailable" icon="image-outline" size="large" color="outlined"
                class="buttonEdit" v-if="!isSpeach"
                @click="gotToThumbnailMode"
                :tooltip="$t('createWeetComponent.advancedEditing.tooltip.thumbnail')">
      </w-button>

      <w-button v-if="isSpeach" size="large" :enabled="isAdvancedEditingAivailable" icon="check"
                @click="informSpeach">
        {{$t('createWeetComponent.speachSave')}}
      </w-button>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import {DUPLICATE_MY_WEET, SEND_CHANGE_WEET_TITLE} from '@/store/myWeet/myWeetAction';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import {copyLink, createUnsavedEditingWeet, goToSubtitleService, isWeetEquals, isWeetIsReady} from '@/utils/weetUtil';
import {inform} from '@/utils/dialog';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import ChannelWorkspace from '@/components/channel/ChannelWorkspace.vue';
import ChannelWorkspaceAddDropDown from '@/components/channel/ChannelWorkspaceAddDropDown.vue';
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import GoToSubtitleWeetButton from "@/components/actionButton/GoToSubtitleWeetButton.vue";
import {isSpeach} from "@/utils/speachUtils";


@Component({
  components: {
    GoToSubtitleWeetButton,
    ChannelWorkspaceAddDropDown,
    ChannelWorkspace, ProgressProcessPlayer, EndWeetButton, WButton, WError, WInput, WeetPlayer
  },
})
export default class CreateWeetPreviewStep extends Vue {
  private errormsg: string = '';
  private title: string = '';
  private loading: boolean = false;

  get isSpeach() {
    return isSpeach();
  }

  get isAdvancedEditingAivailable(): boolean {
    return (!store.getters.isSychronisationInProgress && !store.getters.timelineNeedToBeSaved);
  }

  get subtitleFeatureAvailable(){
    return store.getters.isTranscript;
  }

  get weetID(): string {
    return store.getters.getEditingWeetID;
  }

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspaceID;
  }

  get isProcessing(): boolean {
    return !isWeetIsReady(this.myWeet);
  }

  get myWeet(): Weet {
    return createUnsavedEditingWeet(this.weetID);
  }

  private clickEnd() {
    this.loading = true;
    this.$emit('end');
  }

  private sendBack() {
    this.$emit('back');
  }


  private gotToChapterMode(value: string) {
    setActionEvent(ActionEvent.record_advanced_setting);
    this.$emit('advancedEditing', AdvancedEditingMode.CHAPTER);
  }

  private goToInteractionMode(value: string) {
    setActionEvent(ActionEvent.record_advanced_setting);
    this.$emit('advancedEditing', AdvancedEditingMode.INTERACTION);
  }

  private gotToCutMode(value: string) {
    setActionEvent(ActionEvent.record_advanced_setting);
    this.$emit('advancedEditing', AdvancedEditingMode.CUT);
  }

  private gotToThumbnailMode(value: string) {
    setActionEvent(ActionEvent.record_advanced_setting);
    this.$emit('advancedEditing', AdvancedEditingMode.THUMBNAIL);
  }

  private gotToVoiceOverMode(value: string) {
    setActionEvent(ActionEvent.record_advanced_setting);
    this.$emit('advancedEditing', AdvancedEditingMode.VOICE_OVER);
  }


  @Emit('informSpeach')
  private informSpeach(){
    return;
  }

  private mounted() {
    const weet = createUnsavedEditingWeet(this.weetID);
    if (weet !== null && weet) {
      this.title = weet.title;
    }
    setPageEvent(PageEvent.page_record_step_share_preview);
    this.selectTitle();
  }


  private selectTitle() {
    const titleDiv = this.$refs.titleDiv as HTMLDivElement;
    if (titleDiv) {
      titleDiv.focus();
      // @ts-ignore
      window.getSelection().selectAllChildren(titleDiv);
    }
  }

  private focusOutTitleEdition(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

  private changeTitleWeet(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      if (element.innerText.trim() !== this.title.trim()) {
        // get weetID
        store.dispatch(SEND_CHANGE_WEET_TITLE,
            {
              weetID: store.getters.getEditingWeetID,
              title: element.innerText,
            }).then(() => {
          this.errormsg = '';
        }).catch((err) => {
          this.errormsg = err;
        });
        inform(this.$t('player.title.edition.success').toString());
      }
    }
  }
}

</script>

<style scoped lang="scss">

.weetPreviewContainer {
  margin: auto;

  .playerContainer {
    position: relative;
    height: 324px;
    max-height: 324px;
    width: 576px;
    margin: auto;
    background: var(--black);
    border-radius: 8px;

    .progressWeet {
      display: flex;
      justify-items: center;
      align-content: center;
      height: 100%;
      width: 100%;
      padding: 64px;
    }
  }

  .linkBar {
    display: flex;
    margin-left: 64px;
    margin-top: 32px;
    width: calc(100% - 128px);
    justify-content: space-between;

    .buttonEdit {
      margin-right: 16px;
    }
  }

  .buttonValidate {
    margin-left: 16px;
  }

  .inputLayout {
    display: flex;
    text-align: left;
    margin-left: 64px;
    margin-top: 32px;
    margin-bottom: 16px;
    width: calc(100% - 128px);

    .titleEditable {
      font-size: 24px;
      font-weight: 900;
      flex-grow: 1;
      color: var(--black);
      text-align: left;
      justify-content: center;
      line-height: 32px;
      margin-left: 16px;
      border-radius: 4px;

      &:hover {
        box-shadow: 0px 0px 2px var(--light2);
      }

      &:focus {
        box-shadow: 0px 0px 2px var(--primary);
      }

    }

    .errorMessage {
      margin-top: 16px;
      margin-bottom: 16px;
    }

  }
}
</style>
