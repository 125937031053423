export const LAUNCH_CUSTOM_DOWNLOAD = 'LAUNCH_CUSTOM_DOWNLOAD';
export const GET_PROGRESS_CUSTOM_DOWNLOAD = 'GET_PROGRESS_CUSTOM_DOWNLOAD';
export const CANCEL_CUSTOM_DOWNLOAD = 'CANCEL_CUSTOM_DOWNLOAD';
export const GET_CUSTOM_DOWNLOAD_URL = 'GET_CUSTOM_DOWNLOAD_URL';
export const CUSTOM_DOWNLOAD_DOWNLOADED = 'CUSTOM_DOWNLOAD_DOWNLOADED';


export class CustomDownloadParams {
    public weetID: string;
    public height: number;
    public fps: number;
    public speed: number;
    public urlSubtitle: string;

    public exportInteraction:boolean

    constructor(weetID: string, height: number, fps: number, speed: number, urlSubtitle: string,exportInteraction:boolean) {
        this.weetID = weetID;
        this.height = height;
        this.fps = fps;
        this.speed = speed;
        this.urlSubtitle = urlSubtitle;
        this.exportInteraction=exportInteraction;
    }
}

export interface CustomDownloadResponse {
    weetID: string;
    height: number;
    fps: number;
    jobID: string;
    speed: number,
    progress: number;
    exportID: string | null;
    dateStartUTC: number;
}
