import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {cropAndScaleBitmap} from '@/utils/video/canvasUtils';


export class CopyCropAndScaleTransformer implements ICanvasTransform {
    public initialised: boolean = false;
    private id: string = uuidv4();

    private canvasPreRendering!: OffscreenCanvas;
    private width: number;
    private height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    public async init(stream: MediaStream) {
        this.canvasPreRendering = new OffscreenCanvas(this.width, this.height);
        log.debug('Initialise CopyDrawTransformer');
    }

    public async start() {
        log.debug('start CopyDrawTransformer');
        return;
    }

    public async transform(sleepMode: boolean, input: ImageBitmap | null): Promise<ImageBitmap | null> {
        try {
            const dateStart = new Date().getTime();
            let imageGenerated: ImageBitmap | null = null;
            if (!sleepMode && input && input.width > 0) {
                // console.log('imageGrabbed', new Date().getTime() - dateStart);
                imageGenerated = await cropAndScaleBitmap(input, this.width, this.height);
            }
            this.initialised = true;
            return imageGenerated;
        } catch (e: any) {
            console.error(e);
            throw new Error('RENDER_ERROR: ' + e.toString());
        }
    }


    public async flush() {
        log.debug('Flush CopyBitmapTransform');
    }
}
