<template>
  <div>
    {{ subtitleLine }}
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {getUrlContent} from '@/utils/util';
// @ts-ignore
import srtParser from 'subtitles-parser';

@Component(
    {
      components: {WIcon, WToolTip},
    })
export default class SubtitlePlayer extends Vue {
  // this is the result
  @Prop({default: ''})
  private subtitle!: string;

  @Prop({default: 0})
  private time!: number;

  private subtitleData: any[] = [];

  private currentLine = '';


  public mounted() {
    this.downloadSubtitle();
  }


  @Watch('subtitle')
  public async downloadSubtitle() {
    if (this.subtitle) {
      const srtContent = await getUrlContent(this.subtitle);
      this.onReceiveSubtitle(srtContent);
    } else {
      this.subtitleData = [];
    }
  }

  get subtitleLine(): string {
    let tmpLine = '';
    for (const line of this.subtitleData) {
      if (this.time >= line.startTime) {
        tmpLine = line.text;
      } else {
        break;
      }
    }
    if (this.currentLine !== tmpLine) {
      this.$emit('changeSubtitleLine', tmpLine);
    }
    this.currentLine = tmpLine;
    return tmpLine;
  }

  public onReceiveSubtitle(srtBrut: string) {
    this.subtitleData = srtParser.fromSrt(srtBrut, true);
  }


}
</script>

<style scoped lang="scss">

.wordClass {
  font-size: 12px;
  font-style: italic;
}
</style>
