export class AccurateTimer {

  private stopped: boolean = false;
  private freq = 50 / 1000;
  private startStep: number = 0;
  public preciseStep: number = 0;

  public launch(callback, frequency: number = 50) {
    // AudioContext time parameters are in seconds
    this.setFrequency(frequency);

    const aCtx = new AudioContext();
    // Chrome needs our oscillator node to be attached to the destination
    // So we create a silent Gain Node
    const silence = aCtx.createGain();
    silence.gain.value = 0;
    silence.connect(aCtx.destination);
    this.startStep = new Date().getTime();

    this.stopped = false;

    const onOSCend = () => {
      this.preciseStep = new Date().getTime() - this.startStep;
      const osc = aCtx.createOscillator();
      osc.onended = onOSCend;
      osc.connect(silence);
      osc.start(0);
      this.startStep = new Date().getTime();
      // console.log(aCtx.currentTime, this.freq);
      osc.stop(aCtx.currentTime + this.freq);
      if (this.stopped) {
        osc.onended = () => {
          return;
        };
      } else {
        callback(aCtx.currentTime);
      }
    };
    onOSCend();
  }

  /**
   * Can modify the frequecy during the timer
   * @param frequency
   */
  public setFrequency(frequency: number) {
    this.freq = frequency / 1000;
  }

  /**
   * Can modify the frequecy during the timer
   * @param fps
   */
  public setFps(fps: number) {
    this.freq = 1000 / fps / 1000;
  }

  public stop() {
    // this boolean will stop the oscillator
    this.stopped = true;
  }
}
