export enum FilterVideoType {

    VINTAGE = 'VINTAGE',
    SEPIA = 'SEPIA',
    GRAY_SCALE = 'GRAY_SCALE',
    NONE = 'NONE',
    HIGH_CONTRAST = 'HIGH_CONTRAST',
    BLUE = 'BLUE',
    ROSERT = 'ROSERT',
    VIOLET = 'VIOLET',
    BRIGHT = 'BRIGHT',
    OVERLAY1 = 'OVERLAY1',
    SATURATE = 'SATURATE',
    SHINING = 'SHINING',
    BULLE = 'BULLE',
    AUTUMN = 'AUTUMN',
    STARS = 'STARS',
    AQUA1 = 'AQUA1',
    SNOW_STAR = 'SNOW_STAR',
    HALLOWEEN = 'HALLOWEEN',
    CHRISTMAS_SNOW = 'CHRISTMAS_SNOW',
    SUNSET = 'SUNSET',
    HEART = 'HEART',
    EASTER = 'EASTER',
    PSYCHEDELIC = 'PSYCHEDELIC',
    XRAY = 'XRAY',
    NEON = 'NEON',
    PINKSET = 'PINKSET',
    WEET = 'WEET',
    PEACH = 'PEACH',
    GRINK = 'GRINK',
    AUBURN = 'AUBURN'
}
