<template></template>

<script lang='ts'>
import Component from 'vue-class-component';
import {log} from '@/utils/log';
import {Vue} from 'vue-property-decorator';
import {PushNotificationMessage} from '@/store/integration/integrationModel';
import 'firebase/database';

@Component
/**
 * This component display the foreground push notifications coming from firebase.
 * Currently we don't display push notification when app is visible or focused in browser.
 */
export default class ShowFirebasePushNotification extends Vue {

  public async mounted() {
    try {
      log.debug('Waiting for firebase push notification...');
      await this.onPushedMessage();
    } catch (e) {
      log.error(`Unable to listen for firebase push notification`);
      log.error(e);
    }
  }

  // Callback fired when a new push notification is received
  private onPushedMessage() {
    // @ts-ignore
    return Vue.$messaging.onMessage((payload: PushNotificationMessage) => {
      // We don't do anything if the app is in foreground. But we still need this listener to make firebase-messaging works.
      log.debug(`Foreground Push Message received. ${payload.data?.comment}`);
    }, (error) => {
      log.error('Error while listining for push message ' + error);
    });
  }


}
</script>
