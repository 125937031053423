<template>
  <div class="containerCommentCreator" :class="classLayout">

    <div v-if="!commentVideoRecord && !screenVideoRecord">
      <w-error :message="error"/>
      <div class="textLine">
        <CommentTextCreator :autofocus="commentIDToReply?true:false" :placeholder="placeholder"
                            :size="commentIDToReply?'small':'default'"
                            v-model="valueText"
                            @focus="pauseVideo" @blur="reinitialiseTimer"/>
      </div>
      <div class="optionLine">
        <div class="timeBox" v-if="commentIDToReply===''">{{ humanTime }}</div>
        <div class="fakeLine" v-if="commentIDToReply==='' && totalComment>0"></div>
        <Transition name="zoom">
          <div class="otherMedia" v-show="valueText.trim()===''">
            <CommentVideoCreator :weet="weet" size="small" @click="chooseVideoRecord"/>
            <CommentVideoCreator :weet="weet" size="small" audio-only="true" @click="chooseVideoRecord"/>
            <CommentScreenCreator :weet="weet" size="small" @click="chooseScreenRecord"/>
          </div>
        </Transition>
        <div class="buttonLine">
          <w-button class="buttonCancel" data-test="commentMessageValidationButton" color="text"
                    size="small" :enabled="commentIDToReply|| valueText.length>0"
                    :loading="loading" @click="cancelComment">{{ $t('commentPanelCreator.button.cancel') }}
          </w-button>
          <w-button data-test="commentMessageValidationButton" color="outlined" :enabled="valueText.trim()!==''"
                    size="small"
                    :loading="loading" @click="sendComment">{{ $t('commentPanelCreator.button.send') }}
          </w-button>
        </div>
      </div>
    </div>
    <div v-if="commentVideoRecord ">
      <CommentVideoRecorder :weet="weet" @close="closeVideoRecorder"/>
    </div>
    <div v-if="screenVideoRecord ">
      <CommentScreenRecorder :weet="weet" @close="closeVideoRecorder"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import prettyMilliseconds from 'pretty-ms';
import {
  COMMENT_TO_REPLY,
  CommentParams,
  OPEN_PANEL_REPLY,
  REPLY_COMMENT,
  SEND_COMMENT
} from '@/store/comment/commentAction';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {Weet} from '@/store/weet/weetModel';
import {PLAYER_PLAYING} from '@/store/timeLine/timeLineAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import CommentTextCreator from '@/components/comment/CommentTextCreator.vue';
import CommentVideoCreator from '@/components/comment/CommentVideoCreator.vue';
import CommentScreenCreator from '@/components/comment/CommentScreenCreator.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import CommentVideoRecorder from '@/components/comment/CommentVideoRecorder.vue';
import CommentScreenRecorder from '@/components/comment/CommentScreenRecorder.vue';
import {quickAuthentication} from '@/utils/loginUtil';
import {canEditOrCommentThisWeet} from '@/utils/weetUtil';
import {alertMessage} from '@/utils/dialog';

@Component({
  components: {
    CommentScreenRecorder,
    CommentVideoRecorder, WError, WButton, CommentScreenCreator, CommentVideoCreator, CommentTextCreator
  }
})
export default class CommentPanelCreator extends Vue {
  @Prop()
  public weet!: Weet;
  private loading: boolean = false;
  private error: string = '';

  @Prop({default: ''})
  private commentIDToReply!: string;

  @Prop({default: ''})
  private placeholder!: string;

  private valueText: string = '';

  private commentVideoRecord: boolean = false;
  private screenVideoRecord: boolean = false;

  private timerComment: number = -1;

  get time(): number {
    return store.getters.getTimerPlayer;
  }

  get totalComment(): number {
    return store.getters.getTotalOfComment;
  }


  get classLayout(): string {
    let classes = '';
    if (this.commentIDToReply) {
      classes += ' replyMode';
    } else {
      classes += ' create';
    }

    if (this.commentVideoRecord || this.screenVideoRecord) {
      classes += ' minSize';
    }
    return classes;
  }

  get humanTime(): string {
    const timeToComment = this.timerComment === -1 ? this.time : this.timerComment;
    let labelMilliSencond = prettyMilliseconds(timeToComment, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    if (timeToComment < 1000) {
      labelMilliSencond = '0:00';
    }
    return labelMilliSencond;
  }


  get openPanelReplyID(): string {
    return store.getters.getReplyPanelOpenID;
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get selectedTagID(): string {
    return store.getters.getSelectedTagID;
  }

  @Watch('openPanelReplyID')
  public onCommentReplyChange() {
    if (this.openPanelReplyID) {
      this.commentVideoRecord = false;
      this.screenVideoRecord = false;
    }
  }

  public pauseVideo() {
    this.timerComment = this.time;
    store.dispatch(PLAYER_PLAYING, false);
  }

  public reinitialiseTimer() {
    if (this.valueText.length === 0) {
      this.timerComment = -1;
    }
  }


  public async chooseVideoRecord() {
    await quickAuthentication();
    this.commentVideoRecord = true;
    this.screenVideoRecord = false;
    if (!this.commentIDToReply) {
      store.dispatch(COMMENT_TO_REPLY, '');
      store.dispatch(OPEN_PANEL_REPLY, '');
    }
  }

  public async chooseScreenRecord() {
    await quickAuthentication();
    this.commentVideoRecord = false;
    this.screenVideoRecord = true;
    if (!this.commentIDToReply) {
      store.dispatch(COMMENT_TO_REPLY, '');
      store.dispatch(OPEN_PANEL_REPLY, '');
    }
  }

  public closeVideoRecorder() {
    this.commentVideoRecord = false;
    this.screenVideoRecord = false;
  }


  public cancelComment() {
    this.valueText = '';
    store.dispatch(COMMENT_TO_REPLY, '');
    store.dispatch(OPEN_PANEL_REPLY, '');
  }

  public async sendComment() {
    if (!this.valueText) {
      return;
    }
    await quickAuthentication();

    if (!canEditOrCommentThisWeet(this.weet)) {
      alertMessage(this.$t('commentPanelView.disabled.subtitle').toString(), this.$t('commentPanelView.disabled.title').toString());
      return;
    }
    // if we are in respond mode
    if (this.commentIDToReply) {
      this.replyComment();
    } else {
      this.loading = true;
      const params = new CommentParams(this.weet.weetID, CommentTypeEnum.TEXT, this.timerComment);
      params.text = this.valueText;

      store.dispatch(SEND_COMMENT, params).then((comment) => {
        setActionEvent(ActionEvent.comment_text_sent, {
          player_time: this.time,
          number_of_char: this.valueText.length
        });
        this.valueText = '';
        this.error = '';
        this.loading = false;
        this.timerComment = -1;
      }).catch((err) => {
        this.error = err;
        this.loading = false;
      });
    }
  }

  public replyComment() {
    if (!this.valueText) {
      return;
    }
    this.loading = true;
    const params = new CommentParams(this.weet.weetID, CommentTypeEnum.TEXT);
    params.commentID = this.commentIDToReply;
    params.text = this.valueText;

    store.dispatch(REPLY_COMMENT, params).then(() => {
      setActionEvent(ActionEvent.comment_reply_text_sent, {
        number_of_char: this.valueText.length
      });
      this.valueText = '';
      this.error = '';
      this.loading = false;
    }).catch((err) => {
      this.error = err;
      this.loading = false;
    });
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import '@/scss/gridBreakPoint.scss';

.containerCommentCreator {
  margin: auto;
  position: relative;
  border: solid 1px var(--light1);
  //@extend .shadow1;
  border-radius: 8px;
  padding: 8px;
  transition: all 1s ease;

  .fakeLine {
    border: solid 1px var(--light1);
    position: absolute;
    height: 16px;
    width: 0px;
    bottom: 4px;
    left: 29px;
  }

  &.create {
    margin-top: 16px;
    max-width: 600px;

    &.minSize {
      max-width: 350px;
      box-shadow: none;
      //border: none;
    }

    .otherMedia {
      margin-left: 12px;
    }
  }

  &.replyMode {
    border-left: 0px;
    border-radius: 0px 0px 8px 0px;
    padding-right: 8px;

  }

  .textLine {
    display: flex;

  }

  .otherMedia {
    animation-duration: 0.2s;

    display: flex;

    @include GRID1X {
      display: none;
    }
  }

  .optionLine {
    display: flex;
    align-items: center;

    .timeBox {
      font-size: 12px;
      margin-left: 2px;
      border: solid 1px var(--light2);
      background: white;
      color: var(--light2);
      border-radius: 50px;
      display: inline-block;
      max-height: 20px;
      width: 44px;
      margin-top: -24px;
    }

    .buttonLine {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .buttonCancel {
        margin-right: 8px;
      }

    }
  }
}
</style>
