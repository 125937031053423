import {APP_ENVIRONEMENT} from "@/store/conf/api";
import {SpeachEvent, SpeachEventName, SpeachModification} from "@/speach/speachEvent";
import store from "@/store";


export const isSpeach=()=>{
    return APP_ENVIRONEMENT=="SPEACH"
}

export const sendSilentWeetId=(weetID:string)=>{
    if(isSpeach()) {
        window.parent.postMessage(new SpeachEvent(SpeachEventName.SILENTLY_SEND_WEET_ID, new SpeachModification(weetID, false,store.getters.getTimerRecord)), "*")
    }
}
