<template>
  <div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import store from '@/store';
import WebcamAuthHelperArrow from '@/components/recorder/videoLayout/authLayout/WebcamAuthHelperArrow.vue';

@Component({
  components: {WModal, WIcon}
})
export default class WebcamAuthHelper extends Vue {

  get isWebcamAivailable(): boolean {
    return store.getters.isWebcamAivalable;
  }

  public mounted() {
    if (!this.isWebcamAivailable) {
      this.$buefy.modal.open({
        canCancel: false,
        fullScreen: false,
        width: '100%',
        customClass: 'fullscreenLayoutWebCamAuth',
        component: WebcamAuthHelperArrow
      });
    }
  }


  public initComponenent() {
    return {};
  }

  private beforeDestroy() {
    // this.$buefy.modal.close();
  }

}
</script>
