export enum VirtualBackgroundEnum {

  // VINTAGE = 'VINTAGE',
  BLUR = 'BLUR',
  NONE = 'NONE',
  BEACH = 'BEACH',
  OFFICE = 'OFFICE',
  OFFICE2 = 'OFFICE2',
  AQUARIUM = 'AQUARIUM',
  CITY = 'CITY',
  BROWN_FOREST = 'BROWN_FOREST',
  RAIN_FOREST = 'RAIN_FOREST',
  SAN_FRANCISCO = 'SAN_FRANCISCO',
  SPACE = 'SPACE',
  CHRISTMAS = 'CHRISTMAS',
  ATLANTIQUE = 'ATLANTIQUE',
  BLUE = 'BLUE',
  BLUR_1 = 'BLUR_1',
  BLUR_2 = 'BLUR_2',
  BLUR_3 = 'BLUR_3',
  BLUR_4 = 'BLUR_4',
  BLUR_5 = 'BLUR_5',
  BLUR_6 = 'BLUR_6',
  BLUR_7 = 'BLUR_7',
  CLOUD = 'CLOUD',
  DARK_LIQUID = 'DARK_LIQUID',
  DARK_LIQUID_2 = 'DARK_LIQUID_2',
  DARK_LIQUID_3 = 'DARK_LIQUID_3',
  DARK_LIQUID_4 = 'DARK_LIQUID_4',
  DARK_LIQUID_5 = 'DARK_LIQUID_5',
  LIQUID = 'LIQUID',
  LIQUID_2 = 'LIQUID_2',
  PALM = 'PALM',
  PALM_2 = 'PALM_2',
  PINK = 'PINK',
  PINK_2 = 'PINK_2',
  PINK_3 = 'PINK_3',
  PURPLE = 'PURPLE',
  SPACE_2 = 'SPACE_2',
  SPACE_3 = 'SPACE_3',
  SPACE_4 = 'SPACE_4',
  VORTEX = 'VORTEX',
  WATTERFALL_2 = 'WATTERFALL_2',
  WHITE_CURVE = 'WHITE_CURVE',
  WHITE_GEO = 'WHITE_GEO',
  WHITE_GEO_2 = 'WHITE_GEO_2',
  YELLOW_GEO = 'YELLOW_GEO',
  YELLOW_VORTEX = 'YELLOW_VORTEX',

  // IMAGE BACKGROUND
  HOUSE_1 = 'HOUSE_1',
  HOUSE_2 = 'HOUSE_2',
  HOUSE_3 = 'HOUSE_3',

  VB_CUSTOM_SLOT_1 = 'VB_CUSTOM_SLOT_1'
}
