<template>
  <div>
    <PopUpTitle :title="$t('channelSubscriber.create.title')"></PopUpTitle>

    <div class="channelContainer">
      <w-input :placeholder="$t('channelSubscriber.create.placeholder')" v-model="valueNewChannel"
               class="channelNameInput" icon="pound" size="default"
               :maxlength="50"/>
      <div>
        <w-button icon="plus" :loading="loading" :enabled="valueNewChannel" @click="createChannel">{{
            $t('channelSubscriber.create.buttonCreate')
          }}
        </w-button>
      </div>
    </div>

    <w-error :message="error"/>
    <!-- CLOSE BUTTON PANEL -->
    <div class="closePanelButton" @click="close">
      <w-icon icon="close" size="small"/>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WError from '@/components/wrapper/w-error.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WInput from '@/components/wrapper/w-input.vue';
import store from '@/store';
import {Channel} from '@/store/channel/channelModel';
import {inform} from '@/utils/dialog';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {
  CREATE_CHANNEL_TO_WORKSPACE,
  SUBSCRIBE_TO_A_CHANNEL
} from '@/store/channel/channelAction';
import {User} from '@/store/user/userModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';
import delay from 'delay';
import WSwitch from '@/components/wrapper/w-switch.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';

@Component({
  components: {WSwitch, WToolTip, WInput, WIcon, PopUpTitle, CenterCenterPanel, WButton, WError},
})
export default class ChannelSubscriberAdd extends Vue {


  private valueNewChannel: string = '';

  private error: string = '';
  private loading: boolean = false;

  private isPrivate(channel: Channel) {
    return channel.visibilityPolicy === VisibilityChannelPolicy.PRIVATE;
  }

  private mounted() {
    setActionEvent(ActionEvent.channel_open_add);
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }


  private async createChannel() {
    const value = this.valueNewChannel;
    // try to find a channel with the same value
    const channel = store.getters.getChannelByName(value);
    if (channel) {
      this.error = this.$t('channels.addPanel.alreadyExist').toString();
      delay(3500).then(() => {
        this.error = '';
      });
    } else {
      this.loading = true;
      const newChannel = await store.dispatch(CREATE_CHANNEL_TO_WORKSPACE, [value]) as Channel[];
      if (newChannel.length > 0) {
        await this.subscribe(newChannel[0]);
        this.$emit('subscribe', newChannel[0]);
      }
      this.loading = false;
      inform(this.$t('channelSubscriber.create.confirm').toString());
      setActionEvent(ActionEvent.channel_added);
      this.close();
    }
    return true;
  }

  private async subscribe(channel: Channel) {
    await store.dispatch(SUBSCRIBE_TO_A_CHANNEL, {channel, emails: [this.userConnected.login]});
    setActionEvent(ActionEvent.channel_subscribed);
  }


  private close() {
    this.$emit('close');
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/shadows.scss";

.channelContainer {
  margin-top: 32px;
  display: flex;
  width: 100%;

  .channelNameInput {
    flex: 1;
    margin-right: 8px;
  }

}

.closePanelButton {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

</style>
