<template>
  <div>
    <div class="notificationContainer">
      <transition-group name="list-complete"
      >
        <NotificationItem class="list-complete-item" :notification="notification" style="transition: transform 1s"
                          v-for="notification of notifications"
                          :key="notification.id"/>
      </transition-group>
      <div v-if="notifications.length===0">
        {{ $t('notification.panel.empty') }}
      </div>
    </div>

    <infinite-loading v-if="firstLoading"
                      @infinite="debouceInfinite">
      <div slot="no-more"></div>
    </infinite-loading>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Notification, NotificationType} from '@/store/myNotifications/myNotificationModel';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import unionWith from 'lodash/unionWith';

import {
  GET_NEXT_NOTIFICATIONS,
  RESET_NOTIFICATIONS
} from '@/store/myNotifications/myNotificationsAction';
import {User} from '@/store/user/userModel';
import NotificationItem from '@/components/notifications/NotificationItem.vue';
import RegisterFirebaseToken from '@/components/firebaseNotification/RegisterFirebaseToken.vue';
import {log} from '@/utils/log';
import NotificationMarkAsRead from '@/components/notifications/NotificationMarkAsRead.vue';


@Component({
  components: {NotificationItem, WIcon, UserAvatar, NotificationMarkAsRead, RegisterFirebaseToken},
})
export default class NotificationPanem extends Vue {
  private filterUnread: boolean = false;
  private firstLoading: boolean = false;

  private debouceInfinite = debounce((state) => {
    this.infiniteHandler(state);
  }, 250);

  public mounted() {
    store.dispatch(RESET_NOTIFICATIONS);
    store.dispatch(GET_NEXT_NOTIFICATIONS).then(() => {
      this.firstLoading = true;
    });
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }

  get notifications(): Notification[] {
    // remove double
    const notifications = unionWith(Object.freeze(store.getters.getMyNotifications), Object.freeze(store.getters.getUnreadNotification), (a: Notification, b: Notification) => {
      return a.id === b.id;
    });

    return orderBy(notifications, ['viewed', 'created'], ['asc', 'desc']) as Notification[];
  }


  public infiniteHandler(state) {
    let lastCreatedNotification: any = 0;
    const oldNotifications = store.getters.getMyNotifications;
    if (oldNotifications.length > 0) {
      lastCreatedNotification = oldNotifications[oldNotifications.length - 1].created;
    }
    store.dispatch(GET_NEXT_NOTIFICATIONS).then(() => {

      let newLastCreatedNotification: any = 0;
      const newNotifications = store.getters.getMyNotifications;
      if (this.notifications.length > 0) {
        newLastCreatedNotification = newNotifications[newNotifications.length - 1].created;
      }

      if (newLastCreatedNotification === lastCreatedNotification) {
        log.debug('loading complete');
        state.complete();
      }
      state.loaded();
    }).catch(() => {
      state.complete();
    });
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

</style>
