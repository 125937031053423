// function lazyloading (need to keep one import by function please)
const PRIORITY_CLASS = "theme_weet_detection_";
const defaultTheme = () => {
    // @ts-ignore
    import('@/scss/themes/default.scss');
};
const blueTheme = () => {
    // @ts-ignore
    import('@/scss/themes/blue.scss');
};
const darkTheme = () => {
    // @ts-ignore
    import('@/scss/themes/dark.scss');
};
const greenTheme = () => {
    // @ts-ignore
    import('@/scss/themes/green.scss');
};
const redTheme = () => {
    // @ts-ignore
    import('@/scss/themes/red.scss');
};
const yellowTheme = () => {
    // @ts-ignore
    import('@/scss/themes/yellow.scss');
};
const orangeTheme = () => {
    // @ts-ignore
    import('@/scss/themes/orange.scss');
};
const brownTheme = () => {
    // @ts-ignore
    import('@/scss/themes/brown.scss');
};
const pinkTheme = () => {
    // @ts-ignore
    import('@/scss/themes/pink.scss');
}
const purpleTheme = () => {
    // @ts-ignore
    import('@/scss/themes/purple.scss');
}

export enum Theme {
    DEFAULT = 'DEFAULT',
    BLUE = 'BLUE',
    DARK = 'DARK',
    GREEN = 'GREEN',
    RED = 'RED',
    YELLOW = 'YELLOW',
    BROWN = 'BROWN',
    ORANGE = 'ORANGE',
    PINK = 'PINK',
    PURPLE = 'PURPLE'
}

export const loadTheme = (theme: Theme = Theme.DEFAULT) => {
    prioritiseTheme(theme);

    switch (theme) {
        case Theme.DEFAULT: {
            defaultTheme();
            break;
        }
        case Theme.BLUE: {
            blueTheme();
            break;
        }
        case Theme.DARK: {
            darkTheme();
            break;
        }
        case Theme.GREEN: {
            greenTheme();
            break;
        }
        case Theme.RED: {
            redTheme();
            break;
        }
        case Theme.YELLOW: {
            yellowTheme();
            break;
        }
        case Theme.ORANGE: {
            orangeTheme();
            break;
        }
        case Theme.BROWN: {
            brownTheme();
            break;
        }
        case Theme.PINK: {
            pinkTheme();
            break;
        }
        case Theme.PURPLE: {
            purpleTheme();
            break;
        }
    }
    setTimeout(() => {
        setupThemeNode(theme);
    }, 50)
};

const prioritiseTheme = (t: Theme) => {
    const tNode = getThemeNode(t);
    // move node to the end to be last cascade (CSS)
    if (tNode) {
        tNode.parentElement?.appendChild(tNode);
    }
};

const getThemeNode = (t: Theme): Node | undefined => {
    const head = document.querySelector('head');
    if (head) {
        const currentLoad = head.querySelectorAll('#' + PRIORITY_CLASS + t);
        if (currentLoad.length > 0) {
            return currentLoad[0];
        }
    }
    return undefined;
}

const setupThemeNode = async (t: Theme) => {

    if (getThemeNode(t)) {
        return;
    }
    const head = document.querySelector('head');
    if (head) {
        // const elm = process.env.NODE_ENV === 'production' ? 'link' : 'style';
        const listOfStyleElement = head.querySelectorAll('style');
        const listOfLinkElement = head.querySelectorAll('link');
        // reverse the list
        let listOfElement: HTMLElement[] = [];
        for (const el of listOfLinkElement) {
            listOfElement.push(el);
        }
        for (const el of listOfStyleElement) {
            listOfElement.push(el);
        }
        listOfElement = listOfElement.reverse();
        // try to search the theme
        for (const el of listOfElement) {
            // @ts-ignore
            if (el.href) {
                // load the style
                // @ts-ignore
                const result = await fetch(el.href);
                const content = await result.text();
                if (content.indexOf('themeWeetUnloader' + t) > 0) {
                    el.id = PRIORITY_CLASS + t;
                }
            } else {
                if (el.innerText.indexOf('themeWeetUnloader' + t) > 0) {
                    el.id = PRIORITY_CLASS + t;
                }
            }
        }

        // lastNode.id = PRIORITY_CLASS + t;
    }
}
