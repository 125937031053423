<template>
  <div class="mainContainer">
    <Transition name="zoom">
      <img alt="arrow" class="arrowImage" src="@/assets/white_arrow.png" v-show="mustShow"/>
    </Transition>

    <Transition name="slideRight">
      <div class="labelAllowWebcam" v-show="mustShow">
        {{ messageAllow }}
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import store from '@/store';

@Component({
  components: {WModal, WIcon}
})
export default class WebcamAuthHelperArrow extends Vue {
  private mustShow: boolean = false;

  get isWebcamAivailable(): boolean {
    return store.getters.isWebcamAivalable;
  }

  public mounted() {
    this.mustShow = true;
  }

  @Watch('isWebcamAivailable')
  private closeModal() {
    this.$emit('close');
  }

  get messageAllow(): string {
    // this label is not in en.json because it instance programaticaly
    // TODO find a solution
    return 'Give your browser access to the camera and microphone.';
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';

.mainContainer {
  width: 100%;
  height: calc(100vh - 128px);

  .labelAllowWebcam {
    font-size: 32px;
    position: absolute;
    top: 180px;
    max-width: 450px;
    left: 400px;
    color: white;
    animation-delay: 0.2s;
    animation-duration: 0.5s;
  }

  .arrowImage {
    animation-delay: 0.5s;
    animation-duration: 1.5s;
    position: absolute;
    width: 320px;
    top: 32px;
    left: 256px;
    transform: scaleX(-1);
    //bottom: 20%;
    //left: 50%;
  }
}

</style>
