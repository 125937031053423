<template></template>
<script lang='ts'>
import Component from 'vue-class-component';
import {log} from '@/utils/log';
import {Vue} from 'vue-property-decorator';
import {PushNotificationToken} from '@/store/integration/integrationModel';
import store from '@/store';
import {
  REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET,
  SAVE_PUSH_NOTIFICATION_EXTERNAL_ID
} from '@/store/integration/integrationAction';
import {v4 as uuidv4} from 'uuid';
import {PUSH_NOTIFICATION_VAPID_KEY} from '@/store/conf/api';
import 'firebase/database';

@Component
/**
 * This component register the ServiceWorker and the token for firebase.
 */
export default class RegisterFirebaseToken extends Vue {

  public async mounted() {
    try {
      log.debug('Trying to register service-worker and firebase token');
      // This will automatically ask push notification request for user
      await this.initServiceWorkerAndRegisterFirebaseToken();
    } catch (e) {
      log.error(`Unable to init firebase push notification`);
      log.error(e);
    }
  }


  // Register the firebase service worker to handle background push notifications
  private initServiceWorkerAndRegisterFirebaseToken() {
    if ('serviceWorker' in navigator) {
      return navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((registration) => {
            log.debug('Service Worker Registered');
            this.getPushTokenFromFirebase(registration);
          }).catch((error) => {
            log.error('Cannot register service worker ' + error);
            return Promise.reject(error);
          });
    } else {
      log.error('serviceWorker not supported in this browser');
      return Promise.reject('serviceWorker not supported in this browser');
    }
  }


  private getPushTokenFromFirebase(registration: ServiceWorkerRegistration) {
    // @ts-ignore
    Vue.$messaging
        .getToken({serviceWorkerRegistration: registration, vapidKey: PUSH_NOTIFICATION_VAPID_KEY})
        .then(async (currentToken) => {
          if (this.existingTokenInSharedStore(currentToken) === undefined) {
            // On a obtenu un nouveau token de firebase qui n'est pas déjà connu de notre backend
            log.info('New push token detected...');
            const externalToolId = uuidv4();
            const pushTokenPayload = new PushNotificationToken(currentToken, externalToolId);
            if (this.oldPushNotificationToken !== undefined) {
              // On avait déjà un ancien token dans notre store, il faut le supprimer
              await store.dispatch(REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET, this.oldPushNotificationToken);
            }
            await store.dispatch(SAVE_PUSH_NOTIFICATION_EXTERNAL_ID, pushTokenPayload);
          }
        }).catch((err) => {
      log.error('An error occurred while retrieving token. ' + err);
      if (err.code === 'messaging/token-unsubscribe-failed') {
        // Know bug for fcm v7, Retry if this error occurs
        return this.getPushTokenFromFirebase(registration);
      } else {
        return Promise.reject(err);
      }
    });
  }


  /**
   * Check if current token already exist in shared store (state.authModule.pushNotificationTokens).
   * Return the existing token or undefined if token is not already known.
   * @param tokenValue, the token to check
   * @private
   */
  private existingTokenInSharedStore(tokenValue: string): PushNotificationToken | undefined {
    const pushNotificationTokens = store.getters.pushNotificationTokens as PushNotificationToken[];
    if (pushNotificationTokens) {
      return pushNotificationTokens
          .find((token: PushNotificationToken) => token.pushNotificationToken === tokenValue);
    } else {
      return undefined;
    }
  }

  // Return the previous known token in this browser or undefined if no token found
  private get oldPushNotificationToken(): PushNotificationToken | undefined {
    const currentTokenExternalId = store.getters.getCurrentPushTokenExternalID;
    const pushNotificationTokens = store.getters.pushNotificationTokens as PushNotificationToken[];
    if (pushNotificationTokens && currentTokenExternalId) {
      log.debug('Old push token detected with externalID= ' + currentTokenExternalId);
      return pushNotificationTokens
          .find((token: PushNotificationToken) => token.externalId === currentTokenExternalId);
    } else {
      return undefined;
    }
  }

}
</script>
