import {Weet} from '@/store/weet/weetModel';
import {TimeEventMedia} from '@/store/timeLine/timeEvent';
import {getDurationOfTimeLine, getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import {getMediasForWeet, getOwner} from '@/utils/weetUtil';
import {getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import delay from 'delay';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {StaticFilter} from '@/utils/video/videoEffect/StaticFilter';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Media} from '@/store/media/mediaModel';
import {drawCoverImage} from '@/utils/video/canvasUtils';
import prettyMilliseconds from 'pretty-ms';
import {v4 as uuidv4} from 'uuid';
import {PUBLIC_PATH} from "@/store/conf/api";

export class ThumbnailGenerator {
    public static filtersTemplate: any = {};
    private videoMainId: string = 'TGvideoMain';
    private videoSecondId: string = 'TGvideoSecond';

    private videoMain: HTMLVideoElement;
    private videoSecond: HTMLVideoElement;
    private render: OffscreenCanvas;
    private renderSticker: OffscreenCanvas;

    private STICKER_SIZE = 0.35;
    private STICKER_BORDER = 0.07;
    private STICKER_WHITE_LINE = 0.02;

    private borderStickerPX = 0;
    private sizeStickerPX = 0;
    private lineStickerPX = 0;

    constructor() {
        this.videoMain = this.createInvisibleVideo(this.videoMainId);
        this.videoSecond = this.createInvisibleVideo(this.videoSecondId);
        this.render = new OffscreenCanvas(10, 10);
        this.renderSticker = new OffscreenCanvas(10, 10);
    }

    public async loadModel() {
        const response = await fetch(PUBLIC_PATH+'filters/filters.json');
        ThumbnailGenerator.filtersTemplate = await response.json();
    }

    public async generateThumbnailForWeet(weet: Weet, time: number = 1, {
        width = 1280, height = 720, blur, avatarInSticker,
        removeSticker, showTitle, showTime, playIcon, background = null,
    }: {
        width?: number,
        height?: number,
        blur: boolean,
        avatarInSticker: boolean,
        removeSticker: boolean,
        showTitle: boolean,
        showTime: boolean,
        playIcon: boolean,
        background: HTMLImageElement | null
    }): Promise<Blob> {
        if (Object.values(ThumbnailGenerator.filtersTemplate).length === 0) {
            await this.loadModel();
            if (Object.values(ThumbnailGenerator.filtersTemplate).length === 0) {
                throw new Error('Error, model not loaded for thumbnail generator');
            }
        }
        // async load video
        const timeEvent = getTimeEventbeforeTime(weet.timeLine, time, TimeEventType.MEDIA_PLAY);
        if (!timeEvent) {
            throw new Error('No event on the weet for thumbnaul generator');
        }
        const mainMedia = getMediasForWeet(weet, timeEvent.mainMedia.mediaID);
        const secondMedia = getMediasForWeet(weet, timeEvent.secondMedia.mediaID);
        if (!mainMedia) {
            throw new Error('impossible to generate thumbnail, media miss');
        }

        let urlMain = getVideoURLForStatus(mainMedia);
        let urlSecond = secondMedia ? getVideoURLForStatus(secondMedia) : undefined;
        let mainReady = false;
        let secondReady = urlSecond === undefined;

        // check if we need 2 media
        // if (mainMedia.type !== SourceVideoType.WEBCAM) {
        //     if (!secondMedia) {
        //         throw new Error('Need 2 media, but have only one');
        //     }
        // }

        if (mainMedia && !urlMain) {
            throw new Error('No url for main media ');
        }
        if (secondMedia && !urlSecond) {
            throw new Error('No url for second media ');
        }
        urlMain += '#t=' + (time / 1000);
        urlSecond += '#t=' + (time / 1000);

        this.videoMain.oncanplay = () => {
            mainReady = true;
        };
        this.videoSecond.oncanplay = () => {
            secondReady = true;
        };
        this.videoMain.src = urlMain;
        if (urlSecond) {
            this.videoSecond.src = urlSecond;
        }

        // wait loading video
        for (let i = 0; i < 10; i++) {
            await delay(1000);
            if (mainReady && secondReady) {
                break;
            }
        }
        if (!mainReady || !secondReady) {
            throw new Error('Impossible to load vide to generate the thumbnail');
        }
        this.render.height = this.videoMain.videoHeight;
        this.render.width = this.videoMain.videoWidth;
        if (width > 0) {
            this.render.width = width;
        }
        if (height > 0) {
            this.render.height = height;
        }
        if (secondMedia) {
            this.borderStickerPX = this.render.height * this.STICKER_BORDER;
            this.sizeStickerPX = this.render.height * this.STICKER_SIZE;
            this.lineStickerPX = this.render.height * this.STICKER_WHITE_LINE;
        }
        // now start procedure
        const ctx = this.render.getContext('2d');
        if (ctx == null) {
            throw new Error('Impossible to create a render to generate the thumbnail');
        }
        // clear canvas
        ctx.clearRect(0, 0, this.render.width, this.render.height);
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, this.render.width, this.render.height);

        let isBlur = false;
        if (mainMedia.type === SourceVideoType.SCREENCAST) {
            isBlur = blur;
        }
        if (background != null) {
            this.drawBackgroundImage(ctx, background);
        } else {
            // draw background
            this.drawBackground(ctx, timeEvent.mainMedia, mainMedia, isBlur);
        }

        if (secondMedia && !removeSticker) {

            // get Owner
            const owner = getOwner(weet);
            let fallbackURL = '';
            if (owner) {
                fallbackURL = getUserAvatar(owner, 400);
            }
            // draw sticker
            await this.drawSticker(ctx, timeEvent.secondMedia, secondMedia, fallbackURL, avatarInSticker);
            // merge result
            this.mergeSticker(ctx, this.borderStickerPX, this.render.height - this.sizeStickerPX - this.borderStickerPX);
        }

        if (showTitle) {
            this.drawTitle(ctx, weet.title);
        }
        if (showTime) {

            this.drawTime(ctx, getDurationOfTimeLine(weet.timeLine));
        }
        if (playIcon) {
            this.drawPlayIcon(ctx);
        }
        return await this.render.convertToBlob({type: 'image/jpeg', quality: 0.9});
    }

    private drawTitle(ctx: OffscreenCanvasRenderingContext2D, title: string) {


        title = title.toLocaleUpperCase();
        const titleToWrite: string[] = [];
        if (title.length > 30) {
            let numberOfLetter = 0;
            let line = '';
            for (const word of title.split(' ')) {
                line += ' ' + word;
                numberOfLetter += word.length;
                if (numberOfLetter > 25) {
                    titleToWrite.push(line);
                    numberOfLetter = 0;
                    line = '';
                }
            }
            if (line) {
                titleToWrite.push(line.toLocaleUpperCase());
            }
        } else {
            titleToWrite.push(title);
        }
        ctx.font = 'normal small-caps 900 48px Nunito Sans';

        let y = 64;
        const x = 64;
        const height = titleToWrite.length * 64;
        // compute width
        let width = 500; // todo compute
        for (const line of titleToWrite) {
            const lineDescription = ctx.measureText(line);
            if (lineDescription.width > width) {
                width = lineDescription.width;
            }
        }
        ctx.beginPath();
        // @ts-ignore
        ctx.roundRect(x - 16, y - 16, width + 32, height + 32, 32);
        ctx.fillStyle = 'rgba(10, 8, 47, 0.5)';
        ctx.fill();

        for (const line of titleToWrite) {
            y += 48;
            ctx.fillStyle = 'white';
            ctx.fillText(line, x, y);
            y += 16;

        }
    }

    private drawTime(ctx: OffscreenCanvasRenderingContext2D, time: number) {
        const x = ctx.canvas.width - 64;
        const y = ctx.canvas.height - 64;

        let labelMilliSencond = prettyMilliseconds(time, {
            colonNotation: true,
            secondsDecimalDigits: 0
        });
        if (time < 1000) {
            labelMilliSencond = '0:00';
        }


        ctx.font = 'normal small-caps 900 48px Nunito Sans';
        ctx.textAlign = 'right';
        const lineDescription = ctx.measureText(labelMilliSencond);

        ctx.beginPath();
        // @ts-ignore
        ctx.roundRect(x - lineDescription.width - 16, y - 48, lineDescription.width + 32, 64, 16);
        ctx.fillStyle = 'rgba(10, 8, 47, 0.5)';
        ctx.fill();

        ctx.fillStyle = 'white';
        ctx.fillText(labelMilliSencond, x, y);
    }

    private drawPlayIcon(ctx: OffscreenCanvasRenderingContext2D) {

        const width = ctx.canvas.width / 2;
        const height = ctx.canvas.height / 2;
        ctx.beginPath();
        ctx.arc(width, height, 60, 0, 2 * Math.PI, false);
        ctx.shadowColor = 'rgba(27, 57, 84, 0.8)';
        ctx.shadowBlur = 5;
        ctx.fillStyle = 'rgba(255,255,255,1)';
        ctx.fill();

        ctx.beginPath();
        const sizeTriangle = 14;
        ctx.moveTo(width - sizeTriangle + 4, height - sizeTriangle);
        ctx.lineTo(width - sizeTriangle + 4, height + sizeTriangle);
        ctx.lineTo(width + sizeTriangle, height);
        ctx.fillStyle = '#56548d';
        ctx.fill();
    }

    private drawBackgroundImage(ctx: OffscreenCanvasRenderingContext2D,
                                background: HTMLImageElement) {
        ctx.drawImage(background, 0, 0, ctx.canvas.width, ctx.canvas.height);
        // drawCoverImage(ctx, background, 0, 0, 0, 0, 0.5, 0.5);
    }

    private drawBackground(ctx: OffscreenCanvasRenderingContext2D,
                           mediaDescription: TimeEventMedia,
                           media: Media, blur: boolean) {

        const videoMain = this.videoMain;

        ctx.save();
        let filter = '';
        if (mediaDescription.filter && mediaDescription.filter !== FilterVideoType.NONE) {
            filter = this.applyFilter(ThumbnailGenerator.filtersTemplate[mediaDescription.filter] as StaticFilter);
        }
        if (blur && filter.indexOf('blur') === -1) {
            filter += ' blur(4px) ';
        }
        ctx.filter = filter;

        // draw image
        const mirror = media.type === SourceVideoType.WEBCAM;
        if (mirror) {
            ctx.scale(-1, 1);
        }
        videoMain.width = videoMain.videoWidth;
        videoMain.height = videoMain.videoHeight;
        drawCoverImage(ctx, videoMain, 0, 0, 0, 0, 0.5, 0.5, mirror);

        ctx.restore();
        this.drawFilter(ctx, mediaDescription, 0, 0, ctx.canvas.width, ctx.canvas.height, mirror);

    }

    private async drawSticker(ctxRender: OffscreenCanvasRenderingContext2D,
                              mediaDescription: TimeEventMedia,
                              media: Media,
                              urlFallback: string,
                              avatarInSticker: boolean) {
        const videoSecond = this.videoSecond;
        const canvasSticker = this.renderSticker;
        canvasSticker.width = this.sizeStickerPX;
        canvasSticker.height = this.sizeStickerPX;

        const ctx = canvasSticker.getContext('2d');
        if (ctx == null) {
            return;
        }
        ctx.save();
        // draw thumnbail user
        const img = new Image();
        img.crossOrigin = 'anonymous';
        await new Promise((resolve, reject) => {
            img.src = urlFallback;
            img.onload = resolve;
        });


        if (!avatarInSticker) {
            if (mediaDescription.filter && mediaDescription.filter !== FilterVideoType.NONE) {
                ctx.filter = this.applyFilter(ThumbnailGenerator.filtersTemplate[mediaDescription.filter] as StaticFilter);
            }
        }


        drawCoverImage(ctx, img, 0, 0, 0, 0, 0.5, 0.5);


        // draw image
        const mirror = media && media.type === SourceVideoType.WEBCAM;
        if (mirror) {
            if (!avatarInSticker) {
                // now draw thumnbnail video
                ctx.scale(-1, 1);
                videoSecond.width = videoSecond.videoWidth;
                videoSecond.height = videoSecond.videoHeight;
                drawCoverImage(ctx, videoSecond, 0, 0, 0, 0, 0.5, 0.5, true);

            }
        }
        ctx.restore();
        if (!avatarInSticker) {
            this.drawFilter(ctx, mediaDescription, 0, 0, this.sizeStickerPX, this.sizeStickerPX, mirror);
        }

    }

    private mergeSticker(ctx: OffscreenCanvasRenderingContext2D, x: number, y: number) {
        const width = this.sizeStickerPX;
        const height = this.sizeStickerPX;
        const canvasSticker = this.renderSticker;
        // shape on canvas
        ctx.save();
        // @ts-ignore
        ctx.roundRect(x, y, width, height, [this.sizeStickerPX, this.sizeStickerPX, this.sizeStickerPX, 40]);
        ctx.lineWidth = this.lineStickerPX;
        ctx.strokeStyle = 'rgb(255,255,255)';
        ctx.stroke();
        ctx.clip();
        ctx.drawImage(canvasSticker, x, y);
        ctx.restore();
    }

    private applyFilter(filter: StaticFilter): string {
        let filterString = '';
        if (filter.sepia !== undefined) {
            filterString += 'sepia(' + filter.sepia + '%) ';
        }
        if (filter.grayscale !== undefined) {
            filterString += 'grayscale(' + filter.grayscale + '%) ';
        }
        if (filter.contrast !== undefined) {
            filterString += 'contrast(' + filter.contrast + '%) ';
        }
        if (filter.saturate !== undefined) {
            filterString += 'saturate(' + filter.saturate + '%) ';
        }
        if (filter.brightness !== undefined) {
            filterString += 'brightness(' + filter.brightness + '%) ';
        }
        if (filter.blur !== undefined) {
            filterString += 'blur(' + filter.blur + 'px) ';
        }
        if (filter.invert !== undefined) {
            filterString += 'brightness(' + filter.invert + '%) ';
        }
        if (filter.hueRotate !== undefined) {
            filterString += 'brightness(' + filter.hueRotate + '%) ';
        }
        return filterString;
    }

    private drawFilter(ctx: OffscreenCanvasRenderingContext2D, mediaDescription: TimeEventMedia, x: number, y: number, width: number, height: number, mirror: boolean = false) {

        ctx.save();
        // add filter
        if (mediaDescription && FilterVideoType.NONE.toString() !== mediaDescription.filter) {

            // manage filter
            if (!mediaDescription.filter || mediaDescription.filter === FilterVideoType.NONE) {
                return;
            }

            const filter = ThumbnailGenerator.filtersTemplate[mediaDescription.filter] as StaticFilter;
            if (!filter) {
                return;
            }

            // apply filter (contrast, brightness etc..)
            if (filter.mask) {

                // apply mixBlendMode
                if (filter.mask.mixBlendMode) {
                    // @ts-ignore
                    ctx.globalCompositeOperation = filter.mask.mixBlendMode;
                }
                // apply linearbakcground on mask
                if (filter.mask.linearGradient && filter.mask.linearGradient.length > 1) {
                    const grd = ctx.createLinearGradient(0, 0, width, height);
                    let listColor = filter.mask.linearGradient;
                    if (mirror) {
                        listColor = listColor.reverse();
                    }
                    for (let i = 0; i < listColor.length; i++) {
                        grd.addColorStop(i, listColor[i]);
                    }
                    ctx.fillStyle = grd;
                    // ctx.fillStyle="black";
                    ctx.fillRect(x, y, width, height);
                }
                // apply radialBackground
                if (filter.mask.radialGradient && filter.mask.radialGradient.length > 1) {
                    const grd = ctx.createRadialGradient(width / 2, height / 2, width, height, width, height);
                    for (let i = 0; i < filter.mask.radialGradient.length; i++) {
                        grd.addColorStop(i, filter.mask.radialGradient[i]);
                    }
                    ctx.fillStyle = grd;
                    ctx.fillRect(x, y, width, height);
                }
            }
        }
        ctx.restore();
    }

    private createInvisibleVideo(id: string): HTMLVideoElement {
        let video = document.getElementById(id) as HTMLVideoElement;
        if (video) {
            video.remove();
        }
        video = document.createElement('video');
        video.id = id;
        video.style.zIndex = '-1';
        video.style.position = 'absolute';
        video.style.top = '0';
        video.style.opacity='0';
        video.style.left = '0';
        video.muted = true;
        video.controls = true;
        video.playsInline = true;
        video.crossOrigin = 'anonymous';
        document.body.append(video);
        return video;
    }


    public destroy() {
        let video = document.getElementById(this.videoMainId) as HTMLVideoElement;
        if (video) {
            video.remove();
        }
        video = document.getElementById(this.videoSecondId) as HTMLVideoElement;
        if (video) {
            video.remove();
        }
    }

}
