<template>
  <div class="blueLayout" :class="size">
    <w-icon icon="shimmer" size="small" color="white" class="iconUpgradeplan"/>
    <div class="labelUpgrade">{{ $t('generic.upgrade') }}</div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';

@Component({
  components: {WIcon, WToolTip, WButton},
})
export default class UpgradePlanTag extends Vue {

  @Prop({default: 'default'})
  private size!: string;
}
</script>
<style scoped lang="scss">

.blueLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px 2px 8px;
  background: var(--secondary);
  border-radius: 10px;

  .iconUpgradeplan {
    margin-right: 4px;
  }

  .labelUpgrade {
    font-weight: 900;
    font-size: 13px;
    color: white;
  }

  &.small {
    padding: 2px 8px 2px 8px;

    .labelUpgrade {
      padding-top: 1px;
      font-weight: 900;
      font-size: 10px;
    }
  }

  &.mini {
    padding: 2px 8px 2px 8px;
    width: 28px;

    .labelUpgrade {
      display: none;
    }
  }
}

</style>
