import axios from 'axios';
import {
    PREVIEW_MODE,
    SELECT_MODE,
    SET_VOICE_OVER_PREVIEW,
    TIMELINE_ADVANCED_MODIFIED
} from '@/store/advancedEditing/advancedEditingAction';
import {AdvancedEditingMode} from '@/enum/AdvancedEditingMode';
import stat = FS.stat;


export default {
    state: {
        mode: AdvancedEditingMode.NONE,
        advancedEditingModified: false,
        previewMode: true, // preview for the cut mode
        voiceOverPreviewMode:false
    },
    mutations: {
        [SELECT_MODE]: (state: any, mode: AdvancedEditingMode) => {
            state.mode = mode;
        },
        [TIMELINE_ADVANCED_MODIFIED]: (state: any, value: boolean) => {
            state.advancedEditingModified = value;
        },
        [PREVIEW_MODE]: (state: any, value: boolean) => {
            state.previewMode = value;
        },
        [SET_VOICE_OVER_PREVIEW]: (state: any, value: boolean) => {
            state.voiceOverPreviewMode = value;
        },
    },
    getters: {
        getAdvancedEditingMode: (state: any): AdvancedEditingMode => state.mode,
        isPreviewMode: (state: any): boolean => state.previewMode,
        isCutMode: (state: any): boolean => state.mode === AdvancedEditingMode.CUT,
        isThumbnailMode: (state: any): boolean => state.mode === AdvancedEditingMode.THUMBNAIL,
        isInteractionMode:(state:any):boolean=> state.mode === AdvancedEditingMode.INTERACTION,
        isCutterMode: (state: any): boolean => state.mode === AdvancedEditingMode.CUTTER,
        isChapterMode: (state: any): boolean => state.mode === AdvancedEditingMode.CHAPTER,
        isMergeMode: (state: any): boolean => state.mode === AdvancedEditingMode.MERGE,
        isAdvancedEditingModified: (state: any): boolean => state.advancedEditingModified,
        isSubtitleMode: (state: any): boolean => state.mode === AdvancedEditingMode.SUBTITLE,
        isVoiceOverMode: (state: any): boolean => state.mode === AdvancedEditingMode.VOICE_OVER,
        isVoiceOverPreviewMode:(state:any):boolean=>state.voiceOverPreviewMode,
    },
    actions: {
        [SELECT_MODE]({commit, dispatch}: { commit: any, dispatch: any },
                      mode: AdvancedEditingMode) {
            commit(SELECT_MODE, mode);
        },
        [TIMELINE_ADVANCED_MODIFIED]({commit, dispatch}: { commit: any, dispatch: any },
                                     value: boolean) {
            commit(TIMELINE_ADVANCED_MODIFIED, value);
        },
        [PREVIEW_MODE]({commit, dispatch}: { commit: any, dispatch: any },
                       value: boolean) {
            commit(PREVIEW_MODE, value);
        },
        [SET_VOICE_OVER_PREVIEW]({commit, dispatch}: { commit: any, dispatch: any },
                       value: boolean) {
            commit(SET_VOICE_OVER_PREVIEW, value);
        }
    },
};
