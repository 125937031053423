<template>
  <div>
    <div class="button2" :key="'button2'"
    >
      <w-button data-test="recorderWebcamMuteButton" :enabled="enabled && !isWebcamFull && !isWebcamIntegration"
                @click="switchVideoMute"
                class="buttonRecorder"
                :icon="videoMuteIcon" size="small"
                color="text"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {
  RECORD_STICKER_FILTER,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_MUTE,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIDEO_MUTE,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {inform} from '@/utils/dialog';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';


@Component({
  components: {WButton, VideoSource},
  computed: {},
})
export default class StickerVideoMuteButton extends Vue {
  @Prop({default: 'right'})
  public toolTipPosition!: string;

  public enabled: boolean = true;


  get isWebcamFull() {
    return store.getters.getMainRecordSource === SourceVideoType.WEBCAM;
  }

  get isWebcamIntegration() {
    return store.getters.getMainRecordSource === SourceVideoType.SCREENCAST && store.getters.getWebCamIntegration !== WebCamIntegrationTypeEnum.NONE;
  }

  get videoMuteIcon(): string {
    if (store.getters.isStickerVideoMute) {
      return 'video-off-outline';
    } else {
      return 'video-outline';
    }
  }


  public switchVideoMute() {
    this.enabled = false;
    setTimeout(() => {
      this.enabled = true;
    }, 2000);
    if (store.getters.isStickerVideoMute) {
      store.dispatch(RECORD_STICKER_POSITION, StickerPosition.BOTTOM_LEFT);
      setActionEvent(ActionEvent.record_button_unmute_video);
      inform(this.$t('stickers.button.webcam.toast.on').toString(), 'top', 'light');
    } else {
      setActionEvent(ActionEvent.record_button_mute_video);
      inform(this.$t('stickers.button.webcam.toast.off').toString(), 'top', 'light');
    }
    store.dispatch(RECORD_STICKER_VIDEO_MUTE);
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';


</style>
