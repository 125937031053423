<template>
  <Transition v-if="startRender && animation" :name="nameAnim">
    <slot v-show="startAnim"></slot>
  </Transition>
  <div v-else>
    <slot v-if="startRender"></slot>
  </div>
</template>

<script lang="ts">
  import {Component, Prop, Vue} from 'vue-property-decorator';

  @Component(
    {})
  export default class DelayAnim extends Vue {
    @Prop({default: 100})
    private delay!: number;
    @Prop({default: 'fade'})
    private nameAnim!: string;

    @Prop({default: true})
    private animation!: boolean;


    private startRender: boolean = false;
    private startAnim: boolean = false;

    public mounted() {
      setTimeout(() => {
        this.startRender = true;
        if (this.animation) {
          this.$nextTick(() => {
            this.startAnim = true;
          });
        }
      }, this.delay);
    }
  }
</script>

<style scoped lang="scss">

</style>
