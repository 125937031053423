<template>
  <div steptour="setting" class="settingPosition">
    <w-button data-test="recorderSettingButton" :enabled="isAivailable" icon="cog-outline" size="small"
              @click="activeSetingSource" color="text"
    ></w-button>
    <w-modal :active.sync="showChooseWC">
      <RecordSettingPanel/>
    </w-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {
  RECORD_STICKER_FILTER,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_MUTE,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIDEO_MUTE,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import RecordSettingPanel from '@/components/recorder/setup/RecordSettingPanel.vue';
import WModal from '@/components/wrapper/w-modal.vue';


@Component({
  components: {WModal, RecordSettingPanel, WButton, VideoSource},
  computed: {},
})
export default class StickerFilterButton extends Vue {
  @Prop({default: 'right'})
  public toolTipPosition!: string;

  private showChooseWC: boolean = false;


  get isAivailable(): boolean {
    return store.getters.isStartRecordingAivailable;
  }

  public activeSetingSource() {
    this.showChooseWC = true;
    setActionEvent(ActionEvent.record_button_setting);
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';


</style>
