import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';

export const SEND_COMMENT = 'SEND_COMMENT';
export const REPLY_COMMENT = 'REPLY_COMMENT';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_REPLY_COMMENT = 'ADD_REPLY_COMMENT';
export const COMMENT_TO_REPLY = 'COMMENT_TO_REPLY';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_REPLY_COMMENT = 'DELETE_REPLY_COMMENT';
export const LIST_COMMENT = 'LIST_COMMENT';
export const LIST_REPLIES = 'LIST_REPLIES';
export const LAST_CREATED_COMMENT = 'LAST_CREATED_COMMENT';
export const RESET_COMMENTMODULE = 'RESET_COMMENTMODULE';
export const REFRESH_MEDIA_COMMENT = 'REFRESH_MEDIA_COMMENT';
export const SHOW_LAST_COMMENT = 'SHOW_LAST_COMMENT';
export const OPEN_PANEL_REPLY = 'OPEN_PANEL_REPLY';


export class CommentParams {
  public time: number = 0;
  public type: CommentTypeEnum;
  public text: string | null = null;
  public imageUrl: string | null = null;
  public mediaID: string | null = null;
  public weetID: string;
  public filter: string | null = null;
  public commentID: string | null = null;

  constructor(weetID: string, type: CommentTypeEnum, time: number = 0, imageUrl: string | null = null, text: string | null = null,
              mediaID: string | null = null) {
    this.imageUrl = imageUrl;
    this.type = type;
    this.mediaID = mediaID;
    this.text = text;
    this.time = time;
    this.weetID = weetID;
  }
}


export class GetCommentParams {
  public page: number;
  public weetID: string;
  public time;

  constructor(weetID: string, page: number = 0) {
    this.weetID = weetID;
    this.page = page;
  }
}

export class GetRepliesParams {
  public page: number;
  public weetID: string;
  public commentID;

  constructor(weetID: string, commentID: string, page: number = 0) {
    this.weetID = weetID;
    this.page = page;
    this.commentID = commentID;
  }
}




