import {CHANGE_TITLE_PAGE, VIEW_PRODUCT_NEWS} from '@/store/appView/appViewAction';


export default {
    state: {
        viewProductNews: false,
        titlePage: ''
    },
    mutations: {
        [VIEW_PRODUCT_NEWS]: (state: any, value: boolean) => {
            state.viewProductNews = value;
        },
        [CHANGE_TITLE_PAGE]: (state: any, value: string) => {
            state.titlePage = value;
        },
    },
    getters: {
        isViewProductNews: (state: any): boolean => {
            return state.viewProductNews;
        },
        titlePage: (state: any): string => {
            if (state.titlePage) {
                return state.titlePage;
            } else {
                return 'Create video tutorials with Weet';
            }
        },
    },
    actions: {
        [VIEW_PRODUCT_NEWS]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            // commit(VIEW_PRODUCT_NEWS, value);
        },
        [CHANGE_TITLE_PAGE]({commit, dispatch}: { commit: any, dispatch: any }, value: string) {
            commit(CHANGE_TITLE_PAGE, value);
        },
    },
};
