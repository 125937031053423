<template>
  <w-tool-tip :label="toolTipMessage"
              :size="tooltipSize"
              :color="tooltipColor"
              :position="tooltipPosition"
              :delay="tooltipDelay">
    <div class="wrapper">

      <transition name="fade">
        <div class="pastille" v-show="isNotificationOnWorkspace"></div>
      </transition>
      <div :class="itemClass" :style="backgroundOfAvatar"
           @click="onClick">
      </div>
    </div>
  </w-tool-tip>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {Notification} from '@/store/myNotifications/myNotificationModel';

@Component({
  components: {WButton, WToolTip},
})
export default class WorkspaceAvatar extends Vue {
  @Prop({default: ''})
  private workspaceAvatar!: string;
  @Prop({default: ''})
  private workspaceName!: string;
  @Prop({default: 'default'})
  private size!: string;
  @Prop({default: false})
  private selected!: boolean;
  @Prop({default: () => []})
  private notifications!: Notification[];
  @Prop({default: null})
  private workspaceID!: string | null;

  @Prop({default: 'top'})
  private tooltipPosition!: string;
  @Prop({default: 'small'})
  private tooltipSize!: string;
  @Prop({default: 'light'})
  private tooltipColor!: string;
  @Prop({default: 0})
  private tooltipDelay!: number;


  get itemClass() {
    return {
      [this.classOfAvatar]: true,
      selected: this.selected,
    };

  }

  get classOfAvatar() {
    return `avatar ${this.size}`;
  }

  get backgroundOfAvatar() {
    const avatar = this.workspaceAvatar || require('@/assets/avatar.jpg');
    return 'background-image: url(' + avatar + ')';
  }

  get toolTipMessage(): string {
    return this.workspaceName || '';
  }

  get isNotificationOnWorkspace(): boolean {
    return this.notifications?.find((notif: Notification) => notif.workspaceID === this.workspaceID) !== undefined;
  }

  private onClick() {
    this.$emit('click');
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.wrapper {
  position: relative;

  &:hover {
    //transform: scale(1.1);
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  }

  .avatar {
    background-size: cover;
    border-radius: 16px;
    width: 48px;
    height: 48px;
    transition: all 0.2s ease;
    border: 1px solid var(--light1);


    &.selected {
      border: 1px solid var(--light);
      box-shadow: 0 0 0 2px var(--primary);
    }

    &.none {
      border-radius: 0px;
      border: none;
    }

    &.xlarge {
      width: 160px;
      height: 160px;
      border-radius: 16px;
    }

    &.large {
      width: 128px;
      height: 128px;
      border-radius: 16px;
    }

    &.medium-large {
      width: 96px;
      height: 96px;
      border-radius: 16px;
    }

    &.medium {
      width: 64px;
      height: 64px;
      border-radius: 16px;
    }

    &.small {
      width: 36px;
      height: 36px;
      border-radius: 12px;
    }

    &.tiny {
      width: 24px;
      height: 24px;
      border-radius: 8px;
    }

    &.mini {
      width: 16px;
      height: 16px;
      border: 1px solid #FFFFFF;
      border-radius: 6px;
    }

    &.none.mini {
      border: 0px solid #FFFFFF;
    }

  }

  .pastille {
    width: 12px;
    height: 12px;
    border: solid 2px #FFF;
    border-radius: 12px;
    background: var(--primary);
    animation-delay: 1s;
    position: absolute;
    top: -3px;
    right: -3px;
    @extend .shadow1;
  }
}
</style>
