import axios from 'axios';
import sortBy from 'lodash/sortBy';

import {BACK_ENDPOINT} from '@/store/conf/api';
import Vue from 'vue';
import {
    ADD_COMMENT,
    ADD_REPLY_COMMENT,
    COMMENT_TO_REPLY,
    CommentParams,
    DELETE_COMMENT,
    DELETE_REPLY_COMMENT,
    GetCommentParams,
    GetRepliesParams,
    LAST_CREATED_COMMENT,
    LIST_COMMENT,
    LIST_REPLIES,
    OPEN_PANEL_REPLY,
    REFRESH_MEDIA_COMMENT,
    REPLY_COMMENT,
    RESET_COMMENTMODULE,
    SEND_COMMENT,
    SHOW_LAST_COMMENT,
} from '@/store/comment/commentAction';
import {CommentEvent} from '@/store/comment/commentModel';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import store from '@/store';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {isMediaNeedRefresh} from '@/utils/util';
import delay from 'delay';
import {REFRESH_MEDIA} from '@/store/media/mediaAction';
import {log} from '@/utils/log';

const commentAPI = BACK_ENDPOINT + '/comment';
export default {
    state: {
        commentsForWeet: {},
        currentPageComment: 0,
        totalResultComment: 0,
        lastCreatedEvent: undefined,
        commentToReply: '',
        openPanelReply: '',
    },
    mutations: {
        [COMMENT_TO_REPLY]: (state: any, commentID: string) => {
            state.commentToReply = commentID;
        },
        [OPEN_PANEL_REPLY]: (state: any, commentID: string) => {
            state.openPanelReply = commentID;
        },
        [ADD_COMMENT]: (state: any, comment: CommentEvent) => {
            Vue.set(state.commentsForWeet, comment.commentID, comment);
        },
        [ADD_REPLY_COMMENT]: (state: any, {commentID, reply}: { commentID: string, reply: CommentEvent }) => {
            // get Comment
            const mainComment = state.commentsForWeet[commentID] as CommentEvent;
            // now we verify if there is no 'double item';
            Vue.set(mainComment.replies, mainComment.replies.length, reply);
            mainComment.numberOfReplies++;
            Vue.set(state.commentsForWeet, commentID, mainComment);
        },
        [DELETE_REPLY_COMMENT]: (state: any, {commentID, replyTo}: { commentID: string, replyTo: string }) => {
            const mainComment = state.commentsForWeet[replyTo] as CommentEvent;
            Vue.delete(state.commentsForWeet, replyTo);
            // now we reconstruc the comment
            const arrayOfReplies: CommentEvent[] = [];
            for (const replyComment of Object.values(mainComment.replies) as CommentEvent[]) {
                if (replyComment.commentID !== commentID) {
                    Vue.set(arrayOfReplies, arrayOfReplies.length, replyComment);
                }
            }
            mainComment.replies = arrayOfReplies;
            mainComment.numberOfReplies--;
            Vue.set(state.commentsForWeet, replyTo, mainComment);
        },
        [DELETE_COMMENT]: (state: any, commentID: string) => {
            Vue.delete(state.commentsForWeet, commentID);
        },

        [LAST_CREATED_COMMENT]: (state: any, comment: CommentEvent) => {
            state.lastCreatedEvent = comment;
        },
        [LIST_COMMENT]: (state: any, paginationWrapper: PaginationWrapper) => {
            state.totalResultComment = paginationWrapper.count;
            state.currentPageComment = paginationWrapper.page;
            // reset de la list if page 0
            if (paginationWrapper.page === 0) {
                state.commentsForWeet = {};
            }
            const comments = paginationWrapper.data as CommentEvent[];
            let comment: CommentEvent;
            for (const i in comments) {
                if (comments[i] !== undefined) {

                    comment = comments[i];
                    Vue.set(state.commentsForWeet, comment.commentID, comment);
                }
            }
        },
        [LIST_REPLIES]: (state: any, paginationWrapper: PaginationWrapper) => {
            const comments = paginationWrapper.data as CommentEvent[];
            // get main Comment
            if (comments.length === 0 && comments[0].replyToCommentID === null) {
                return;
            }
            // @ts-ignore the nullable is control with the return
            const mainComment = state.commentsForWeet[comments[0].replyToCommentID];
            for (const comment of comments) {
                mainComment.replies.push(comment);
            }
            Vue.set(state.commentsForWeet, mainComment.commentID, mainComment);
        },
        [RESET_COMMENTMODULE]: (state: any) => {
            state.commentToReply = '';
            state.commentsForWeet = {};
            state.currentPageComment = 0;
            state.totalResultComment = 0;
            state.lastCreatedEvent = undefined;
        },

    },
    getters: {
        getComments: (state: any): CommentEvent[] => {
            return sortBy(Object.values(state.commentsForWeet), ['time', 'created']);
        },
        getCommentsForID: (state: any) => (commentID: string): Comment => {
            return state.commentsForWeet[commentID];
        },
        getAgregateComments: (state: any): CommentEvent[] => {

            // merge comment emote
            const output: CommentEvent[] = [];
            const TAMPON_AGREGATE_EMOTE = 3;
            let lastInsertCommment: CommentEvent | undefined;
            let prevComment: CommentEvent | undefined;
            let timeToProcessed = 0;
            const commentToProceed = store.getters.getComments;
            for (const comment of commentToProceed) {
                if (comment.type === CommentTypeEnum.EMOTE) {
                    comment.repeatsComment = [];
                    // if comment before was already the same emote
                    if (prevComment && prevComment.type === CommentTypeEnum.EMOTE &&
                        (prevComment.user && comment.user && prevComment.user.userID === comment.user.userID) &&
                        Math.round(timeToProcessed / 1000) <= Math.round(comment.time / 1000) + TAMPON_AGREGATE_EMOTE &&
                        Math.round(timeToProcessed / 1000) + TAMPON_AGREGATE_EMOTE > Math.round(comment.time / 1000)
                    ) {
                        // if already same time we just increment the repeat attribute
                        if (lastInsertCommment) {
                            if (!lastInsertCommment.repeatsComment) {
                                lastInsertCommment.repeatsComment = [];
                            }
                            lastInsertCommment.repeatsComment.push(comment);
                        }
                    } else {
                        lastInsertCommment = comment;
                        timeToProcessed = comment.time;
                        output.push(comment);
                    }
                } else {
                    lastInsertCommment = comment;
                    timeToProcessed = comment.time;
                    output.push(comment);
                }
                prevComment = comment;
            }
            return output;
        },
        getCurrentPageComment: (state: any): number => {
            return state.currentPageComment;
        },
        getTotalOfComment: (state: any): number => {
            return state.totalResultComment;
        },
        getLastCreatedEvent: (state: any): CommentEvent => {
            return state.lastCreatedEvent;
        },
        getCommentIDToReply: (state: any): string => {
            return state.commentToReply;
        },
        isCommentSelectedExist: (state: any, getters: any): boolean => {
            // if id but no comment, the comment is deleted
            let commentSelected;
            if (getters.getReplyPanelOpenID) {
                // searchMainComment
                const mainComment = getters.getCommentsForID(getters.getCommentIDToReply);
                // if no main comment the reply as desapear
                if (mainComment) {
                    // if it the same ID we are in creation mode.
                    // So this is the creation mode
                    if (mainComment.commentID === getters.getReplyPanelOpenID) {
                        commentSelected = mainComment;
                    } else {
                        for (const reply of mainComment.replies) {
                            if (reply.commentID === getters.getReplyPanelOpenID) {
                                commentSelected = reply;
                                break;
                            }
                        }
                    }
                }
            } else if (getters.getCommentIDToReply) {
                commentSelected = getters.getCommentsForID(getters.getCommentIDToReply);
            }
            if (commentSelected) {
                return true;
            } else {
                return false;
            }
        },
        getReplyPanelOpenID: (state: any, getters: any): string => {
            return state.openPanelReply;
        }
    },
    actions: {
        async [LIST_COMMENT]({commit, dispatch}: { commit: any, dispatch: any }, paramsGetComment: GetCommentParams) {
            return await axios({
                url: commentAPI + '/' + paramsGetComment.weetID,
                params: {page: paramsGetComment.page},
                method: 'GET',
            }).then((resp) => {
                commit(LIST_COMMENT, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [LIST_REPLIES]({commit, dispatch}: { commit: any, dispatch: any }, params: GetRepliesParams) {
            return await axios({
                url: commentAPI + '/' + params.weetID + '/' + params.commentID + '/replies',
                params: {page: params.page},
                method: 'GET',
            }).then((resp) => {
                commit(LIST_REPLIES, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SEND_COMMENT]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                             commentParams: CommentParams) {
            // get weet in player
            const weetId = commentParams.weetID;

            return await axios({
                url: commentAPI + '/' + weetId,
                data: {
                    type: commentParams.type,
                    time: commentParams.time,
                    text: commentParams.text,
                    filter: commentParams.filter,
                    imageUrl: commentParams.imageUrl,
                    mediaID: commentParams.mediaID,
                },
                method: 'POST',
            }).then(async (resp) => {
                commit(ADD_COMMENT, resp.data);
                commit(OPEN_PANEL_REPLY, '');
                commit(COMMENT_TO_REPLY, resp.data.commentID);
                // now we dispatch the refresh if the comment has to be refresh in case of a media
                dispatch(REFRESH_MEDIA_COMMENT, resp.data).then((refreshComment) => {
                    commit(ADD_COMMENT, refreshComment);
                });
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REPLY_COMMENT]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                              commentParams: CommentParams) {
            // get weet in player
            const weetId = commentParams.weetID;

            return await axios({
                url: commentAPI + '/' + weetId + '/' + commentParams.commentID,
                data: {
                    type: commentParams.type,
                    text: commentParams.text,
                    filter: commentParams.filter,
                    imageUrl: commentParams.imageUrl,
                    mediaID: commentParams.mediaID,
                },
                method: 'POST',
            }).then(async (resp) => {
                // first commit to show in interface
                commit(ADD_REPLY_COMMENT, {commentID: commentParams.commentID, reply: resp.data});
                // e refresh the comment if need
                dispatch(REFRESH_MEDIA_COMMENT, resp.data).then((refreshComment) => {
                    // we delete the replie to update it
                    commit(DELETE_REPLY_COMMENT, {
                        commentID: refreshComment.commentID,
                        replyTo: commentParams.commentID
                    });
                    // and update this comment;
                    commit(ADD_REPLY_COMMENT, {commentID: commentParams.commentID, reply: refreshComment});
                });


                // set the new comment to inform the new ID of comment
                commit(OPEN_PANEL_REPLY, resp.data.commentID);
                // we close the panel after a litle time
                setTimeout(() => {
                    commit(OPEN_PANEL_REPLY, '');
                }, 1000);

            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DELETE_COMMENT]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                               {
                                   weetID,
                                   commentID,
                                   replyTo
                               }: { weetID: string, commentID: string, replyTo: string | null }) {
            if (replyTo) {
                // get weet in player
                commit(DELETE_REPLY_COMMENT, {commentID, replyTo});
            } else {
                // get weet in player
                commit(DELETE_COMMENT, commentID);
            }
            return await axios({
                url: commentAPI + '/' + weetID + '/' + commentID,
                method: 'DELETE',
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        [COMMENT_TO_REPLY]({
                               commit,
                               dispatch,
                               getters
                           }: { commit: any, dispatch: any, getters: any }, commentID: string) {
            commit(COMMENT_TO_REPLY, commentID);
        },
        [OPEN_PANEL_REPLY]({
                               commit,
                               dispatch,
                               getters
                           }: { commit: any, dispatch: any, getters: any }, commentID: string) {
            commit(OPEN_PANEL_REPLY, commentID);
        },
        [RESET_COMMENTMODULE]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            commit(RESET_COMMENTMODULE);
        },
        [SHOW_LAST_COMMENT]({
                                commit,
                                dispatch,
                                getters
                            }: { commit: any, dispatch: any, getters: any }, commentID: string) {
            const comment = getters.getCommentsForID(commentID);
            if (comment) {
                commit(LAST_CREATED_COMMENT, comment);
            }
        },
        async [REFRESH_MEDIA_COMMENT]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                      comment: CommentEvent) {
            const MAX_ITERATION = 10;
            if (comment.media) {
                let media = comment.media;
                if (isMediaNeedRefresh(media)) {
                    let iteration = 0;
                    while (isMediaNeedRefresh(media)) {
                        if (iteration >= MAX_ITERATION) {
                            // TODO ? must show an error or not? because it's long
                            log.error('Media not ready after ' + (MAX_ITERATION * 2) + 's');
                            return comment;
                        }
                        log.info('Start refresh media on comment ' + comment.commentID);
                        await delay(2000);
                        media = await store.dispatch(REFRESH_MEDIA, media);
                        iteration++;
                    }
                    log.info('Media is fresh 👍 ');
                    // clone to avoid mutation problem
                    comment = JSON.parse(JSON.stringify(comment));
                    comment.media = media;
                    return comment;
                }
            }
            return comment;
        },
    },

};
