<template>
  <div>
    <div class="buttonBar" v-if="withButton">
      <w-button  v-if="!isRecording"  color="outlined" @click="startRecording">
        <w-icon icon="record"></w-icon>
      </w-button>
      <w-button  v-if="isRecording"  color="outlined" @click="stopRecording">
        <w-icon icon="stop"></w-icon>
      </w-button>

    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop} from "vue-property-decorator";
import delay from "delay";
// @ts-ignore
import RecordRTC from 'recordrtc';
import Vue from "vue";
import WButton from "@/components/wrapper/w-button.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import {alertError} from "@/utils/dialog";


@Component({
      components: {WIcon, WButton},
    }
)
export default class MediaRecorder extends Vue {

  @Prop({default: 3})
  private countdown!: number;

  @Prop({default: false})
  private audioOnly!: boolean

  @Prop({default: true})
  private withButton!: boolean;

  private currentCountDown = 0;

  private recordRTC: RecordRTC | null = null;

  private isRecording = false;

  private timeRecorded:number=0;

  private currentStream:MediaStream|null=null

  private cancelAsked:boolean=false

  private isPause=false;

  mounted() {
    this.currentCountDown = this.countdown
  }

  public async startRecording():Promise<void> {
    this.currentCountDown=this.countdown;
    this.cancelAsked=false;
    // Get authorisation
    try {
      const mic = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        }
      });
      if (!mic) {
        this.onError(this.$t('mediaRecorder.mic.error').toString())
      }
    } catch (e: any) {
      this.onError(this.$t('mediaRecorder.mic.error').toString());
    }
    for (let i = this.countdown; i > -1; i--) {
      this.currentCountDown = i;
      this.onCountDown(this.currentCountDown)
      await delay(1000)
      if(this.cancelAsked){
        this.cancelAsked=false;
        return;
      }
    }
    if(this.cancelAsked){
      this.cancelAsked=false;
      return;
    }
    this.record()
  }

  private async record() {
    this.currentStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      }
    });
    this.recordRTC = new RecordRTC(this.currentStream, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=opus',
      disableLogs: true,
      audioBitsPerSecond: 64000,
    })
    this.recordRTC.startRecording()
    this.isRecording = true;
    this.onStart()
    let timeStart=new Date().getTime()
    this.timeRecorded=0;
    while (this.isRecording){
      timeStart = new Date().getTime();
      await delay(500)
      if(!this.isPause) {
        this.timeRecorded += (new Date().getTime() - timeStart);
        this.onTimeRecord(this.timeRecorded)
      }
    }
  }

  public pauseRecording():void{
    this.recordRTC.pauseRecording();
    this.isPause=true;
  }
  public resumeRecording():void{
    this.recordRTC.resumeRecording();
    this.isPause=false;
  }
  public async stopRecording():Promise<void> {
    if(this.isRecording) {
      if (this.isPause){
        this.resumeRecording()
        await delay(100)
      }
      this.recordRTC.stopRecording(async () => {
        const blob = this.recordRTC.getBlob();
        this.recordRTC.destroy()
        this.onBlobRecorded(null, blob)
      });
      this.currentStream?.getTracks().forEach(track => track.stop());
      this.currentStream=null;
      this.isRecording = false;
    }
  }

  public cancel():void{
    this.cancelAsked=true;
  }

  @Emit('onBlobRecorded')
  onBlobRecorded(event: any, blob: Blob): void {
    return
  }

  @Emit('onError')
  onError(message:string){
    alertError(message)
  }

  @Emit("onCountDown")
  onCountDown(counter:number){
    return;
  }
  @Emit("onTimeRecord")
  onTimeRecord(time:number){
    return;
  }
  @Emit('onStart')
  onStart(){
    return;
  }
}
</script>

<style lang="scss" scoped>
.buttonBar {
  width: 100%;
  text-align: center;
}
</style>
