<template>
  <div>
    <router-link :to="navigateTo" channel="a" @click.native="goToWeetOrWorkspace()">
      <div :class="'notificationItemContainer '+classViewed+' '+classMini">
        <div class="avatarBox">
          <UserAvatar type="sticker" v-if="!mini" :user-avatar="imageForUser"/>
          <UserAvatar type="sticker" size="small" v-else :user-avatar="imageForUser"/>
        </div>
        <div class="labelBox">
          <div class="titleBox">

            <w-tool-tip label="Mark as read" position="top">
              <div class="pastille" @click.prevent.stop="readNotification"
                   v-show="!this.notification.viewed && mustShow"></div>
            </w-tool-tip>

            <w-tool-tip label="Mark as unread" position="top">
              <div class="pastilleRead" @click.prevent.stop="unreadNotification"
                   v-show="this.notification.viewed && mustShow"></div>
            </w-tool-tip>

            <div class="userDescription">
              <span class="userName">
                {{ this.nameOfUser }}
              </span>
              {{ labelDescription }}
            </div>
            <div class="commentTime" v-if="this.timeOfNotification">
              @{{ this.timeOfNotification }}
            </div>
          </div>
          <div class="commentContentBox" v-if="this.notification.comment">
            <div v-if="this.notification.comment.commentType===commentType.TEXT" data-hj-suppress
                 v-html="this.commentContent">
            </div>
            <div v-if="this.notification.comment.commentType===commentType.EMOTE">
              <img width="16" alt="emote" :src="this.notification.comment.content"/>
            </div>
          </div>
          <div class="subLine">
            <div class="dateBox">{{ dateTime }}</div>
            <div class="channelsBox">
              <div v-for="channelID of notification.channels" :key="'channelBox'+channelID">
                <ChannelWorkspace :channel="getChannelForId(channelID)" v-if="getChannelForId(channelID)"/>
              </div>
            </div>
          </div>
        </div>
        <div :class="isWorkspaceNotification?'workspaceImage':'weetImage'"
             :style="'background-image:url('+imageNotification+')'">
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Notification, NotificationType} from '@/store/myNotifications/myNotificationModel';
import {getDateAgo, getImageURLForSize, getTabOfColor, getUserAvatar, htmliFy} from '@/utils/util';
import mobileDetect from 'mobile-device-detect';
import UserAvatar from '@/components/user/UserAvatar.vue';
import prettyMilliseconds from 'pretty-ms';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import slugify from '@sindresorhus/slugify';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import store from '@/store';
import {READ_NOTIFICATIONS, UNREAD_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';
import ChannelWorkspace from '@/components/channel/ChannelWorkspace.vue';
import {Channel} from '@/store/channel/channelModel';
import WButton from '@/components/wrapper/w-button.vue';
import {isInviteWorkspace} from '@/utils/notification';


@Component({
  components: {ChannelWorkspace, WToolTip, WIcon, UserAvatar, WButton},
})
export default class NotificationItem extends Vue {
  @Prop()
  private notification!: Notification;
  @Prop({default: false})
  private mini!: boolean;

  private isMobile: boolean = mobileDetect.isMobile;
  private mustShow: boolean = false;
  private loading = false;

  public mounted() {
    this.mustShow = true;
  }


  get dateTime(): string {
    if (this.notification.created) {
      return getDateAgo(this.notification.created);
    } else {
      return '';
    }
  }

  get commentContent(): string {
    if (this.notification && this.notification.comment) {
      return htmliFy(this.notification.comment.content + '');
    } else {
      return '';
    }
  }

  get commentType(): any {
    return CommentTypeEnum;
  }

  get imageForUser(): string {
    if (!this.notification.by || this.notification.by.imageUrl === null
        || this.notification.by.imageUrl === '') {
      return getUserAvatar(undefined, 100);
    } else {
      return this.notification.by.imageUrl;
    }
  }

  get isWorkspaceNotification(): boolean {
    return isInviteWorkspace(this.notification);
  }

  get nameOfUser(): string {
    if (!this.notification.by) {
      return this.$t('generic.anonymous').toString();
    } else {
      return this.notification.by.firstName + ' ' + this.notification.by.lastName;
    }
  }

  get nameOfOtherUser(): string {
    if (!this.notification.other) {
      return this.$t('generic.anonymous').toString();
    } else {
      return this.notification.other.firstName + ' ' + this.notification.other.lastName;
    }
  }

  get classMini(): string {
    if (this.mini) {
      return 'mini';
    } else {
      return '';
    }
  }

  get labelDescription(): string {
    if (this.notification.type === NotificationType.COMMENT_WEET) {
      let time = '0:00';
      if (this.notification.time && this.notification.time > 999) {
        time = prettyMilliseconds(this.notification.time, {colonNotation: true, secondsDecimalDigits: 0});
      }
      return this.$t('notificationItem.description.comment_weet', {
        weetTitle: this.notification.weet?.weetTitle
      }).toString();
    } else if (this.notification.type === NotificationType.VIEW_WEET) {
      return this.$t('notificationItem.description.view_weet', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.SHARE_WEET) {
      return this.$t('notificationItem.description.share_weet', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.INVITE_WEET) {
      return this.$t('notificationItem.description.invite_weet', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.NEW_WEET) {
      return this.$t('notificationItem.description.new_weet', {
        weetTitle: this.notification.weet?.weetTitle,
        workspaceTitle: store.getters.getWorkspace(this.notification.workspaceID)?.name || 'deleted'
      }).toString();
    } else if (this.notification.type === NotificationType.UPDATE_WEET) {
      return this.$t('notificationItem.description.update_weet', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.SHARED_WEET) {
      return this.$t('notificationItem.description.shared_weet', {
        weetTitle: this.notification.weet?.weetTitle,
        newAuthor: this.nameOfOtherUser
      }).toString();
    } else if (this.notification.type === NotificationType.REACT_WEET) {
      return this.$t('notificationItem.description.react_weet', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.REPLY_COMMENT) {
      return this.$t('notificationItem.description.reply_comment', {
        weetTitle: this.notification.weet?.weetTitle,
      }).toString();
    } else if (this.notification.type === NotificationType.INVITE_WORKSPACE) {
      return this.$t('notificationItem.description.invite_workspace', {
        workspaceName: this.notification.workspace?.name
      }).toString();
    }

    return '';
  }

  get timeOfNotification() {
    let time = '';
    if (this.notification.time && this.notification.time > 999) {
      time = prettyMilliseconds(this.notification.time, {colonNotation: true, secondsDecimalDigits: 0});
    }
    return time;
  }

  get color(): string {
    if (this.notification.type === NotificationType.COMMENT_WEET) {
      return getTabOfColor()[0];
    } else if (this.notification.type === NotificationType.VIEW_WEET) {
      return getTabOfColor()[2];
    } else if (this.notification.type === NotificationType.SHARE_WEET) {
      return getTabOfColor()[3];
    } else if (this.notification.type === NotificationType.INVITE_WEET) {
      return getTabOfColor()[3];
    } else if (this.notification.type === NotificationType.NEW_WEET) {
      return getTabOfColor()[3];
    } else if (this.notification.type === NotificationType.UPDATE_WEET) {
      return getTabOfColor()[2];
    } else if (this.notification.type === NotificationType.SHARED_WEET) {
      return getTabOfColor()[3];
    } else if (this.notification.type === NotificationType.REACT_WEET) {
      return getTabOfColor()[1];
    } else if (this.notification.type === NotificationType.REPLY_COMMENT) {
      return getTabOfColor()[0];
    }
    return getTabOfColor()[0];
  }

  get navigateTo(): any {
    if (this.isWorkspaceNotification) {
      return this.goToWorkspaceInviteParams;
    } else {
      return this.getGoToWeetParams;
    }
  }

  get getGoToWeetParams(): any {
    if (this.notification.weet?.weetLink) {
      if (this.notification.weet.weetID) {
        let title = 'title';
        if (this.notification.weet.weetTitle) {
          title = this.notification.weet.weetTitle;
        }
        const commentID = this.notification.commentID ? this.notification.commentID : '';
        const commentToReply = this.notification.replyToCommentID ? this.notification.replyToCommentID : '';
        return {
          name: 'PreviewWeet',
          params: {
            weetID: this.notification.weet.weetID,
            workspaceID: this.notification.workspaceID,
            channels: this.notification.channels,
            title: slugify(title),
            time: this.notification.time + '',
            commentID: commentID,
            commentToReply: commentToReply,
          },
        };
      }
    }
    return {}
  }

  get goToWorkspaceInviteParams(): any {
    if (this.notification.workspace?.workspaceID) {
      return {
        name: 'MyWorkspaceInvite',
        params: {
          workspaceID: this.notification.workspace?.workspaceID,
        },
      };
    }
    return {}
  }

  private getChannelForId(channelId: string): Channel | undefined {
    return store.getters.getChannel(channelId);
  }

  public goToWeetOrWorkspace() {
    setActionEvent(ActionEvent.notification_click, {type: this.notification.type});
  }

  get imageNotification(): string {
    if (this.isWorkspaceNotification && this.notification.workspace?.imageURL) {
      return getImageURLForSize(this.notification.workspace?.imageURL, 128) || '/avatar.jpg';
    } else if (this.notification.weet?.weetThumbnail) {
      return this.notification.weet?.weetThumbnail;
    } else {
      return '';
    }
  }

  get classViewed(): string {
    if (this.notification.viewed) {
      return 'viewed';
    } else {
      return '';
    }
  }


  private readNotification() {
    store.dispatch(READ_NOTIFICATIONS, [this.notification]);
  }

  private unreadNotification() {
    store.dispatch(UNREAD_NOTIFICATIONS, [this.notification]);
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import '@/scss/gridBreakPoint.scss';

.notificationItemContainer {
  position: relative;
  border-bottom: solid 1px var(--light);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  background: white;

  &.viewed {
    // opacity: 0.7;
  }

  &:hover {
    opacity: 1;
    //  @extend .shadow1;
    border-bottom: solid 1px var(--light1);
  }


  .avatarBox {
    padding: 8px;
    position: relative;
    @include GRID1X {
      padding: 4px;
    }
    @include GRID2X {
      padding: 4px;
    }
  }

  &.mini {
    .avatarBox {
      padding: 4px;
    }
  }


  .labelBox {
    padding: 8px;
    flex: 1;

    .titleBox {
      display: flex;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: var(--dark);

      .userName {
        color: var(--black);
        margin-right: 0px;
        font-weight: 700;
      }

      .commentTime {
        margin-left: 8px;
        color: var(--black);
      }

      .pastille {
        animation-duration: 0.5s;
        background: var(--primary);
        border: solid 2px #FFF;
        width: 16px;
        height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
        border-radius: 12px;
        right: 8px;
        top: 8px;
        align-self: flex-start;
        margin-top: 2px;
        @extend .shadow1;
        margin-right: 8px;
      }

      .pastilleRead {
        animation-duration: 0.5s;
        background: var(--white);
        border: solid 2px var(--light1);
        width: 16px;
        height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
        border-radius: 12px;
        right: 8px;
        top: 8px;
        align-self: flex-start;
        margin-top: 2px;
        @extend .shadow1;
        margin-right: 8px;
      }

      @include GRID1X {
        font-size: 12px;
        padding: 4px;
      }
      @include GRID2X {
        font-size: 14px;
        padding: 4px;
      }
    }

    .commentContentBox {
      font-size: 14px;
      margin-top: 8px;
      text-align: left;
      font-weight: 600;
      color: var(--dark);
      font-style: italic;
    }

    &.mini {
      .commentContentBox {
        font-size: 12px;
      }
    }


    .subLine {
      display: flex;

      .dateBox {
        margin-top: 4px;
        text-align: left;
        color: var(--light2);
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        @include GRID1X {
          font-size: 10px;
        }
        @include GRID2X {
          font-size: 10px;
        }
      }

      .channelsBox {
        display: flex;
        margin-left: 16px;
      }
    }
  }

  &.mini {
    .labelBox {
      font-size: 14px;
      min-width: 500px;
    }
  }


  .weetImage {
    overflow: hidden;
    width: 128px;
    height: 80px;
    margin: 8px;
    margin-top: 16px;
    border-radius: 4px 4px 4px 0px;
    background-size: cover;
    @extend .shadow1;
    @include GRID1X {
      display: none;
    }
    @include GRID2X {
      display: none;
    }
  }

  .workspaceImage {
    overflow: hidden;
    width: 48px;
    height: 48px;
    margin: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 12px;
    background-size: contain;
    @extend .shadow1;
    @include GRID1X {
      display: none;
    }
    @include GRID2X {
      display: none;
    }
  }

  &.mini {
    .weetImage {
      display: none;
    }

    .workspaceImage {
      display: none;
    }
  }

}
</style>
