<template>
  <div class="timelineElement" v-show="mustShow" :class="classOfElement">
    <div class="text">
      {{ type }}<br/>
      {{ time }}<br/>
      <!--      {{mediaID1}}<br/>-->
      <!--      1 | {{ media1Time }}<br/>-->
      <!--      {{mediaID2}}<br/>-->
      <!--      2 | {{ media2Time }}-->
      <br/>


    </div>


  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import prettyMilliseconds from 'pretty-ms';

@Component
export default class TimeLineDebugElement extends Vue {
  @Prop()
  private timeEvent!: TimeEvent;
  private mustShow = false;

  public mounted() {
    this.mustShow = true;
  }

  get classOfElement(): string {
    return this.timeEvent.type;
  }

  get type(): string {
    return this.timeEvent.type;
  }

  get mediaID1(): string {
    return this.timeEvent?.mainMedia?.mediaID;
  }

  get mediaID2(): string {
    return this.timeEvent?.secondMedia?.mediaID;
  }

  get media1Time(): number {
    return this.timeEvent?.mainMedia?.currentTime;
  }

  get media2Time(): number {
    return this.timeEvent?.secondMedia?.currentTime;
  }

  get time(): number {
    return this.timeEvent.time; // prettyMilliseconds(this.timeEvent.time, {colonNotation: true});
  }

}
</script>

<style scoped lang="scss">

.timelineElement {
  position: relative;
  padding: 8px;
  margin-top: 4px;
  border-radius: 4px;
  overflow: hidden;
  min-width: 10px;
  max-width: 130px;
  height: 90px;
  text-align: center;
  animation-duration: 1s;
  box-shadow: inset 2px 2px 10px -5px #000;
  background: white;

  .text {
    font-size: 8px;
  }
}

.FILTER {
  background: #eda800;
}

.POSITION {
  background: #34cc96;
}

.FULLSCREEN {
  background: #7464d1;
}

.NO_SOUND_DETECT {
  background: rgba(255, 255, 0, 1);
}

.SOUND_DETECT {
  background: rgba(255, 255, 0, 0.52);
}

.START_CUT {
  background: rgb(157, 255, 0);
}

.END_CUT {
  background: rgb(157, 255, 0, 0.5);
}

.MEDIA_PLAY, .MEDIA_PAUSE {
  background: #e54c5f;
}
</style>
