<template>
  <transition name="fade">
    <div v-show="mustShow" data-test="commentStickerPlayer" ref="commentStickerPlayer"
         class="commentStickerPlayerContainer" @mousemove="!isMobile?onMouseOver():undefined"
         @click="isMobile?onMouseOver():undefined">

      <!-- MAIN VIDEO PLAYER -->
      <div class="sticker" :style="'background-image: url('+stickerBackground+')'">
        <div class="stickerLayout">
          <video class="videoElement" ref="mainVideo" :src="mediaUrl" @ended="onEndedVideo"
                 @canplay="onCanPlay()" :class="filterClass" @timeupdate="onTimeUpdate()" preload="metadata">
          </video>
          <div class="mask"></div>
          <!-- filter only if play -->
          <div class="layer1"></div>
          <div class="layer2"></div>
          <div class="layer3"></div>
          <div class="layer4"></div>
          <div class="layer5"></div>
        </div>
        <Transition name="fade">
          <div class="controlBar" v-show="!highlight ">
            <!-- timer -->
            <div class="timeContainer" v-if="duration>0 && !play">
              {{ humanDuration }}
            </div>
            <div class="timeContainer" v-if="duration>0 && play">
              {{ humanCountDown }}
            </div>
          </div>
        </Transition>
        <!-- CONTROL BAR -->
        <Transition name="fade" v-if="!noControl">
          <div class="controlBar" v-show="highlight ">
            <!-- volume player -->
            <div class="volumeContainer" v-if="!isMobile" @mouseover="changeVolumeHover=true"
                 @mouseout="changeVolumeHover=false">
              <div @click="muteUnmuteVolume">
                <w-icon :icon="volumeIcon" class="volumeIcon"/>
              </div>
              <b-slider class="volumeSlider" :tooltip="false" v-show="changeVolumeHover" :type="'is-primary'"
                        :max="100" @input="changeVolume" :value="volume" bigger-slider-focus
                        rounded size="is-small"/>
            </div>

            <!-- speed container -->
            <div class="speedContainer" v-if="!isMobile" @click="switchSpeedMode">
              <div class="speed">{{ speedMode }}</div>
            </div>

          </div>
        </Transition>

        <!-- PLAY BUTTOn -->
        <Transition name="fade">
          <div class="playContainer" v-if="!noControl" v-show="highlight || !play"
          >
            <Transition name="fade">
              <div @click.stop="rewind" v-show="highlight && play">
                <w-icon icon="rewind" size="small" class="logoRewind" color="white"/>
              </div>
            </Transition>
            <div @click.stop="onClickPlay">
              <w-icon icon="pause" class="logoPlay" v-show="play" color="white"/>
              <w-icon icon="play" class="logoPlay" v-show="!play" color="white"/>
            </div>
            <Transition name="fade">
              <div @click.stop="forward" v-show="highlight && play">
                <w-icon icon="fast-forward" size="small" class="logoForward" color="white"/>
              </div>
            </Transition>
          </div>
        </Transition>
      </div>

    </div>

  </transition>

</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {getImageURLForSize, getUserAvatar, getVideoURLForStatus} from '@/utils/util';
import {CommentEvent} from '@/store/comment/commentModel';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WButton from '@/components/wrapper/w-button.vue';
import store from '@/store';
import WIcon from '@/components/wrapper/w-icon.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {log} from '@/utils/log';
import prettyMilliseconds from 'pretty-ms';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import mobileDetect from 'mobile-device-detect';
import {CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME} from '@/store/persistedInformation/persistAction';


@Component({
  components: {WIcon, WButton, CenterCenterPanel},
  computed: {},
})
export default class CommentStickerPlayer extends Vue {

  @Prop()
  private comment!: CommentEvent;

  @Prop({default: false})
  private noControl!: boolean;

  private mustShow: boolean = false;
  private play: boolean = false;
  private timer: number = 0;
  private duration: number = 0;
  private changeVolumeHover: boolean = false;
  private highlight: boolean = false;
  private timeTimeOut: number = 1000;
  private timeOutHighlight: any = 0;
  private showSubtitle: boolean = false;

  private isMobile: boolean = mobileDetect.isMobile;


  public mounted() {
    this.mustShow = true;
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  get stickerBackground(): string {
    return getUserAvatar(this.comment.media?.creator, 256);
  }

  get volume(): number {
    return store.getters.volume;
  }

  private onMouseOver() {
    if (this.isMobile && !this.highlight && !this.play) {
      this.onClickPlay();
    }
    this.highlight = true;
    clearTimeout(this.timeOutHighlight);
    this.timeOutHighlight = setTimeout(() => {
      this.highlight = false;
    }, this.timeTimeOut);
  }

  private getUrlUser(): string {
    return getUserAvatar(this.comment.media?.creator, 48);
  }

  get mediaUrl(): string {
    if (this.comment !== null && this.comment.media !== null) {
      return getVideoURLForStatus(this.comment.media);
    }
    return '';
  }

  private onTimeUpdate(val) {
    if (this.mainVideo()) {
      this.timer = this.mainVideo().currentTime;
    }
  }


  private onEndedVideo() {
    this.play = false;
    setActionEvent(ActionEvent.comment_player_screen_close);
    this.$emit('ended');
  }

  private mainVideo(): HTMLVideoElement {
    return this.$refs.mainVideo as HTMLVideoElement;
  }

  private onCanPlay() {
    if (!this.mainVideo()) {
      return;
    }
    this.duration = this.mainVideo().duration;
    if (this.mainVideo()) {
      this.mainVideo().playbackRate = this.speedMode;
    }
  }


  get filterClass(): string {
    if (this.comment.filter) {
      let filter = this.comment.filter + ' ';
      if (!this.play) {
        filter += ' noAnimation';
      }
      return filter;

    } else {
      return FilterVideoType.NONE;
    }
  }

  get humanCountDown(): string {
    return this.getHumanTime((this.duration - this.timer) * 1000);
  }

  get humanDuration(): string {
    return this.getHumanTime(this.duration * 1000);
  }

  private forward() {
    let time = this.timer + 10;
    if (time > this.duration) {
      time = this.duration;
    }
    this.changeCurrentTime(time);
  }

  private rewind() {
    let time = this.timer - 10;
    if (time < 0) {
      time = 0;
    }
    this.changeCurrentTime(time);
  }

  private onClickPlay() {
    if (!this.play) {
      this.mainVideo().play().catch((e) => {
        log.error(e.message);
      });
      // TODO map amplitude
      this.play = true;
    } else {
      this.mainVideo().pause();
      this.play = false;
      // TODO map amplitude
    }
  }

  private getHumanTime(val: number): string {
    if (val < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }

  private changeCurrentTime(val) {
    if (this.mainVideo()) {
      this.mainVideo().currentTime = val;
    }
    // TODO amplitude
  }


  get volumeIcon(): string {
    if (this.volume >= 75) {
      return 'volume-high';
    } else if (this.volume >= 40) {
      return 'volume-medium';
    } else if (this.volume < 40 && this.volume > 0) {
      return 'volume-low';
    }
    return 'volume-off';
  }


  private muteUnmuteVolume() {
    if (this.volume <= 0) {
      this.mainVideo().volume = 1;
      store.dispatch(CHANGE_PLAYER_VOLUME, 100);
      // TODO map amplitude
    } else {
      this.mainVideo().volume = 0;
      store.dispatch(CHANGE_PLAYER_VOLUME, 0);
      // TODO map amplitude
    }
  }

  private changeVolume(val) {
    this.mainVideo().volume = val / 100;
    store.dispatch(CHANGE_PLAYER_VOLUME, val);
    // TODO map amplitude
  }

  private switchSpeedMode() {
    if (this.mainVideo()) {
      const speed = this.speedMode;
      const speedTab: number[] = [0.8, 1, 1.2, 1.5, 2];
      let newSpeed = speedTab[0];
      for (const speedAvailable of speedTab) {
        if (speedAvailable > speed) {
          newSpeed = speedAvailable;
          break;
        }
      }
      if (this.mainVideo()) {
        this.mainVideo().playbackRate = newSpeed;
      }
      store.dispatch(CHANGE_PLAYER_SPEED, newSpeed);
      // TODO amplitude
    }
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/gridBreakPoint.scss';
@import "@/scss/shadows.scss";

.commentStickerPlayerContainer {
  height: 100%;
  width: 100%;
  position: relative;

  .sticker {
    position: absolute;
    border: 2px solid #FFFFFF;
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
    overflow: hidden;
    bottom: 0px;
    border-radius: 100% 100% 100% 16px;
    left: 0px;

    &.minimize {
      transform: scale(0);
    }

    transition: all 0.2s;
    //fix issue in safari border-radius and overflow
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    background: black;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    .stickerLayout {
      min-height: 140px;
      transform: scaleX(-1) scaleY(1);
      transition: all 0.5s;

      .videoElement {
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

    }

    // CONTROL BAR
    .controlBar {
      background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9));
      width: 100%;
      position: absolute;
      min-height: 30px;
      display: flex;
      border-radius: 4px;
      left: 0px;
      bottom: 0px;
      animation-duration: 0.5s;

      // Volume
      .volumeContainer {
        display: flex;
        cursor: pointer;
        color: white;
        font-size: 12px;
        align-items: center;
        margin-left: 4px;
        margin-right: 4px;


        .volumeIcon {
          transition: transform ease-in-out 0.1s;

          &:hover {
            transform: scale(1.2);;
          }
        }

        .volumeSlider {
          width: 64px;
          position: absolute;
          bottom: 32px;
          margin-right: 0px;
        }
      }

      // time container
      .timeContainer {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #FFF;
        margin-left: 8px;
      }

      // speed
      .speedContainer {
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        color: var(--dark);
        font-size: 10px;
        margin-left: 8px;
        font-weight: 900;
        transition: transform ease-in-out 0.1s;
        line-height: 18px;
        margin-right: 8px;
        margin-bottom: 2px;

        &:hover {
          transform: scale(1.2);;
        }

        .speed {
          border: solid 1px var(--white);
          border-radius: 4px;
          width: 20px;
          height: 18px;
          padding-right: 1px;
          color: var(--white);
        }
      }
    }

    .playContainer {
      position: absolute;
      width: 100%;
      top: 0;
      text-align: center;
      height: calc(100% - 50px);
      cursor: pointer;
      animation-duration: 0.5s;

      .logoPlay {
        // border: solid 1px white;
        border-radius: 60px;
        position: absolute;
        left: calc(50% - 16px);
        padding: 8px;
        top: calc(50% - 16px + 25px);
        background-color: rgba(0, 0, 0, 0.4);
        @extend .shadow3;
        width: 32px;
        height: 32px;
        transition: transform ease-in-out 0.2s;
        @media(hover: hover) and (pointer: fine) {
          &:hover {
            transform: scale(1.2);;
          }
        }
      }

      .logoRewind {
        left: 12px;
        top: calc(50% - 12px + 25px);
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 8px;
        width: 24px;
        height: 24px;
        border-radius: 60px;
        transition: transform ease-in-out 0.2s;
        @extend .shadow3;
        @media(hover: hover) and (pointer: fine) {
          &:hover {
            transform: scale(1.2);;
          }
        }
      }

      .logoForward {
        right: 12px;
        top: calc(50% - 12px + 25px);
        position: absolute;
        border-radius: 60px;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 8px;
        width: 24px;
        height: 24px;
        @extend .shadow3;
        transition: transform ease-in-out 0.2s;
        @media(hover: hover) and (pointer: fine) {
          &:hover {
            transform: scale(1.2);;
          }
        }
      }
    }
  }


}

</style>
