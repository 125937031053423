<template>

</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import store from '@/store';
import {GET_MY_NOTIFICATIONS_STAT, GET_UNREAD_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';
import delay from 'delay';


@Component({
  components: {UserAvatar},
})
export default class NotificationRefresh extends Vue {
  private intervalIndex: any = 0;
  private intervalTime: number = 60000;
  private MAX_UNREAD_LOAD: number = 250;

  get isUserConnected(): boolean {
    return store.getters.isAuthenticated;
  }

  @Watch('isUserConnected')
  public whenUserConnect() {
    if (this.isUserConnected) {
      clearInterval(this.intervalIndex);

      this.intervalIndex = setInterval(() => {
        store.dispatch(GET_MY_NOTIFICATIONS_STAT).then(() => {
          this.loadUnreadNotificationIfNecessary();
        });
      }, this.intervalTime);
      store.dispatch(GET_MY_NOTIFICATIONS_STAT).then(() => {
        this.loadUnreadNotificationIfNecessary();
      });
    } else {
      clearInterval(this.intervalIndex);
    }
  }

  public loadUnreadNotificationIfNecessary() {
    // if we have unread notification we load the unread notification
    if (store.getters.getTotalOfUnreadNotification > 0
        && store.getters.getUnreadNotification.length < this.MAX_UNREAD_LOAD) {

      // if we miss some unread notification, we load the last notifications
      if (store.getters.getTotalOfUnreadNotification > store.getters.getUnreadNotification.length) {
        store.dispatch(GET_UNREAD_NOTIFICATIONS);
      }
    }
  }

  public async mounted() {
    await delay(2000);
    this.whenUserConnect();
  }

}
</script>
