<template>
  <transition name="fade">
    <div class="videoContainer"
         v-show="streamLoading"
    >
      <video ref="videoElement" :src="urlScreenVirtualBackground" class="videoElement" loop
             muted autoplay>
      </video>
    </div>
  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Media} from '@/store/media/mediaModel';


import {
  CLEAR_MAIN_MEDIAID,
  GENERATE_MAIN_MEDIAID,
  SET_MAIN_TIME_RECORDING,
  UPDATE_MAIN_RUNNING_STATE,
} from '@/store/recordingState/recordStateAction';
import {ADD_MEDIA, SEND_VIRTUAL_MEDIA} from '@/store/media/mediaAction';
import VideoFilePlayer from '@/components/recorder/videoLayout/videoPlayer/VideoFilePlayer.vue';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {log} from '@/utils/log';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import {getVideoURLForStatus, getVirtualScreenVideoURL} from '@/utils/util';


@Component({
  components: {VideoFilePlayer},
  computed: {},
})
export default class VirtualScreenSource extends Vue {
  // this date of start Recording (Interval step is not really confidence)
  public dateOfStartRecording: number = new Date().getTime();

  private currentTime: number = 0;

  // id of interval when recording recordRTC
  private recordInterval: any = 0;

  // Step of recording
  private recordIntervalStep: number = 250;

  // value if the stream is setup
  private streamLoading: boolean = false;

  // get the video HTML element
  get videoElement(): HTMLVideoElement {
    return this.$refs.videoElement as HTMLVideoElement;
  }


  get previewMode(): boolean {
    return store.getters.isPreviewRecording;
  }


  get recordingState(): RecordingState {
    return store.getters.getRecordingState;
  }

  get screenVirtualBackground(): ScreenVirtualBackgroundEnum {
    return store.getters.getScreenVirtualBackground;
  }

  get urlScreenVirtualBackground(): string {
    return getVirtualScreenVideoURL(this.screenVirtualBackground);
  }

  @Watch('recordingState')
  public onrecordingStateChange() {
    if (this.recordingState === RecordingState.RECORDING) {
      this.onStartRecording();
    } else if (this.recordingState === RecordingState.PAUSE) {
      this.onPauseRecording();
    } else if (this.recordingState === RecordingState.FINISH) {
      this.onStopRecording();
    }
  }

  @Watch('previewMode')
  public muteStreamIfNeed() {
    if (this.previewMode) {
      this.videoElement.pause();
    } else {
      this.videoElement.play();
    }
  }


  public async mounted() {
    this.streamLoading = true;
  }


  public onStartRecording() {

    this.generateMediaID();
    this.createMedia();
    this.clearRecordingTime();
    this.recordInterval = setInterval(this.incRecordingTime, this.recordIntervalStep);
    this.updateRunningStatus(true);
  }

  public onPauseRecording() {
    this.onStopRecording();
  }

  public onStopRecording() {
    log.info('on Stop recording');
    log.info('on Stop RTC');
    this.clearRecordingTime();
    this.updateRunningStatus(false);
  }


  public clearRecordingTime() {
    clearInterval(this.recordInterval);
    this.dateOfStartRecording = new Date().getTime();
    this.currentTime = 0;
    store.dispatch(SET_MAIN_TIME_RECORDING, 0);
  }

  public incRecordingTime() {
    // compute time
    // the tick is not confidence (chrome try to send the tick at a time)
    this.currentTime = new Date().getTime() - this.dateOfStartRecording;
    store.dispatch(SET_MAIN_TIME_RECORDING, this.currentTime);
  }

  public createMedia() {
    const media = new Media();
    media.mediaID = store.getters.getMainMediaId;
    media.type = SourceVideoType.VIRTUAL_SCREENCAST;

    store.dispatch(ADD_MEDIA, media);
    store.dispatch(SEND_VIRTUAL_MEDIA, {
      media: media, type: this.screenVirtualBackground
    });
  }

  public generateMediaID() {
    if (store.getters.getMainRecordSource === SourceVideoType.NONE) {
      store.dispatch(CLEAR_MAIN_MEDIAID);
    }
    store.dispatch(GENERATE_MAIN_MEDIAID);
  }

  public updateRunningStatus(value: boolean) {
    store.dispatch(UPDATE_MAIN_RUNNING_STATE, value);
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/shadows.scss";

.videoContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  animation-duration: 1s;
  background: var(--light);
  border-radius: 8px;

  .videoElement {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    @extend .shadow3;
  }
}

</style>
