import axios from 'axios';
import sortBy from 'lodash/sortBy';
import {v4 as uuidv4} from 'uuid';
import {BACK_ENDPOINT} from '@/store/conf/api';
import Vue from 'vue';
import {
    ADD_WEET_TO_WORKSPACE,
    ATTACHED_MEDIA_TO_WEET,
    ATTACHED_TIMELINE_TO_WEET,
    AttachedMediaParams,
    AttachedTimeLineParams, COUNT_MY_OWN_WEETS,
    CREATE_MY_WEET,
    CUT_TIMELINE_WEET,
    CutTimeLineParams, DELETE_ANNOTATION_ON_WEET,
    DELETE_MY_WEET,
    DETACHED_MEDIA_TO_WEET,
    DettachedMediaParams,
    DUPLICATE_MY_WEET,
    LOAD_WEET_FOR_EDITING, MIGRATE_MY_PS,
    MY_LIST_WEET, MY_WEET_INBOX,
    MyWeetListParams,
    NOTIFY_WEET_UPDATE,
    NotifyUpdateParams,
    REFRESH_MY_WEET,
    REFRESH_WEET_FOR_EDITING,
    REFRESH_WEET_UNBLURED,
    REINIT_GRID_MY_WEET,
    REINIT_MY_WEET,
    SEARCH_MY_WEET,
    SEND_CHANGE_WEET_TITLE,
    SEND_COMMENT_POLICY_WEET, SEND_EMOTE_POLICY_WEET,
    SEND_VISIBILITY_POLICY_WEET, SET_CHANNELS_TO_WEET, StaticTag, UPDATE_ANNOTATION_ON_WEET, UPDATE_MY_WEET_IMAGE,
} from '@/store/myWeet/myWeetAction';
import {Weet, WeetCountRepresentation} from '@/store/weet/weetModel';
import {ADD_TIME_LINE, TIMELINE_SAVED, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import {SET_MEDIA_LIST} from '@/store/media/mediaAction';
import {SET_CURRENT_EDITING_WEET, SET_CHANNELS_FOR_EDITING} from '@/store/recordingState/recordStateAction';
import {ADD_A_WEET} from '@/store/weet/weetAction';
import {PaginationWrapper} from '@/store/generic/paginationWrapper';
import {GET_LIST_OF_GROUP_CONTACT} from '@/store/contact/contactAction';
import delay from 'delay';
import {CommentPolicy} from '@/store/weet/CommentPolicy';
import {VisibilityPolicy} from '@/store/weet/VisibilityPolicy';
import store from '@/store';
import {isWeetIsReady} from '@/utils/weetUtil';
import {TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {EmotePolicy} from "@/store/weet/EmotePolicy";
import {log} from "@/utils/log";
import {UPDATE_ANNOTATION} from "@/store/annotation/annotationAction";
import {Annotation} from "@/store/annotation/annotationModel";

const myWeetAPI = BACK_ENDPOINT + '/my/weet';

export default {
    state: {
        myWeets: {},
        totalOfWeet: 0,
        numberOwnWeets: 0,
        currentPage: 0,
        // filter by title
        titleFilter: '',
        // filter by members
        membersFilter: [],
        // filter by group
        groupFilter: '',
        // filter by contact user
        userFilter: '',
        // filter by workspace
        workspaceFilter: '',
        // filter by workspace channel
        channelFilter: '',
        // filter by static tag
        tagFilter: '',
        totalResult: 0,
        gridPageID: ''
    },
    mutations: {
        [MY_LIST_WEET]: (state: any, {data, params}: { data: PaginationWrapper, params: MyWeetListParams }) => {
            let sp: Weet;
            if (data.page === 0) {
                state.myWeets = {};
            }
            // we erase data if mode filter
            if (data.filter) {
                state.myWeets = {};
                state.currentPage = 0;
            } else {
                state.totalOfWeet = data.count;
            }
            const weets = data.data as Weet[];
            for (const i in weets) {
                if (weets[i] !== undefined) {

                    sp = weets[i];
                    Vue.set(state.myWeets, sp.weetID, sp);
                }
            }
            state.currentPage = data.page;
            state.totalResult = data.count;

            state.titleFilter = params.titleFilter;
            state.membersFilter = params.membersFilter;
            state.tagFilter = params.tagFilter;
            state.groupFilter = params.groupFilter;
            state.userFilter = params.userFilter;
            state.workspaceFilter = params.workspaceFilter;
            state.channelFilter = params.channelFilter;

        },
        [CREATE_MY_WEET]: (state: any, weet: Weet) => {
            Vue.set(state.myWeets, weet.weetID, weet);
        },
        [DELETE_MY_WEET]: (state: any, weet: Weet) => {
            if (state.myWeets[weet.weetID]) {
                state.totalOfWeet--;
                state.totalResult--;
            }
            Vue.delete(state.myWeets, weet.weetID);
        },
        [REINIT_MY_WEET]: (state: any, params: MyWeetListParams) => {
            // state.myWeets = {};
            state.titleFilter = params.titleFilter;
            state.membersFilter = params.membersFilter;
            state.tagFilter = params.tagFilter;
            state.groupFilter = params.groupFilter;
            state.userFilter = params.userFilter;
            state.workspaceFilter = params.workspaceFilter;
            state.channelFilter = params.channelFilter;
            state.currentPage = 0;
            state.totalResult = 0;
            state.totalOfWeet = 0;
            state.gridPageID = uuidv4();
        },
        [REINIT_GRID_MY_WEET]: (state: any, params: MyWeetListParams) => {

            state.gridPageID = uuidv4();
        },
        [COUNT_MY_OWN_WEETS]: (state: any, data: WeetCountRepresentation) => {
            state.numberOwnWeets = data.count;
        },
        [UPDATE_ANNOTATION_ON_WEET]:(state:any,{weetID,annotation}:{weetID:string,annotation:Annotation})=>
        {
            const weetToUpdate=state.myWeets[weetID];
            if(!weetToUpdate){
                return;
            }
            let annotationToRemove:Annotation|null=null;
            for(const annot of weetToUpdate.annotations){
                if(annot.annotationID==annotation.annotationID){
                    annotationToRemove=annot;
                    break;
                }
            }
            if(annotationToRemove){
                const index=weetToUpdate.annotations.indexOf(annotationToRemove);
                weetToUpdate.annotations.splice(index, 1);
            }
            weetToUpdate.annotations.push(annotation);
            Vue.set(state.myWeets, weetID, weetToUpdate);
        },
        [DELETE_ANNOTATION_ON_WEET]:(state:any,{weetID,annotation}:{weetID:string,annotation:Annotation})=>
        {
            const weetToUpdate=state.myWeets[weetID];
            if(!weetToUpdate){
                return;
            }
            let annotationToRemove:Annotation|null=null;
            for(const annot of weetToUpdate.annotations){
                if(annot.annotationID==annotation.annotationID){
                    annotationToRemove=annot;
                    break;
                }
            }
            if(annotationToRemove){
                const index=weetToUpdate.annotations.indexOf(annotationToRemove);
                weetToUpdate.annotations.splice(index, 1);
            }
            Vue.set(state.myWeets, weetID, weetToUpdate);
        }

    },
    getters: {
        getMyWeets: (state: any): Weet[] => {
            return sortBy(Object.values(state.myWeets), ['created']).reverse() as Weet[];
        },
        getMyWeet: (state: any) => (weetID: string): Weet => {
            return state.myWeets[weetID];
        },
        getTotalOfWeet: (state: any): number => {
            return state.totalOfWeet;
        },
        getNumberOwnWeets: (state: any): number => {
            return state.numberOwnWeets;
        },
        getCurrentPage: (state: any): number => {
            return state.currentPage;
        },
        getTitleFilter: (state: any): string => {
            return state.titleFilter;
        },
        getMembersFilter: (state: any): string[] => {
            return state.membersFilter;
        },
        getTagsFilter: (state: any): string => {
            return state.tagFilter;
        },
        getGroupFilter: (state: any): string => {
            return state.groupFilter;
        },
        getUserFilter: (state: any): string => {
            return state.userFilter;
        },
        getWorkspaceFilter: (state: any): string => {
            return state.workspaceFilter;
        },
        getChannelFilter: (state: any): string => {
            return state.channelFilter;
        },
        getGridPageId: (state: any): string => {
            return state.gridPageID;
        }
    },
    actions: {

        [REINIT_MY_WEET]({
                             commit,
                             dispatch,
                             getters
                         }: { commit: any, dispatch: any, getters: any },
                         params: MyWeetListParams) {
            commit(REINIT_MY_WEET, params);
        },
        [REFRESH_MY_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, weet: Weet) {
            commit(CREATE_MY_WEET, weet);
        },
        async [SEND_CHANGE_WEET_TITLE]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                       params: { weetID: string, title: string }) {
            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/name',
                data: {title: params.title},
                method: 'PUT',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                dispatch(ADD_A_WEET, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SEND_COMMENT_POLICY_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                         params: { weetID: string, policy: CommentPolicy }) {
            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/commentPolicy',
                data: {commentPolicy: params.policy},
                method: 'PUT',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                dispatch(ADD_A_WEET, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SEND_EMOTE_POLICY_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                       params: { weetID: string, policy: EmotePolicy }) {
            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/emotePolicy',
                data: {emotePolicy: params.policy},
                method: 'PUT',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                dispatch(ADD_A_WEET, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SEND_VISIBILITY_POLICY_WEET]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                            params: { weetID: string, policy: VisibilityPolicy }) {
            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/visibilityPolicy',
                data: {visibilityPolicy: params.policy},
                method: 'PUT',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                dispatch(ADD_A_WEET, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [MY_LIST_WEET]({commit, dispatch}: { commit: any, dispatch: any }, paramsMyWeet: MyWeetListParams) {

            // we select the good workspace
            paramsMyWeet.workspaceFilter = store.getters.getSelectedWorkspaceID;
            if (!paramsMyWeet.workspaceFilter
                && paramsMyWeet.tagFilter != StaticTag.SHARED_WITH_ME
                && paramsMyWeet.tagFilter != StaticTag.LAST_VIEWED) {
                paramsMyWeet.tagFilter = StaticTag.SHARED_WITH_ME;
            }
            return await axios({
                url: myWeetAPI,
                params: paramsMyWeet,
                method: 'GET',
            }).then(async (resp) => {
                await delay(100);
                commit(MY_LIST_WEET, {data: resp.data, params: paramsMyWeet});
                // store weet on Main Modul
                for (const weet of resp.data.data) {
                    commit(ADD_A_WEET, weet);
                }
                commit(REINIT_GRID_MY_WEET);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [MY_WEET_INBOX]({commit, dispatch}: { commit: any, dispatch: any }) {

            // we select the good workspace
            const params = new MyWeetListParams();
            params.tagFilter = StaticTag.SHARED_WITH_ME;
            params.workspaceFilter = null;
            return await axios({
                url: myWeetAPI,
                params: params,
                method: 'GET',
            }).then(async (resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SEARCH_MY_WEET]({commit, dispatch}: { commit: any, dispatch: any }, paramsMyWeet: MyWeetListParams) {
            paramsMyWeet.workspaceFilter = store.getters.getSelectedWorkspaceID;
            return await axios({
                url: myWeetAPI,
                params: paramsMyWeet,
                method: 'GET',
            }).then((resp) => {
                return resp.data.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [MIGRATE_MY_PS]({commit, dispatch}: { commit: any, dispatch: any }, workspaceID: string) {
            return await axios({
                url: myWeetAPI + '/migratePS',
                params: {workspaceID},
                method: 'POST',
            }).then((resp) => {
                return;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [CREATE_MY_WEET]({
                                   commit,
                                   dispatch,
                                   getters
                               }: { commit: any, dispatch: any, getters: any },
                               weetTitle: string | null) {
            return await axios({
                url: myWeetAPI,
                data: {
                    title: weetTitle,
                    workspaceID: getters.getSelectedWorkspaceID
                },
                method: 'POST',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [ADD_WEET_TO_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any }, {
            weetID,
            workspaceID
        }: { weetID: string, workspaceID: string }) {
            return await axios({
                url: `${myWeetAPI}/${weetID}/workspace`,
                data: {workspaceID: workspaceID},
                method: 'PUT',
            }).then((resp) => {
                commit(DELETE_MY_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SET_CHANNELS_TO_WEET]({commit, dispatch}: { commit: any, dispatch: any }, {
            weetID,
            channelIDs
        }: { weetID: string, channelIDs: string[] }) {
            return await axios({
                url: `${myWeetAPI}/${weetID}/channels`,
                data: {channelIDs},
                method: 'PUT',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                commit(ADD_A_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [DUPLICATE_MY_WEET]({commit, dispatch}: { commit: any, dispatch: any }, weetID: string) {
            const response = await axios({
                url: `${myWeetAPI}/${weetID}/clone`,
                method: 'POST',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        },
        async [DELETE_MY_WEET]({commit, dispatch}: { commit: any, dispatch: any }, weet: Weet) {
            commit(DELETE_MY_WEET, weet);
            return await axios({
                url: myWeetAPI + '/' + weet.weetID,
                method: 'DELETE',
            }).then((resp) => {
                commit(DELETE_MY_WEET, weet);
                return resp.data;
            }).catch((err) => {
                // we restore the weet if it's not ok
                // delete is it was reload by another function
                commit(CREATE_MY_WEET, weet);
                dispatch(GET_LIST_OF_GROUP_CONTACT, 0);
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [ATTACHED_MEDIA_TO_WEET]({commit, dispatch}: { commit: any, dispatch: any },
                                       attachedMediaParams: AttachedMediaParams) {
            return await axios({
                url: myWeetAPI + '/' + attachedMediaParams.weetID + '/media',
                data: {mediaID: attachedMediaParams.mediaID},
                method: 'POST',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },

        async [DETACHED_MEDIA_TO_WEET]({
                                           commit,
                                           dispatch,
                                           state,
                                           getters
                                       }: { commit: any, dispatch: any, state: any, getters: any },
                                       dettachedMediaParams: DettachedMediaParams) {
            return await axios({
                url: myWeetAPI + '/' + dettachedMediaParams.weetID + '/media',
                data: {mediaID: dettachedMediaParams.mediaID},
                method: 'DELETE',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                log.warn(err);
                // throw err;
            });
        },
        async [ATTACHED_TIMELINE_TO_WEET]({commit, dispatch}: { commit: any, dispatch: any },
                                          attachedTimeLineParams: AttachedTimeLineParams) {

            dispatch(TIMELINE_SAVED);
            return await axios({
                url: myWeetAPI + '/' + attachedTimeLineParams.weetID + '/timeline',
                data: {
                    timeLineEvent: attachedTimeLineParams.timeLineEvent,
                    offsetTime: attachedTimeLineParams.offsetTime
                },
                method: 'POST',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                // so the timeLine is saved
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },

        async [CUT_TIMELINE_WEET]({
                                      commit,
                                      dispatch,
                                      getters
                                  }: { commit: any, dispatch: any, getters: any },
                                  params: CutTimeLineParams) {
            // so call the API to cut the video
            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/timeline/cut',
                data: {
                    timeLineEvent: params.timeLineEvent,
                },
                method: 'PUT',
            });
        },
        async [NOTIFY_WEET_UPDATE]({commit, dispatch}: { commit: any, dispatch: any },
                                   params: NotifyUpdateParams) {

            return await axios({
                url: myWeetAPI + '/' + params.weetID + '/notify',
                data: {
                    times: params.times,
                },
                method: 'POST',
            }).then((resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [REFRESH_WEET_FOR_EDITING]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                         weetID: string):Promise<Weet> {
            const MAX_REFRESH_ITERATION = 250;
            // 1 - GET THE LAST VERSION OF WEET
            const weet: Weet = await axios({
                url: myWeetAPI + '/' + weetID,
                method: 'GET',
            }).then(async (resp) => {
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            // put weet my Weet List
            commit(CREATE_MY_WEET, weet);
            let iteration = 0;
            await dispatch(SET_CURRENT_EDITING_WEET, weet.weetID);
            // Add MEDIA
            commit(SET_MEDIA_LIST, weet.medias);
            // ADD TIMELINE
            commit(ADD_TIME_LINE, weet.timeLine);

            dispatch(TIMER_RECORD, getters.getTimeLineDuration);

            if (!isWeetIsReady(weet) && iteration < MAX_REFRESH_ITERATION) {
                iteration++;
                await delay(1000);
                dispatch(REFRESH_WEET_FOR_EDITING, weet.weetID);
            }
            return weet;
        },
        async [LOAD_WEET_FOR_EDITING]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                      weetID: string): Promise<Weet> {
            // 1 - GET THE LAST VERSION OF WEET
            const weet: Weet = await axios({
                url: myWeetAPI + '/' + weetID,
                method: 'GET',
            }).then(async (resp) => {
                return resp.data;
            }).catch((err) => {
                throw err;
            });


            commit(CREATE_MY_WEET, weet);

            // 2 - SET THE WEET ID FOR EDITION
            await dispatch(SET_CURRENT_EDITING_WEET, weet.weetID);

            // 4 - Map the timeEvent of the weet
            // Add MEDIA
            commit(SET_MEDIA_LIST, weet.medias);
            // ADD TIMELINE
            commit(ADD_TIME_LINE, weet.timeLine);

            dispatch(SET_CHANNELS_FOR_EDITING, weet.channels);

            dispatch(TIMER_RECORD, getters.getTimeLineDuration);


            dispatch(TIMELINE_ADVANCED_MODIFIED, false);
            return weet;
        },
        [REFRESH_WEET_UNBLURED]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
                                weetID: string) {
            return axios({
                url: myWeetAPI + '/' + weetID,
                method: 'GET',
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                store.dispatch(ADD_A_WEET, resp.data);
                return resp.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },

        async [COUNT_MY_OWN_WEETS](
            {commit, dispatch}: { commit: any, dispatch: any }
        ): Promise<WeetCountRepresentation> {
            return await axios({
                url: `${myWeetAPI}/countMyOwn`,
                method: 'GET',
            }).then((resp) => {
                commit(COUNT_MY_OWN_WEETS, resp.data);
                return resp.data as WeetCountRepresentation;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [UPDATE_MY_WEET_IMAGE]({commit, dispatch}: { commit: any, dispatch: any }, {
            weetID,
            blob,
            fix
        }: { weetID: string, blob: Blob, fix: boolean }) {
            const formData = new FormData();
            formData.append('image', blob);

            return await axios({
                url: myWeetAPI + '/' + weetID + '/thumbnail',
                params: {manual: fix},
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=media',
                },
            }).then((resp) => {
                commit(CREATE_MY_WEET, resp.data);
                commit(ADD_A_WEET, resp.data);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        [UPDATE_ANNOTATION_ON_WEET]({
                             commit,
                             dispatch,
                             getters
                         }: { commit: any, dispatch: any, getters: any },
                                    {weetID,annotation}:{weetID:string,annotation:Annotation}) {
            commit(UPDATE_ANNOTATION_ON_WEET,  {weetID,annotation});
        },
        [DELETE_ANNOTATION_ON_WEET]({
                                        commit,
                                        dispatch,
                                        getters
                                    }: { commit: any, dispatch: any, getters: any },
                                    {weetID,annotation}:{weetID:string,annotation:Annotation}) {
            commit(DELETE_ANNOTATION_ON_WEET,  {weetID,annotation});
        },
    },
};
