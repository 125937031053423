import { render, staticRenderFns } from "./SectionPlayer.vue?vue&type=template&id=2ef4764c&scoped=true"
import script from "./SectionPlayer.vue?vue&type=script&lang=ts"
export * from "./SectionPlayer.vue?vue&type=script&lang=ts"
import style0 from "./SectionPlayer.vue?vue&type=style&index=0&id=2ef4764c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef4764c",
  null
  
)

export default component.exports