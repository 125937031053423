// SLACK/TEAMS
export const BIND_EXTERNAL_ACCOUNT_REQUEST = 'BIND_EXTERNAL_ACCOUNT_REQUEST';
export const EXTERNAL_TOOL_INIT = 'EXTERNAL_TOOL_INIT';
export const EXTERNAL_TOOL_AUTH_SUCCESS = 'EXTERNAL_TOOL_AUTH_SUCCESS';
export const EXTERNAL_TOOL_AUTH_FAILURE = 'EXTERNAL_TOOL_AUTH_FAILURE';
export const EXTERNAL_TOOL_SEND_MESSAGE = 'EXTERNAL_TOOL_SEND_MESSAGE';
export const NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL = 'NOTIFY_WEET_CREATION_FROM_EXTERNALTOOL';
export const SET_TEAMS_CONVERSATION_REFERENCE = 'SET_TEAMS_CONVERSATION_REFERENCE';
export const PREPOPULATE_EXTERNALTOOL_REFERENCE = 'PREPOPULATE_EXTERNALTOOL_REFERENCE';
export const FROM_EXTERNAL_TOOL_URL = 'FROM_EXTERNAL_TOOL_URL';
export const FROM_EXTERNALTOOL = 'FROM_EXTERNALTOOL';
export const CHECK_CONNECTED_EXTERNAL_USER = 'CHECK_CONNECTED_EXTERNAL_USER';

// PUSH NOTIFICATIONS
export const REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET = 'REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET';
export const DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE = 'DELETE_PUSH_NOTIFICATION_FROM_FIREBASE';
export const SAVE_PUSH_NOTIFICATION_EXTERNAL_ID = 'SAVE_PUSH_NOTIFICATION_EXTERNAL_ID';
export const ADD_PUSH_NOTIFICATION_TOKEN = 'ADD_PUSH_NOTIFICATION_TOKEN';
export const REMOVE_PUSH_NOTIFICATION_TOKEN = 'REMOVE_PUSH_NOTIFICATION_TOKEN';
export const REMOVE_ALL_PUSH_NOTIFICATION_TOKEN = 'REMOVE_ALL_PUSH_NOTIFICATION_TOKEN';
