export class PushNotificationToken {
  public pushNotificationToken: string;
  public externalId: string;

  constructor(pushNotification: string, externalId: string) {
    this.externalId = externalId;
    this.pushNotificationToken = pushNotification;
  }
}

export class PrivateUserOption {
  public key: string;
  public externalId: string;
  public value: string;
  public weetUserID: string;

  constructor(key: string, externalId: string, value: string, weetUserID: string) {
    this.externalId = externalId;
    this.key = key;
    this.value = value;
    this.weetUserID = weetUserID;
  }
}

export enum PrivateUserOptionKeyEnum {
  SLACK_USER_TOKEN = 'SLACK_USER_TOKEN',
  SLACK_TEAM_TOKEN = 'SLACK_TEAM_TOKEN',
  PUSH_NOTIFICATION_TOKEN = 'PUSH_NOTIFICATION_TOKEN'
}

export interface PushNotificationMessage {
  data: {
    byLastName: string,
    byFirstName: string,
    weetTitle: string,
    comment: string,
    weetId: string,
    type: string,
    time: string,
    commentId: string,
    commentToReply: string
  };
}

export interface NotifyWeetCreation {
  externalToolReference: string;
  weetId: string;
  externalTool: string;
}
