import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth/authModule';
import settings from '@/store/settings/settingsModule';
import upload from '@/store/upload/uploadModule';
import download from '@/store/download/downloadModule';
import myWeetModule from '@/store/myWeet/myWeetModule';
import weetModule from '@/store/weet/weetModule';
import recordingState from '@/store/recordingState/recordStateModule';
import timeLine from '@/store/timeLine/timeLineModule';
import setupModule from '@/store/setup/setupModule';
import mediaStore from '@/store/media/mediaModule';
import myUser from '@/store/user/myUserModule';
import persist from '@/store/persistedInformation/persistModule';
import weetView from '@/store/weetview/weetViewModule';
import comment from '@/store/comment/commentModule';
import myNotification from '@/store/myNotifications/myNotificationsModule';
import contactModule from '@/store/contact/contactModule';
import settingUserModule from '@/store/settingUsers/settingUserModule';
import VuexPersistence from 'vuex-persist';
import createMutationsSharer from 'vuex-shared-mutations';
import integrationModule from '@/store/integration/integrationModule';
import transcriptModule from '@/store/transcript/transcriptModule';
import appView from '@/store/appView/appViewModule';
import identity from '@/store/identity/identityModule';
import workpaceModule from '@/store/workspace/workspaceModule';
import workpaceRightModule from '@/store/workspaceRight/workspaceRightModule';
import paymentModule from '@/store/payment/paymentModule';
import channelModule from '@/store/channel/channelModule';
import advancedEditingModule from '@/store/advancedEditing/advancedEditingModule';
import customDownloadModule from '@/store/customDownload/customDownloadModule';
import subtitlesModule from '@/store/subtitles/SubtitlesModule';
import annotationsModule from '@/store/annotation/annotationModule';

import {AUTH_ERROR, AUTH_REQUEST, AUTH_SUCCESS, RESET_AUTH_STORE, UPDATE_USER_CONNECTED} from '@/store/auth/authAction';
import {
    DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE,
    PREPOPULATE_EXTERNALTOOL_REFERENCE,
    REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET,
    SAVE_PUSH_NOTIFICATION_EXTERNAL_ID
} from '@/store/integration/integrationAction';
import {
    GET_MY_NOTIFICATIONS_STAT,
    GET_UNREAD_NOTIFICATIONS,
    READ_ALL_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    UNREAD_NOTIFICATIONS
} from '@/store/myNotifications/myNotificationsAction';
import {LAUNCH_CUSTOM_DOWNLOAD} from "@/store/customDownload/customDownloadAction";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: localStorage,
    key: 'weetStoreV1.1',
    modules: [
        'auth', 'persist', 'setupModule', 'integrationModule', 'onboarding',
        'workpaceRightModule', 'customDownloadModule'
    ],
});


export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {},
    plugins: [
        vuexLocal.plugin,
        createMutationsSharer({
            predicate: [
                // AUTH SYNCHRO
                AUTH_REQUEST,
                AUTH_SUCCESS,
                AUTH_ERROR,
                RESET_AUTH_STORE,
                UPDATE_USER_CONNECTED,

                // PUSH configuration
                SAVE_PUSH_NOTIFICATION_EXTERNAL_ID,
                REVOKE_PUSH_NOTIFICATION_TOKEN_FROM_WEET,
                DELETE_PUSH_NOTIFICATION_TOKEN_FROM_FIREBASE,
                // Notification
                READ_NOTIFICATIONS,
                READ_ALL_NOTIFICATIONS,
                UNREAD_NOTIFICATIONS,
                GET_MY_NOTIFICATIONS_STAT,
                GET_UNREAD_NOTIFICATIONS,
                // Integration
                PREPOPULATE_EXTERNALTOOL_REFERENCE,
                LAUNCH_CUSTOM_DOWNLOAD
            ],
        })
    ],
    mutations: {
        RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
    },
    actions: {},
    modules: {
        auth,
        settings,
        upload,
        myWeetModule,
        recordingState,
        timeLine,
        setupModule,
        mediaStore,
        download,
        weetModule,
        myUser,
        persist,
        weetView,
        comment,
        myNotification,
        contactModule,
        settingUserModule,
        integrationModule,
        transcriptModule,
        appView,
        workpaceModule,
        identity,
        advancedEditingModule,
        paymentModule,
        channelModule,
        workpaceRightModule,
        customDownloadModule,
        subtitlesModule,
        annotationsModule
    },
});
