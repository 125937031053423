<template>
  <div class="filterContainer">
    <!-- this is the "normal" filter panel -->
    <b-dropdown v-if="!modal" ref="dropDownFilter" position="is-bottom-left"
                aria-role="menu" class="dropdownFilter"
                expanded
                animation="fadeLeft" :max-height="450" @active-change="onDropChange"
                :can-close="canClose" :disabled="isWebcamVideoMute">
      <w-button stepTour="filterButton" class="buttonFilterQuickChange"
                icon="auto-fix"
                size="small"
                color="text" slot="trigger"/>

      <b-dropdown-item class="noHover" :focusable="false"
                       custom
                       paddingless>
        <FilterPanel @showTest="onShowTest" @choosed="checkMustClose()"/>
      </b-dropdown-item>
    </b-dropdown>
    <div v-else>
      <!-- this is the comment filter panel -->
      <w-button class="buttonFilterQuickChange" :enabled="!isWebcamVideoMute"
                icon="auto-fix"
                size="small"
                stepTour="filterButton"
                color="light" @click="showModal=true"/>
      <w-modal :active.sync="showModal">
        <CenterCenterPanel>
          <div class="backgroundFilterPanel">
            <FilterPanel @showTest="onShowTest" @choosed="showModal=false"/>
          </div>
        </CenterCenterPanel>
      </w-modal>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import store from '@/store';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import FilterPanel from '@/components/recorder/sticker/buttons/FilterPanel.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import {isGrid1X, isGrid2X} from '@/utils/gridBreakPoint';


@Component({
  components: {CenterCenterPanel, WModal, FilterPanel, WIcon, WButton, VideoSource},
  computed: {},
})
export default class StickerFilterButton extends Vue {

  @Prop({default: false})
  private modal!: boolean;
  private canClose: boolean = true;
  private showModal: boolean = false;

  get isWebcamVideoMute() {
    return store.getters.isStickerVideoMute;
  }

  public onShowTest(value: boolean) {
    this.canClose = !value;
  }

  public checkMustClose() {
    if (isGrid2X() || isGrid1X()) {
      // @ts-ignore
      this.$refs.dropDownFilter.toggle();
    }
  }

  public onDropChange(value) {
    this.$emit('open', value);
  }


}
</script>

<style lang="scss">
@import '@/scss/shadows.scss';
@import '@/scss/gridBreakPoint.scss';

.dropdownFilter {
  .dropdown-menu {
    margin-top: -272px;
    margin-right: 48px;
    max-height: 500px;
    min-width: 256px;
    border-radius: 16px;
    //  @extend .shadow1;
    @include GRID2X {
      margin-top: -0px;
      margin-right: 0px;
    }
    @include GRID1X {
      margin-top: -0px;
      margin-right: 0px;
    }
  }
}

</style>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';

.backgroundFilterPanel {
  background: white;
  margin: auto;
  width: 400px;
  max-width: 400px;
  border-radius: 16px;
  position: relative;
}

</style>
