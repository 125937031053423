import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  CHANGE_MY_PASSWORD,
  DISCONNECT_ALL_SESSIONS,
  DELETE_MY_ACCOUNT,
  PasswordChangeParam,
  ProfilUpdateParams,
  UPDATE_MY_IMAGE,
  UPDATE_MY_PROFILE, CAN_DELETE_MY_ACCOUNT,
} from '@/store/user/myUserAction';
import {AUTH_LOGOUT, AUTH_SUCCESS, UPDATE_USER_CONNECTED} from '@/store/auth/authAction';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {getTenantID} from '@/utils/util';
import {PrivateUserOption} from '@/store/integration/integrationModel';
import {DeleteAccountStatus} from '@/store/workspace/workspaceModel';

const myWeetAPI = BACK_ENDPOINT + '/my/user';

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async [UPDATE_MY_IMAGE]({commit, dispatch}: { commit: any, dispatch: any }, blob: Blob) {
      const formData = new FormData();
      formData.append('image', blob);

      const response = await axios({
        url: myWeetAPI + '/image',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'form-data; name=media',
        },
      }).then((resp) => {
        // get user
        const user = resp.data;
        dispatch(UPDATE_USER_CONNECTED, user);
      }).catch((err) => {

        if (err.data) {
          err = err.data;
        }

        throw err;

      });
      return response;
    },
    async [CHANGE_MY_PASSWORD]({
                                 commit,
                                 dispatch
                               }: { commit: any, dispatch: any },
                               passwordParams: PasswordChangeParam) {

      return await axios({
        url: myWeetAPI + '/newPassword',
        method: 'POST',
        data: passwordParams,
      }).then((resp) => {
        const token = resp.data.token;
        // get user
        const user = resp.data.user;
        sendMessageToExtention(AUTH_SUCCESS, {token: token, tenantID: getTenantID()});
        // commit token
        commit(AUTH_SUCCESS, {
          token,
          user,
          privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
        });
      }).catch((err) => {

        if (err.data) {
          err = err.data;
        }

        throw err;

      });
    },
    async [DISCONNECT_ALL_SESSIONS]({
                                      commit
                                    }: { commit: any, dispatch: any }) {

      return await axios({
        url: myWeetAPI + '/disconnectOtherSessions',
        method: 'PUT',
      }).then((resp) => {
        const token = resp.data.token;
        const user = resp.data.user;
        sendMessageToExtention(AUTH_SUCCESS, {token: token, tenantID: getTenantID()});
        // commit token
        commit(AUTH_SUCCESS, {
          token,
          user,
          privateUserOptions: (resp.data.privateUserOptions as PrivateUserOption[])
        });
      }).catch((err) => {

        if (err.data) {
          err = err.data;
        }

        throw err;

      });
    },
    async [UPDATE_MY_PROFILE]({
                                commit,
                                dispatch
                              }: { commit: any, dispatch: any },
                              profilParams: ProfilUpdateParams) {

      const response = await axios({
        url: myWeetAPI + '/update',
        method: 'PUT',
        data: profilParams,
      }).then((resp) => {
        // get user
        const user = resp.data;
        dispatch(UPDATE_USER_CONNECTED, user);
      }).catch((err) => {

        if (err.data) {
          err = err.data;
        }

        throw err;

      });
      return response;
    },
    async [CAN_DELETE_MY_ACCOUNT]({commit, dispatch}: { commit: any, dispatch: any }): Promise<DeleteAccountStatus> {
      return await axios(
          {
            url: myWeetAPI + '/candelete',
            method: 'GET',
          }).then((resp) => {
        return resp.data as DeleteAccountStatus;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [DELETE_MY_ACCOUNT]({commit, dispatch}: { commit: any, dispatch: any }) {

      const response = await axios(
          {
            url: myWeetAPI + '/delete',
            method: 'DELETE',
          }).then((resp) => {
        dispatch(AUTH_LOGOUT);
      }).catch((err) => {

        if (err.data) {
          err = err.data;
        }

        throw err;

      });
      return response;
    },
  },
};
