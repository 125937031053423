import axios from 'axios';
import Vue from 'vue';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {
    SET_SETTINGUSER,
    SETTINGUSER_CHANGED,
    SettingUserParams,
    SETTINGUSERS_LIST,
    UPLOAD_VIRTUAL_BACKGROUND
} from '@/store/settingUsers/settingUserAction';
import {SettingUser} from '@/store/settingUsers/settingUserModel';
import {SettingUsersKey} from '@/store/settingUsers/settingUserKey';
import {UPDATE_SETTING_USER_CONNECTED} from '@/store/auth/authAction';
import {SettingWorkspaceKey} from '@/store/workspace/workspaceModel';
import store from '@/store';

const settingUsersAPI = BACK_ENDPOINT + '/settingUsers';

export default {
    state: {
        settingUsers: {},
    },
    mutations: {
        [SETTINGUSER_CHANGED]: (state: any, settingUser: SettingUser) => {
            return Vue.set(state.settingUsers, settingUser.key, settingUser.value);
        },
    },
    getters: {
        getSetting: (state: any) => (key: SettingUsersKey): string => state.settingUsers[key],
        isEmailNotificationOnWeetViewActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.EMAIL_NOTIFICATION_WEET_VIEWED] === 'true' || false,
        isEmailNotificationOnWeetCommentActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.EMAIL_NOTIFICATION_WEET_COMMENT] === 'true' || false,
        isEmailNotificationOnWeetShareActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.EMAIL_NOTIFICATION_WEET_SHARED] === 'true' || false,
        isEmailNotificationOnNewWeetActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.EMAIL_NOTIFICATION_NEW_WEET] === 'true' || false,
        isEmailNotificationOnWeetUpdateActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.EMAIL_NOTIFICATION_WEET_UPDATED] === 'true' || false,
        isWebPushNotificationActivated: (state: any): boolean => state.settingUsers[SettingUsersKey.WEB_PUSH_NOTIFICATION] === 'true' || false,
        getResolutionCapture: (state: any): number => {
            const value = state.settingUsers[SettingUsersKey.CAPTURE_RESOLUTION];
            if (!value) {
                return 720;
            } else {
                const numberValue = parseInt(value, 10);
                if (numberValue === 1080 || numberValue === 720 || numberValue === 1440 || numberValue === 2160) {
                    return numberValue;
                } else {
                    return 720;
                }
            }
        },
        getFpsCapture: (state: any): number => {
            const value = state.settingUsers[SettingUsersKey.CAPTURE_FPS];
            if (!value) {
                return 25;
            } else {
                const numberValue = parseInt(value, 10);
                if (numberValue >= 25 && numberValue <= 60) {
                    return numberValue;
                } else {
                    return 25;
                }
            }
        },
        isTimeSectionSoundActivated: (state: any, getters: any): boolean => {
            const settings = getters.userConnected.settings;
            if (settings === undefined) {
                return false;
            }
            for (const setting of settings) {
                if (setting.key === SettingUsersKey.TIME_SECTION_SOUND) {
                    return setting.value === 'true';
                }
            }
            return false;
        },
        isTimeSectionNotificationActivated: (state: any, getters: any): boolean => {
            const settings = getters.userConnected.settings;
            if (settings === undefined) {
                return false;
            }
            for (const setting of settings) {
                if (setting.key === SettingUsersKey.TIME_SECTION_NOTIFICATION) {
                    return setting.value === 'true';
                }
            }
            return false;
        },
        getNoiseCancelationLvl: (state: any, getters: any): number => {
            const settings = getters.userConnected.settings;
            if (settings === undefined) {
                return 0;
            }
            for (const setting of settings) {
                if (setting.key === SettingUsersKey.NOISE_CANCELATION) {
                    if (setting.value === 'true' || setting.value === true) {
                        return 1;
                    } else {
                        try {
                            return parseFloat(setting.value);
                        } catch (e) {
                            return 0;
                        }
                    }
                }
            }
            return 0;
        },
        getPersonalWeetQuota: (state: any, getters: any): number => {
            const settings = getters.userConnected.settings;
            if (settings === undefined) {
                return 10;
            }
            for (const setting of settings) {
                if (setting.key === SettingUsersKey.PERSONAL_SPACE_QUOTA) {
                    return parseInt(setting.value, 10);
                }
            }
            return 10;
        },
        getExcedingWeetInPersonalSpace: (state: any, getters: any): number | null => {
            const settings = getters.userConnected.settings;
            if (settings === undefined) {
                return null;
            }
            for (const setting of settings) {
                if (setting.key === SettingUsersKey.PERSONAL_SPACE_QUOTA) {
                    return getters.getNumberOwnWeets - parseInt(setting.value, 10);
                }
            }
            return null;
        }
    },
    actions: {
        async [SETTINGUSERS_LIST]({commit, dispatch}: { commit: any, dispatch: any }) {
            await axios({
                url: settingUsersAPI,
                method: 'GET',
            }).then((resp) => {
                const settingUsers = resp.data as SettingUser[];
                for (const settingUser of settingUsers) {
                    commit(SETTINGUSER_CHANGED, settingUser);
                }
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [SET_SETTINGUSER]({commit, dispatch}: { commit: any, dispatch: any }, settingUser: SettingUserParams) {
            await axios({
                url: settingUsersAPI,
                method: 'PUT',
                data: settingUser
            }).then((res) => {
                const settingUserResponse = res.data as SettingUser;
                commit(SETTINGUSER_CHANGED, settingUserResponse);
                dispatch(UPDATE_SETTING_USER_CONNECTED, settingUserResponse);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },
        async [UPLOAD_VIRTUAL_BACKGROUND]({commit, dispatch}: { commit: any, dispatch: any },
                                          {key, blob}: { key: SettingUsersKey, blob: Blob }) {
            const formData = new FormData();
            formData.append('image', blob);
            formData.append('key', key.toString());
            const response = await axios({
                url: settingUsersAPI + '/upload',
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': 'form-data; name=image',
                },
            }).then((res) => {
                const settingUserResponse = res.data as SettingUser;
                commit(SETTINGUSER_CHANGED, settingUserResponse);
                dispatch(UPDATE_SETTING_USER_CONNECTED, settingUserResponse);
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
            return response;
        }
    }
};
