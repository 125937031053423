<template>
  <div>
    <b-dropdown aria-role="menu" expanded :disabled="!selectedWorkspaceID">
      <!-- TRIGGER -->
      <template #trigger="{ active }">
        <div class="buttonWorkspaceMenu" data-test="openMenuWorkspaceSetting">
          <div class="labelWorkspace">{{ selectedWorkspaceName }}</div>
          <div v-if="selectedWorkspaceID" class="iconChevron">
            <w-icon :icon="active?'chevron-up':'chevron-down'" size="small"></w-icon>
          </div>
        </div>
      </template>

      <!-- ITEM SEND EVENT -->
      <b-dropdown-item @click="openPopUpInviteWorkspace"
                       v-if="canSendInvite"
                       data-test="workspaceInviteMembers">
        <div class="dropdownItemContentWrapper">
          <div class="dropdownItemContent">{{ $t('workspace.menu.invite') }}</div>
          <div class="dropdownItemIcon">
            <w-icon icon="account-multiple-plus-outline" class="icon" size="small"/>
          </div>
        </div>
      </b-dropdown-item>

      <b-dropdown-item @click="goToWorkspaceSettings" v-if="canAccessWorkspaceSetting"
                       data-test="workspaceSetting">
        <div class="dropdownItemContentWrapper">
          <div class="dropdownItemContent"> {{ $t('workspace.menu.settings') }}</div>
          <div class="dropdownItemIcon">
            <w-icon icon="cog-outline" class="icon" size="small"/>
          </div>
        </div>
      </b-dropdown-item>

      <b-dropdown-item @click="goToBillingSettings" v-if="canManagePayments && isFreemium"
                       data-test="menuPaymentWorkspace">
        <div class="dropdownItemContentWrapper bold">
          <div class="dropdownItemContent">
            {{ $t('workspace.plan.upgrade') }}
          </div>
          <div class="dropdownItemIcon">
            <w-icon icon="shimmer" class="icon secondary" size="small"/>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item separator v-if="canSendInvite || canAccessWorkspaceSetting"></b-dropdown-item>
      <b-dropdown-item @click="leaveWorkspace">
        <div class="dropdownItemContentWrapper red">
          <div class="dropdownItemContent">
            {{ $t('workspace.menu.signOut.title', { workspaceName: this.selectedWorkspaceName }) }}
          </div>
          <div class="dropdownItemIcon">
            <w-icon icon="logout" class="icon" size="small"/>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <!-- SHOW POPUP OF WEET INVIT-->
    <w-modal :active.sync="showPopUpInviteWorkspace">
      <WorkspaceInvite @close="showPopUpInviteWorkspace=false"/>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import {
  COULD_LEAVE_WORKSPACE,
  LEAVE_WORKSPACE
} from '@/store/workspace/workspaceAction';
import {confirmationDanger, inform} from '@/utils/dialog';
import {
  canAccessWorkspaceSetting,
  canManagePayments,
  canSendInvite,
  isFreemium,
  selectWorkspace
} from '@/utils/workspaceUtil';
import router from '@/router';
import WModal from '@/components/wrapper/w-modal.vue';
import WorkspaceInvite from '@/components/workspace/WorkspaceInvite.vue';
import {LeaveWorkspaceEnum} from '@/store/workspace/workspaceModel';
import {WorkspaceUserRoleEnum} from '@/store/workspaceRight/workspaceRightModel';
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";

@Component({
  components: {WorkspaceInvite, WModal, WIcon, WButton},
})
export default class WorkspaceMenu extends Vue {


  private showPopUpInviteWorkspace = false;

  get selectedWorkspaceName(): string {
    return store.getters.getSelectedWorkspace?.name || this.$t('workspace.personal');
  }

  get canAccessWorkspaceSetting() {
    return canAccessWorkspaceSetting();
  }

  get canManagePayments() {
    return canManagePayments();
  }

  get canSendInvite(): boolean {
    return canSendInvite();
  }

  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspaceID;
  }

  get isFreemium(): boolean {
    return isFreemium();
  }

  get myWorkspaceRoles(): WorkspaceUserRoleEnum[] {
    return store.getters.getWorkspaceRights;
  }

  get userConnected() {
    return store.getters.userConnected;
  }

  private openPopUpInviteWorkspace() {
    this.showPopUpInviteWorkspace = true;
  }


  private goToWorkspaceSettings() {
    this.$router.push({name: 'MyWorkspaceSettings'});
  }

  private goToBillingSettings() {
    store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
    this.$router.push({name: 'MyWorkspacePayment'});
  }

  private leaveWorkspace() {
    store.dispatch(COULD_LEAVE_WORKSPACE).then(async (couldLeave: LeaveWorkspaceEnum) => {
      if (couldLeave === LeaveWorkspaceEnum.SHOULD_REMOVE_WORKSPACE) {
        this.displayRemoveWorkspacePopup();
      } else if (couldLeave === LeaveWorkspaceEnum.SHOULD_TRANSFER_OWNERSHIP) {
        this.displayTransferOwnershipPopup();
      } else if (couldLeave === LeaveWorkspaceEnum.COULD_LEAVE_WORKSPACE) {
        this.displayLeaveWorkspacePopup();
      }
    });

  }

  private async displayLeaveWorkspacePopup() {
    const message = this.$t('workspace.menu.signOut.leaveWorkspacePopup.description', {workspaceName: this.selectedWorkspaceName}).toString();
    const confirmText = this.$t('workspace.menu.signOut.leaveWorkspacePopup.confirmText').toString();
    const title = this.$t('workspace.menu.signOut.leaveWorkspacePopup.title').toString();
    try {
      await confirmationDanger(message, title, confirmText);
      store.dispatch(LEAVE_WORKSPACE).then(() => {
        // User confirm, leave the workspace
        selectWorkspace(null);
        inform(this.$t('workspace.leaveConfirm').toString());
      });
      router.push({name: 'MyWeets'});
    } catch (e) {
      // User cancel popup, do nothing
    }
  }

  private async displayRemoveWorkspacePopup() {
    const message = this.$t('workspace.menu.signOut.removeWorkspacePopup.description', {workspaceName: this.selectedWorkspaceName}).toString();
    const confirmText = this.$t('workspace.menu.signOut.removeWorkspacePopup.confirmText').toString();
    const title = this.$t('workspace.menu.signOut.removeWorkspacePopup.title').toString();
    try {
      await confirmationDanger(message, title, confirmText);
      // User confirm, go to delete workspace page
      router.push({name: 'MyWorkspaceDelete'});
    } catch (e) {
      // User cancel popup, do nothing
    }
  }

  private async displayTransferOwnershipPopup() {
    const message = this.$t('workspace.menu.signOut.transferOwnerPopup.description', {workspaceName: this.selectedWorkspaceName}).toString();
    const confirmText = this.$t('workspace.menu.signOut.transferOwnerPopup.confirmText').toString();
    const title = this.$t('workspace.menu.signOut.transferOwnerPopup.title').toString();
    try {
      await confirmationDanger(message, title, confirmText);
      // User confirm, go to manage members page so that user can choose another owner
      router.push({name: 'MyWorkspaceManageMembers'});
    } catch (e) {
      // User cancel popup, do nothing
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import '@/scss/gridBreakPoint.scss';


.buttonWorkspaceMenu {
  display: flex;
  height: 48px;
  align-items: center;
  cursor: pointer;
  border-bottom: solid 1px var(--light);
  // max-width: 220px;

  .labelWorkspace {
    font-size: 16px;
    color: var(--black);
    font-weight: 900;
    cursor: pointer;
    letter-spacing: 0.3px;
    flex-grow: 1;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 192px;
    @include GRID1X {
      max-width: 256px;
    }

  }
  .icon{
    &.secondary{
      color: var(--secondary);
    }
  }
  .iconChevron {
    padding-right: 8px;
    display: flex;
    align-items: center;
    color: var(--black);
  }
}

.dropdownItemContentWrapper {
  display: flex;
  align-items: center;
  color: var(--black);

  &.bold {
    font-weight: 900;
  }

  .dropdownItemContent {
    flex-grow: 1;
    text-align: left;
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdownItemIcon {
    display: flex;
    align-items: center;
    .icon{
      &.secondary{
        color:var(--secondary)
      }
    }
  }

  &.red {
    color: var(--red);
  }

}

</style>
