export const GET_MY_WORKSPACES_LIST = 'GET_MY_WORKSPACES_LIST';
export const ADD_USERS_TO_WORKSPACE = 'ADD_USERS_TO_WORKSPACE';
export const GET_WORKSPACE_USER_LIST = 'GET_WORKSPACE_USER_LIST';
export const RESET_WORKSPACE_USER_LIST = 'RESET_WORKSPACE_USER_LIST';
export const UPDATE_WORKSPACE_USER_ROLE = 'UPDATE_WORKSPACE_USER_ROLE';
export const SET_WORKSPACE_USER = 'SET_WORKSPACE_USER';
export const DELETE_USER_FROM_WORKSPACE = 'DELETE_USER_FROM_WORKSPACE';
export const UPDATE_WORKSPACE_ICON = 'UPDATE_WORKSPACE_ICON';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const LEAVE_WORKSPACE = 'LEAVE_WORKSPACE';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const FILTER_WORKSPACE_BY_NAME = 'FILTER_WORKSPACE_BY_NAME';
export const SEARCH_WORKSPACE_USERS_BY_EMAIL = 'SEARCH_WORKSPACE_USERS_BY_EMAIL';
export const COULD_LEAVE_WORKSPACE = 'COULD_LEAVE_WORKSPACE';
export const REINIT_WORKSPACE_USERS_SEARCHED = 'REINIT_WORKSPACE_USERS_SEARCHED';
export const SET_SETTINGWORKSPACE = 'SET_SETTINGWORKSPACE';
export const GET_WORKSPACE_USERS_COUNT = 'GET_WORKSPACE_USERS_COUNT';
export const GET_WORKSPACE_CREATORS_COUNT = 'GET_WORKSPACE_CREATORS_COUNT';
export const GET_WORKSPACE_WEET_COUNT = 'GET_WORKSPACE_WEET_COUNT';
export const REQUEST_UPGRADE_WORKSPACE = 'REQUEST_UPGRADE_WORKSPACE';
export const GET_MY_WORKSPACE = 'GET_MY_WORKSPACE';
export const JOIN_WORKSPACE = 'JOIN_WORKSPACE';
export const GET_MY_WORKSPACES_COUNT = 'GET_MY_WORKSPACES_COUNT';
export const GET_MY_STATUS_IN_WORKSPACE = 'GET_MY_STATUS_IN_WORKSPACE';
export const SET_MY_LOGO_HEADER = 'SET_MY_LOGO_HEADER';
