

export class DownloadMedia {
    public mediaId: string = '';
    public pourcent: number = 0;
    public byte: number = 0;
    public url: string = '';
    public totalByte: number = 0;
    public finished: boolean = false;
}
