import axios from 'axios';
import {BACK_ENDPOINT} from '@/store/conf/api';
import Vue from 'vue';
import store from '@/store';
import {User} from '@/store/user/userModel';
import {WorkspaceUserCountRepresentation, WorkspaceWeetCountRepresentation} from '@/store/payment/paymentModel';
import {WorkspaceRightManagment, WorkspaceRightManagmentEnum} from '@/store/workspaceRight/workspaceRightModel';
import {GET_WORKSPACE_RIGHTS} from '@/store/workspaceRight/workspaceRightAction';

const workspaceAPI = BACK_ENDPOINT + '/workspaces';

export default {
  state: {
    workspacesRights: {},
  },
  mutations: {
    [GET_WORKSPACE_RIGHTS]: (state: any, {
      data,
      workspaceID
    }: { data: WorkspaceRightManagment[], workspaceID: string }) => {
      Vue.set(state.workspacesRights, workspaceID, data);
    },
  },
  getters: {
    getWorkspaceRights: (state: any) => (workspaceID: string): WorkspaceRightManagment[] => {
      return state.workspacesRights[workspaceID];
    },
    getWorkspaceRightByKey: (state: any) => (workspaceID: string, key: WorkspaceRightManagmentEnum): boolean => {
      const array = state.workspacesRights[workspaceID] as WorkspaceRightManagment[];
      const elem = array?.find((r: WorkspaceRightManagment) => r.key === key);
      if (!elem?.value) {
        return false;
      }
      const value = JSON.parse(elem?.value + ''); // force convert string to boolean
      return value === true;
    },
  },
  actions: {
    async [GET_WORKSPACE_RIGHTS]({commit, dispatch}: { commit: any, dispatch: any }) {
      const workspace = store.getters.getSelectedWorkspaceID;
      if (!workspace) {
        throw new Error('impossible to find the workspace');
      }
      return await axios({
        url: `${workspaceAPI}/${workspace}/rights`,
        method: 'GET',
      }).then((resp) => {
        commit(GET_WORKSPACE_RIGHTS, {data: resp.data, workspaceID: workspace});
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },

};

