<template>
  <div>
    <slot></slot>
    <div v-if="annotation" class="iconSelector" v-for="ico in listOfIcons" :key="generateUID()+'ico-'+ico" @click="onSelect(ico)" :class="size">

      <w-icon :size="size"  :icon="ico"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Annotation} from "@/store/annotation/annotationModel";
import WIcon from "@/components/wrapper/w-icon.vue";
import {v4 as uuidv4} from 'uuid';


@Component({
  components: {WIcon},
})
export default class IconsSelector extends Vue {

  @Prop({default:'large'})
  private size!:string;

  @Prop({required:true})
  private annotation!:Annotation;

  private uid:string="";

  private listOfIcons: string[] = ['information-outline', 'bell-outline', 'alert-octagram-outline',
    'alert-outline', 'link-variant', 'account-circle-outline', 'arrow-down-bold-circle-outline',
    'pencil-outline', 'cellphone', 'cloud-outline','currency-usd','database-outline','clock-outline',
      'calendar-blank-outline','alarm-light-outline','alarm','calendar-blank-outline'
  ];

  private onSelect(value: string) {
    this.$emit('select', value);
  }

  private generateUID(){
    return uuidv4()
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.iconSelector {
  &.large{
    min-width: 64px;
    min-height: 64px;
    border-radius: 12px;
  }
  &.small{
    min-width: 24px;
    min-height: 24px;
    border-radius: 8px;
  }
  color: var(--dark);

  background: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  cursor: pointer;

  &:hover {
    background: var(--light1);
  }


}
</style>
