import {log} from '@/utils/log';

export class SegmentOfSound {
    public timeStart: number = 0;
    public timeEnd: number = 0;
}

export class SoundSegmenter {

    public timeStart: number = 0;
    public arrayOfSound: SegmentOfSound[] = [];
    private audioContext!: AudioContext;

    private instant = 0;
    private script!: ScriptProcessorNode;

    private currentSegment: SegmentOfSound | undefined = undefined;
    private firstSilenceEvent: number | undefined = undefined;
    private running: boolean = false;

    private onSegmentSound;

    constructor(stream: MediaStream, onSegmentSound, accurracy = 256) {

        this.audioContext = new AudioContext();
        this.script = this.audioContext.createScriptProcessor(accurracy, 1, 1);
        this.onSegmentSound = onSegmentSound;
        this.script.onaudioprocess = (event) => {
            // to keep the real this
            this.onAudioProcess(event);
        };
        try {
            const mic = this.audioContext.createMediaStreamSource(stream);
            mic.connect(this.script);
            this.script.connect(this.audioContext.destination);
        } catch (e: any) {
            log.warn(e.message);
        }
    }

    /**
     * Stop the silence detect
     */
    public stop() {
        this.running = false;

        // launch event
        if (this.onSegmentSound && this.currentSegment) {
            this.currentSegment.timeEnd = new Date().getTime() - this.timeStart;
            this.onSegmentSound(this.currentSegment);
            this.currentSegment = undefined;
        }
    }

    public start() {
        this.timeStart = new Date().getTime();
        this.running = true;
        // we start by a silence
        this.arrayOfSound = [];
    }

    /**
     * close the time
     */
    public close() {
        this.script.onaudioprocess = null;
    }

    private onAudioProcess(event) {
        const input = event.inputBuffer.getChannelData(0);
        let i;
        let sum = 0.0;
        let clipcount = 0;
        for (i = 0; i < input.length; ++i) {
            sum += input[i] * input[i];
            if (Math.abs(input[i]) > 0.99) {
                clipcount += 1;
            }
        }


        this.instant = Math.sqrt(sum / input.length);
        if (this.running) {
            const OFFSET_PRETIME = 0;
            if (this.instant > 0.03) {
                if (!this.currentSegment) {
                    this.currentSegment = new SegmentOfSound();
                    this.currentSegment.timeStart = new Date().getTime() - this.timeStart - OFFSET_PRETIME;
                }
                this.firstSilenceEvent = undefined;
            } else {
                const TIME_OF_SILENCE = 500;
                if (!this.firstSilenceEvent) {
                    this.firstSilenceEvent = new Date().getTime();
                }
                if (this.currentSegment && (new Date().getTime() - this.firstSilenceEvent) > TIME_OF_SILENCE) {
                    this.currentSegment.timeEnd = new Date().getTime() - this.timeStart;
                    this.arrayOfSound.push(this.currentSegment);
                    if (this.onSegmentSound) {
                        this.onSegmentSound(this.currentSegment);
                    }
                    this.currentSegment = undefined;
                }
            }
        }
    }
}
