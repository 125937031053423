<template>
  <div class="containerThumbnail" v-if="thumbnailUrl" :style="'background-image:url('+thumbnailUrl+');'">
    <WeetSpeedSelectorPlayer :weet="weet" class="speedSelector"/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {getImageURLForSize} from '@/utils/util';
import WeetSpeedSelectorPlayer from '@/components/play/player/weetPlayerSubComponent/WeetSpeedSelectorPlayer.vue';

@Component({
  components: {WeetSpeedSelectorPlayer, WToolTip},
})
export default class WeetThumbnailPlayer extends Vue {

  @Prop({required: true})
  private weet!: Weet;

  private hideThumbnail: boolean = false;

  get play(): boolean {
    return store.getters.isPlaying;
  }

  get thumbnailUrl(): string | undefined {
    if (this.weet?.thumbnail) {
      return getImageURLForSize(this.weet.thumbnail, 1280);
    } else {
      return undefined;
    }
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

.containerThumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-content: center;
  background-size: cover;
  background-position: bottom left;

  .speedSelector {
    position: absolute;
    left: calc(50% - 121px);
    width: 242px;
    top: calc(50% - 104px);
  }
}
</style>
