export enum TimeEventType {
  PRELOAD = 'PRELOAD',
  ANNOTATION = 'ANNOTATION',
  FILTER = 'FILTER',
  POSITION = 'POSITION',
  FULLSREEN = 'FULLSCREEN',
  STEP = 'STEP',
  MEDIA_SEEK = 'MEDIA_SEEK',
  MEDIA_PLAY = 'MEDIA_PLAY',
  MEDIA_PAUSE = 'MEDIA_PAUSE',
  KEYFRAME = 'KEYFRAME',
  NONE = 'NONE',
  NO_SOUND_DETECT = 'NO_SOUND_DETECT',
  SOUND_DETECT = 'SOUND_DETECT',
  START_CUT = 'START_CUT',
  END_CUT = 'END_CUT',
  CHAPTER_TITLE = 'CHAPTER_TITLE'
}
