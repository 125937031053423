<template>
  <div>
    <w-button v-if="canEditWorkspace"
              @click="goToBillingSettings"
              :rounded="rounded || circle"
              :size="size"
              icon="shimmer"
              :icon-right="iconRight"
              :color="color"
              :disabled="!enabled"
              :loading="loading"
              v-on="$listeners"
              :tooltip="$t('workspace.plan.upgradeInfo')">
      <span v-if="withLabel">{{ $t('workspace.plan.upgrade') }}</span>
    </w-button>
    <w-button v-else
              @click="sendRequest"
              :rounded="rounded || circle"
              :size="size"
              :icon-right="iconRight"
              :color="color"
              :disabled="!enabled"
              :loading="loading"
              v-on="$listeners"
              :tooltip="$t('workspace.plan.requestInfo')">
      <span v-if="withLabel">{{ $t('workspace.plan.request') }}</span>
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import {Weet} from '@/store/weet/weetModel';
import {inform} from '@/utils/dialog';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import store from '@/store';
import {REQUEST_UPGRADE_WORKSPACE} from '@/store/workspace/workspaceAction';
import {canEditWorkspace} from '@/utils/workspaceUtil';
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";

@Component({
  components: {WIcon, WToolTip, WButton},
})
export default class UpgradePlanButton extends Vue {
  @Prop()
  private weet!: Weet;

  @Prop({default: false})
  private rounded!: boolean;
  @Prop({default: 'small'})
  private size!: string;
  @Prop({default: ''})
  private iconRight!: string;
  @Prop({default: 'outlined'})
  private color!: string;
  @Prop({default: false})
  private circle!: boolean;
  @Prop({default: true})
  private enabled!: boolean;
  @Prop({default: false})
  private loading!: boolean;
  @Prop({default:true})
  private withLabel!:false
  get canEditWorkspace(): boolean {
    return canEditWorkspace();
  }

  get selectedWorkspaceID() {
    return store.getters.getSelectedWorkspaceID;
  }

  private goToBillingSettings() {
    store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
    this.$router.push({name: 'MyWorkspacePayment'});
  }

  private sendRequest() {
    this.loading = true;
    store.dispatch(REQUEST_UPGRADE_WORKSPACE, this.selectedWorkspaceID).then(() => {
          inform(this.$t('workspace.plan.requestSent.success').toString());
        }
    ).finally(() => {
          this.loading = false;
        }
    );

  }

}
</script>
<style scoped lang="scss">
</style>
