<template>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {
  ADD_EVENT_TO_WEET_VIEW_SESSION,
  CREATE_WEET_VIEW_SESSION,
  STOP_WEET_VIEW_SESSION,
} from '@/store/weetview/weetViewAction';
import {weetViewAPI} from '@/store/weetview/weetViewModule';
import {getTenantID} from '@/utils/util';
import {GetCommentParams, LIST_COMMENT} from '@/store/comment/commentAction';
import {CommentEvent} from '@/store/comment/commentModel';
import {log} from '@/utils/log';

@Component
export default class CommentLoader extends Vue {

  @Prop({default: ''})
  private weetID!: string;
  private loading: boolean = false;

  @Prop({default: 0})
  private timePlayer!: number;

  public mounted() {
    this.loadComment(0);
  }

  get currentPage(): number {
    return store.getters.getCurrentPageComment;
  }

  public loadComment(page) {
    const paramsGetComment = new GetCommentParams(this.weetID, page);
    store.dispatch(LIST_COMMENT, paramsGetComment).then(() => {
      this.loading = false;
    }).catch((e) => {
      this.loading = false;
      log.error(e);
    });
  }

  get duration(): number {
    return store.getters.getTimeLineDuration;
  }


  get comments(): CommentEvent[] {
    return store.getters.getComments;
  }

  @Watch('timePlayer')
  public onTimechange() {
    if (this.loading) {
      return;
    }
    if (this.timePlayer % 1000 <= 100) {
      // we verify is all comment isn't loaded
      if (this.comments.length < store.getters.getTotalOfComment) {

        // now we try to find how many second we have to the last event
        if (this.timePlayer + 10 > this.lastEvent.time) {
          // so we load a page
          this.loading = true;
          this.loadComment(this.currentPage + 1);
        }

      }
    }
  }

  get lastEvent(): CommentEvent {
    return this.comments[this.comments.length - 1];
  }

  get isPlaying(): boolean {
    return store.getters.isPlaying;
  }
}
</script>

<style scoped lang="scss">
</style>
